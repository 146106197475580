define('gooru-web/components/activity/unit-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['activity-unit-card'], // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * collection object
   * @type {Object}
   */collection:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('unit.standards.[]',function(){var standards=this.get('unit.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}})});});