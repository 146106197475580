define('gooru-web/services/api-sdk/featured-courses',['exports','ember','gooru-web/serializers/content/featured-courses','gooru-web/adapters/content/featured-courses'],function(exports,_ember,_gooruWebSerializersContentFeaturedCourses,_gooruWebAdaptersContentFeaturedCourses){ /**
 * Service to support the  Featured Courses CRUD operations
 *
 * @typedef {Object} FeaturedCourseService
 */exports['default'] = _ember['default'].Service.extend({serializer:null,adapter:null,init:function init(){this._super.apply(this,arguments);this.set('serializer',_gooruWebSerializersContentFeaturedCourses['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('adapter',_gooruWebAdaptersContentFeaturedCourses['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Returns the independent course list.
   * @returns {Promise.<Milestones>}
   */getIndependentCourseList:function getIndependentCourseList(params){var service=this;return service.get('adapter').getIndependentCourseList(params).then(function(courseList){return service.get('serializer').normalizeIndependentCourseList(courseList);});}, /**
   * @function joinPublicClassbyGrade
   * @param {UUID} courseId
   * @param {Object} gradeLowerBound
   * Method to add students into a class
   */joinPublicClassbyGrade:function joinPublicClassbyGrade(courseId,gradeLowerBound,autoJoin){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').joinPublicClassbyGrade(courseId,gradeLowerBound,autoJoin).then(function(response,data,jqXHR){var classId=jqXHR.getResponseHeader('location');resolve(_ember['default'].Object.create({classId:classId}));},function(error){reject(error);});});}});});