define('gooru-web/models/result/user-resources',['exports','ember','gooru-web/models/result/question'],function(exports,_ember,_gooruWebModelsResultQuestion){ /**
 * User resource results
 *
 * @typedef {Object} UserResourcesResult
 *
 */exports['default'] = _ember['default'].Object.extend({ // -------------------------------------------------------------------------
// Properties
/**
   * @property {boolean} isAttemptStarted - is the user starting a new attempt?
   */isAttemptStarted:false, /**
   * @property {boolean} isAttemptFinished - is the user finishing the current attempt?
   */isAttemptFinished:false, /**
   * @property {QuestionResult[]} questionResults
   */questionResults:_ember['default'].computed('resourceResults.[]',function(){return this.get('resourceResults').filter(function(resourceResult){return resourceResult instanceof _gooruWebModelsResultQuestion['default'];});}), /**
   * @property {ResourceResult[]} questionResults
   */resourceResults:[], /**
   * @property {string} user
   */user:null // -------------------------------------------------------------------------
// Methods
});});