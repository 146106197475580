define('gooru-web/utils/endpoint-config',['exports','gooru-web/services/configuration'],function(exports,_gooruWebServicesConfiguration){exports.getEndpointUrl = getEndpointUrl;exports.getEndpointSecureUrl = getEndpointSecureUrl;exports.getRealTimeWebServiceUrl = getRealTimeWebServiceUrl;exports.getRealTimeWebServiceUri = getRealTimeWebServiceUri;exports.getRealTimeWebSocketUri = getRealTimeWebSocketUri;exports.getRealTimeWebSocketUrl = getRealTimeWebSocketUrl;exports.getLanguageSettingdefaultLang = getLanguageSettingdefaultLang;exports.getLanguageSettingdropMenu = getLanguageSettingdropMenu;exports.getResearcher = getResearcher;exports.getMarketingsiteURL = getMarketingsiteURL;exports.getUserAlertMessage = getUserAlertMessage; /**
 * Get endpoint url from config
 */function getEndpointUrl(){ //TODO don't use global configuration variable
var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration?configuration.get('endpoint.url'):'';} /**
 * Get endpoint secure url from config
 */function getEndpointSecureUrl(){var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration?configuration.get('endpoint.secureUrl'):'';} /**
 * Get real time url from config
 */function getRealTimeWebServiceUrl(){var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration.get('realTime.webServiceUrl');} /**
 * Get real time uri from config
 */function getRealTimeWebServiceUri(){var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration.get('realTime.webServiceUri');} /**
 * get real time web socket uri
 */function getRealTimeWebSocketUri(){var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration.get('realTime.webSocketUri');} /**
 * Get real time web socket url
 */function getRealTimeWebSocketUrl(){var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration.get('realTime.webSocketUrl') + getRealTimeWebSocketUri();} /**
 * Get defaultLanguage setting from config
 */function getLanguageSettingdefaultLang(){ //TODO don't use global configuration variable
var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration?configuration.get('languageSetting.defaultLang'):'';} /**
 * Get defaultLanguage drop menu setting from config
 */function getLanguageSettingdropMenu(){ //TODO don't use global configuration variable
var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration?configuration.get('languageSetting.showDropMenu'):'';} /**
 * Get research application url for researcher role users
 */function getResearcher(){ //TODO don't use global configuration variable
var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration?configuration.get('researcher'):'';} /**
 * Get marketing site URL to redirect  the page after  user logout depending upon the environment.
 */function getMarketingsiteURL(){ //TODO don't use global configuration variable
var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration?configuration.get('marketingSiteUrl'):'';} /**
 * Get maintenance window ticker needed from config
 */function getUserAlertMessage(){ //TODO don't use global configuration variable
var configuration=_gooruWebServicesConfiguration['default'].configuration;return configuration?configuration.get('userAlert.message'):null;}});