define('gooru-web/components/new-cards/activities/gru-add-content-popup-card',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ /**
   * Added class attributes
   */classNames:['gru-add-content-popup-card'], /**
   * @property {Object} activeActivityContent hold the active content details
   */activeActivityContent:null, /**
   * @property {Object} isUpdateCard used to check update
   */isUpdateCard:false, /**
   * @property {Boolean} isAddActivity used to toggle activity popup
   */isAddActivity:false, /**
   * @property {Boolean} isAddActivity used to toggle activity popup
   */isConferenceAllow:false, /**
   * @property {Boolean} hasVideoConference used to toggle activity popup
   */hasVideoConference:_ember['default'].computed('isUpdateCard',function(){return !!this.get('isUpdateCard');}), /**
   * @property {String} updateThumbanil
   */updateThumbnailUrl:_ember['default'].computed('isUpdateCard',function(){var content=this.get('activeActivityContent');return content.get('collection.thumbnailUrl');}),actions:{ /**
     * @function onClose
     */onClose:function onClose(){this.set('isAddActivity',false);}, /**
     * @function onAddActivity  add class activity
     */onAddActivity:function onAddActivity(content){this.set('isAddActivity',false);this.sendAction('onAddActivity',content);}, /**
     * @function onToggleCheckbox  action trigger when click toggle checkbox
     */onToggleCheckbox:function onToggleCheckbox(){this.set('isConferenceAllow',true);}, /**
     * @function onDeny  action trigger when click deny
     */onDeny:function onDeny(){this.set('isConferenceAllow',false);this.set('hasVideoConference',false);}, /**
     * @function onDeny  action trigger when click deny
     */onAllow:function onAllow(){this.set('isConferenceAllow',false);this.set('hasVideoConference',true);}}});});