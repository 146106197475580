define('gooru-web/components/activity/gru-new-offline-activity',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['activity','gru-new-offline-activity'],lookupService:_ember['default'].inject.service('api-sdk/lookup'),collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @requires service:api-sdk/class-activity
   */classActivityService:_ember['default'].inject.service('api-sdk/class-activity'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.openPullUp();component.fetchAudiences();component.set('forMonth',moment().format('MM'));component.set('forYear',moment().format('YYYY'));component.$('.activity-title').focus();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when close pullup
closePullUp:function closePullUp(){var component=this;component.closePullUp();}, //Action triggered when select a date from datepicker
onSelectDate:function onSelectDate(selectedDate){var component=this;component.set('activityDate',selectedDate);component.set('unscheduledMonth',null);component.set('activityScheduled',selectedDate);}, //Action triggered when select audience card
onSelectAudience:function onSelectAudience(audience){var component=this;var selectedAudiences=component.get('selectedAudiences');var selectedAudienceIndex=selectedAudiences.indexOf(audience);if(selectedAudienceIndex < 0){audience.set('active',true);selectedAudiences.push(audience);}else {audience.set('active',false);selectedAudiences[selectedAudienceIndex] = audience;selectedAudiences.splice(selectedAudienceIndex,1);}component.set('selectedAudiences',selectedAudiences);}, //Action triggered when create activity
onCreateActivity:function onCreateActivity(){var component=this;var requestBody=component.getDataParams();component.get('collectionService').createExternalCollection(requestBody).then(function(externalCollection){var contentId=externalCollection.id;var classId=component.get('classId');var activityDate=component.get('activityDate');var scheduledMonth=activityDate?null:component.get('unscheduledMonth.monthNumber');var scheduledYear=activityDate?null:component.get('unscheduledMonth.monthYear');component.addActivity(classId,contentId,activityDate,scheduledMonth,scheduledYear).then(function(activityId){var contentType='collection-external';externalCollection.set('collectionType',contentType);externalCollection.set('format',contentType);var activityData=_ember['default'].Object.create({collection:_ember['default'].Object.create(externalCollection),id:activityId,added_date:activityDate,activityDate:activityDate,usersCount:-1,isActive:false,forMonth:parseInt(scheduledMonth),forYear:parseInt(scheduledYear)});component.sendAction('onAddExternalCollectionToDCA',activityData,activityDate,scheduledMonth,scheduledYear);component.closePullUp();});});}, //Action triggered when toggle taxonomy picker
onToggleTaxonomyPicker:function onToggleTaxonomyPicker(){var component=this;var taxonomyPickerContainer=component.$('.taxonomy-picker-container');component.$(taxonomyPickerContainer).slideDown(1000,function(){if(!component.get('selectedCompetencies.length')){component.set('course',null);component.set('domain',null);}component.set('isShowTaxonomyPicker',true);});}, //Action triggered when submit selected competencies
onSubmitCompetencies:function onSubmitCompetencies(selectedCompetencies,course,domain){var component=this;component.set('selectedCompetencies',selectedCompetencies);component.set('course',course);component.set('domain',domain);component.set('isShowTaxonomyPicker',false);}, //Action triggered when close taxonomy picker modal
onCloseTaxonomyPicker:function onCloseTaxonomyPicker(){var component=this;component.set('isShowTaxonomyPicker',false);}, //Action triggered when select unscheduled month
onSelectMonth:function onSelectMonth(month){var component=this;component.set('unscheduledMonth',month);component.set('activityScheduled',month.monthName);component.set('activityDate',null);}, //Action triggered when close create activity screen
onCloseCreateActivity:function onCloseCreateActivity(){var component=this;component.closePullUp();}, //Action triggered when remove tag
onRemoveSelectedTag:function onRemoveSelectedTag(tag){var component=this;component.get('selectedCompetencies').removeObject(tag.get('data'));component.get('visibleTaxonomyTags').removeObject(tag);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isShowPullUp
   */isShowPullUp:false, /**
   * @property {Boolean} isClose
   */isClose:false, /**
   * @property {Boolean} isShowTaxonomyPicker
   */isShowTaxonomyPicker:false, /**
   * @property {String} activityTitle
   */activityTitle:null, /**
   * @property {String} activityDescription
   */activityDescription:null, /**
   * @property {Array} selectedAudiences
   */selectedAudiences:_ember['default'].A([]), /**
   * @property {Date} activityDate
   */activityDate:moment().format('YYYY-MM-DD'), /**
   * @property {Boolean} isShowActivityDatepicker
   */isShowActivityDatepicker:true, /**
   * @property {Array} selectedCompetencies
   */selectedCompetencies:_ember['default'].A([]), /**
   * @property {String} unscheduledMonth
   */unscheduledMonth:null, /**
   * @property {String} activityScheduled
   */activityScheduled:_ember['default'].computed('activityDate',function(){var component=this;return component.get('activityDate');}), /**
   * It Maintains the state of month need to display or not.
   * @type {Boolean}
   */showMonths:true, /**
   * It maintains number of months to show or not
   * @type {Number}
   */numberOfMonthsToShow:3, /**
   * Maintains the value which of month activities displaying
   * @type {Integer}
   */forMonth:_ember['default'].computed(function(){return moment().format('MM');}), /**
   * Maintains the value which of year activities displaying
   * @type {Integer}
   */forYear:_ember['default'].computed(function(){return moment().format('YYYY');}), /**
   * Maintains  the value of first date of month
   * @return {String}
   */forFirstDateOfMonth:_ember['default'].computed('forMonth','forYear',function(){var forMonth=this.get('forMonth');var forYear=this.get('forYear');var date=forYear + '-' + forMonth + '-01';return moment(date).format('YYYY-MM-DD');}), /**
   * It maintains list of months to be display for unschedule contents
   * @return {Array}
   */months:_ember['default'].computed('forFirstDateOfMonth',function(){var component=this;var showMonths=component.get('showMonths');var monthsList=_ember['default'].A([]);var forFirstDateOfMonth=component.get('forFirstDateOfMonth');var monthAndYearOfCurrentDate=moment().format('YYYY-MM');var firtDateOfCurrentMonth=moment(monthAndYearOfCurrentDate + '-01');if(showMonths && forFirstDateOfMonth){var numberOfMonthsToShow=component.get('numberOfMonthsToShow');for(var index=0;index < numberOfMonthsToShow;index++) {var slectedMonth=moment(forFirstDateOfMonth).add(index,'months');var monthName=moment(forFirstDateOfMonth).add(index,'months').format('MMMM');var monthYear=moment(forFirstDateOfMonth).add(index,'months').format('YYYY');var monthNumber=moment(forFirstDateOfMonth).add(index,'months').format('MM');var month=_ember['default'].Object.create({monthNumber:monthNumber,monthName:monthName,monthYear:monthYear});month.set('isPast',!slectedMonth.isSameOrAfter(firtDateOfCurrentMonth));monthsList.pushObject(month);}}return monthsList;}), /**
   * @property {Boolean} isEnableCreateActivity
   */isEnableCreateActivity:_ember['default'].computed('activityTitle',function(){var component=this;var activityTitle=component.get('activityTitle');return activityTitle !== null && activityTitle.trim() !== '';}), /**
   * @property {String} course
   * Property to hold selected course title
   */course:null, /**
   * @property {String} domain
   * Property to hold selected domain title
   */domain:null, /**
   * @property {Array} visibleTaxonomyTags
   * Properto to hold visible taxonomy tags
   */visibleTaxonomyTags:_ember['default'].computed('selectedCompetencies',function(){var component=this;var selectedCompetencies=component.get('selectedCompetencies');var visibleTaxonomyTags=selectedCompetencies.map(function(taxonomyTag){return _ember['default'].Object.create({data:taxonomyTag,isActive:true,isReadonly:true,isRemovable:true});});return _ember['default'].A(visibleTaxonomyTags);}), // -------------------------------------------------------------------------
// Methods
/**
   * @function getDataParams
   * Method to create request body
   */getDataParams:function getDataParams(){var component=this;var title=component.get('activityTitle');var description=component.get('activityDescription');var taxonomy=component.get('selectedCompetencies');var selectedAudiences=component.get('selectedAudiences');var audienceIds=selectedAudiences.map(function(audience){return audience.id;});return {title:title.trim(),description:description && description.length?description.trim():null,audience:audienceIds,taxonomy:taxonomy};}, /**
   * @function openPullUp
   * Method to open pullup
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'15%'},400);}, /**
   * @function closePullUp
   * Method to close pullup
   */closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.resetProperties();component.set('isShowPullUp',false);});}, /**
   * @function fetchAudiences
   * Method to fetch audiences
   */fetchAudiences:function fetchAudiences(){var component=this;var lookupService=component.get('lookupService');return _ember['default'].RSVP.hash({audiences:_ember['default'].RSVP.resolve(lookupService.readAudiences())}).then(function(_ref){var audiences=_ref.audiences;component.set('audiences',audiences);});}, /**
   * @function createExternalCollection
   * Method to create external collection
   */createExternalCollection:function createExternalCollection(collectionData){var component=this;var collectionService=component.get('collectionService');return _ember['default'].RSVP.resolve(collectionService.createExternalCollection(collectionData));}, /**
   * @function addActivity
   * Method to add activity into a scheduled date/month/year
   */addActivity:function addActivity(classId,contentId,date,scheduledMonth,scheduledYear){var component=this;var classActivityService=component.get('classActivityService');return _ember['default'].RSVP.resolve(classActivityService.addActivityToClass(classId,contentId,'collection-external',date,scheduledMonth,scheduledYear));}, /**
   * @function resetProperties
   * Method to reset Properties
   */resetProperties:function resetProperties(){var component=this;component.set('selectedCompetencies',_ember['default'].A([]));component.set('selectedAudiences',_ember['default'].A([]));component.set('activityTitle',null);component.set('activityDescription',null);}});});