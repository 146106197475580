define('gooru-web/routes/sign-up-finish',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Properties
/**
   * @property {Ember.Service} Service to do retrieve countries
   */lookupService:_ember['default'].inject.service('api-sdk/lookup'), /**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), /**
   * @property {TenantService}
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), /**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), // -------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(params){if(this.get('session.isAnonymous')){this.transitionTo('index');}if(params.queryParams.showKnowMore){this.set('showKnowMore',true);}this.knowMore();},model:function model(){var route=this;return route.get('lookupService').readCountries().then(function(countries){var usCountry=countries.findBy('code',_gooruWebConfigConfig.COUNTRY_CODES.US);var usStates=route.get('lookupService').readStates(usCountry.id);return _ember['default'].RSVP.hash({countries:countries,states:usStates,showKnowMore:route.get('showKnowMore')});});}, /**
   * Set all controller properties used in the template
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){this._super(controller,model);controller.set('countries',model.countries);controller.set('states',model.states);if(model.showKnowMore){controller.openKnowMore();}controller.resetProperties();},knowMore:function knowMore(){var route=this;var userId=route.get('session.userId');route.get('profileService').readUserProfile(userId).then(function(updatedProfile){if(updatedProfile.info && updatedProfile.info.grade){route.set('userInfo',updatedProfile.info);}});route.get('tenantService').getActiveTenantSetting().then(function(tenantSetting){var tenantKnowMore=tenantSetting && tenantSetting.know_more_about_user_questions && tenantSetting.know_more_about_user_questions.length;route.set('tenantKnowMore',tenantKnowMore);});if(route.get('userInfo') && route.get('tenantKnowMore')){route.transitionTo('index');}}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when submitting the sign up finish form
     */signUpFinish:function signUpFinish(role){if(role === 'teacher'){this.transitionTo('content.classes.create');}else if(role === 'student'){this.transitionTo('student-home');}else {this.transitionTo('index');}}}});});