define('gooru-web/routes/reports/student-collection-analytics',['exports','ember','gooru-web/models/result/context','gooru-web/mixins/private-route-mixin','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsResultContext,_gooruWebMixinsPrivateRouteMixin,_gooruWebConfigConfig){ /**
 *
 * Analytics data for a student related to a collection of resources
 * Gathers and passes the necessary information to the controller
 *
 * @module
 * @augments ember/Route
 */exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), /**
   * @property {Ember.Service} Service to retrieve an assessment result
   */userSessionService:_ember['default'].inject.service('api-sdk/user-session'), /**
   * @property {AssessmentService} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {CollectionService} Service to retrieve a collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @property {LessonService} Service to retrieve a lesson
   */lessonService:_ember['default'].inject.service('api-sdk/lesson'), /**
   * @property {Ember.Service} Service to retrieve an assessment result
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), /**
   * @property {Ember.Service} Service to retrieve an assessment result from LI
   */learnerService:_ember['default'].inject.service('api-sdk/learner'), /**
   * @property {Service} profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), // -------------------------------------------------------------------------
// Actions
actions:{goBack:function goBack(){var route=this;var controller=route.get('controller');var context=controller.get('context');var toRoute=controller.get('backUrl');if(context.get('lessonId')){if(controller.get('isTeacher')){toRoute?route.transitionTo(toRoute):route.backToCourseMap();}else {route.backToData();}}else {toRoute = toRoute || 'index'; //index when refreshing the page, TODO fix
route.transitionTo(toRoute);}}, /**
     * Open the Open ended question summary report
     * @function actions:viewOEReport
     * @param questionId {String}
     */viewOEReport:function viewOEReport(questionId){var route=this;var controller=route.get('controller');var context=controller.get('context');var sessionId=context.get('sessionId');var isCollection=controller.get('collection.isCollection');if(isCollection){sessionId = controller.get('collectionSessionId');}var queryParams={collectionId:context.get('collectionId'),collectionType:context.get('collectionType'),studentId:context.get('userId'),classId:context.get('classId'),sessionId:sessionId,courseId:context.get('courseId'),unitId:context.get('unitId'),lessonId:context.get('lessonId'),questionId:questionId,role:controller.get('role'),backUrl:controller.get('backUrl')};route.transitionTo('reports.student-open-ended-summary',{queryParams:queryParams});}}, // -------------------------------------------------------------------------
// Methods
/**
   * @param {{ assessmentId: string, resourceId: string }} params
   */model:function model(params){var route=this;var context=route.getContext(params);var type=params.type || 'collection';var lessonPromise=context.get('courseId')?route.get('lessonService').fetchById(context.get('courseId'),context.get('unitId'),context.get('lessonId')):null;var isCollection=type === 'collection';var collectionPromise=isCollection?route.get('collectionService').readCollection(params.collectionId):route.get('assessmentService').readAssessment(params.collectionId);var completedSessionsPromise=isCollection?[]:context.get('classId')?route.get('userSessionService').getCompletedSessions(context):route.get('learnerService').fetchLearnerSessions(context);return _ember['default'].RSVP.hash({collection:collectionPromise,completedSessions:completedSessionsPromise,lesson:lessonPromise,context:context,profile:context.userId !== 'anonymous'?route.get('profileService').readUserProfile(context.userId):{}});},afterModel:function afterModel(model){var controller=this;var context=model.context;var completedSessions=model.completedSessions;var totalSessions=completedSessions.length;var session=totalSessions?completedSessions[totalSessions - 1]:null;if(session){ //collections has no session
context.set('sessionId',session.sessionId);}if(context.get('classId')){var performanceService=controller.get('performanceService');return performanceService.findAssessmentResultByCollectionAndStudent(context).then(function(assessmentResult){model.assessmentResult = assessmentResult;});}else {var learnerService=controller.get('learnerService');return learnerService.fetchCollectionPerformance(context).then(function(assessmentResult){model.assessmentResult = assessmentResult;});}}, /**
   *
   * @param controller
   * @param model
   * @returns {Promise.<T>}
   */setupController:function setupController(controller,model){controller.set('collection',model.collection.toPlayerCollection());controller.set('lesson',model.lesson);controller.set('completedSessions',model.completedSessions);controller.set('context',model.context);controller.set('profile',model.profile);var isCollection=controller.get('collection.isCollection');if(model.assessmentResult){if(isCollection){controller.set('collectionSessionId',model.assessmentResult.sessionId);}controller.setAssessmentResult(model.assessmentResult);}}, /**
   * Get the player context
   * @param params
   * @returns {Context}
   */getContext:function getContext(params){var route=this;var userId=route.get('session.userId');var role=params.role;if(role === 'teacher'){ /*
        for teachers, it could be the teacher playing a collection or a teacher seeing its students report
        for student should use always the session id
       */userId = params.userId || userId;}var collectionId=params.collectionId;var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;return _gooruWebModelsResultContext['default'].create({collectionType:params.type,userId:userId,collectionId:collectionId,courseId:courseId,classId:params.classId,unitId:unitId,lessonId:lessonId});}, /**
   * Take the user back to data page
   */backToData:function backToData(){var route=this;var controller=route.get('controller');var context=controller.get('context');if(context.get('classId')){route.transitionTo('student.class.performance',context.get('classId'),{queryParams:{filterBy:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT}});}else {route.transitionTo('student.independent.performance',context.get('courseId'),{queryParams:{filterBy:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT}});}}, /**
   * Take the user back to course map page
   */backToCourseMap:function backToCourseMap(){var route=this;var controller=route.get('controller');var context=controller.get('context');var unitId=context.get('unitId');var lessonId=context.get('lessonId');route.transitionTo('teacher.class.course-map',context.get('classId'),{queryParams:{location:unitId + '+' + lessonId}});},deactivate:function deactivate(){this.get('controller').resetValues();}});});