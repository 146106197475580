define('gooru-web/components/new-cards/gru-collection-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/mixins/modal','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebMixinsModal,_gooruWebConfigConfig){ /**
 * Collection, Assessment and Course card
 *
 * Component responsible of showing the collection assessment or rubric information in cards, so that most useful information is summarized there.
 * @module
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/session
   */session:_ember['default'].inject.service('session'), /**
   * @property {Ember.Service} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {Ember.Service} Service to retrieve a collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @requires service:api-sdk/course
   */courseService:_ember['default'].inject.service('api-sdk/course'), // -------------------------------------------------------------------------
// Attributes
classNames:['new-cards','gru-collection-card'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to bookmark content
     * @param {Collection/Assessment} content
     * @param {Boolean} showType
     */bookmarkContent:function bookmarkContent(content,showType){this.sendAction('onBookmarkContent',content,showType);}, /**
     * Action triggered to add to classroom or daily class activities
     */addToClassroom:function addToClassroom(){var component=this;component.addStudentCountToClasses();var model=_ember['default'].Object.create({classroomList:this.get('classroomList'),classActivity:!this.get('isCourse'),content:this.get('content')});if(this.get('isCourse')){model.set('callback',{success:function success(){component.sendAction('onUpdateUserClasses');}});}component.send('showModal','content.modals.gru-add-to-classroom',model,null,'add-to');}, /**
     * Action triggered to edit content
     */editContent:function editContent(){this.sendAction('onEditContent',this.get('content'));}, /**
     * Action triggered to open the content player
     * @param {string} content identifier
     */openContentPlayer:function openContentPlayer(content){this.sendAction('onOpenContentPlayer',content);}, /**
     * Action triggered to open the independent content player
     * @param {string} content identifier
     */playIndependent:function playIndependent(content){this.sendAction('onOpenIndependentPlayer',content);}, /**
     * Action triggered to preview the content
     * @param content
     */previewContent:function previewContent(content){var component=this;var isTeacher=this.get('isTeacher');var isStudent=this.get('isStudent');var isCourse=this.get('isCourse');var isCollection=content.get('isCollection');var contentId=content.get('id');var model=_ember['default'].Object.create({content:content,isTeacher:isTeacher,isStudent:isStudent,disabledBookmark:this.get('disabledBookmark')});if(isCourse){component.get('courseService').fetchById(contentId).then(function(course){model.set('content.children',course.children);model.set('remixCourse',function(){return component.remixCourse();});model.set('bookmarkCourse',function(){return component.send('bookmarkContent',content,false);});model.set('playCourse',function(){return component.send('playIndependent',content,false);});}).then(function(){component.send('showModal','gru-preview-course',model);});}else {model.set('remixCollection',function(){return component.remixCollection();});model.set('bookmarkCollection',function(){return component.send('bookmarkContent',content,false);});model.set('playCollection',function(){return component.send('playIndependent',content,false);});component.loadCollection(contentId,isCollection,model).then(function(){component.send('showModal','gru-preview-collection',model);});}}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * Indicates if the edit functionality is enabled
   * @property {boolean}
   */addEnabled:true, /**
   * @property {Object} Object containing student count by class
   */classStudentCount:null, /**
   * @property {Course, Collection, Assessment} content
   */content:null, /**
   * @property {contentType} content type
   */contentType:_ember['default'].computed('content',function(){return this.get('content.isCollection')?_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT;}), /**
   * Indicates if bookmark action is disabled
   * @property {boolean}
   */disabledBookmark:_ember['default'].computed('isTeacher','session.isAnonymous',function(){return this.get('session.isAnonymous') || this.get('isTeacher');}), /**
   * Indicates if is on the user Profile in order to enable edit functions
   * @property {boolean}
   */isMyProfile:false, /**
   * Indicates if the publish icon is visible
   * @property {boolean}
   */publishVisible:false, /**
   * Indicates if is the card is showing on profile
   * @property {boolean}
   */isOnProfile:false, /**
   * @property {boolean} Indicates if collection has 1 or more resources
   */hasResources:_ember['default'].computed.gt('content.resourceCount',0), /**
   * @property {boolean} Indicates if collection has 1 or more questions
   */hasCollections:_ember['default'].computed.gt('collectionCount',0), /**
   * @property {boolean} Indicates if collection has 1 or more resources
   */hasAssessments:_ember['default'].computed.gt('assessmentCount',0), /**
   * @property {boolean} Indicates if collection has 1 or more questions
   */hasQuestions:_ember['default'].computed.gt('content.questionCount',0), /**
   * @property {boolean}
   */isAssessment:_ember['default'].computed.alias('content.isAssessment'), /**
   * @property {boolean} isCourse indicate if the card is showing a course
   */isCourse:false, /**
   * Indicates if the teacher is seeing the card
   * @property {boolean}
   */isTeacher:_ember['default'].computed.equal('profile.role','teacher'), /**
   * Indicates if the student is seeing the card
   * @property {boolean}
   */isStudent:_ember['default'].computed.equal('profile.role','student'), /**
   * @property {string} bookmark content action
   */onBookmarkContent:null, /**
   * @property {string} edit action
   */onEditContent:null, /**
   * @property {string} on content player action
   */onOpenContentPlayer:null, /**
   * @property {string} on independent player action
   */onOpenIndependentPlayer:null, /**
   * @property {Profile} user profile
   */profile:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('content.standards.[]','isCourse','course.taxonomy.[]',function(){if(!this.get('isCourse')){var standards=this.get('content.standards');standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}else {return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('content.taxonomy'));}}), // -------------------------------------------------------------------------
// Methods
remixCourse:function remixCourse(){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {var remixModel={content:this.get('content')};this.send('showModal','content.modals.gru-course-remix',remixModel);}},remixCollection:function remixCollection(){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {var remixModel={content:this.get('content')};if(this.get('content.isCollection')){this.send('showModal','content.modals.gru-collection-remix',remixModel);}else {this.send('showModal','content.modals.gru-assessment-remix',remixModel);}}}, /**
   * Loads the collection/assessment
   * @param {string} contentId
   * @param {boolean} isCollection
   * @param {Object} model
   * @returns {Promise.<Collection>}
   */loadCollection:function loadCollection(contentId,isCollection,model){var component=this;if(isCollection){return component.get('collectionService').readCollection(contentId).then(function(collection){model.set('content.children',collection.children);});}return component.get('assessmentService').readAssessment(contentId).then(function(collection){model.set('content.children',collection.children);});}, /**
   * Add student count to classes
   */addStudentCountToClasses:function addStudentCountToClasses(){var component=this;var classStudentCount=component.get('classStudentCount');var classRoomList=component.get('classroomList');if(classRoomList){classRoomList.forEach(function(classroom){var studentCount=component.studentCount(classStudentCount,classroom);classroom.set('studentCount',studentCount);});}}, /**
   * @property {Number} Count of students in the class
   */studentCount:function studentCount(_studentCount,classroom){var classStudentCount=_studentCount;return classStudentCount && _ember['default'].keys(classStudentCount).length?classStudentCount[classroom.get('id')]?classStudentCount[classroom.get('id')]:0:0;}});});