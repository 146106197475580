define('gooru-web/components/content/rubric/gru-rubric-edit',['exports','ember','gooru-web/mixins/session','gooru-web/models/rubric/rubric-category','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/mixins/modal','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config','gooru-web/utils/taxonomy'],function(exports,_ember,_gooruWebMixinsSession,_gooruWebModelsRubricRubricCategory,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebMixinsModal,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig,_gooruWebUtilsTaxonomy){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsSession['default'],_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications
   */notifications:_ember['default'].inject.service(), /**
   * @property {MediaService} Media service API SDK
   */mediaService:_ember['default'].inject.service('api-sdk/media'), /**
   /**
   * @property {RubricService} Rubric service API SDK
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * @property {Ember.Service} Service to do retrieve language
   */lookupService:_ember['default'].inject.service('api-sdk/lookup'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','rubric','gru-rubric-edit'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Add new category
     */addNewCategory:function addNewCategory(){var newCategory=_gooruWebModelsRubricRubricCategory['default'].create(_ember['default'].getOwner(this).ownerInjection(),{isNew:true,allowsLevels:true,allowsScoring:true}).initLevels();var categories=this.get('categories');categories.addObject(newCategory);}, /**
     * Cancel Rubric Edition
     */cancelEdit:function cancelEdit(){this.cancel();}, /**
     * Update Rubric
     */updateContent:function updateContent(){this.save();}, /**
     * Edit Content
     */editContent:function editContent(){var rubricForEditing=this.get('rubric').copy();this.set('tempRubric',rubricForEditing);this.set('isEditing',true);}, /**
     * Triggered by gru-category
     *Copy category
     */copyCategory:function copyCategory(category,index){var categories=this.get('categories');var newCategory=category.copy();categories.insertAt(index + 1,newCategory);}, /**
     *Triggered by gru-category
     * Delete category
     */deleteCategory:function deleteCategory(category){var categories=this.get('categories');categories.removeObject(category);this.saveCategory(category);}, /**
     *Set if feedback is required
     */setFeedBack:function setFeedBack(){this.set('rubric.requiresFeedback',!this.get('rubric.requiresFeedback'));}, /**
     *
     * @param {TaxonomyRoot} subject
     */selectSubject:function selectSubject(subject){this.set('selectedSubject',subject);}, /**
     *SelectCategory
     * @param {String} category
     */selectCategory:function selectCategory(category){var component=this;var standardLabel=category === _gooruWebConfigConfig.EDUCATION_CATEGORY.value;component.set('standardLabel',!standardLabel);if(category === component.get('selectedCategory')){component.set('selectedCategory',null);}else {component.set('selectedCategory',category);}component.set('selectedSubject',null);}, /**
     * SelectCategory
     * @param {String} category
     */updateCategory:function updateCategory(category){this.saveCategory(category);}, /**
     * Remove tag data from the taxonomy list in tempUnit
     */removeTag:function removeTag(taxonomyTag){var tagData=taxonomyTag.get('data');this.get('tempRubric.standards').removeObject(tagData);}, /**
     * Open taxonomy modal
     */openTaxonomyModal:function openTaxonomyModal(){this.openTaxonomyModal();},updateLanguage:function updateLanguage(language){this.set('tempRubric.primaryLanguage',language.id);this.set('selectedLanguage',language);this.save();}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);component.fetchLanguage();},fetchLanguage:function fetchLanguage(){var controller=this;controller.get('lookupService').getLanguages().then(function(languages){var primaryLanguageId=controller.get('primaryLanguage')?controller.get('primaryLanguage'):controller.get('tempRubric.primaryLanguage');var languageLists=languages.filter(function(language){return language.id === primaryLanguageId;});controller.set('selectedLanguage',languageLists && languageLists.length?languageLists[0]:null);controller.set('languageList',languages);});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Category[]} Temporal categories array
   */categories:_ember['default'].computed('tempRubric.categories.[]',function(){var categories=_ember['default'].A([]);if(this.get('tempRubric.categories.length')){categories = this.get('tempRubric.categories');}return categories;}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */editableTags:_ember['default'].computed('tempRubric.standards.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('tempRubric.standards'),false,true);}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('rubric.standards.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('rubric.standards'),false);}), /**
   * If the file upload should show an error
   * @property {Boolean}
   */emptyFileError:false, /**
   *
   * @property {Object[]} footerActions List of action buttons to show
   */footerActions:_ember['default'].computed(function(){var _this=this;return [{name:'cancel',text:this.get('i18n').t('common.cancel'),'class':'btn-default',action:function action(){return _this.cancel();}},{name:'save',text:this.get('i18n').t('common.save'),'class':'btn-primary',action:function action(){return _this.save();}}];}), /**
   * @property {Object[]} headerActions List of action buttons to show
   */headerActions:_ember['default'].computed(function(){var _this2=this;return [{name:'delete',icon:'delete',action:function action(){return _this2['delete']();}},{name:'link',text:this.get('i18n').t('common.link'),icon:'insert_link',isShare:true,type:'rubric'},{name:'copy',icon:'content_copy',action:function action(){return _this2.copy();}},{name:'preview',text:this.get('i18n').t('common.preview'),action:function action(){return _this2.preview();}}];}), /*
   *  @property {Object}  backButton
   */backButton:_ember['default'].computed(function(){var _this3=this;return {text:this.get('i18n').t('common.back'),'class':'back-to',name:'keyboard_arrow_left',visible:true,action:function action(){return _this3.back();}};}), /**
   * @property {String} headerTitle
   */headerTitle:_ember['default'].computed(function(){return this.get('i18n').t('common.edit-rubric');}), /**
   * @property {Boolean} isEditing
   */isEditing:true, /**
   * @property {Object[]} options List of tab options to show
   */options:_ember['default'].computed(function(){return [{name:'information',text:this.get('i18n').t('common.information')},{name:'rubric',text:this.get('i18n').t('common.rubric-creation')}];}), /**
   * @property {String} selected Current option selected
   */selected:'information', /**
   * @property {RubricBackUrl} to back url
   */RubricBackUrl:null, /**
   * @property {boolean}
   */standardDisabled:_ember['default'].computed.not('selectedSubject'), /**
   * @property {boolean}
   */standardLabel:true, /**
   * i18n key for the standard/competency dropdown label
   * @property {string}
   */standardLabelKey:_ember['default'].computed('standardLabel',function(){return this.get('standardLabel')?'common.competency' //changing standards to competency
:'common.competencies';}), /**
   * @type {String} the selected category
   */selectedCategory:_ember['default'].computed('rubric',function(){var standard=this.get('rubric.standards.firstObject');var subjectId=standard?(0,_gooruWebUtilsTaxonomy.getSubjectId)(standard.get('id')):null;return subjectId?(0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectId):null;}),selectedSubject:_ember['default'].computed('rubric',function(){var standard=this.get('rubric.standards.firstObject');if(standard){standard.set('subjectCode',(0,_gooruWebUtilsTaxonomy.getGutCodeFromSubjectId)((0,_gooruWebUtilsTaxonomy.getSubjectId)(standard.get('id'))));}return standard?standard:null;}), // -------------------------------------------------------------------------
// Methods
/**
   * Cancel function for rubric edition
   */cancel:function cancel(){var rubricForCanceEditing=this.get('rubric').copy();this.set('tempRubric',rubricForCanceEditing);this.set('isEditing',false);}, /*
   * Back fucntion  for rubric edition
   */back:function back(){var component=this;var backUrl=component.get('rubric.backUrl');if(backUrl){this.get('router').transitionTo(backUrl);}else {component.get('router').transitionTo('library-search',{queryParams:{profileId:component.get('session.userId'),type:'my-content',activeContentType:'rubric'}});}},'delete':function _delete(){var myId=this.get('session.userId');var model={content:this.get('rubric'),deleteMethod:(function(){return this.get('rubricService').deleteRubric(this.get('rubric.id'));}).bind(this),type:'rubric',redirect:{route:'library-search',params:{profileId:myId,type:'my-content'}}};this.actions.showModal.call(this,'content.modals.gru-delete-rubric',model,null,null,null,false);}, /**
   * Copy function for rubric edition
   */copy:function copy(){var component=this;var rubricId=component.get('rubric.id');var rubricTitle=component.get('rubric.title');component.get('rubricService').copyRubric(rubricId).then(function(newRubricId){var successMsg=component.get('i18n').t('gru-rubric-edit.copy.success-message',{title:rubricTitle}).string;var editLabel=component.get('i18n').t('common.edit');var editRubricUrl=component.get('router').generate('content.rubric.edit',newRubricId);component.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + editRubricUrl + '">' + editLabel + '</a>');});}, /**
   * Save function for rubric edition
   */save:function save(){var component=this;var tempRubric=component.get('tempRubric');tempRubric.set('categories',component.get('categories').filter(function(category){return category.get('title');}));var maxScore=component.computeRubricMaxScore(tempRubric.get('categories'));tempRubric.set('maxScore',maxScore);component.setScoring(tempRubric);var rubric=component.get('rubric');this.set('emptyFileError',!tempRubric.get('url'));tempRubric.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){var imageIdPromise=new _ember['default'].RSVP.resolve(tempRubric.get('thumbnail'));if(tempRubric.get('thumbnail') && tempRubric.get('thumbnail') !== rubric.get('thumbnail')){imageIdPromise = component.get('mediaService').uploadContentFile(tempRubric.get('thumbnail'));}imageIdPromise.then(function(imageId){tempRubric.set('thumbnail',imageId);return component.get('rubricService').updateRubric(tempRubric);}).then(function(){rubric.merge(tempRubric,['title','description','thumbnail','subject','audience','isPublished','categories','url','uploaded','feedback','standards','primaryLanguage','metadata']);component.set('isEditing',false);});}component.set('didValidate',true);});}, /**
   * Save function for categories
   */saveCategory:function saveCategory(category){var component=this;var tempRubric=component.get('rubric');var categories=tempRubric.get('categories');tempRubric.set('categories',component.get('categories').filter(function(cat){return cat.get('title') && (categories.findBy('title',cat.get('title')) || category.get('title') === cat.get('title'));}));var maxScore=component.computeRubricMaxScore(tempRubric.get('categories'));tempRubric.set('maxScore',maxScore);component.setScoring(tempRubric);return component.get('rubricService').updateRubric(tempRubric);}, /**
   * Method used to compute the max score of rubric.
   * @param  {Array} categories
   * @return {Number}
   */computeRubricMaxScore:function computeRubricMaxScore(categories){var maxScore=0;if(categories){categories.filterBy('allowsScoring',true).map(function(category){var categoryLevelScore=[];category.get('levels').map(function(level){categoryLevelScore.push(Number(level.get('score')));});if(categoryLevelScore.length > 0){maxScore += Math.max.apply(Math,categoryLevelScore);}});}return maxScore > 0?maxScore:null;}, /**
   * @param {Object} rubric
   */setScoring:function setScoring(rubric){var maxScore=rubric.get('maxScore');if(maxScore > 0){rubric.set('scoring',true);}else {rubric.set('scoring',false);}}, /**
   * Preview rubric on header action
   */preview:function preview(){var component=this;var rubric=component.get('rubric');component.get('router').transitionTo('content.rubric.preview',rubric.id);}, /**
   * Open Taxonomy Modal
   */openTaxonomyModal:function openTaxonomyModal(){var component=this;var standards=component.get('tempRubric.standards') || [];var subject=component.get('selectedSubject');var subjectStandards=_gooruWebModelsTaxonomyTaxonomyTagData['default'].filterBySubject(subject,standards);var notInSubjectStandards=_gooruWebModelsTaxonomyTaxonomyTagData['default'].filterByNotInSubject(subject,standards);var model={selected:subjectStandards,shortcuts:null, // TODO: TBD
subject:subject,standardLabel:component.get('standardLabel'),callback:{success:function success(selectedTags){var dataTags=selectedTags.map(function(taxonomyTag){return taxonomyTag.get('data');});var standards=_ember['default'].A(dataTags);standards.pushObjects(notInSubjectStandards.toArray());component.get('tempRubric.standards').pushObjects(standards);component.set('tempRubric.standards',component.get('tempRubric.standards').uniqBy('code'));}}};this.actions.showModal.call(this,'taxonomy.modals.gru-standard-picker',model,null,'gru-standard-picker');}});});