define('gooru-web/components/gru-footer',['exports','ember'],function(exports,_ember){ /**
 * Application footer component
 * @see application.hbs
 *
 * @class
 * @typedef {Object} AppFooter
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-footer','hidden'],tagName:'footer' // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});