define('gooru-web/adapters/taxonomy/taxonomy',['exports','ember'],function(exports,_ember){ /**
 * Adapter for Taxonomy endpoints
 *
 * @typedef {Object} TaxonomyAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/taxonomy',namespaceV2:'/api/nucleus/v2/taxonomy',namespaceV2Classes:'/api/nucleus/v2/classes',namespaceLookup:'/api/nucleus/v1/lookups', /**
   * @namespace taxonomyDSNamespace
   * API Endpoint of the DS users for taxonomy
   */taxonomyDSNamespace:'/api/ds/users/v2/tx', /**
   * Fetches the Taxonomy Subjects for the specific type
   *
   * @param category - The classification type
   * @returns {Promise}
   */fetchSubjects:function fetchSubjects(category){var filter=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/subjects';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{classification_type:category}};options.data = Object.assign(options.data,filter);return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Taxonomy Subjects from the DS
   *
   * @param category - The classification type
   * @returns {Promise}
   */fetchTaxonomySubjects:function fetchTaxonomySubjects(category){var adapter=this;var namespace=adapter.get('taxonomyDSNamespace');var url=namespace + '/subjects';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{classificationType:category}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Taxonomy Subjects from the DS
   *
   * @param category - The classification type
   * @returns {Promise}
   */getTaxonomySubjectsByUserId:function getTaxonomySubjectsByUserId(userId){var adapter=this;var namespace=adapter.get('namespaceV2Classes');var url=namespace + '/subjects';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{userId:userId}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Taxonomy Courses
   *
   * @param frameworkId - the framework ID
   * @param subjectId - the taxonomy subject ID
   * @returns {Promise}
   */fetchCourses:function fetchCourses(frameworkId,subjectId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/frameworks/' + frameworkId + '/subjects/' + subjectId + '/courses';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Taxonomy Domains
   *
   * @param frameworkId - the framework ID
   * @param subjectId - the taxonomy subject ID
   * @param courseId - the taxonomy course ID
   * @returns {Promise}
   */fetchDomains:function fetchDomains(frameworkId,subjectId,courseId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/frameworks/' + frameworkId + '/subjects/' + subjectId + '/courses/' + courseId + '/domains';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Taxonomy Codes
   *
   * @param frameworkId - the framework ID
   * @param subjectId - the taxonomy subject ID
   * @param courseId - the taxonomy course ID
   * @param domainId - the taxonomy domain ID
   * @returns {Promise}
   */fetchCodes:function fetchCodes(frameworkId,subjectId,courseId,domainId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/frameworks/' + frameworkId + '/subjects/' + subjectId + '/courses/' + courseId + '/domains/' + domainId + '/codes';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Taxonomy Codes by a provided list of IDs
   *
   * @param codesIds - the list of codes IDs
   * @returns {Promise}
   */fetchCodesByIds:function fetchCodesByIds(codesIds){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/codes';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{idList:codesIds.join(',')}};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchDomainGradeBoundaryBySubjectId
   */fetchDomainGradeBoundaryBySubjectId:function fetchDomainGradeBoundaryBySubjectId(gradeId){var adapter=this;var namespace=adapter.get('taxonomyDSNamespace');var url=namespace + '/grade/boundary/' + gradeId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchGradesBySubject
   */fetchGradesBySubject:function fetchGradesBySubject(filters){var adapter=this;var namespace=adapter.get('taxonomyDSNamespace');var url=namespace + '/grades';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:filters};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchCommonGrade
   */fetchCommonGrade:function fetchCommonGrade(){var adapter=this;var namespace=adapter.get('namespaceLookup');var url=namespace + '/common/grade/levels';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Taxonomy Subjects for the specific type
   *
   * @param filters - The classification type
   * @example GET /api/nucleus/:version/taxonomy/subjects/:subjectId
   * @returns {Promise}
   */fetchSubject:function fetchSubject(filters){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/subjects/' + filters;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Subjects frameworks for the specified subject
   *
   * @param filters - The classification type
   * @example GET /api/nucleus/:version/taxonomy/frameworks/subjects?subject=K12.SC
   * 'http://staging.gooru.org/api/nucleus/v1/taxonomy/frameworks/subjects?subject=K12.SC'
   * @returns {Promise}
   */fetchSubjectFWKs:function fetchSubjectFWKs(filters){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/frameworks/subjects?subject=' + filters;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);},fetchCrossWalkFWC:function fetchCrossWalkFWC(frameworkCode,subjectCode){var adapter=this;var namespace=adapter.get('namespaceV2');var url=namespace + '/crosswalk/frameworks/' + frameworkCode + '/subjects/' + subjectCode;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Return list of  taxonomy subject classification details based  on tenant settings
   */fetchCategories:function fetchCategories(){var adapter=this;var namespace=adapter.get('namespaceV2');var url=namespace + '/classifications';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches results that match with the gut code
   *
   * @param prerequisites the prerequisites to search
   * @returns {Promise.<Array[]>}
   */fetchPrerequisites:function fetchPrerequisites(prerequisites){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/competency/prerequisite/' + prerequisites;var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the crosswalk competency based on the class framework
   *
   * @param framework- The class framework
   * @param competency- competency array
   * @returns {Promise}
   */crosswalkCompetency:function crosswalkCompetency(framework,competencies){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/framework/crosswalk/competency/' + framework;var options={type:'POST',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({codes:competencies})}; // options.data = Object.assign(options.data, filter);
return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the Taxonomy Codes
   *
   * @param frameworkId - the framework ID
   * @param competncyId - the taxonomy ID
   * @returns {Promise}
   */fetchCompetencyCodes:function fetchCompetencyCodes(frameworkId,competencyId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/frameworks/' + frameworkId + '/competencies/' + competencyId + '/codes';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});