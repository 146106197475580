define('gooru-web/components/gru-gut-filter',['exports','ember','gooru-web/utils/taxonomy'],function(exports,_ember,_gooruWebUtilsTaxonomy){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['filter','gru-gut-filter'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:taxonomy
   */taxonomyService:_ember['default'].inject.service('taxonomy'),competencyService:_ember['default'].inject.service('api-sdk/competency'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadCategories().then(function(categories){component.reloadGutFilters(categories);});}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when selecting a category
onSelectCategory:function onSelectCategory(category){var component=this;if(component.get('activeCategory.id') !== category.get('id')){component.loadSubjects(category);component.set('activeCategory',category);component.updateGutFilters('category');}}, //Action triggered when selecting a subject
onSelectSubject:function onSelectSubject(subject){var component=this;if(component.get('activeSubject.id') !== subject.get('id')){component.loadDomainCompetencies(subject);component.set('activeSubject',subject);component.updateGutFilters('subject',subject.get('code'));}}, //Action triggered when selecting a domain
onSelectDomain:function onSelectDomain(domain){var component=this;if(component.get('activeDomain.domainCode') !== domain.get('domainCode')){var domainCompetencies=component.get('domainCompetencies');var selectedDomainCompetencies=domainCompetencies.findBy('domainCode',domain.domainCode);component.set('competencies',selectedDomainCompetencies?selectedDomainCompetencies.competencies:_ember['default'].A([]));component.set('activeDomain',domain);component.updateGutFilters('domain',domain.get('domainCode'));}}, //Action triggered when selecting a competency
onSelectCompetency:function onSelectCompetency(competency){var component=this;if(component.get('activeCompetency.competencyCode') !== competency.get('competencyCode')){component.set('activeCompetency',competency);component.updateGutFilters('competency',competency.get('competencyCode'));}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} userId
   * Userid of active user
   */userId:_ember['default'].computed.alias('session.userId'), /**
   * @property {Array} competencies
   * List of competencies aligned to selected domain
   */competencies:_ember['default'].A([]), /**
   * @property {Object} activeCategory
   */activeCategory:null, /**
   * @property {Object} activeSubject
   */activeSubject:null, /**
   * @property {Object} activeDomain
   */activeDomain:null, /**
   * @property {Object} activeCompetency
   */activeCompetency:null, /**
   * @property {Object} gutFilters
   * Default filter object item
   */gutFilters:{subjectCode:undefined,domainCode:undefined,gutCode:undefined}, /**
   * @property {Object} standardPreference
   * Student selected subject and framework preference
   */standardPreference:_ember['default'].computed(function(){var component=this;return component.get('userPreference.standard_preference') || {};}), /**
   * @property {Boolean} isLoading
   */isLoading:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadCategories
   * @return {Array}
   * Method to load categories
   */loadCategories:function loadCategories(){var component=this;return component.get('taxonomyService').getCategories().then(function(categories){component.set('categories',categories);return categories;});}, /**
   * @function loadCategories
   * @param {Object} category
   * @return {Array}
   * Method to load subjects aligned to given category
   */loadSubjects:function loadSubjects(category){var component=this;var categoryId=category.get('id');component.set('isLoading',true);return component.get('taxonomyService').getTaxonomySubjects(categoryId).then(function(subjects){if(!component.isDestroyed){component.set('subjects',subjects);component.set('isLoading',false);}return subjects;});}, /**
   * @function loadDomainCompetencies
   * @param {Object} activeSubject
   * Method to load domain competencies aligned to given subject
   */loadDomainCompetencies:function loadDomainCompetencies(activeSubject){var component=this;var subject=activeSubject || component.get('activeSubject');var standardPreference=component.get('standardPreference');var frameworkId=standardPreference[subject.get('code')] || null;var taxonomyService=component.get('taxonomyService');var competencyService=component.get('competencyService');var userId=component.get('userId');var subjectId=subject.get('id');component.set('isLoading',true);return _ember['default'].RSVP.hash({domainCompetencies:competencyService.getCompetencyMatrixDomain(userId,subjectId),matrixCoordinates:competencyService.getCompetencyMatrixCoordinates(subjectId),crossWalkFWCompetencies:frameworkId?taxonomyService.fetchCrossWalkFWC(frameworkId,subjectId):_ember['default'].RSVP.resolve(null)}).then(function(_ref){var domainCompetencies=_ref.domainCompetencies;var matrixCoordinates=_ref.matrixCoordinates;var crossWalkFWCompetencies=_ref.crossWalkFWCompetencies;if(!component.isDestroyed){component.set('domains',matrixCoordinates.get('domains') || _ember['default'].A([]));component.set('domainCompetencies',domainCompetencies.domains || _ember['default'].A([]));if(crossWalkFWCompetencies){component.set('fwCompetencies',(0,_gooruWebUtilsTaxonomy.flattenGutToFwCompetency)(crossWalkFWCompetencies));component.set('fwDomains',(0,_gooruWebUtilsTaxonomy.flattenGutToFwDomain)(crossWalkFWCompetencies));}component.set('isLoading',false);}return domainCompetencies;});}, /**
   * @function updateGutFilters
   * @param {String} dataKey
   * @param {String} value
   * Method to update selected gut filters
   */updateGutFilters:function updateGutFilters(dataKey,value){var component=this;if(!component.isDestroyed){var gutFilters=component.get('gutFilters') || {};if(dataKey === 'category'){gutFilters.subjectCode = undefined;gutFilters.domainCode = undefined;gutFilters.gutCode = undefined;component.setProperties({subjects:_ember['default'].A([]),domains:_ember['default'].A([]),competencies:_ember['default'].A([])});component.setProperties({activeSubject:null,activeDomain:null,activeCompetency:null});}else if(dataKey === 'subject'){gutFilters.subjectCode = value;gutFilters.domainCode = undefined;gutFilters.gutCode = undefined;component.setProperties({domains:_ember['default'].A([]),competencies:_ember['default'].A([])});component.setProperties({activeDomain:null,activeCompetency:null});}else if(dataKey === 'domain'){gutFilters.domainCode = value;gutFilters.gutCode = undefined;component.setProperties({competencies:_ember['default'].A([])});component.setProperties({activeCompetency:null});}else if(dataKey === 'competency'){gutFilters.gutCode = value;}component.set('gutFilters',gutFilters);}}, /**
   * @function reloadGutFilters
   * @param {Array} categories
   * Method to reload gut filters based on selection
   */reloadGutFilters:function reloadGutFilters(categories){var component=this;var appliedFilters=component.get('appliedFilters');var filterSubjectCode=appliedFilters.subjectCode;var filterDomainCode=appliedFilters.domainCode;var filterGutCode=appliedFilters.gutCode;if(filterSubjectCode){var activeCategory=categories.findBy('code',filterSubjectCode.split('.')[0]);component.set('activeCategory',activeCategory);component.loadSubjects(activeCategory).then(function(subjects){var activeSubject=subjects.findBy('code',filterSubjectCode);component.set('activeSubject',activeSubject);if(filterDomainCode){component.loadDomainCompetencies(activeSubject,filterDomainCode).then(function(domainCompetencies){var activeDomain=domainCompetencies.domains.findBy('domainCode',filterDomainCode);component.set('activeDomain',activeDomain);component.set('competencies',activeDomain.get('competencies'));if(filterGutCode){var activeCompetency=activeDomain.get('competencies').findBy('competencyCode',filterGutCode);component.set('activeCompetency',activeCompetency);component.set('appliedFilters.gutCode',activeCompetency.get('competencyCode'));}});}});}}});});