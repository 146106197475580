define('gooru-web/components/file-picker',['exports','ember','ember-cli-file-picker/components/file-picker','gooru-web/utils/utils','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_emberCliFilePickerComponentsFilePicker,_gooruWebUtilsUtils,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _emberCliFilePickerComponentsFilePicker['default'].extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Properties
/**
   * Default handler when a file is removed
   * @type {Function}
   */onRemoveFile:null, // -------------------------------------------------------------------------
// Methods
/*
   * Validate the files per:
   * https://github.com/funkensturm/ember-cli-file-picker#validations
   */filesAreValid:function filesAreValid(files){var file=files[0];var valid=true; // Clear any previous error messages
this.get('errors').clear();var fileMaxSize=this.get('configuration.FILE_UPLOAD.MAX_SIZE_IN_MB') || _gooruWebConfigConfig.FILE_MAX_SIZE_IN_MB;var fileMaxSizeInBytes=parseInt(fileMaxSize) * 1024 * 1024;if(file.size > fileMaxSizeInBytes){var errorMessage=this.get('i18n').t('common.errors.file-max-size-increase',{fileMaxSize:fileMaxSize}).string;this.get('errors').addObject(errorMessage);valid = false;} // regex to check for valid file format
var format=this.accept;var acceptedType=undefined; // Check the accepted format against each type separately
var _iteratorNormalCompletion=true;var _didIteratorError=false;var _iteratorError=undefined;try{for(var _iterator=_gooruWebConfigConfig.UPLOADABLE_TYPES[Symbol.iterator](),_step;!(_iteratorNormalCompletion = (_step = _iterator.next()).done);_iteratorNormalCompletion = true) {var value=_step.value;if(value.validType === format){acceptedType = value;break;}}}catch(err) {_didIteratorError = true;_iteratorError = err;}finally {try{if(!_iteratorNormalCompletion && _iterator['return']){_iterator['return']();}}finally {if(_didIteratorError){throw _iteratorError;}}}if(acceptedType){ // Extract valid extensions string from the accepted type object
var validExtensionsString=acceptedType.validExtensions; // Split the valid extensions string into an array
var validExtensionsArray=validExtensionsString.split(',').map(function(ext){return ext.trim();}); // Check if the file name has a valid extension
var isValidExtension=validExtensionsArray.some(function(ext){return file.name.endsWith(ext);});if(!isValidExtension){var errorMessage=this.get('i18n').t('common.errors.file-upload-missing',{extensions:validExtensionsString}).string;this.get('errors').addObject(errorMessage);valid = false;}}if(!valid){ // Remove the image preview and run the handler for removing the image
this.clearPreview();if(this.get('onRemoveFile')){this.get('onRemoveFile')();}}return valid;}, /**
   * When the file input changed (a file got selected)
   * Override original method to restore preview after selecting 'cancel' from the browser file dialog
   * @see https://github.com/funkensturm/ember-cli-file-picker/pull/18
   * @param  {Event} event The file change event
   */filesSelected:function filesSelected(event){var files=event.target.files;var extraParams=this.extraParams;if(files.length){for(var i=0;i < files.length;i++) {files[0].extraParam = extraParams;}this.handleFiles(files,extraParams);}else { // The user chose to cancel the image selection from the browser file window
// so preview will be cleared along with any error messages there might have been
this.clearPreview();this.get('errors').clear();if(this.get('onRemoveFile')){this.get('onRemoveFile')();}}}, /**
   * @override
   * @function addPreviewImage
   * @param file uploaded file object
   * Method to add preview thumbnail container to show image and other type based icons
   */addPreviewImage:function addPreviewImage(file){var fileType=(0,_gooruWebUtilsUtils.inferUploadType)(file.filename,_gooruWebConfigConfig.OA_TASK_SUBMISSION_TYPES);fileType = fileType || _gooruWebConfigConfig.OA_TASK_SUBMISSION_TYPES.findBy('value','others');var previewContainer='';if(fileType.value === 'image'){previewContainer = this.$('<img src="' + file.data + '" class="file-picker__preview__image ' + (this.get('multiple')?'multiple':'single') + '">');}else {previewContainer = this.$('<i class="fa ' + fileType.icon + ' file-preview-type-icon"></i>');}this.hideProgress();this.$('.file-picker__preview').append(previewContainer);}});});