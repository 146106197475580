define('gooru-web/components/content/modals/gru-quick-course-search',['exports','ember','gooru-web/utils/sort-featured-courses'],function(exports,_ember,_gooruWebUtilsSortFeaturedCourses){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(),classService:_ember['default'].inject.service('api-sdk/class'),courseService:_ember['default'].inject.service('api-sdk/course'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-quick-course-search'],classNameBindings:['component-class'], // -------------------------------------------------------------------------
// Actions
actions:{selectCourse:function selectCourse(id){this.set('selectedCourse',id);$('.gru-quick-course-search .selected').removeClass('selected');$('.' + id).addClass('selected');},assignCourse:function assignCourse(){var component=this;var courseId=component.get('selectedCourse');var classId=component.get('model.classId');var courseIdPromise=_ember['default'].RSVP.resolve(courseId);if(component.get('model').get('areFeatured')){courseIdPromise = component.get('courseService').copyCourse(courseId);}courseIdPromise.then(function(courseIdToAssign){return component.get('classService').associateCourseToClass(courseIdToAssign,classId);}).then(function(){component.triggerAction({action:'closeModal'});component.get('router').transitionTo('teacher.class.course-map',classId,{queryParams:{refresh:true}});},function(){var message=component.get('i18n').t('common.errors.course-not-associated').string;component.get('notifications').error(message);});}}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {?String} specific class
   */'component-class':null, /**
   * @type {?String} specific class
   */areFeatured:_ember['default'].computed.bool('model.areFeatured'), /**
   * @type {String} selected Course's ID
   */selectedCourse:null, /**
    * @type {String} selected Course's ID
    */hasSelectedCourse:_ember['default'].computed.notEmpty('selectedCourse'),orderedCourses:_ember['default'].computed('model.courses',function(){return (0,_gooruWebUtilsSortFeaturedCourses.sortCoursesBySubject)(this.get('model.courses'));})});});