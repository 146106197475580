define('gooru-web/models/rubric/grade-category-score',['exports','ember'],function(exports,_ember){ /**
 * Grade Category Score model
 *
 * @typedef {Object} GradeCategoryScore
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {String} title
   */title:null, /**
   * @property {String} level obtained
   */levelObtained:null, /**
   * @property {Integer} Max score for the level
   */levelMaxScore:null, /**
   * @property {Integer} Score for the level
   */levelScore:null, /**
   * @property {String} level comment
   */levelComment:null, /**
   * If the category has scores
   * @property {Boolean}
   */hasScore:_ember['default'].computed('levelScore',function(){var score=this.get('levelScore');return !!score || score === 0;})});});