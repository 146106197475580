define('gooru-web/models/content/unit',['exports','ember','ember-cp-validations'],function(exports,_ember,_emberCpValidations){var Validations=(0,_emberCpValidations.buildValidations)({title:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.unit-title-required'})]}}); /**
 * Unit model
 *
 * @typedef {Object} Content/Unit
 */exports['default'] = _ember['default'].Object.extend(Validations,{ /**
   * @property {String} id - Gooru id for the unit
   */id:null, /**
   * @property {String} bigIdeas
   */bigIdeas:'', /**
   * @property {Content/Lesson[]} children - List of unit lessons
   */children:[], /**
   * @property {String} essentialQuestions
   */essentialQuestions:'', /**
   * @property {String} hours
   */expectedhours:'', /**
   * @property {String} minutes
   */expectedminutes:'', /**
   * @property {Number} lessonsTotal - total number of lessons in the unit
   */lessonCount:0, /**
   * @property {String} sequence - sequence order among other course units
   */sequence:0, /**
   * @property {String} title
   */title:'', /**
   * @property {Object[]} taxonomy - Taxonomy array
   */taxonomy:[], /**
   * @property {Number} membersCount - number of members in the unit (for analytics)
   */membersCount:0, /**
   * @property {sortedLessonResults[]} Lessons sorted by sequence
   */sortedLessonResults:_ember['default'].computed('children.[]',function(){return this.get('children').sortBy('sequence');}), /**
   * Return a copy of the unit for editing
   *
   * @function
   * @return {Content/Unit}
   */copy:function copy(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key)); // Copy null values as well to avoid triggering the validation on empty input fields
if(value === 'string' || value === 'number' || value === 'boolean' || value === 'null'){properties.push(key);}} // Copy the unit data
properties = this.getProperties(properties);properties.taxonomy = _ember['default'].A(this.get('taxonomy').slice(0));return this.get('constructor').create(_ember['default'].getOwner(this).ownerInjection(),properties);}, /**
   * Copy a list of property values from another unit to override the current ones
   *
   * @function
   * @param {Content/Unit} unit
   * @param {String[]} propertyList
   * @return {null}
   */merge:function merge(unit){var propertyList=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var properties=unit.getProperties(propertyList);this.setProperties(properties);}, /**
   * Get an specific lesson index of the children
   *
   * @function
   * @param {Lesson} lesson
   * @return {Number}
   */getChildLessonIndex:function getChildLessonIndex(lesson){return this.get('sortedLessonResults').mapBy('id').indexOf(lesson.get('id'));}});});