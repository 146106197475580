define('gooru-web/components/charts/competency-progress-bar',['exports','ember','gooru-web/utils/utils','gooru-web/config/config'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['student-comptency-progress'], /**
   * @property {Object} competency
   */competency:null, /**
   * @property {Boolean}
   * Property to store given screen value is compatible
   */isMobileView:(0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.LARGE),didInsertElement:function didInsertElement(){this.parseCompetency();},didRender:function didRender(){this._super.apply(this,arguments);var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, /**
   * @function parseCompetency
   * Method to calculate comptency status count and set height for div
   */parseCompetency:function parseCompetency(){var component=this;var competency=component.get('competency');var total=competency.inprogress + competency.notstarted + competency.completed;var completed=competency.completed === 0?0:Math.round(competency.completed / total * 100);var inProgress=competency.inprogress === 0?0:Math.round(competency.inprogress / total * 100);var notStarted=competency.notstarted === 0?0:Math.round(competency.notstarted / total * 100);var size=component.get('isMobileView')?'width':'height';component.$('.completed').css('' + size,completed + '%');component.$('.in-progress').css('' + size,inProgress + '%');component.$('.not-started').css('' + size,notStarted + '%');}});});