define('gooru-web/components/player/offline-activity/gru-offline-activity-start-page',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ /**
   * Maintains the session object.
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['offline-activity-player','gru-offline-activity-start-page'], // -------------------------------------------------------------------------
// Events
/**
   * Function to triggered once when the component element is after rendered
   */didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action get triggered when rubric attachment preview got close
     */filePreviewClose:function filePreviewClose(user){this.$('.rubric-file-preview-container.' + user).fadeOut('slow');}, /**
     * Action get triggered when rubric attachment preview got open
     */filePreviewOpen:function filePreviewOpen(user){this.$('.rubric-file-preview-container.' + user).css('visibility','visible').hide().fadeIn('slow');}, //Action triggered when click on start player
onStartPlayer:function onStartPlayer(){var component=this;component.sendAction('onStartPlayer');}, //Action triggered when click on close player
onClosePlayer:function onClosePlayer(){var component=this;component.sendAction('onClosePlayer');}, //Aciton triggered when toggle teacher/student rubric categories container
onToggleRubricContainer:function onToggleRubricContainer(containerSection){var component=this;if(containerSection === _gooruWebConfigConfig.ROLES.STUDENT){component.toggleProperty('isStudentCategoriesExpanded');}else {component.toggleProperty('isTeacherCategoriesExpanded');}component.$('.' + containerSection + '.categories-container').slideToggle();}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} activityTasks
   * Property for list of tasks associated with the offline activity
   */activityTasks:_ember['default'].computed.alias('offlineActivity.tasks'), /**
   * @property {Array} activityReferences
   * Property for list references associated with the offline activity
   */activityReferences:_ember['default'].computed.alias('offlineActivity.references'), /**
   * @property {Array} oaTaxonomies
   * Property for list of taxonomies associated with the offline activity
   */oaTaxonomies:_ember['default'].computed('offlineActivity.standards.[]',function(){var standards=this.get('offlineActivity.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), /**
   * @property {Array} activityTeacherRubric
   * Property for list of teacher rubrics
   */activityTeacherRubric:_ember['default'].computed('offlineActivity.rubrics',function(){var component=this;var oaRubrics=component.get('offlineActivity.rubric');return oaRubrics.findBy('gradeType',_gooruWebConfigConfig.ROLES.TEACHER);}), /**
   * @property {Array} teacherRubricCategories
   * Property for list of teacher rubric categories
   */teacherRubricCategories:_ember['default'].computed('activityTeacherRubric',function(){var component=this;var activityTeacherRubric=component.get('activityTeacherRubric');var oaRubricTeacherCategories=_ember['default'].A([]);var categories=activityTeacherRubric.get('categories') || _ember['default'].A([]);oaRubricTeacherCategories = component.parseRubricCategories(oaRubricTeacherCategories,categories);return oaRubricTeacherCategories;}), /**
   * @property {Array} activityStudentRubric
   * Property for list of student rubrics
   */activityStudentRubric:_ember['default'].computed('offlineActivity.rubrics',function(){var component=this;var oaRubrics=component.get('offlineActivity.rubric');return oaRubrics.findBy('gradeType',_gooruWebConfigConfig.ROLES.STUDENT);}), /**
   * @property {Array} studentRubricCategories
   * Property for list of student rubric categories
   */studentRubricCategories:_ember['default'].computed('activityStudentRubric',function(){var component=this;var activityStudentRubric=component.get('activityStudentRubric');var oaRubricStudentCategories=_ember['default'].A([]);var categories=activityStudentRubric.get('categories') || _ember['default'].A([]);oaRubricStudentCategories = component.parseRubricCategories(oaRubricStudentCategories,categories);return oaRubricStudentCategories;}), /**
   * @property {Boolean} isStudent
   */isStudent:_ember['default'].computed.equal('session.role',_gooruWebConfigConfig.ROLES.STUDENT), /**
   * property used to show student title
   */studentTitle:_gooruWebConfigConfig.ROLES.STUDENT, /**
   * property used to show teacher title
   */teacherTitle:_gooruWebConfigConfig.ROLES.TEACHER, /**
   * @property {studentActivityReferences[]} List of user reference details
   */studentActivityReferences:_ember['default'].computed('offlineActivity.references',function(){var reference=this.get('offlineActivity.references');if(reference && reference.length){var filtercontent=reference.filterBy('userType',_gooruWebConfigConfig.ROLES.STUDENT);return _ember['default'].Object.create({references:filtercontent});}return {};}), /**
   * @property {teacherActivityReferences[]} List of teacher reference details
   */teacherActivityReferences:_ember['default'].computed('offlineActivity.references',function(){var reference=this.get('offlineActivity.references');if(reference && reference.length){var filtercontent=reference.filterBy('userType',_gooruWebConfigConfig.ROLES.TEACHER);return _ember['default'].Object.create({references:filtercontent});}return {};}), // -------------------------------------------------------------------------
// Methods
/**
   * @function parseRubricCategories
   * Method to parse rubric categories
   */parseRubricCategories:function parseRubricCategories(oaRubricCategories,categories){categories.map(function(category){var levels=category.get('levels');if(levels){if(category.get('allowsLevels') && category.get('allowsScoring')){levels = levels.sortBy('score');}category.set('levels',levels);}oaRubricCategories.pushObject(category);});return oaRubricCategories;}});});