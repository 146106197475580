define('gooru-web/adapters/notification',['exports','ember','gooru-web/adapters/application'],function(exports,_ember,_gooruWebAdaptersApplication){exports['default'] = _gooruWebAdaptersApplication['default'].extend({ /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @type {String}} base url for notifications API endpoints
   */namespace:'/api/notifications/v1', /**
   * Method to fetchs notifications for given teacher in class from the API
   * @function teacherFetch
   * @returns {Promise}
   */teacherFetch:function teacherFetch(filter){var adapter=this;var namespace=adapter.get('namespace');var nurl=namespace + '/teacher';var url=filter.body?nurl + '?classId=' + filter.body.classId + '&limit=' + filter.body.limit + '&boundary=' + filter.body.boundary:nurl;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.get('headers')};return _ember['default'].$.ajax(url,options);}, /**
   * Method to fetchs notifications for given student in class from the API
   * @function teacherFetch
   * @returns {Promise}
   */studentFetch:function studentFetch(filter){var adapter=this;var namespace=adapter.get('namespace');var nurl=namespace + '/student';var url=filter.body?nurl + '?classId=' + filter.body.classId + '&limit=' + filter.body.limit + '&boundary=' + filter.body.boundary:nurl;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.get('headers')};return _ember['default'].$.ajax(url,options);}, /**
   * Dismisses teacher notification of the supplied actionID
   * @param {actionId}
   */resetTeacherNotification:function resetTeacherNotification(actionId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/teacher/' + actionId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.get('headers'),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Dismisses student notification of the supplied actionID
   * @param {actionId}
   */resetStudentNotification:function resetStudentNotification(actionId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/student/' + actionId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.get('headers'),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}});});