define('gooru-web/serializers/notification/notification',['exports','ember','gooru-web/models/notification/notification','gooru-web/mixins/configuration','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsNotificationNotification,_gooruWebMixinsConfiguration,_gooruWebConfigConfig){ /**
 * Serializer to support the Notification CRUD operations for API 3.0
 *
 * @typedef {Object} NotificationSerializer
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{session:_ember['default'].inject.service('session'), /**
   * Normalize the notifications endpoint response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Notification[]}
   */normalizeNotifications:function normalizeNotifications(payload){var result=[];var serializer=this;var notifications=payload.notifications;if(_ember['default'].isArray(notifications)){result = notifications.map(function(notification){return serializer.normalizeNotification(notification);});}return result;}, /**
   * Normalize a notification
   * @param {*} notificationData
   * @returns {Notification}
   */normalizeNotification:function normalizeNotification(notificationData){var serializer=this;var actors=serializer.normalizeActors(notificationData.actors || []);return _gooruWebModelsNotificationNotification['default'].create(_ember['default'].getOwner(this).ownerInjection(),{id:notificationData.id,status:notificationData.status,createdAt:notificationData.created_at,updatedDate:notificationData.updated_at,notificationEvent:notificationData.notification_event,template:notificationData.template,actors:actors,object:{objectId:notificationData.object.content_id,objectName:notificationData.object.display_string,objectType:notificationData.object.content_type}});}, /**
   *
   * @param ActorsData
   * @returns {Array}
   */normalizeActors:function normalizeActors(actorsData){var serializer=this;var basePath=serializer.get('session.cdnUrls.user');var appRootPath=this.get('appRootPath'); //configuration appRootPath
return actorsData.map(function(actorData){var thumbnailUrl=actorData.thumbnail?basePath + actorData.thumbnail:appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.USER_PROFILE;return {id:actorData.user_id,username:actorData.display_name,avatarUrl:thumbnailUrl};});}});});