define('gooru-web/components/content/modals/gru-edit-content-filename',['exports','ember','gooru-web/models/content/oa/reference'],function(exports,_ember,_gooruWebModelsContentOaReference){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-edit-content-filename'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {MediaService} Media service API SDK
   */mediaService:_ember['default'].inject.service('api-sdk/media'), /**
   * @requires service:api-sdk/offline-activity/offline-activity
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), // -------------------------------------------------------------------------
// Properties
/**
   * @property hold uploaded file name
   */filename:'', /**
   * @property {model} reference model being edited
   */model:'', /**
   * @property {Array} references
   * property hold user reference list of data
   */references:_ember['default'].A([]), /**
   * Indicate if it's waiting for createCollection callback
   */isLoading:false, /**
   * showing reference error message
   */isexistingName:false, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);if(this.get('model')){var content=this.get('model');this.set('filename',content);}}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * when sumbit filename edit option
     */updateContent:function updateContent(){var component=this;component.validate().then(function(isValid){if(isValid){(function(){component.set('isLoading',true);var editRefModel=component.get('model');component.uploadSelectedFile().then(function(fileId){component.toggleReference(editRefModel,fileId).then(function(){component.set('isLoading',false); // needed to break the ref
});});})();}});}, //Action triggered when click close
closeModal:function closeModal(){var model=this.get('model');if(model.callback && model.callback.success()){model.callback.success();}this.triggerAction({action:'closeModal'});}}, /**
   * uploading files
   */uploadSelectedFile:function uploadSelectedFile(){var component=this;var fileToUpload=this.get('model').file;var fileIdPromise=new _ember['default'].RSVP.resolve(fileToUpload);if(fileToUpload){fileIdPromise = component.get('mediaService').uploadContentFile(fileToUpload);}return fileIdPromise;}, /**
   * update fileName to API
   */toggleReference:function toggleReference(model,fileId){var _this=this;var component=this;return component.get('activityService').createReferences(model,fileId).then(function(createdRefModel){component.updateParent(model,createdRefModel,fileId);var referenceInstance=_this.getNewReferenceModel();component.set('model',referenceInstance); // needed to break the ref
_this.triggerAction({action:'closeModal'});});}, /**
   * update reference data in UI without refresh
   */updateParent:function updateParent(model,createdRefModel,fileId){var createdNewRef=_ember['default'].Object.create({fileName:createdRefModel.fileName,id:createdRefModel.id,location:fileId,userType:model.userType,type:createdRefModel.type,subType:createdRefModel.subType,oaId:createdRefModel.oaId});var modelRef=model.get('references');modelRef.pushObject(createdNewRef);}, /**
   * get oaId value from model
   */getNewReferenceModel:function getNewReferenceModel(){return _gooruWebModelsContentOaReference['default'].create(_ember['default'].getOwner(this).ownerInjection(),{oaId:this.get('oaId')});}, /**
   * Validates string by passing value
   */validate:function validate(){this.set('isexistingName',false);var component=this;var model=component.get('model');var didValidate;var uploadedCol=model.get('references').findBy('fileName',model.get('fileName'));if(uploadedCol){this.set('isexistingName',true);}if(model.get('fileName') !== '' && !uploadedCol){didValidate = new _ember['default'].RSVP.Promise(function(resolve){resolve(true);});}else {didValidate = new _ember['default'].RSVP.Promise(function(resolve){if(model){model.validate().then(function(_ref){var validations=_ref.validations;return resolve(validations.get('isValid'));},function(){return resolve(false);});}});}component.set('didValidate',didValidate);return didValidate;}});});