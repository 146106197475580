define('gooru-web/models/network/network',['exports','ember'],function(exports,_ember){ /**
 * Network model with the information about followers and following
 *
 * @typedef {Object} NetworkModel
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {Array} followers - The list of followers
   */followers:[], /**
   * @property {Array} followings - The list of followings
   */followings:[], /**
   * @property {number} followerCount - The number of followers
   */followerCount:_ember['default'].computed('followers',function(){return _ember['default'].isArray(this.get('followers'))?this.get('followers').length:0;}), /**
   * @property {number} followingCount - The number of followings
   */followingCount:_ember['default'].computed('followings',function(){return _ember['default'].isArray(this.get('followings'))?this.get('followings').length:0;}), /**
   * @property {Array} details - The details of the followers/followings based on the details flag passed to the endpoint
   */details:[]});});