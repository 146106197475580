define('gooru-web/routes/content/questions/create',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/models/content/question','gooru-web/config/question'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebModelsContentQuestion,_gooruWebConfigQuestion){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{queryParams:{isCollection:{},collectionId:{},questionType:{},compQuestionId:{refreshModel:true}}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/question
   */questionService:_ember['default'].inject.service('api-sdk/question'), /**
   * @requires service:api-sdk/assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @requires service:api-sdk/collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Methods
model:function model(params){var route=this;var collection=_ember['default'].Object.create({});var questionType=params.questionType;var compQuestionId=params.compQuestionId && params.compQuestionId !== 'null'?params.compQuestionId:null;var questions=_gooruWebModelsContentQuestion['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:route.get('i18n').t('common.question-type.' + questionType).string,type:_gooruWebConfigQuestion.DEFAULT_QUESTION[questionType].type});var modelData={questions:questions,collection:collection,questionType:questionType,compQuestionId:compQuestionId};if(params.collectionId && params.collectionId !== 'null'){collection.setProperties({id:params.collectionId});modelData.isCollection = !!(params.isCollection === 'true');}return modelData;},setupController:function setupController(controller,model){controller.set('question',model.questions);if(model.isCollection){controller.set('isCollection',model.isCollection);}controller.set('isCreateQuestion',true);controller.set('collection',model.collection);controller.set('createQuestiontype',model.questionType);controller.set('compQuestionId',model.compQuestionId);},resetController:function resetController(controller){controller.set('compQuestionId',null);controller.set('collection',null);controller.set('collectionId',null);}});});