define('gooru-web/controllers/student/independent',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Collapses the header section
     * @param {boolean} state
     */toggleHeader:function toggleHeader(state){var $panels=$('.header .panel');if(state){$panels.slideUp();}else {$panels.slideDown();}}, /**
     * Trigger the event to open student course report
     */openCourseReport:function openCourseReport(){this.openStudentCourseReport();}}, // -------------------------------------------------------------------------
// Events
/**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){var item=this.get('menuItem');this.selectItem(item);}, // -------------------------------------------------------------------------
// Properties
/**
   * The course presented to the user
   * @property {Course}
   */course:null, /**
   * The performance for the presented course
   * @property {Performance}
   */performance:null, /**
   * The units presented to the user
   * @property {Unit}
   */units:null, /**
   * The menuItem selected
   * @property {String}
   */menuItem:null,ILActivity:null, /**
   * Percentage value for the score chart
   * @property {Boolean}
   */percentageToShow:_ember['default'].computed('performance.scoreInPercentage',function(){var score=this.get('performance.scoreInPercentage');return score || score === 0?score + '%':'--';}), /**
   * @property {boolean} Indicates if course has 1 or more units
   */hasUnits:_ember['default'].computed.gt('course.unitCount',0), /**
   * @property {Boolean}
   * Computed property  to identify class is started or not
   */hasStarted:_ember['default'].computed('performance',function(){var scorePercentage=this.get('performance.score');return scorePercentage !== null && scorePercentage >= 0;}), // -------------------------------------------------------------------------
// Methods
/**
   * Selected the menu item
   * @param {string} item
   */selectMenuItem:function selectMenuItem(item){this.set('menuItem',item);},openStudentCourseReport:function openStudentCourseReport(){var controller=this;controller.set('showCourseReport',true);var params=_ember['default'].Object.create({userId:controller.get('session.userId'),courseId:controller.get('course.id'),course:controller.get('course'),performance:controller.get('performance'),isTeacher:false,isStudent:true,loadUnitsPerformance:true});controller.set('studentCourseReportContext',params);}});});