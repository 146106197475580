define('gooru-web/adapters/content/bookmark',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){ /**
 * Adapter to support the Bookmark CRUD operations in the API 3.0
 *
 * @typedef {Object} BookmarkAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v2/bookmarks', /**
   * Posts a new bookmark
   *
   * @param data bookmark data to be sent in the request body
   * @returns {Promise}
   */createBookmark:function createBookmark(data){var adapter=this;var url=this.get('namespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data.body)};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches bookmarks
   * @param resetPagination indicates if the pagination needs a reset
   * @param pagination - pagination values to list bookmarks
   * @returns {Promise}
   */fetchBookmarks:function fetchBookmarks(){var pagination=arguments.length <= 0 || arguments[0] === undefined?{}:arguments[0];var resetPagination=arguments.length <= 1 || arguments[1] === undefined?false:arguments[1];var adapter=this;var url=adapter.get('namespace');var offset=!pagination.offset || resetPagination?0:pagination.offset;var pageSize=pagination.pageSize || _gooruWebConfigConfig.DEFAULT_SEARCH_PAGE_SIZE;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{offset:offset,limit:pageSize}};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes a bookmark by id
   *
   * @param bookmarkId bookmark id to be sent
   * @returns {Promise}
   */deleteBookmark:function deleteBookmark(bookmarkId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + bookmarkId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});