define('gooru-web/components/reports/gru-class-assessment-report',['exports','ember','gooru-web/mixins/modal','gooru-web/config/config','gooru-web/models/result/assessment'],function(exports,_ember,_gooruWebMixinsModal,_gooruWebConfigConfig,_gooruWebModelsResultAssessment){ // Private variables
exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','gru-class-assessment-report'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Set a new emotion as selected and update the component appearance accordingly
     *
     * @function actions:changeView
     * @param {string} layout type @see gru-view-layout-picker
     * @returns {undefined}
     */changeView:function changeView(layout){var thumbnails=layout === _gooruWebConfigConfig.VIEW_LAYOUT_PICKER_OPTIONS.LIST;this.set('isTableView',!thumbnails);}, /**
     * When showing the question details
     * @param {string} questionId
     */viewQuestionDetail:function viewQuestionDetail(questionId){_ember['default'].Logger.debug('Class assessment report: question with ID ' + questionId + ' was selected');var question=this.get('assessment.resources').findBy('id',questionId);var modalModel={anonymous:this.get('anonymous'),assessment:this.get('assessment'),students:this.get('students'),selectedQuestion:question,reportData:this.get('reportData')};this.actions.showModal.call(this,'reports.class-assessment.gru-questions-detail',modalModel,null,'gru-questions-detail-modal',true);}, /**
     * When showing the student details
     * @param {string} studentId
     */viewAssessmentReport:function viewAssessmentReport(studentId){var _this=this;_ember['default'].Logger.debug('Class assessment report: student with ID ' + studentId + ' was selected');var userObj=this.get('students').findBy('id',studentId);if(userObj !== undefined){(function(){var reportData=_this.get('reportData');var assessment=_this.get('assessment');var resourceResults=reportData.getResultsByStudent(studentId);resourceResults.forEach(function(resourceResult){var resource=assessment.get('resources').findBy('id',resourceResult.get('resourceId'));resourceResult.set('resource',resource);});var assessmentResult=_gooruWebModelsResultAssessment['default'].create({totalAttempts:1,selectedAttempt:1,resourceResults:resourceResults,collection:assessment,isRealTime:_this.get('isRealTime'),showAttempts:_this.get('showAttempts')});var modalModel={assessmentResult:assessmentResult,profile:userObj};_this.actions.showModal.call(_this,'reports.gru-assessment-report',modalModel,null,'gru-assessment-report-modal',true);})();}}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Indicates if the report is displayed in anonymous mode
   * @property {boolean} anonymous
   */anonymous:false, /**
   * @prop { Collection } assessment - Assessment taken by a group of students
   */assessment:null, /**
   * @prop { boolean } isTableView - is the table view currently selected?
   */isTableView:true, /**
   * @property { ReportData } report data
   */reportData:null, /**
   * @prop { User[] } students - Group of students taking an assessment
   */students:null, /**
   * @prop { boolean } isRealTime - if the report is a real time report
   */isRealTime:false, /**
   * @prop { boolean } isRealTime - if the report is a real time report
   */showAttempts:false});});