define('gooru-web/models/subject',['exports','ember-data'],function(exports,_emberData){ /**
 * Model to represent the Subjects obtained from the end-point
 * @typedef {Object} Subject
 */exports['default'] = _emberData['default'].Model.extend({ /**
   * @property {number} libraryId
   */libraryId:_emberData['default'].attr('number'), /**
   * @property {string} library
   */library:_emberData['default'].attr('string'), /**
   * @property {string} label
   */label:_emberData['default'].attr('string'), /**
   * @property {string} subjectCode
   */subjectCode:_emberData['default'].attr('string')});});