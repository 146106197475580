define('gooru-web/components/tenant/gru-tenant-theme',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-tenant-theme'], // -------------------------------------------------------------------------
// Actions
actions:{}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Method
// -------------------------------------------------------------------------
// Properties
/**
   * @property {Tenant} tenant information
   */tenant:null, /**
   * @property {*} theme settings
   */theme:_ember['default'].computed.alias('tenant.theme')});});