define('gooru-web/routes/facets-search',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],_gooruWebMixinsConfiguration['default'],{queryParams:{isDeepLink:false,searchFilter:null,maxLimit:20}, /**
   * @property {Service} tenant
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------
// Hooks
beforeModel:function beforeModel(){if(this.get('session.role') === undefined){this.transitionTo('login');return;}if(this.get('session.role') === 'teacher'){return;}else {this.transitionTo('student-home');}},model:function model(params){var isDeepLink=params.isDeepLink;var searchFilter=null;if(params.searchFilter && params.searchFilter !== 'null'){searchFilter = JSON.parse(atob(params.searchFilter));} // Future reference: We could set this in the route of edit resource too but
// at the time of doing route handling for edit resource, we are doing it in
// navigator-widget-ui and not in Gooru-FE due to which configuration is
// inaccessible from widget-ui directly, so we are setting it here.
var PASSAGE_GEN_OBJ=this.get('configuration.PASSAGE_GENERATION') || {};window.localStorage.setItem('PASSAGE_GEN',JSON.stringify(PASSAGE_GEN_OBJ));return {isDeepLink:isDeepLink,searchFilter:searchFilter,maxLimit:params.maxLimit || 20,tenantSettings:this.get('tenantService').getActiveTenantSetting()};}, // ------------------------------------------------------------
// Setup controller
setupController:function setupController(controller,model){controller.set('isDeepLink',model.isDeepLink);controller.set('searchFilter',model.searchFilter);controller.set('maxLimit',model.maxLimit);controller.set('libraryLink',this.getLibraryLink(model.tenantSettings));if(!controller.get('libraryLink') && controller.get('isDeepLink') === true){_ember['default'].$('body').addClass('hide-header'); // use case : whenever we are opening contentNav inside schoology, there this flag will be on
controller.set('insideIframe',true);if(sessionStorage.getItem('app-initialization') === 'true'){sessionStorage.clear();}}else {_ember['default'].$('body').removeClass('hide-header');controller.set('insideIframe',false);}},resetController:function resetController(controller){controller.set('searchFilter',null);},getLibraryLink:function getLibraryLink(tenantSettings){if(tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.show_library_header){return tenantSettings.ui_element_visibility_settings.show_library_header;}return false;},actions:{didTransition:function didTransition(){_ember['default'].$('body').addClass('overflow-hidden');},willTransition:function willTransition(){_ember['default'].$('body').removeClass('overflow-hidden');}}});}); /* eslint-disable no-console */