define('gooru-web/controllers/guardian-invitees-approval',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * Maintains the session object.
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// Attributes
queryParams:['token'], // -------------------------------------------------------------------------
// Properties
/**
   * Token from the update email verify flow
   * @property {String}
   */token:'', /**
   * @property {Number}
   */guardianId:null, /**
   * @param {Boolean } isApproved- value used to check if the guardian invitees is approve or not  */isApproved:false, /**
   * @param {Boolean } invalidToken - value used to check if token is valid or not
   */invalidToken:false, // -------------------------------------------------------------------------
// Actions
actions:{onClose:function onClose(){this.transitionToRoute('login');}}, // -------------------------------------------------------------------------
// Methods
/**
   * Approve guardian invitees
   */approveGuardianInvitees:function approveGuardianInvitees(token){var controller=this;var guardianId=controller.get('guardianId');controller.get('profileService').approveGuardianInvitees(token).then(function(){controller.get('profileService').getGuardianInformation(guardianId).then(function(guardianInfo){controller.set('guardianName',guardianInfo.firstName);controller.set('isApproved',true);});})['catch'](function(error){if(error.status === 410){controller.set('invalidToken',true);}});}});});