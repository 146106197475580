define('gooru-web/services/api-sdk/report',['exports','ember','gooru-web/adapters/report/report','gooru-web/serializers/report/report','gooru-web/adapters/taxonomy/taxonomy','gooru-web/serializers/taxonomy/taxonomy'],function(exports,_ember,_gooruWebAdaptersReportReport,_gooruWebSerializersReportReport,_gooruWebAdaptersTaxonomyTaxonomy,_gooruWebSerializersTaxonomyTaxonomy){function _toConsumableArray(arr){if(Array.isArray(arr)){for(var i=0,arr2=Array(arr.length);i < arr.length;i++) arr2[i] = arr[i];return arr2;}else {return Array.from(arr);}}exports['default'] = _ember['default'].Service.extend({ // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('adapter',_gooruWebAdaptersReportReport['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('serializer',_gooruWebSerializersReportReport['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomyAdapter',_gooruWebAdaptersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {GoalAdapter} adapter
   */adapter:null, // -------------------------------------------------------------------------
// Methods
/**
   * @function fetchStudentsSummaryReport
   * Method to fetch students summary report data
   */fetchStudentsSummaryReport:function fetchStudentsSummaryReport(classId,params,classFramework,isDefaultShowFW){var service=this;var adapter=service.get('adapter');var serializer=service.get('serializer');return new _ember['default'].RSVP.Promise(function(resolve,reject){adapter.fetchStudentsSummaryReport(classId,params).then(function(reportData){var studentSummaryReport=serializer.normalizeStudentsSummaryReport(reportData);if(isDefaultShowFW){(function(){var studentsSummaryData=studentSummaryReport.studentsSummaryData;var studentsSummaryCompetencies=[];studentsSummaryData.map(function(studentSummary){var summaryData=studentSummary.summaryData;var studentSummaryCompetencies=[].concat(_toConsumableArray(summaryData.completedCompetencies),_toConsumableArray(summaryData.inferredCompetencies),_toConsumableArray(summaryData.inprogressCompetencies),_toConsumableArray(summaryData.masteredCompetencies),_toConsumableArray(summaryData.reInforcedCompetencies));studentsSummaryCompetencies = studentsSummaryCompetencies.concat(studentSummaryCompetencies);});if(studentsSummaryCompetencies.length){service.getcrosswalkCompetency(studentsSummaryCompetencies,classFramework).then(function(){resolve(studentSummaryReport);});}else {resolve(studentSummaryReport);}})();}else {resolve(studentSummaryReport);}},function(error){reject(error);});});}, /**
   * @function fetchStudentsWeeklySummaryReport
   * Method to fetch student's weekly summary report data
   */fetchStudentsWeeklySummaryReport:function fetchStudentsWeeklySummaryReport(classId,data,classFramework,isDefaultShowFW){var service=this;var adapter=service.get('adapter');var serializer=service.get('serializer');return new _ember['default'].RSVP.Promise(function(resolve,reject){adapter.fetchStudentsWeeklySummaryReport(classId,data).then(function(weeklyReportData){var studentSummaryReport=serializer.normalizeStudentsWeeklySummaryReport(weeklyReportData);if(isDefaultShowFW){(function(){var studentsSummaryData=studentSummaryReport.studentsSummaryData;var studentsSummaryCompetencies=[];studentsSummaryData.map(function(studentSummary){var summaryData=studentSummary.summaryData;var studentSummaryCompetencies=[].concat(_toConsumableArray(summaryData.completedCompetencies),_toConsumableArray(summaryData.inferredCompetencies),_toConsumableArray(summaryData.inprogressCompetencies),_toConsumableArray(summaryData.masteredCompetencies),_toConsumableArray(summaryData.reInforcedCompetencies));studentsSummaryCompetencies = studentsSummaryCompetencies.concat(studentSummaryCompetencies);});if(studentsSummaryCompetencies.length){service.getcrosswalkCompetency(studentsSummaryCompetencies,classFramework).then(function(){resolve(studentSummaryReport);});}else {resolve(studentSummaryReport);}})();}else {resolve(studentSummaryReport);}},function(error){reject(error);});});}, /**
   * @function fetchStudentTimespentReport
   * Method to fetch student's timespent report data
   */fetchStudentTimespentReport:function fetchStudentTimespentReport(params){var service=this;var adapter=service.get('adapter');var serializer=service.get('serializer');return new _ember['default'].RSVP.Promise(function(resolve,reject){adapter.fetchStudentTimespentReport(params).then(function(reportData){resolve(serializer.normalizeStudentsTimespentReport(reportData));},function(error){reject(error);});});}, /**
   * @function fetchStudentCompetencyReport
   * Method to fetch student's timespent report data
   */fetchStudentCompetencyReport:function fetchStudentCompetencyReport(params,classFramework,isDefaultShowFW){var service=this;var adapter=service.get('adapter');return new _ember['default'].RSVP.Promise(function(resolve,reject){adapter.fetchStudentCompetencyReport(params).then(function(reportData){var studentCompetencyReport=[].concat(_toConsumableArray(reportData.diagnostic),_toConsumableArray(reportData['new']),_toConsumableArray(reportData.reinforced));if(isDefaultShowFW && studentCompetencyReport.length){service.getcrosswalkCompetency(studentCompetencyReport,classFramework).then(function(){resolve(reportData);});}else {resolve(reportData);}},function(error){reject(error);});});}, /**
   * @function fetchStudentsTimespentSummaryreport
   * Method to fetch student's timespent report data
   */fetchStudentsTimespentSummaryreport:function fetchStudentsTimespentSummaryreport(params){var service=this;var adapter=service.get('adapter');var serializer=service.get('serializer');return new _ember['default'].RSVP.Promise(function(resolve,reject){adapter.fetchStudentsTimespentSummaryreport(params).then(function(reportData){resolve(serializer.normalizeStudentsTimespentSummaryreport(reportData));},function(error){reject(error);});});},fetchDiagnosticSummaryData:function fetchDiagnosticSummaryData(params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('adapter').fetchDiagnosticSummaryData(params).then(function(response){resolve(service.get('serializer').normalizeDiagnosticSummary(response));},function(){resolve(null);});});},fetchMinProficiencySummary:function fetchMinProficiencySummary(params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('adapter').fetchMinProficiencySummary(params).then(function(response){resolve(service.get('serializer').normalizeMinProficiency(response));},function(){resolve(null);});});}, /**
   * @function fetchStudentSelReport
   * Method to fetch students sel reports
   */fetchStudentSelReport:function fetchStudentSelReport(classId,params){var service=this;var adapter=service.get('adapter');return new _ember['default'].RSVP.Promise(function(resolve){adapter.fetchStudentSelReport(classId,params).then(function(reportData){resolve(reportData);},function(){resolve(null);});});}, /**
   * @function fetchLessonSummaryReport
   * Method to fetch lesson summary report
   */fetchLessonSummaryReport:function fetchLessonSummaryReport(classId,lessonId,params){var service=this;var adapter=service.get('adapter');return new _ember['default'].RSVP.Promise(function(resolve){adapter.fetchLessonSummaryReport(classId,lessonId,params).then(function(reportData){resolve(reportData);},function(){resolve(null);});});}, /**
   * @function fetchActivitySummaryReport
   * Method to fetch activity summary report
   */fetchActivitySummaryReport:function fetchActivitySummaryReport(classId,lessonId,activityType,params){var service=this;var adapter=service.get('adapter');return new _ember['default'].RSVP.Promise(function(resolve){adapter.fetchActivitySummaryReport(classId,lessonId,activityType,params).then(function(reportData){resolve(reportData);},function(){resolve(null);});});}, /**
   * @function fetchQuizSummaryReport
   * Method to fetch quiz summary report
   */fetchQuizSummaryReport:function fetchQuizSummaryReport(classId,lessonId,activityType,params){var service=this;var adapter=service.get('adapter');return new _ember['default'].RSVP.Promise(function(resolve){adapter.fetchQuizSummaryReport(classId,lessonId,activityType,params).then(function(reportData){resolve(reportData);},function(){resolve(null);});});}, /**
   * @function fetchFeelingSummaryReport
   * Method to fetch feeling summary report
   */fetchFeelingSummaryReport:function fetchFeelingSummaryReport(classId,lessonId,activityType,params){var service=this;var adapter=service.get('adapter');return new _ember['default'].RSVP.Promise(function(resolve){adapter.fetchFeelingSummaryReport(classId,lessonId,activityType,params).then(function(reportData){resolve(reportData);},function(){resolve(null);});});}, /**
   * @function fetchRechargeSummaryReport
   * Method to fetch recharge summary report
   */fetchRechargeSummaryReport:function fetchRechargeSummaryReport(classId,lessonId,activityType,params){var service=this;var adapter=service.get('adapter');return new _ember['default'].RSVP.Promise(function(resolve){adapter.fetchRechargeSummaryReport(classId,lessonId,activityType,params).then(function(reportData){resolve(reportData);},function(){resolve(null);});});}, /**
   * @function fetchActivityEvidenceReport
   * Method to fetch activity evidence report
   */fetchActivityEvidenceReport:function fetchActivityEvidenceReport(classId,oaId){var service=this;var adapter=service.get('adapter');return new _ember['default'].RSVP.Promise(function(resolve){adapter.fetchActivityEvidenceReport(classId,oaId).then(function(response){resolve(service.get('serializer').normalizeActivityEvidence(response));},function(){resolve(null);});});},getcrosswalkCompetency:function getcrosswalkCompetency(studentsSummaryCompetencies,classFramework){var service=this;var taxonomyCodes=[];var taxonomyCode=studentsSummaryCompetencies.map(function(summaryCompetency){return summaryCompetency.code;});taxonomyCodes = taxonomyCodes.concat(taxonomyCode);return service.get('taxonomyAdapter').crosswalkCompetency(classFramework,taxonomyCodes).then(function(crosswalkResponse){var frameworkCrossWalkComp=service.get('taxonomySerializer').normalizeCrosswalkCompetency(crosswalkResponse);studentsSummaryCompetencies.map(function(summaryCompetency){var taxonomyData=frameworkCrossWalkComp.findBy('sourceDisplayCode',summaryCompetency.code);summaryCompetency.code = taxonomyData?taxonomyData.targetDisplayCode:summaryCompetency.code;summaryCompetency.loCode = taxonomyData.targetLoCode;summaryCompetency.loName = taxonomyData.targetLoName;});});}});});