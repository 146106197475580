define('gooru-web/components/portfolio/gru-portfolio-content-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config','gooru-web/config/parse-event','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig,_gooruWebConfigParseEvent,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['portfolio','gru-portfolio-content-card'], /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on performance of the activity
onShowReport:function onShowReport(){var component=this;if(component.get('content').type === 'collection'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_PROFICIENCY_CHARTS_GRADE_RANGE_COLLECTION);}else if(component.get('content').type === 'assessment'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_PROFICIENCY_CHARTS_GRADE_RANGE_ASSESSMENT);}else if(component.get('content').type === 'offline-activity'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_PROFICIENCY_CHARTS_GRADE_RANGE_OFFLINE);}component.sendAction('onShowActivityReport',component.get('content'));}},didRender:function didRender(){this.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isOfflineActivity
   * Property to check whether it's a offlince activity or not
   */isOfflineActivity:_ember['default'].computed.equal('content.type',_gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY), /**
   * @property {Boolean} isAssessment
   * Property to check whether it's a assessment or not
   */isAssessment:_ember['default'].computed.equal('content.type',_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT), /**
   * @property {Boolean} isExternalAssessment
   * Property to check whether it's a external assessment or not
   */isExternalAssessment:_ember['default'].computed.equal('content.type',_gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT), /**
   * @property {Boolean} isCollection
   * Property to check whether it's a collection or not
   */isCollection:_ember['default'].computed.equal('content.type',_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION), /**
   * @property {Boolean} isExternalCollection
   * Property to check whether it's a external collection or not
   */isExternalCollection:_ember['default'].computed.equal('content.type',_gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_COLLECTION), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('content.taxonomy.[]',function(){var standards=this.get('content.taxonomy');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), /**
   * @property {String} contentDescription
   * Property for current item's description or first taxonomies description
   */contentDescription:_ember['default'].computed('content.learningObjective','tags',function(){var component=this;var learningObjective=component.get('content.learningObjective');var tags=component.get('tags');var contentDescription=learningObjective || (tags.length?tags.objectAt(0).get('data.title'):'');return contentDescription;}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */isDataLake:_ember['default'].computed('content.contentSource',function(){var component=this;var source=component.get('content.contentSource');var datalake=source.includes('Datalake',0);return datalake;})});});