define('gooru-web/mixins/reports/pull-up/pull-up-mixin',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Mixin.create({ //--------------------------------------------------------------------------
// Properties
/**
   * @property {String} populatedTopPos
   */populatedTopPos:'10%', /**
   * @property {Boolean} showPullUp
   */showPullUp:false, //--------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when close pullup
onClosePullUp:function onClosePullUp(){this.closePullUp();}}, //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var mixin=this;var populatedTopPos=mixin.get('populatedTopPos');mixin.$().animate({top:populatedTopPos},400).addClass('pull-up-open');}, /**
   * Method to close pullup
   */closePullUp:function closePullUp(){var mixin=this;mixin.$().animate({top:'100%'},400,function(){mixin.set('showPullUp',false);mixin.sendAction('onClosePullUp');}).removeClass('pull-up-open');}});});