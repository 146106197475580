define('gooru-web/routes/teacher/class/class-activities',['exports','ember','gooru-web/mixins/tenant-settings-mixin','gooru-web/config/config','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin,_gooruWebConfigConfig,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsTenantSettingsMixin['default'],_gooruWebMixinsUiHelperMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
videConferenceService:_ember['default'].inject.service('api-sdk/video-conference'), // -------------------------------------------------------------------------
// Attributes
queryParams:{videoConference:{refreshModel:true}}, // -------------------------------------------------------------------------
// Actions
actions:{didTransition:function didTransition(){this.get('controller').get('classController').fetchDcaSummaryPerformance();}}, // -------------------------------------------------------------------------
// Events
beforeModel:function beforeModel(){this.authorizeMeetingTool();},model:function model(params){var currentClass=this.modelFor('teacher.class')['class'];this.setTitle('Class Activities',currentClass.title);if(params.videoConference && this.preferredMeetingTool() === _gooruWebConfigConfig.MEETING_TOOLS.zoom){this.get('videConferenceService').fetchZoomToken().then(function(){window.close();});}else if(params.videoConference){this.get('videConferenceService').fetchConferenceToken().then(function(){window.close();});}},setupController:function setupController(controller){controller.get('classController').selectMenuItem('class-activities');controller.changeLanguage();}, // Reset controller properties
resetController:function resetController(controller){controller.resetProperties();controller.set('isShowGoLive',false);}, // -------------------------------------------------------------------------
// Methods
authorizeMeetingTool:function authorizeMeetingTool(){if(this.preferredMeetingTool() === _gooruWebConfigConfig.MEETING_TOOLS.zoom){return this.get('videConferenceService').fetchZoomToken();}return this.get('videConferenceService').fetchConferenceToken();}});});