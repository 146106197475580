define('gooru-web/routes/student/class/diagnosis-of-knowledge',['exports','ember','gooru-web/utils/utils','gooru-web/mixins/private-route-mixin','gooru-web/config/config','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebMixinsPrivateRouteMixin,_gooruWebConfigConfig,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],_gooruWebMixinsUiHelperMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Ember.Service} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'), /**
   * @type {SkylineInitialService} Service to retrieve skyline initial service
   */skylineInitialService:_ember['default'].inject.service('api-sdk/skyline-initial'), // -------------------------------------------------------------------------
// Properties
/**
   * Subject associated with the course
   * @type {String}
   */subject:_ember['default'].computed.alias('course.subject'), /**
   * Extract subject code from subject
   * @return {String}
   */subjectCode:_ember['default'].computed('subject',function(){if(this.get('subject')){return (0,_gooruWebUtilsUtils.getSubjectIdFromSubjectBucket)(this.get('subject'));}}), // -------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(){var route=this;var skylineInitialState=route.modelFor('student.class').skylineInitialState;var currentClass=route.modelFor('student.class')['class'];var classId=currentClass.get('id');return route.get('skylineInitialService').fetchState(classId).then(function(skylineInitialStateRes){skylineInitialState.set('destination',skylineInitialStateRes.get('destination'));skylineInitialState.set('context',skylineInitialStateRes.get('context'));route.set('skylineInitialState',skylineInitialState);var destination=skylineInitialState.get('destination');if(destination === _gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.courseMap){return route.transitionTo('student.class.course-map');}else if(destination === _gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.classSetupInComplete){return route.transitionTo('student.class.setup-in-complete');}else if(destination === _gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.showDirections || destination === _gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.ILPInProgress){return route.transitionTo('student.class.proficiency');}});},model:function model(){var route=this;var currentClass=route.modelFor('student.class')['class'];var studentClass=route.modelFor('student.class');_ember['default'].set(studentClass,'isDisableNavbar',true);route.setTitle('Diagnostic',currentClass.title);var course=route.modelFor('student.class').course;route.set('course',course);var subjectCode=route.get('subjectCode');var skylineInitialState=route.modelFor('student.class').skylineInitialState;var diagnosticId=skylineInitialState.get('context.diagnosticId');return _ember['default'].RSVP.hash({course:course,subject:route.get('taxonomyService').fetchSubject(subjectCode),assessment:route.get('assessmentService').readAssessment(diagnosticId),'class':currentClass});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){controller.set('class',model['class']);controller.set('course',model.course);controller.set('subject',model.subject);controller.set('assessment',model.assessment);}});});