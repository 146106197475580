define('gooru-web/services/api-sdk/scope-sequence',['exports','ember','gooru-web/adapters/scope-sequence/scope-sequence','gooru-web/serializers/scope-sequence/scope-sequence','gooru-web/adapters/taxonomy/taxonomy','gooru-web/serializers/taxonomy/taxonomy'],function(exports,_ember,_gooruWebAdaptersScopeSequenceScopeSequence,_gooruWebSerializersScopeSequenceScopeSequence,_gooruWebAdaptersTaxonomyTaxonomy,_gooruWebSerializersTaxonomyTaxonomy){ /**
 * Service to support the scope and sequence listing
 *
 * @typedef {Object} ScopeAndSequenceService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {ScopeAndSequenceAdapter} scopeAndSequenceAdapter
   */scopeAndSequenceAdapter:null, /**
   * @property {ScopeAndSequenceSerializer} scopeAndSequenceSerializer
   */scopeAndSequenceSerializer:null,init:function init(){this._super.apply(this,arguments);this.set('scopeAndSequenceAdapter',_gooruWebAdaptersScopeSequenceScopeSequence['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('scopeAndSequenceSerializer',_gooruWebSerializersScopeSequenceScopeSequence['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomyAdapter',_gooruWebAdaptersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * @param {Object} params
   * @return {Array} fetchScopeAndSequence
   */fetchScopeAndSequence:function fetchScopeAndSequence(params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchScopeAndSequence(params).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeScopeAndSequence(response));},reject);});}, /**
   * @param {Object} params
   * @return {Array} fetchScopeAndSequence
   */fetchModulesByScopeId:function fetchModulesByScopeId(pathParams){var params=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchModulesByScopeId(pathParams,params).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeScopeAndSequenceModules(response));},reject);});}, /**
   * @param {Object} params
   * @return {Array} fetchScopeAndSequence
   */fetchTopicsByModule:function fetchTopicsByModule(pathParams){var params=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchTopicsByModule(pathParams,params).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeScopeAndSequenceTopics(response));},reject);});}, /**
   * @param {Object} params
   * @return {Array} fetchScopeAndSequence
   */fetchCompeteciesByTopics:function fetchCompeteciesByTopics(pathParams){var params=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchCompeteciesByTopics(pathParams,params).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeCompetencies(response));},reject);});}, /**
   * @param {Object} params
   * @return {Array} fetchContentSources
   */fetchContentSources:function fetchContentSources(){var params=arguments.length <= 0 || arguments[0] === undefined?{}:arguments[0];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchContentSources(params).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeContentSource(response));},reject);});}, /**
   * @param {Object} pathParams
   * @return {Array} fetchDomainsByGrade
   */fetchDomainsByGrade:function fetchDomainsByGrade(pathParams){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchDomainsByGrade(pathParams).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeGradeDomains(response));},reject);});}, /**
   * @param {Object} pathParams
   * @return {Array} fetchTopicsByDomain
   */fetchTopicsByDomain:function fetchTopicsByDomain(pathParams){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchTopicsByDomain(pathParams).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeDomainTopics(response));},reject);});}, /**
   * @param {Object} pathParams
   * @return {Array} fetchCompetencyByDomainTopics
   */fetchCompetenciesByDomainTopic:function fetchCompetenciesByDomainTopic(pathParams,isDefaultShowFW,classFramework){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchCompetenciesByDomainTopic(pathParams).then(function(response){var competencies=service.get('scopeAndSequenceSerializer').normalizeTopicCompetencies(response);var taxonomyIds=[];var taxonomyId=competencies.map(function(competency){return competency.id;});taxonomyIds = taxonomyIds.concat(taxonomyId);if(taxonomyIds.length){service.getcrosswalkCompetency(competencies,classFramework,taxonomyIds).then(function(){return resolve(competencies);});}else {return resolve(competencies);}},reject);});}, /**
   * @param {Object} pathParams
   * @return {Array} fetchCompetencyByGrade
   */fetchCompetenciesByGrade:function fetchCompetenciesByGrade(pathParams){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchCompetenciesByGrade(pathParams).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeGradeCompetencies(response));},reject);});},activityStatus:function activityStatus(params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return service.get('scopeAndSequenceAdapter').fetchActivityStatus(params).then(function(response){return resolve(service.get('scopeAndSequenceSerializer').normalizeFetchStatus(response));},reject);});},getcrosswalkCompetency:function getcrosswalkCompetency(competencies,classFramework,taxonomyIds){var service=this;return service.get('taxonomyAdapter').crosswalkCompetency(classFramework,taxonomyIds).then(function(crosswalkResponse){var frameworkCrossWalkComp=service.get('taxonomySerializer').normalizeCrosswalkCompetency(crosswalkResponse);competencies.map(function(competency){var taxonomyData=frameworkCrossWalkComp.findBy('sourceDisplayCode',competency.code);if(taxonomyData){competency.code = taxonomyData.targetDisplayCode;competency.loCode = taxonomyData.targetLoCode;competency.loName = taxonomyData.targetLoName;}});});}});});