define('gooru-web/components/cards/gru-teacher-incomplete-card',['exports','ember','gooru-web/config/parse-event','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebConfigParseEvent,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ /**
   * Question of this FRQ grade item.
   * @type {Object}
   */'class':_ember['default'].computed.alias('incompleteClass'), /**
   * class dependency injection
   * @requires service:api-sdk/class
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), /**
   * @property {JSON}
   * Property to store last accessed class data
   */course:null, /**
   * @property {Boolean}
   * property to checked/unchecked to view part
   */checked:false, /**
   * @property {Boolean} isBottomPanelExpanded
   * Property to check whether the bottom panel is in expanded state or not
   */isBottomPanelExpanded:false, /**
   * @property {Array} levelsList Holding level names
   */ /**
   * Dynamic random images for thumbnail
   */DynamicImage:['assets/gooru/bg-image.png','assets/gooru/bg-image1.png','assets/gooru/bg-image2.png','assets/gooru/bg-image3.png','assets/gooru/bg-image4.png'],didInsertElement:function didInsertElement(){var _this=this;this.get('incompleteClass.rosterData').map(function(data){_this.set('lower_roster_grade',data.lower_roster_grade);if(data.subjects){data.subjects.map(function(gradeData){if(gradeData){_this.set('gradename',gradeData.grade_name);_this.set('fw_code',gradeData.fw_code);_this.set('code',gradeData.code);_this.set('label',gradeData.label);_this.set('subjects',gradeData);_this.set('grade_id',gradeData.grade_id);}});}});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when subject option clicked
     */handleClick:function handleClick(){this.set('checked',!this.get('checked'));this.toggleProperty('isBottomPanelExpanded');}, /**
     * Action triggered when delete classroom option clicked
     */deleteClick:function deleteClick(){this.toggleProperty('isBottomPanelExpanded');},updatePerference:function updatePerference(classId,subject,framework){var _this2=this;var selectedGrade=this.get('grade_id');if(selectedGrade){var classData={grade_id:selectedGrade,fw:framework,subject:subject,class_id:classId};var classDataArr=_ember['default'].A([]);classDataArr.push(classData);var data={data:classDataArr};this.get('classService').triggerAutoClassSetup(data).then(function(){_this2.sendAction('onDeleteClass',_this2.get('class'));});}}, /*** Triggered when a delete class option is selected */deleteClasses:function deleteClasses(){var controller=this;var classId=controller.get('class.id');controller.get('classService').deleteClass(classId);var context={classId:controller.get('class.id')};controller.sendAction('onDeleteClass',controller.get('class'));this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.DELETE_CLASS_ROOM,context);}}, /**
   * @property {Boolean} cardBackgroundImage
   * Property help to maintain card images
   */cardBackgroundImage:_ember['default'].computed('course',function(){var RandomImgArray=this.get('DynamicImage');var appRootPath=this.get('appRootPath');var coverImage=this.get('class.coverImage');var thumbnail=coverImage?coverImage:this.get('course.thumbnailUrl');var item=Math.floor(Math.random() * RandomImgArray.length);var thumbnailImage=appRootPath + RandomImgArray[item];if(thumbnail){thumbnailImage = thumbnail === '/' + _gooruWebConfigConfig.DEFAULT_IMAGES.COURSE?appRootPath + RandomImgArray[item]:thumbnail;}return thumbnailImage;})});});