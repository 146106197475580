define('gooru-web/components/gru-study-timer',['exports','ember','gooru-web/config/config','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Service
/**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-study-timer resource-child'],classNameBindings:['isExpanded:expanded','isShowPullup:show-timer-popup','isPlayer:isPlayer'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this._super.apply(this,arguments);var component=this;var collection=component.get('collection');if(collection){component.set('intervalObject',component.readingTimer());$(document).on('visibilitychange',function(){if(document.hidden){clearInterval(component.get('intervalObject'));}else {component.set('intervalObject',component.readingTimer());}});}component.calculateContentTimespent();}, /**
   * @function didDestroyElement
   * Method to destroy keypress
   */didDestroyElement:function didDestroyElement(){clearInterval(this.get('intervalObject'));}, /**
   * Observe the data change
   */timespentObserver:_ember['default'].observer('studentTimespentData',function(){var component=this;var collection=component.get('collection');component.set('totalStudyTime',null);component.set('totalAssessmentTimespent',null);component.set('totalCollectionTimespent',null);component.calculateContentTimespent();if(collection){component.set('intervalObject',component.readingTimer());$(document).on('visibilitychange',function(){if(document.hidden){clearInterval(component.get('intervalObject'));}else {component.set('intervalObject',component.readingTimer());}});}}), // -------------------------------------------------------------------------
// Actions
actions:{timerExpanded:function timerExpanded(show){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_STUDENT_STUDY_TIMER);this.set('isExpanded',!show);},closeStudyTimer:function closeStudyTimer(){this.sendAction('showTimer');}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean}
   */isExpanded:false,totalStudyTime:null,totalCollectionTimespent:null,totalAssessmentTimespent:null,isBulr:false,intervalObject:null, // -------------------------------------------------------------------------
// Methods
/**
   * @function calculateContentTimespent
   * Method to calculate content timespent
   */calculateContentTimespent:function calculateContentTimespent(){var component=this;var studentTimespentData=component.get('studentTimespentData') || [];if(!component.isDestroyed){studentTimespentData.map(function(reportData){var timespentData=reportData.get('data');timespentData.map(function(data){var totalTimespent=component.get('totalStudyTime') + data.get('totalTimespent');component.set('totalStudyTime',totalTimespent);if(data.get('format') === _gooruWebConfigConfig.CONTENT_TYPES.COLLECTION || data.get('format') === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_COLLECTION){var collectionTimespent=component.get('totalCollectionTimespent') + data.get('totalTimespent');component.set('totalCollectionTimespent',collectionTimespent);}else if(data.get('format') === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT || data.get('format') === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT){var assessmentTimespent=component.get('totalAssessmentTimespent') + data.get('totalTimespent');component.set('totalAssessmentTimespent',assessmentTimespent);}});});}},readingTimer:function readingTimer(){var component=this;var collection=component.get('collection');clearInterval(component.get('intervalObject'));return setInterval(function(){if(!component.isDestroyed){component.incrementProperty('totalStudyTime',1000);if(collection.get('isCollection')){component.incrementProperty('totalCollectionTimespent',1000);}else {component.incrementProperty('totalAssessmentTimespent',1000);}}},1000);}});});