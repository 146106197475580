define('gooru-web/components/content/questions/answers/gru-open-ended',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){ /**
 *
 * Component for building an open-ended question
 *
 * @module
 * @augments Ember/Component
 *
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-open-ended'], // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var answers=this.get('answers');if(!answers || !answers.length){var answer=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,isCorrect:true,type:'text'});this.get('answers').pushObject(answer);}},didUpdate:function didUpdate(){this.$('.text-area-container textarea').trigger('blur');}, // -------------------------------------------------------------------------
// Properties
/**
   * Question answer
   */answers:null});});