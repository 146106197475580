define('gooru-web/components/reports/assessment/questions/gru-multiple-answer',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * Multiple answer
 *
 * Component responsible for show the multiple answer, which option is selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-multiple-answer'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
answers:_ember['default'].computed('question.answerObject.[]',function(){var component=this;var question=component.get('question');var questionUtil=component.getQuestionUtil(question);var userAnswers=component.get('userAnswer') || [];if(component.get('showCorrect')){userAnswers = questionUtil.getCorrectAnswer();}var answers=question.get('answers');return answers.map(function(answer){var userAnswer=userAnswers.filterBy('id',answer.get('id'));var correct=userAnswer.length?questionUtil.isAnswerChoiceCorrect(userAnswer.get('firstObject')):false;return {text:answer.get('text'),selected:userAnswer.length?userAnswer.get('firstObject.selection'):false,correct:correct};});}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});