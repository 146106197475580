define('gooru-web/components/reports/pull-up/likert-user-report',['exports','ember','gooru-web/mixins/configuration','gooru-web/utils/math'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebUtilsMath){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','backdrop-pull-ups','pull-up-question-report'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user invoke the pull up.
     **/onPullUpClose:function onPullUpClose(closeAll){this.closePullUp(closeAll);},reportClose:function reportClose(){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);});}}, /**
   * Function to triggered once when the component element is first rendered.
   */didInsertElement:function didInsertElement(){this.openPullUp();this.initialize();}, // -------------------------------------------------------------------------
// Properties
/**
   * Maintains context data
   * @type {Object}
   */context:null, /**
   * ClassId belongs to this collection report.
   * @type {String}
   */classId:_ember['default'].computed.alias('context.classId'), /**
   * CourseId belongs to this collection report.
   * @type {String}
   */courseId:_ember['default'].computed.alias('context.courseId'), /**
   * Unit belongs to this collection report.
   * @type {String}
   */unit:_ember['default'].computed.alias('context.unit'), /**
   * Lesson belongs to this question report.
   * @type {[type]}
   */lesson:_ember['default'].computed.alias('context.lesson'), /**
   * Collection belongs to this question report.
   * @type {Object}
   */collection:_ember['default'].computed.alias('context.collection'), /**
   * collectionId of this  question report.
   * @type {String}
   */collectionId:_ember['default'].computed.alias('context.collection.id'), /**
   * Propery to hide the default pullup.
   * @property {showPullUp}
   */showPullUp:false, /**
   * List of class members
   * @type {Object}
   */classMembers:_ember['default'].computed.alias('context.classMembers'), /**
   * It maintains the state of loading
   * @type {Boolean}
   */isLoading:false,initialize:function initialize(){var component=this;var resultArray=[];var scalePoints=component.get('scalePoint.questionScalePoints');resultArray.scalePoints = scalePoints;scalePoints.map(function(dataSet){var userLength=dataSet.userInfo.length;var userPercentage=(0,_gooruWebUtilsMath.roundFloat)(userLength / component.get('classMembers').length * 100);dataSet.set('userPercentage',userPercentage);});component.set('userScalePoints',scalePoints);},openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(closeAll){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);if(closeAll){component.sendAction('onClosePullUp');}});}});});