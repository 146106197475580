define('gooru-web/controllers/student-independent-learning',['exports','ember','gooru-web/utils/utils','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
applicationController:_ember['default'].inject.controller('application'), /**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
init:function init(){var localStorage=this.get('applicationController').getLocalStorage();var userId=this.get('session.userId');var localStorageLogins=userId + '_logins';var loginCount=localStorage.getItem(localStorageLogins);if(loginCount){this.set('loginCount',+loginCount);}}, /**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){var item=this.get('menuItem');this.selectItem(item);}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Class[]}
   */activeClasses:_ember['default'].computed('applicationController.myClasses.classes.[]',function(){return this.get('applicationController.myClasses').getStudentActiveClasses(this.get('profile.id'));}), /**
   * @property {Profile}
   */profile:_ember['default'].computed.alias('applicationController.profile'), /**
   * @property {Number} Total of joined classes
   */totalJoinedClasses:_ember['default'].computed.alias('activeClasses.length'), /*
   * @property {Array[]} - featuredCourses
   */featuredCourses:null, /**
   * @property {Number} - Amount of logins by the user
   */loginCount:null, /*
   * @property {Array[]} - bookmarks
   */bookmarks:null, /**
   * @property {Number} firstRowLimit of bookmarks in the planel
   */firstRowLimit:9, /**
   * The menuItem selected
   * @property {String}
   */menuItem:_ember['default'].computed(function(){var currentPage=(0,_gooruWebUtilsUtils.getCurrentPage)();if(currentPage !== 'bookmarks'){return 'current-study';}return currentPage;}), /**
   * help to handled featured course
   * @property {boolean}
   */isShowFeaturedCourses:_ember['default'].computed.alias('configuration.GRU_FEATURE_FLAG.isShowFeaturedCourses'), // -------------------------------------------------------------------------
// Methods
/**
   * Selected the menu item
   * @param {string} item
   */selectMenuItem:function selectMenuItem(item){this.set('menuItem',item);}});});