define('gooru-web/components/content/modals/gru-assessment-remix',['exports','ember','gooru-web/components/content/modals/gru-base-remix'],function(exports,_ember,_gooruWebComponentsContentModalsGruBaseRemix){exports['default'] = _gooruWebComponentsContentModalsGruBaseRemix['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Assessment service API SDK
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'),lessonService:_ember['default'].inject.service('api-sdk/lesson'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-assessment-remix'],isCollectionType:_ember['default'].computed('model',function(){return this.get('model.content.type') === 'assessments-external';}), // -------------------------------------------------------------------------
// Actions
copyContent:function copyContent(assessment){this.set('isLoading',true);var type=this.get('isCollectionType')?'assessments-external':'assessments';return this.get('assessmentService').copyAssessment(assessment.get('id'),type);},updateContent:function updateContent(assessment){var component=this;var type=this.get('isCollectionType')?'assessments-external':'assessments';return component.get('assessmentService').updateAssessmentTitle(assessment.get('id'),assessment.get('title'),type).then(function(){var courseId=component.get('courseId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var assessmentId=component.get('contentModel.id');var isCollection=component.get('isCollection');return lessonId?component.get('lessonService').associateAssessmentOrCollectionToLesson(courseId,unitId,lessonId,assessmentId,isCollection):_ember['default'].RSVP.resolve();});},showSuccessNotification:function showSuccessNotification(assessment){var component=this;var successMsg=component.get('i18n').t('common.remix-assessment-success',{assessmentTitle:assessment.get('title')});var assessmentEditUrl=component.get('router').generate(assessment && assessment.type === 'assessments-external'?'content.external-assessments.edit':'content.assessments.edit',assessment.get('id'));var edit=component.get('i18n').t('common.edit');component.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + assessmentEditUrl + '?editingContent=true' + '">' + edit + '</a>');},showFailureNotification:function showFailureNotification(){var message=this.get('i18n').t('common.errors.assessment-not-copied').string;this.get('notifications').error(message);},init:function init(){this._super.apply(this,arguments);this.set('courseId',this.get('model.courseId'));this.set('unitId',this.get('model.unitId'));this.set('lessonId',this.get('model.lessonId'));this.set('isCollection',this.get('model.isCollection'));}, /**
   * Disable remix button once user clicked it
   */isLoading:false});});