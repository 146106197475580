define('gooru-web/components/ps-dropdown',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // The array of items for the dropdown
items:_ember['default'].A([]), // The property of each item that should be displayed in the dropdown
itemLabelProperty:'display_name', // The property of each item that should be used as its value
itemValueProperty:'id', // The placeholder text for the dropdown when no items are selected
placeholder:'Select an item', // Whether the dropdown can have multiSelect items selected
multiSelect:false, // Whether the dropdown is required
required:false, // The property of each item that should be displayed in the dropdown
requiredMessage:'Please select an item.', // Whether the dropdown is disabled
disabled:false, // Whether the dropdown using translation
transKey:null, // The selected item or items (depending on whether multiple items can be selected)
selectedItems:_ember['default'].computed('items.@each.selected',function(){if(this.get('items')){return this.get('items').filterBy('selected',true);}return;}), // The text to display in the dropdown button for the selected item or items
selectedText:_ember['default'].computed('selectedItems.[]','itemLabelProperty','placeholder',function(){var selectedItems=this.get('selectedItems');var itemLabelProperty=this.get('itemLabelProperty');var placeholder=this.get('placeholder');if(selectedItems && selectedItems.length > 0){if(this.get('multiSelect')){ //return selectedItems.mapBy(itemLabelProperty).join(', ');
return selectedItems;}else {return selectedItems.get('firstObject').get(itemLabelProperty);}}else {return placeholder;}}), // The initially selected items
initialSelectedItems:[], // Set initially selected items
didReceiveAttrs:function didReceiveAttrs(){this._super.apply(this,arguments);var initialSelectedItems=this.get('initialSelectedItems');var items=this.get('items');var key=this.get('itemValueProperty'); // const placeholder = this.get('placeholder');
// // Add an empty item at the beginning if multiSelect is false
// if (!this.get('multiSelect')) {
//   items = items || Ember.A([]); // Create an empty array if items is undefined
//   items.unshift(Ember.Object.create({
//     [this.get('itemLabelProperty')]: placeholder,
//     [this.get('itemValueProperty')]: '',
//     selected: false
//   }));
// }
if(initialSelectedItems && initialSelectedItems.length > 0 && items){items.forEach(function(item){if(initialSelectedItems.includes(item) || initialSelectedItems.includes(item.get(key))){item.set('selected',true);}});}},isMenuVisible:false,didInsertElement:function didInsertElement(){this._super.apply(this,arguments); // Get references to the button and dropdown menu elements
var button=this.element.querySelector('.dropdown-toggle');var dropdownMenu=this.element.querySelector('.dropdown-menu'); // Add click event listener to the button
button.addEventListener('click',function(){ // Show or hide the dropdown menu based on its current state
if(dropdownMenu.style.display === 'block'){dropdownMenu.style.display = 'none';}else {dropdownMenu.style.display = 'block';}});if(!this.get('multiSelect')){dropdownMenu.addEventListener('click',function(){ // Show or hide the dropdown menu based on its current state
if(dropdownMenu.style.display === 'block'){dropdownMenu.style.display = 'none';}else {dropdownMenu.style.display = 'block';}});} // Add click event listener to the window object
window.addEventListener('click',function(event){ // Check if the click event occurred outside the dropdown menu
if(!dropdownMenu.contains(event.target) && !button.contains(event.target)){dropdownMenu.style.display = 'none';}});},actions:{toggleMenu:function toggleMenu(){this.toggleProperty('isMenuVisible');}, // Called when an item is selected
itemSelected:function itemSelected(item){if(!this.get('multiSelect')){ // If only one item can be selected, deselect all other items first
this.get('items').setEach('selected',false);} // Toggle the selected state of the item
item.toggleProperty('selected'); // Call the updateSelectionAction action with the selected item's ID
var updateSelectionAction=this.get('updateSelection');if(updateSelectionAction){var selectedItems=this.get('selectedItems');if(this.get('multiSelect')){updateSelectionAction(selectedItems);}else {updateSelectionAction(selectedItems.get('firstObject'));}}}, // remove item from selectedItems onclick of pills cross
removeItem:function removeItem(item){var removeSelection=this.get('removeSelection');this.get('selectedItems').removeObject(item);this.get('items').findBy('id',item.get('id')).set('selected',false);removeSelection(item);}}});});