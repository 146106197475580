define('gooru-web/components/proficiency/inspect/student-class-progress-report',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['individual-student-report-preview'], // -------------------------------------------------------------------------
// Dependencies
i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Date} timeSeriesStartDate
   */timeSeriesStartDate:_ember['default'].computed.alias('class.startDate'),currentDate:moment().format('MMMM DD, YYYY'),studentCompetencies:_ember['default'].computed('reportData',function(){var reportData=this.get('reportData');var competenciesData=_ember['default'].A([]);if(reportData){(function(){var masteredCompetencies=reportData.get('masteredCompetencies');var inprogressCompetencies=reportData.get('inprogressCompetencies');if((masteredCompetencies && masteredCompetencies.length) > (inprogressCompetencies && inprogressCompetencies.length)){masteredCompetencies.forEach(function(competencies,index){var competencyObj=_ember['default'].Object.create({masteredCompetencyData:competencies.status > 3?competencies.code + '-' + competencies.competencyStudentDesc:'',inprogressCompetencyData:inprogressCompetencies[index]?inprogressCompetencies[index].status === 1?inprogressCompetencies[index].code + '-' + inprogressCompetencies[index].competencyStudentDesc:'':''});competenciesData.push(competencyObj);});}else {inprogressCompetencies.forEach(function(competencies,index){var competencyObj=_ember['default'].Object.create({masteredCompetencyData:masteredCompetencies[index]?masteredCompetencies[index].status > 3?masteredCompetencies[index].code + '-' + masteredCompetencies[index].competencyStudentDesc:'':'',inprogressCompetencyData:competencies.status === 1?competencies.code + '-' + competencies.competencyStudentDesc:''});competenciesData.push(competencyObj);});}})();}return competenciesData;}), /**
   * @property {Number} maxWidth
   */maxWidth:450, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, //--------------------------------------------------------------------------
//Actions
actions:{onPrintPreview:function onPrintPreview(){var firstName=this.get('studentProfile.firstName');var lastName=this.get('studentProfile.lastName');var className=this.get('class.title');var startDate=moment(this.get('reportStartDate')).format('DD MMMM YYYY');var endDate=moment(this.get('reportEndDate')).format('DD MMMM YYYY');var tempTitle=document.title;document.title = lastName + '-' + firstName + '-' + className + '-' + startDate + ' to ' + endDate;window.print();document.title = tempTitle;},isShowLoaderSet:function isShowLoaderSet(value){this.set('isShowLoader',value);}}});});