define('gooru-web/adapters/application',['exports','ember','ember-data','gooru-web/config/environment','gooru-web/mixins/session','gooru-web/utils/endpoint-config'],function(exports,_ember,_emberData,_gooruWebConfigEnvironment,_gooruWebMixinsSession,_gooruWebUtilsEndpointConfig){var Config=_gooruWebConfigEnvironment['default']['simple-auth-custom'] || {};exports['default'] = _emberData['default'].RESTAdapter.extend(_gooruWebMixinsSession['default'],{headers:_ember['default'].computed('session.token-api3',function(){return {Authorization:'Token ' + this.get('session.token-api3')};}), /**
   * @property {string} API Key
   * @see simple-auth-custom at environment.js
   */apiKey:Config.apiKey, /**
   * This custom implementation removes the default pluralization of the type
   */pathForType:function pathForType(){return '';}, /**
   * Customizing ajax calls
   * @param url
   * @param method
   * @param hash
   * @returns {*}
   */ajax:function ajax(url,method,hash){var endpointUrl=_gooruWebUtilsEndpointConfig['default'].getEndpointUrl();return this._super('' + endpointUrl + url,method,hash);}});});