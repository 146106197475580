define('gooru-web/components/content/modals/gru-assessment-new',['exports','ember','gooru-web/components/content/modals/gru-collection-new','gooru-web/models/content/assessment','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebComponentsContentModalsGruCollectionNew,_gooruWebModelsContentAssessment,_gooruWebConfigParseEvent){exports['default'] = _gooruWebComponentsContentModalsGruCollectionNew['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {AssessmentService} Assessment service API SDK
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(),parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-assessment-new'], // -------------------------------------------------------------------------
// Actions
validate:function validate(){var assessment=this.get('assessment');return assessment.validate();},createAssessmentOrCollection:function createAssessmentOrCollection(){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CREATE_ASSESSMENT);return this.get('assessmentService').createAssessment(this.get('assessment'));},associateToLesson:function associateToLesson(courseId,unitId,lessonId,assessmentOrCollectionId){return this.get('lessonService').associateAssessmentOrCollectionToLesson(courseId,unitId,lessonId,assessmentOrCollectionId,false);},closeModal:function closeModal(assessmentId){this.set('isLoading',false);this.triggerAction({action:'closeModal'});var queryParams={queryParams:{editing:true}};this.get('router').transitionTo('content.assessments.edit',assessmentId,queryParams);},showErrorMessage:function showErrorMessage(error){_ember['default'].Logger.error(error);var message=this.get('i18n').t('common.errors.assessment-not-created').string;this.get('notifications').error(message);}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var assessment=_gooruWebModelsContentAssessment['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null,classroom_play_enabled:false});this.set('assessment',assessment);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Assessment} assessment
   */assessment:null});});