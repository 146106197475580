define('gooru-web/components/content/collections/gru-collection-list',['exports','ember','gooru-web/models/content/collection','gooru-web/mixins/content/builder','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebModelsContentCollection,_gooruWebMixinsContentBuilder,_gooruWebMixinsModal){ /**
 * Collection List
 *
 * Component responsible for listing a set of resources/questions
 *
 * @module
 * @augments content/courses/gru-accordion-course
 *
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsContentBuilder['default'],_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @requires service:api-sdk/collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @property {Service} tenant service
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','collections','gru-collection-list'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Remove collection item
     */removeCollectionItem:function removeCollectionItem(builderItem){var indexOfObject=this.get('items').find(function(object){return object.id === builderItem.id;});if(indexOfObject){this.get('items').removeObject(builderItem);}this.refreshOrderList();}, /**
     * Remix collection item
     */remixCollectionItem:function remixCollectionItem(builderItem){this.get('items').addObject(builderItem);this.refreshOrderList();}, /**
     * Save reorder collection items
     */saveCollectionItemsOrder:function saveCollectionItemsOrder(){var component=this;var orderList=component.get('orderList');if(orderList){if(this.get('isCollection')){component.get('collectionService').reorderCollection(component.get('model.id'),component.get('orderList')).then(function(){component.actions.finishSort.call(component);});}else {component.get('assessmentService').reorderAssessment(component.get('model.id'),component.get('orderList')).then(function(){component.actions.finishSort.call(component);});}}else {component.actions.finishSort.call(component);}},openQuestion:function openQuestion(){this.sendAction('updateContent');this.send('showModal','content.modals.gru-question-new',this.get('model'),null,'gru-question-new');},openResource:function openResource(){this.sendAction('updateContent');this.send('showModal','content.modals.gru-resource-new',this.get('model'),null,'gru-resource-new');}}, // -------------------------------------------------------------------------
// Events
/**
   * WillDestroyElement ember event
   */didRender:function didRender(){$('[data-toggle="tooltip"]').tooltip();}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isCollection - is this a listing for a collection or for an assessment
   */isCollection:_ember['default'].computed('model',function(){return this.get('model') instanceof _gooruWebModelsContentCollection['default'];}),isShowMiniLesson:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());return tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.show_mini_lesson_button_ca_card && tenantSettings.ui_element_visibility_settings.show_mini_lesson_button_ca_card === true;})});});