define('gooru-web/components/reports/assessment/gru-summary',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Handle event triggered by gru-bubbles
     */bubbleSelect:function bubbleSelect(bubbleOption){this.sendAction('onBubbleSelect',bubbleOption);}, /**
     * Handle event triggered by gru-bubbles
     */selectAttempt:function selectAttempt(attempt){this.set('selectedAttempt',attempt);this.sendAction('onSelectAttempt',attempt);}}, // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','gru-summary'], // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('selectedAttempt',this.get('assessmentResult.totalAttempts'));}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {boolean} areQuestionLinksHidden - Should answer links be hidden?
   */areQuestionLinksHidden:false, /**
   * @property {AssessmentResult} assessment
   */assessmentResult:null, /**
   * @property {Collection}
   */collection:_ember['default'].computed.alias('assessmentResult.collection'), /**
   * @property {number} selected attempt
   */selectedAttempt:null, /**
   * @property {boolean} is real time report
   */isRealTime:false, /**
   * @property {[]}
   */resourceLinks:_ember['default'].computed('assessmentResult.sortedResourceResults',function(){return this.getResourceLinks(this.get('assessmentResult.sortedResourceResults'));}), /**
   * @property {[]}
   */attempts:_ember['default'].computed('assessmentResult.totalAttempts',function(){return this.getAttemptList();}), /**
   * @property {boolean}showAttempts
   */showAttempts:true, /**
   * Indicates if the reaction bar is visible
   * @property {boolean}
   */showReactionBar:true, /**
   * Indicates the type of collection.
   * @return {Boolean}
   */isAssessment:_ember['default'].computed('assessmentResult.collection',function(){return this.get('assessmentResult.collection.collectionType') === 'assessment';}), /**
   * Indicates the type of collection.
   * @return {Boolean}
   */imageUrl:_ember['default'].computed('assessmentResult.collection',function(){if(this.get('assessmentResult.collection.thumbnailUrl') !== undefined){return this.get('assessmentResult.collection.thumbnailUrl');}else {return this.get('assessmentResult.collection.imageUrl');}}), /**
   * It has the total number of  question count.
   * @property {Number}
   */questionCount:0, /**
   * It has the total number of  resource count.
   * @property {Number}
   */resourceCount:0, /**
   * It has the total number of  OE question count.
   * @property {Number}
   */oeQuestionCount:0,hasOnlyOEQuestion:_ember['default'].computed('questionCount','resourceCount','oeQuestionCount',function(){return this.get('oeQuestionCount') > 0 && this.get('resourceCount') === 0 && this.get('questionCount') === 0;}),hasQuestionScore:_ember['default'].computed(function(){return this.get('assessmentResult.score');}), // -------------------------------------------------------------------------
// Methods
getAttemptList:function getAttemptList(){var attempts=[];var totalAttempts=this.get('assessmentResult.totalAttempts');for(;totalAttempts > 0;totalAttempts--) {attempts.push({label:totalAttempts,value:totalAttempts});}return attempts;}, /**
   * Convenience structure to render resource information
   * @param resourceResults
   * @returns {Array}
   */getResourceLinks:function getResourceLinks(resourceResults){return resourceResults.map(function(resourceResult,index){return _ember['default'].Object.create({label:index + 1, //using index here because the resource.order could have gaps
status:_ember['default'].get(resourceResult,'attemptStatus'),value:_ember['default'].get(resourceResult,'id')});});}});});