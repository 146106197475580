define('gooru-web/components/player/resources/gru-pdf-resource',['exports','ember','gooru-web/utils/utils','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-pdf-resource'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {Resource} the resource
   */resource:null,pdfURL:_ember['default'].computed('resource.assetUrl','resource.file',function(){var resourceUrl=this.get('resource.assetUrl');if(this.get('resource.file')){var url=URL.createObjectURL(this.get('resource.file'));return url;}var configuration=this.get('configurationService.configuration');var cdnUrl=this.get('session.cdnUrls.content');var assetUrl=(0,_gooruWebUtilsUtils.addProtocolIfNecessary)(resourceUrl,(0,_gooruWebUtilsUtils.checkDomains)(resourceUrl,cdnUrl));if(configuration.get('player.resources.pdf.googleDriveEnable') && !(0,_gooruWebUtilsUtils.checkIfIsGoogleDoc)(assetUrl)){return configuration.get('player.resources.pdf.googleDriveUrl') + assetUrl + '&embedded=true';}else {return assetUrl;}}), /**
   * @property {Number} the calculated resource content height
   */calculatedResourceContentHeight:null, /**
   * @property {string} bind the height css style for the component
   */resourceHeight:_ember['default'].computed('calculatedResourceContentHeight',function(){var height=this.get('calculatedResourceContentHeight');var heightString=height > 0?height + 'px':'100%';return _ember['default'].String.htmlSafe('height: ' + heightString);}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});