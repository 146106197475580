define('gooru-web/components/class/activities/gru-content-search-filter',['exports','ember','gooru-web/config/config','gooru-web/config/parse-event','gooru-web/mixins/configuration','gooru-web/mixins/instructional-coache-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebConfigParseEvent,_gooruWebMixinsConfiguration,_gooruWebMixinsInstructionalCoacheMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],_gooruWebMixinsInstructionalCoacheMixin['default'],{classNames:['gru-content-search-filter'], // -------------------------------------------------------------------------
// Dependencies
/**
     * @property {Service} tenant service
     */tenantService:_ember['default'].inject.service('api-sdk/tenant'),session:_ember['default'].inject.service('session'), /**
     * @property {Service} parseEvent service
     */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'),videConferenceService:_ember['default'].inject.service('api-sdk/video-conference'), // -------------------------------------------------------------------------
// Properties
/**
     * @property {Array} contentTypes
     */contentTypes:_ember['default'].computed(function(){var contentTypes=_ember['default'].A();_gooruWebConfigConfig.SEARCH_FILTER_BY_CONTENT_TYPES.forEach(function(content){contentTypes.pushObject(_ember['default'].Object.create(content));});return contentTypes.sortBy('seqId');}),isInstructionalCoache:_ember['default'].computed(function(){return this.instructionalCoache();}),primaryClass:null, /**
     * @property {Object} activeContentType with initialized content type
     */activeContentType:_ember['default'].computed(function(){return this.get('contentTypes').get(0);}),isShowVideoConference:_ember['default'].computed.alias('configuration.GRU_FEATURE_FLAG.isShowCAVideoConference'), /**
     * @property {Array} selectedFilters
     */selectedFilters:_ember['default'].A([]),searchTerms:'', /**
     * @property {boolean} isFilterApplied
     */isFilterApplied:false,isConferenceAllow:true,isMeetingPanel:false,activeVideoConference:false, // -------------------------------------------------------------------------
// Actions
actions:{ // Action trigger when click content types from the default view
onChangeContent:function onChangeContent(content){if(content.format === 'assessment'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CA_ASSESSMENT);}else if(content.format === 'collection'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CA_COLLECTION);}else if(content.format === 'offline-activity'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CA_OFFLINE_ACTIVITY);}this.set('activeContentType',content);this.get('onChangeContentType')(content);this.set('activeVideoConference',false);if(this.get('isMeetingPanel')){this.$('.create-video-conference').slideToggle();this.set('isMeetingPanel',false);this.set('activeVideoConference',false);}}, // Action trigger when toggle the filter icon
onToggleFilter:function onToggleFilter(container){var _this=this;if(container === 'create-video-conference'){this.set('activeVideoConference',true);}else {var isVideoConference=this.get('isMeetingPanel')?this.get('isMeetingPanel'):false;this.set('activeVideoConference',isVideoConference);}var controller=this;controller.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CA_VIDEO_CONFERENCE);controller.get('videConferenceService').fetchZoomToken().then(function(zoomToken){if(!zoomToken){var token=window.localStorage.getItem('user_vid_config_' + _this.get('session.userId'));if(token){_this.set('isConferenceAllow',false);}else {_this.set('isConferenceAllow',true);}}else {_this.set('isConferenceAllow',false);_this.set('hasVideoConference',true);}if(container === 'create-video-conference'){_this.set('isMeetingPanel',true);_this.$('.' + container).slideToggle(function(){if($('.' + container).is(':hidden')){controller.set('activeVideoConference',false);controller.set('isMeetingPanel',false);}});}else {_this.$('.' + container).slideToggle();}});}, // Action trigger when filter applied
onCloseFilter:function onCloseFilter(){this.$('.content-resource-filter').slideUp();}, // Action trigger when apply filter
applyFilter:function applyFilter(contentResource,isFilterApplied,additionalFilter,isCustomeFilter,enableCaBaseline){if(isCustomeFilter === undefined)isCustomeFilter = false;this.send('onCloseFilter');this.set('isFilterApplied',isFilterApplied);this.sendAction('applyFilter',contentResource,additionalFilter,this.get('activeContentType'),isCustomeFilter,enableCaBaseline);}, // Action trigger when click on search button
onSearchContent:function onSearchContent(searchTerms){this.set('searchTerms',searchTerms.trim());this.get('onSearch')(searchTerms.trim());var context={classId:this.get('primaryClass').id,courseId:this.get('primaryClass').courseId,searchText:searchTerms,additionalText:{title:this.get('primaryClass').title,grade:this.get('primaryClass').grade,startDate:this.get('primaryClass').startDate}};this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.SEARCH_CA,context);},onRefreshData:function onRefreshData(meetingInfo){var _this2=this;var controller=this;var activity={class_id:meetingInfo.class_id,dca_added_date:meetingInfo.dca_added_date,end_date:meetingInfo.end_date,title:meetingInfo.title,for_month:meetingInfo.for_month,for_year:meetingInfo.for_year};controller.get('videConferenceService').createConferenceCaCard(activity).then(function(){_this2.$('.create-video-conference').slideToggle();_this2.set('isMeetingPanel',false);controller.set('activeVideoConference',false);controller.sendAction('onRefreshData',meetingInfo);});},onDeny:function onDeny(){this.set('isConferenceAllow',false);this.set('hasVideoConference',false);this.set('activeVideoConference',false);this.$('.create-video-conference').slideToggle();},onAllow:function onAllow(){this.set('isConferenceAllow',false);this.set('hasVideoConference',true);}},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},didInsertElement:function didInsertElement(){var component=this;var contentTypes=component.get('contentTypes');this.defaultSelections(contentTypes);},defaultSelections:function defaultSelections(contentTypes){var component=this;component.get('tenantService').getActiveTenantSetting().then(function(tenantSettings){var defaultSelection=contentTypes.find(function(item){return item.format === tenantSettings.default_ca_collection_type_selection;});if(defaultSelection){component.send('onChangeContent',defaultSelection);}});}});});