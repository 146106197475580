define('gooru-web/adapters/learner/learner',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Learner CRUD operations in the API 3.0
 *
 * @typedef {Object} LearnerAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus-insights/v2', /**
   * Fetches independent learner locations
   *
   * @param {number} userId
   * @param {number} contentType - type of content to retrieve
   * @param {number} offset - for paginated listing of locations
   * @param {number} limit - number of locations to fetch
   * @returns {Promise}
   */fetchLocations:function fetchLocations(userId,contentType){var offset=arguments.length <= 2 || arguments[2] === undefined?0:arguments[2];var limit=arguments.length <= 3 || arguments[3] === undefined?20:arguments[3];var adapter=this;var url=adapter.get('namespace') + '/learner/location';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{userId:userId,contentType:contentType,offset:offset,limit:limit}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches independent learner performance
   *
   * @param {number} userId
   * @param {number} contentType - type of content to retrieve
   * @param {number} offset - for paginated listing of performance
   * @param {number} limit - number of performance to fetch
   * @returns {Promise}
   */fetchPerformance:function fetchPerformance(userId,contentType){var offset=arguments.length <= 2 || arguments[2] === undefined?0:arguments[2];var limit=arguments.length <= 3 || arguments[3] === undefined?20:arguments[3];var adapter=this;var url=adapter.get('namespace') + '/learner/performance';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{userId:userId,contentType:contentType,offset:offset,limit:limit}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches independent learner performance in unit
   *
   * @param {string} courseId
   * @param {string} unitId
   * @param {string} collectionType - type of collection to retrieve
   * @returns {Promise}
   */fetchPerformanceUnit:function fetchPerformanceUnit(courseId,unitId,collectionType){var adapter=this;var queryParams=collectionType?'collectionType=' + collectionType:'';var url=adapter.get('namespace') + '/course/' + courseId + '/unit/' + unitId + '/learner/performance?' + queryParams;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches independent learner performance in course
   *
   * @param {string} courseId
   * @param {string} collectionType - type of collection to retrieve
   * @returns {Promise}
   */fetchPerformanceCourse:function fetchPerformanceCourse(courseId,collectionType){var adapter=this;var queryParams=collectionType?'collectionType=' + collectionType:'';var url=adapter.get('namespace') + '/course/' + courseId + '/learner/performance?' + queryParams;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches independent learner performance in lesson
   *
   * @param {string} courseId
   * @param {string} unitId
   * @param {string} lessonId
   * @param {string} collectionType - type of collection to retrieve
   * @returns {Promise}
   */fetchPerformanceLesson:function fetchPerformanceLesson(courseId,unitId,lessonId,collectionType){var adapter=this;var queryParams=collectionType?'collectionType=' + collectionType:'';var url=adapter.get('namespace') + '/course/' + courseId + '/unit/' + unitId + '/lesson/' + lessonId + '/learner/performance?' + queryParams;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches independent learner performance for specific courses
   *
   * @param {number} userId
   * @param {number} courseIds
   * @returns {Promise}
   */fetchCoursesPerformance:function fetchCoursesPerformance(userId,courseIds){var adapter=this;var url=adapter.get('namespace') + '/courses/learner/performance';var options={type:'POST',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({userId:userId,courseIds:courseIds})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}, /**
   * Fetches independent learner location in course
   *
   * @param {string} courseId
   * @param {string} userId
   * @returns {Promise}
   */fetchLocationCourse:function fetchLocationCourse(courseId,userId){var adapter=this;var url=adapter.get('namespace') + '/course/' + courseId + '/user/' + userId + '/learner/current/location';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Given a Content ID, User ID and Collection Type, returns performance data of each resource/question in Content
   * @param params
   * @returns {Promise}
   */fetchCollectionPerformance:function fetchCollectionPerformance(params){var adapter=this;var contentId=params.contentId;var collectionType=params.collectionType;var unitId=params.unitId;var lessonId=params.lessonId;var userId=params.userId;var courseId=params.courseId;var sessionId=params.sessionId;var queryParams=sessionId?'sessionId=' + sessionId:courseId?'courseGooruId=' + courseId + '&unitGooruId=' + unitId + '&lessonGooruId=' + lessonId:'';var url=adapter.get('namespace') + '/' + collectionType + '/' + contentId + '/learner/' + userId + '?' + queryParams;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Given a Content ID, User ID, Unit ID, Lesson ID, Course ID and Collection Type, returns the session(s) for a specific user.
   * @param params
   * @returns {string}
   */fetchLearnerSessions:function fetchLearnerSessions(params){var adapter=this;var contentId=params.contentId;var collectionType=params.collectionType;var unitId=params.unitId;var lessonId=params.lessonId;var userId=params.userId;var courseId=params.courseId;var openSession=params.openSession;var queryParams=courseId?'userUid=' + userId + '&courseGooruId=' + courseId + '&unitGooruId=' + unitId + '&lessonGooruId=' + lessonId + '&openSession=' + openSession:'userUid=' + userId + '&openSession=' + openSession;var url=adapter.get('namespace') + '/learner/' + collectionType + '/' + contentId + '/sessions?' + queryParams;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}});});