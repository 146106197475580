define('gooru-web/routes/profile/guardian',['exports','ember','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsUiHelperMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), // -------------------------------------------------------------------------
// Methods
model:function model(){var route=this;var profile=route.modelFor('profile').profile;route.setTitle('Guardian',null,true);return route.get('profileService').getGuardianRoles().then(function(roleList){var guardianList=route.get('profileService').getGuardianList();return _ember['default'].RSVP.hash({profile:profile,roleList:roleList.relationships,guardianList:guardianList});});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){controller.set('profile',model.profile);controller.set('guardianRoles',model.roleList);controller.set('guardianList',model.guardianList.guardians);controller.resetController();controller.init();}});});