define('gooru-web/components/library/gru-browse-library',['exports','ember','gooru-web/utils/sort-featured-courses','gooru-web/utils/taxonomy'],function(exports,_ember,_gooruWebUtilsSortFeaturedCourses,_gooruWebUtilsTaxonomy){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to bookmark a course
     * @param {Course} course
     * @param {Boolean} showType
     */onBookmarkCourse:function onBookmarkCourse(course,showType){this.sendAction('onBookmarkCourse',course,showType);}, /**
     * Action triggered to open the independent player
     * @param {string} collectionId collection identifier
     */onIndependentPlayer:function onIndependentPlayer(collection){this.sendAction('onOpenIndependentPlayer',collection);}, /**
     * Action triggered to open the course player
     * @param {string} courseId course identifier
     */playCourse:function playCourse(course){this.sendAction('onOpenContentPlayer',course);}}, // -------------------------------------------------------------------------
// Attributes
classNames:['library','gru-browse-library'], /**
   * @property {Courses[]} courses to show
   */courses:null, /**
   * @property {Object[]} group courses by subject
   */formattedContent:_ember['default'].computed('courses',function(){var _this=this;return (0,_gooruWebUtilsSortFeaturedCourses.getSubjects)(this.get('courses')).map(function(subjectBucket,index){return _ember['default'].Object.create({category:(0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectBucket.subject),subject:subjectBucket.taxonomySubject,courses:(0,_gooruWebUtilsSortFeaturedCourses.sortFeaturedCourses)(_this.get('courses'))[index]});});}), /**
   * Profile information
   * @property {Profile} profile
   */profile:null, /**
   * Action to send when creating a bookmark
   */onBookmarkCourse:null, /**
   * @property {string} on independent player action
   */onOpenIndependentPlayer:null});});