define('gooru-web/routes/forgot-password',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Methods
/**
   * Set all controller properties used in the template
   * @param controller
   * @param model
   */setupController:function setupController(controller){controller.resetProperties();}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the Forgot Password Confirmation page is closed
     */close:function close(){this.transitionTo('sign-in');}}});});