define('gooru-web/components/gru-resource-results',['exports','ember','gooru-web/mixins/modal','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsModal,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-resource-results'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to play the resource/question
     */playQuestion:function playQuestion(question){var transitionRoute=question.get('format') === _gooruWebConfigConfig.CONTENT_TYPES.QUESTION?'content.questions.play':'content.resources.play';this.get('router').transitionTo(transitionRoute,question.get('id'));}, /**
     * Action triggered to remix the question
     */remixQuestion:function remixQuestion(question){var remixModel={content:question};this.send('showModal','content.modals.gru-question-remix',remixModel);}}, // -------------------------------------------------------------------------
// Events
/**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * Profile information
   * @property {Profile} profile
   */profile:null, /**
   * @property {array} Resource results for the search
   */resourceResults:null, /**
   * @property {array} Term used to search
   */term:'', /**
  * @property {boolean} isSearch component from search
  */isSearch:false});});