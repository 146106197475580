define('gooru-web/components/content/modals/gru-remove-student',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-remove-student'], // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments); // 'validator' should never be set as a param except for testing
var validator=this.get('validator');if(!validator){this.set('validator',_ember['default'].Object.create({confirm:'',check1:false,check2:false,check3:false}));}else {this.set('validator',validator);}}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     *Remove Student
     */removeStudent:function removeStudent(model){var component=this;model.deleteMethod().then(function(){model.callback.success();component.triggerAction({action:'closeModal'});})['catch'](function(error){var message=component.get('i18n').t('content.modals.remove-student.delete-error',{type:component.get('i18n').t('common.class').string.toLowerCase()}).string;component.get('notifications').error(message);_ember['default'].Logger.error(error);});}}, // -------------------------------------------------------------------------
// Properties
/**
   * This is the model used to delete.
   * @property {model}
   */model:null, /**
   * Object to control when the delete button becomes enabled
   * @property {model}
   */validator:null, /**
   * Indicate if delete button is disabled
   */isDisabled:_ember['default'].computed('validator.{confirm,check1,check2,check3}',function(){var areChecked=this.get('validator.check1') && this.get('validator.check2') && this.get('validator.check3');var isConfirm=this.get('validator.confirm').toUpperCase() === 'DELETE';return !(areChecked && isConfirm);})});});