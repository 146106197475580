define('gooru-web/components/reports/pull-up/milestone/gru-milestone-student-proficiency',['exports','ember','gooru-web/mixins/student-learner-proficiency','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsStudentLearnerProficiency,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsStudentLearnerProficiency['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // --------------------------------------------------------------------
// Attributes
classNames:['gru-milestone-student-proficiency'], /**
     * Inject the  student class parent controller.
     */currentClass:null, /**
     * @property {Array} fwCompetencies
     */fwCompetencies:_ember['default'].A(), /**
     * @property {Array} fwDomains
     */fwDomains:_ember['default'].A(), /**
     * A link to the parent class controller
     * @see controllers/class.js
     * @property {studentTimespentData}
     */studentTimespentData:_ember['default'].A(), /**
     * @property {Boolean}
     */isPublicClass:false,course:null,isShowProficiency:false, // ----------------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.loadData();}, // -------------------------------------------------------------------------
// Actions
actions:{closePullUp:function closePullUp(){var component=this;component.set('isOpenPlayer',false);},playContent:function playContent(queryParams,contentId,content){var component=this;component.set('playerUrl',component.target.get('router').generate('player',contentId,{queryParams:queryParams}));component.set('isOpenPlayer',true);component.set('playerContent',content);},onChangeToBack:function onChangeToBack(){this.set('isShowProficiency',false);}}, // ------------------------------------------------------------------
// Methods
changeLanguage:function changeLanguage(){var controller=this;var classes=controller.get('class');controller.changeLanguages(classes);}});});