define('gooru-web/serializers/portfolio',['exports','ember','gooru-web/serializers/taxonomy/taxonomy','gooru-web/serializers/analytics/analytics','gooru-web/config/question','gooru-web/config/config','gooru-web/mixins/configuration','gooru-web/utils/utils'],function(exports,_ember,_gooruWebSerializersTaxonomyTaxonomy,_gooruWebSerializersAnalyticsAnalytics,_gooruWebConfigQuestion,_gooruWebConfigConfig,_gooruWebMixinsConfiguration,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
taxonomySerializer:null,session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('analyticsSerializer',_gooruWebSerializersAnalyticsAnalytics['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, // -------------------------------------------------------------------------
// Methods
/**
   * @function serializePortfolioItems
   * @param {Object} portfolioItems
   * @param {String} contentType
   * Method to serialize portfolio activities
   */serializePortfolioItems:function serializePortfolioItems(portfolioItems,contentType){if(portfolioItems === undefined)portfolioItems = {};var serializer=this;var serializedPortfolioItems=_ember['default'].A([]);if(_ember['default'].isArray(portfolioItems.usageData) || portfolioItems.usageData){(function(){var learningActivities=portfolioItems.usageData;if(contentType === _gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY || !_ember['default'].isArray(learningActivities)){var availableSubTypes=Object.keys(learningActivities);availableSubTypes.map(function(subType){learningActivities['' + subType].map(function(learningActivity){serializedPortfolioItems['' + subType] = serializedPortfolioItems['' + subType] || _ember['default'].A([]);serializedPortfolioItems['' + subType].pushObject(serializer.serializePortfolioItem(learningActivity));});});}else {learningActivities.map(function(learningActivity){serializedPortfolioItems.pushObject(serializer.serializePortfolioItem(learningActivity));});}})();}return serializedPortfolioItems;}, /**
   * @function serializePortfolioItem
   * @param {Object} content
   * Method to serialize individual portfolio activity
   */serializePortfolioItem:function serializePortfolioItem(content){var serializer=this;var basePath=serializer.get('session.cdnUrls.content');var appRootPath=this.get('appRootPath'); //configuration appRootPath
var contentType=content.type;var serializedPortfolioItem=_ember['default'].Object.create({});if(content){var thumbnailUrl=content.thumbnail?basePath:appRootPath;var imageLoc=content.thumbnail || '';if(!content.thumbnail){imageLoc = contentType.includes(_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT)?_gooruWebConfigConfig.DEFAULT_IMAGES.ASSESSMENT:contentType === _gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY?_gooruWebConfigConfig.DEFAULT_IMAGES.OFFLINE_ACTIVITY:_gooruWebConfigConfig.DEFAULT_IMAGES.COLLECTION;}var contentScore=content.score?Math.round(content.score):content.score;serializedPortfolioItem = _ember['default'].Object.create({activityTimestamp:content.activityTimestamp,contentSource:content.contentSource,efficacy:0.5 || content.efficacy,engagement:0.5 || content.engagement,gradingStatus:content.gradingStatus,isGraded:content.graded,gutCodes:content.gutCodes,id:content.id,learningObjective:content.learningObjective,lastSessionId:content.sessionId,masterySummary:content.masterySummary,maxScore:content.maxScore,owner:serializer.normalizeOwner(content.owner),questionCount:content.questionCount,resourceCount:content.resourceCount,relevance:0.5 || content.relevance,score:contentScore,status:content.status,subType:content.subType,taskCount:content.taskCount,taxonomy:serializer.get('taxonomySerializer').normalizeTaxonomyObject(content.taxonomy),thumbnailUrl:thumbnailUrl + imageLoc,timespent:content.timeSpent || content.timespent,title:content.title,type:content.type,updatedAt:content.updatedAt});}return serializedPortfolioItem;}, /**
   * @function serializeActivityAttempts
   * @param {Object} activityAttemptsObject
   * @return {Ember.Array}
   * Method to serialize list of activity attempts
   */serializeActivityAttempts:function serializeActivityAttempts(activityAttemptsObject){var serializer=this;var activityAttempts=_ember['default'].A([]);if(activityAttemptsObject && activityAttemptsObject.usageData.length){var attemptsList=activityAttemptsObject.usageData;attemptsList.map(function(attempt){activityAttempts.pushObject(serializer.normalizeActivityAttemptObject(attempt));});}return activityAttempts;}, /**
   * @function serializeActivityPerformance
   * @param {Object} payload
   * @return {Ember.Object}
   * Method to serialize activity performance
   */serializeActivityPerformance:function serializeActivityPerformance(payload){var serializer=this;var collection=payload.collection || payload.assessment;var resources=payload.resources || payload.questions;return _ember['default'].Object.create({score:collection?collection.score:null,collectionId:collection?collection.id:null,timespent:collection?collection.timespent:null,resourceResults:resources?serializer.normalizeResourceResults(resources):[],reaction:collection?collection.reaction:null,submittedAt:collection && collection.eventTime?(0,_gooruWebUtilsUtils.toLocal)(collection.eventTime):null,type:collection?collection.type:null});}, /**
   * @function normalizeResourceResults
   * @param {Ember.Array} resourceResults
   * @return {Ember.Array}
   */normalizeResourceResults:function normalizeResourceResults(){var resourceResults=arguments.length <= 0 || arguments[0] === undefined?_ember['default'].A([]):arguments[0];var serializer=this;var normalizedResourceResults=_ember['default'].A([]);resourceResults.map(function(resourceResult){normalizedResourceResults.pushObject(serializer.normalizeResourceResult(resourceResult));});return normalizedResourceResults;}, /**
   * @function normalizeResourceResult
   * @param {Object} resourceResult
   * @return {Ember.Object}
   */normalizeResourceResult:function normalizeResourceResult(resourceResult){var _this=this;var serializer=this;var questionType=resourceResult.questionType;var questionUtil=undefined;var answerObjects=undefined;var resourceType=resourceResult.resourceType;if(resourceType === 'question'){if(Object.values(_gooruWebConfigQuestion.QUESTION_TYPES).indexOf(questionType) <= -1){questionType = (0,_gooruWebConfigQuestion.getQuestionTypeByApiType)(questionType);}answerObjects = serializer.get('analyticsSerializer').normalizeAnswerObjects(resourceResult.answerObject,questionType);questionUtil = (0,_gooruWebConfigQuestion.getQuestionUtil)(questionType).create();}var eventTime=resourceResult.eventTime?(0,_gooruWebUtilsUtils.toLocal)(resourceResult.eventTime):null;var timespent=resourceResult.timespent || resourceResult.timeSpent;var resourceItem=_ember['default'].Object.create({ //Commons fields for real time and student collection performance
resourceId:resourceResult.id,id:resourceResult.id,reaction:resourceResult.reaction,timespent:timespent,answerObject:resourceResult.answerObject,answerStatus:resourceResult.answerStatus || (!resourceResult.score && !(resourceResult.answerObject && resourceResult.answerObject.length) && !timespent?'skipped':'completed'),userAnswer:resourceType === 'question'?questionUtil.toUserAnswer(answerObjects):null,correct:resourceResult.score > 0,score:resourceResult.score,title:resourceResult.title,maxScore:resourceResult.maxScore,resourceType:resourceType,questionType:questionType,eventTime:eventTime,isGraded:resourceResult.isGraded,evidence:resourceResult.evidence});if(resourceResult.subQuestions){resourceItem.set('subQuestions',resourceResult.subQuestions.map(function(item){return _this.normalizeResourceResult(item);}));}return resourceItem;}, /**
   * @function normalizeActivityAttemptObject
   * @param {Object} attempt
   * @return {Ember.Object}
   */normalizeActivityAttemptObject:function normalizeActivityAttemptObject(attempt){return _ember['default'].Object.create({classId:attempt.classId,contentSource:attempt.contentSource,createdAt:attempt.createdAt,id:attempt.id,maxScore:attempt.maxScore,score:attempt.score,sessionId:attempt.sessionId,timespent:attempt.timespent,type:attempt.type,updatedAt:attempt.updatedAt,dcaContentId:attempt.dcaContentId || undefined,courseId:attempt.courseId || undefined,unitId:attempt.unitId || undefined,lessonId:attempt.lessonId || undefined,status:attempt.status});}, /**
   * Normalize the Read Profile endpoint response
   * @param payload is the endpoint response in JSON format
   * @returns {ProfileModel} a profile model object
   */normalizeOwner:function normalizeOwner(owner){var serializer=this;var ownerObject=_ember['default'].Object.create({id:null,firstName:null,lastName:null,username:null,fullName:null,displayName:null,thumbnailUrl:null});if(owner){var basePath=serializer.get('session.cdnUrls.user');var appRootPath=this.get('appRootPath'); //configuration appRootPath
var thumbnailUrl=owner.thumbnail?basePath + owner.thumbnail:appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.USER_PROFILE;ownerObject.setProperties({id:owner.id,firstName:owner.firstName,lastName:owner.lastName,username:owner.username,fullName:owner.lastName + ', ' + owner.firstName,displayName:owner.displayName,thumbnailUrl:thumbnailUrl});}return ownerObject;}});});