define('gooru-web/components/content/questions/answers/gru-pick-n-choose',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-pick-n-choose'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Add new answer choice
     */onAddExcercise:function onAddExcercise(){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,isCorrect:false,type:'text'});this.get('answers').pushObject(newChoice);}, /**
     * Remove existing answer
     */removeAnswer:function removeAnswer(answer){this.get('answers').removeObject(answer);},selectCorrect:function selectCorrect(answer){answer.toggleProperty('isCorrect');}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Hot Spot Text Answers
   */answers:null, /**
   * Is in edit mode
   */editMode:false, /**
   * @property {boolean}
   */disableEditorButtons:_ember['default'].computed.not('showAdvancedEditor')});});