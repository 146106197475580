define('gooru-web/components/content/modals/gru-login-prompt',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-login-prompt'],classNameBindings:['component-class'], // -------------------------------------------------------------------------
// Actions
actions:{signIn:function signIn(){this.get('router').transitionTo('sign-in');},signUp:function signUp(){this.get('router').transitionTo('sign-up');}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {?String} specific class
   */'component-class':null //Methods
});});