define('gooru-web/models/tenant/tenant',['exports','ember'],function(exports,_ember){ /**
 * Model for tenant information
 *
 * @typedef {Object} Tenant
 *
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string}
   */id:null, /**
   * @property {*} settings
   */settings:{}, /**
   * @property {*} theme settings
   */theme:_ember['default'].computed.alias('settings.theme')});});