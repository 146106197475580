define('gooru-web/components/content/questions/answers/gru-sorting',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-sorting'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Help to add new columns to the group list
     */addNewItem:function addNewItem(type){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,type:type,isCorrect:true,sequence:0});this.$('#sorting-list-data-' + type).focus();this.get('answers').pushObject(newChoice);}, /**
     * Action triggered when click delete button from the list
     */removeItem:function removeItem(answer){this.get('answers').removeObject(answer);}}, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){ // this.loadData();
if(this.get('editMode')){ // this.initialize();
}}, // -------------------------------------------------------------------------
// Properties
answers:null, /**
   * Is in edit mode
   */editMode:false, /**
   * @property { Object } answerObj holding the first answer object from answers
   */answerObj:null,softAnswers:_ember['default'].computed('answers.@each',function(){return this.get('answers').filterBy('type','soft');}),hardAnswers:_ember['default'].computed('answers.@each',function(){return this.get('answers').filterBy('type','hard');}), // -------------------------------------------------------------------------
// Methods
initialize:function initialize(){var component=this;var answers=component.get('answers');component.$(function(){component.$('.list-item-blk .answer-list-item').draggable({helper:'clone',revert:'invalid'});component.$('.list-item-blk').droppable({tolerance:'intersect',drop:function drop(event,ui){$(this).append($(ui.draggable));var answerIndex=ui.helper[0].dataset.answerIndex;var type=event.target.classList.contains('column-soft-list')?'soft':'hard';var answer=answers.get(answerIndex);answer.set('type',type);component.getSequenceItem(component.$('.column-soft-list'),component);component.getSequenceItem(component.$('.column-hard-list'),component);}});});},loadData:function loadData(){var component=this;var answers=component.get('answers');var sortAnswers=answers.sortBy('sequence');sortAnswers.forEach(function(answer){var answerIndex=answers.indexOf(answer);if(answer.type){component.$('.column-' + answer.type + '-list').append('<li class="answer-list-item" data-answer-index=' + answerIndex + '> ' + answer.text + '</li>');}});},getSequenceItem:function getSequenceItem(_this,component){var answers=component.get('answers');var elementList=_this.children();elementList.each(function(index,el){var answerIndex=el.dataset.answerIndex;var answer=answers.get(answerIndex);answer.set('sequence',index);});}});});