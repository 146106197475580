define('gooru-web/controllers/reports/study-student-collection',['exports','ember','gooru-web/controllers/reports/student-collection','gooru-web/config/config','gooru-web/utils/taxonomy','gooru-web/mixins/study-player','gooru-web/mixins/activity-feedback-mixin','gooru-web/mixins/tenant-settings-mixin','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/utils/utils','gooru-web/mixins/lti-event/lti-event-mixin'],function(exports,_ember,_gooruWebControllersReportsStudentCollection,_gooruWebConfigConfig,_gooruWebUtilsTaxonomy,_gooruWebMixinsStudyPlayer,_gooruWebMixinsActivityFeedbackMixin,_gooruWebMixinsTenantSettingsMixin,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebUtilsUtils,_gooruWebMixinsLtiEventLtiEventMixin){ /**
 *
 * Controls the access to the analytics data for a
 * student related to a collection of resources
 *
 */exports['default'] = _gooruWebControllersReportsStudentCollection['default'].extend(_gooruWebMixinsStudyPlayer['default'],_gooruWebMixinsActivityFeedbackMixin['default'],_gooruWebMixinsTenantSettingsMixin['default'],_gooruWebMixinsLtiEventLtiEventMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
     * @property {CourseMapService}
     */courseMapService:_ember['default'].inject.service('api-sdk/course-map'), /**
     * @property {NavigateMapService}
     */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), /**
     * @type {AttemptService} attemptService
     * @property {Ember.Service} Service to send attempt related events
     */quizzesAttemptService:_ember['default'].inject.service('quizzes/attempt'),session:_ember['default'].inject.service('session'), /**
     * @requires studyPlayerController
     */studyPlayerController:_ember['default'].inject.controller('study-player'), /**
     * @type {ClassService} Service to retrieve class information
     */classService:_ember['default'].inject.service('api-sdk/class'), /**
     * @dependency {i18nService} Service to retrieve translations information
     */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Actions
actions:{ /**
       * Action triggered for the next button
       */next:function next(){var controller=arguments.length <= 0 || arguments[0] === undefined?this:arguments[0];var suggestedContent=controller.get('suggestedContent');if(suggestedContent && this.get('isShowMasteryGreeting')){controller.set('isShowActivityFeedback',false);controller.set('isShowSuggestion',true);controller.set('isShowMasteryGreeting',false);}else {if(this.get('isShowMasteryGreeting')){this.getNextContent().then(function(){controller.checknPlayNext();});}else {controller.checknPlayNext();controller.set('isShowMasteryGreeting',false);}}controller.toggleScreenMode(); // });
// controller.checknPlayNext();
// controller.toggleScreenMode();
// controller.set('isShowMasteryGreeting', false);
}, /**
       * Action triggered for the next button
       */onFeedbackCapture:function onFeedbackCapture(){var controller=this;var isShowMasteryGreeting=controller.get('isShowMasteryGreeting');var userFeedback=controller.get('userCategoryFeedback');if(isShowMasteryGreeting){controller.send('onCloseMastery');}else {if(userFeedback && userFeedback.length){var learningFeedback=controller.getFeedbackObject();controller.get('activityFeedbackService').submitUserFeedback(learningFeedback).then(function(){controller.send('next');});}else {controller.send('next');}}}, // /**
//  * Action triggered for the next button
//  */
// onShowMasteryOrNext() {
//   const component = this;
//   if (component.get('isLoadProficiencyProgress')) {
//     component.set('isShowActivityFeedback', false);
//     component.set('isShowMasteryGreeting', true);
//   } else {
//     component.send('next');
//   }
// },
playSignatureAssessmentSuggestions:function playSignatureAssessmentSuggestions(){this.playSuggestedContent(this.get('mapLocation.signatureAssessmentSuggestions'));},playSignatureCollectionSuggestions:function playSignatureCollectionSuggestions(){this.playSuggestedContent(this.get('mapLocation.signatureCollectionSuggestions'));},onRedirectToProfiencyProgress:function onRedirectToProfiencyProgress(){var controller=this;controller.showStudentProficiencyProgress();controller.set('isShowMasteryGreeting',false);}, /**
       * Action triggered for the exit button
       */onExit:function onExit(rouet,id){var controller=this;var isIframeMode=controller.get('isIframeMode');if(isIframeMode){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}else {controller.transitionToRoute(rouet,id);}},showTimer:function showTimer(){this.toggleProperty('isShowStudyTimer');},showFeedbackContainer:function showFeedbackContainer(){this.toggleProperty('isShowFeedbackContainer');},onCloseSuggestion:function onCloseSuggestion(){var _this=this;this.getNextContent().then(function(){_this.set('isShowSuggestion',false);});},onContinueLessonSuggestion:function onContinueLessonSuggestion(){this.checknPlayNext();},onCloseMastery:function onCloseMastery(){var _this2=this;var controller=this;controller.getNextContent().then(function(){var suggestedContent=controller.get('suggestedContent');if(suggestedContent && _this2.get('isShowMasteryGreeting')){controller.set('isShowActivityFeedback',false);controller.set('isShowSuggestion',true);controller.set('isShowMasteryGreeting',false);}else {controller.set('isShowMasteryGreeting',false);}controller.toggleScreenMode();});}}, // -------------------------------------------------------------------------
// Properties
/**
     * @property {Course} course
     */course:null, /**
     * @property {Unit} unit
     */unit:null, /**
     * @property {Lesson} lesson
     */lesson:null, /**
     * @property {Collection} collection
     */collection:null, /**
     *signatureAssessmentType suggestion
     * @property {String} signatureAssessmentType
     */signatureAssessmentType:_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.SIGNATURE_ASSESSMENT, /**
     *signatureCollectionType suggestion
     * @property {String} signatureCollectionType
     */signatureCollectionType:_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.SIGNATURE_COLLECTION, /**
     * Indicate if show pre test suggestion
     * @property {Boolean} showSuggestion
     */showSuggestion:true, /**
     * Current map location
     * @property {MapSuggestions}
     */mapLocation:null, /**
     * Current class  assessment minScore
     * @property {integer}
     */minScore:null,isPlayedDependentLesson:false, /**
     * @property {boolean}
     */hasSignatureCollectionSuggestions:_ember['default'].computed.alias('mapLocation.hasSignatureCollectionSuggestions'), /**
     * @property {boolean}
     */hasSignatureAssessmentSuggestions:_ember['default'].computed.alias('mapLocation.hasSignatureAssessmentSuggestions'), /**
     * @property {boolean}
     */isDone:_ember['default'].computed('mapLocation.context.status',function(){return (this.get('mapLocation.context.status') || '').toLowerCase() === 'done';}),isDependentLessonSuggestion:_ember['default'].computed('mapLocation.context.status',function(){return (this.get('mapLocation.context.status') || '').toLowerCase() === 'dep-lesson-suggestion-served' && !this.get('isPlayedDependentLesson');}),nextContentType:_ember['default'].computed('mapLocation.context',function(){var context=this.get('mapLocation.context') || {};return context.itemType || '';}), /**
     * @property {pathType}
     */pathType:null, /**
     * @property {Boolean}
     * Is suggested content
     */isSuggestedContent:_ember['default'].computed('mapLocation.context',function(){var component=this;var pathType=component.get('mapLocation.context.pathType');return pathType === 'teacher' || pathType === 'system';}), /**
     * @property {boolean}
     */hasAnySuggestion:_ember['default'].computed('hasSignatureAssessmentSuggestions','hasSignatureCollectionSuggestions','showSuggestion',function(){return (this.get('hasSignatureCollectionSuggestions') || this.get('hasSignatureAssessmentSuggestions')) && this.get('showSuggestion');}), /**
     * confettiTruth  for all statisfactions
     * @property {boolean} source
     */enableConfetti:false, /**
     * Report Source Type
     * @property {String}
     */source:null, /**
     * @property {Json}
     * Computed property to store suggestedContent
     */suggestedContent:_ember['default'].computed('mapLocation.suggestions',function(){var controller=this;var suggestions=controller.get('mapLocation.suggestions');return suggestions?suggestions[0]:null;}),nextContentDetails:_ember['default'].computed('nextContent',function(){var nextContent=this.get('nextContent') || {};if(nextContent && Object.keys(nextContent).length){var basePath=this.get('session.cdnUrls.content');var thumbnail=nextContent.thumbnail?basePath + nextContent.thumbnail:null;nextContent.thumbnail = thumbnail;}return nextContent && Object.keys(nextContent).length?nextContent:null;}), /**
     * @property {Boolean} isFullScreen
     */isFullScreen:_ember['default'].computed(function(){var controller=this;var studyPlayerController=controller.get('studyPlayerController');var isFullScreen=studyPlayerController.get('isFullScreen');return isFullScreen;}), /**
     * @property {Boolean} isPremiumCourse
     */isPremiumCourse:_ember['default'].computed('course',function(){var controller=this;return controller.get('course.version') === 'premium';}), /**
     * @property {Boolean} isLoadProficiencyProgress
     */isLoadProficiencyProgress:_ember['default'].computed('attemptData.averageScore','collectionObj','isPremiumCourse','mapLocation.context','isAssessmentHasFRQ','masteryMinScore',function(){var controller=this;var averageScore=controller.get('attemptData.averageScore');var isPremiumCourse=controller.get('isPremiumCourse');var context=controller.get('mapLocation.context');var isAssessmentHasFRQ=controller.get('isAssessmentHasFRQ');var collectionObj=controller.get('collectionObj');var isTeaherSuggestion=context.get('pathType') === 'teacher';var isSignatureAssessment=context.get('itemSubType') === 'signature-assessment';return isPremiumCourse && context && context.get('itemType') === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT && collectionObj.get('gutCodes.length') && !isAssessmentHasFRQ && !isTeaherSuggestion && !isSignatureAssessment && averageScore >= controller.get('masteryMinScore');}), /**
     * @property {Boolean} isAssessmentHasFRQ
     * Property to evaluate whether the completed collection has FR Question
     */isAssessmentHasFRQ:_ember['default'].computed('collectionObj',function(){var controller=this;var questions=controller.get('collectionObj.children');var frQuesitons=questions.filter(function(question){return question.get('isOpenEnded');});return !!frQuesitons.length;}), /**
     * @property {Boolean} isShowActivityFeedback
     * Property to evaluate whether the feedback tab should shown
     */isShowActivityFeedback:false, /**
     * @property {array[]} feedbackCategory
     * store feedback category list
     */feedbackCategory:null, /**
     * @property {boolean} isShowFeedback
     * Property to show/hide feedback component
     */isShowFeedback:false, /**
     * @property {Boolean}
     */isPublicClass:_ember['default'].computed.alias('currentClass.isPublic'),isCollection:_ember['default'].computed('collection',function(){return this.get('collection.isCollection');}), /**
     * @property {boolean} isShowStudyTimer
     * Property to show/hide study timer component
     */isShowStudyTimer:true,taxonomyTags:_ember['default'].computed('nextContent',function(){var taxonomyItems=this.get('nextContent.taxonomy') || {};var standards=_ember['default'].A();var _iteratorNormalCompletion=true;var _didIteratorError=false;var _iteratorError=undefined;try{for(var _iterator=Object.keys(taxonomyItems)[Symbol.iterator](),_step;!(_iteratorNormalCompletion = (_step = _iterator.next()).done);_iteratorNormalCompletion = true) {var code=_step.value;var competency=taxonomyItems[code];competency.id = code;standards.pushObject(_ember['default'].Object.create(competency));}}catch(err) {_didIteratorError = true;_iteratorError = err;}finally {try{if(!_iteratorNormalCompletion && _iterator['return']){_iterator['return']();}}finally {if(_didIteratorError){throw _iteratorError;}}}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}),milestones:_ember['default'].A(),route0Milestone:_ember['default'].A(),milestoneTitle:_ember['default'].computed('mapLocation.context',function(){var context=this.get('mapLocation.context');var milestones=this.get('milestones');var milestoneIndex=null;if(context.milestoneId){milestoneIndex = milestones.findIndex(function(item){return item.milestone_id === context.milestoneId;}) + 1;}return context.milestoneId && context.pathType !== _gooruWebConfigConfig.PATH_TYPE.UNIT0?this.get('i18n').t('common.milestone').string + ' ' + milestoneIndex:this.get('unit.title');}),isStartNewLesson:_ember['default'].computed('mapLocation.context',function(){var context=this.get('mapLocation.context');return context.lessonId !== this.get('lesson.id');}),userLocation:null,isSuggestionReport:false,isLoading:false, // -------------------------------------------------------------------------
// Methods
init:function init(){this.set('bgUrl',this.tenantSettingBgCheck());},initializer:function initializer(){var controller=this;controller.set('isShowMasteryGreeting',_ember['default'].computed('isLoadProficiencyProgress',function(){return this.get('isLoadProficiencyProgress');}));controller.set('isShowSuggestion',_ember['default'].computed('suggestedContent',function(){return this.get('isLoadProficiencyProgress')?false:!!this.get('suggestedContent');}));if(controller.get('isLoadProficiencyProgress')){controller.set('isShowMasteryGreeting',true);}else {controller.getNextContent();}var ltiContext=this.getLTIContext();if(ltiContext && Object.keys(ltiContext).length){this.triggerAWTTokenEvent();}}, /**
     * Get next content data
     */getNextContent:function getNextContent(){var controller=this;controller.set('isLoading',true);var contextId=controller.get('contextId');var profileId=controller.get('session.userData.gooruUId');var navigateMapService=controller.get('navigateMapService');return controller.get('quizzesAttemptService').getAttemptIds(contextId,profileId).then(function(attemptIds){return !attemptIds || !attemptIds.length?{}:controller.get('quizzesAttemptService').getAttemptData(attemptIds[attemptIds.length - 1]);}).then(function(attemptData){return _ember['default'].RSVP.hash({attemptData:attemptData,mapLocationNxt:navigateMapService.getStoredNext()});}).then(function(_ref){var mapLocationNxt=_ref.mapLocationNxt;var attemptData=_ref.attemptData;if(controller.get('hasSuggestion')){mapLocationNxt.context.set('status','content-served');}if(mapLocationNxt.context.pathType === 'system'){controller.set('isSuggestionReport',true);}controller.set('isPlayedDependentLesson',(mapLocationNxt.context.status || '').toLowerCase() === _gooruWebConfigConfig.DEPENDENT_LESSON_SUGGESTION_EVENTS.served);mapLocationNxt.context.set('score',attemptData.get('averageScore'));if(mapLocationNxt.context.itemType === 'assessment'){mapLocationNxt.context.set('item',{id:mapLocationNxt.context.itemId,format:mapLocationNxt.context.itemType,resourceResults:attemptData.resourceResults});}return navigateMapService.next(mapLocationNxt.context);}).then(function(_ref2){var context=_ref2.context;var suggestions=_ref2.suggestions;var hasContent=_ref2.hasContent;var content=_ref2.content;controller.set('nextContent',content);controller.set('mapLocation.context',context);controller.set('mapLocation.suggestions',suggestions);controller.set('mapLocation.hasContent',hasContent);controller.set('userLocation',(0,_gooruWebUtilsUtils.getObjectCopy)(context));controller.set('isLoading',false);});}, /**
     * Confetti Initialize once Component Initialize
     */confettiSetup:function confettiSetup(){var controller=this;var averageScore=controller.get('attemptData.averageScore');var minScore=controller.get('minScore');var masteryMinScore=controller.get('masteryMinScore');var role=controller.get('role');var type=controller.get('type');if(role === _gooruWebConfigConfig.ROLES.STUDENT && type === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT){if(minScore && minScore <= averageScore || averageScore >= masteryMinScore){_ember['default'].run.later(function(){controller.set('enableConfetti',false);},5400);controller.set('enableConfetti',true);}}_ember['default'].run.later(function(){controller.showFeedback();},5000);}, /**
     * Show feedback tab initialize once Component Initialize
     */showFeedback:function showFeedback(){var controller=this;var isCollection=controller.get('collection.isCollection');var categoryLists=controller.get('categoryLists');var type=isCollection?_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT;var contentCategory=categoryLists.get(type + 's');if(contentCategory && contentCategory.length){controller.set('isShowActivityFeedback',true);controller.set('feedbackCategory',contentCategory.sortBy('feedbackTypeId'));controller.set('format',type);}else {controller.set('isShowActivityFeedback',false);controller.set('feedbackCategory',null);}}, /**
     * Resets to default values
     */resetValues:function resetValues(){this.setProperties({courseId:null,userId:null,role:null,contextId:null,source:null,classId:'',unitId:null,milestoneId:null,lessonId:null,collectionId:null,type:null,mapLocation:null});}, /**
     * @function toggleScreenMode
     * Method to toggle screen mode
     */toggleScreenMode:function toggleScreenMode(){var controller=this;var studyPlayerController=controller.get('studyPlayerController');studyPlayerController.toggleScreenMode();}, /**
     * @function showStudentProficiencyProgress
     * Method to redirect the student into the proficiency progress page whenever they acquired 80% or more
     */showStudentProficiencyProgress:function showStudentProficiencyProgress(){var controller=this;var contextId=controller.get('contextId');var profileId=controller.get('session.userData.gooruUId');var context=this.get('mapLocation.context');var queryParams={classId:context.get('classId'),courseId:context.get('courseId'),contextId:contextId,role:_gooruWebConfigConfig.ROLES.STUDENT,source:controller.get('source'),assessmentId:controller.get('collection.id'),collectionId:controller.get('collection.id'),milestoneId:context.get('milestoneId'),unitId:context.get('unitId'),lessonId:context.get('lessonId'),pathId:context.get('pathId') || 0,pathType:context.get('pathType') || null,isIframeMode:controller.get('isIframeMode')};controller.updateStudentMasteredCompetencies();controller.transitionToRoute('student-learner-proficiency',profileId,{queryParams:queryParams});}, /**
     * @function updateStudentMasteredCompetencies
     * Method to update the mastered competency into local storage to populate learner profile at FE
     */updateStudentMasteredCompetencies:function updateStudentMasteredCompetencies(){var controller=this;var localStorage=controller.get('navigateMapService').getLocalStorage();var storageKey=controller.get('navigateMapService').getMasteredCompetenciesKey();var storedCompetencies=localStorage.getItem(storageKey);var studentMasteredCompetencies=storedCompetencies?JSON.parse(storedCompetencies):_ember['default'].A([]);var masteredCompetencies=controller.get('collectionObj.gutCodes');masteredCompetencies.map(function(competencyCode){if(!studentMasteredCompetencies.findBy('competencyCode',competencyCode)){var domainCode=(0,_gooruWebUtilsTaxonomy.getDomainCode)(competencyCode);studentMasteredCompetencies.push({domainCode:domainCode,competencyCode:competencyCode});}});studentMasteredCompetencies.filter(function(competency){competency.isHighLight = false;});var lastMasteredCompetency=studentMasteredCompetencies.objectAt(studentMasteredCompetencies.length - 1);lastMasteredCompetency.isHighLight = true;localStorage.setItem(storageKey,JSON.stringify(studentMasteredCompetencies));}, /**
     * @function getFeedbackObject
     * Method to return feedback objective
     */getFeedbackObject:function getFeedbackObject(){var controller=this;var userId=controller.get('session.userId');var role=controller.get('session.role')?controller.get('session.role'):_gooruWebConfigConfig.ROLES.STUDENT;var userCategoryId=_gooruWebConfigConfig.FEEDBACK_USER_CATEGORY['' + role];var userFeedback=_ember['default'].A([]);var categoryLists=controller.get('userCategoryFeedback');var collection=controller.get('collection');categoryLists.map(function(category){var feedbackObj={feeback_category_id:category.categoryId};if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE){feedbackObj.user_feedback_quantitative = category.rating;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.BOTH){feedbackObj.user_feedback_qualitative = category.comments;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUALITATIVE){feedbackObj.user_feedback_qualitative = category.quality;}userFeedback.pushObject(feedbackObj);});var userFeedbackObj={content_id:collection.get('id'),content_type:collection.get('isCollection')?_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT,user_category_id:userCategoryId,user_feedbacks:userFeedback,user_id:userId};return userFeedbackObj;},triggerAWTTokenEvent:function triggerAWTTokenEvent(){var attemptData=this.get('attemptData');this.triggerAWTTokenAPI(attemptData);}});});