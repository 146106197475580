define('gooru-web/components/reports/assessment/questions/gru-words-per-minute',['exports','ember','gooru-web/mixins/reports/assessment/questions/question','gooru-web/utils/utils'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion,_gooruWebUtilsUtils){ /**
 * SERP Encoding Assessment
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-words-per-minute'], // -------------------------------------------------------------------------
// Properties
showCorrect:false, /**
   * @property {Array} answers
   * List of answers
   */answers:_ember['default'].computed.alias('answerObj'), /**
   * @property {Array} baseAnswers
   * List of base answers
   */baseAnswers:_ember['default'].computed.alias('question.answers'), /**
   * @property {Array} exemplars
   * List of question exemplars
   */exemplars:_ember['default'].computed.alias('question.exemplarDocs'),audioObject:_ember['default'].computed('answers',function(){var answers=this.get('answers');var answersDetails=answers?answers.get('firstObject'):null;answersDetails = answersDetails && answersDetails.text?JSON.parse(answersDetails.text):null;return answersDetails;}),audio:null,isPauseAudio:false,wpmCount:0,wpmAvgCount:0,selectedText:null,didInsertElement:function didInsertElement(){var component=this;var answerObject=this.get('audioObject');if(answerObject){this.set('selectedText',answerObject.selectedText);var wpmCount=answerObject.selectedText.wpmCount || 0;var speechText=answerObject.speechText.fullAudioText.split(' ').length;var avgWpmcount=answerObject.speechText.avgWordCount?answerObject.speechText.avgWordCount:0;avgWpmcount = avgWpmcount < speechText?avgWpmcount:speechText;component.set('audioDuration',(0,_gooruWebUtilsUtils.sec2time)(component.get('selectedText').audioLength / 1000 || 0));this.set('wpmAvgCount',avgWpmcount);this.setProperties({wpmCount:wpmCount});var audioUrl=answerObject.value;this.set('audio',new Audio(audioUrl));}}, //Actions
actions:{ //Action triggered when play audio
onPlayAudio:function onPlayAudio(){var component=this;var _audio=component.get('audio');_audio.play();component.set('isPauseAudio',true);_audio.onended = function(){component.set('isPauseAudio',false);};_audio.ontimeupdate = function(){component.$('.answer-container .audio-progress .progress-filling').css('width',_audio.currentTime / _audio.duration * 100 + '%');var timerEl=component.$('.wpm-timer-section .timer-count');timerEl.find('.current-count').html((0,_gooruWebUtilsUtils.sec2time)(_audio.currentTime));timerEl.find('.duration').html((0,_gooruWebUtilsUtils.sec2time)(component.get('selectedText').audioLength / 1000 || 0));};},onPauseAudio:function onPauseAudio(){var audio=this.get('audio');audio.pause();this.set('isPauseAudio',false);}}});});