define('gooru-web/components/teacher/class-atc-view',['exports','ember','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigParseEvent){ /**
 * class performance chart
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['teacher','class-atc-view'], // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click on carousel nav
     */onChangeAtcClass:function onChangeAtcClass(actionSequence){var component=this;component.set('isLoading',true);component.sendAction('onChangeAtcClass',actionSequence);}, /**
     * Action triggered when click on class properties icon
     * Page will be redirected to specific route based on item selected
     */onSelectClassItem:function onSelectClassItem(item,classId){var component=this;component.sendAction('onSelectClassItem',item,classId);}, /**
     * Action triggered when select a domain from pull up
     */onSelectDomain:function onSelectDomain(domainSet){var component=this;component.set('selectedDomain',domainSet);component.set('isShowDomainCompetencyReport',true);}, /**
     * Action triggered when close all pull ups
     */onCloseCompetencyReportPullUp:function onCloseCompetencyReportPullUp(){var component=this;component.set('isShowDomainCompetencyReport',false);component.set('isShowCompetencyReport',false);}, /**
     * Action triggered when select a competency from competency report
     */onSelectCompetency:function onSelectCompetency(competency,userId){var component=this;component.set('selectedCompetency',competency);component.set('selectedStudentUserId',userId);component.set('isShowCompetencyContentReport',true);this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_COLLECTION);},onSelectSecondaryClass:function onSelectSecondaryClass(secondaryClass){var component=this;component.sendAction('onSelectSecondaryClass',secondaryClass);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {JSON}
   * Property to hold active class data
   */classData:null, /**
   * @property {Boolean}
   * Property to enable/disable previous nav icon
   */isPreviousDisabled:_ember['default'].computed('classPosition',function(){var component=this;var classPosition=component.get('classPosition');return classPosition === 0;}), /**
   * @property {Boolean}
   * Property to enable/disable next nav icon
   */isNextDisabled:_ember['default'].computed('classPosition','totalClasses',function(){var component=this;var totalClasses=component.get('totalClasses');var classPosition=component.get('classPosition');return totalClasses === classPosition + 1;}), /**
   * @property {Array}
   * Property to hold class performance data
   */classPerformanceData:_ember['default'].computed('classData',function(){var component=this;var classPerformance=component.get('classData.performance');var score=classPerformance?classPerformance.score:0;var classPerformanceData=_ember['default'].A([{score:score}]);return classPerformanceData;}), /**
   * @property {Boolean}
   * Computed property  to identify class is started or not
   */hasStarted:_ember['default'].computed('classData',function(){var scorePercentage=this.get('classData.performance.score');return scorePercentage !== null && scorePercentage >= 0;}), /**
   * @property {Boolean}
   * Property to check whether the class mapped with a course or not
   */isStudentPerformed:_ember['default'].computed('classData',function(){var component=this;return component.get('classData.courseId') || false;}), /**
   * @property {Boolean}
   * Property to show/hide competency report pull up
   */isShowCompetencyReport:false, /**
   * @property {Boolean}
   * Property to show/hide domain competency report pull up
   */isShowDomainCompetencyReport:false, /**
   * @property {courseSubjectCode}
   * Property to fetch subject code from subject bucket
   */courseSubjectCode:_ember['default'].computed('classData',function(){var component=this;var classData=component.get('classData');return classData.courseSubjectCode;}), /**
   * @property {Boolean}
   * Property to check whether a premium course or not
   */isPremiumClass:_ember['default'].computed('classData',function(){var component=this;var classData=component.get('classData');var setting=classData.setting;return setting?setting['course.premium']:false;})});});