define('gooru-web/models/performance/class-performance',['exports','ember','ember-data','gooru-web/utils/math'],function(exports,_ember,_emberData,_gooruWebUtilsMath){ /**
 * Model that contains a class performance data.
 * @typedef {Object} ClassPerformance
 */exports['default'] = _emberData['default'].Model.extend({ /**
   * @property {StudentPerformance[]} List of StudentPerformance items
   */studentPerformanceData:_emberData['default'].hasMany('performance/student-performance',{async:false}), /**
   * @property {Number} Computed property with the average score of the whole class.
   */classAverageScore:_ember['default'].computed('studentPerformanceData',function(){var fieldName='averageScore';var studentPerformanceData=this.get('studentPerformanceData');var validStudentPerformanceData=this.filterValidStudentPerformanceData(fieldName,studentPerformanceData);return this.calculateClassAverage(fieldName,validStudentPerformanceData);}), /**
   * @property {Number} Computed property with the average time spent of the whole class.
   */classAverageTimeSpent:_ember['default'].computed('studentPerformanceData',function(){var fieldName='averageTimeSpent';var studentPerformanceData=this.get('studentPerformanceData');var validStudentPerformanceData=this.filterValidStudentPerformanceData(fieldName,studentPerformanceData);return this.calculateClassAverage(fieldName,validStudentPerformanceData);}), /**
   * @property {Number} Computed property with the summatory of completion done for the whole class.
   */classSumCompletionDone:_ember['default'].computed('studentPerformanceData',function(){var fieldName='sumCompletionDone';var studentPerformanceData=this.get('studentPerformanceData');var validStudentPerformanceData=this.filterValidStudentPerformanceData(fieldName,studentPerformanceData);return this.calculateClassSum(fieldName,validStudentPerformanceData);}), /**
   * @property {Number} Computed property with the summatory of completion total for the whole class.
   */classSumCompletionTotal:_ember['default'].computed('studentPerformanceData',function(){var fieldName='sumCompletionTotal';var studentPerformanceData=this.get('studentPerformanceData');var validStudentPerformanceData=this.filterValidStudentPerformanceData(fieldName,studentPerformanceData);return this.calculateClassSum(fieldName,validStudentPerformanceData);}), /**
   * @property {boolean}
   */hasStarted:_ember['default'].computed('studentPerformanceData',function(){return this.get('classAverageTimeSpent') >= 0 || this.get('classAverageScore') >= 0;}), /**
   * Function to calculate the average score of a unit, lesson or collection|statement
   * @param itemId
   * @returns {Number} the average score
   */calculateAverageScoreByItem:function calculateAverageScoreByItem(itemId){var studentPerformanceData=this.get('studentPerformanceData');var filteredStudentPerformanceData=this.filterStudentPerformanceDataByItem(itemId,studentPerformanceData);return this.calculateAverageByItem(itemId,'score',filteredStudentPerformanceData);}, /**
   * Function to calculate the average time spent of a unit, lesson or collection|statement
   * @param itemId
   * @returns {Number} the average time spent
   */calculateAverageTimeSpentByItem:function calculateAverageTimeSpentByItem(itemId){var studentPerformanceData=this.get('studentPerformanceData');var filteredStudentPerformanceData=this.filterStudentPerformanceDataByItem(itemId,studentPerformanceData);return this.calculateAverageByItem(itemId,'timeSpent',filteredStudentPerformanceData);}, /**
   * Function to calculate the summatory of completion done for a unit or lesson or collection|statement
   * @param itemId
   * @returns {Number} The summatory of completion done
   */calculateSumCompletionDoneByItem:function calculateSumCompletionDoneByItem(itemId){var studentPerformanceData=this.get('studentPerformanceData');var filteredStudentPerformanceData=this.filterStudentPerformanceDataByItem(itemId,studentPerformanceData);return this.calculateSumByItem(itemId,'completionDone',filteredStudentPerformanceData);}, /**
   * Function to calculate the summatory of completion total for a unit or lesson or collection|statement
   * @param itemId
   * @returns {Number} The summatory of completion total
   */calculateSumCompletionTotalByItem:function calculateSumCompletionTotalByItem(itemId){var studentPerformanceData=this.get('studentPerformanceData');var filteredStudentPerformanceData=this.filterStudentPerformanceDataByItem(itemId,studentPerformanceData);return this.calculateSumByItem(itemId,'completionTotal',filteredStudentPerformanceData);}, /**
   * Helper function to calculate the average of the specified field by unit or lesson or collection|statement
   * @param itemId the item id
   * @param fieldName the field to calculate
   * @param studentPerformanceData an array with the student performance data
   * @param doRoundValue indicates if the result should be rounded
   * @returns {number} the average value
   */calculateAverageByItem:function calculateAverageByItem(itemId,fieldName){var studentPerformanceData=arguments.length <= 2 || arguments[2] === undefined?[]:arguments[2];var doRoundValue=arguments.length <= 3 || arguments[3] === undefined?true:arguments[3];var avgValue=-1;if(studentPerformanceData && studentPerformanceData.length > 0){var counter=0;var sumValue=0;if(studentPerformanceData && studentPerformanceData.length > 0){studentPerformanceData.forEach(function(studentPerformance){if(studentPerformance.get(fieldName) !== null){var studentScore=studentPerformance.get(fieldName);if(studentScore !== undefined && !isNaN(studentScore)){sumValue += studentScore;counter += 1;}}});}if(sumValue !== null){avgValue = sumValue / counter;if(doRoundValue){avgValue = _gooruWebUtilsMath['default'].roundFloat(avgValue);}}else {avgValue = null;}}return avgValue;}, /**
   * Helper function to calculate the summatory of a specified field by unit, lesson or collection|statement.
   * @param itemId the item id
   * @param fieldName the field to calculate
   * @param studentPerformanceData an array with the student performance data
   * @param doRoundValue indicates if the result should be rounded
   * @returns {number} the summatory value
   */calculateSumByItem:function calculateSumByItem(itemId,fieldName){var studentPerformanceData=arguments.length <= 2 || arguments[2] === undefined?[]:arguments[2];var doRoundValue=arguments.length <= 3 || arguments[3] === undefined?true:arguments[3];var sumValue=0;if(studentPerformanceData && studentPerformanceData.length > 0){studentPerformanceData.forEach(function(studentPerformance){if(studentPerformance.get(fieldName) !== null){sumValue += studentPerformance.get(fieldName);}else {sumValue = null;}});if(doRoundValue){sumValue = _gooruWebUtilsMath['default'].roundFloat(sumValue);}}return sumValue;}, /**
   * Helper function to calculate the average of a specified field.
   * @param fieldName required values are score or timeSpent
   * @param studentPerformanceData an array with the student performance data
   * @returns {Number} the average value
   */calculateClassAverage:function calculateClassAverage(fieldName){var studentPerformanceData=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var classAverage=-1;if(studentPerformanceData && studentPerformanceData.length > 0){var counter=studentPerformanceData.length;var classSum=this.calculateClassSum(fieldName,studentPerformanceData);classAverage = _gooruWebUtilsMath['default'].roundFloat(classSum / counter);}return classAverage;}, /**
   * Helper function to calculate the summatory of a specified field.
   * @param fieldName required values are completionDone or completionTotal
   * @param studentPerformanceData an array with the student performance data
   * @returns {Number} the average value
   */calculateClassSum:function calculateClassSum(fieldName){var studentPerformanceData=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var sumValue=0;if(studentPerformanceData && studentPerformanceData.length > 0){studentPerformanceData.forEach(function(studentPerformance){sumValue += studentPerformance.get(fieldName);});}return sumValue;}, /**
   * Helper function to filter the student performance data for the cases where the specified field value is greater or than zero
   * @param fieldName to field to be used in the filter
   * @param studentPerformanceData an array with the student performance data
   * @returns {Array}
   */filterValidStudentPerformanceData:function filterValidStudentPerformanceData(fieldName){var studentPerformanceData=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var filteredPerformanceData=_ember['default'].A([]);if(studentPerformanceData && studentPerformanceData.length > 0){filteredPerformanceData = studentPerformanceData.filter(function(studentPerformance){return studentPerformance.get(fieldName) >= 0;});}return filteredPerformanceData;}, /**
   * Helper function to filter the all students data by a specified unit, lesson or collection|statement
   * @param itemId the unit, lesson or collection|statement ID
   * @param studentPerformanceData an array with the student performance data
   * @returns {Array}
   */filterStudentPerformanceDataByItem:function filterStudentPerformanceDataByItem(itemId){var studentPerformanceData=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var filteredPerformanceData=_ember['default'].A([]);if(studentPerformanceData && studentPerformanceData.length > 0){studentPerformanceData.forEach(function(studentPerformance){var performanceData=studentPerformance.get('performanceData').findBy('realId',itemId);if(performanceData){filteredPerformanceData.push(performanceData);}});}return filteredPerformanceData;}, /**
   * @function findNumberOfStudentsByItem
   * Method to find number of students have performed in an item
   */findNumberOfStudentsByItem:function findNumberOfStudentsByItem(itemId){var numberOfStudents=0;var studentPerformanceData=this.get('studentPerformanceData') || [];if(studentPerformanceData && studentPerformanceData.length > 0){studentPerformanceData.forEach(function(studentPerformance){var performanceData=studentPerformance.get('performanceData').findBy('realId',itemId);if(performanceData){numberOfStudents++;}});}return numberOfStudents;}});});