define('gooru-web/components/charts/gru-standard-dount',['exports','ember','d3'],function(exports,_ember,_d3){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------
// Attributes
classNames:['gru-standard-dount-chart'], // ---------------------------------------------------------
// Dependencies
// ------------------------------------------------------------
// Properties
/**
   * @property {number} width help to set dount width
   */width:300, /**
   * @property {number} height help to set dount height
   */height:300, /**
   * @property {number} innerRadius help to set dount radius
   */innerRadius:70, /**
   * @property {number} innerRadius help to set dount radius
   */outerRadius:100, /**
   * @property {number} margin help to set dount margin
   */margin:100, /**
   * @property {Arrray} chartData has data for the chart
   */data:[],totalScore:60,onWatchData:_ember['default'].observer('data',function(){this.drawChart();}), // -------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.drawChart();}, // -------------------------------------------------------------
// Methods
drawChart:function drawChart(){var component=this;var width=component.get('width');var height=component.get('height');var innerRadius=component.get('innerRadius');var outerRadius=component.get('outerRadius');var data=component.get('data');_d3['default'].select('#' + component.elementId).select('svg').remove();var svg=_d3['default'].select('#' + component.elementId).append('svg').attr('width',width).attr('height',height).append('g').attr('transform','translate(' + width / 2 + ',' + height / 2 + ')');var pie=_d3['default'].layout.pie().value(function(d){return d.score;});var g=svg.selectAll('.arc').data(pie(data)).enter().append('g').attr('class','arc');var arc=_d3['default'].svg.arc().innerRadius(innerRadius).outerRadius(outerRadius);g.append('path').attr('class',function(d){return 'path ' + d.data.className;}).attr('d',arc).attr('fill',function(d){return d.data.color;});g.append('text').attr('transform',function(d){var _d=arc.centroid(d);_d[0] *= 1.5;_d[1] *= 1.5;return 'translate(' + _d + ')';}).attr('dy','.50em').attr('class',function(d){return d.data.className;}).style('text-anchor','middle').text(function(d){if(d.data.score < 8){return '';}return d.data.score + '%';});}});});