define('gooru-web/components/reports/assessment/questions/gru-hs-image',['exports','ember','gooru-web/mixins/reports/assessment/questions/question','gooru-web/mixins/configuration','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion,_gooruWebMixinsConfiguration,_gooruWebConfigConfig){ /**
 * Hot spot image
 *
 * Component responsible for show the hot spot image, which option is selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-hs-image'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
answers:_ember['default'].computed('question.answerObject.[]','anonymous',function(){var component=this;var question=component.get('question');var questionUtil=component.getQuestionUtil(question);var userAnswers=component.get('userAnswer');var appRootPath=this.get('appRootPath'); //configuration appRootPath
if(component.get('showCorrect')){userAnswers = questionUtil.getCorrectAnswer();}var answers=question.get('answers');var anonymous=this.get('anonymous');return answers.map(function(answer){var userAnswerCorrect=false;var selected=false;if(userAnswers.includes(answer.get('id'))){userAnswerCorrect = questionUtil.isAnswerChoiceCorrect(answer.get('id'));selected = true;}var elementClass=anonymous?'anonymous':userAnswerCorrect?'correct':'incorrect';return {image:answer.get('text')?answer.get('text'):appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.QUESTION_PLACEHOLDER_IMAGE,selected:selected,'class':elementClass};});}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});}); // constants