define('gooru-web/components/charts/domain-competency-chart',['exports','ember','d3','gooru-web/utils/utils'],function(exports,_ember,_d3,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['domain-competency-chart'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;var competencyDataSet=component.get('competencies');component.drawChart(competencyDataSet);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Number}
   * Property to hold cell width
   */cellWidth:20, /**
   * @type {Number}
   * Property to hold cell height
   */cellHeight:_ember['default'].computed('competencies',function(){var component=this;var numberOfCompetencies=component.get('competencies.length');var maxChartHeight=component.get('maxChartHeight');return maxChartHeight / numberOfCompetencies;}), /**
   * @type {Number}
   * Property to hold max chart height
   */maxChartHeight:250, // -------------------------------------------------------------------------
// Methods
/**
   * @function drawChart
   * Method to draw individual domain competency chart
   */drawChart:function drawChart(competencyDataSet){var component=this;var cellWidth=component.get('cellWidth');var cellHeight=component.get('cellHeight');var numberOfCompetencies=competencyDataSet.length;var domainSeq=component.get('domainSeq');var studentSeq=component.get('studentSeq');var svgContainer=_d3['default'].select('.render-domain-competency-chart-' + domainSeq + '-' + studentSeq).append('svg').attr('width',20).attr('height',cellHeight * numberOfCompetencies);var cellContainer=svgContainer.append('g').attr('id','cell-container');var cells=cellContainer.selectAll('.competency').data(competencyDataSet);cells.enter().append('rect').attr('class',function(d){return 'competency-' + d.competencySeq;}).attr('id','competency-cell').attr('width',cellWidth).attr('height',cellHeight).attr('x',0).attr('y',function(d){var competencySeq=d.competencySeq;return (competencySeq - 1) * cellHeight;}).style('fill',function(d){var competencyStatus=d.status;return _gooruWebUtilsUtils.colorsBasedOnStatus['' + competencyStatus];});cells.exit().remove();}});});