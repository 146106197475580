define('gooru-web/services/api-sdk/rescope',['exports','ember','gooru-web/serializers/rescope/rescope','gooru-web/adapters/rescope/rescope'],function(exports,_ember,_gooruWebSerializersRescopeRescope,_gooruWebAdaptersRescopeRescope){exports['default'] = _ember['default'].Service.extend({ /**
   * @property {RescopeSerializer} rescopeSerializer
   */rescopeSerializer:null, /**
   * @property {RescopeAdapter} rescopeAdapter
   */rescopeAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('rescopeSerializer',_gooruWebSerializersRescopeRescope['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('rescopeAdapter',_gooruWebAdaptersRescopeRescope['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Get rescope contents from the scope skipped API
   * @function getSkippedContents
   * @returns {Ember.RSVP.Promise}
   */getSkippedContents:function getSkippedContents(filter){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('rescopeAdapter').getSkippedContents(filter).then(function(response){return resolve(service.get('rescopeSerializer').normalizeSkippedContents(response));},reject);});}, /**
   * Get rescope contents from the API
   * @function getRescopeItems
   * @returns {Ember.RSVP.Promise}
   */getRescopeItems:function getRescopeItems(params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('rescopeAdapter').getRescopeItems(params).then(function(response){return resolve(service.get('rescopeSerializer').normalizeRescopeContents(response));},reject);});}});});