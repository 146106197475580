define('gooru-web/adapters/report/report',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the class report APIs
 *
 * @typedef {Object} ReportAdapter
 */exports['default'] = _ember['default'].Object.extend({namespace:'/api/ds/users/v2/',classNamespace:'/api/ds/users/v2/class/',namespaceNucleus:'/api/nucleus-insights/v2/',selNamespace:'/api/ds/users/v2/selreports/',evidenceNamespace:'/api/v1/evidence',session:_ember['default'].inject.service('session'), /**
   * @function fetchStudentsWeeklySummaryReport
   * Method to fetch students weekly summary report
   */fetchStudentsWeeklySummaryReport:function fetchStudentsWeeklySummaryReport(classId,data){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + 'class/' + classId + '/student/summary/weekly';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:data};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchStudentsSummaryReport
   * Method to fetch students summary report
   */fetchStudentsSummaryReport:function fetchStudentsSummaryReport(classId,data){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + 'class/' + classId + '/student/summary';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:data};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchStudentTimespentReport
   * Method to fetch students timespent report
   */fetchStudentTimespentReport:function fetchStudentTimespentReport(data){var adapter=this;var namespace=adapter.get('classNamespace');var url=namespace + 'user/timespent';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:data};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchStudentCompetencyReport
   * Method to fetch students timespent report
   */fetchStudentCompetencyReport:function fetchStudentCompetencyReport(data){var adapter=this;var namespace=adapter.get('classNamespace');var url=namespace + 'user/competency/report';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:data};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchStudentsTimespentSummaryreport
   * Method to fetch students timespent report
   */fetchStudentsTimespentSummaryreport:function fetchStudentsTimespentSummaryreport(data){var adapter=this;var namespace=adapter.get('classNamespace');var url=namespace + 'timespent';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:data};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchDiagnosticSummaryData help to fetch diagnostic summary data for the domain
   * @param {Object} params has userId, classId, contentSoruce, domainCode
   */fetchDiagnosticSummaryData:function fetchDiagnosticSummaryData(params){var adapter=this;var namespace=adapter.get('namespace');var endpoint=namespace + 'content/portfolio/diagnostic/assessment/summary';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(endpoint,options);}, /**
   * @function fetchMinProficiencySummary
   * @param {classId, courseId} params
   * @returns
   */fetchMinProficiencySummary:function fetchMinProficiencySummary(params){var adapter=this;var namespace=adapter.get('namespaceNucleus');var endpoint=namespace + 'class/' + params.classId + '/course/' + params.courseId + '/activities/proficiency/summary';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(endpoint,options);}, /**
   * @function fetchStudentSelReport
   * Method to fetch students sel report
   */fetchStudentSelReport:function fetchStudentSelReport(classId){var adapter=this;var namespace=adapter.get('selNamespace');var url=namespace + 'class/' + classId + '/summary';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchLessonSummaryReport
   * Method to fetch lesson summary report
   */fetchLessonSummaryReport:function fetchLessonSummaryReport(classId,lessonId,params){var adapter=this;var namespace=adapter.get('selNamespace');var url=namespace + 'class/' + classId + '/lesson/' + lessonId + '/summary';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchActivitySummaryReport
   * Method to fetch activity summary report
   */fetchActivitySummaryReport:function fetchActivitySummaryReport(classId,lessonId,activityType,params){var adapter=this;var namespace=adapter.get('selNamespace');var url=namespace + 'class/' + classId + '/lesson/' + lessonId + '/activity/' + activityType + '/summary';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchQuizSummaryReport
   * Method to fetch quiz summary report
   */fetchQuizSummaryReport:function fetchQuizSummaryReport(classId,lessonId,activityType,params){var adapter=this;var namespace=adapter.get('selNamespace');var url=namespace + 'class/' + classId + '/lesson/' + lessonId + '/activity/' + activityType + '/answerstats';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchFeelingSummaryReport
   * Method to fetch feeling summary report
   */fetchFeelingSummaryReport:function fetchFeelingSummaryReport(classId,lessonId,activityType,params){var adapter=this;var namespace=adapter.get('selNamespace');var url=namespace + 'class/' + classId + '/lesson/' + lessonId + '/activity/' + activityType + '/answerstats';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchRechargeSummaryReport
   * Method to fetch recharge summary report
   */fetchRechargeSummaryReport:function fetchRechargeSummaryReport(classId,lessonId,activityType,params){var adapter=this;var namespace=adapter.get('selNamespace');var url=namespace + 'class/' + classId + '/lesson/' + lessonId + '/activity/' + activityType + '/details';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * @function fetchActivityEvidenceReport
   * Method to fetch activity evidence report
   */fetchActivityEvidenceReport:function fetchActivityEvidenceReport(classId,oaId){var adapter=this;var namespace=adapter.get('evidenceNamespace');var url=namespace + '/class/' + classId + '/oa/' + oaId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Creates the headers required by API 3.0
   * @returns {{Authorization: string}}
   */defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});