define('gooru-web/models/map/map-suggestion',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){ /**
 * MapSuggestion model
 *
 * @typedef {Object} MapSuggestion
 */var MapSuggestionModel=_ember['default'].Object.extend({ /**
   * @property {String} id - Suggestion id
   */id:null, /**
   * @property {String} title
   */title:null, /**
   * @property {String} type assessment
   */type:null, /**
   * @property {String} type pre-test, post-test, benchmark
   */subType:null, /**
    * @property {String} resource subformat
    */resourceFormat:null, /**
   * @property {boolean}
   */isPreTest:_ember['default'].computed.equal('subType',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.PRE_TEST), /**
   * @property {boolean}
   */isPostTest:_ember['default'].computed.equal('subType',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.POST_TEST), /**
   * @property {boolean}
   */isBackFill:_ember['default'].computed.equal('subType',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.BACKFILL), /**
   * @property {boolean}
   */isBenchmark:_ember['default'].computed.equal('subType',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.BENCHMARK), /**
   * @property {boolean}
   */isResource:_ember['default'].computed.equal('subType',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.RESOURCE)});exports['default'] = MapSuggestionModel;});