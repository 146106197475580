define('gooru-web/adapters/performance/class-performance-summary',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the goal CRUD operations in the API 3.0
 *
 * @typedef {Object} GoalAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service(),namespace:'/api/nucleus-insights/v2', /**
   * Gets student class performance by student and class ids
   *
   * @param {string} studentId
   * @param {string[]} classIds
   * @returns {Promise}
   */findClassPerformanceSummaryByStudentAndClassIds:function findClassPerformanceSummaryByStudentAndClassIds(studentId,classCourseIds){var namespace=this.get('namespace');var url=namespace + '/classes/performance?userId=' + studentId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:this.defineHeaders(),data:JSON.stringify({classes:classCourseIds})};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});}, /**
   * Gets class performance by class ids
   *
   * @param {string[]} classIds
   * @returns {Promise}
   */findClassPerformanceSummaryByClassIds:function findClassPerformanceSummaryByClassIds(classCourseIds){var namespace=this.get('namespace');var url=namespace + '/classes/performance';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:this.defineHeaders(),data:JSON.stringify({classes:classCourseIds})};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});