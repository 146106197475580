define('gooru-web/components/content/modals/gru-join-class',['exports','ember','ember-cp-validations'],function(exports,_ember,_emberCpValidations){var Validations=(0,_emberCpValidations.buildValidations)({code:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.join-class-code'}),(0,_emberCpValidations.validator)('dependent',{on:['allowedCode'],message:'{{description}}',descriptionKey:'content.classes.join.join-not-allowed'}),(0,_emberCpValidations.validator)('dependent',{on:['validCode'],message:'{{description}}',descriptionKey:'content.classes.join.invalid-code'}),(0,_emberCpValidations.validator)('dependent',{on:['notMember'],message:'{{description}}',descriptionKey:'content.classes.join.already-member'})]},validCode:(0,_emberCpValidations.validator)('presence',true),allowedCode:(0,_emberCpValidations.validator)('presence',true),notMember:(0,_emberCpValidations.validator)('presence',true)});exports['default'] = _ember['default'].Component.extend(Validations,{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['content','modal','gru-join-class'], // -------------------------------------------------------------------------
// Actions
actions:{joinClass:function joinClass(){var component=this;component.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){component.set('isLoading',true);component.sendAction('onJoinClass',component.get('code'));}component.set('didValidate',true);});},onCodeFocusOut:function onCodeFocusOut(){this.clearValidations();},onCodeTyping:function onCodeTyping(){this.clearValidations();}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$().on('keyup','.modal-body',function(e){var keyCode=event.keyCode?event.keyCode:event.which;if(keyCode === 13){$(e.target).blur().focus();component.$('.join-class-btn').trigger('click');}});}, // -------------------------------------------------------------------------
// Methods
/**
   * Clear validation messages
   */clearValidations:function clearValidations(){var component=this;component.set('allowedCode',true);component.set('validCode',true);component.set('notMember',true);}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {string}
   */code:null, /**
   * @property {string}
   */onJoinClass:null, /**
   * Indicates if the code is valid, false when the class is not found
   * @property {boolean}
   */validCode:true, /**
   * Indicates if the code is allowed, false if the user can't join that class
   * @property {boolean}
   */allowedCode:true, /**
   * Indicates if user is not a member of this class
   * @property {boolean}
   */notMember:true, /**
   * Indicate if it's waiting for join class callback
   */isLoading:false});});