define('gooru-web/components/player/chrono-view/student-card',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['card','student-activity'],classNameBindings:['cardPos'],cardPos:_ember['default'].computed('activity.selected','activity.position','activity.positionSeq',function(){var activity=this.get('activity');if(activity.get('selected')){return 'selected';}else {return activity.get('position') + '-' + activity.get('positionSeq');}}), /**
   * @requires {AssessmentService} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @requires {CollectionService} Service to retrieve a collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'),offlineActivityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {String} color - Hex color value for the default bgd color of the bar chart
   */defaultBarColor:'#E3E5EA', /**
   * @property {collection}
   */collection:null, /**
   * @property {type}
   */type:_ember['default'].computed('activity',function(){return this.get('activity.collectionType');}), /**
   * @property {activity}
   */activity:null, /**
   * @property {loading}
   */loading:true,isOfflineActivity:_ember['default'].computed.equal('type',_gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY),init:function init(){this._super.apply(this,arguments);this.getStundentCollectionReport();}, /**
   * @function  getCollection summary report by student
   */getStundentCollectionReport:function getStundentCollectionReport(){var component=this;var activity=component.get('activity');var collectionPromise=undefined;if(activity.get('collectionType') === 'collection'){collectionPromise = component.get('collectionService').readCollection(activity.get('collectionId'));}else if(activity.get('collectionType') === 'assessment'){collectionPromise = component.get('assessmentService').readAssessment(activity.get('collectionId'));}else if(activity.get('collectionType') === 'assessment-external'){collectionPromise = component.get('assessmentService').readExternalAssessment(activity.get('collectionId'));}else if(activity.get('collectionType') === _gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY){collectionPromise = component.get('offlineActivityService').readActivity(activity.get('collectionId'));}else {collectionPromise = component.get('collectionService').readExternalCollection(activity.get('collectionId'));}return _ember['default'].RSVP.hashSettled({collection:collectionPromise}).then(function(hash){component.set('collection',hash.collection.state === 'fulfilled'?hash.collection.value:null);component.set('loading',false);});},actions:{onSelectCard:function onSelectCard(activity){this.sendAction('onSelectCard',activity);},onOpenCollectionReport:function onOpenCollectionReport(){var component=this;var activity=component.get('activity');var isActive=activity.get('selected');var collection=component.get('collection');collection.set('performance',_ember['default'].Object.create({score:activity.score}));if(isActive){component.sendAction('onOpenCollectionReport',activity,collection,component.get('type'));}}}});});