define('gooru-web/components/reports/assessment/questions/gru-multiple-choice',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * Multiple choice
 *
 * Component responsible for show the multiple choice answer, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-multiple-choice'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
answers:_ember['default'].computed('question.answerObject.[]',function(){var component=this;var question=component.get('question');var questionUtil=component.getQuestionUtil(question);var userAnswer=component.get('userAnswer');if(component.get('showCorrect')){userAnswer = questionUtil.getCorrectAnswer();}var userAnswerCorrect=questionUtil.isAnswerChoiceCorrect(userAnswer);var answers=question.get('answers');return answers.map(function(answer){return {text:answer.get('text'),selected:answer.get('id') === userAnswer,correct:userAnswerCorrect};});}),isChooseOne:false // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});