define('gooru-web/models/depth-of-knowledge',['exports','ember'],function(exports,_ember){ /**
 * DepthOfKnowledge model
 *
 * @typedef {Object} DepthOfKnowledge
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string}
   */id:null, /**
   * @property {string}
   */name:null, /**
   * @property {number}
   */order:null});});