define('gooru-web/models/content/builder/match-the-following',['exports','ember','ember-cp-validations'],function(exports,_ember,_emberCpValidations){var Validations=(0,_emberCpValidations.buildValidations)({leftValue:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.match-the-following-answer'})]},rightValue:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.match-the-following-answer'})]}}); /**
 * match the following model
 * typedef {Object} MatchTheFollowing
 */var MatchTheFollowing=_ember['default'].Object.extend(Validations,{ /**
   * @property {Number} sequence - The order sequence of the answer
   */sequence:0, /**
   * @property {String} leftValue - Left side answer text
   */leftValue:null, /**
   * @property {String} rightValue - Right side answer text
   */rightValue:null, /**
   * @property {String} leftValueFormat - The answer format
   */leftValueFormat:null, /**
   * @property {String} rightValueFormat - The answer format
   */rightValueFormat:null, /**
   * @property {String} rightValShuffleOrder - The answer order
   */rightValShuffleOrder:null, /**
   * Return a copy of the answer
   *
   * @function
   * @return {Answer}
   */copy:function copy(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key));if(value === 'string' || value === 'number' || value === 'boolean' || value === 'array' || value === 'instance' || value === 'object'){properties.push(key);}} // Copy the question data
properties = this.getProperties(properties);return MatchTheFollowing.create(_ember['default'].getOwner(this).ownerInjection(),properties);}});exports['default'] = MatchTheFollowing;});