define('gooru-web/services/api-sdk/taxonomy',['exports','ember','gooru-web/serializers/taxonomy/taxonomy','gooru-web/adapters/taxonomy/taxonomy'],function(exports,_ember,_gooruWebSerializersTaxonomyTaxonomy,_gooruWebAdaptersTaxonomyTaxonomy){ /**
 * API-SDK Service for the Taxonomies back-end endpoints
 *
 * @typedef {Object} APITaxonomyService
 */exports['default'] = _ember['default'].Service.extend({taxonomySerializer:null,taxonomyAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomyAdapter',_gooruWebAdaptersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Fetches the Taxonomy Subjects
   *
   * @param category - The classification type
   * @returns {Promise}
   */fetchSubjects:function fetchSubjects(category){var filter=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchSubjects(category,filter).then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchSubjects(response));},function(error){reject(error);});});}, /**
   * Fetches the Taxonomy Subjects from DS
   *
   * @param category - The classification type
   * @returns {Promise}
   */fetchTaxonomySubjects:function fetchTaxonomySubjects(taxonomyCategory){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchTaxonomySubjects(taxonomyCategory).then(function(response){resolve(service.get('taxonomySerializer').normalizeTaxonomySubjects(response));},function(error){reject(error);});});}, /**
   * Fetches the Taxonomy Subjects by user id
   *
   * @param taxonomyCategory
   * @returns {Promise}
   */getTaxonomySubjectsByUserId:function getTaxonomySubjectsByUserId(taxonomyCategory){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').getTaxonomySubjectsByUserId(taxonomyCategory).then(function(response){resolve(response);},function(error){reject(error);});});},fetchCrossWalkFWC:function fetchCrossWalkFWC(frameworkCode,subjectCode){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('taxonomyAdapter').fetchCrossWalkFWC(frameworkCode,subjectCode).then(function(response){resolve(service.get('taxonomySerializer').normalizeFWCMatrixs(response));})['catch'](function(){_ember['default'].Logger.error(subjectCode + ' is doesn\'t exists in this ' + frameworkCode);resolve([]);});});}, /**
   * Fetches the Taxonomy Courses
   *
   * @param frameworkId - the framework ID
   * @param subjectId - the taxonomy subject ID
   * @returns {Promise}
   */fetchCourses:function fetchCourses(frameworkId,subjectId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchCourses(frameworkId,subjectId).then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchCourses(response));},function(error){reject(error);});});}, /**
   * Fetches the Taxonomy Domains
   *
   * @param frameworkId - the framework ID
   * @param subjectId - the taxonomy subject ID
   * @param courseId - the taxonomy course ID
   * @returns {Promise}
   */fetchDomains:function fetchDomains(frameworkId,subjectId,courseId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchDomains(frameworkId,subjectId,courseId).then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchDomains(response));},function(error){reject(error);});});}, /**
   * Fetches the Taxonomy Codes
   *
   * @param frameworkId - the framework ID
   * @param subjectId - the taxonomy subject ID
   * @param courseId - the taxonomy course ID
   * @param domainId - the taxonomy domain ID
   * @returns {Promise}
   */fetchCodes:function fetchCodes(frameworkId,subjectId,courseId,domainId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchCodes(frameworkId,subjectId,courseId,domainId).then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchCodes(response));},function(error){reject(error);});});}, /**
   * Fetches the Taxonomy Codes by IDs
   *
   * @param codesIds - the list of codes IDs
   * @returns {Promise}
   */fetchCodesByIds:function fetchCodesByIds(codesIds){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchCodesByIds(codesIds).then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchCodes(response));},function(error){reject(error);});});}, /**
   * @function fetchDomainGradeBoundaryBySubjectId
   */fetchDomainGradeBoundaryBySubjectId:function fetchDomainGradeBoundaryBySubjectId(gradeId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchDomainGradeBoundaryBySubjectId(gradeId).then(function(response){resolve(service.get('taxonomySerializer').normalizeDomainGradeBoundary(response));},function(error){reject(error);});});}, /**
   * @function fetchGradesBySubject
   */fetchGradesBySubject:function fetchGradesBySubject(filters){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchGradesBySubject(filters).then(function(response){resolve(service.get('taxonomySerializer').normalizeGrades(response));},function(error){reject(error);});});}, /**
   * @function fetchCommonGrade
   */fetchCommonGrade:function fetchCommonGrade(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchCommonGrade().then(function(response){resolve(response.common_grade_levels);},function(error){reject(error);});});}, /**
   * Fetches the Taxonomy Subjects
   *
   * @param category - The classification type
   * @returns {Promise}
   */fetchSubject:function fetchSubject(category){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchSubject(category).then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchSubject(response));},function(error){reject(error);});});}, /**
   * Fetches the Taxonomy Subjects
   *
   * @param category - The classification type
   * @returns {Promise}
   */fetchSubjectFWKs:function fetchSubjectFWKs(category){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchSubjectFWKs(category).then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchSubjectFrameworks(response));},function(error){reject(error);});});},fetchCategories:function fetchCategories(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('taxonomyAdapter').fetchCategories().then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchCategories(response));});});}, /**
   *
   * @param prerequisites gut code
   * @returns {Promise.<Question[]>}
   */fetchPrerequisites:function fetchPrerequisites(prerequisites){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchPrerequisites(prerequisites).then(function(response){resolve(response);},function(error){reject(error);});});}, /**
   * Fetches competency gutCode
   *
   * @param competencyCode - The competency code
   * @param framework - class framework
   * @returns {Promise}
   */fetchCrosswalkCompetency:function fetchCrosswalkCompetency(competencyCode,classFramework){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').crosswalkCompetency(classFramework,competencyCode).then(function(response){resolve(service.get('taxonomySerializer').normalizeCrosswalkCompetency(response));},function(error){reject(error);});});}, /**
   * Fetches the Taxonomy Codes
   *
   * @param frameworkId - the framework ID
   * @param subjectId - the taxonomy subject ID
   * @param courseId - the taxonomy course ID
   * @param domainId - the taxonomy domain ID
   * @returns {Promise}
   */fetchCompetencyCodes:function fetchCompetencyCodes(frameworkId,competncyId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('taxonomyAdapter').fetchCompetencyCodes(frameworkId,competncyId).then(function(response){resolve(service.get('taxonomySerializer').normalizeFetchCodes(response));},function(error){reject(error);});});}});});