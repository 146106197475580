define('gooru-web/components/reports/pull-up/dca-collection-report-gridview',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','pull-up-dca-collection-report-gridview'], // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Properties
/**
   * This property will get change based on filter selection, by default reaction filter off.
   * @type {Boolean}
   */isReactionFltApplied:false, /**
   * This property will get change based on filter selection, by default timespent filter off.
   * @type {Boolean}
   */isTimeSpentFltApplied:false, /**
   * List of contents associated with collection
   * @type {Array}
   */contents:_ember['default'].A(), /**
   * Students report data
   * @type {Array}
   */studentReportData:_ember['default'].A(), // -------------------------------------------------------------------------
// Actions
actions:{studentReport:function studentReport(studentPerformance){this.sendAction('studentReport',studentPerformance);}}});});