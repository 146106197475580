define('gooru-web/adapters/performance/milestone-performance',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the  Milestone Performance API
 *
 * @typedef {Object} MilestonePerformanceAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus-insights/v2', /**
   * @function getPerformanceForMilestones
   * Get Performance Data for course milestones
   */getPerformanceForMilestones:function getPerformanceForMilestones(classId,courseId,collectionType,userUid,fwCode){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/milestone/performance';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:{collectionType:collectionType,userUid:userUid,fwCode:fwCode}};return _ember['default'].$.ajax(url,options);}, /**
   * @function getLessonsPerformanceByMilestoneId
   * Get Lessons Performance Data by  milestone Id
   */getLessonsPerformanceByMilestoneId:function getLessonsPerformanceByMilestoneId(classId,courseId,milestoneId,collectionType,userUid,fwCode){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/milestone/' + milestoneId + '/performance';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:{collectionType:collectionType,userUid:userUid,fwCode:fwCode}};return _ember['default'].$.ajax(url,options);}, /**
   * @function getPerformanceForMilestones
   * Get Performance Data for course milestones
   */getPerformanceForMilestoneUnits:function getPerformanceForMilestoneUnits(classId,courseId,collectionType,userUid,fwCode){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/prestudy/milestone/performance';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:{collectionType:collectionType,userUid:userUid,fwCode:fwCode}};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});