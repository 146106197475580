define('gooru-web/components/cards/suggest-collection-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/parse-event','gooru-web/mixins/instructional-coache-mixin'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigParseEvent,_gooruWebMixinsInstructionalCoacheMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsInstructionalCoacheMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['cards','suggest-collection-card'], /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * collection object
   * @type {Object}
   */collection:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collection.standards.[]',function(){var standards=this.get('collection.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}),isInstructionalCoache:_ember['default'].computed(function(){return this.instructionalCoache();}), /**
   * Maintains collection type
   * @type {String}
   */contentType:null, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user play collection
     * It'll open the player in new tab
     */onPlayCollection:function onPlayCollection(collectionId){if(this.get('collection').format === 'collection'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_LJ_MILESTONE_REPORT_SUGGEST_COLLECTION_PLAY);}else if(this.get('collection').format === 'assessment'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_LJ_MILESTONE_REPORT_SUGGEST_ASSESSMENT_PLAY);}var collectionUrl=window.location.origin + '/player/' + collectionId + '?type=' + this.get('collection').format;window.open(collectionUrl);}, // Action triggered when click suggestion button
onSuggestCollection:function onSuggestCollection(collection){var component=this;if(collection.format === 'collection'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_LJ_MILESTONE_REPORT_SUGGEST_COLLECTION_ADD);}else if(collection.format === 'assessment'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_LJ_MILESTONE_REPORT_SUGGEST_ASSESSMENT_ADD);}this.sendAction('onSuggestCollection',collection);}, /**
     * Action triggered when add collection to dca.
     * @param  {Object} collection
     */onAddCollectionToDCA:function onAddCollectionToDCA(collection){var component=this;if(collection.format === 'collection'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_COLLECTION_ADD_TODAY_CLASS);}else if(collection.format === 'assessment'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_ASSESSMENT_ADD_TODAY_CLASS);}this.sendAction('onAddCollectionToDCA',collection);}, /**
     * Action get triggered when schedule content to CA got clicked
     */onScheduleContentToDCA:function onScheduleContentToDCA(content,event){if(content.format === 'collection'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_COLLECTION_SCHEDULE_LATER);}else if(content.format === 'assessment'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_ASSESSMENT_SCHEDULE_LATER);}this.sendAction('onScheduleContentToDCA',content,event);}}});});