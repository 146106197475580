define('gooru-web/components/ca-student-suggestion-panel',['exports','ember','gooru-web/config/config','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['ca-student-suggestion-panel'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/suggest
   */suggestService:_ember['default'].inject.service('api-sdk/suggest'),actions:{onClosePullUp:function onClosePullUp(){var component=this;component.set('showStudentList',false);},onPullUpClose:function onPullUpClose(){var component=this;component.closePullUp();},openStudentList:function openStudentList(suggestion){var component=this;component.set('selectedSuggestion',suggestion);component.set('showStudentList',true);},onPlayCASuggestionContent:function onPlayCASuggestionContent(suggestionContent){var component=this;var contentId=suggestionContent.get('suggestedContentId');var collectionType=suggestionContent.get('suggestedContentType');var classId=component.get('classId');var queryParams={collectionId:contentId,classId:classId,role:'teacher',source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.CLASS_ACTIVITY,type:collectionType,isIframeMode:true};component.set('playerUrl',component.get('router').generate('player',contentId,{queryParams:queryParams}));component.set('isOpenPlayer',true);component.set('playerContent',suggestionContent);var content=component.get('playerContent');content.set('format',collectionType);},closePullUp:function closePullUp(){var component=this;component.set('isOpenPlayer',false);}}, //--------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);component.fetchSuggestionContent();},didInsertElement:function didInsertElement(){var component=this;component.openPullUp();}, //--------------------------------------------------------------------------
// Methods
fetchSuggestionContent:function fetchSuggestionContent(){var component=this;var classId=component.get('classId');var activity=component.get('activity');var caId=activity.get('id');var context={scope:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.CLASS_ACTIVITY,caIds:[caId],detail:true};component.get('suggestService').fetchSuggestionsByCAId(classId,context).then(function(content){var suggestions=content.get('suggestions');component.set('suggestions',suggestions);});}, /**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.sendAction('onClosePullUp');});}});});