define('gooru-web/adapters/performance/chrono-performance',['exports','ember','gooru-web/adapters/application'],function(exports,_ember,_gooruWebAdaptersApplication){ /**
 * Adapter to support the Performance API
 *
 * @typedef {Object} PerformanceAdapter
 */exports['default'] = _gooruWebAdaptersApplication['default'].extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus-insights/v2', /**
   * getStudentPerformanceOfAllItemsInClass for student view
   * @returns {Promise.<[]>}
   */getStudentPerformanceOfAllItemsInClass:function getStudentPerformanceOfAllItemsInClass(filterData){var _filterData$body=filterData.body;var classId=_filterData$body.classId;var courseId=_filterData$body.courseId;var userId=_filterData$body.userId;var startDate=_filterData$body.startDate;var limit=_filterData$body.limit;var offset=_filterData$body.offset;var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/class/' + classId + '/items/performance';var options={type:'GET',headers:adapter.get('headers'),contentType:'application/json; charset=utf-8',data:{userId:userId,courseId:courseId,startDate:startDate,limit:limit,offset:offset}};return _ember['default'].$.ajax(url,options);}, /**
   * getStudentPerformanceOfAllItemsInClass for student view
   * @returns {Promise.<[]>}
   */getStudentPerformanceOfIndepedentLearning:function getStudentPerformanceOfIndepedentLearning(filterData){var _filterData$body2=filterData.body;var courseId=_filterData$body2.courseId;var userId=_filterData$body2.userId;var startDate=_filterData$body2.startDate;var limit=_filterData$body2.limit;var offset=_filterData$body2.offset;var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/learner/course/' + courseId + '/items/performance';var options={type:'GET',headers:adapter.get('headers'),contentType:'application/json; charset=utf-8',data:{userId:userId,courseId:courseId,startDate:startDate,limit:limit,offset:offset}};return _ember['default'].$.ajax(url,options);}});});