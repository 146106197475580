define('gooru-web/components/reports/class-assessment/gru-student-performance-box',['exports','ember','gooru-web/utils/question-result'],function(exports,_ember,_gooruWebUtilsQuestionResult){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['reports','class-assessment','gru-student-performance-box'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * When the user clicks at the box
     */selectStudent:function selectStudent(){var component=this;component.get('onSelectStudent')(component.get('student.id'));_ember['default'].Logger.debug('Clicking at student: ' + component.get('student.id'));}, /**
     * @function actions:selectQuestion
     * @param {Number} questionId
     */selectQuestion:function selectQuestion(questionId){if(questionId){this.get('onSelectQuestion')(questionId);}}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {User} student
   */student:null, /**
   * Array containing the QuestionResult or empty object based on the student responses
   * empty object for not started questions
   * @property {QuestionResult[]} reportData
   */reportData:null, /**
   * Indicates if the report is displayed in anonymous mode
   * @property {boolean} anonymous
   */anonymous:false, /**
   * It returns an object representing the status for each question
   * @property {[]} questions
   */questions:_ember['default'].computed('reportData.[]',function(){var component=this;var reportData=component.get('reportData');return reportData.map(function(item){return component.getQuestionStatus(item);});}), /**
   * @property {number} user assessment score
   */score:_ember['default'].computed('reportData.[]',function(){return (0,_gooruWebUtilsQuestionResult.correctPercentage)(this.get('reportData'));}), /**
   * Indicates if the assessment has been started
   * @property {number} started
   */started:_ember['default'].computed('reportData.[]',function(){return (0,_gooruWebUtilsQuestionResult.totalCompleted)(this.get('reportData'));}), /**
   * Indicates if the assessment has not started questions
   * @property {number} notStarted
   */totalNotStarted:_ember['default'].computed('reportData.[]',function(){return (0,_gooruWebUtilsQuestionResult.totalNotStarted)(this.get('reportData'));}), /**
   * @property {Function} onSelectQuestion - Event handler called when a question in a column is selected
   */onSelectQuestion:null, // -------------------------------------------------------------------------
// Methods
/**
   * Gets the question status
   * @param {QuestionResult} questionResult
   */getQuestionStatus:function getQuestionStatus(questionResult){var status='not-started';var questionId=undefined;var isOE=questionResult.get('questionType') === 'OE';if(questionResult.get('started')){ //if it has been started
var correct=questionResult.get('correct');var skipped=questionResult.get('skipped');status = skipped?'skipped':isOE?'open-ended':correct?'correct':'incorrect';questionId = questionResult.get('questionId');}return _ember['default'].Object.create({status:status,id:questionId});}});});