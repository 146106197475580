define('gooru-web/components/content/modals/gru-delete-resource',['exports','ember','gooru-web/config/config','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebConfigParseEvent){ /**
 * Delete resource component
 *
 * Component responsible for delete a content from content builder
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(),parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-delete-resource'], // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments); // 'validator' should never be set as a param except for testing
var validator=this.get('validator');if(!validator){this.set('validator',_ember['default'].Object.create({check1:false,check2:false}));}else {this.set('validator',validator);}}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Delete Content
     */deleteContent:function deleteContent(model){var component=this;component.set('isLoading',true);component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.RESOURCE_DELETE); // This deleteMethod will be a wrapper around the actual delete method that is particular to
// each question type.
model.deleteMethod().then(function(){if(model.callback){model.callback.success();}component.set('isLoading',false);component.triggerAction({action:'closeModal'});if(model.redirect){component.get('router').transitionTo(model.redirect.route,{queryParams:{profileId:model.redirect.params.profileId,type:model.redirect.params.type,activeContentType:model.type}});}})['catch'](function(error){var message=component.get('i18n').t('content.modals.delete-content.delete-error',{type:component.get('i18n').t('common.\'' + model.type).string.toLowerCase()}).string;component.get('notifications').error(message);_ember['default'].Logger.error(error);});}}, // -------------------------------------------------------------------------
// Properties
/**
   * This is the model used to delete.
   * @property {model}
   */model:null, /**
   * Content types.
   */types:_gooruWebConfigConfig.CONTENT_TYPES, /**
   * Object to control when the delete button becomes enabled
   * @property {model}
   */validator:null, /**
   * Indicate if it's waiting for deleteMethod callback
   */isLoading:false, /**
   * Indicate if delete button is disabled
   */isDisabled:_ember['default'].computed('validator.{check1,check2}',function(){var areChecked=this.get('validator.check1') && this.get('validator.check2');return !areChecked;})});});