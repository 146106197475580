define('gooru-web/utils/performance-data',['exports','ember','gooru-web/utils/math','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsMath,_gooruWebUtilsUtils){exports.createPerformanceObject = createPerformanceObject;exports.createUserAverageObject = createUserAverageObject;exports.createItemAverageObject = createItemAverageObject;exports.createClassAverageObject = createClassAverageObject;exports.createDataMatrix = createDataMatrix;exports.aggregateMilestonePerformanceScore = aggregateMilestonePerformanceScore;exports.aggregateMilestonePerformanceTimeSpent = aggregateMilestonePerformanceTimeSpent; /**
 * Create a performanceObject from the performance data
 * @param performance the base performance data
 * @param model model information
 * @param level indicates the object level, course|unit|lesson
 */function createPerformanceObject(performance,model){var level=arguments.length <= 2 || arguments[2] === undefined?false:arguments[2];var score=performance.get('score');var timeSpent=performance.get('timeSpent');return _ember['default'].Object.create({id:performance.get('realId'),collectionType:performance.get('collectionType'),headerTitle:model.get('title'),model:model,score:(0,_gooruWebUtilsMath.roundFloat)(score),timeSpent:(0,_gooruWebUtilsUtils.formatTime)(timeSpent),hasStarted:score > 0 || timeSpent > 0,hasScore:score >= 0,completionDone:performance.get('completionDone'),completionTotal:performance.get('completionTotal'),level:level,hideScore:level === 'lesson' && model && !model.get('hasNonOpenEndedQuestions')});} /**
 * Create a User average object from the classPerformanceData
 * @param classPerformanceData the base performance data
 */function createUserAverageObject(studentPerformance){var score=studentPerformance.get('averageScore');var timeSpent=studentPerformance.get('averageTimeSpent');return _ember['default'].Object.create({isAverage:true, //indicates it is an average object
score:(0,_gooruWebUtilsMath.roundFloat)(score),studyTime:timeSpent,timeSpent:(0,_gooruWebUtilsUtils.formatTime)((0,_gooruWebUtilsMath.roundFloat)(timeSpent)),hasStarted:score > 0 || timeSpent > 0,hasScore:score >= 0,completionDone:studentPerformance.get('sumCompletionDone'),completionTotal:studentPerformance.get('sumCompletionTotal')});} /**
 * Create an Item average object from the classPerformanceData with an itemId as well
 * @param classPerformanceData the base performance data
 * @param model item we are getting the item average object of
 */function createItemAverageObject(classPerformanceData,model){var level=arguments.length <= 2 || arguments[2] === undefined?false:arguments[2];var itemId=model.get('id');var score=classPerformanceData.calculateAverageScoreByItem(itemId);var timeSpent=classPerformanceData.calculateAverageTimeSpentByItem(itemId);return _ember['default'].Object.create({score:(0,_gooruWebUtilsMath.roundFloat)(score),timeSpent:(0,_gooruWebUtilsUtils.formatTime)((0,_gooruWebUtilsMath.roundFloat)(timeSpent)),hasStarted:score > 0 || timeSpent > 0,hasScore:score >= 0,completionDone:classPerformanceData.calculateSumCompletionDoneByItem(itemId),completionTotal:classPerformanceData.calculateSumCompletionTotalByItem(itemId),hideScore:level === 'lesson' && model && !model.get('hasNonOpenEndedQuestions')});} /**
 * Create a class average object from the classPerformanceData
 * @param classPerformanceData the base performance data
 */function createClassAverageObject(classPerformanceData){var score=classPerformanceData.get('classAverageScore');var timeSpent=classPerformanceData.get('classAverageTimeSpent');return _ember['default'].Object.create({score:(0,_gooruWebUtilsMath.roundFloat)(score),timeSpent:(0,_gooruWebUtilsUtils.formatTime)((0,_gooruWebUtilsMath.roundFloat)(timeSpent)),hasStarted:score > 0 || timeSpent > 0,hasScore:score >= 0,completionDone:classPerformanceData.get('classSumCompletionDone'),completionTotal:classPerformanceData.get('classSumCompletionTotal')});} /**
 * Create a matrix with random data to be used to fill the teacher analytics data by student.
 * @param headers the table header
 * @param classPerformanceData the base performance data
 */function createDataMatrix(headers,classPerformanceData){var level=arguments.length <= 2 || arguments[2] === undefined?false:arguments[2];var studentPerformanceData=classPerformanceData.get('studentPerformanceData');var excludedIds=[];if(level === 'lesson'){excludedIds = headers.filter(function(header){return !header.get('hasNonOpenEndedQuestions');}).map(function(header){return header.get('id');});}var dataMatrix=_ember['default'].A([]);studentPerformanceData.forEach(function(studentPerformance){studentPerformance.set('excludedIds',excludedIds);var user=studentPerformance.get('user');var performanceData=studentPerformance.get('performanceData');var userData=_ember['default'].Object.create({user:user.get('fullName'),userId:user.get('id'),performanceData:_ember['default'].A([])});headers.forEach(function(headerItem){var performance=performanceData.findBy('id',user.get('id') + '@' + headerItem.get('id'));if(performance){userData.get('performanceData').push(createPerformanceObject(performance,headerItem,level));}else {userData.get('performanceData').push(undefined);}}); // Inserts User averages at position 0 of the current row of performance elements.
userData.get('performanceData').insertAt(0,createUserAverageObject(studentPerformance)); // Pushes User data in the matrix.
dataMatrix.push(userData);}); // Inserts the Header average for each item (unit|lesson|collection)
var itemPerformanceAverageData=_ember['default'].Object.create({performanceData:_ember['default'].A([])});headers.forEach(function(headerItem){var itemPerformanceAverage=createItemAverageObject(classPerformanceData,headerItem,level);itemPerformanceAverageData.get('performanceData').push(itemPerformanceAverage);});itemPerformanceAverageData.get('performanceData').insertAt(0,createClassAverageObject(classPerformanceData));dataMatrix.insertAt(0,itemPerformanceAverageData);return dataMatrix;} /**
 * @function aggregateMilestonePerformanceScore
 * Method to aggregate given list of performance objects
 */function aggregateMilestonePerformanceScore(items){var aggregatedScoreValue=null;if(items && items.length){aggregatedScoreValue = 0;items.map(function(item){var itemPerformanceScore=item.get('performance.scoreInPercentage');aggregatedScoreValue += itemPerformanceScore?itemPerformanceScore:0;});aggregatedScoreValue = aggregatedScoreValue / items.length;}return (0,_gooruWebUtilsMath.roundFloat)(aggregatedScoreValue);} /**
 * @function aggregateMilestonePerformanceTimeSpent
 * Method to aggregate given list of performance objects
 */function aggregateMilestonePerformanceTimeSpent(items){var aggregatedTimeSpentValue=null;if(items && items.length){aggregatedTimeSpentValue = 0;items.map(function(item){var itemPerformanceTimeSpent=item.get('performance.timeSpent');aggregatedTimeSpentValue += itemPerformanceTimeSpent?itemPerformanceTimeSpent:0;});}return (0,_gooruWebUtilsMath.roundFloat)(aggregatedTimeSpentValue);}});