define('gooru-web/services/api-sdk/learner',['exports','ember','gooru-web/serializers/learner/learner','gooru-web/adapters/learner/learner','gooru-web/serializers/performance/student-collection-performance','gooru-web/serializers/user-session','gooru-web/services/api-sdk/performance'],function(exports,_ember,_gooruWebSerializersLearnerLearner,_gooruWebAdaptersLearnerLearner,_gooruWebSerializersPerformanceStudentCollectionPerformance,_gooruWebSerializersUserSession,_gooruWebServicesApiSdkPerformance){ /**
 * @typedef {Object} LearnerService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {LearnerSerializer} learnerSerializer
   */learnerSerializer:null, /**
   * @property {LearnerAdapter} learnerAdapter
   */learnerAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('learnerSerializer',_gooruWebSerializersLearnerLearner['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('learnerAdapter',_gooruWebAdaptersLearnerLearner['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('studentCollectionPerformanceSerializer',_gooruWebSerializersPerformanceStudentCollectionPerformance['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('userSessionSerializer',_gooruWebSerializersUserSession['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('performanceService',_gooruWebServicesApiSdkPerformance['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Fetches the learner locations
   *
   * @param userId - user to fetch the learner locations
   * @param contentType - one of course, collection or assessment
   * @param offset - for paginated listing of learner locations
   * @param limit - for paginated listing of learner locations
   * @returns {Promise}
   */fetchLocations:function fetchLocations(userId,contentType){var offset=arguments.length <= 2 || arguments[2] === undefined?0:arguments[2];var limit=arguments.length <= 3 || arguments[3] === undefined?20:arguments[3];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnerAdapter').fetchLocations(userId,contentType,offset,limit).then(function(response){return resolve(service.get('learnerSerializer').normalizeLocations(response));},reject);});}, /**
   * Fetches the learner performance
   *
   * @param userId - user to fetch the learner performance
   * @param contentType - one of course, collection or assessment
   * @param offset - for paginated listing of learner performance
   * @param limit - for paginated listing of learner performance
   * @returns {Promise}
   */fetchPerformance:function fetchPerformance(userId,contentType){var offset=arguments.length <= 2 || arguments[2] === undefined?0:arguments[2];var limit=arguments.length <= 3 || arguments[3] === undefined?20:arguments[3];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnerAdapter').fetchPerformance(userId,contentType,offset,limit).then(function(response){return resolve(service.get('learnerSerializer').normalizePerformances(response));},reject);});}, /**
   * Fetches the learner performance in lesson
   *
   * @param courseId - course to fetch the learner performance
   * @param unitId - unit to fetch the learner performance
   * @param lessonId - lesson to fetch the learner performance
   * @param collectionType
   * @returns {Promise}
   */fetchPerformanceLesson:function fetchPerformanceLesson(courseId,unitId,lessonId,collectionType){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnerAdapter').fetchPerformanceLesson(courseId,unitId,lessonId,collectionType).then(function(response){return resolve(service.get('learnerSerializer').normalizePerformancesLesson(response));},reject);});}, /**
   * Fetches the learner performance in course
   *
   * @param courseId - course to fetch the learner performance
   * @param collectionType
   */fetchPerformanceCourse:function fetchPerformanceCourse(courseId,collectionType){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnerAdapter').fetchPerformanceCourse(courseId,collectionType).then(function(response){return resolve(service.get('learnerSerializer').normalizePerformancesCourse(response));},reject);});}, /**
   * Fetches the learner performance in unit
   *
   * @param courseId - course to fetch the learner performance
   * @param unitId - unit to fetch the learner performance
   * @param collectionType
   */fetchPerformanceUnit:function fetchPerformanceUnit(courseId,unitId,collectionType){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnerAdapter').fetchPerformanceUnit(courseId,unitId,collectionType).then(function(response){return resolve(service.get('learnerSerializer').normalizePerformancesUnit(response));},reject);});}, /**
   * Fetches the learner performance for specific courses
   *
   * @param userId - user to fetch the learner performance
   * @param courseIds - list of ids to fetch performance
   * @returns {Promise}
   */fetchCoursesPerformance:function fetchCoursesPerformance(userId,courseIds){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnerAdapter').fetchCoursesPerformance(userId,courseIds).then(function(response){return resolve(service.get('learnerSerializer').normalizePerformances(response));},reject);});}, /**
   * Fetches the learner location in course
   *
   * @param courseId - course to fetch the learner location
   * @param userId - user to fetch the learner location
   * @returns {Promise}
   */fetchLocationCourse:function fetchLocationCourse(courseId,userId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnerAdapter').fetchLocationCourse(courseId,userId).then(function(response){return resolve(service.get('learnerSerializer').normalizeFetchLocationCourse(response));},reject);});}, /**
   * Fetches the performance summary data of each resource/question in Collection/Assessment.
   * @param context
   * @returns {Promise.<AssessmentResult>}
   */fetchCollectionPerformance:function fetchCollectionPerformance(context){var service=this;var params={collectionType:context.collectionType,contentId:context.collectionId,userId:context.userId,sessionId:context.sessionId};if(context.courseId){params.courseId = context.courseId;params.unitId = context.unitId;params.lessonId = context.lessonId;}return new _ember['default'].RSVP.Promise(function(resolve){return service.get('learnerAdapter').fetchCollectionPerformance(params).then(function(payload){var assessmentResult=service.get('studentCollectionPerformanceSerializer').normalizeStudentCollection(payload);resolve(assessmentResult);},function(){resolve();});});}, /**
   * Gets all the session that were completed.
   * @param context
   * @returns {Promise.<Object>}
   */fetchLearnerSessions:function fetchLearnerSessions(context){var service=this;var params={collectionType:context.collectionType,contentId:context.collectionId,userId:context.userId,openSession:false};if(context.sessionId){params.sessionId = context.sessionId;}if(context.courseId){params.courseId = context.courseId;params.unitId = context.unitId;params.lessonId = context.lessonId;}return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnerAdapter').fetchLearnerSessions(params).then(function(response){return resolve(service.get('userSessionSerializer').serializeSessionAssessments(response));},reject);});}});});