define('gooru-web/components/class/diagnostic-assessment-report',['exports','ember','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({classNames:['diagnostic-assessment-report-pull-up'], // -------------------------------------------------------------------------
// Dependencies
analyticsService:_ember['default'].inject.service('api-sdk/analytics'),collectionService:_ember['default'].inject.service('api-sdk/collection'),assessmentService:_ember['default'].inject.service('api-sdk/assessment'),parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), /**
   * Propery of collection
   * @property {Object}
   */collection:_ember['default'].computed('context',function(){return this.get('context.collection');}),isCompetencyReport:false,actions:{onPullUpClose:function onPullUpClose(closeAll){var component=this;component.closePullUp(closeAll);},pullUpClose:function pullUpClose(closeAll){var component=this;component.set('isShowStudentActivityReport',false);component.closePullUp(closeAll);},onOpenSuggestionPullup:function onOpenSuggestionPullup(){this.set('showSuggestionPullup',true);},onOpenCompetencyReport:function onOpenCompetencyReport(item){this.set('isCompetencyReport',true);this.set('competencyData',item);},onOpenPerformanceEntry:function onOpenPerformanceEntry(){var component=this;component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CS_ACTIVITY_REPORT_UPLOAD_DATA);var classActivity=component.get('context.activityClass');component.sendAction('onOpenPerformanceEntry',classActivity);}}, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.openPullUp();this.handleAppContainerScroll();},didDestroyElement:function didDestroyElement(){this.handleAppContainerScroll();},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});component.$('[data-toggle="popover"]').popover({trigger:'hover'});}, /**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.set('showPullUp',true);component.$().animate({bottom:'0'},400);}, /**
   * Function to animate the  pullup from top to bottom
   */closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);});}, /**
   * Function to hanle the pullup scroll
   */handleAppContainerScroll:function handleAppContainerScroll(){var activePullUpCount=_ember['default'].$(document.body).find('.backdrop-pull-ups').length;if(activePullUpCount > 0){_ember['default'].$(document.body).addClass('no-vertical-scroll');}else if(activePullUpCount === 0){_ember['default'].$(document.body).removeClass('no-vertical-scroll');}}});});