define('gooru-web/components/library/library-content-result-grid-view',['exports','ember','gooru-web/models/content/bookmark','gooru-web/config/config','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebModelsContentBookmark,_gooruWebConfigConfig,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/bookmark
   */bookmarkService:_ember['default'].inject.service('api-sdk/bookmark'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['library-content-result-grid'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} searchResults
   */searchResults:null, /**
   * @property {Boolean} isRemixableContent
   */isRemixableContent:_ember['default'].computed('type',function(){return this.get('type') === _gooruWebConfigConfig.SEARCH_CONTEXT.GOORU_CATALOG || this.get('type') === _gooruWebConfigConfig.SEARCH_CONTEXT.LIBRARY;}), /**
   * @property {Array} classroomWithoutCourse
   * Propety to get list of classrooms which doesn't associated with a course
   */classroomWithoutCourse:_ember['default'].computed('activeClasses.[]',function(){var component=this;return component.get('activeClasses').filterBy('courseId',null) || [];}), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.handleShowMoreData();},init:function init(){var component=this;component._super.apply(component,arguments);component.set('offlineActivityModel',{courseId:null,unitId:null,lessonId:'new',associateLesson:false,isIndependentOA:true});component.set('isDeepLink',component.get('isDeepLink') === 'true');}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on the play icon
openContentPlayer:function openContentPlayer(assessment){var component=this;var previewContentType=assessment.get('isExternalAssessment')?'assessment-external':'assessment';component.set('previewContent',assessment);component.set('previewContentType',previewContentType);component.set('isShowContentPreview',true);}, //Action triggered when click play icon
openCollectionContentPlayer:function openCollectionContentPlayer(collection){var component=this;var type=collection.get('format') || 'collection';component.set('previewContent',collection);component.set('previewContentType',type);component.set('isShowContentPreview',true);}, /**
     * On card edit collection button click
     * @param {Collection} collection
     */editCollection:function editCollection(collection){this.get('router').transitionTo(collection && collection.format === 'collection-external'?'content.external-collections.edit':'content.collections.edit',collection.get('id'),{queryParams:{isLibraryContent:true,isPreviewReferrer:false,editing:false}});}, /**
     * Remix course action, when clicking remix at the course card
     * @param {Content/Course}
     */remixCourse:function remixCourse(course){var remixModel={content:course};this.sendAction('onRemixCourse',remixModel);}, /**
     * Triggers the refresh of user classes
     */updateClass:function updateClass(){this.sendAction('updateUserClasses');}, /**
     * Action triggered to bookmark a course
     * @param {Course} course
     */onBookmarkCourse:function onBookmarkCourse(_ref,showType){var _this=this;var title=_ref.title;var id=_ref.id;var bookmark=_gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:title,contentId:id,contentType:_gooruWebConfigConfig.CONTENT_TYPES.COURSE});this.createBookmark(bookmark).then(function(){return _this.notifyBookmarkSuccess(bookmark,showType);});}, /**
     * Edit course action, when clicking Play at the course card
     * @param {Content/Course}
     */playIndependentContent:function playIndependentContent(_ref2){var _this2=this;var title=_ref2.title;var id=_ref2.id;var collectionType=_ref2.collectionType;var isCourse=!collectionType;var bookmark=_gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:title,contentId:id,contentType:isCourse?_gooruWebConfigConfig.CONTENT_TYPES.COURSE:collectionType});return this.createBookmark(bookmark).then(function(){if(isCourse){_this2.get('router').transitionTo('student.independent',id);}else {var queryParams={role:_gooruWebConfigConfig.ROLES.STUDENT,source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.INDEPENDENT_ACTIVITY,isIframeMode:true};_this2.get('router').transitionTo('player',id,{queryParams:queryParams});}});}, /**
     * Action triggered to bookmark a collection or assessment
     * @param {Collection/Assessment} content
     */onBookmarkContent:function onBookmarkContent(_ref3,showType){var _this3=this;var title=_ref3.title;var id=_ref3.id;var collectionType=_ref3.collectionType;var bookmark=_gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:title,contentId:id,contentType:collectionType});this.createBookmark(bookmark).then(function(){return _this3.notifyBookmarkSuccess(bookmark,showType);});}, /**
     * Edit course action, when clicking Edit at the course card
     * @param {Content/Course}
     */editCourse:function editCourse(course){var queryParams={userId:course.get('ownerId'),isLibraryContent:true,editing:false};this.get('router').transitionTo('content.courses.edit',course.get('id'),{queryParams:queryParams});}, /**
     * Edit course action, when clicking Play at the course card
     * @param {Content/Course}
     */playCourse:function playCourse(course){var queryParams={sourceType:this.get('type')};if(this.get('productId')){queryParams.productId = this.get('productId');}else if(this.get('libraryId')){queryParams.libraryId = this.get('libraryId');}this.get('router').transitionTo('content.courses.play',course.get('id'),{queryParams:queryParams});},selectSingleBox:function selectSingleBox(content){this.sendAction('selectSingleBox',content);}, /**
     * On card edit assessment button click
     * @param {Assessment} assessment
     */editAssessment:function editAssessment(assessment){this.get('router').transitionTo(assessment && assessment.format === 'assessment-external'?'content.external-assessments.edit':'content.assessments.edit',assessment.get('id'),{queryParams:{editingContent:true,isLibraryContent:true,isPreviewReferrer:false,editing:false}});}, /**
     * Action triggered when click preview button
     */onPreviewContent:function onPreviewContent(previewContent,previewContentType){var component=this;component.set('previewContent',previewContent);component.set('previewContentType',previewContentType);component.set('isShowContentPreview',true);}, /**
     * On card play question button click
     * @param {Question} question
     */playQuestion:function playQuestion(question){this.get('router').transitionTo('content.questions.play',question.get('id'));}, /**
     * On card edit question button click
     * @param {Question} question
     */editQuestion:function editQuestion(question){this.get('router').transitionTo('content.questions.edit',question.get('id'),{queryParams:{isLibraryContent:true,editing:false}});}, /**
     * On card remix question button click
     * @param {Question} question
     */remixQuestion:function remixQuestion(question){this.sendAction('onRemixQuestion',question);}, /**
     * Edit rubric
     */editRubric:function editRubric(resource){this.get('router').transitionTo('content.rubric.edit',resource.get('id'));}, /**
     * On card edit resource button click
     * @param {Resource} resource
     */editResource:function editResource(resource){this.get('router').transitionTo('content.resources.edit',resource.get('id'),{queryParams:{isLibraryContent:true}});}, /**
     * On card play resource button click
     * @param {Resource} resource
     */playResource:function playResource(resource){this.get('router').transitionTo('player-resource',resource.get('id'));},showModal:function showModal(type){this.sendAction('onShowCreateModal',type);}, //Action triggered when click on OA preview
onShowOfflineActivityPreview:function onShowOfflineActivityPreview(offlineActivity){var component=this;component.set('previewContent',offlineActivity);component.set('isShowOfflineActivityPreview',true);}}, // -------------------------------------------------------------------------
// Methods
handleShowMoreData:function handleShowMoreData(){var component=this;var loading=false;var container=_ember['default'].$('.library-content-result-grid');component.$(container).scroll(function(){if(!loading){var scrollTop=_ember['default'].$(container).scrollTop();var listContainerHeight=_ember['default'].$(container).height() + 500;var isScrollReachedBottom=scrollTop >= component.$(container).prop('scrollHeight') - listContainerHeight;if(isScrollReachedBottom){loading = true;component.sendAction('paginateNext');loading = false;}}});}, /**
   * Send bookmark info to BE for creation
   * @param bookmark
   */createBookmark:function createBookmark(bookmark){return this.get('bookmarkService').createBookmark(bookmark);}, /**
   * Show notification on bookmark success
   * @param bookmark
   * @param showType
   */notifyBookmarkSuccess:function notifyBookmarkSuccess(bookmark,showType){this.get('notifications').setOptions({positionClass:'toast-top-full-width',toastClass:'gooru-toast',timeOut:10000});var successMsg=showType?this.get('i18n').t('common.bookmarked-content-success',{contentType:bookmark.get('contentType')}):this.get('i18n').t('common.bookmarked-success');var independentLearningURL=this.get('router').generate('student-independent-learning');var buttonText=this.get('i18n').t('common.take-me-there');this.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + independentLearningURL + '">' + buttonText + '</a>');}});});