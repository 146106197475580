define('gooru-web/components/content/external-assessments/gru-external-assessment-edit',['exports','ember','gooru-web/components/content/collections/gru-collection-edit','gooru-web/config/config','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/utils/taxonomy','gooru-web/utils/utils'],function(exports,_ember,_gooruWebComponentsContentCollectionsGruCollectionEdit,_gooruWebConfigConfig,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebUtilsTaxonomy,_gooruWebUtilsUtils){exports['default'] = _gooruWebComponentsContentCollectionsGruCollectionEdit['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/course
   */externalAssessmentService:_ember['default'].inject.service('api-sdk/external-assessment'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * Collection model as instantiated by the route. This is the model used when not editing
   * or after any collection changes have been saved.
   * @property {Collection}
   */collection:null,isLoading:false,collectionType:'assessment-external',aggregatedTags:_ember['default'].computed('tempCollection.aggregatedTag.[]',function(){var aggregatedTags=_gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('tempCollection.aggregatedTag'),false,false,true);return aggregatedTags;}),selectedType:_ember['default'].computed('tempCollection',function(){var isScorm=this.get('tempCollection.subFormat')?2:1;return isScorm;}), // -------------------------------------------------------------------------
// Attributes
classNames:['content','assessments','gru-assessment-edit'], // -------------------------------------------------------------------------
// Actions
actions:{selectCategory:function selectCategory(category){var component=this;if(category === component.get('selectedCategory')){component.set('selectedCategory',null);}else {component.set('selectedCategory',category);}component.set('selectedSubject',null);}, /**
     * Save Content
     */updateContent:function updateContent(){var component=this;var editedAssessment=component.get('tempCollection');var assessment=component.get('collection');var etlHrs=editedAssessment.get('hours');var etlMins=editedAssessment.get('minutes');(0,_gooruWebUtilsUtils.etlSecCalculation)(editedAssessment,etlHrs,etlMins);editedAssessment.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){var imageIdPromise=new _ember['default'].RSVP.resolve(editedAssessment.get('thumbnailUrl'));if(editedAssessment.get('thumbnailUrl') && editedAssessment.get('thumbnailUrl') !== assessment.get('thumbnailUrl')){imageIdPromise = component.get('mediaService').uploadContentFile(editedAssessment.get('thumbnailUrl'));}imageIdPromise.then(function(imageId){editedAssessment.set('thumbnailUrl',imageId);component.get('externalAssessmentService').updateExternalAssessment(editedAssessment.get('id'),editedAssessment).then(function(){assessment.merge(editedAssessment,['title','learningObjectives','isVisibleOnProfile','thumbnailUrl','standards','audience','depthOfknowledge','centurySkills','hours','minutes','url','primaryLanguage']);component.set('isEditing',false);component.get('tempCollection.standards').forEach(function(suggeststanObj){suggeststanObj.set('isRemovable',false);});})['catch'](function(error){var message=component.get('i18n').t('common.errors.assessment-not-updated').string;component.get('notifications').error(message);_ember['default'].Logger.error(error);});});}component.set('didValidate',true);});}, /**
     * Add tag data from the taxonomy list in tempUnit
     */addTag:function addTag(taxonomyTag){taxonomyTag.set('isRemovable',true);taxonomyTag.set('tagAlreadyexist',false);this.get('tempCollection.standards').addObject(taxonomyTag);this.set('tempCollection.standards',this.get('tempCollection.standards').uniqBy('code'));this.get('tempCollection.aggregatedTag').removeObject(taxonomyTag);var newtaxonomyObj=_ember['default'].Object.create({code:taxonomyTag.get('code'),frameworkCode:taxonomyTag.get('frameworkCode'),isRemovable:false,tagAlreadyexist:false});this.get('tempCollection.aggregatedTag').addObject(newtaxonomyObj);this.compareAggregatedTags();}, /**
     * Remove tag data from the taxonomy list in tempUnit
     */removeTag:function removeTag(taxonomyTag){var tagData=taxonomyTag;this.get('tempCollection.standards').removeObject(tagData);tagData.set('isRemovable',false);tagData.set('tagAlreadyexist',false);this.get('tempCollection.aggregatedTag').addObject(tagData);this.set('tempCollection.aggregatedTag',this.get('tempCollection.aggregatedTag').uniqBy('code'));this.compareAggregatedTags();}, /**
     * Save setting for visibility of collection in profile
     */publishToProfile:function publishToProfile(){var collectionForEditing=this.get('collection').copy();this.set('tempCollection',collectionForEditing);this.actions.updateContent.call(this);}, /**
     * @type {String} the selected category
     */selectedCategory:_ember['default'].computed('collection',function(){var standard=this.get('collection.standards.firstObject');var subjectId=standard?(0,_gooruWebUtilsTaxonomy.getSubjectId)(standard.get('id')):null;return subjectId?(0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectId):null;}),selectedSubject:_ember['default'].computed('collection',function(){var standard=this.get('collection.standards.firstObject');if(standard){standard.set('subjectCode',(0,_gooruWebUtilsTaxonomy.getGutCodeFromSubjectId)((0,_gooruWebUtilsTaxonomy.getSubjectId)(standard.get('id'))));}return standard?standard:null;}), /**
     * Delete assessment
     */deleteItem:function deleteItem(){var myId=this.get('session.userId');var model={content:this.get('collection'),isHeaderDelete:true,parentName:this.get('course.title'),deleteMethod:(function(){return this.get('externalAssessmentService').deleteExternalAssessment(this.get('collection'));}).bind(this),type:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT,redirect:{route:'library-search',params:{profileId:myId,type:'my-content'}}};this.actions.showModal.call(this,'content.modals.gru-delete-content',model,null,null,null,false);}, //Action triggered when click back in the edit page
onClickBack:function onClickBack(){var isPreview=this.get('isPreviewReferrer');var router=this.get('router');var previewRoute='library-search';var myId=this.get('session.userId');var queryParams={profileId:myId,type:'my-content',activeContentType:'assessment'};if(isPreview){router.transitionTo(previewRoute,{queryParams:queryParams});}else {window.history.back();}},selectType:function selectType(type){this.set('selectedType',type);},selectFile:function selectFile(file){var component=this;component.set('isErrorScormFile',false);if(file){component.set('isLoading',true);component.get('mediaService').uploadScormFile(file).then(function(response){var tempCollection=component.get('tempCollection');var collection=component.get('collection');if(response && response.scorm_file_info && response.scorm_file_info.resources && response.scorm_file_info.resources.length){var url=component.get('session.cdnUrls.content') + response.filepath + response.scorm_file_info.resources[0].filename;tempCollection.set('url',url);collection.set('url',url);var scorm={scorm:{originalFilename:response.scorm_file_info.resources[0].filename,filepath:response.filepath,fileName:response.original_filename}};tempCollection.set('metadata',scorm);collection.set('metadata',scorm);var selectedType=component.get('selectedType');tempCollection.set('subFormat',selectedType === 1?'url':'scorm');collection.set('subFormat',selectedType === 1?'url':'scorm');component.set('isErrorScormFile',false);}else {component.set('isErrorScormFile',true);}component.set('isLoading',false);});}}}, /**
   * Returns compareAggregatedTags data
   * @param {Number[]} compareAggregatedTags ids
   * @return {compareAggregatedTags[]}
   */compareAggregatedTags:function compareAggregatedTags(){var component=this;component.get('tempCollection.aggregatedTag').forEach(function(suggeststanObj){_ember['default'].set(suggeststanObj,'tagAlreadyexist',true);});component.get('tempCollection.standards').forEach(function(standardObj){var suggestObj=component.get('tempCollection.aggregatedTag').findBy('code',standardObj.code);if(suggestObj !== undefined){_ember['default'].set(suggestObj,'tagAlreadyexist',false);}});},init:function init(){this._super.apply(this,arguments);var collection=this.get('collection').copy();this.set('tempCollection',collection);}});});