define('gooru-web/components/player/offline-activity/gru-offline-activity-task-submission',['exports','ember','gooru-web/config/config','gooru-web/utils/utils','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['offline-activity-player','gru-offline-activity-task-submission'], // -------------------------------------------------------------------------
// Dependencies
mediaService:_ember['default'].inject.service('api-sdk/media'),session:_ember['default'].inject.service('session'), /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'),offlineActivityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;if(!component.get('isReadOnly')){component.setFocusCurrentTask();}component.setDefaultProperties();component.$('div.task-description a').on('click',function(event){event.preventDefault();if(event.currentTarget.href){window.open(event.currentTarget.href,'_blank');}});}, // -------------------------------------------------------------------------
// Observer
/**
   * Observe task submission changes
   */observePendingItems:_ember['default'].observer('pendingUrlSubmissions','pendingUploadSubmissions','savedUploadSubmissions','task.submissionText',function(){var component=this;var pendingUploadSubmissions=component.get('savedUploadSubmissions');var freeeFormText=component.get('task.submissionText');var isFreeFormTextEntered=freeeFormText?freeeFormText.length:false;var mandatoryUploads=component.get('mandatoryUploads');var hasAddedMandatorySubmission=false;if(mandatoryUploads > 0){if(pendingUploadSubmissions <= 0){hasAddedMandatorySubmission = true;}}else if(isFreeFormTextEntered || pendingUploadSubmissions <= 0){hasAddedMandatorySubmission = true;}component.set('task.isAddedMandatorySubmission',hasAddedMandatorySubmission);}), // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when select a file from picker
onUploadFile:function onUploadFile(file){var component=this;var task=component.get('task');var uploadedFiles=task.get('files'); //add new file
uploadedFiles.pushObject(file);component.get('listOfFilesUploads').pushObject(_ember['default'].Object.create({isFileAvailable:false,isUploaded:false}));var fileType=(0,_gooruWebUtilsUtils.inferUploadType)(file.name,_gooruWebConfigConfig.OA_TASK_SUBMISSION_TYPES);file.fileType = fileType?fileType.value:'others';file.icon = _gooruWebConfigConfig.OA_TASK_SUBMISSION_TYPES.findBy('value',file.fileType).icon;component.set('task.isTaskSubmitted',false);}, //Action triggered when click on save
onSaveTask:function onSaveTask(){var component=this;component.set('isSubmittingTask',true);component.uploadFilesToS3().then(function(){component.set('isSubmittingTask',false);component.submitTaskDetails(component.createTaskSubmissionPayload());component.set('task.isTaskSubmitted',true);component.send('onToggleTask');});}, //Action triggered when click on toggle task container
onToggleTask:function onToggleTask(){var component=this;component.toggleProperty('isTaskExpanded');component.$('.task-details-container').slideToggle();}, //Action triggered when click on remove selected file
onRemoveFile:function onRemoveFile(filePos){this.removeFile(filePos);}, //Action triggered when click on plus button in the url
onAppendUrl:function onAppendUrl(){this.get('task.urls').pushObject(_ember['default'].Object.create({value:null,isSubmittedUrl:false}));},filePreview:function filePreview(){var component=this;component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CA_ITEM_GRADE_OFFLINE_UPLOADS);},onUrls:function onUrls(){var component=this;component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CA_ITEM_GRADE_OFFLINE_URLS);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} studentTaskSubmissions
   * Property for list of student submission respective to task
   */studentTaskSubmissions:_ember['default'].computed('task.studentTaskSubmissions',function(){var component=this;var studentTaskSubmissions=component.get('task.studentTaskSubmissions');return studentTaskSubmissions?studentTaskSubmissions:_ember['default'].A([]);}), /**
   * @property {Array} studentTaskUploadSubmission
   * Property for list of student task upload submissions
   */studentTaskUploadSubmission:_ember['default'].computed.filter('studentTaskSubmissions',function(taskSubmission){return taskSubmission.submissionType === 'uploaded';}), /**
   * @property {Array} studentTaskUrlSubmission
   * Property for list of student task url submissions
   */studentTaskUrlSubmission:_ember['default'].computed.filter('studentTaskSubmissions',function(taskSubmission){return taskSubmission.submissionType === 'remote';}), /**
   * @property {Array} oaTaskSubmissions
   * Property to capture student submissions
   */oaTaskSubmissions:_ember['default'].computed.alias('task.oaTaskSubmissions'), /**
   * @property {Array} oaTaskUploadSubmissions
   * Property to capture student uploaded submissions
   */oaTaskUploadSubmissions:_ember['default'].computed.filter('oaTaskSubmissions',function(submission){return submission.get('taskSubmissionType') === 'uploaded';}), /**
   * @property {Number} mandatoryUploads
   * Property for number of mandatory upload count
   */mandatoryUploads:_ember['default'].computed('oaTaskSubmissions',function(){var component=this;var oaTaskSubmissions=component.get('oaTaskSubmissions');var uploadSubmissions=oaTaskSubmissions.filter(function(submission){return submission.get('taskSubmissionType') === 'uploaded';});return uploadSubmissions.length;}), /**
   * @property {Number} pendingUploadSubmissions
   * Property for number of upload submissions pending
   */pendingUploadSubmissions:_ember['default'].computed('task.files.[]','mandatoryUploads','studentTaskUploadSubmission',function(){var component=this;var uploadedFilesCount=component.get('task.files.length');var mandatoryUploads=component.get('mandatoryUploads');var studentTaskUploadSubmission=component.get('studentTaskUploadSubmission.length');return mandatoryUploads > studentTaskUploadSubmission?mandatoryUploads - (uploadedFilesCount + studentTaskUploadSubmission):0;}), /**
   * @property {Number} savedUploadSubmissions
   * Property for number of upload submissions pending
   */savedUploadSubmissions:_ember['default'].computed('task.files.[]','mandatoryUploads','studentTaskUploadSubmission',function(){var component=this;var uploadedFilesCount=component.get('task.files.length');var mandatoryUploads=component.get('mandatoryUploads');var studentTaskUploadSubmission=component.get('studentTaskUploadSubmission.length');return mandatoryUploads > studentTaskUploadSubmission?mandatoryUploads - (uploadedFilesCount + studentTaskUploadSubmission):0;}), /**
   * @property {Array} oaTaskRemoteSubmissions
   * Property to capture student added url submissions
   */oaTaskRemoteSubmissions:_ember['default'].computed.filter('oaTaskSubmissions',function(submission){return submission.get('taskSubmissionType') === 'remote';}), /**
   * @property {Number} mandatoryUrls
   * Property for number of mandatory url submissions
   */mandatoryUrls:_ember['default'].computed('oaTaskRemoteSubmissions',function(){var component=this;return component.get('oaTaskRemoteSubmissions.length');}), /**
   * @property {Number} pendingUrlSubmissions
   * Property for number of url submissions pending
   */pendingUrlSubmissions:_ember['default'].computed('task.urls.@each.value','mandatoryUrls','studentTaskUrlSubmission',function(){var component=this;var taskUrls=component.get('task.urls');var mandatoryUrls=component.get('mandatoryUrls');var studentTaskUrlSubmission=component.get('studentTaskUrlSubmission.length');var uploadedUrls=taskUrls?taskUrls.filter(function(url){return url.value;}):_ember['default'].A([]);return mandatoryUrls > studentTaskUrlSubmission?mandatoryUrls - (uploadedUrls.length + studentTaskUrlSubmission):0;}), /**
   * @property {Boolean} isUploadedRequiredTaskFiles
   * Property to check is student uploaded required files
   */isUploadedRequiredTaskFiles:_ember['default'].computed('task.files.[]','studentTaskUploadSubmission',function(){var component=this;var uploadedFiles=component.get('task.files');var mandatoryUploads=component.get('mandatoryUploads');var studentTaskUploadSubmission=component.get('studentTaskUploadSubmission.length');return uploadedFiles?uploadedFiles.length + studentTaskUploadSubmission >= mandatoryUploads:false;}), /**
   * @property {Boolean} isAddedRequiredTaskUrls
   * Property to check is student added required urls
   */isAddedRequiredTaskUrls:_ember['default'].computed('task.urls.@each.value','studentTaskUrlSubmission',function(){var component=this;var taskUrls=component.get('task.urls');var mandatoryUrls=component.get('mandatoryUrls');var studentTaskUrlSubmission=component.get('studentTaskUrlSubmission.length');var uploadedUrls=taskUrls?taskUrls.filter(function(url){return url.value;}):_ember['default'].A([]);return uploadedUrls.length + studentTaskUrlSubmission >= mandatoryUrls;}), /**
   * @property {Boolean} isEnableTaskSubmission
   * Property to check whether to enable or not save button
   */isEnableTaskSubmission:_ember['default'].computed('task.urls.@each.value','task.files.[]','task.submissionText','isValidTimespent',function(){var component=this;var addedUrls=component.get('task.urls')?component.get('task.urls').filter(function(url){return !url.get('isSubmittedUrl') && url.get('value');}).length:0;var addedFiles=component.get('task.files')?component.get('task.files').length:false;var addedAnswerText=component.get('task.submissionText');return addedUrls > 0 || addedFiles > 0 || addedAnswerText;}), /**
   * @property {Array} typeBasedMandatoryUploads
   * Property for list of type based mandatory submission count
   */typeBasedMandatoryUploads:_ember['default'].computed(function(){var component=this;var oaTaskUploadSubmissions=component.get('oaTaskSubmissions');var typeBasedMandatoryUploads=_ember['default'].A([]);_gooruWebConfigConfig.OA_TASK_SUBMISSION_TYPES.map(function(submissionType){var type=submissionType.value;var typeBasedSubmission=oaTaskUploadSubmissions.filterBy('taskSubmissionSubType',type);if(typeBasedSubmission.length){var typeBasedSubmissionCount=_ember['default'].Object.create({type:type,mandatorySubmissionCount:typeBasedSubmission.length,isUploaded:false,pendingCount:typeBasedSubmission.length});typeBasedMandatoryUploads.pushObject(typeBasedSubmissionCount);}});return typeBasedMandatoryUploads;}), /**
   * @property {Boolean} isTaskExpanded
   */isTaskExpanded:true, /**
   * @property {String} contentSource
   * Property for content source send to Analytics
   * TODO hardcoded due to currently support available at DCA
   */contentSource:'dailyclassactivity', /**
   * @property {UUID} userId
   */userId:_ember['default'].computed.alias('session.userId'), /**
   * @property {UUID} classId
   */classId:null, /**
   * @property {UUId} caContentId
   */caContentId:null, /**
   * @property {Array} listOfFilesUploads
   * Property to handle the visibility of number of files needs to be uploaded
   */listOfFilesUploads:_ember['default'].computed('mandatoryUploads',function(){return _ember['default'].A([_ember['default'].Object.create({isFileAvailable:false,isUploaded:false})]);}), /**
   * @property {Boolean} isStudentSubmitted
   * Property to check whether the student has submitted this task
   */isStudentSubmitted:_ember['default'].computed.gte('studentTaskSubmissions.length',1), /**
   * Maintains the mode the component view
   * @type {Boolean}
   */isReadOnly:false, /**
   * Maintains the value of show the submissions or not
   * @type {Boolean}
   */showSubmissions:false, /**
   * Maintains the value of show toggle or not
   * @type {Boolean}
   */showToggle:false, /*
   * @property {Number} timespentInMilliSec
   * Property for student submitted timespent in millisec
   */timespentInMilliSec:0, /**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}), // -------------------------------------------------------------------------
// Methods
/**
   * @function uploadFilesToS3
   * Method to upload list of files into S3
   */uploadFilesToS3:function uploadFilesToS3(){var component=this;var files=component.get('task.files').filter(function(file){return !file.isUploaded;});var uploadedFilesPromise=files.map(function(file){return component.uploadFileIntoS3(file).then(function(fileObject){return fileObject;});});return _ember['default'].RSVP.allSettled(uploadedFilesPromise);}, /**
   * @function uploadFileIntoS3
   * Method to upload given file into S3
   */uploadFileIntoS3:function uploadFileIntoS3(fileObject){var component=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){return _ember['default'].RSVP.hash({fileLocation:component.get('mediaService').uploadContentFile(fileObject)}).then(function(_ref){var fileLocation=_ref.fileLocation;var cdnUrls=component.get('session.cdnUrls');var UUIDFileName=(0,_gooruWebUtilsUtils.cleanFilename)(fileLocation,cdnUrls);fileObject.UUIDFileName = UUIDFileName;fileObject.isUploaded = true;return resolve(fileObject);},reject);});}, /**
   * @function submitTaskDetails
   * Method to send student task submissions into Analytics
   */submitTaskDetails:function submitTaskDetails(taskSubmissionPayload){var component=this;component.get('offlineActivityService').oaTaskSubmissions(taskSubmissionPayload);}, /**
   * @function createTaskSubmissionPayload
   * Method to create task submission request payload
   */createTaskSubmissionPayload:function createTaskSubmissionPayload(){var component=this;var task=component.get('task');var userId=component.get('userId');var contentSource=component.get('contentSource');var classId=component.get('classId');var caContentId=component.get('caContentId');var taskSubmissions=[];var uploadedFiles=task.get('files').filter(function(file){return !file.isSubmitted;});var timespentInMilliSec=component.get('timespentInMilliSec');uploadedFiles.map(function(uploadedFile){var submissionContext=_ember['default'].Object.create({submissionValue:uploadedFile.UUIDFileName,submissionType:'uploaded',submissionSubType:uploadedFile.fileType,submissionOriginalName:uploadedFile.name});taskSubmissions.push(component.getTaskSubmissionContext(submissionContext));uploadedFile.isSubmitted = true;}); //fetch only newly added urls
var submissionUrls=task.get('urls').filter(function(taskUrl){return taskUrl.get('value') && !taskUrl.get('isSubmittedUrl');});submissionUrls.map(function(submissionUrl){var submissionContext=_ember['default'].Object.create({submissionValue:submissionUrl.get('value'),submissionType:'remote',submissionSubType:'url'});taskSubmissions.push(component.getTaskSubmissionContext(submissionContext));submissionUrl.set('isSubmittedUrl',true);}); //Task free form text data
var freeFormTextSubmissionContext=_ember['default'].Object.create({submissionValue:task.get('submissionText'),submissionType:'free-form-text',submissionSubType:'free-form-text'});taskSubmissions.push(component.getTaskSubmissionContext(freeFormTextSubmissionContext));var submissionPayload={student_id:userId,class_id:classId,oa_id:task.get('oaId'),content_source:contentSource,submissions:taskSubmissions,time_spent:timespentInMilliSec};if(component.get('isStudyPlayer')){submissionPayload.course_id = component.get('courseId');submissionPayload.unit_id = component.get('unitId');submissionPayload.lesson_id = component.get('lessonId');}else {submissionPayload.oa_dca_id = parseInt(caContentId);}return submissionPayload;}, /**
   * @function getTaskSubmissionContext
   * Metho to create individual submission payload
   */getTaskSubmissionContext:function getTaskSubmissionContext(submissionContext){var component=this;var task=component.get('task');return {task_id:task.get('id'),submission_info:submissionContext.get('submissionValue'),submission_type:submissionContext.get('submissionType'),submission_subtype:submissionContext.get('submissionSubType'),submission_originalfilename:submissionContext.get('submissionOriginalName')};}, /**
   * @function removeFile
   * Method to remove selected file
   */removeFile:function removeFile(){return true;}, /**
   * @function setDefaultProperties
   * Method to set default values to properties
   */setDefaultProperties:function setDefaultProperties(){var component=this;component.set('task.files',_ember['default'].A([]));component.set('task.urls',_ember['default'].A([]));component.set('fileUploadErrors',_ember['default'].A());if(component.get('isStudentSubmitted')){(function(){var studentTaskUrlSubmission=component.get('studentTaskUrlSubmission');var taskUrls=component.get('task.urls');component.set('task.isTaskSubmitted',true);studentTaskUrlSubmission.forEach(function(taskUrlSubmission,urlIndex){var taskUrl=taskUrls.objectAt(urlIndex);if(taskUrl){taskUrl.set('value',taskUrlSubmission.submissionInfo);taskUrl.set('isSubmittedUrl',true);}else {taskUrls.pushObject(_ember['default'].Object.create({value:taskUrlSubmission.submissionInfo,isSubmittedUrl:true}));}});})();}if(!component.get('isReadOnly')){component.get('task.urls').pushObject(_ember['default'].Object.create({value:null,isSubmittedUrl:false}));}}, /**
   * Sets focus on last not submitted task
   */setFocusCurrentTask:function setFocusCurrentTask(){var component=this;var $component=this.$();if(component.get('task.focus',true)){_ember['default'].run.later(function(){$component.get(0).scrollIntoView();var rte=$component.find('.rich-editor');rte.focus();},1000);}} //})
});});