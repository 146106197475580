define('gooru-web/components/content/modals/gru-collection-remix',['exports','ember','gooru-web/components/content/modals/gru-base-remix'],function(exports,_ember,_gooruWebComponentsContentModalsGruBaseRemix){exports['default'] = _gooruWebComponentsContentModalsGruBaseRemix['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Collection service API SDK
   */collectionService:_ember['default'].inject.service('api-sdk/collection'),lessonService:_ember['default'].inject.service('api-sdk/lesson'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-collection-remix'],isCollectionType:_ember['default'].computed('model',function(){return this.get('model.content.type') === 'collections-external';}), // -------------------------------------------------------------------------
// Actions
copyContent:function copyContent(collection){this.set('isLoading',true);var type=this.get('isCollectionType')?'collections-external':'collections';return this.get('collectionService').copyCollection(collection.get('id'),type);},updateContent:function updateContent(collection){var component=this;var type=this.get('isCollectionType')?'collections-external':'collections';return component.get('collectionService').updateCollectionTitle(collection.get('id'),collection.get('title'),type).then(function(){var courseId=component.get('courseId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var collectionId=component.get('contentModel.id');var isCollection=component.get('isCollection');return lessonId?component.get('lessonService').associateAssessmentOrCollectionToLesson(courseId,unitId,lessonId,collectionId,isCollection):_ember['default'].RSVP.resolve();});},showSuccessNotification:function showSuccessNotification(collection){var component=this;var successMsg=component.get('i18n').t('common.remix-collection-success',{collectionTitle:collection.get('title')});var collectionEditUrl=component.get('router').generate(collection && collection.type === 'collections-external'?'content.external-collections.edit':'content.collections.edit',collection.get('id'));var edit=component.get('i18n').t('common.edit');component.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + collectionEditUrl + '?editingContent=true' + '">' + edit + '</a>');},showFailureNotification:function showFailureNotification(){var message=this.get('i18n').t('common.errors.collection-not-copied').string;this.get('notifications').error(message);},init:function init(){this._super.apply(this,arguments);this.set('courseId',this.get('model.courseId'));this.set('unitId',this.get('model.unitId'));this.set('lessonId',this.get('model.lessonId'));this.set('isCollection',this.get('model.isCollection'));}, /**
   * Disable remix button once user clicked it
   */isLoading:false});});