define('gooru-web/adapters/profile/courses',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){ /**
 * Adapter to get the list of courses created by a Profile
 *
 * @typedef {Object} ProfileCourseAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/profiles', /**
   * Get the list of Courses of a specified profile and filter by subject
   *
   * @param profileId the Profile Id owner of the courses
   * @param subject this is an option parameter to filter the courses
   * @returns {Promise}
   */getCourses:function getCourses(profileId,subject){var params=arguments.length <= 2 || arguments[2] === undefined?{}:arguments[2];var filter=arguments.length <= 3 || arguments[3] === undefined?{}:arguments[3];var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + profileId + '/courses';var page=params.page || 0;var pageSize=params.pageSize || _gooruWebConfigConfig.DEFAULT_PAGE_SIZE;var offset=page * pageSize;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{limit:pageSize,offset:offset}};if(subject){options.data.subject = subject;}options.data = Object.assign(options.data,filter);return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});