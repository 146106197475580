define('gooru-web/routes/resource-player',['exports','ember','quizzes-addon/routes/resource-player','gooru-web/mixins/private-route-mixin','gooru-web/config/config'],function(exports,_ember,_quizzesAddonRoutesResourcePlayer,_gooruWebMixinsPrivateRouteMixin,_gooruWebConfigConfig){ /**
 * Study Player Route
 *
 * The context player route extends the player route to provide the study player
 * controller with additional information available only to signed-in users
 *
 * @module
 * @extends PlayerRoute
 */exports['default'] = _quizzesAddonRoutesResourcePlayer['default'].extend(_gooruWebMixinsPrivateRouteMixin['default'],{templateName:'resource-player', /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), /**
   * @type {UnitService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {UnitService} Service to retrieve unit information
   */unitService:_ember['default'].inject.service('api-sdk/unit'), /**
   * @type {LessonService} Service to retrieve lesson information
   */lessonService:_ember['default'].inject.service('api-sdk/lesson'), /**
   * @type {AssessmentService} Service to retrieve assessment information
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @type {CollectionService} Service to retrieve collection information
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * When the next button is clicked
     */onNext:function onNext(){var _this=this;var controller=this.get('controller');var queryParams={role:_gooruWebConfigConfig.ROLES.STUDENT,source:controller.get('source')};var classId=controller.get('classId');if(classId){queryParams.classId = classId;}var navigateMapService=this.get('navigateMapService');navigateMapService.getStoredNext().then(function(mapLocation){return navigateMapService.next(mapLocation.context);}).then(function(mapLocation){var status=(mapLocation.get('context.status') || '').toLowerCase();if(status === 'done'){controller.set('isDone',true);}else {_this.transitionTo('study-player',controller.get('course.id'),{queryParams:queryParams});}});}}, // -------------------------------------------------------------------------
// Methods
/**
   * @param {{ collectionId: string, resourceId: string }} params
   */model:function model(params){var _this2=this;var route=this;var classId=params.classId;var courseId=params.courseId;var collectionUrl=params.collectionUrl;var isIframeMode=params.isIframeMode;var navigateMapService=this.get('navigateMapService');return navigateMapService.getMapLocation(params).then(function(currentContext){var unitId=currentContext.get('context.unitId');var lessonId=currentContext.get('context.lessonId');var collectionId=currentContext.get('context.collectionId');var collectionType=currentContext.get('context.collectionType') || currentContext.get('context.itemType');params.unitId = unitId;params.lessonId = lessonId;params.collectionId = collectionId;params.pathId = currentContext.get('context.pathId');params.sourceUrl = location.host;params.partnerId = _this2.get('session.partnerId');params.tenantId = _this2.get('session.tenantId');params.ctxPathType = currentContext.get('context.ctxPathType');var ctxPathType=params.ctxPathType;return _ember['default'].RSVP.hash({ //loading breadcrumb information and navigation info
course:route.get('courseService').fetchById(courseId),unit:ctxPathType && ctxPathType !== 'route0'?route.get('unitService').fetchById(courseId,unitId):null,lesson:ctxPathType && ctxPathType !== 'route0'?route.get('lessonService').fetchById(courseId,unitId,lessonId):null,collection:_this2.loadCollection(collectionId,collectionType)}).then(function(hash){ // Set the correct unit sequence number
hash.course.children.find(function(child,index){var found=false;if(hash.unit){if(child.get('id') === hash.unit.get('id')){found = true;hash.unit.set('sequence',index + 1);}}return found;}); // Set the correct lesson sequence number
if(hash.unit){hash.unit.children.find(function(child,index){var found=false;if(child.get('id') === hash.lesson.get('id')){found = true;hash.lesson.set('sequence',index + 1);}return found;});}var course=hash.course;var unit=hash.unit;var lesson=hash.lesson;var collection=hash.collection;return _this2.quizzesModel(params).then(function(quizzesModel){return Object.assign(quizzesModel,{course:course,unit:unit,lesson:lesson,collection:collection,classId:classId,collectionUrl:collectionUrl,isIframeMode:isIframeMode});});});});},setupController:function setupController(controller,model){controller.setProperties({course:model.course,unit:model.unit,lesson:model.lesson,classId:model.classId,collection:model.collection,collectionUrl:model.collectionUrl,isIframeMode:model.isIframeMode});this._super.apply(this,arguments);},loadCollection:function loadCollection(collectionId,type){var route=this;var isCollection=type === 'collection';var isAssessment=type === 'assessment';var loadAssessment=!type || isAssessment;var loadCollection=!type || isCollection;return _ember['default'].RSVP.hashSettled({assessment:loadAssessment?route.get('assessmentService').readAssessment(collectionId):false,collection:loadCollection?route.get('collectionService').readCollection(collectionId):false}).then(function(hash){var collectionFound=hash.assessment.state === 'rejected' || hash.assessment.value === false;return collectionFound?hash.collection.value?hash.collection.value.toPlayerCollection():undefined:hash.assessment.value?hash.assessment.value.toPlayerCollection():undefined;});}});});