define('gooru-web/components/content/questions/answers/gru-multiple-answer',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-multiple-answer'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Add new answer choice
     */addNewChoice:function addNewChoice(){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,isCorrect:false,type:'text'});this.get('answers').pushObject(newChoice);}, /**
     * Remove existing answer
     */removeChoice:function removeChoice(answer){this.get('answers').removeObject(answer);}, /**
     * Select answer correctness
     */setCorrect:function setCorrect(answer,value){_ember['default'].set(answer,'isCorrect',value);}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Hot Spot Text Answers
   */answers:null, /**
   * Is in edit mode
   */editMode:false, /**
   * @property {boolean}
   */disableEditorButtons:_ember['default'].computed.not('showAdvancedEditor')});});