define('gooru-web/components/grading/gru-roster-header',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-roster-header'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Open student roster
     */openRoster:function openRoster(){this.sendAction('onOpenRoster');}}, // -------------------------------------------------------------------------
// Properties
/**
   * Text to show as current response
   * @property {String} currentResponse
   */currentResponse:'', /**
   * Text to show as submitted at
   * @property {String} submittedAt
   */submittedAt:'', /**
   * Text to show as time spent
   * @property {String} currentResponse
   */timeSpent:''});});