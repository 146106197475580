define('gooru-web/components/player/questions/gru-classic',['exports','gooru-web/components/player/questions/gru-question','ember'],function(exports,_gooruWebComponentsPlayerQuestionsGruQuestion,_ember){ /**
 * Classic
 *
 * Component responsible for controlling the logic and appearance of a classic
 * question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-classic'], // -------------------------------------------------------------------------
// Actions
answers:_ember['default'].computed.alias('question.answers'),readOnly:false, // -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Event
initInputEvents:(function(){var _this=this;if(this.get('baseQuestion') || this.get('question.answers')){(function(){var answers=_this.get('baseQuestion.answers');if(_this.get('readOnly') && !_this.get('baseQuestion.answers')){answers = _this.get('question.answers') || {};}var answersObj=[];answers.forEach(function(item){if(item.correctAnswer && item.correctAnswer.length){answersObj.push({answer_text:item.correctAnswer[0] || '',text_image:item.textImage,additional_letters:item.additionalLetters?item.additionalLetters.map(function(letter){return letter.text;}):[]});}});_this.injectEncoding(answersObj);})();}}).on('didInsertElement'), // -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Methods
injectEncoding:function injectEncoding(baseAnswers){var user={userId:'userId'};var accessibilitySettings=JSON.parse(window.localStorage.getItem('accessibility_settings'));var content={contentId:'contentId',contentTitle:'contentTitle',answers:baseAnswers,isHighContrast:accessibilitySettings && accessibilitySettings.is_high_contrast_enabled?accessibilitySettings.is_high_contrast_enabled:false};window.serp.languageDecode().select('#classic-player-container').dataIn(user,null,content).classic().render().listener(function(){return;});}});});