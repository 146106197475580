define('gooru-web/components/class/activities/gru-class-activities-default-adding-view',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({classNames:['gru-class-activities-default-adding-view'], // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} todayActivities it has today's activities
   */todayActivities:null, /**
   * @property {boolean} isFetchingContents
   */isFetchingContents:false, /**
   * @property {Array} activeSequence has list of active content source list
   */activeSequence:_ember['default'].A([]), // -------------------------------------------------------------------------
// Hooks
// -------------------------------------------------------------------------
// Actions
actions:{ // Action trigger when click on content preview
onShowContentPreview:function onShowContentPreview(content){this.sendAction('onShowContentPreview',content);}, // Action trigger when click on add activites from right panel
onAddActivityPop:function onAddActivityPop(content){this.sendAction('onAddActivityPop',content);}, // Action trigger when click on add activities from popup
onAddActivity:function onAddActivity(content){var startDate=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var endDate=arguments.length <= 2 || arguments[2] === undefined?null:arguments[2];this.sendAction('onAddActivity',content,startDate,endDate);},onClickShowMore:function onClickShowMore(sequenceLevel){this.sendAction('onDefaultShowMore',{page:sequenceLevel.get('page'),sequenceIndex:sequenceLevel.get('sequenceIndex')});}}});});