define('gooru-web/adapters/performance/activity-performance-summary',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){ /**
 * Adapter ActivityPerformanceSummary
 *
 * @typedef {Object} ActivityPerformanceSummaryAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service(),namespace:'/api/nucleus-insights/v2', /**
   * Finds activity performance summary for the ids provided
   * @param {string} userId user id, useful to the get the performance for an specific class user
   * @param {string} classId optional class id filter
   * @param {string[]} activityIds
   * @param {string} activityType collection|assessment
   * @param {Date} startDate optional start date, default is now
   * @param {Date} endDate optional end date, default is now
   * @returns {Ember.RSVP.Promise}
   */findClassActivityPerformanceSummaryByIds:function findClassActivityPerformanceSummaryByIds(userId,classId,activityIds,activityType){var startDate=arguments.length <= 4 || arguments[4] === undefined?new Date():arguments[4];var endDate=arguments.length <= 5 || arguments[5] === undefined?new Date():arguments[5];var namespace=this.get('namespace');var url=namespace + '/class/' + classId + '/activity';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',headers:this.defineHeaders(),data:JSON.stringify({userId:userId,collectionType:activityType,startDate:(0,_gooruWebUtilsUtils.formatDate)(startDate,'YYYY-MM-DD'),endDate:(0,_gooruWebUtilsUtils.formatDate)(endDate,'YYYY-MM-DD'),collectionIds:activityIds})};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});}, /**
   * @function findClassActivityPerformanceSummaryByIds
   * Get performance of offline class activity
   */findOfflineClassActivityPerformanceSummaryByIds:function findOfflineClassActivityPerformanceSummaryByIds(classId,oaIds,userId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/class/' + classId + '/activity';var options={type:'POST',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:JSON.stringify({dcaContentIds:oaIds,collectionType:'offline-activity',userId:userId})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});