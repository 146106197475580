define('gooru-web/components/reports/assessment/questions/gru-reorder',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * Multiple choice
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-reorder'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
answers:_ember['default'].computed('question.answerObject.[]',function(){var component=this;var question=component.get('question');var questionUtil=component.getQuestionUtil(question);var userAnswers=component.get('userAnswer');var correctAnswers=questionUtil.getCorrectAnswer();var answers=question.get('answers').sortBy('order');var userAnswersWithText=userAnswers && userAnswers.map(function(userAns){var userAnsValue=answers.findBy('id',userAns),userAnsText=userAnsValue?userAnsValue.text:'';return {value:userAns,userAnsText:userAnsText};});return answers.map(function(answer,inx){var userAnswerAtIndex=userAnswers && userAnswers.objectAt(inx);var correctAnswerAtIndex=correctAnswers.objectAt(inx);return {showCorrect:component.get('showCorrect'),selectedOrderText:userAnswersWithText && userAnswersWithText.length > 0 && userAnswersWithText[inx].userAnsText,selectedOrder:userAnswers && userAnswers.indexOf(correctAnswerAtIndex) + 1,text:answer.get('text'),correct:questionUtil.isAnswerChoiceCorrect(userAnswerAtIndex,inx)};});}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});