define('gooru-web/components/gru-preview-course',['exports','ember','gooru-web/mixins/session','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsSession,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsSession['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-preview-course'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to remix the course
     * @param content
     */remixCourse:function remixCourse(){this.get('model').remixCourse();}, /**
     * Action triggered to bookmark the course
     * @param content
     */bookmarkCourse:function bookmarkCourse(){this.get('model').bookmarkCourse();}, /**
     * Action triggered to play the course
     * @param content
     */playCourse:function playCourse(){this.get('model').playCourse();this.triggerAction({action:'closeModal'});}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);component.set('course',component.get('model.content'));component.set('isTeacher',component.get('model.isTeacher'));component.set('isStudent',component.get('model.isStudent'));}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Course} course
   */course:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('course.taxonomy.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('course.taxonomy'));}), /**
   * Indicates if the teacher is seeing the course card
   * @property {boolean}
   */isTeacher:null, /**
   * Indicates if the student is seeing the course card
   * @property {boolean}
   */isStudent:null});});