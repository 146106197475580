define('gooru-web/gru-exemplar-attachment',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({classNames:['gru-references'], /**
   * Activity model dirty copy passed by caller, for saving exemplar and reference
   */model:null, /**
   * Collection model as passed by the route. The dirty model used for editing and saving changes to.
   * This is sent to parent for syncing.holds list of references in the activity
   * @property {references}
   */references:null,isEditing:false, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);}, // -------------------------------------------------------------------------
// Properties
createNewReference:null,actions:{ /**
     * Action to save reference, and exemplar fields
     */updateContent:function updateContent(){this.get('updateContent')(this.get('model'));this.set('isEditing',false);}, /**
     * Action to save/ add reference
     */updateReferenceCollection:function updateReferenceCollection(reference){var component=this;var refsCol=component.get('references');refsCol.pushObject(reference);component.refreshReference();component.get('updateParent')();},deleteReference:function deleteReference(refitem){var component=this;if(refitem && refitem.length > 0){refitem = refitem[0];}else {return;}component.deleteReferenceItem(refitem);},deleteUrlReference:function deleteUrlReference(refitem){var component=this;component.deleteReferenceItem(refitem);}},deleteReferenceItem:function deleteReferenceItem(refitem){var component=this;component.get('questionService').deleteReference(refitem).then(function(refItem){var refsCol=component.get('references');refsCol.removeObject(refItem);component.refreshReference();component.get('updateParent')();});},refreshReference:function refreshReference(){var component=this;var refsCol=component.get('references');var newSource=refsCol.slice(0);_ember['default'].set(this,'references',newSource);component.set('references',refsCol);}});});