define('gooru-web/components/reports/pull-up/lesson-report-listview',['exports','ember','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','pull-up-lesson-report-listview'], // -------------------------------------------------------------------------
// Properties
/**
   * This property will get change based on filter selection.
   * @type {Boolean}
   */isPerformanceFltApplied:false, /**
   * This property will get change based on filter selection, by default timespent filter off.
   * @type {Boolean}
   */isTimeSpentFltApplied:false, /**
   * List of contents associated with lesson
   * @type {Array}
   */contents:_ember['default'].A(), /**
   * Students report data
   * @type {Array}
   */studentReportData:_ember['default'].A(), /**
   * Maintain the status of sort by firstName
   * @type {String}
   */sortByFirstnameEnabled:false, /**
   * Maintain the status of sort by lastName
   * @type {String}
   */sortByLastnameEnabled:true, /**
   * Maintain the status of sort by score
   * @type {String}
   */sortByScoreEnabled:false, /**
   * Maintain the status of sort by Time spent
   * @type {String}
   */sortByTimeSpentEnabled:false, /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Actions
actions:{sortByFirstName:function sortByFirstName(){this.sendAction('sortByFirstName');},sortByLastName:function sortByLastName(){this.sendAction('sortByLastName');},sortByScore:function sortByScore(){this.sendAction('sortByScore');},sortByTimeSpent:function sortByTimeSpent(){this.sendAction('sortByTimeSpent');},openCollectionReport:function openCollectionReport(collection,collections){var component=this;component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_LJ_IS_MILESTONE_LESSON_CFU);this.sendAction('openCollectionReport',collection,collections);},openStudentLessonReport:function openStudentLessonReport(userId){var component=this;component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_LJ_IS_MILESTONE_LESSON_USER);this.sendAction('openStudentLessonReport',userId);},onClickScrollLeftArrow:function onClickScrollLeftArrow(){var component=this;var scrollLeft=component.$('#table-fixed-right-xs').scrollLeft() - 400;component.$('#table-fixed-right-xs').animate({scrollLeft:scrollLeft + 'px'},400);},onClickScrollRightArrow:function onClickScrollRightArrow(){var component=this;var scrollLeft=component.$('#table-fixed-right-xs').scrollLeft() + 400;component.$('#table-fixed-right-xs').animate({scrollLeft:scrollLeft + 'px'},400);}}});});