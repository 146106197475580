define('gooru-web/components/class/activities/gru-content-resource-filter',['exports','ember','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{classNames:['gru-content-resource-filter'], /**
   * @type {scopeAndSequenceService}
   */scopeAndSequenceService:_ember['default'].inject.service('api-sdk/scope-sequence'), /**
   * @type {taxonomyService}
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'),tenantService:_ember['default'].inject.service('api-sdk/tenant'), // ----------------------------------------------------------------------
// Dependencies
// ----------------------------------------------------------------------
// Properties
/**
   * @property {Object} primaryClass has current class details
   */primaryClass:null, /**
   * @property {boolean} hasCourse help to identify class has course or not
   */hasCourse:_ember['default'].computed('primaryClass',function(){return !!this.get('primaryClass.courseId');}), /**
   * @property {Array} contentResource help to hold the list of content sources for the class
   */contentResource:_ember['default'].A([]), /**
   * @property {Array} selectedContentResource help to hold selected content resources
   */selectedContentResource:_ember['default'].computed('contentResource.@each.isActive',function(){var activeContentResource=this.get('contentResource').filterBy('isActive',true);return activeContentResource;}),selectedFilters:_ember['default'].A(), /**
   * @property {Array} audienceList it has audience type
   */audienceList:_ember['default'].A([_ember['default'].Object.create({name:'Teachers',label:'Teachers'}),_ember['default'].Object.create({name:'All Students',label:'Students'})]),switchOptions:_ember['default'].A([_ember['default'].Object.create({label:'common.yes',value:true}),_ember['default'].Object.create({label:'common.no',value:false})]), /**
   * @property {Array} selectedAudienceList
   */selectedAudienceList:_ember['default'].computed.filterBy('audienceList.@each.isActive','isActive',true), /**
   * @property {Object} additionalFilter
   */additionalFilter:_ember['default'].computed('selectedAudienceList.@each','activeCompetencyList.@each',function(){var filterList={filters:{}};var activeAudience=this.get('selectedAudienceList');var activeCompetencies=this.get('activeCompetencyList');filterList.isStandardFilter = !!activeCompetencies.findBy('filter','flt.standard');filterList.filters['flt.audience'] = activeAudience.length?activeAudience.mapBy('name').toString():{};filterList.standard = activeCompetencies?activeCompetencies.mapBy('id'):[];filterList.filters['flt.taxGrade'] = activeCompetencies.findBy('filter','flt.taxGrade')?activeCompetencies.findBy('filter','flt.taxGrade').name:null;filterList.filters['flt.subject'] = activeCompetencies.findBy('filter','flt.subject')?activeCompetencies.findBy('filter','flt.subject').id:null;filterList.filters['flt.domain'] = activeCompetencies.findBy('filter','flt.domain')?activeCompetencies.findBy('filter','flt.domain').id:null;return filterList;}), /**
   * @property {boolean} isFilterApplied
   */isFilterApplied:_ember['default'].computed('selectedAudienceList.@each','activeCompetencyList.@each','contentResource.@each.isActive',function(){var activeAudience=this.get('selectedAudienceList');var activeCompetencies=this.get('activeCompetencyList');var contentResource=this.get('contentResource');var selectedContentResource=this.get('selectedContentResource');return activeAudience.length > 0 || activeCompetencies.length > 0 || JSON.stringify(contentResource) !== JSON.stringify(selectedContentResource);}),isShowdiagnosticKey:_ember['default'].computed(function(){return this.get('isEnableCaBaseline');}), /**
   * @property {Array} activeCompetencyList
   */activeCompetencyList:_ember['default'].A([]),isShowFilter:false,removedFilterItem:null, // ------------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){var _this=this;this.fetchContentSources().then(function(){var selectedContentResource=_this.get('selectedContentResource');_this.get('applyFilter')(selectedContentResource,_this.get('isFilterApplied'),_this.get('additionalFilter'));});}, // -----------------------------------------------------------------------
// Actions
actions:{ // Action trigger when select on checkbox
onSelectResourceContent:function onSelectResourceContent(selectedResource){selectedResource.toggleProperty('isActive');}, // Action trigger when click on any toggle
onToggleBox:function onToggleBox(container){this.set('isShowFilter',true);this.$('.' + container).slideToggle();}, // Action trigger when click on filter
applyFilters:function applyFilters(){this.$('.dropdown-blk').slideUp();var selectedContentResource=this.get('selectedContentResource').length?this.get('selectedContentResource'):this.get('contentResource');this.get('applyFilter')(selectedContentResource,this.get('isFilterApplied'),this.get('additionalFilter'),true,this.get('enableCaBaseline'));}, // Action trigger when click on domain
onSelectDomain:function onSelectDomain(domain){domain.set('isActive',!domain.get('isActive'));if(!domain.get('competenciesList')){this.fetchCompetencies(domain);}}, // Action trigger when click on competency from filter
onSelectCompetency:function onSelectCompetency(competency){var competencies=this.get('activeCompetencyList');competencies = competencies.filter(function(item){return item.id !== competency.id;}); /**Remove subject**/if(competency.filter === 'flt.subject'){var removeItem=competencies.filter(function(item){return item.filter === 'flt.subject' || item.filter === 'flt.fwCode' || item.filter === 'flt.taxGrade' || item.filter === 'flt.domain' || item.filter === 'flt.standard';});competencies.removeObjects(removeItem);this.set('selectedSubject',null);} /**Remove grade**/if(competency.filter === 'flt.taxGrade'){var removeItem=competencies.filter(function(item){return item.filter === 'flt.fwCode' || item.filter === 'flt.domain' || item.filter === 'flt.standard';});competencies.removeObjects(removeItem);} /**Remove domain**/if(competency.filter === 'flt.domain'){var removeItem=competencies.filter(function(item){return item.filter === 'flt.standard';});competencies.removeObjects(removeItem);}this.set('removedFilterItem',competency);this.set('activeCompetencyList',competencies);},onFilter:function onFilter(){var selectedFilters=this.get('selectedFilters');this.set('activeCompetencyList',selectedFilters);this.$('.domain-competency-blk').slideUp();},onEnableToggle:function onEnableToggle(isChecked){if(!isChecked){this.set('enableCaBaseline',false);}else {this.set('enableCaBaseline',true);}}}, // -------------------------------------------------------------------------
// Methods
// Methods help to parse the path params
parseDefaultSequenceFilter:function parseDefaultSequenceFilter(){return {'course-map':{'flt.courseId':this.get('primaryClass.courseId'),'flt.publishStatus':'published,unpublished'},'open-library':{scopeKey:'open-library','flt.publishStatus':'published,unpublished',scopeTargetNames:''},'tenant-library':{scopeKey:'tenant-library','flt.publishStatus':'published,unpublished',scopeTargetNames:''},'open-all':{scopeKey:'open-all','flt.publishStatus':'published'},'my-content':{scopeKey:'my-content-ca','flt.publishStatus':'published,unpublished'}};}, // Method help to fetch list of content source that are assigned for the class grade
fetchContentSources:function fetchContentSources(){var _this2=this;var preference=this.get('primaryClass.preference');var subjectCode=preference?preference.get('subject'):'';return this.get('scopeAndSequenceService').fetchContentSources({subjectId:subjectCode}).then(function(contentSource){var buildSequence=_ember['default'].A([]);contentSource.forEach(function(item){if(!_this2.get('hasCourse') && item.key === 'course-map'){return;}item.filters = _this2.parseDefaultSequenceFilter()[item.key]?Object.assign(_this2.parseDefaultSequenceFilter()[item.key]):{};if(item.key === 'open-library' || item.key === 'tenant-library'){item.filters.scopeTargetNames = item.short_name;}item.isActive = true;buildSequence.pushObject(_ember['default'].Object.create(item));});_this2.set('contentResource',buildSequence.sortBy('seq_id'));});}});});