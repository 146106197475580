define('gooru-web/controllers/student-independent-learning/student-bookmarks',['exports','ember'],function(exports,_ember){ /**
 * Independent Learning Bookmarks controller
 *
 * Controller responsible of the logic for the Student independent bookmarks
 */exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), /**
   * @type {BookmarkService} Service to retrieve bookmark information
   */bookmarkService:_ember['default'].inject.service('api-sdk/bookmark'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * show more bookmark results
     */showMoreResults:function showMoreResults(){this.showMoreResults();}, /**
     * Remove a bookmark from a list of bookmarks
     */removeBookmark:function removeBookmark(bookmark){this.get('bookmarks').removeObject(bookmark);}, /**
     * Triggered when the expand/collapse arrows are selected.
     */togglePanel:function togglePanel(){this.set('isExpanded',!this.get('isExpanded'));}}, // -------------------------------------------------------------------------
// Attributes
/**
   * @property {Number} number of items to load per page
   */PAGE_SIZE:8, /**
   * @property {Number} number of ]items loaded in this iteration
   */CURRENT_ITERATION_SIZE:0, /**
   * @property {Number} number of items to show in a section
   */ROW_SIZE:4, /**
   * @property {Number} number of offset to get items
   */OFFSET:0, /*
   * @property {Array[]} - bookmarks
   */bookmarks:null, /**
   * @property {*}
   */pagination:null,isExpanded:false,isShowMoreVisible:true, /**
   * show more bookmark results
   */showMoreResults:function showMoreResults(){var controller=this;var pagination=this.get('pagination');pagination.offset = pagination.offset + pagination.pageSize;pagination.pageSize = this.get('PAGE_SIZE');this.set('pagination',pagination);controller.get('bookmarkService').fetchBookmarks(pagination,false).then(function(bookmarkResults){controller.set('CURRENT_ITERATION_SIZE',bookmarkResults.length);controller.get('bookmarks').pushObjects(bookmarkResults);});},currentIterationObserver:_ember['default'].observer('CURRENT_ITERATION_SIZE',function(){this.set('isShowMoreVisible',this.get('CURRENT_ITERATION_SIZE') >= this.get('PAGE_SIZE'));}),showMoreToggle:_ember['default'].computed('bookmarks',function(){return this.get('bookmarks.length') >= this.get('ROW_SIZE');})});});