define('gooru-web/components/new-cards/gru-create-subject-card',['exports','ember','gooru-web/models/content/class','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebModelsContentClass,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Class service API SDK
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} session service
   */session:_ember['default'].inject.service('session'), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyProvider:_ember['default'].inject.service('api-sdk/taxonomy'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-create-subject-card'], // -------------------------------------------------------------------------
// Actions
actions:{createClass:function createClass(){var component=this;var newClass=this.get('newClass');newClass.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){if(component.get('taxonomyGrades') && component.get('taxonomyGrades').length > 0){component.set('isSelectGrade',true);}else {component.fetchTaxonomyGrades();}}component.set('didValidate',true);});},selectGrade:function selectGrade(grade){this.set('selectedGrade',grade);},cancel:function cancel(){this.set('isSelectGrade',false);},createSubjectClass:function createSubjectClass(){var component=this;var selectedSubject=component.get('subject.subjectCode');var courses=component.get('subject.courses');var selectedFramework=component.get('subject.fwCode');var newClass=component.get('newClass');component.get('classService').createClass(newClass).then(function(newClass){if(selectedSubject){(function(){var preferenceSettings={subject:selectedSubject,framework:selectedFramework?selectedFramework:null};component.get('classService').updatePreference(newClass.id,preferenceSettings).then(function(){var selectedGrade=component.get('selectedGrade');var lowerGrade=component.get('taxonomyGrades').get(0);if(selectedGrade){var settings={grade_lower_bound:lowerGrade.id,grade_current:component.get('selectedGrade.id'),force_calculate_ilp:true,preference:preferenceSettings};component.get('classService').classSettings(newClass.id,settings).then(function(){var courseId=component.getCourseId(courses);if(courseId){component.get('classService').associateCourseToClass(courseId,newClass.id).then(function(){component.gotoClassSettings(newClass);});}else {component.gotoClassSettings(newClass);}});}else {component.gotoClassSettings(newClass);}});})();}else {component.gotoClassSettings(newClass);}});}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var newClass=_gooruWebModelsContentClass['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null,classSharing:'open'});this.set('newClass',newClass);},didRender:function didRender(){var component=this;component.$().on('keyup','.modal-body',function(e){var keyCode=event.keyCode?event.keyCode:event.which;if(keyCode === 13){$(e.target).blur().focus();component.$('.get-started-btn').trigger('click');}});component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Class} class
   */newClass:null, /**
   * @property {String} action to send up after creating the class to
   * refresh the list of classes in the top header
   */updateUserClasses:null, /**
   * @type {String} open or restricted, tells the component which radio is checked.
   */currentClassSharing:_ember['default'].computed.alias('newClass.classSharing'), /**
   * Indicate if it's waiting for join class callback
   */isLoading:false, /**
   * Checking is demo account
   */isGuestAccount:_ember['default'].computed.alias('session.isGuest'), /**
   * @property {Boolean} cardBackgroundImage
   * Property help to maintain card images
   */cardBackgroundImage:_ember['default'].computed('subject',function(){var appRootPath=this.get('appRootPath');var randomNumber=parseInt(Math.random() * 3,0);var thumbnailImage=appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES['CLASS_DEFAULT_' + randomNumber];return thumbnailImage;}),isSelectGrade:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function fetchTaxonomyGrades
   * Method to fetch taxonomy grades
   */fetchTaxonomyGrades:function fetchTaxonomyGrades(){var component=this;var taxonomyService=component.get('taxonomyProvider');var subjectCode=component.get('subject.subjectCode');var filters={subject:subjectCode,fw_code:component.get('subject.fwCode')};if(subjectCode){return _ember['default'].RSVP.hash({taxonomyGrades:taxonomyService.fetchGradesBySubject(filters)}).then(function(_ref2){var taxonomyGrades=_ref2.taxonomyGrades;if(!(component.get('isDestroyed') || component.get('isDestroying'))){component.set('isSelectGrade',true);component.set('taxonomyGrades',taxonomyGrades.sortBy('sequence'));}});}}, // Method help to go class activities page
gotoClassActivity:function gotoClassActivity(newClass){this.get('router').transitionTo('teacher.class.class-activities',newClass.id);},gotoClassSettings:function gotoClassSettings(newClass){var component=this;component.get('router').transitionTo('teacher.class.class-management',newClass.id);},getCourseId:function getCourseId(courses){var component=this;var course=Object.entries(courses).find(function(key){return key[0] === component.get('selectedGrade.id').toString();});if(course){return course[1];}}});});