define('gooru-web/components/charts/competencies-progress-graph',['exports','ember','gooru-web/utils/math','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebUtilsMath,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['charts','competencies-progress-graph'], /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;_ember['default'].run.later(function(){var competenciesContext=component.get('competenciesContext');var totalCount=component.get('totalCount');competenciesContext.map(function(competencyContext){var competencyGraphContainer=component.$('.' + competencyContext.type + '-progress');competencyGraphContainer.animate({height:(0,_gooruWebUtilsMath.findPercentage)(totalCount,competencyContext.count) + '%'},1000,function(){competencyGraphContainer.addClass('show-count');});},2000);});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} competenciesContext
   * Property for type based competencies context
   */competenciesContext:_ember['default'].computed(function(){var component=this;var masteredCount=component.get('masteredCount');var inProgressCount=component.get('inProgressCount');var notStartedCount=component.get('notStartedCount');var competenciesContext=_ember['default'].A([{count:masteredCount,type:'mastered'},{count:inProgressCount,type:'inprogress'},{count:notStartedCount,type:'notstarted'}]);return competenciesContext;}), // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on progress graph
onClickProgressChart:function onClickProgressChart(){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_STUDENT_LJ_PROGRESS_VIEW);this.sendAction('onClickProgressChart');}}});});