define('gooru-web/components/player/questions/gru-scientific-fib',['exports','ember','gooru-web/components/player/questions/gru-question','gooru-web/utils/question/fill-in-the-blank'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruQuestion,_gooruWebUtilsQuestionFillInTheBlank){ /**
 * Scientific Fill in the blank
 *
 * Component responsible for controlling the logic and appearance of a Scientific fill in the blank
 * question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-scientific-fib'], // -------------------------------------------------------------------------
// Events
initInputEvents:(function(){var component=this;component.setAnswersEvents();}).on('didInsertElement'),isShow:true, // -------------------------------------------------------------------------
// Properties
/**
   * Replace "_______" to an input
   * @param question
   *
   */answers:_ember['default'].computed('question',function(){var component=this;var answers=component.get('question.answerDetails');var hints=component.get('question.hints');var readOnly=component.get('readOnly');var disabled=readOnly?'disabled':''; // matches [] but not []{, which indicates a malformed sqrt
var answerData=_ember['default'].A([]);answers.map(function(choice){var input='<input type=\'text\' value=\'\' data=' + choice.answer_category + ' ' + disabled + '/>';var answerText=choice.answer_text.replace(new RegExp(_gooruWebUtilsQuestionFillInTheBlank['default'].SCIENTIFIC_FIB_REGEX.global.source,'g'),input);var hint=hints[choice.answer_category + '_explanation'];var answerObj=_ember['default'].Object.create({answer_category:choice.answer_category,answer_text:answerText,ishintShow:true,hint:hint.replace(/&lt;/g,'<').replace(/&gt;/g,'>')});answerData.pushObject(answerObj);});return answerData;}), // -------------------------------------------------------------------------
// Methods
/**
   * Notify input answers
   * @param {boolean} onLoad if this was called when loading the component
   */notifyInputAnswers:function notifyInputAnswers(onLoad){var component=this,inputs=component.$('.fib-answers input[type=text]'),answers=inputs.map(function(index,input){var answer=_ember['default'].$(input).val();return _ember['default'].$.trim(answer);}).toArray();var answerCompleted=answers.join('').length > 0; //to check that at least 1 answer has text
var questionUtil=component.get('questionUtil');var correct=questionUtil.isCorrect(answers);component.notifyAnswerChanged(answers,correct);if(answerCompleted){if(onLoad){component.notifyAnswerLoaded(answers,correct);}else {component.notifyAnswerCompleted(answers,correct);}}else {component.notifyAnswerCleared(answers);}}, /**
   * Set answers
   */setAnswersEvents:function setAnswersEvents(){var component=this;var inputs=component.$('.fib-answers');if(component.get('hasUserAnswer')){component.notifyInputAnswers(true);}inputs.on('keyup','input[type=text]',function(){component.notifyInputAnswers(false);});},toggleAction:function toggleAction(item){var flag=item.get('ishintShow');item.set('ishintShow',!flag);}});});