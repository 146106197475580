define('gooru-web/components/content/questions/gru-question-play',['exports','ember','gooru-web/models/result/question','gooru-web/models/taxonomy/taxonomy-tag'],function(exports,_ember,_gooruWebModelsResultQuestion,_gooruWebModelsTaxonomyTaxonomyTag){exports['default'] = _ember['default'].Component.extend({session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','gru-question-play'],classNameBindings:['view','fixed-header'],tagName:'article', // -------------------------------------------------------------------------
// Actions
actions:{submitQuestion:function submitQuestion(){_ember['default'].Logger.debug('Submitting question from question player');}, /**
     * Performs a back action in the browser history
     */goBack:function goBack(){window.history.go(-1);},onCloseWindow:function onCloseWindow(){window.close();}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},didInsertElement:function didInsertElement(){this._super.apply(this,arguments);this.set('fixed-header',true);}, // -------------------------------------------------------------------------
// Properties
/**
   * Course model as instantiated by the route.
   * @property {Course}
   */question:null, /**
   * Player question format
   * @property {Resource}
   */playerQuestion:_ember['default'].computed('question',function(){return this.get('question').toPlayerResource();}), /**
   * Question result for this viewer, it is always an empty result
   */questionResult:_ember['default'].computed(function(){return _gooruWebModelsResultQuestion['default'].create({});}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('question.standards.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('question.standards'),false);}), /**
   * @property {Boolean} Whether or not the currently logged in user is the creator/owner of the question
   */isCreator:_ember['default'].computed('question.owner',function(){return this.get('question.owner.id') === this.get('session.userId');}),isH5PContent:_ember['default'].computed('question',function(){return this.get('question.type') === 'H5P_DAD';}), /**
   * @property {String}
   */accessToken:_ember['default'].computed.alias('session.token-api3'),contentURL:_ember['default'].computed('isH5PContent',function(){if(this.get('isH5PContent')){var accessToken=this.get('accessToken');var questionId=this.get('question.id');var questionType=this.get('question.content_subformat');var format='question';var contentURL=window.location.protocol + '//' + window.location.host + '/tools/h5p/play/' + questionId + '?accessToken=' + accessToken + '&contentType=' + questionType + '&format=' + format;return contentURL;}})});});