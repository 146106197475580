define('gooru-web/components/new-cards/gru-new-class-card',['exports','ember','gooru-web/models/content/class'],function(exports,_ember,_gooruWebModelsContentClass){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Class service API SDK
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} session service
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-new-class-card'],classNameBindings:['isNewClassCardOnly:class-card-only'], // -------------------------------------------------------------------------
// Actions
actions:{createClass:function createClass(){var component=this;var newClass=this.get('newClass');newClass.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){component.sendAction('onCreateClass',newClass);}component.set('didValidate',true);});}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var newClass=_gooruWebModelsContentClass['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null,classSharing:'open'});this.set('newClass',newClass);},didRender:function didRender(){var component=this;component.$().on('keyup','.modal-body',function(e){var keyCode=event.keyCode?event.keyCode:event.which;if(keyCode === 13){$(e.target).blur().focus();component.$('.get-started-btn').trigger('click');}});}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Class} class
   */newClass:null, /**
   * @property {String} action to send up after creating the class to
   * refresh the list of classes in the top header
   */updateUserClasses:null, /**
   * @type {String} open or restricted, tells the component which radio is checked.
   */currentClassSharing:_ember['default'].computed.alias('newClass.classSharing'), /**
   * Indicate if it's waiting for join class callback
   */isLoading:false, /**
   * Checking is demo account
   */isGuestAccount:_ember['default'].computed.alias('session.isGuest')});});