define('gooru-web/components/reports/pull-up/struggling-competency-domain-level',['exports','ember','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{classNames:['pull-up','struggling-competency-domain-level'], //-------------------------------------------------------------
// Property
/**
   * @property {Array} gradeDomainsList
   * property hold grade domain list
   */gradeDomainsList:[], /**
   * @property {Number} gradeDomainIndex
   * property hold grade domain index
   */gradeDomainIndex:null, /**
   * Maintains the value of popover position
   * @type {String}
   */tagPopoverDefaultPosition:'bottom', //-------------------------------------------------------------
// Actions
actions:{ // Action triggered when click domain
toggleDomain:function toggleDomain(domainIndex){this.$('.domain-accordion-' + domainIndex).slideToggle(500);}, //Action triggered when click close
onClosePullUp:function onClosePullUp(){var component=this;component.$().slideUp({complete:function complete(){component.sendAction('closePullUp');}});}, // Action trigger when select competency
onSelectCompetency:function onSelectCompetency(competency,domain){var component=this;competency.set('domainName',domain.get('domainName'));component.sendAction('selectCompetency',competency);}}, //------------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.openPullUp();this.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});this.setupTooltip();}, //---------------------------------------------------------------------
// Method
openPullUp:function openPullUp(){var component=this;component.$().slideDown();},setupTooltip:function setupTooltip(){var component=this;var $anchor=this.$('.lo-content');var isMobile=window.matchMedia('only screen and (max-width: 768px)');var tagPopoverDefaultPosition=this.get('tagPopoverDefaultPosition');$anchor.attr('data-html','true');$anchor.popover({placement:tagPopoverDefaultPosition,content:function content(){return component.$('.tag-tooltip').html();},trigger:'manual',container:'body'});if(isMobile.matches){$anchor.on('click',function(){var $this=$(this);if(!$this.hasClass('list-open')){ // Close all tag tooltips by simulating a click on them
$('.struggling-competency-domain-level > .content.list-open').click();$this.addClass('list-open').popover('show');}else {$this.removeClass('list-open').popover('hide');}});}else {$anchor.on('mouseenter',function(){$(this).popover('show');});$anchor.on('mouseleave',function(){$(this).popover('hide');});}}});});