define('gooru-web/components/taxonomy/gru-subject-picker',['exports','ember','gooru-web/utils/utils','gooru-web/config/config'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigConfig){ /**
 * Taxonomy subject picker component
 *
 * Component responsible for displaying subjects
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['taxonomy','gru-subject-picker'], // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Set Subject
     */setSubject:function setSubject(subject){var component=this;component.set('selectedFramework',component.get('selectedSubject'));component.set('selectedSubject',subject);if(component.get('selectedFramework.frameworkId') !== component.get('selectedSubject.frameworkId') && component.get('onSubjectSelected')){component.sendAction('onSubjectSelected',subject);}}}, //
// Methods
didRender:function didRender(){var component=this;if(component.get('isCompatiableMode')){component.$('.trigger').click(function(e){e.stopPropagation();});}}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Array} List of subjects
   */subjects:null, /**
   * @type {Array} List of subjects
   */subjectsWithStandards:_ember['default'].computed('subjects',function(){return this.get('subjects').filter(function(subject){return subject.get('hasStandards');});}), /**
   * @property {boolean}
   */onlySubjectsWithStandards:false, /**
   * the subject selected
   * @property {TaxonomyRoot}
   */selectedSubject:null, /**
   * when a subject is selected
   * @property {string}
   */onSubjectSelected:null, /**
   * @property {string}
   */placeholderLabelKey:'taxonomy.gru-taxonomy-selector.choose-subject', /**
   * @property {string} dropdown alignment, right | left
   */alignment:null, /**
   * @property {Boolean} indicates if the control is disabled
   */disabled:false, /**
   * @property {Boolean} isCompatibleMode
   * Property to handle is mobile view
   */isCompatiableMode:(0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.MEDIUM)});});