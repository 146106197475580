define('gooru-web/components/teacher/class/course-search-pull-up',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['course-search-pull-up'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} showPullUp
   */showPullUp:false, /**
   * @property {Boolean} isMenuEnabled
   */isMenuEnabled:false, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;if(component.get('showPullUp')){component.openPullUp();component.handleSearchBar();}component.handleShowMoreData();},handleShowMoreData:function handleShowMoreData(){var component=this;var loading=false;var container=_ember['default'].$('.pull-up-body-container');component.$(container).scroll(function(){if(!loading){var scrollTop=_ember['default'].$(container).scrollTop();var isScrollReachedBottom=scrollTop >= component.$(container).prop('scrollHeight') - (_ember['default'].$(container).height() + 80);if(isScrollReachedBottom && scrollTop > 0){component.sendAction('paginateNext');}}});}, // -------------------------------------------------------------------------
// Actions
actions:{onPullUpClose:function onPullUpClose(){var component=this;component.closePullUp();},onRemixCourse:function onRemixCourse(courseId){var component=this;component.sendAction('onRemixCourse',courseId);},onAddCourse:function onAddCourse(courseId){var component=this;component.sendAction('onAddCourse',courseId);},onSelectCatalog:function onSelectCatalog(catalog){var component=this;component.set('isLoading',true);component.sendAction('onSelectCatalog',catalog);this.toggleProperty('isMenuEnabled');}, /**
     * Toggle menu list based on the recent selection of the menu.
     */toggleMenuList:function toggleMenuList(){this.toggleProperty('isMenuEnabled');}},coursesObserver:_ember['default'].observer('courses',function(){var component=this;var courses=component.get('courses');component.set('isCourseNotAvailable',courses.length <= 0);component.set('isLoading',false);}), //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);});component.sendAction('resetValue');},handleSearchBar:function handleSearchBar(){var component=this;component.$('#search-courses').on('keyup',function(e){if(e.which === _gooruWebConfigConfig.KEY_CODES.ENTER){component.loadData();}});component.$('.search-icon .search').click(function(){var term=component.getSearchTerm();if(term.length > 0){component.loadData();}});},loadData:function loadData(){var component=this;var searchText=component.get('searchText');component.sendAction('onSearchCourse',searchText);}});});