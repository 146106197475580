define('gooru-web/helpers/grade-range',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports.gradeRange = gradeRange; /**
 * Get the range  for the grade bracket that a score falls under per the app's grading scale (@see /app/config/config.js#GRADING_SCALE)
 *
 * @example
 *
 * Based on a range  value, the report icon(svg) show different color base
 *
 * @see /app/templates/components/cards/gru-class-card.hbs#L1
 *
 * @param value - range within the grading scale
 * @returns {String} - range string
 */function gradeRange(params /*, hash*/){return _ember['default'].String.htmlSafe((0,_gooruWebUtilsUtils.getGradeRange)(params[0]));}exports['default'] = _ember['default'].Helper.helper(gradeRange);});