define('gooru-web/components/charts/monthly-time-series-chart',['exports','ember','d3','gooru-web/utils/utils','gooru-web/config/config'],function(exports,_ember,_d3,_gooruWebUtilsUtils,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['monthly-time-series-chart'], /**
   * @property {Boolean}
   * Property to store given screen value is compatible
   */isMobileView:(0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.MEDIUM), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;var currentDate=moment().format('YYYY-MM') + '-01';component.sendAction('onSelectMonth',moment(currentDate).toDate());}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {startDomainDate}
   * Property to store time series start date
   */startDomainDate:null, /**
   * @property {endDomainDate}
   * Property to store time series start date
   */endDomainDate:null,dateSeries:_ember['default'].A([]), /**
   * @property {startDate}
   * Property to store time series start date
   */startDate:_ember['default'].computed(function(){var date=new Date();var curMonth=date.getMonth();var curYear=date.getFullYear();var oneYearBeforeFromCurrentDate=date;oneYearBeforeFromCurrentDate = new Date(oneYearBeforeFromCurrentDate.setMonth(curMonth - 11));oneYearBeforeFromCurrentDate = new Date(oneYearBeforeFromCurrentDate.setFullYear(curYear - 1));return oneYearBeforeFromCurrentDate;}),date:_ember['default'].observer('endDate',function(){var component=this;component.set('domainEndDate',this.get('endDate'));component.calculateDates();}), // -------------------------------------------------------------------------
// Methods
calculateDates:function calculateDates(){var component=this;var domainEndDate=new Date(component.get('domainEndDate'));var domainEndYear=domainEndDate.getFullYear();var domainEndMonth=domainEndDate.getMonth();var domainStartDate=new Date(component.get('startDate'));var diffMonthBtwTwoDate=(0,_gooruWebUtilsUtils.diffMonthBtwTwoDates)(domainStartDate,domainEndDate); // if more than a year
if(diffMonthBtwTwoDate > 12){domainStartDate = new Date(domainStartDate.setMonth(domainEndMonth - 11));domainStartDate = new Date(domainStartDate.setFullYear(domainEndYear - 1));}var domainStartMonth=domainStartDate.getMonth();var domainStartYear=domainStartDate.getFullYear(); //add default addon months if required
var addOnMonths=diffMonthBtwTwoDate <= 5?5 - (diffMonthBtwTwoDate - 1):0;var startDomainDate=new Date(domainStartYear,domainStartMonth - addOnMonths);var endDomainDate=new Date(domainEndYear,domainEndMonth);component.set('startDomainDate',startDomainDate);component.set('endDomainDate',endDomainDate);if(!component.get('isMobileView')){component.$('svg.time-series').remove();component.drawChart(diffMonthBtwTwoDate);}else {component.setDates();}}, /**
   * @function drawChart
   * Method to get dates between two date
   */setDates:function setDates(){var component=this;var dates=[];var startDate=moment(component.get('startDomainDate'));var endDate=moment(component.get('endDomainDate'));while(startDate.isSameOrBefore(endDate)) {dates.push(new Date(startDate));startDate.add(1,'month');}component.set('dateSeries',dates);component.set('activeDate',dates.objectAt(dates.length - 1));}, /**
   * @function drawChart
   * Method to plot time series chart
   */drawChart:function drawChart(diffMonthBtwTwoDate){var component=this; // Config SVG size
var width=700,height=65; // Define d3 xScale
var xScale=_d3['default'].time.scale().domain([component.get('startDomainDate'),component.get('endDomainDate')]).range([0,width - 40]); // Define main d3 xAxis
var xAxis=_d3['default'].svg.axis().scale(xScale).tickFormat(_d3['default'].time.format('%b \'%y')).tickPadding(14); // Draw SVG element
var svgContainer=_d3['default'].select(component.element).append('svg'); // Draw axes
var axes=svgContainer.append('g').attr('class','x axis').attr('transform','translate(20,20)').call(xAxis);var zoomIn=_d3['default'].behavior.zoom().x(xScale).scaleExtent([1,1]).on('zoom',function(){var panX=_d3['default'].event.translate[0];var panY=_d3['default'].event.translate[1];var maxPanX=diffMonthBtwTwoDate > 12?(diffMonthBtwTwoDate - 12) * 60 + 3:0;panX = panX < 10?0:panX;panX = panX > maxPanX?maxPanX:panX;zoomIn.translate([panX,panY]);axes.call(xAxis);if(panX !== 0 || maxPanX !== 0){component.bindTicksClickable();}});svgContainer.attr('width',width).attr('height',height).attr('class','time-series').call(zoomIn);component.bindTicksClickable();component.bindNonClickableTicks();}, /**
   * @function bindNonClickableTicks
   * Method to bind non clickable ticks
   */bindNonClickableTicks:function bindNonClickableTicks(){var component=this;var ticksContainer=_d3['default'].selectAll('.tick');var startDate='01-' + moment(component.get('startDate')).format('MM-YYYY');ticksContainer.attr('y',function(date){var tickCotainer=_d3['default'].select(this);var timeSeriesDate=moment(date);if(moment(startDate).isAfter(moment(timeSeriesDate).format('DD-MM-YYYY'))){tickCotainer.attr('class','tick gray-out');}});}, /**
   * @function bindTicksClickable
   * Method to bind each tick clickable
   */bindTicksClickable:function bindTicksClickable(){var component=this;var ticksContainer=_d3['default'].selectAll('.tick');var curDate=new Date();var curMonth=curDate.getMonth();var curYear=curDate.getFullYear();ticksContainer.attr('y',function(date){var tickCotainer=_d3['default'].select(this);var curTickMonth=date.getMonth();var curTickYear=date.getFullYear(); //Default selection of current month and year
if(curMonth === curTickMonth && curYear === curTickYear){_d3['default'].select('circle.active-month').remove();tickCotainer.append('circle').attr('cx',0).attr('cy',0).attr('r',10).attr('class','active-month');}tickCotainer.append('foreignObject').attr('transform','translate(-' + 50 / 2 + ',0)').attr('width',50).attr('height',50);}).on('click',function(date){_d3['default'].select('circle.active-month').remove();_d3['default'].select(this).append('circle').attr('cx',0).attr('cy',0).attr('r',10).attr('class','active-month');component.sendAction('onSelectMonth',date);});},actions:{onDateSelect:function onDateSelect(date){var component=this;component.set('activeDate',date);component.sendAction('onSelectMonth',date);}}});});