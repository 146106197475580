define('gooru-web/utils/question/true-false',['exports','ember','gooru-web/utils/question/multiple-choice','gooru-web/utils/question/answer-object'],function(exports,_ember,_gooruWebUtilsQuestionMultipleChoice,_gooruWebUtilsQuestionAnswerObject){ /**
 * It contains convenience methods for grading and retrieving useful information
 * from True False questions
 *
 * # Answer object (structure required by the BE)
 *
 *   It is an array containing a single json object
 *
 *   text it contains True or False, based on user selection
 *   status could be correct or incorrect based on the user selection
 *   order is always 1
 *   answerId correspond to the answer choice id selected or 0 when the answerId is not provided
 *   skip is always false
 *
 * [{"text":"True","status":"correct","order":1,"answerId":1234,"skip":false}]
 *
 * # User answer (structure used by the FE)
 *
 *   It corresponds to the answerId selected by the user or false|true when the answerId is not provided
 *
 *  ie 123020
 *
 * @typedef {Object} TrueFalseUtil
 */exports['default'] = _gooruWebUtilsQuestionMultipleChoice['default'].extend({ // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
/**
   * Converts the model user answer into an answerObject format
   *
   * @param {string} userAnswer answer choice id
   * @return {AnswerObject[]}
   *
   * @see '# User Answer' section at class comment
   * @see '# Answer Object' section at class comment
   */toAnswerObjects:function toAnswerObjects(userAnswer){var util=this;var answer=util.getAnswerById(userAnswer); /*
     When no answer is found the userAnswer brings true or false indicating the user selection
     */var text=answer?answer.get('text'):userAnswer?'True':'False'; /*
     When no answer if found the answerId should be 0
     */var answerId=answer?userAnswer:0;var answerObject=_gooruWebUtilsQuestionAnswerObject['default'].create({text:text,correct:util.isCorrect(userAnswer),order:1,answerId:answerId,skip:false});return _ember['default'].A([answerObject]);}, /**
   * Converts an answerObject format to model userAnswer
   *
   * @param {AnswerObject[]} answerObjects
   * @return {string} answer id
   *
   * @see '# User Answer' section at class comment
   * @see '# Answer Object' section at class comment
   */toUserAnswer:function toUserAnswer(answerObjects){var userAnswer=null;if(answerObjects.get('length')){var answerObject=_ember['default'].get(answerObjects,'firstObject');var text=_ember['default'].get(answerObject,'text');var answerId=_ember['default'].get(answerObject,'answerId'); /*
       When answerId = 0, we need to use the text to know the answer selected
       */userAnswer = !answerId?text === 'True':answerId;}return userAnswer;}});});