define('gooru-web/validations/reset-password',['exports','ember-cp-validations'],function(exports,_emberCpValidations){var noNameOrUsernameValidator=(0,_emberCpValidations.validator)(function(value,options,model){var firstName=model.get('firstName');var lastName=model.get('lastName');if(value && firstName && value.toLowerCase().includes(firstName.toLowerCase())){return 'Your password contains your username.';}if(value && lastName && value.toLowerCase().includes(lastName.toLowerCase())){return 'Your password contains your username.';}return true;},{message:'{{description}}',descriptionKey:'common.errors.password-username'});exports['default'] = (0,_emberCpValidations.buildValidations)({password:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.password-required'}),(0,_emberCpValidations.validator)('format',{regex:/^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,message:'{{description}}',descriptionKey:'common.errors.password-special-character'}), /* allowing any character for now
    validator('format', {
      regex: /^[a-z0-9]+$/i,
      message: '{{description}}',
      descriptionKey: 'common.errors.password-special-characters'
    }),
*/(0,_emberCpValidations.validator)('length',{min:5,max:16,message:'{{description}}',descriptionKey:'common.errors.password-length'}),noNameOrUsernameValidator],rePassword:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.password-confirm'}), /* allowing any character for now
    validator('format', {
      regex: /^[a-z0-9]+$/i,
      message: '{{description}}',
      descriptionKey: 'common.errors.password-special-characters'
    }),
*/(0,_emberCpValidations.validator)('confirmation',{on:'password',message:'{{description}}',descriptionKey:'common.errors.password-not-match'})]});});