define('gooru-web/components/teacher/class/ca-course-map',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['teacher-class-ca-course-map'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/course-map
   */courseMapService:_ember['default'].inject.service('api-sdk/course-map'), /**
   * @requires service:api-sdk/class-activity
   */classActivityService:_ember['default'].inject.service('api-sdk/class-activity'), /**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {UnitService} Service to retrieve unit information
   */unitService:_ember['default'].inject.service('api-sdk/unit'), // -------------------------------------------------------------------------
// Properties
/**
   * Maintains the state of data loading
   * @type {Boolean}
   */isLoading:false, /**
   * Class Id extract from context
   * @type {String}
   */classId:null, /**
   * Course Id which is mapped to this class.
   * @type {String}
   */courseId:null, /**
   * This property have details of course object
   * @type {Object}
   */course:null, /**
   * Selected collection for scheduling
   * @type {Object}
   */selectedContentForSchedule:null, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Handle toggle functionality of hide/show unit items
     * @return {Object}
     */toggleUnitItems:function toggleUnitItems(selectedUnit){var component=this;var unitId=selectedUnit.get('id');var element='#dca-unit-' + unitId;var courseId=component.get('courseId');if(selectedUnit.get('isActive')){component.$(element).slideUp(400,function(){selectedUnit.set('isActive',false);});}else {component.$(element).slideDown(400,function(){selectedUnit.set('isActive',true);});}component.get('unitService').fetchById(courseId,unitId).then(function(unit){if(!component.isDestroyed){selectedUnit.set('children',unit.get('children'));selectedUnit.set('hasLessonFetched',true);}});}, /**
     * Handle toggle functionality of hide/show lesson items
     * @return {Object}
     */toggleLessonItems:function toggleLessonItems(selectedUnit,selectedLesson){var component=this;selectedLesson.set('isFetchingContent',true);var classId=selectedUnit.get('classId');var unitId=selectedUnit.get('id');var lessonId=selectedLesson.get('id');var element='#dca-lesson-' + lessonId;var courseId=component.get('courseId');var collectionIds=this.get('todayActivitiesContentIds');if(selectedLesson.get('isActive')){component.$(element).slideUp(400,function(){selectedLesson.set('isActive',false);});}else {component.$(element).slideDown(400,function(){selectedLesson.set('isActive',true);});}component.get('courseMapService').getLessonInfo(classId,courseId,unitId,lessonId,true).then(function(lesson){if(!component.isDestroyed){if(component.get('isShowLessonPlan')){selectedLesson.set('lessonPlan',lesson.get('lessonPlan'));}else {(function(){var lessonItems=lesson.get('children');var collections=_ember['default'].A();lessonItems.forEach(function(collection){var id=collection.get('id');if(collectionIds.includes(id)){collection.set('isAdded',true);}var isExternalContent=collection.get('format').includes('external');if(isExternalContent || collection.get('resourceCount') > 0 || collection.get('questionCount') > 0 || collection.get('isOfflineActivity')){collections.pushObject(collection);}});selectedLesson.set('children',collections);selectedLesson.set('hasCollectionFetched',true);})();}}selectedLesson.set('isFetchingContent',false);});}, /**
     * Action get triggered when add content to DCA got clicked
     */onAddContentToDCA:function onAddContentToDCA(content){var component=this;component.sendAction('onAddContentToDCA',content);}, /**
     * Open the player with the specific collection/assessment
     *
     * @function actions:playContent
     * @param {string} unitId - Identifier for a unit
     * @param {string} lessonId - Identifier for lesson
     * @param {string} collection - collection or assessment
     */playContent:function playContent(unitId,lessonId,collection){var component=this;var classId=component.get('classId');var courseId=component.get('courseId');var collectionId=collection.get('id');var collectionType=collection.get('collectionType');var url=window.location.origin + '/player/course/' + courseId + '/unit/' + unitId + '/lesson/' + lessonId + '/collection/' + collectionId + '?role=teacher&type=' + collectionType + '&classId=' + classId;if(collection.get('isExternalAssessment')){url = collection.get('url');}window.open(url);}, /**
     * Action get triggered when schedule content to CA got clicked
     */onScheduleContentToDCA:function onScheduleContentToDCA(content,event){this.sendAction('onScheduleContentToDCA',content,event);},onAddActivity:function onAddActivity(content){this.sendAction('onAddActivity',content);},onScheduleActivity:function onScheduleActivity(content){this.sendAction('onScheduleActivity',content);},onShowContentPreview:function onShowContentPreview(content){this.sendAction('onShowContentPreview',content);}}, // -------------------------------------------------------------------------
// Events
/**
   * Function to triggered once when the component element is first rendered.
   */didInsertElement:function didInsertElement(){this.loadData();this.closeCADatePickerOnScroll();},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},observerClassChanges:_ember['default'].observer('classId',function(){this.loadData();}), //--------------------------------------------------------------------------
// Methods
loadData:function loadData(){var component=this;var courseId=component.get('courseId');component.set('isLoading',true);component.get('courseService').fetchById(courseId).then(function(course){if(!component.isDestroyed){component.set('course',course);component.set('isLoading',false);}});},serializerSearchContent:function serializerSearchContent(content,contentId,date,forMonth,forYear){return _ember['default'].Object.create({id:contentId,added_date:date,collection:content,activityDate:date,forMonth:forMonth,forYear:forYear,usersCount:-1,isActive:false});},closeCADatePickerOnScroll:function closeCADatePickerOnScroll(){var component=this;component.$('.dca-course-map-unit-container').on('scroll',function(){if(_ember['default'].$('.ca-datepicker-popover-container').is(':visible')){_ember['default'].$('.ca-datepicker-popover-container').hide();_ember['default'].$('.ca-datepicker-popover').removeClass('active');}});}});});