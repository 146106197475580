define('gooru-web/components/ca-month-picker',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['ca-month-picker'], // -------------------------------------------------------------------------
// Properties
/**
   * It maintains number of months to show or not
   * @type {Number}
   */numberOfMonthsToShow:3, /**
   * It maintains whether to show calender or not
   * @type {Boolean}
   */showCalendarView:false, /**
   * Maintains the value of selected year
   * @type {Moment}
   */forYear:moment(), /**
   * Maintains the value which of year activities displaying
   * @type {Moment}
   */selectedYear:_ember['default'].computed(function(){return this.get('forYear') || moment().format('YYYY');}), /**
   * Maintains the selected month by user
   * @type {String}
   */selectedMonth:_ember['default'].computed(function(){return this.get('forMonth') || moment().format('MM');}),classStartDate:_ember['default'].computed('class',function(){return this.get('class.startDate');}), /**
   * It maintains list of months to be display for unschedule contents
   * @return {Array}
   */months:_ember['default'].computed('currentMonth',function(){var component=this;var monthsList=_ember['default'].A([]);var currentMonth=component.get('currentMonth');var monthAndYearOfCurrentDate=moment().format('YYYY-MM');var firtDateOfCurrentMonth=moment(monthAndYearOfCurrentDate + '-01');if(currentMonth){var numberOfMonthsToShow=component.get('numberOfMonthsToShow');for(var index=0;index < numberOfMonthsToShow;index++) {var slectedMonth=moment(currentMonth).add(index,'months');var monthName=moment(currentMonth).add(index,'months').format('MMMM');var monthYear=moment(currentMonth).add(index,'months').format('YYYY');var monthNumber=moment(currentMonth).add(index,'months').format('MM');var month=_ember['default'].Object.create({monthNumber:monthNumber,monthName:monthName,monthYear:monthYear});month.set('isPast',!slectedMonth.isSameOrAfter(firtDateOfCurrentMonth));monthsList.pushObject(month);}}return monthsList;}), // -------------------------------------------------------------------------
// Actions
actions:{onSelectMonth:function onSelectMonth(month){var component=this;component.sendAction('onSelectMonth',month);},showPreviousYear:function showPreviousYear(){var component=this;var previousYear=component.get('selectedYear');component.set('selectedYear',parseInt(previousYear) - 1);var monthPickerEle=component.$('#ca-monthpicker');monthPickerEle.find('.datepicker .datepicker-months thead tr:first-child th.prev').click();},showNextYear:function showNextYear(){var component=this;var nextYear=component.get('selectedYear');component.set('selectedYear',parseInt(nextYear) + 1);var monthPickerEle=component.$('#ca-monthpicker');monthPickerEle.find('.datepicker .datepicker-months  thead tr:first-child .next').click();},onCloseDatePicker:function onCloseDatePicker(){this.sendAction('closeDatePicker');}},didInsertElement:function didInsertElement(){var component=this;component.initialize();},didRender:function didRender(){var component=this;$('#ca-monthpicker .datepicker .datepicker-months .table-condensed tbody tr td span').attr('tabindex',component.get('tabindex'));}, // -------------------------------------------------------------------------
// Methods
initialize:function initialize(){var component=this;var monthPickerEle=component.$('#ca-monthpicker');var defaultParams={format:'mm',viewMode:'months',minViewMode:'months'};var selectedMonth=component.get('selectedMonth');var showCalendarView=component.get('showCalendarView');var forYear=component.get('selectedYear');if(component.get('disableFutureDate')){defaultParams.endDate = 'today';}var showAtcView=component.get('showAtcView');if(showAtcView){defaultParams.format = 'mm-yyyy';defaultParams.startDate = moment(this.get('classStartDate')).format('MM-YYYY');defaultParams.endDate = moment().format('MM-YYYY');if(forYear && forYear >= moment().format('YYYY')){defaultParams.endDate = moment().format('MM-YYYY');}}var pickerStartDate=component.get('pickerStartDate');if(pickerStartDate){defaultParams.startDate = moment(pickerStartDate).format('YYYY-MM-DD');}monthPickerEle.datepicker(defaultParams);if(showCalendarView && selectedMonth && !showAtcView){var forMonth=moment(selectedMonth).format('MM');monthPickerEle.datepicker('setDate',forMonth);var monthAndyear=forYear + '-' + forMonth;component.sendAction('onSelectMonth',monthAndyear,false);}if(showAtcView && selectedMonth){var forMonth=moment(selectedMonth).format('MM');var _forYear=component.get('forYear');monthPickerEle.datepicker('setDate',forMonth + '-' + _forYear);}monthPickerEle.off('changeDate').on('changeDate',function(){var monthpicker=this;var selectedMonth=_ember['default'].$(monthpicker).datepicker('getFormattedDate').valueOf();var forYear=component.get('selectedYear');if(!moment(forYear).isValid()){forYear = moment(forYear).format('YYYY');}var monthAndyear=forYear + '-' + selectedMonth;if(showAtcView){monthAndyear = moment(selectedMonth,'MM-YYYY').format('YYYY-MM');}component.sendAction('onSelectMonth',monthAndyear,true);});monthPickerEle.off('keypress').on('keypress',function(e){if(e.keyCode === 13 || e.which === 13){document.activeElement.click();}});}});});