define('gooru-web/services/api-sdk/authentication',['exports','ember','gooru-web/serializers/authentication/authentication','gooru-web/adapters/authentication/authentication','gooru-web/adapters/profile/profile'],function(exports,_ember,_gooruWebSerializersAuthenticationAuthentication,_gooruWebAdaptersAuthenticationAuthentication,_gooruWebAdaptersProfileProfile){ /**
 * Service for the Authentication (Login) with API 3.0
 *
 * @typedef {Object} AuthenticationService
 */exports['default'] = _ember['default'].Service.extend({authenticationSerializer:null,authenticationAdapter:null,profileAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('authenticationSerializer',_gooruWebSerializersAuthenticationAuthentication['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('authenticationAdapter',_gooruWebAdaptersAuthenticationAuthentication['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('profileAdapter',_gooruWebAdaptersProfileProfile['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Authenticates as an anonymous account
   *
   * @returns {Object} the a normalized response from the endpoint
   */authenticateAsAnonymous:function authenticateAsAnonymous(nonce){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('authenticationAdapter').postAuthentication({isAnonymous:true,nonce:nonce}).then(function(response){resolve(service.get('authenticationSerializer').normalizeResponse(response,true));},function(error){reject(error);});});}, /**
   * Authenticates as a normal user using the credentials
   * @param username account username
   * @param password account password
   * @returns {Object} the normalized response from the endpoint
   */authenticateWithCredentials:function authenticateWithCredentials(username,password){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('authenticationAdapter').postAuthentication({isAnonymous:false,username:username,password:password}).then(function(response){resolve(service.get('authenticationSerializer').normalizeResponse(response,false));},reject);});}, /**
   * Authenticates as a normal user using access token
   * @param accessToken user access token
   * @returns {Object} the normalized response from the endpoint
   */authenticateWithToken:function authenticateWithToken(accessToken){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('authenticationAdapter').postAuthenticationWithToken({accessToken:accessToken}).then(function(response){resolve(service.get('authenticationSerializer').normalizeResponse(response,false,accessToken));},reject);});}, /**
   * Checks if a token is valid
   * @param accessToken user access token
   */checkToken:function checkToken(accessToken){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('authenticationAdapter').checkToken({accessToken:accessToken}).then(resolve,reject);});}, /**
   * Invalidates current token
   */signOut:function signOut(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('authenticationAdapter').signOut().then(resolve,reject);});}, /**
   * Find the domain name have any directions for authentication.
   * @param logged in bowser session domain name.
   */domainBasedRedirection:function domainBasedRedirection(domain){var redirectURL=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var service=this;var data={domain:domain};if(redirectURL){data.context_url = redirectURL;}return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('authenticationAdapter').domainBasedRedirection(data).then(function(response){resolve(service.get('authenticationSerializer').normalizeDomainRedirectResponse(response));},reject);});}});});