define('gooru-web/serializers/performance/chrono-performance',['exports','ember','ember-data'],function(exports,_ember,_emberData){exports['default'] = _emberData['default'].JSONAPISerializer.extend({ /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'),getStudentPerformanceOfAllItemsInClass:function getStudentPerformanceOfAllItemsInClass(data){return data;}, /**
   * Filter convertions to be consumend by the adapter
   */serializedFilterData:function serializedFilterData(data){var classId=data.classId;var courseId=data.courseId;var userId=data.userId;var startDate=data.startDate;var limit=data.limit;var offset=data.offset;classId = classId?classId:null;courseId = courseId?courseId:null;userId = userId?userId:this.get('session.userId');startDate = startDate?startDate:new Date().toISOString().slice(0,10);limit = limit?limit:10;offset = offset?offset:0;return {classId:classId,courseId:courseId,userId:userId,startDate:startDate,limit:limit,offset:offset}; //return data;
}, /**
   * @param {JSON Object } responseData, response data returned by service (snake_case)
   * @returns{JSON Object} data normalized, converted to the form used by application(non snake_case )
   */normalizeUsageData:function normalizeUsageData(payload){var serializer=this;var timeData=_ember['default'].Object.create({activities:_ember['default'].A()});if(payload.content && payload.content.length > 0){if(_ember['default'].isArray(payload.content[0].usageData)){timeData.set('activityStartDate',payload.content[0].startDate);var activityData=payload.content[0].usageData.map(function(timelineData){return serializer.normalizeChronoPerformanceSummary(timelineData);});timeData.set('activities',activityData);}}return timeData;}, /**
   * Normalize a ChronoPerformanceSummary
   * @param {*} data
   * @return {ChronoPerformanceSummary}
   */normalizeChronoPerformanceSummary:function normalizeChronoPerformanceSummary(data){return _ember['default'].Object.create({id:data.collectionId || data.collection_id,collectionId:data.collectionId || data.collection_id,timeSpent:data.timeSpent,attempts:data.attempts,views:data.views,score:data.scoreInPercentage,pathId:data.pathId,ctxPathId:data.ctxPathId,sessionId:data.sessionId,status:data.status,collectionType:data.collectionType,pathType:data.pathType,ctxPathType:data.ctxPathType,lastAccessedDate:data.lastAccessed,classId:data.classId,unitId:data.unitId,lessonId:data.lessonId,courseId:data.courseId,contentSource:data.contentSource});},normalizeFetch:function normalizeFetch(data){return data;},updateAction:function updateAction(data){return data;}});});