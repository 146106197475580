define('gooru-web/components/reports/assessment/gru-resources',['exports','ember','gooru-web/mixins/configuration','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','gru-resources'], // -------------------------------------------------------------------------
// Properties
/**
   * List of resources to be displayed by the component
   *
   * @constant {Array}
   */results:null, /**
   * Indicates if the reaction bar is visible
   * @property {boolean}
   */showReactionBar:true, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */taxonomyTags:_ember['default'].computed('content.resource.standards.[]',function(){var standards=this.get('content.resource.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}})});});