define('gooru-web/adapters/analytics/analytics',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus-insights/v2',dsUsersNamespace:'/api/ds/users/v3',dsUsersNamespacev2:'/api/ds/users/v2',queryRecord:function queryRecord(query){var namespace=this.get('namespace');var classId=query.classId;var courseId=query.courseId;var unitId=query.unitId;var lessonId=query.lessonId;var collectionId=query.collectionId;var collectionType=query.collectionType;var url=namespace + '/class/' + classId + '/course/' + courseId + '/unit/' + unitId + '/lesson/' + lessonId + '/' + collectionType + '/' + collectionId + '/performance';var options={type:'GET',dataType:'json',headers:this.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);},queryRecordForDCA:function queryRecordForDCA(query){var namespace=this.get('namespace');var includedateParam='';var includestartDateParam='';var includeendDateParam='';var includepathIdParam='';var includeClassId='';var includesessionIdParam='sessionId=' + query.sessionId;if(query.classId){includeClassId = 'classId=' + query.classId;}if(query.date){includedateParam = 'date=' + query.date;}if(query.startDate){includestartDateParam = 'startDate=' + query.startDate;}if(query.endDate){includeendDateParam = 'endDate=' + query.endDate;}if(query.pathId){includepathIdParam = 'pathId=' + query.pathId;}var collectionId=query.collectionId;var userId=query.userId;var collectionType=query.collectionType;var url=namespace + '/dca/' + collectionType + '/' + collectionId + '/user/' + userId + '?' + includesessionIdParam + '&' + includeClassId + '&' + includedateParam + '&' + includestartDateParam + '&' + includeendDateParam + '&' + includepathIdParam;var options={type:'GET',dataType:'json',headers:this.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);}, /**
   * Get DCA class performance summary
   * @param {UUID} classId
   * @returns {Promise}
   */getDCASummaryPerformance:function getDCASummaryPerformance(classId){var namespace=this.get('namespace');var url=namespace + '/dca/class/' + classId + '/performance';var options={type:'GET',dataType:'json',headers:this.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);}, /**
   * Get DCA class summary report
   * @param {UUID} classId
   * @returns {Promise}
   */getDCAYearlySummary:function getDCAYearlySummary(classId,userId){var namespace=this.get('namespace');var url=namespace + '/dca/class/' + classId + '/summary';var options={type:'GET',dataType:'json',headers:this.defineHeaders(),data:{userId:userId}};return _ember['default'].$.ajax(url,options);}, /**
   * Update score of questions in an Assessment/Collection
   * @param {string} RawData of questions score update for assessment or collection.
   * @returns {Promise}
   */updateQuestionScore:function updateQuestionScore(data){var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(data)};var namespace=this.get('namespace');var url=namespace + '/score';return _ember['default'].$.ajax(url,options);}, /**
   * @function getAtcPerformanceSummary
   * Method to fetch performance summary of a class for ATC view
   */getAtcPerformanceSummary:function getAtcPerformanceSummary(classId,courseId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/atc/pvc';var options={type:'GET',headers:adapter.defineHeaders(),data:{classId:classId,courseId:courseId}};return _ember['default'].$.ajax(url,options);}, /**
   * @function getAtcPerformanceSummaryPremiumClass
   * Method to fetch performance summary of a premium class for ATC view
   */getAtcPerformanceSummaryPremiumClass:function getAtcPerformanceSummaryPremiumClass(classId,subjectCode,filters){var adapter=this;var namespace=this.get('dsUsersNamespace');var url=namespace + '/nc/atc/pvc';var options={type:'GET',headers:adapter.defineHeaders(),data:{classId:classId,subjectCode:subjectCode}};if(filters){options.data = Object.assign(options.data,filters);}return _ember['default'].$.ajax(url,options);}, /**
   * @function getInitialSkyline
   * Method to fetch initial skyline for ATC view
   */getInitialSkyline:function getInitialSkyline(classId,subjectCode,filters,fwCode){var adapter=this;var namespace=this.get('dsUsersNamespace');var url=namespace + '/nc/atc/initial/baseline';var options={type:'GET',headers:adapter.defineHeaders(),data:{classId:classId,subjectCode:subjectCode,fwCode:fwCode}};if(filters){options.data = Object.assign(options.data,filters);}return _ember['default'].$.ajax(url,options);}, /**
   * @function studentSelfReporting
   * Method to update external assessment score
   */studentSelfReporting:function studentSelfReporting(dataParams){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/self-report';var options={type:'POST',headers:adapter.defineHeaders(),data:JSON.stringify(dataParams)};return _ember['default'].$.ajax(url,options);}, /**
   * This Method used to fetch DCA collection/assesssment performance details for the specfic date.
   * @param  {ClassId} classId        Unique Id of the class
   * @param  {CollectionId} collectionId   Unique Id of the collection.
   * @param  {CollectionType} collectionType Type of the collection, it should be collection/assessment.
   * @param  {SessionId} sessionId          sessionId of the activity
   * @param  {UserId} userId
   */getDCAPerformanceBySessionId:function getDCAPerformanceBySessionId(userId,classId,collectionId,collectionType,sessionId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/dca/class/' + classId + '/' + collectionType + '/' + collectionId + '/session/' + sessionId + '/performance';var options={type:'GET',headers:adapter.defineHeaders(),data:{userId:userId}};return _ember['default'].$.ajax(url,options);}, /**
   * This Method used to fetch DCA collection/assesssment performance details for the specfic date.
   * @param  {ClassId} classId        Unique Id of the class
   * @param  {CollectionId} collectionId   Unique Id of the collection.
   * @param  {CollectionType} collectionType Type of the collection, it should be collection/assessment.
   * @param  {String} date           Date format should YYYY-MM-DD
   */getDCAPerformance:function getDCAPerformance(classId,collectionId,collectionType,date,endDate){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/dca/class/' + classId + '/' + collectionType + '/' + collectionId + '/performance';var options={type:'GET',headers:adapter.defineHeaders(),data:{date:date,startDate:date,endDate:endDate}};return _ember['default'].$.ajax(url,options);},queryLikertRecord:function queryLikertRecord(query){var namespace=this.get('namespace');var classId=query.classId;var courseId=query.courseId;var unitId=query.unitId;var lessonId=query.lessonId;var collectionId=query.collectionId;var collectionType=query.collectionType;var selectedQuestionId=query.selectedQuestionId;var url=namespace + '/class/' + classId + '/course/' + courseId + '/unit/' + unitId + '/lesson/' + lessonId + '/' + collectionType + '/' + collectionId + '/question/' + selectedQuestionId + '/performance';var options={type:'GET',dataType:'json',headers:this.defineHeaders(),data:{}};return _ember['default'].$.ajax(url,options);}, /**
   * Get grade competency count based on grade
   * @param {Object} params has subject and fw_code properties
   */getGradeCompetencyCount:function getGradeCompetencyCount(params){var adapter=this;var namespace=this.get('dsUsersNamespacev2');var url=namespace + '/tx/grade/competency/count';var options={type:'GET',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});