define('gooru-web/components/gru-pull-out',['exports','ember'],function(exports,_ember){ /**
 * Gooru APP pull out component
 *
 * Component responsible for generating the selekton pull out component,
 * which  have header, footer(Optional), title, close button and more info(Optional).
 * @augments ember/Component
 * see the sample usage below
 * {{#gru-pull-out showPullOut=showPullOut title=title  description=description  showMore=true  as |section| }}
 * {{#if section.isLessContent}}
 * {{custom-less-info-component}}
 * {{else if section.isMoreContent}}
 * {{custom-more-info-component}}
 * {{/if}}
 * {{/gru-pull-out}}
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-pull-out'],classNameBindings:['showLess:gru-pull-out-more'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user invoke the pull out.
     */onPullOutOpen:function onPullOutOpen(){this.set('showPullOut',true);}, /**
     * Action triggered when the user click's the more info button.
     */showMoreInfo:function showMoreInfo(){var component=this;component.$().animate({right:'0'},{complete:function complete(){component.toggleProperty('showMore');component.toggleProperty('showLess');}});}, /**
     * Action triggered when the user click's the less info button.
     */showLessInfo:function showLessInfo(){var component=this;var right=460 - component.$().width();component.$().animate({right:right + 'px'},{complete:function complete(){component.toggleProperty('showMore');component.toggleProperty('showLess');component.$().css('right','calc(460px - 100%)');}});}, /**
     * Action triggered when the user close the pull out
     */onPullOutClose:function onPullOutClose(){this.set('showMore',false);this.set('showLess',false);this.sendAction('onClosePullOut');}}, // -------------------------------------------------------------------------
// Properties
/**
   * It indicates the display  status of show more action button.
   * @type {Boolean}
   */showMore:false, /**
   * It indicates the display  status of show more action button
   * @type {Boolean}
   */showLess:false,lessContent:{isLessContent:true},moreContent:{isMoreContent:true},showPullOut:false, //--------------------------------------------------------------------------
// Observer
//
onChange:_ember['default'].observer('showPullOut',function(){var _this=this;if(this.get('showPullOut')){(function(){_ember['default'].$('body.application').addClass('no-vertical-scroll');var component=_this;var right=460 - component.$().width();component.$().removeClass('gru-pull-out-hidden');component.$().animate({right:right + 'px'},{complete:function complete(){component.$().css('right','calc(460px - 100%)');}});})();}else {(function(){var component=_this;component.$().animate({right:'-101%'},{complete:function complete(){component.$().addClass('gru-pull-out-hidden');_ember['default'].$('body.application').removeClass('no-vertical-scroll');}});})();}})});});