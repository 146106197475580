define('gooru-web/components/new-cards/gru-offline-activity-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebMixinsModal){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['new-cards','gru-offline-activity-card'], // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on edit
onEditContent:function onEditContent(){var component=this;var contentId=component.get('offlineActivity.id');var isLibraryContent=component.get('isLibraryContent');component.get('router').transitionTo('content.activity.edit',contentId,{queryParams:{editingContent:true,isLibraryContent:isLibraryContent}});},selectSingleBox:function selectSingleBox(content){this.sendAction('selectSingleBox',content);}, /**
     * Action triggered to add to classroom or daily class activities
     */onAddContent:function onAddContent(){var component=this;component.addStudentCountToClasses();var model=_ember['default'].Object.create({classroomList:this.get('classroomList'),classActivity:true,content:this.get('offlineActivity')});if(this.get('isCourse')){model.set('callback',{success:function success(){component.sendAction('onUpdateUserClasses');}});}component.send('showModal','content.modals.gru-add-to-classroom',model,null,'add-to');}, //Action triggered when click on play
onShowOfflineActivityPreview:function onShowOfflineActivityPreview(){var component=this;var offlineActivity=component.get('offlineActivity');offlineActivity.set('tabindex',this.get('tabindex'));component.sendAction('onShowOfflineActivityPreview',offlineActivity);}, //Action triggered when click on title
offlineActivityPreview:function offlineActivityPreview(){if(this.get('isDeepLink')){this.send('onShowOfflineActivityPreview');}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */oaStandards:_ember['default'].computed('offlineActivity.standards.[]',function(){var standards=this.get('offlineActivity.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), /**
   * @property {Boolean} isSameOwnerAndCreator
   */isSameOwnerAndCreator:_ember['default'].computed('offlineActivity.originalCreatorId',function(){var component=this;var offlineActivity=component.get('offlineActivity');return offlineActivity.get('originalCreatorId') === null || offlineActivity.get('originalCreatorId') === offlineActivity.get('ownerId');}), // -------------------------------------------------------------------------
// Methods
/**
   * Add student count to classes
   */addStudentCountToClasses:function addStudentCountToClasses(){var component=this;var classStudentCount=component.get('classStudentCount');var classRoomList=component.get('classroomList');if(classRoomList){classRoomList.forEach(function(classroom){var studentCount=component.studentCount(classStudentCount,classroom);classroom.set('studentCount',studentCount);});}}, /**
   * @property {Number} Count of students in the class
   */studentCount:function studentCount(_studentCount,classroom){var classStudentCount=_studentCount;return classStudentCount && _ember['default'].keys(classStudentCount).length?classStudentCount[classroom.get('id')]?classStudentCount[classroom.get('id')]:0:0;}});});