define('gooru-web/components/proficiency/inspect/student-domain-performance',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['inspect-competency','student-domain-performance'], // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);$(window).on('resize',this.handleResize.bind(this));},didInsertElement:function didInsertElement(){this.handleResize();},filteredWeekLocal:_ember['default'].A([]), // -------------------------------------------------------------------------
// Actions
actions:{onSearchWeeklyLocal:function onSearchWeeklyLocal(searchWeekLocal){var testing=this.get('filteredWeekLocal');if(!testing.length){this.set('filteredWeekLocal',(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(this.get('studentsDomainPerformance')));}var filteredStudents=(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(this.get('filteredWeekLocal')).filter(function(student){ // eslint-disable-next-line no-console
searchWeekLocal = searchWeekLocal.toLowerCase();return student.firstName && student.firstName.toLowerCase().startsWith(searchWeekLocal) || student.lastName && student.lastName.toLowerCase().startsWith(searchWeekLocal) || student.email && student.email.toLowerCase().startsWith(searchWeekLocal) || student.username && student.username.toLowerCase().startsWith(searchWeekLocal);});this.set('studentsDomainPerformance',filteredStudents);}, // Action triggered when click left/right arrow
onClickArrow:function onClickArrow(direction){var component=this;var curDeviceVW=window.screen.width;var mobilePotraitVW=component.get('mobilePotraitVW');if(curDeviceVW <= mobilePotraitVW){component.mobilePotraitScroller(direction);}else {var scrollableContainer=component.$('.scrollable-container');var curPos=scrollableContainer.scrollLeft();var nextPos=direction === 'left'?curPos - 232:curPos + 232;scrollableContainer.animate({scrollLeft:nextPos},400);}}, //Action triggered when select a domain
onSelectDomain:function onSelectDomain(domain){var component=this;component.sendAction('onSelectDomain',domain);}, //Action triggered when select a student
onSelectStudent:function onSelectStudent(student){var component=this;component.sendAction('onSelectStudent',student);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {JSON} courseCoverageCount
   */courseCoverageCount:_ember['default'].Object.create({mastered:0,'in-progress':0,'not-started':0}), /**
   * @property {Array} domainCoverageCount
   */domainCoverageCount:null, /**
   * @property {Number} totalCompetencies
   */totalCompetencies:0, /**
   * @property {Number} mobilePotraitVW
   */mobilePotraitVW:639, /**
   * @property {Boolean} isMobilePotraitView
   */isMobilePotraitView:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function mobilePotraitScroller
   * Method to handle potrait mode scroller
   */mobilePotraitScroller:function mobilePotraitScroller(direction){var component=this;var $domainsPerformanceContainer=component.$('.domains-performance-container');var $domainsCoverageContainer=component.$('.domains-coverage-container');var curDeviceVW=window.screen.width;var curPerfPos=$domainsPerformanceContainer.scrollLeft();var curCoveragePos=$domainsCoverageContainer.scrollLeft();var scrollablePos=curDeviceVW - 142;var nextPerfPos=direction === 'left'?curPerfPos - scrollablePos:curPerfPos + scrollablePos;var nextCoveragePos=direction === 'left'?curCoveragePos - curDeviceVW:curCoveragePos + curDeviceVW;$domainsPerformanceContainer.animate({scrollLeft:nextPerfPos},400);$domainsCoverageContainer.animate({scrollLeft:nextCoveragePos},400);}, /**
   * @function handleResize
   * Method to handle screen resize events
   */handleResize:function handleResize(){var component=this;var curDeviceVW=window.screen.width;var curDeviceVH=window.screen.height;var mobilePotraitVW=component.get('mobilePotraitVW');var domainsCoverageContainer=component.$('.domains-coverage-container');var domainsPerformanceContainer=component.$('.domains-performance-container');if(!component.get('isDestroyed') || component.get('isDestroying')){component.set('isMobilePotraitView',curDeviceVW <= mobilePotraitVW);}var scrollableContainer=component.$('.scrollable-container');scrollableContainer.animate({scrollLeft:0},400);if(curDeviceVH - 280 < domainsPerformanceContainer.height()){domainsCoverageContainer.addClass('scrollable-margin');}else {domainsCoverageContainer.removeClass('scrollable-margin');}}});});