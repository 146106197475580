define('gooru-web/adapters/suggest/suggest',['exports','ember','gooru-web/adapters/application'],function(exports,_ember,_gooruWebAdaptersApplication){exports['default'] = _gooruWebAdaptersApplication['default'].extend({session:_ember['default'].inject.service('session'), /**
   * @property {string} End-point URI
   */namespace:'/gooru-search/rest/suggest/v2',namespaceV3:'/gooru-search/rest/v3/suggest',namespaceST:'/api/stracker/v2',suggestResources:function suggestResources(term){var params=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var adapter=this;var namespace=this.get('namespace');var url=namespace + '/resource';var page=params.page || 0;var pageSize=params.pageSize || 3;var contentGOid=params.GOid || '';var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:{searchTerm:term,event:'collection-edit',contentGooruOid:contentGOid,'flt.publishStatus':'published',start:page + 1,pageSize:pageSize}};return _ember['default'].$.ajax(url,options);}, //TODO this has to be changed according to the documentation -is not using now-
/**
   * Gets resource suggestion for an specific resource in a context
   * @param {String} resourceId
   * @param {SuggestContext} context
   * @param {number} limit
   * @returns {*}
   */suggestResourcesForResource:function suggestResourcesForResource(resourceId,context){var limit=arguments.length <= 2 || arguments[2] === undefined?10:arguments[2];var adapter=this;var namespace=this.get('namespaceV3');var url=namespace + '/resource?limit=' + limit;var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:{contextType:'resource-study-suggest',resourceId:resourceId,userId:context.get('userId'),containerId:context.get('containerId')?context.get('containerId'):undefined,courseId:context.get('courseId')?context.get('courseId'):undefined,unitId:context.get('unitId')?context.get('unitId'):undefined,lessonId:context.get('lessonId')?context.get('lessonId'):undefined}};return _ember['default'].$.ajax(url,options);}, /**
   * Gets resource suggestion for an specific collection/assessment in a context
   * @param {SuggestContext} context
   * @param {number} limit
   * @returns {*}
   */suggestResourcesForCollection:function suggestResourcesForCollection(context){var limit=arguments.length <= 1 || arguments[1] === undefined?3:arguments[1];var adapter=this;var namespace=this.get('namespaceV3');var url=namespace + '/resource?limit=' + limit;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:JSON.stringify({context:{context_type:'collection-study',context_area:'study-player',collection_id:context.get('collectionId'),user_id:context.get('userId'),course_id:context.get('courseId')?context.get('courseId'):undefined,unit_id:context.get('unitId')?context.get('unitId'):undefined,lesson_id:context.get('lessonId')?context.get('lessonId'):undefined},metrics:{score:context.get('score') >= 0?context.get('score'):undefined,timespent:context.get('timeSpent') >= 0?context.get('timeSpent'):undefined}})};return _ember['default'].$.ajax(url,options);}, /**
   * Add class-activity suggestions
   * @param {SuggestContext} context
   * @returns {*}
   */suggestContent:function suggestContent(context){var adapter=this;var namespace=adapter.get('namespaceST');var url=namespace + '/track';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(context)};return _ember['default'].$.ajax(url,options);}, /**
   * Add class-activity suggestions
   * @param {SuggestContext} context
   * @returns {*}
   */fetchSuggestionForCA:function fetchSuggestionForCA(classId,context){var adapter=this;var namespace=adapter.get('namespaceST');var url=namespace + '/ca/class/' + classId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(context)};return _ember['default'].$.ajax(url,options);}, /**
   * Add class-activity suggestions
   */fetchAcrossClassSuggestionsByCode:function fetchAcrossClassSuggestionsByCode(userId,code,params){var adapter=this;var namespace=adapter.get('namespaceST');var url=namespace + '/user/' + userId + '/code/' + code + '/codetype/competency';var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch In-class suggestionsnfor student
   */fetchInClassSuggestionsForStudent:function fetchInClassSuggestionsForStudent(userId,classId,params){var adapter=this;var namespace=adapter.get('namespaceST');var url=namespace + '/user/' + userId + '/class/' + classId;var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * Add class-activity suggestions
   */fetchInClassSuggestionsByCode:function fetchInClassSuggestionsByCode(userId,code,params){var adapter=this;var namespace=adapter.get('namespaceST');var url=namespace + '/user/' + userId + '/code/' + code + '/codetype/competency';var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});