define('gooru-web/components/grading/gru-rubric-off',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-rubric-off'], // -------------------------------------------------------------------------
// Actions
actions:{}, // -------------------------------------------------------------------------
// Properties
/**
   * Rubric to grade
   * @property {Rubric} rubric
   */rubric:null, /**
   * Student grade results
   * @property {RubricGrade} grade
   */grade:null});});