define('gooru-web/serializers/analytics/current-location',['exports','ember','gooru-web/models/analytics/current-location'],function(exports,_ember,_gooruWebModelsAnalyticsCurrentLocation){ /**
 * CurrentLocation's Serializer
 *
 * @typedef {Object} CurrentLocationSerializer
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Normalize the response from CurrentLocation for all classes
   * @param payload is the endpoint response in JSON format
   * @returns {CurrentLocation[]}
   */normalizeForGetUserClassesLocation:function normalizeForGetUserClassesLocation(payload){var serializer=this;if(_ember['default'].isArray(payload.usageData) && payload.usageData.length > 0){return payload.usageData.map(function(locationPayload){return serializer.normalizeCurrentLocation(locationPayload);});}return [];}, /**
   * Normalize the response from CurrentLocation endpoint
   * @param payload is the endpoint response in JSON format
   * @returns {CurrentLocation} a current location model object
   */normalizeForGetUserCurrentLocation:function normalizeForGetUserCurrentLocation(payload){var currentLocation=null;if(_ember['default'].isArray(payload.content) && payload.content.length > 0){var locationPayload=payload.content[0];return this.normalizeCurrentLocation(locationPayload);}return currentLocation;}, /**
   * Normalize the response from CurrentLocation endpoint
   * @param locationPayload is the endpoint response in JSON format
   * @returns {CurrentLocation} a current location model object
   */normalizeCurrentLocation:function normalizeCurrentLocation(locationPayload){var currentLocation=null;currentLocation = _gooruWebModelsAnalyticsCurrentLocation['default'].create({classId:locationPayload.classId,courseId:locationPayload.courseId,unitId:locationPayload.unitId,lessonId:locationPayload.lessonId,collectionId:locationPayload.collectionId?locationPayload.collectionId:locationPayload.assessmentId,collectionType:locationPayload.collectionType?locationPayload.collectionType:locationPayload.collectionId?'collection':'assessment',status:locationPayload.status,pathId:locationPayload.pathId || 0,pathType:locationPayload.pathType || null,ctxPathId:locationPayload.ctxPathId || 0,ctxPathType:locationPayload.ctxPathType || null,scoreInPercentage:locationPayload.scoreInPercentage || null,collectionTitle:locationPayload.collectionTitle || locationPayload.assessmentTitle,milestoneId:locationPayload.milestoneId || null});return currentLocation;}});});