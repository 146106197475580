define('gooru-web/components/reports/pull-up/activity-list',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['activity-list-pull-up'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.openPullUp();},didDestroyElement:function didDestroyElement(){this.set('activityContents',_ember['default'].A([]));}, // -------------------------------------------------------------------------
// Actions
actions:{onSuggestContent:function onSuggestContent(collection){var component=this;component.sendAction('onSuggestContent',collection);}, /**
     * Action triggered when close pull up
     */onClosePullUp:function onClosePullUp(closeAll){var component=this;component.closePullUp(closeAll);}, /**
     * Action triggered when click show more activity
     */onClickShowMoreActivity:function onClickShowMoreActivity(){var component=this;component.sendAction('onClickShowMoreActivity');}}, // -------------------------------------------------------------------------
// Properties
/**
   * Propery to hide the default pullup.
   * @property {showPullUp}
   */showPullUp:false, /**
   * @property {String} type
   */type:'', /**
   * @property {Boolean} isShowMoveVisible
   */isShowMoveVisible:_ember['default'].computed('activityContents',function(){var component=this;var activityTotalHitCount=component.get('activityTotalHitCount');var offsetCount=component.get('offsetCount');return offsetCount < activityTotalHitCount;}), // -------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'0%'},400);},closePullUp:function closePullUp(closeAll){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);component.sendAction('onResetPullUpData');if(closeAll){component.sendAction('onClosePullUp');}});}});});