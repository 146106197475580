define('gooru-web/components/content/modals/gru-oa-remix',['exports','ember','gooru-web/components/content/modals/gru-base-remix'],function(exports,_ember,_gooruWebComponentsContentModalsGruBaseRemix){exports['default'] = _gooruWebComponentsContentModalsGruBaseRemix['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} OfflineActivity service API SDK
   */oaService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {Service} Lesson service API SDK
   */lessonService:_ember['default'].inject.service('api-sdk/lesson'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-oa-remix'], // -------------------------------------------------------------------------
// Actions
copyContent:function copyContent(offlineActivity){this.set('isLoading',true);return this.get('oaService').copyActivity(offlineActivity.get('id'));},updateContent:function updateContent(offlineActivity){var component=this;return component.get('oaService').updateActivityTitle(offlineActivity.get('id'),offlineActivity.get('title')).then(function(){var courseId=component.get('courseId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var oaId=component.get('contentModel.id');var isCollection=component.get('isCollection');var oaType='offline-activity';return lessonId && component.get('contentModel.format') !== oaType?component.get('lessonService').associateAssessmentOrCollectionToLesson(courseId,unitId,lessonId,oaId,isCollection,oaType):_ember['default'].RSVP.resolve({});});},showSuccessNotification:function showSuccessNotification(offlineActivity){var component=this;var successMsg=component.get('i18n').t('oa.remix.success',{oaTitle:offlineActivity.get('title')});var oaEditUrl=component.get('router').generate('content.activity.edit',offlineActivity.get('id'));var edit=component.get('i18n').t('common.edit');component.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + oaEditUrl + '">' + edit + '</a>');},showFailureNotification:function showFailureNotification(){var message=this.get('i18n').t('errors.oa-not-copied').string;this.get('notifications').error(message);},init:function init(){this._super.apply(this,arguments);this.set('courseId',this.get('model.courseId'));this.set('unitId',this.get('model.unitId'));this.set('lessonId',this.get('model.lessonId'));this.set('isCollection',this.get('model.isCollection'));}, /**
   * Disable remix button once user clicked it
   */isLoading:false});});