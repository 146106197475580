define('gooru-web/adapters/content/collection',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Collection CRUD operations in the API 3.0
 *
 * @typedef {Object} CollectionAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/collections',quizzesCollectionnamespace:'/quizzes/api/v1/collections',quizzesPerformancenamespace:'/api/nucleus-insights/v2/dca/class',copierNamespace:'/api/nucleus/v1/copier',externalNamespace:'/api/nucleus/v1/collections-external',updateNamespace:'/api/nucleus/v1', /**
   * Posts a new collection
   *
   * @param data collection data to be sent in the request body
   * @returns {Promise}
   */createCollection:function createCollection(data){var adapter=this;var url=this.get('namespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data.body)};return _ember['default'].$.ajax(url,options);}, /**
   * Reads a Collection by id
   *
   * @param {string} collectionId
   * @returns {Promise}
   */readCollection:function readCollection(collectionId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + collectionId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Reads a Collection by id
   *
   * @param {string} collectionId
   * @returns {Promise}
   */readQuizzesCollection:function readQuizzesCollection(collectionId,type){var refresh=arguments.length <= 2 || arguments[2] === undefined?false:arguments[2];var adapter=this;var namespace=adapter.get('quizzesCollectionnamespace');var url=namespace + '/' + collectionId + '?type=' + type + '&refresh=' + refresh;var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Reads a Collection by id
   *
   * @param {string} collectionId
   * @returns {Promise}
   */readPerformanceCollection:function readPerformanceCollection(classId,collectionId,startDate){var adapter=this;var namespace=adapter.get('quizzesPerformancenamespace');var url=namespace + '/' + classId + '/assessment/' + collectionId + '/performance?startDate=' + startDate + '&endDate=' + startDate;var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Reads an External Collection by id
   *
   * @param {string} collectionId
   * @returns {Promise}
   */readExternalCollection:function readExternalCollection(collectionId){var adapter=this;var namespace=adapter.get('externalNamespace');var url=namespace + '/' + collectionId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Reads a Collection by id
   *
   * @param {string} collectionId
   * @returns {Promise}
   */readPerformanceCollectionDCA:function readPerformanceCollectionDCA(classId,collectionId,startDate,collectionType){var adapter=this;var namespace=adapter.get('quizzesPerformancenamespace');var url=namespace + '/' + classId + '/' + collectionType + '/' + collectionId + '/performance?startDate=' + startDate + '&endDate=' + startDate;if(collectionType === 'collection'){url = namespace + '/' + classId + '/' + collectionType + '/' + collectionId + '/performance?date=' + startDate;}var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Update a Collection
   *
   * @param collectionId the id of the Collection to be updated
   * @param data Collection data to be sent in the request body
   * @returns {Promise}
   */updateCollection:function updateCollection(collectionId,data){var type=arguments.length <= 2 || arguments[2] === undefined?'collections':arguments[2];var adapter=this;var namespace=this.get('updateNamespace');var url=namespace + '/' + type + '/' + collectionId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Reorder collection resources
   *
   * @param collectionId the id of the Collection to be updated
   * @param data Collection data to be sent in the request body
   * @returns {Promise}
   */reorderCollection:function reorderCollection(collectionId,data){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + collectionId + '/order';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes a collection by id
   *
   * @param collectionId collection id to be sent
   * @returns {Promise}
   */deleteCollection:function deleteCollection(collectionId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + collectionId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Adds a resource to a collection
   *
   * @param {string} collectionId
   * @param {string} resourceId
   * @returns {Promise}
   */addResource:function addResource(collectionId,resourceId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + collectionId + '/resources';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',headers:adapter.defineHeaders(),data:JSON.stringify({id:resourceId})};return _ember['default'].$.ajax(url,options);}, /**
   * Adds a question to a collection
   *
   * @param {string} collectionId
   * @param {string} questionId
   * @returns {Promise}
   */addQuestion:function addQuestion(collectionId,questionId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + collectionId + '/questions';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',headers:adapter.defineHeaders(),data:JSON.stringify({id:questionId})};return _ember['default'].$.ajax(url,options);}, /**
   * Copies a collection by id
   *
   * @param collectionId
   * @returns {Promise}
   */copyCollection:function copyCollection(collectionId){var type=arguments.length <= 1 || arguments[1] === undefined?'collections':arguments[1];var adapter=this;var namespace=this.get('copierNamespace');var url=namespace + '/' + type + '/' + collectionId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Creates a new external collection
   *
   * @param data collection data to be sent in the request body
   * @returns {Promise}
   */createExternalCollection:function createExternalCollection(collectionData){var adapter=this;var url=this.get('externalNamespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(collectionData)};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};},fetchCollectionSummary:function fetchCollectionSummary(param){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/summary';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(param)};return _ember['default'].$.ajax(url,options);}});});