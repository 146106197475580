define('gooru-web/controllers/sign-in',['exports','ember','gooru-web/models/sign-in/sign-in','gooru-web/config/environment','gooru-web/config/config','gooru-web/config/parse-event','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebModelsSignInSignIn,_gooruWebConfigEnvironment,_gooruWebConfigConfig,_gooruWebConfigParseEvent,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
queryParams:['sessionEnds','redirectURL','nonce','userEmail'], /**
   * @property {Service} Session
   */session:_ember['default'].inject.service(), /**
   * @property {Service} Session service
   */sessionService:_ember['default'].inject.service('api-sdk/session'), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} TenantService service
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'),appplicationController:_ember['default'].inject.controller('application'),castEventService:_ember['default'].inject.service('api-sdk/cast-event/cast-event'),parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), /**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {Service} LookupService service
   */lookupService:_ember['default'].inject.service('api-sdk/lookup'), /**
   * @property {Controller} Application Controller
   */applicationController:_ember['default'].inject.controller('application'), // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var component=this;var tenantService=this.get('tenantService');tenantService.removeStoredTenantSetting();tenantService.getActiveTenantSetting().then(function(tenantSettings){component.set('tenantSetting',tenantSettings);if(tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.show_classlink_sso_button && tenantSettings.ui_element_visibility_settings.show_classlink_sso_button === true){component.set('showClassLinkLogin',true);}if(tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.enable_navigator_programs){component.set('enableNavigatorPrograms',true);}if(tenantSettings && tenantSettings.know_more_about_user_questions && tenantSettings.know_more_about_user_questions.length){component.set('showKnowMoreAboutUser',true);}if(tenantSettings && tenantSettings.enable_email_verification === 'off'){component.set('emailVerification',false);}else {component.set('emailVerification',true);}});}, // -------------------------------------------------------------------------
// Actions
actions:{authenticate:function authenticate(){var controller=this;var user=controller.get('user');var enableNavigatorPrograms=controller.get('enableNavigatorPrograms');var showKnowMoreAboutUser=controller.get('showKnowMoreAboutUser');var emailVerification=controller.get('emailVerification');controller.get('notifications').clear();controller.get('notifications').setOptions({positionClass:'toast-top-full-width sign-in'});if(emailVerification){(function(){var userProfile=JSON.parse(window.localStorage.getItem('userLoginProfile'));if(userProfile){controller.get('sessionService').signUp(userProfile).then(function(){var userId=controller.get('session.userId');if(userId){controller.get('profileService').readUserProfile(userId).then(function(updatedProfile){if(!updatedProfile.role){updatedProfile.set('role',userProfile.role);updatedProfile.set('country',userProfile.country);updatedProfile.set('state',userProfile.state);updatedProfile.set('countryId',userProfile.countryId);updatedProfile.set('stateId',userProfile.stateId);controller.get('profileService').updateMyProfile(updatedProfile).then(function(){var session=controller.get('session');controller.get('applicationController').loadSessionProfile(updatedProfile);session.set('userData.isNew',true);session.set('userData.role',userProfile.role);session.set('updatedProfile',updatedProfile);session.set('role',userProfile.role);controller.get('sessionService').updateUserData(session.get('userData'));},function(){return _ember['default'].Logger.error('Error updating user');});}});}});}})();} // TODO needs to be revisited, this is a quick fix
controller.get('sessionService').authorize().then(function(){if(controller.get('didValidate') === false){var username=_ember['default'].$('.gru-input.username input').val();var password=_ember['default'].$('.gru-input.password input').val();user.set('username',username);user.set('password',password);}user.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){controller.get('sessionService').signInWithUser(user,true).then(function(){var encodedEmail=window.btoa(user.get('username'));var resourceShareLinkId=window.localStorage.getItem('resourceShareLinkId');window.localStorage.removeItem('resourceShareLinkId');window.localStorage.setItem('userEmail',encodedEmail);controller.get('appplicationController').initalizeExternalTools();controller.set('didValidate',true);var userId=controller.get('session.userId');controller.get('profileService').readUserProfile(userId).then(function(updatedProfile){controller.set('currentRole',updatedProfile.role);controller.set('session.updatedProfile',updatedProfile);if(!enableNavigatorPrograms || updatedProfile.role === 'teacher' || updatedProfile.info && showKnowMoreAboutUser){if(resourceShareLinkId){controller.transitionToRoute('content.resources.play',resourceShareLinkId);}else {controller.send('signIn');}}else {if(enableNavigatorPrograms && updatedProfile.role === 'student' && (!updatedProfile.info || updatedProfile.info && updatedProfile.info.grade === undefined)){if(resourceShareLinkId){controller.transitionToRoute('content.resources.play',resourceShareLinkId);}else {controller.send('signUpFinish');}}else {if(resourceShareLinkId){controller.transitionToRoute('content.resources.play',resourceShareLinkId);}else {controller.send('signIn');}}}});var data={};controller.get('castEventService').castEvent(_gooruWebConfigConfig.CAST_EVENTS.LOGIN,data); // Trigger parse event
controller.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.LOGIN);},function(error){var anonymousSessionData=controller.get('anonymousSessionData'); // Authenticate as anonymous if it fails to mantain session
controller.get('session').authenticateAsAnonymousWithData(anonymousSessionData);var message=error.responseJSON.message; //If your mail was not verified Throwing a 403 error and email not verified message
if(message === _gooruWebConfigConfig.EMAIL_VALIDATION.EMAIL){var queryParam={queryParams:{not_verified:true}};controller.transitionToRoute('email-verification',queryParam);}else {controller.set('showErrorMsg',true);}});}});});}, /**
     * Action triggered when click on back arrow
     */goBack:function goBack(){window.history.back();}, /**
     * Action triggered when click on sign in with classlink button
     */tenantLogin:function tenantLogin(){var tenantUrl=this.get('configuration.OAUTH_LOGIN_LANUCH_URL');var tenantShortName=this.get('anonymousSessionData.tenant.tenantShortName');window.location.href = tenantUrl + '/' + tenantShortName;}}, // -------------------------------------------------------------------------
// Methods
/**
   * init and reset all the properties for the validations
   */resetProperties:function resetProperties(){var controller=this;var userEmail=controller.get('userEmail');if(controller.get('nonce')){var email=window.localStorage.getItem('userEmail');if(email){userEmail = window.atob(email);}}var user=_gooruWebModelsSignInSignIn['default'].create(_ember['default'].getOwner(this).ownerInjection(),{username:userEmail?userEmail:null,password:null});controller.set('user',user);var homeURL=window.location.protocol + '//' + window.location.host;var redirectURL=controller.get('redirectURL') || homeURL;var url='' + homeURL + _gooruWebConfigEnvironment['default']['google-sign-in'].url + '?redirectURL=' + redirectURL;controller.set('googleSignInUrl',url);controller.set('didValidate',false);controller.set('userEmail',null);var gg4lUrl='' + homeURL + _gooruWebConfigEnvironment['default']['passport-login-url'].url;controller.set('gg4lLoginUrl',gg4lUrl);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {User} user
   */user:null,tenantSetting:null,tenantLogo:_ember['default'].computed('tenantSetting',function(){var tenantSettings=this.get('tenantSetting');return tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.logo_url;}),isShowTenantLogo:_ember['default'].computed('tenantSetting',function(){var tenantSettings=this.get('tenantSetting');return tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.show_logo === true;}),target:null, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false, /**
   * Query param
   * @property {Boolean} sessionEnds
   */sessionEnds:false,redirectURL:null,userEmail:null, /**
   * Maintain the state of redirection completed or not
   * @property {Boolean}
   */isRedirectionDomainDone:false, /**
   * Computed property to identify gooru or tenant login.
   */isGooruLogin:_ember['default'].computed.alias('session.isGooruClientId'), /**
   * Boolean value to show error message or not in the form after clicking on submit/continue
   * @property {Boolean}
   */showErrorMsg:false, /**
   * Logged in tenant logo URL
   */tenantLogoUrl:_ember['default'].computed('anonymousSessionData',function(){return this.get('isShowTenantLogo')?this.get('tenantLogo'):this.get('anonymousSessionData.tenant.imageUrl');}),isShowGg4lButton:_ember['default'].computed.alias('configuration.GRU_FEATURE_FLAG.isShowGg4lButton'), /**
   * Maintains the state of anonymous session data.
   * @type {Session}
   */anonymousSessionData:null, /**
   * Computed property to maintain the tenant name
   */tenantName:_ember['default'].computed('isGooruLogin',function(){return this.get('isGooruLogin')?_gooruWebConfigConfig.GOORU_SHORT_NAME:this.get('anonymousSessionData.tenant.tenantShortName');}),showClassLinkLogin:false});});