define('gooru-web/components/content/modals/gru-activity-new',['exports','ember','gooru-web/components/content/modals/gru-collection-new','gooru-web/models/content/activity'],function(exports,_ember,_gooruWebComponentsContentModalsGruCollectionNew,_gooruWebModelsContentActivity){exports['default'] = _gooruWebComponentsContentModalsGruCollectionNew['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {activityService} Activity service API SDK
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-activity-new'], // -------------------------------------------------------------------------
// Actions
validate:function validate(){var activity=this.get('activity');return activity.validate();},createAssessmentOrCollection:function createAssessmentOrCollection(){return this.get('activityService').createActivity(this.get('activity'));},associateToLesson:function associateToLesson(courseId,unitId,lessonId,activityId){return this.get('lessonService').associateActivityToLesson(courseId,unitId,lessonId,activityId,false);},closeModal:function closeModal(activityId){this.set('isLoading',false);this.triggerAction({action:'closeModal'});var queryParams={queryParams:{editing:true}};this.get('router').transitionTo('content.activity.edit',activityId,queryParams);},showErrorMessage:function showErrorMessage(error){_ember['default'].Logger.error(error);var message=this.get('i18n').t('common.errors.activity-not-created').string;this.get('notifications').error(message);}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var activity=_gooruWebModelsContentActivity['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null,classroom_play_enabled:false});this.set('activity',activity);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Activity} activity
   */activity:null});});