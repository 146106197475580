define('gooru-web/mixins/configuration',['exports','ember'],function(exports,_ember){ /**
 * Convenience mixin for accesing the app configuration
 *
 * @typedef {Object} ConfigurationMixin
 */exports['default'] = _ember['default'].Mixin.create({ /**
   * @property {Ember.Service} Service to configuration properties
   */configurationService:_ember['default'].inject.service('configuration'), /**
   * @property {*} application configuration properties
   */configuration:_ember['default'].computed.alias('configurationService.configuration'), /**
   * @property {*} application feature properties
   */features:_ember['default'].computed.alias('configuration.features'), /**
   * @property {string}
   */appRootPath:_ember['default'].computed.alias('configuration.appRootPath'), /**
   * @property {string}
   */reservedWords:_ember['default'].computed.alias('configuration.reservedWords'), /**
   * @property {string}
   */adminRootPath:_ember['default'].computed.alias('configuration.ADMIN_GUEST_ROOT_PATH'),redirectEndpoint:_ember['default'].computed.alias('configuration.REDIRECT_ENDPOINT'), /**
   * Returns the local storage
   * @returns {Storage}
   */getLocalStorage:function getLocalStorage(){return window.localStorage;}});});