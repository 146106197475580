define('gooru-web/components/gru-teacher-featured-courses',['exports','ember','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebMixinsModal){ /**
 * teacher featured courses component
 *
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
// -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to preview the course
     * @param course
     */previewCourse:function previewCourse(course){var component=this;var isTeacher=this.get('isTeacher');this.set('course',course);var model=_ember['default'].Object.create({content:course,isTeacher:isTeacher});model.set('remixCourse',function(){return component.remixCourse();});model.set('playCourse',function(){return component.playCourse();});model.set('bookmarkCourse',function(){return component.bookmarkCourse();});component.send('showModal','gru-preview-course',model);}}, /**
   *Action triggered when select remix the course
   */remixCourse:function remixCourse(){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {var remixModel={content:this.get('course')};this.send('showModal','content.modals.gru-course-remix',remixModel);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array[]} - courses
   */courses:null, /**
   * @property {Profile} user profile
   */profile:null});});