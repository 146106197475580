define('gooru-web/adapters/content/featured-courses',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Featured Courses CRUD operations in the API 3.0
 *
 * @typedef {Object} FeaturedCoursesAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/courses',classNamespace:'/api/nucleus/v1/classes/public/course', /**
   * @namespace taxonomyDSNamespace
   * API Endpoint of the DS users for taxonomy
   */taxonomyDSNamespace:'/api/ds/users/v2/tx', /**
   * Get independent course list
   *
   * @returns {Promise|Object}
   */getIndependentCourseList:function getIndependentCourseList(params){var namespace=this.get('namespace');var url=namespace + '/independent/list';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * @param courseId course id to be sent
   * @param gradeLowerBound grade lower bound to be sent
   * @function joinPublicClassbyGrade Method to join public class by grade
   * @returns {Promise} class id return into response header location
   */joinPublicClassbyGrade:function joinPublicClassbyGrade(courseId,gradeLowerBound){var autoJoin=arguments.length <= 2 || arguments[2] === undefined?true:arguments[2];var adapter=this;var namespace=this.get('classNamespace');var url=namespace + '/' + courseId + '/members';var options={type:'PUT',contentType:'application/json',headers:adapter.defineHeaders(),data:JSON.stringify({grade_lower_bound:gradeLowerBound,auto_join:autoJoin})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});