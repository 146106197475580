define('gooru-web/components/player/gru-question-viewer',['exports','ember','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){ /**
 * Player question viewer
 *
 * Component responsible for providing a frame where all question types
 * will be displayed i.e. it will be responsible for selecting any specific
 * question components per question type.
 *
 * @module
 * @see controllers/player.js
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-question-viewer'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user see a hint
     */showHint:function showHint(){var actualHint=this.get('actualHint');this.get('hintsToDisplay').pushObject(this.get('question.hints').objectAt(actualHint));actualHint += 1;this.set('actualHint',actualHint);},showExplanation:function showExplanation(){this.set('isExplanationShown',true);}, /**
     * When the question is submitted
     */submitQuestion:function submitQuestion(){this.submitQuestion();}, /**
     * When the question answer has been changed
     * @param {Resource} question the question
     */changeAnswer:function changeAnswer(question){if(!this.get('submitted')){ //todo track analytics
this.set('question',question);}}, /**
     * When the question answer has been completed
     * @param {Resource} question the question
     * @param { { answer: Object, correct: boolean } } stats
     */completeAnswer:function completeAnswer(question,stats){if(!this.get('submitted')){var questionResult=this.get('questionResult');questionResult.set('userAnswer',stats.answer);questionResult.set('correct',stats.correct);this.set('question',question);this.set('answerCompleted',true);}}, /**
     * When the question answer has been cleared
     * @param {Resource} question the question
     */clearAnswer:function clearAnswer(question){if(!this.get('submitted')){ //todo track analytics
this.set('question',question);this.set('answerCompleted',false);}}, /**
     * When the question answer was loaded from BE
     * @param {Resource} question the question
     * @param { { answer: Object, correct: boolean } } stats
     */loadedAnswer:function loadedAnswer(question,stats){if(!this.get('submitted')){var questionResult=this.get('questionResult');questionResult.set('userAnswer',stats.answer);questionResult.set('correct',stats.correct);this.set('question',question);this.set('answerCompleted',false);}}}, // -------------------------------------------------------------------------
// Events
/**
   * Listen to enter in order to submit the question when the user press enter
   */listenToEnter:_ember['default'].on('didInsertElement',function(){var component=this;this.fontChanger();this.fontChangerOld();$(document).on('keyup',function(e){if(e.which === _gooruWebConfigConfig.KEY_CODES.ENTER){if(!component.get('isSubmitDisabled')){if(!component.get('question.isOpenEnded')){component.submitQuestion();}}}});}), /**
   * Removed keyup handler when the component will destroy
   */disableListenToEnter:_ember['default'].on('willDestroyElement',function(){$(document).off('keyup');}), // -------------------------------------------------------------------------
// Properties
/**
   * Hits available for a question
   * @property {number} availableHints
   */actualHint:0, /**
   * Hits available for a question
   * @property {number} availableHints
   */availableHints:_ember['default'].computed('actualHint','question',function(){return this.get('question.hints.length') - this.get('actualHint');}), /**
   * @property {boolean} indicates when the answer is completed
   */answerCompleted:false, /**
   * Default button text key
   * @property {string}
   */buttonTextKey:'common.save', /**
   * The collection
   * @property {Collection}
   */collection:null, /**
   * Indicates when the question has explanation
   * @property {boolean}
   */doesNotHaveExplanation:_ember['default'].computed.not('question.explanation'), /**
   * Unicode value depending on the correctness of the question
   * @property {boolean}
   */feedbackUnicode:_ember['default'].computed('questionResult.correct',function(){return this.get('questionResult.correct')?_gooruWebConfigConfig.FEEDBACK_EMOTION_VALUES.CORRECT:_gooruWebConfigConfig.FEEDBACK_EMOTION_VALUES.INCORRECT;}), /**
   * Hints to display
   * @property {Array} hintsToDisplay
   */hintsToDisplay:_ember['default'].A(), /**
   * Default instructions action text key
   * @property {string}
   */instructionsActionTextKey:'common.save', /**
   * Key to show the correct/incorrect message
   * @property {String} isCorrectMessageKey
   */isCorrectMessageKey:_ember['default'].computed('questionResult.correct',function(){return this.get('questionResult.correct')?'common.answer-correct':'common.answer-incorrect';}), /**
   * Is the explanation shown?
   * @property {boolean} disableExplanation
   */isExplanationShown:false, /**
   * Is the explanation button disabled?
   * @property {boolean} disableHint
   */isExplanationButtonDisabled:_ember['default'].computed.or('isExplanationShown','doesNotHaveExplanation'), /**
   * @property {boolean} indicates when the inputs are enabled
   */isInputDisabled:_ember['default'].computed('questionResult.submittedAnswer','showFeedback',function(){var showFeedback=this.get('showFeedback');return showFeedback && this.get('isStudent') && this.get('questionResult.submittedAnswer') || this.get('submitted');}), /**
   * Is the hints button disabled?
   * @property {boolean} disableHint
   */isHintButtonDisabled:_ember['default'].computed.not('availableHints'), /**
   * Indicates if the student is playing the collection
   * @property {boolean}
   */isStudent:_ember['default'].computed.equal('role','student'), /**
   * @property {boolean} indicates when the submit functionality is enabled
   */isSubmitDisabled:_ember['default'].computed('answerCompleted','submitted','questionResult.submittedAnswer','showFeedback',function(){var showFeedback=this.get('showFeedback');if(!showFeedback || this.get('isTeacher') || !this.get('questionResult.submittedAnswer')){return this.get('submitted') || !this.get('answerCompleted');}return false;}), /**
   * Indicates if the teacher is playing this collection
   * @property {boolean}
   */isTeacher:_ember['default'].computed.not('isStudent'), /**
   * @property {string} on submit question action
   */onSubmitQuestion:'submitQuestion', /**
   * The question
   * @property {Resource} question
   */question:null, /**
   * Question result, it is passed as a parameter for this component
   * @property {QuestionResult}
   */questionResult:null,compIndex:0, /**
   * Indicates if feedback should be shown
   * @property {boolean}
   */showFeedback:_ember['default'].computed('collection.showFeedback','questionResult.submittedAnswer','showQuestionFeedback',function(){var isShowQuestionFeedbackSet=this.get('showQuestionFeedback') !== undefined;var feedback=isShowQuestionFeedbackSet?this.get('showQuestionFeedback'):this.get('collection.showFeedback') === _gooruWebConfigConfig.ASSESSMENT_SHOW_VALUES.IMMEDIATE;return feedback && this.get('isStudent') && this.get('questionResult.submittedAnswer');}), /**
   * it forces to show the question feedback, no matter what configuration is set for the collection,
   * should be undefined by default, so the property is ignored
   * @property {boolean}
   */showQuestionFeedback:undefined, /**
   * Indicates when the collection is already submitted
   * @property {boolean}
   */submitted:false, /**
   * Indicates the user's role, could be 'student', 'teacher' or null
   * @property {string}
   */role:null,isSerpMultiChoice:_ember['default'].computed.equal('question.questionType','SERP_MC'), // -------------------------------------------------------------------------
// Observers
/**
   * Observes for the question itself
   * When it is changed some data should be reloaded
   */reloadQuestion:(function(){this.setProperties({actualHint:0,answerCompleted:false,hintsToDisplay:_ember['default'].A(),isExplanationShown:false});}).observes('question'), // -------------------------------------------------------------------------
// Methods
submitQuestion:function submitQuestion(){if(!this.get('submitted')){var questionResult=this.get('questionResult');this.sendAction('onSubmitQuestion',this.get('question'),questionResult);}},fontChangerOld:function fontChangerOld(){var component=this;component.$('.panel-body h2').on('input','.font-size-loader .font-changer',function(){var el=component.$(this).closest('.panel-body');var fontChanger=el.find('.decoding-answers .decoding-answer .decoding-text, .silent-reading-answers .silent-reading-text');if(el.parent().hasClass('SERP_AFC')){var panelBody=el.find('.panel-body');panelBody.find('.question .gru-math-text').css('zoom','' + parseFloat($(this).val() / 10));}else {fontChanger.css('zoom','' + parseFloat($(this).val() / 10));}});},fontChanger:function fontChanger(){var component=this;component.$('.question-panel-left h2').on('input','.font-size-loader .font-changer',function(){var el=component.$(this).closest('.question-panel-left');var el1=component.$('.answers-panel');var fontChanger=el.find('.decoding-answers .decoding-answer .decoding-text, .silent-reading-answers .silent-reading-text');if(el.parent().hasClass('SERP_AFC')){var panelBody=el.find('.panel-default');panelBody.find('.panel-body .question .gru-math-text').css('zoom','' + parseFloat($(this).val() / 10));var panelBody1=el1.find('.gru-comprehension-panel');panelBody1.find('.panel-default .question .gru-math-text').css('zoom','' + parseFloat($(this).val() / 10));}else {fontChanger.css('zoom','' + parseFloat($(this).val() / 10));}});}});});