define('gooru-web/components/ca-taxonomy-picker',['exports','ember','gooru-web/utils/taxonomy'],function(exports,_ember,_gooruWebUtilsTaxonomy){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['ca-taxonomy-picker'], // -------------------------------------------------------------------------
// Dependencies
taxonomyService:_ember['default'].inject.service('taxonomy'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.set('isLoadTaxonomyPicker',false);component.loadData();}, // -------------------------------------------------------------------------
// Actions
actions:{updateSelectedTags:function updateSelectedTags(selectedTags,course,domain,isCloseBrowser){var component=this;var dataTags=selectedTags.map(function(taxonomyTag){return taxonomyTag.get('data');});var standards=_ember['default'].A(dataTags);component.set('selectedCompetencies',standards);if(isCloseBrowser){component.sendAction('onSubmitCompetencies',standards,course,domain);}},loadTaxonomyData:function loadTaxonomyData(path){return new _ember['default'].RSVP.Promise((function(resolve){var subject=this.get('taxonomyPickerData.subject');var taxonomyService=this.get('taxonomyService');if(path.length > 1){(function(){var courseId=path[0];var domainId=path[1];taxonomyService.getCourseDomains(subject,courseId).then(function(){taxonomyService.getDomainCodes(subject,courseId,domainId).then(function(standards){resolve(standards);});});})();}else {var courseId=path[0];taxonomyService.getCourseDomains(subject,courseId).then(function(domains){resolve(domains);});}}).bind(this));}, //Action triggered when close taxonomy picker
onCloseTaxonomyPicker:function onCloseTaxonomyPicker(){var component=this;component.sendAction('onCloseTaxonomyPicker');}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {String} subjectPreference
   */subjectPreference:_ember['default'].computed.alias('classPreference.subject'), /**
   * @property {String} categoryPreference
   */categoryPreference:_ember['default'].computed('classPreference.subject',function(){var component=this;var subjectPreference=component.get('classPreference.subject');return (0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectPreference);}), /**
   * @property {String} frameworkPreference
   */frameworkPreference:_ember['default'].computed.alias('classPreference.framework'), /**
   * i18n key for the browse selector text
   * @property {string}
   */browseSelectorText:_ember['default'].computed('taxonomyPickerData.standardLabel',function(){var standardLabel=this.get('taxonomyPickerData.standardLabel');return standardLabel?'taxonomy.modals.gru-standard-picker.browseSelectorText':'taxonomy.modals.gru-standard-picker.browseCompetencySelectorText';}), /**
   * i18n key for the selected text key
   * @property {string}
   */selectedTextKey:_ember['default'].computed('taxonomyPickerData.standardLabel',function(){var standardLabel=this.get('taxonomyPickerData.standardLabel');return standardLabel?'taxonomy.modals.gru-standard-picker.selectedText':'taxonomy.modals.gru-standard-picker.selectedCompetencyText';}), /**
   * @property {Boolean} isLoadTaxonomyPicker
   * property to show/hide taxonomy picker
   */isLoadTaxonomyPicker:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadData
   * Method to load initial data
   */loadData:function loadData(){var component=this;var category=component.get('categoryPreference');component.getSubjects(category).then(function(subjects){var subjectPreference=component.get('subjectPreference');var activeSubject=subjects.findBy('code',subjectPreference);if(activeSubject){var frameworkId=component.get('frameworkPreference');var subjectId=frameworkId + '.' + subjectPreference;activeSubject.set('frameworkId',frameworkId);activeSubject.set('id',subjectId);component.set('activeSubject',activeSubject);component.getCourses(activeSubject).then(function(){component.loadTaxonomyPicker();});}});}, /**
   * @function getSubjects
   * Method to get subjects
   */getSubjects:function getSubjects(category){var component=this;var taxonomyService=component.get('taxonomyService');return _ember['default'].RSVP.hash({subjects:taxonomyService.getSubjects(category)}).then(function(_ref){var subjects=_ref.subjects;return subjects;});}, /**
   * @function getCourses
   * Method to get courses
   */getCourses:function getCourses(subject){var component=this;var taxonomyService=component.get('taxonomyService');return _ember['default'].RSVP.hash({courses:_ember['default'].RSVP.resolve(taxonomyService.getCourses(subject))}).then(function(_ref2){var courses=_ref2.courses;return courses;});}, /**
   * @function loadTaxonomyPicker
   * Method to load taxonomy picker
   */loadTaxonomyPicker:function loadTaxonomyPicker(){var component=this;var standards=component.get('selectedCompetencies') || [];var subject=component.get('activeSubject');var taxonomyPickerData={selected:standards,shortcuts:null,subject:subject,standardLabel:true};component.set('taxonomyPickerData',taxonomyPickerData);var standardLabel=this.get('taxonomyPickerData.standardLabel')?'common.standard':'common.competency';component.set('panelHeaders',[component.get('i18n').t('common.course').string,component.get('i18n').t('common.domain').string,component.get('i18n').t(standardLabel).string]);component.set('isLoadTaxonomyPicker',true);}});});