define('gooru-web/components/player/chrono-view/chrono-header',['exports','ember','d3','gooru-web/utils/utils'],function(exports,_ember,_d3,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['chrono-header'], /**
   * @property {String} color - Hex color value for the default bgd color
   */defaultBarColor:'#E3E5EA', /**
   * @property {draw observer}
   * draw the timeline activities whenever data model changes
   */draw:_ember['default'].observer('activities.[]','activities.@each.selected',function(){this.set('isLoading',false);this.drawTimeLineActivities();}), /**
   * @property {rightTimeLine}
   */rightTimeLine:null, /**
   * @property {leftTimeLine}
   */leftTimeLine:null, /**
   * @property {activities}
   */activities:null, /**
   * @property {selectedIndex}
   */selectedIndex:null, /**
   * @property {isLoading}
   */isLoading:false, /**
   * @property {activityEndDate}
   */activityEndDate:_ember['default'].computed('activities',function(){var lastIndex=this.get('activities').length - 1;var lastAccesedResource=this.get('activities').objectAt(lastIndex);return this.uiDateFormat(lastAccesedResource.get('lastAccessedDate'));}), /**
   * @property {activityDate}
   */activityDate:_ember['default'].computed('startDate',function(){return this.uiDateFormat(this.get('startDate'));}), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.drawTimeLineActivities();component.paginateNext();},didRender:function didRender(){var component=this;if(component.get('positionToCenter')){component.scrollToCenter();}this.fillActiveResource();},willDestroyElement:function willDestroyElement(){var component=this;component.clearChart();},actions:{onOpenCourseReport:function onOpenCourseReport(){this.sendAction('onOpenCourseReport');}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function uiDateFormat
   * Method to parse given date
   */uiDateFormat:function uiDateFormat(givenDate){givenDate = givenDate || new Date();if(typeof givenDate === 'string'){givenDate = new Date(givenDate);}var dateLocale='en-us',dateMonth=givenDate.toLocaleString(dateLocale,{month:'short'}),dateYear=givenDate.toLocaleString(dateLocale,{year:'2-digit'}),dateDisplay={mon:dateMonth,year:dateYear};return dateDisplay;}, /**
   * @function drawTimeLineActivities
   * Method to draw timeline activities
   */drawTimeLineActivities:function drawTimeLineActivities(){var component=this;component.clearChart();var selectedActivity=component.get('activities').findBy('selected',true);var selectedIndex=component.get('activities').indexOf(selectedActivity);if(selectedIndex > -1){component.set('selectedIndex',selectedIndex);}component.calculateRightNodes();component.calculateLeftNodes();component.drawActiveResource();},fillActiveResource:function fillActiveResource(){var component=this;var selectedActivity=component.get('activities').findBy('selected',true);if(selectedActivity){var color=(0,_gooruWebUtilsUtils.getGradeColor)(selectedActivity.get('score'));component.$('.active-resource').css('background-color',color);}}, /**
   * @function drawActiveResource
   * Function to draw active resource
   */drawActiveResource:function drawActiveResource(){var component=this;var selectedActivity=component.get('activities').findBy('selected',true);var selectedIndex=component.get('activities').indexOf(selectedActivity);if(selectedIndex > -1){var svg=_d3['default'].select('#active-resource').select('svg');if(!svg[0][0]){svg = _d3['default'].select('#active-resource').append('svg').attr('class','center-activities');}var activeResourceGroup=svg.append('g');activeResourceGroup.append('circle').attr('class',function(){var className=selectedActivity.get('pathId')?'suggested':'';return 'active node-' + selectedIndex + ' ' + className;});activeResourceGroup.append('foreignObject').append('xhtml:div').attr('class',function(){var className=selectedActivity.get('collectionType') === 'collection'?'active-collection':'active-assessment';return 'active-resource ' + className;});var currentNodeX=0; //constant cx position
var currentNodeY=35; //constant xy position
var previousIndex=selectedIndex - 1;var nextIndex=selectedIndex + 1;var previousResource=component.get('activities').objectAt(previousIndex);var nextResource=component.get('activities').objectAt(nextIndex);if(nextResource){var nextNode=this.$('.right-node-' + nextIndex);var nextNodeY=parseInt(nextNode.attr('cy'));if(currentNodeY === nextNodeY){this.drawHorizontalLine({x:80, //constant startpoint position
y:currentNodeY - 5,length:20},nextResource.pathId,'center');}else {this.drawCurve({x:80,y:currentNodeY},{x:nextNodeY - currentNodeY,y:currentNodeY - 5,curve:20},nextResource.pathId,'center');}}if(previousResource){var previousNode=this.$('.left-node-' + previousIndex);var prevNodeY=parseInt(previousNode.attr('cy'));if(currentNodeY === prevNodeY){this.drawHorizontalLine({x:currentNodeX,y:currentNodeY - 5,length:20},selectedActivity.pathId,'center');}else {this.drawCurve({x:currentNodeX,y:currentNodeY},{x:currentNodeY - prevNodeY,y:prevNodeY - 5,curve:20},selectedActivity.pathId,'center');}}}}, /**
   * @function calculateLeftNodes
   * Function to calculate left timeline
   */calculateLeftNodes:function calculateLeftNodes(){var component=this;var resources=this.get('activities');var selectedIndex=this.get('selectedIndex');var leftTimeLine=resources.slice(0,selectedIndex);component.set('leftTimeLine',leftTimeLine);component.drawNodes(leftTimeLine,'left');component.drawPath('left');component.handleView('left');}, /**
   * @function calculateRightNodes
   * Function to calculate right timeline
   */calculateRightNodes:function calculateRightNodes(){var component=this;var resources=this.get('activities');var selectedIndex=this.get('selectedIndex');var rightTimeLine=resources.slice(selectedIndex + 1,resources.length);component.set('rightTimeLine',rightTimeLine);component.drawNodes(rightTimeLine,'right');component.handleView('right');component.drawPath('right');}, /**
   * @function drawPath
   * Function to draw path
   */drawPath:function drawPath(position){var _this=this;var resources=position === 'right'?this.get('rightTimeLine'):this.get('leftTimeLine');resources.forEach(function(resource){var index=_this.get('activities').map(function(x){return x;}).indexOf(resource);var node=_this.$('.' + position + '-node-' + index);var nodeX=parseInt(node.attr('cx'));var nodeY=parseInt(node.attr('cy'));if(index < _this.get('activities').length - 1){var nextIndex=index + 1;var nextResource=_this.get('activities').get(nextIndex);var nextNode=_this.$('.' + position + '-node-' + nextIndex);var nextNodeY=parseInt(nextNode.attr('cy'));if(nextResource && nextNode.length > 0){if(nodeY === nextNodeY){_this.drawHorizontalLine({x:nodeX + 8,y:nodeY,length:14},nextResource.pathId,position);}else {_this.drawCurve({x:nodeX + 10,y:nodeY},{x:nextNodeY - nodeY,y:nodeY,curve:10},nextResource.pathId,position);}}}});}, /**
   * @function drawHorizontalLine
   * Function to draw horizontal line
   */drawHorizontalLine:function drawHorizontalLine(startPoint,isSuggestion,position){_d3['default'].select('.' + position + '-activities').append('path').attr('class',function(){return isSuggestion?'suggestion-line':'line';}).attr('d','M ' + startPoint.x + ' ' + startPoint.y + ' l ' + startPoint.length + ' 0');}, /**
   * @function drawCurve
   * Function to draw curve line
   */drawCurve:function drawCurve(startPoint,points,isSuggestion,position){_d3['default'].select('.' + position + '-activities').append('path').attr('class',function(){return isSuggestion?'suggestion-curve':'curve';}).attr('d','M ' + startPoint.x + ' ' + points.y + ' q 5 0 ' + points.curve + ' ' + points.x);}, /**
   * @function drawNodes
   * Function to draw nodes
   */drawNodes:function drawNodes(timeLine,position){var component=this;var resources=this.get('activities');var isLeft=position === 'left';var svg=_d3['default'].select('#' + position + '-activities').select('svg');if(!svg[0][0]){svg = _d3['default'].select('#' + position + '-activities').append('svg').attr('class',position + '-activities');}var node=svg.selectAll('.student-node').data(timeLine).enter();var group=node.append('g');group.append('circle').attr('class',function(d){var index=resources.map(function(x){return x;}).indexOf(d);var className=d.pathId?'suggestion-activity':'activity';return className + ' ' + position + '-node-' + index;}).attr('cx',function(d,i){var xAxis=11 + i * 30;return isLeft?xAxis + 70:xAxis;}).attr('cy',function(d){var position=undefined;if(d.pathId){position = d.pathType === 'teacher'?25:45;}else {position = 35;}return position;});group.append('foreignObject').attr('width',24).attr('height',24).attr('x',function(d,i){var xAxis=i * 30;return isLeft?xAxis + 69:xAxis - 1;}).attr('y',function(d){var position=undefined;if(d.pathId){position = d.pathType === 'teacher'?13:33;}else {position = 23;}return position;}).append('xhtml:div').attr('class',function(d){return d.collectionType === 'collection'?'collection-img':'assessment-img';});group.on('click',function(d){component.set('positionToCenter',true);component.sendAction('onSelectCard',d);});}, /**
   * @function handleView
   * Function to handle svg view
   */handleView:function handleView(position){var component=this;var svg=component.$('.' + position + '-activities')[0];var bbox=svg.getBBox();var width=bbox.width;var height=5;var xPosition=bbox.x;var yPosition=37;svg.setAttribute('viewBox',xPosition + ' ' + yPosition + ' ' + width + ' ' + height);svg.setAttribute('width',width + 'px');svg.setAttribute('height',yPosition + 'px');}, /**
   * @function scrollToCenter
   * Function to set scroll position
   */scrollToCenter:function scrollToCenter(){var component=this;var activeOffset=component.$('#active-resource').offset().left;var activitiesOneHalfWidthContainer=component.$('.student-activities').width() / 2;var activeResourceWidth=component.$('#active-resource').width();var scrollLeft=component.$('.student-activities').scrollLeft() + (activeOffset - (activitiesOneHalfWidthContainer + (activeResourceWidth + 50)));component.$('.student-activities').scrollLeft(scrollLeft);component.set('positionToCenter',false);}, /**
   * @function paginateNext
   * Function to call paginate when scroll reaches at the end
   */paginateNext:function paginateNext(){var component=this;component.$('.student-activities').scroll(function(){if(component.$('.student-activities').scrollLeft() === 0){if(!component.get('isLoading')){component.sendAction('onPaginateNext');component.set('isLoading',true);}}});}, /**
   * @function clearChart
   * Function to clear svg
   */clearChart:function clearChart(){var component=this;component.$('svg').empty();}});});