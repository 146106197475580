define('gooru-web/components/content/questions/answers/gru-encoding-assessment',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-encoding-assessment'], /*
   * Check is Audio Recorded or Stopped
   */isRecorded:true,recorder:null, /**
   * @property {Boolean} isPause
   */isPause:false, /*
   * Hold the audio details
   */audioRecorder:null,didInsertElement:function didInsertElement(){var ansLength=this.get('answers').length;if(ansLength === 0){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,type:'text',audio:'',audioUrl:'',isRecorded:false,isCorrect:true});this.get('answers').pushObject(newChoice);}}, // -------------------------------------------------------------------------
// Actions
actions:{ //Add new answer choice
addNewChoice:function addNewChoice(){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,type:'text',audio:'',audioUrl:'',isRecorded:false,isCorrect:true});this.get('answers').pushObject(newChoice);}, //Remove existing answer
removeChoice:function removeChoice(answer){this.get('answers').removeObject(answer);},encodingvoice:function encodingvoice(index){var _this=this;var component=this;if(!this.get('isRecorded')){this.get('answers')[index].set('audio',this.get('recorder').stop());component.$('.recorder-' + index).removeClass('recording').addClass('enable');this.set('isRecorded',true);this.get('answers')[index].set('isRecorded',true);}else {this.recordAudio().then(function(instance){instance.start();component.$('.recorder-' + index).removeClass('enable').addClass('recording');_this.set('recorder',instance);_this.set('isRecorded',false);});}},playaudio:function playaudio(index){var _this2=this;var component=this;if(this.get('answers')[index].hasOwnProperty('isRecorded') && this.get('answers')[index].isRecorded){this.get('answers')[index].audio.then(function(instance){var audio=component.get('audioRecorder');var url=instance.audioUrl;if(!audio || component.get('answerIndex') !== index || component.get('playerUrl') !== url){audio = new Audio(url);component.set('answerIndex',index);component.set('playerUrl',url);}component.set('audioRecorder',audio);audio.play();component.set('isPause',true);audio.ontimeupdate = function(){component.$('.play' + index).css('width',audio.currentTime / audio.duration * 100 + '%');};audio.addEventListener('ended',function(){component.set('isPause',false);});});}else {(function(){var audio=component.get('audioRecorder');var url=_this2.get('answers')[index].audioUrl;if(!audio || component.get('answerIndex') !== index || component.get('playerUrl') !== url){audio = new Audio(url);component.set('answerIndex',index);component.set('playerUrl',url);}component.set('audioRecorder',audio);audio.play();component.set('isPause',true);audio.ontimeupdate = function(){component.$('.play' + index).css('width',audio.currentTime / audio.duration * 100 + '%');audio.addEventListener('ended',function(){component.set('isPause',false);});};})();}}, //Action triggered when pause audio
pauseAudio:function pauseAudio(){var component=this;var audio=component.get('audioRecorder');audio.pause();component.set('isPause',false);}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
answers:null, /**
   * Multiple Choice max answers
   * */maxAnswers:10, /**
   * Is in edit mode
   */editMode:false, /**
   * @property {boolean}
   */disableEditorButtons:_ember['default'].computed.not('showAdvancedEditor'), /**
   * @type {Ember.A}
   */hasLimitAnswers:_ember['default'].computed('answers.[]',function(){return this.get('answers').length >= this.get('maxAnswers');}),recordAudio:function recordAudio(){if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){return navigator.mediaDevices.getUserMedia({audio:true}).then(function(stream){var mediaRecorder=new MediaRecorder(stream);var audioChunks=[];mediaRecorder.addEventListener('dataavailable',function(event){audioChunks.push(event.data);});var start=function start(){return mediaRecorder.start();};var state=mediaRecorder.state;var stop=function stop(){return new Promise(function(resolve){mediaRecorder.addEventListener('stop',function(){var audioBlob=new Blob(audioChunks,{type:'audio/mp3'});var audioUrl=URL.createObjectURL(audioBlob);var audio=new Audio(audioUrl);var play=function play(){return audio.play();};resolve({audioBlob:audioBlob,audioUrl:audioUrl,play:play});});mediaRecorder.stop();});};return {start:start,stop:stop,state:state};});}else {return null;}}});});