define('gooru-web/components/content/modals/ca-mastery-accrual-confirmation',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','ca-mastery-accrual-confirmation'],classNameBindings:['component-class'], // -------------------------------------------------------------------------
// Actions
actions:{confirm:function confirm(){var component=this;this.set('isLoading',true);var model=this.get('model');model.onConfirm().then(function(){if(model.callback){model.callback.success();}component.set('isLoading',false);component.triggerAction({action:'closeModal'});})['catch'](function(error){var message=component.get('i18n').t('ca.mastery-accrual.update.error').string;component.get('notifications').error(message);component.set('isLoading',false);_ember['default'].Logger.error(error);});},cancel:function cancel(){this.triggerAction({action:'closeModal'});}}, // -------------------------------------------------------------------------
// Properties
isLoading:false});});