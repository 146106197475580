define('gooru-web/serializers/goal/goal',['exports','ember','gooru-web/utils/utils','gooru-web/models/goal/goal'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebModelsGoalGoal){ /**
 * Serializer to support the Goal CRUD operations
 *
 * @typedef {Object} GoalSerializer
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Serialize a Goal/Goal object into a JSON representation required by the Create goal endpoint
   *
   * @param model - The goal model to be serialized
   * @returns {Object} JSON Object representation of the goal model
   */serializeCreateGoal:function serializeCreateGoal(model){return this.serializeGoal(model);}, /**
   * Serialize a Goal/Goal object into a JSON representation
   *
   * @param {Goal} model - The goal model to be serialized
   * @returns {Object} JSON Object representation of the unit model
   */serializeGoal:function serializeGoal(model){return {title:model.get('title'),description:model.get('description'),start_date:(0,_gooruWebUtilsUtils.momentToTimestamp)((0,_gooruWebUtilsUtils.toUtc)(model.get('startDate'))),end_date:(0,_gooruWebUtilsUtils.momentToTimestamp)((0,_gooruWebUtilsUtils.toUtc)(model.get('endDate'))),status:model.get('status'),reflection:model.get('reflection')};}, /**
   * Normalize an array of goals
   *
   * @param payload endpoint response format in JSON format
   * @returns {Goal[]}
   */normalizeGetGoals:function normalizeGetGoals(payload){var serializer=this;if(payload && _ember['default'].isArray(payload)){return payload.map(function(goal){return serializer.normalizeGoal(goal);});}else {return [];}}, /**
   * Normalize a goal
   * @param {*} data
   * @return {Goal}
     */normalizeGoal:function normalizeGoal(data){return _gooruWebModelsGoalGoal['default'].create(_ember['default'].getOwner(this).ownerInjection(),{id:data.id,title:data.title,description:data.description,status:data.status,startDate:(0,_gooruWebUtilsUtils.toLocal)(data.start_date),endDate:(0,_gooruWebUtilsUtils.toLocal)(data.end_date),reflection:data.reflection,order:data.sequence_id});}});});