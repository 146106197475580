define('gooru-web/controllers/student/class/student-learner-proficiency',['exports','ember','gooru-web/mixins/student-learner-proficiency','gooru-web/mixins/tenant-settings-mixin','gooru-web/mixins/language'],function(exports,_ember,_gooruWebMixinsStudentLearnerProficiency,_gooruWebMixinsTenantSettingsMixin,_gooruWebMixinsLanguage){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsStudentLearnerProficiency['default'],_gooruWebMixinsTenantSettingsMixin['default'],_gooruWebMixinsLanguage['default'],{ /**
     * Inject the  student class parent controller.
     */studentClassController:_ember['default'].inject.controller('student.class'), /**
     * @property {Array} fwCompetencies
     */fwCompetencies:_ember['default'].computed.alias('studentClassController.fwCompetencies'), /**
     * @property {Array} fwDomains
     */fwDomains:_ember['default'].computed.alias('studentClassController.fwDomains'), /**
     * A link to the parent class controller
     * @see controllers/class.js
     * @property {studentTimespentData}
     */studentTimespentData:_ember['default'].computed.alias('studentClassController.studentTimespentData'), /**
     * @property {Boolean}
     */isPublicClass:_ember['default'].computed.alias('class.isPublic'),getTenantFWs:_ember['default'].observer('activeSubject',function(){var tenantSetting=this.get('tenantSettingsObj');if(tenantSetting && tenantSetting.tx_fw_prefs && this.get('isPublicClass') && this.get('isEnableNavigatorPrograms')){var activeSubject=this.get('activeSubject');var isFound=tenantSetting.tx_fw_prefs[activeSubject.id];var tenantFW=isFound?isFound.default_fw_id:null;this.set('tenantFW',tenantFW);}}),classFramework:_ember['default'].computed.alias('studentClassController.classFramework'),isDefaultShowFW:_ember['default'].computed.alias('studentClassController.isDefaultShowFW'), // -------------------------------------------------------------------------
// Actions
actions:{closePullUp:function closePullUp(){var component=this;component.set('isOpenPlayer',false);},playContent:function playContent(queryParams,contentId,content){var component=this;component.set('playerUrl',component.target.get('router').generate('player',contentId,{queryParams:queryParams}));component.set('isOpenPlayer',true);component.set('playerContent',content);}},changeLanguage:function changeLanguage(){var controller=this;var classes=controller.get('class');controller.changeLanguages(classes);}});});