define('gooru-web/components/teacher/navigator-math-banner',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['navigator-math-banner'], // -------------------------------------------------------------------------
// Service
classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @type {I18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click join class
     */onJoinDemoClass:function onJoinDemoClass(){var component=this;component.joinCoTeacherIntoClass();}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function joinCoTeacherIntoClass
   * Method to join into a class as a co-teacher
   */joinCoTeacherIntoClass:function joinCoTeacherIntoClass(){var component=this;var configuration=component.get('configurationService.configuration');var classService=component.get('classService');var classCode=configuration.get('demoClass.code');var classId=configuration.get('demoClass.id');var joinActionPromise=classService.joinCoTeacherIntoClass(classCode);return joinActionPromise.then(function(){component.set('status',component.get('i18n').t('teacher-landing.navigator-banner.success-message'));component.set('isJoinedSuccessfully',true);component.sendAction('updateClass',classId);})['catch'](function(){component.set('status',component.get('i18n').t('teacher-landing.navigator-banner.error-message'));component.set('isJoinedSuccessfully',false);});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isJoinedSuccessfully
   */isJoinedSuccessfully:false, /**
   * @property {String} status
   */status:null});});