define('gooru-web/components/content/rubric/gru-scoring-levels',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','rubric','gru-scoring-levels'], //// -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Delete Level
     */deleteLevel:function deleteLevel(level){this.get('scoringLevels').removeObject(level);}, /**
     * Delete Level
     */addLevel:function addLevel(){this.get('scoringLevels').addObject(_ember['default'].Object.create({name:'',score:null}));}, /**
     *Triggered when scoring switch change
     */onScoringChange:function onScoringChange(isChecked){this.set('category.allowsScoring',isChecked);}, /**
     *Triggered when level switch change
     */onLevelChange:function onLevelChange(isChecked){this.set('category.allowsLevels',isChecked);if(!isChecked){this.set('category.allowsScoring',false);}}}, //// -------------------------------------------------------------------------
// Properties
/**
   *Category object to be edited
   *
   * @property {Category}
   */category:null, /**
   *Disabled Score Switch
   *
   * @property {Boolean}
   */disabledScoring:_ember['default'].computed.not('category.allowsLevels'), /**
   * @property {[]} scoringLevels
   * Should have 4 levels as default
   */scoringLevels:_ember['default'].A([{name:'',score:null},{name:'',score:null},{name:'',score:null},{name:'',score:null},{name:'',score:null}]), /**
   *Show the score scale
   *
   * @property {Boolean}
   */showScore:_ember['default'].computed.alias('category.allowsScoring'), /**
   *Show the level scale
   *
   * @property {Boolean}
   */showLevel:_ember['default'].computed.alias('category.allowsLevels'), /**
   * @property {Boolean} preview
   */preview:false, /**
   * @property {Boolean} showNoLevelsError
   */showNoLevelsError:false, /**
   * List of options to show in the switch
   *
   * @property {Array}
   */switchOptions:_ember['default'].A([_ember['default'].Object.create({label:'common.on',value:true}),_ember['default'].Object.create({label:'common.off',value:false})])});});