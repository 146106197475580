define('gooru-web/components/student/class/destination/student-destination-course-accordion',['exports','ember','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin){function _toConsumableArray(arr){if(Array.isArray(arr)){for(var i=0,arr2=Array(arr.length);i < arr.length;i++) arr2[i] = arr[i];return arr2;}else {return Array.from(arr);}}exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['student-destination-course-accordion'], // -------------------------------------------------------------------------
// Dependencies
/**
   * Session service to retrieve session content
   */session:_ember['default'].inject.service('session'), /**
   * Unit service
   */unitService:_ember['default'].inject.service('api-sdk/unit'), /**
   * Lesson service
   */lessonService:_ember['default'].inject.service('api-sdk/lesson'), /**
   * Rescope Service to perform rescope data operations
   */rescopeService:_ember['default'].inject.service('api-sdk/rescope'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;if(component.get('isRoute0')){component.getSkippedContents();}},didRender:function didRender(){var component=this;if(component.get('isRoute0')){component.processSkippedContents();}}, // -------------------------------------------------------------------------
// Observers
rescopeObserver:_ember['default'].observer('skippedContents',function(){var component=this;component.processSkippedContents();}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when select an unit
     */onSelectUnit:function onSelectUnit(selectedUnit){var component=this;var isUnitHasChildren=selectedUnit.get('children').length > 0;if(!isUnitHasChildren){component.loadUnitData(selectedUnit);}component.toggleAccordion('u',selectedUnit.id);}, /**
     * Action triggered when select a lesson
     */onSelectLesson:function onSelectLesson(selectedUnit,selectedLesson){var component=this;var isLessonHasChildren=selectedLesson.get('children').length > 0;if(!isLessonHasChildren){component.loadLessonData(selectedUnit,selectedLesson);}component.toggleAccordion('l',selectedLesson.id);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} units
   */units:_ember['default'].computed('courseData',function(){var component=this;var courseData=component.get('courseData');var units=courseData.get('children');units = [].concat(_toConsumableArray(courseData.unit0Content || []),_toConsumableArray(units));return units;}), /**
   * @property {Array} skippedContents
   */skippedContents:null, /**
   * @property {Boolean} isRoute0
   */isRoute0:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadUnitData
   * Method to load unit data
   */loadUnitData:function loadUnitData(unit){var component=this;var unitId=unit.get('id');var unitService=component.get('unitService');var courseId=component.get('courseId');return _ember['default'].RSVP.hash({unitData:_ember['default'].RSVP.resolve(unitService.fetchById(courseId,unitId))}).then(function(_ref){var unitData=_ref.unitData;unit.set('children',unitData.get('children'));});}, /**
   * @function loadLessonData
   * Method to load lesson data
   */loadLessonData:function loadLessonData(unit,lesson){var component=this;var lessonId=lesson.get('id');var courseId=component.get('courseId');var unitId=unit.get('id');var lessons=unit.get('children');var lessonService=component.get('lessonService');return _ember['default'].RSVP.hash({lessonData:_ember['default'].RSVP.resolve(lessonService.fetchById(courseId,unitId,lessonId))}).then(function(_ref2){var lessonData=_ref2.lessonData;var lessonSeq=lesson.get('sequence');lesson.set('children',lessonData.get('children'));lessons[lessonSeq - 1] = lesson;unit.set('children',lessons);});}, /**
   * @function toggleAccordion
   * Method to toggle accordion
   */toggleAccordion:function toggleAccordion(type,id){var component=this;if(component.$('#' + type + '-' + id).hasClass('collapsed')){component.$('.' + type + '-container').removeClass('expanded').addClass('collapsed');component.$('#' + type + '-' + id).addClass('expanded').removeClass('collapsed');}else {component.$('#' + type + '-' + id).toggleClass('expanded').addClass('collapsed');}}, /**
   * @function processSkippedContents
   * Method to hide/show skipped contents
   */processSkippedContents:function processSkippedContents(){var component=this;var skippedContents=component.get('skippedContents');var isSkippedContentAvailable=skippedContents?component.isSkippedContentsEmpty(skippedContents):false;if(isSkippedContentAvailable){component.toggleSkippedContents(skippedContents);}}, /**
   * @function getSkippedContents
   * Method to get skipped contents
   */getSkippedContents:function getSkippedContents(){var component=this;var filters={classId:component.get('classId'),courseId:component.get('courseId')};var skippedContentsPromise=_ember['default'].RSVP.resolve(component.get('rescopeService').getSkippedContents(filters));return _ember['default'].RSVP.hash({skippedContents:skippedContentsPromise}).then(function(hash){component.set('skippedContents',hash.skippedContents);return hash.skippedContents;})['catch'](function(){component.set('skippedContents',null);});}, /**
   * @function isSkippedContentsEmpty
   * Method to toggle rescoped content visibility
   */isSkippedContentsEmpty:function isSkippedContentsEmpty(skippedContents){var keys=Object.keys(skippedContents);var isContentAvailable=false;keys.some(function(key){isContentAvailable = skippedContents['' + key].length > 0;return isContentAvailable;});return isContentAvailable;}, /**
   * @function toggleSkippedContents
   * Method to toggle skippedContents
   */toggleSkippedContents:function toggleSkippedContents(skippedContents){var component=this;var contentTypes=Object.keys(skippedContents);var formattedContents=component.getFormattedContentsByType(skippedContents,contentTypes);component.toggleContentVisibility(formattedContents);}, /**
   * @function toggleContentVisibility
   * Method to toggle content visibility
   */toggleContentVisibility:function toggleContentVisibility(contentClassnames){var component=this;var isChecked=component.get('isChecked');var $contentComponent=_ember['default'].$(contentClassnames.join());if(isChecked){$contentComponent.show().addClass('rescoped-content');}else {$contentComponent.hide();}}, /**
   * @function getFormattedContentsByType
   * Method to get formatted content type
   */getFormattedContentsByType:function getFormattedContentsByType(contents,types){var component=this;var formattedContents=_ember['default'].A([]);types.map(function(type){var flag=type.charAt(0);formattedContents = formattedContents.concat(component.parseSkippedContents(contents['' + type],flag));});return formattedContents;}, /**
   * @function parseSkippedContents
   * Method to parse fetched rescoped contents
   */parseSkippedContents:function parseSkippedContents(contentIds,flag){var parsedContentIds=_ember['default'].A([]);contentIds.map(function(id){parsedContentIds.push('#' + flag + '-' + id);});return parsedContentIds;}});});