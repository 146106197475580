define('gooru-web/components/content/rubric/gru-fixed-footer',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','rubric','gru-fixed-footer'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Click button action
     */clickAction:function clickAction(action){action();}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {[]} actions List of action buttons to show in the footer
   */footerActions:[]});});