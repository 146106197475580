define('gooru-web/serializers/map/navigate-map',['exports','ember','gooru-web/models/map/map-context','gooru-web/models/map/content','gooru-web/models/map/map-suggestion','gooru-web/models/content/resource','gooru-web/mixins/configuration','gooru-web/serializers/taxonomy/taxonomy','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsMapMapContext,_gooruWebModelsMapContent,_gooruWebModelsMapMapSuggestion,_gooruWebModelsContentResource,_gooruWebMixinsConfiguration,_gooruWebSerializersTaxonomyTaxonomy,_gooruWebConfigConfig){ /**
 * Serializer to support the navigate map operations
 *
 * @typedef {Object} NavigateMapSerializer
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{ /**
   * @property {Service} session
   */session:_ember['default'].inject.service('session'), /**
   * @property {TaxonomySerializer} taxonomySerializer
   */taxonomySerializer:null,init:function init(){this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Serialize a MapContext object into a JSON representation
   *
   * @param {MapContext} model The model to be serialized
   * @returns {Object} JSON Object representation of the model
   */serializeMapContext:function serializeMapContext(model){var path_type=model.get('collectionSubType')?'system':null;var contentData={course_id:model.get('courseId'),class_id:model.get('classId'),unit_id:model.get('unitId'),lesson_id:model.get('lessonId'),collection_id:model.get('collectionId'),collection_type:model.get('collectionType'),collection_subtype:model.get('collectionSubType'),current_item_id:model.get('itemId'),current_item_type:model.get('itemType'),current_item_subtype:model.get('itemSubType'),state:model.get('status'),path_id:model.get('pathId') || 0,path_type:model.get('pathType') || path_type,ctx_path_id:model.get('ctxPathId') || 0,ctx_path_type:model.get('ctxPathType') || null,score_percent:model.get('score'),context_data:model.get('contextData'),milestone_id:model.get('milestoneId'),diagnostic:null,source:model.get('source') || null};if(model.get('diagnostic')){contentData.diagnostic = model.get('diagnostic');}if(model.get('item')){contentData.resources = model.get('item');}return contentData;}, /**
   * Normalize an array of goals
   *
   * @param payload endpoint response format in JSON format
   * @returns {Goal[]}
   */normalizeMapSuggestions:function normalizeMapSuggestions(){var payload=arguments.length <= 0 || arguments[0] === undefined?[]:arguments[0];var serializer=this;var suggestions=[];if(payload && _ember['default'].isArray(payload)){suggestions = payload.map(function(suggestion){return serializer.normalizeMapSuggestion(suggestion);});}return suggestions;}, /**
   * Normalize a map context
   * @param {*} data
   * @return {MapContext}
   */normalizeMapContext:function normalizeMapContext(data){return _gooruWebModelsMapMapContext['default'].create(_ember['default'].getOwner(this).ownerInjection(),{courseId:data.course_id,classId:data.class_id,unitId:data.unit_id,lessonId:data.lesson_id,collectionId:data.collection_id,collectionType:data.collection_type,collectionSubType:data.collection_subtype,itemId:data.current_item_id,itemType:data.current_item_type,itemSubType:data.current_item_subtype,status:data.state,pathId:data.path_id,pathType:data.path_type,ctxPathId:data.ctx_path_id,ctxPathType:data.ctx_path_type,score:data.score_percent,contextData:data.context_data,milestoneId:data.milestone_id,diagnostic:data.diagnostic || null,source:data.source || null});}, /**
   * Normalize a map content
   * @param {*} data
   * @return {MapContext}
   */normalizeMapContent:function normalizeMapContent(data){var basePath=this.get('session.cdnUrls.content');var appRootPath=this.get('appRootPath'); //configuration appRootPath
var thumbnailUrl=data.thumbnail?basePath + data.thumbnail:appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.ASSESSMENT;return _gooruWebModelsMapContent['default'].create(_ember['default'].getOwner(this).ownerInjection(),{id:data.id,title:data.title,description:data.learning_objective,url:data.url,thumbnail:thumbnailUrl});}, /**
   * Normalize a map suggestion
   * @param {*} data
   * @return {MapSuggestion}
   */normalizeMapSuggestion:function normalizeMapSuggestion(data){var serializer=this;var basePath=serializer.get('session.cdnUrls.content');var appRootPath=this.get('appRootPath'); //configuration appRootPath
var subType=data.format === _gooruWebConfigConfig.CONTENT_TYPES.COLLECTION || data.format === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_COLLECTION?_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.SIGNATURE_COLLECTION:_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.SIGNATURE_ASSESSMENT;var pathType=subType?'system':null;var thumbnail=data.thumbnail?basePath + data.thumbnail:appRootPath + (data.format === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT || data.format === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT?_gooruWebConfigConfig.DEFAULT_IMAGES.ASSESSMENT:_gooruWebConfigConfig.DEFAULT_IMAGES.COLLECTION);return _gooruWebModelsMapMapSuggestion['default'].create(_ember['default'].getOwner(this).ownerInjection(),{id:data.id,title:data.title,type:data.format,questionCount:data.questionCount,resourceCount:data.resourceCount,thumbnail:thumbnail,resourceFormat:_gooruWebModelsContentResource['default'].normalizeResourceFormat(data.subformat),subType:subType,pathType:pathType,standards:serializer.get('taxonomySerializer').normalizeTaxonomyObject(JSON.parse(data.taxonomy))});}});});