define('gooru-web/adapters/content/external-assessment',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Assessment CRUD operations in the API 3.0
 *
 * @typedef {Object} ExternalAssessmentAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),externalNamespace:'/api/nucleus/v1/assessments-external', /**
   * Reads an External Assessment by id
   *
   * @param {string} assessmentId
   * @returns {Promise}
   */readExternalAssessment:function readExternalAssessment(assessmentId){var adapter=this;var namespace=adapter.get('externalNamespace');var url=namespace + '/' + assessmentId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Update an Assessment
   *
   * @param assessmentId the id of the Assessment to be updated
   * @param data Assessment data to be sent in the request body
   * @returns {Promise}
   */updateExternalAssessment:function updateExternalAssessment(assessmentId,data){var adapter=this;var namespace=this.get('externalNamespace');var url=namespace + '/' + assessmentId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes an assessment by id
   *
   * @param assessmentId assessment id to be sent
   * @returns {Promise}
   */deleteExternalAssessment:function deleteExternalAssessment(assessmentId){var adapter=this;var namespace=this.get('externalNamespace');var url=namespace + '/' + assessmentId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Creates a new external collection
   *
   * @param data collection data to be sent in the request body
   * @returns {Promise}
   */createExternalAssessment:function createExternalAssessment(assessmentData){var adapter=this;var url=this.get('externalNamespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(assessmentData)};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});