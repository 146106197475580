define('gooru-web/adapters/library/library',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Library CRUD operations in the API 3.0
 *
 * @typedef {Object} LibraryAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v2/libraries',deeplinkingNamespace:'/api/nucleus-lti/v1/lti/v1p3', /**
   * Fetches libraries
   *
   * @returns {Promise}
   */fetchLibraries:function fetchLibraries(){var adapter=this;var url=adapter.get('namespace');var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Returns a library by id
   * @param {string} libraryId - library ID to search for
   * @returns {Promise}
   */getLibraryById:function getLibraryById(libraryId){var adapter=this;var url=adapter.get('namespace') + '/' + libraryId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}, /**
   * Fetches library contents
   * @param libraryId the library id
   * @param {string} contentType  course, collection, assessment, resource, question, rubric
   * @param resetPagination indicates if the pagination needs a reset
   * @param pagination - pagination values to list contents
   * @returns {Promise}
   */fetchLibraryContent:function fetchLibraryContent(libraryId,contentType){var pagination=arguments.length <= 2 || arguments[2] === undefined?{}:arguments[2];var adapter=this;var url=adapter.get('namespace') + '/' + libraryId + '/contents';var offset=pagination.offset;var pageSize=pagination.pageSize;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{offset:offset,limit:pageSize,contentType:contentType}};return _ember['default'].$.ajax(url,options);},postLtiData:function postLtiData(listData){var adapter=this;var namespace=adapter.get('deeplinkingNamespace');var url=namespace + '/deeplinking/urls';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',headers:adapter.defineHeaders(),data:JSON.stringify({deeplinkingUrl:listData})};return _ember['default'].$.ajax(url,options).then(function(result){var form=$(result);$('body').append(form);$('#ltiAuthForm').remove();});}});});