define('gooru-web/components/reports/assessment/questions/gru-counting-syllables',['exports','ember','quizzes-addon/mixins/reports/assessment/questions/question','gooru-web/config/question'],function(exports,_ember,_quizzesAddonMixinsReportsAssessmentQuestionsQuestion,_gooruWebConfigQuestion){function _toConsumableArray(arr){if(Array.isArray(arr)){for(var i=0,arr2=Array(arr.length);i < arr.length;i++) arr2[i] = arr[i];return arr2;}else {return Array.from(arr);}} /**
 * SERP Underline
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-counting-syllables'], // -------------------------------------------------------------------------
// Properties
didInsertElement:function didInsertElement(){var component=this;if(this.get('baseAnswers') && this.get('baseAnswers').length){this.get('baseAnswers').forEach(function(element,answerIndex){var elementItem=!component.get('showCorrect')?component.get('answerObj')[answerIndex]:element;var correctAnswer=!component.get('showCorrect')?JSON.parse(elementItem.text):element.correctAnswer;for(var index=0;index < correctAnswer.length;index++) {var contentTag=component.$('.counting-syllables-edit-' + answerIndex);component.defaultHighlighter(contentTag,element.text,answerIndex,correctAnswer);}});}},showCorrect:false,baseAnswers:_ember['default'].computed('question',function(){return this.get('question.answers');}), // ---------------------------------------------------------------
// Methods
defaultHighlighter:function defaultHighlighter(contentTag,text,answerIndex){var _this=this;var answer=arguments.length <= 3 || arguments[3] === undefined?null:arguments[3];var crossedClass=function crossedClass(pIndex){var crossLetter=answer.find(function(cAns){return parseInt(cAns.crossPosition,0) === pIndex;});var className='selected';if(crossLetter){if(crossLetter.isCross){className = 'crossed';}if(crossLetter.isShort){className = 'short';}return className;}};var isVowels=function isVowels(letter){return _gooruWebConfigQuestion.VOWELS_LETTERS.indexOf(letter) !== -1?'selected':'';};var splitText=[].concat(_toConsumableArray(text)).map(function(item,index){return item !== ' '?'<b class="' + (_this.get('showCorrect')?isVowels(item):'') + ' ' + (answer?crossedClass(index + 1):'') + '" data-b-index=' + (index + 1) + '>' + item + '</b>':item;}).join('');contentTag.html(splitText);}});});