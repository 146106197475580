define('gooru-web/components/gru-feedback-form',['exports','ember','gooru-web/config/config','gooru-web/utils/utils'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Service
/**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), /**
   * @property {activityFeedbackService}
   */activityFeedbackService:_ember['default'].inject.service('api-sdk/activity-feedback'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.fetchLearningActivityFeedback();}, /**
   * Observe the resource change
   */feedbackContentObserver:_ember['default'].observer('feedbackContent',function(){var component=this;component.fetchLearningActivityFeedback();}), // -------------------------------------------------------------------------
// Actions
actions:{updateFeedback:function updateFeedback(){if(!this.get('isStudyPlayer')){this.set('isFeedbackModified',true);}},onToggleCategoryDetails:function onToggleCategoryDetails(category,index){$('.feedback-container .feedback-body-panel .category-panel .category-info.category-' + index).slideToggle();category.set('isExpanded',!category.get('isExpanded'));},selectCategoryLevel:function selectCategoryLevel(category,ratingIndex){var score=(ratingIndex + 1) * Math.floor(100 / category.maxScale.length);category.set('rating',ratingIndex + 1);category.set('scoreInPrecentage',score);this.send('updateFeedback');}, /**
     * Used for saving the user feedback from feedback modal
     */saveUserFeedback:function saveUserFeedback(){var component=this;var learningFeedback=component.getFeedbackObject();component.get('activityFeedbackService').submitUserFeedback(learningFeedback).then(function(){component.closeFeedbackModal();});}, /*
     * Used for closing the feedback modal on clicking on Canel button
     */feedbackCancel:function feedbackCancel(){this.closeFeedbackModal();}}, // -------------------------------------------------------------------------
// Properties
/**
   * content id
   * @property {Number}
   */contentId:_ember['default'].computed.alias('feedbackContent.id'), /**
   * @property {Boolean}
   */isFeedbackModified:false, // -------------------------------------------------------------------------
// Methods
closeFeedbackModal:function closeFeedbackModal(){var component=this;var fn=component.get('feedbackCancel');fn && fn();},getFeedbackObject:function getFeedbackObject(){var component=this;var userId=component.get('session.userId');var role=component.get('session.role')?component.get('session.role'):_gooruWebConfigConfig.ROLES.STUDENT;var userCategoryId=_gooruWebConfigConfig.FEEDBACK_USER_CATEGORY['' + role];var userFeedback=_ember['default'].A([]);var categoryLists=component.get('categoryLists');var collection=component.get('feedbackContent');categoryLists.map(function(category){var feedbackObj={feeback_category_id:category.categoryId};if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE){feedbackObj.user_feedback_quantitative = category.rating;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.BOTH){feedbackObj.user_feedback_qualitative = category.comments;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUALITATIVE){feedbackObj.user_feedback_qualitative = category.quality;}userFeedback.pushObject(feedbackObj);});var userFeedbackObj={content_id:collection.get('id'),content_type:component.get('format'),user_category_id:userCategoryId,user_feedbacks:userFeedback,user_id:userId};return userFeedbackObj;}, /**
   * @function fetchLearningActivityFeedback
   * Method to fetch learning activity feedback
   */fetchLearningActivityFeedback:function fetchLearningActivityFeedback(){var component=this;var userId=component.get('session.userId');var contentId=component.get('contentId');var listOfCategory=(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(component.get('feedbackCategoryLists'));_ember['default'].RSVP.hash({categoryLists:listOfCategory,activityFeedback:component.get('activityFeedbackService').fetchActivityFeedback(contentId,userId)}).then(function(hash){if(hash.activityFeedback.length){hash.activityFeedback.map(function(feedback){var category=hash.categoryLists.findBy('categoryId',feedback.categoryId);if(category){if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE){category.set('rating',feedback.rating);}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUALITATIVE){category.set('quality',feedback.qualitative);}else {category.set('comments',feedback.qualitative);}}});}hash.categoryLists.map(function(category){var score=category.rating * Math.floor(100 / category.maxScale);category.set('scoreInPrecentage',score);category.set('isExpanded',true);});component.set('categoryLists',hash.categoryLists);});}});});