define('gooru-web/components/content/modals/gru-base-remix',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-base-remix'],classNameBindings:['component-class'], // -------------------------------------------------------------------------
// Actions
actions:{remix:function remix(){var component=this;var contentModel=this.get('contentModel');contentModel.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){component.beforeCopy();component.get('copyContent').call(component,contentModel).then(function(contentId){contentModel.set('id',contentId);return component.get('updateContent').call(component,contentModel).then(function(){component.afterCopy(contentModel);},component.get('showFailureNotification').bind(component));});}component.set('didValidate',true);});}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('contentModel',this.get('model.content').copy());this.set('onRemix',this.get('model.onRemixSuccess'));}, // -------------------------------------------------------------------------
// Methods
beforeCopy:function beforeCopy(){_ember['default'].Logger.warn('This function can be overwrite');},afterCopy:function afterCopy(contentModel){var component=this;component.closeModal();component.notifyCopy(contentModel);},closeModal:function closeModal(){var component=this;component.triggerAction({action:component.get('onCloseModal')});},notifyCopy:function notifyCopy(contentModel){var component=this;component.get('notifications').setOptions({positionClass:'toast-top-full-width',toastClass:'gooru-toast',timeOut:10000});if(component.get('onRemix')){component.get('onRemix')(contentModel);}component.get('showSuccessNotification').call(component,contentModel);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {?String} specific class
   */'component-class':null, /**
   * @type {Course/Collection/Assessment} contentModel
   */contentModel:null, /**
   * Function to call on remix success
   */onRemix:null, /**
   * Class handling the actions from the component.
   * This value will be set on instantiation by gru-modal.
   *
   * @type {Ember.Component}
   * @private
   */target:null,onCloseModal:'closeModal'});});