define('gooru-web/controllers/content/courses/edit',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
queryParams:['unitId','lessonId'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Sets the current course builder location
     * @param unitId
     * @param lessonId
     */setLocation:function setLocation(unitId){var lessonId=arguments.length <= 1 || arguments[1] === undefined?undefined:arguments[1];this.set('unitId',unitId || null);this.set('lessonId',lessonId || null);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property  {string}
   */unitId:null, /**
   * @property {string}
   */lessonId:null, /**
   * @property {string}
   */classId:null, /**
   * @property {string}
   */userId:null, // -------------------------------------------------------------------------
// Methods
resetValues:function resetValues(){this.set('unitId',null);this.set('lessonId',null);}});});