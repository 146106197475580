define('gooru-web/components/player/resources/gru-vimeo-resource',['exports','ember','gooru-web/config/environment'],function(exports,_ember,_gooruWebConfigEnvironment){ /**
 * Vimeo resource component
 *
 * Component responsible for controlling the logic and appearance of the vimeo resource type
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-viewer.js
 * @augments Ember/Component
 **/exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-vimeo-resource'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {Resource} the resource
   */resource:null, /**
   * @property {string} full resource vimeo url
   */vimeoUrl:_ember['default'].computed('resource.url',function(){var component=this;var vimeoPlayerUrl=_gooruWebConfigEnvironment['default'].player.vimeoPlayerUrl;return vimeoPlayerUrl + component.getVimeoID(this.get('resource.url'));}), // -------------------------------------------------------------------------
// Methods
/**
   * Get Video ID from a URL
   * @param {string} text
   * @returns {{id: number}} id
   */getVimeoID:function getVimeoID(text){var regex=/([^/.]+)$/gm;var match=regex.exec(text);var id='';if(match !== null){id = match[0];}return id;}});});