define('gooru-web/components/student/class/analytics/gru-performance-table',['exports','ember','gooru-web/utils/utils','gooru-web/config/config','gooru-web/utils/performance-summary'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigConfig,_gooruWebUtilsPerformanceSummary){ /**
 * Student Performance Table
 *
 * Component responsible for showing the Performance Table in the student page.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-performance-table'], /**
   * Query param, filterBy selected
   * @property {String}
   */reportIcon:'', // -------------------------------------------------------------------------
// Actions
actions:{ /**
       * Sort metric by criteria
       */sort:function sort(metric){this.sortByMetrics(metric);var metricsIndex=metric.get('index');var sortCriteria=this.get('sortCriteria');var newSortCriteria={metricsIndex:metricsIndex};this.set('sortByMetric',metric.get('value'));if(sortCriteria.metricsIndex === metricsIndex){ // Reverse the sort order if the same column has been selected
newSortCriteria.order = sortCriteria.order * -1;this.set('sortCriteria',newSortCriteria);}else {newSortCriteria.order = this.get('defaultSortOrder');this.set('sortCriteria',newSortCriteria);}}, /**
       * View analytics report
       */viewReport:function viewReport(assessmentId){this.sendAction('onViewReport',assessmentId);}}, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this._super.apply(this,arguments);this.set('reportIcon',_gooruWebConfigConfig.DEFAULT_IMAGES.REPORTICON);_ember['default'].run.scheduleOnce('afterRender',this,function(){this.set('sortCriteria',this.initSortCriteria());this.resetSortByMetrics();});}, // -------------------------------------------------------------------------
// Properties
/**
   * @prop { Object } sortCriteria - Object with information on how the data should be sorted
   * - metricsIndex: {number} - Index of metrics option
   * - order: {number} - Ascending or descending order
   */sortCriteria:null, /**
   * @property {CollectionPerformanceSummary}
   */aggregatedPerformanceSummary:_ember['default'].computed('collectionPerformanceSummaryItems.[]',function(){return (0,_gooruWebUtilsPerformanceSummary.aggregateCollectionPerformanceSummaryItems)(this.get('collectionPerformanceSummaryItems') || _ember['default'].A([]));}), /**
   * Number of assessments completed
   * @property {Number}
   */assessmentsCompleted:_ember['default'].computed('assessments.length','collectionPerformanceSummaryItems.length',function(){var performanceData=this.createDataArray(this.get('assessments'),this.get('collectionPerformanceSummaryItems'));return performanceData.filterBy('performanceData.status','complete').length;}), /**
   * The assessment performanceData
   * @property {performanceData[]}
   */performanceData:_ember['default'].computed('assessments.length','collectionPerformanceSummaryItems.length','sortCriteria',function(){var _this=this;var performanceData=this.createDataArray(this.get('assessments'),this.get('collectionPerformanceSummaryItems'));var sortCriteria=this.get('sortCriteria');if(sortCriteria){var metricsIndex=sortCriteria.metricsIndex;var sortedData=performanceData;if(metricsIndex >= 0){(function(){var sortByMetric=_this.get('sortByMetric');sortedData.sort(function(a,b){if(a.performanceData && b.performanceData){if(sortByMetric === 'score'){return (0,_gooruWebUtilsUtils.numberSort)(a.performanceData.score,b.performanceData.score) * sortCriteria.order;}else if(sortByMetric === 'completion'){return (0,_gooruWebUtilsUtils.numberSort)(a.performanceData.completionDone,b.performanceData.completionDone) * sortCriteria.order;}else if(sortByMetric === 'study-time'){return (0,_gooruWebUtilsUtils.numberSort)(a.performanceData.timeSpent,b.performanceData.timeSpent) * sortCriteria.order;}else {return (0,_gooruWebUtilsUtils.alphabeticalStringSort)(a.assessment.title,b.assessment.title) * sortCriteria.order;}}});})();}return sortedData.filterBy('performanceData');}else {return performanceData.filterBy('performanceData');}}), /**
   * Default list of  metrics to be displayed by the component
   * @sorted {Boolean}
   * @isAsc {Boolean}
   * @visible {Boolean}
   * @constant {Array}
   */metrics:_ember['default'].A([_ember['default'].Object.create({value:'assessment',sorted:false,isAsc:false,hasSorting:true,visible:true,index:4}),_ember['default'].Object.create({value:'score',sorted:false,isAsc:false,hasSorting:true,visible:false,index:0}),_ember['default'].Object.create({value:'completion',sorted:false,isAsc:false,hasSorting:true,visible:false,index:1}),_ember['default'].Object.create({value:'study-time',sorted:false,isAsc:false,hasSorting:true,visible:false,index:2})]), /**
   * Default sort order for values in columns (1 = ascending; -1 = descending)
   *  * @property {number} defaultSortOrder
   */defaultSortOrder:1, /**
   * Indicate if show report column
   */showReportColumn:false, /**
   * metric sent by the sort function
   */sortByMetric:null, /**
   * Content title, it could be course, unit or lesson title
   * @property {string}
   */contentTitle:null, // -------------------------------------------------------------------------
// Methods
/**
   * Initialize the table's sort criteria
   * @return {Object}
   */initSortCriteria:function initSortCriteria(){return {metricsIndex:-1,order:this.get('defaultSortOrder')};}, /**
   * Create an array to fill the student performance table.
   * @param assessments the table assessments by sort criteria
   * @param {CollectionPerformanceSummary[]} collectionPerformanceSummaryItems the student performance data for each assessments
   */createDataArray:function createDataArray(assessments,collectionPerformanceSummaryItems){var dataArray=_ember['default'].A([]);assessments.forEach(function(assessment){var collectionPerformanceSummaryItem=collectionPerformanceSummaryItems.findBy('id',assessment.get('id'));var itemDataArray=_ember['default'].Object.create({performanceData:collectionPerformanceSummaryItem,assessment:assessment});dataArray.push(itemDataArray);});return dataArray;}, /**
   * Sort by specific metric
   * @metric {Ember Object}
   *
   */sortByMetrics:function sortByMetrics(metric){var component=this;var metrics=component.get('metrics');metrics.forEach(function(option){if(option.get('value') === metric.get('value')){metric.set('sorted',true);component.changeTypeSort(metric);}else {option.set('isAsc',null);option.set('sorted',false);}});}, /**
   * Change the type of sort
   * @metric {Ember Object}
   *
   */changeTypeSort:function changeTypeSort(metric){metric.set('isAsc',!metric.get('isAsc'));},resetSortByMetrics:function resetSortByMetrics(){var component=this;var metrics=component.get('metrics');metrics.forEach(function(option){option.set('isAsc',null);option.set('sorted',false);});}});});