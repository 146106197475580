define('gooru-web/components/reports/class-assessment/gru-question-information',['exports','ember'],function(exports,_ember){ /**
 * Questions information component
 *
 * Component responsible for display the question performance information
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','class-assessment','gru-question-information'], // -------------------------------------------------------------------------
// Properties
/**
   * @prop { Question} question - Question to display
   */question:null, /**
   * @prop { String[]} hints - Question hints
   */hints:_ember['default'].computed('question.hints',function(){return this.get('question.hints');}), /**
   * @prop { String[]} explanation - Question explanation
   */explanation:_ember['default'].computed('question.explanation',function(){return this.get('question.explanation');}), /**
   * Indicates if the report is displayed in anonymous mode
   * @property {boolean} anonymous
   */anonymous:null});});