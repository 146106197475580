define('gooru-web/components/search/gru-resource-options',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-resource-options'],tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * selectOption selects menu option
     */selectOption:function selectOption(option){this.sendAction('onSelectedOption',option);}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Types of question selected
   *  @property {array} selectedOptionTypes
   *
   */selectedOptionTypes:_ember['default'].A([]), /**
   * True if video option is selected
   *  @property {boolean} videoSelected
   *
   */videoSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('video');}), /**
   * True if web-page option is selected
   *  @property {boolean} webPageSelected
   *
   */webPageSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('webpage');}), /**
   * True if interactive option is selected
   *  @property {boolean} interactiveSelected
   *
   */interactiveSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('interactive');}), /**
   * True if image option is selected
   *  @property {boolean} fibSelected
   *
   */imageSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('image');}), /**
   * True if text option is selected
   *  @property {boolean} textSelected
   *
   */textSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('text');}), /**
   * True if audio option is selected
   *  @property {boolean} audioSelected
   *
   */audioSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('audio');}), /**
   * True if interactiveVideo option is selected
   *  @property {boolean} interactiveVideoSelected
   *
   */interactiveVideoSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('h5p_interactive_video');}), /**
   * True if interactiveSlide option is selected
   *  @property {boolean} interactiveSlideSelected
   *
   */interactiveSlideSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('h5p_interactive_slide');}), /**
   * True if interactivePersonalityQuiz option is selected
   *  @property {boolean} interactivePersonalityQuizSelected
   *
   */interactivePersonalityQuizSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('h5p_interactive_personality_quiz');}), /**
   * True if dragAndDropResource option is selected
   *  @property {boolean} dragAndDropResourceSelected
   *
   */dragAndDropResourceSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes('h5p_drag_and_drop_resource');}),visibilityContentTypes:_ember['default'].computed.alias('configuration.visibility_content_types'),resourceTypes:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());var visibilityContentTypes=tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.visibility_content_types?tenantSettings.ui_element_visibility_settings.visibility_content_types:this.get('visibilityContentTypes');return visibilityContentTypes.other_resources;}),interactiveResourceTypes:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());var visibilityContentTypes=tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.visibility_content_types?tenantSettings.ui_element_visibility_settings.visibility_content_types:this.get('visibilityContentTypes');return visibilityContentTypes.interactive_resources;}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});