define('gooru-web/components/reports/pull-up/dca-student-external-collection-report',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/models/result/context'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebModelsResultContext){exports['default'] = _ember['default'].Component.extend({classNames:['reports','backdrop-pull-ups','dca-student-external-collection-report'], /**
   * @requires {Ember.Service} session management
   */session:_ember['default'].inject.service('session'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires {CollectionService} Service to retrieve an collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @requires service:api-sdk/search
   */searchService:_ember['default'].inject.service('api-sdk/search'), /**
   * @requires service:api-sdk/course-map
   */courseMapService:_ember['default'].inject.service('api-sdk/course-map'), // -------------------------------------------------------------------------
// Properties
/**
   * Indicates the status of the spinner
   * @property {Boolean}
   */isLoading:false,showPullUp:false, /**
   * defaultSuggestContentType
   * @type {String}
   */defaultSuggestContentType:'assessment', /**
   * @property {externalCollection} List
   * @type {Number}
   */externalCollection:_ember['default'].computed('reportData',function(){var component=this;var reportData=component.get('reportData');return reportData.collection;}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('externalCollectionContent.standards.[]',function(){var component=this;var standards=component.get('externalCollectionContent.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), /**
   * @property {Number} performanceScore
   */timeSpent:_ember['default'].computed('reportData',function(){var component=this;var reportData=component.get('reportData');return reportData.studentPerformance?reportData.studentPerformance.timeSpent:reportData.collection.get('performance.timeSpent');}), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){this.handleAppContainerScroll();},didDestroyElement:function didDestroyElement(){this.handleAppContainerScroll();}, /**
   * Function to triggered once when the component element is first rendered.
   */didInsertElement:function didInsertElement(){this.openPullUp();this.loadExternalCollectionReportData();},actions:{onPullUpClose:function onPullUpClose(closeAll){this.closePullUp(closeAll);},onCloseSuggest:function onCloseSuggest(){ // on close suggest callback
return true;}, /**
     * Trigger when suggestion button got clicked
     */onOpenSuggestionPullup:function onOpenSuggestionPullup(){var component=this;var studentsSelectedForSuggest=_ember['default'].A([]);var context=component.getContext(component.get('reportData'));var suggestContextParams=_ember['default'].Object.create({classId:context.get('classId'),courseId:context.get('courseId'),unitId:context.get('unitId'),lessonId:context.get('lessonId'),collectionId:context.get('collectionId')});studentsSelectedForSuggest.pushObject(component.get('profile'));component.set('suggestContextParams',suggestContextParams);component.set('studentsSelectedForSuggest',studentsSelectedForSuggest);component.set('showSuggestionPullup',true);},onClosePullUp:function onClosePullUp(){var component=this;component.set('showSuggestionPullup',false);component.closePullUp(true);}}, //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(closeAll){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);if(closeAll){component.sendAction('onClosePullUp',closeAll);}});},handleAppContainerScroll:function handleAppContainerScroll(){var activePullUpCount=_ember['default'].$(document.body).find('.backdrop-pull-ups').length;if(activePullUpCount > 0){_ember['default'].$(document.body).addClass('no-vertical-scroll');}else if(activePullUpCount === 0){_ember['default'].$(document.body).removeClass('no-vertical-scroll');}},loadExternalCollectionReportData:function loadExternalCollectionReportData(){var component=this;var context=component.get('reportData');var profilePromise=new _ember['default'].RSVP.resolve(component.get('profileService').readUserProfile(context.userId));var collectionContentPromise=new _ember['default'].RSVP.resolve(component.get('collectionService').readExternalCollection(context.collectionId));return _ember['default'].RSVP.hash({profile:profilePromise,externalCollection:collectionContentPromise}).then(function(hash){component.set('profile',hash.profile);component.set('externalCollectionContent',hash.externalCollection);component.loadTeacherSuggestions();});},loadTeacherSuggestions:function loadTeacherSuggestions(){var component=this;var context=component.getContext(component.get('reportData'));if(!component.get('isStudent')){component.get('courseMapService').getLessonInfo(context.get('classId'),context.get('courseId'),context.get('unitId'),context.get('lessonId'),true,context.get('userId')).then(function(lesson){var collections=lesson.get('children');var collection=collections.findBy('id',context.get('collectionId'));if(!collection.get('isSuggestedContent')){component.set('showSuggestion',true);}});}}, /**
   * Get the player context
   * @param params
   * @returns {Context}
   */getContext:function getContext(params){var userId=params.userId;var collectionId=params.collectionId;var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;return _gooruWebModelsResultContext['default'].create({collectionType:params.type,userId:userId,collectionId:collectionId,courseId:courseId,classId:params.classId,unitId:unitId,lessonId:lessonId});}});});