define('gooru-web/components/content/questions/answers/gru-classic',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // ----------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-classic'], // ----------------------------------------------------------------------
// Properties
/**
   * Answers List
   */answers:null, /**
   * Is in edit mode
   */editMode:false, // -----------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var answers=this.get('answers');if(!answers.length){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:'',isCorrect:true,textImage:null,additionalLetters:[],correctAnswer:[],type:'text'});this.get('answers').pushObject(newChoice);}if(this.get('editMode')){this.initialize();}this.defaultHightlight();}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Add new answer
     */onAddExcercise:function onAddExcercise(){var newAnswerItem=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:'',isCorrect:true,correctAnswer:[],textImage:null,additionalLetters:[],type:'text'});this.get('answers').pushObject(newAnswerItem);}, /**
     * Remove answer object from answers
     */onDeleteAnswer:function onDeleteAnswer(answer){this.get('answers').removeObject(answer);},addExtraLetters:function addExtraLetters(answer){answer.get('additionalLetters').pushObject({text:null});},removeLetter:function removeLetter(answer,letter){answer.get('additionalLetters').removeObject(letter);}}, // -------------------------------------------------------------------
// Methods
/**
   * Help to wrap span tag for each letters
   */wrapLetters:function wrapLetters(text){var letterGroups='';var childEl=$('<p>' + text + '</p>')[0].childNodes;childEl.forEach(function(item){if(item.nodeName === '#text'){for(var i=0;i < item.data.length;i++) {letterGroups += '<span>' + item.data[i] + '</span>';}return;}letterGroups += item.outerHTML;});return letterGroups;}, /**
   * Help to initialize the answer text
   */initialize:function initialize(){var component=this;component.$('.classic-answer-panel').on('keyup','.answer-input-section input',function(){var wrappedLetters=component.wrapLetters(this.value);var parentEl=$(this).closest('.answer-section');var answerIndex=parentEl.data('answer-index');component.parseAnswerText(answerIndex,this.value);parentEl.find('.answer-text').html(wrappedLetters);});component.$('.classic-answer-panel').on('click','.answer-actions .answer-text span',function(){$(this).toggleClass('active');var parentEl=$(this).closest('.answer-text');component.parseCorrectAnswer(parentEl);});}, /**
   * Method help to parse answer text
   */parseAnswerText:function parseAnswerText(answerIndex,text){var answers=this.get('answers');var answerObj=answers.get(answerIndex);answerObj.set('textImage',null);answerObj.set('text',text);}, /**
   * Method help to parse answer text
   */parseCorrectAnswer:function parseCorrectAnswer(activeEl){var answers=this.get('answers');var answerIndex=activeEl.data('answer-index');var answerObj=answers.get(answerIndex);var correctAnswer=[];var clonedEl=activeEl.clone();var activeSpan=clonedEl.find('span').filter('.active');if(activeSpan.length){activeSpan.each(function(index,item){$(item).text('[' + $(item).text() + ']');});var textContent=clonedEl.text();correctAnswer.push(textContent);}answerObj.set('correctAnswer',correctAnswer);var isErrorMsg=correctAnswer.length === 0;answerObj.set('isShowErrorMsg',isErrorMsg);}, /**
   * Help to highlight existing answer
   */defaultHightlight:function defaultHightlight(){var component=this;var answers=component.get('answers');answers.forEach(function(item,index){var answerEl=component.$('.answer-edit-' + index);var answerText=item.correctAnswer.length?item.correctAnswer[0]:item.text;var replacedText=answerText.replace(/(\[[^[\]]+])/gi,function(match){return '<span class="active">' + match[1] + '</span>';});answerEl.html(component.wrapLetters(replacedText));});}});});