define('gooru-web/models/taxonomy/taxonomy-root',['exports','ember','gooru-web/config/config','gooru-web/utils/taxonomy'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsTaxonomy){ /**
 * Taxonomy Root
 *
 * @typedef {Object} TaxonomyRoot
 */var TaxonomyRoot=_ember['default'].Object.extend({ /**
   * @property {string} id - Item ID
   */id:null, /**
   * @property {string} frameworkId - Standard Framework ID
   */frameworkId:null, /**
   * @property {string} title - Text label for this item
   */title:'', /**
   * @property {string} subjectTitle - Text label for the subject item
   */subjectTitle:'', /**
   * @property {string} code - Code for this item
   */code:'', /**
   * @property {TaxonomyItem[]} courses - List of courses
   */courses:[], /**
   * @property {TaxonomyRoot[]} children - List of frameworks
   */frameworks:[], /**
   * Category
   * @property {string}
   */category:_ember['default'].computed('id',function(){var id=this.get('id');var subjectId=id.split('-')[0];return (0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectId);}), /**
   * @property {boolean}
   */hasCourses:_ember['default'].computed.bool('courses.length'), /**
   * @property {boolean}
   */hasFrameworks:_ember['default'].computed.bool('frameworks.length'), /**
   * Indicates if the subject has standards
   * A subject is considered to have standards if it has a framework that is
   * the Gooru Default Framework (GDF)
   * @property {boolean}
   */hasStandards:_ember['default'].computed('frameworks',function(){var otherThanGDF=this.get('frameworkId') !== _gooruWebConfigConfig.GOORU_DEFAULT_STANDARD;var frameworksWithStandards=this.get('frameworksWithStandards');return otherThanGDF && frameworksWithStandards.length;}), /**
   * Returns all the frameworks having standards
   * @propery {TaxonomyRoot[]}
   */frameworksWithStandards:_ember['default'].computed('frameworks',function(){var frameworks=this.get('frameworks');return frameworks.filter(function(framework){return framework.get('frameworkId') !== _gooruWebConfigConfig.GOORU_DEFAULT_STANDARD; //Gooru default framework
});})});exports['default'] = TaxonomyRoot;});