define('gooru-web/models/content/bookmark',['exports','ember'],function(exports,_ember){ /**
 * Bookmark model
 *
 * @typedef {Object} Bookmark
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {String} id - The bookmark id
   */id:null, /**
   * @property {String} title
   */title:null, /**
   * @property {String} content id
   */contentId:null, /**
   * @property {String} content type
   */contentType:null});});