define('gooru-web/components/player/resources/gru-url-resource',['exports','ember'],function(exports,_ember){ /**
 * Url resource component
 *
 * Component responsible for controlling the logic and appearance of the url resource type
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-viewer.js
 * @augments Ember/Component
 **/exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-url-resource'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {Resource} the resource
   */resource:null, /**
   * @property {Number} the calculated resource content height
   */calculatedResourceContentHeight:null, /**
   * @property {string} bind the height css style for the component
   */resourceHeight:_ember['default'].computed('calculatedResourceContentHeight',function(){var height=this.get('calculatedResourceContentHeight');var heightString=height > 0?height + 'px':'100%';return _ember['default'].String.htmlSafe('height: ' + heightString);})});});