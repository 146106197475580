define('gooru-web/components/content/questions/answers/gru-words-per-minute',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-words-per-minute'],didInsertElement:function didInsertElement(){this.get('answers').forEach(function(answer){answer.set('isReadingType',true);});var ansLength=this.get('answers').length;if(ansLength === 0){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,type:'text',isCorrect:true,isReadingType:true});this.get('answers').pushObject(newChoice);}}, // -------------------------------------------------------------------------
// Actions
actions:{ //Add new answer choice
addNewChoice:function addNewChoice(){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,type:'text',isCorrect:true});this.get('answers').pushObject(newChoice);}, //Remove existing answer
removeChoice:function removeChoice(answer){this.get('answers').removeObject(answer);}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
answers:null, /**
   * Multiple Choice max answers
   * */maxAnswers:10, /**
   * Is in edit mode
   */editMode:false, /**
   * @property {boolean}
   */disableEditorButtons:_ember['default'].computed.not('showAdvancedEditor'), /**
   * @type {Ember.A}
   */hasLimitAnswers:_ember['default'].computed('answers.[]',function(){return this.get('answers').length >= this.get('maxAnswers');})});});