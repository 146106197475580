define('gooru-web/mixins/visibility-settings',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Mixin.create({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {performanceService} Service to retrieve milestone performance information
   */performanceService:_ember['default'].inject.service('api-sdk/performance'),tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Methods
/**
   * Get visibility settings
   */getVisibilitySettings:function getVisibilitySettings(classId){return this.get('performanceService').getVisibilitySettings(classId).then(function(data){return data;});}, /**
   * Get active tenant setting
   */getActiveTenantSetting:function getActiveTenantSetting(){return this.get('tenantService').getActiveTenantSetting().then(function(data){return data.enable_learners_data_visibilty_pref === 'on';});}, /**
   * Get tenant setting
   */getTenantSetting:function getTenantSetting(){return this.get('tenantService').getActiveTenantSetting().then(function(data){return data;});}});});