define('gooru-web/components/content/gru-settings-edit',['exports','ember','gooru-web/config/config','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(),tourService:_ember['default'].inject.service('tours'), /**
   * @property {Ember.Service} Service to do retrieve language
   */lookupService:_ember['default'].inject.service('api-sdk/lookup'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','gru-settings-edit'],tagName:'section',actions:{onCatalogUpdate:function onCatalogUpdate(catalog){this.set('model.product_id',catalog.id?parseInt(catalog.id):null);this.sendAction('action');},onBackwardsChange:function onBackwardsChange(isChecked){if(isChecked){this.set('model.showFeedback',_gooruWebConfigConfig.ASSESSMENT_SHOW_VALUES.SUMMARY);}this.sendAction('action');},onAnswerKeyChange:function onAnswerKeyChange(isChecked){if(isChecked){this.set('model.attempts',1);}this.sendAction('action');},onAttemptsChange:function onAttemptsChange(newValue){this.set('model.attempts',+newValue);this.sendAction('action');},onGenericChange:function onGenericChange(){this.sendAction('action');},onClassroomPlayEnabledChange:function onClassroomPlayEnabledChange(){this.sendAction('action');},updateLanguage:function updateLanguage(language){this.set('model.primaryLanguage',language.id);this.set('selectedLanguage',language);this.sendAction('action');}}, /**
   * Options for attempts
   * @property {Array}
   */attemptsOptions:_ember['default'].computed(function(){var options=[{id:-1,name:this.get('i18n').t('gru-settings-edit.attempts-unlimited')}];for(var i=1;i <= _gooruWebConfigConfig.MAX_ATTEMPTS;i += 1) {options.push({id:i,name:i});}return options;}), /**
   * Options for feedback
   * @property {Map}
   */feedbackItems:_gooruWebConfigConfig.ASSESSMENT_SHOW_VALUES, /**
   * Request pending approval
   * @property {Boolean}
   */isRequestApproved:false, /**
   * Model to change settings to
   * @property {Object}
   */model:null, /**
   * Has the request to make the item searchable been sent?
   * @property {Boolean}
   */wasRequestSent:false, /**
   * Toggle Options
   * @property {Ember.Array}
   */switchOptions:_ember['default'].A([_ember['default'].Object.create({label:'common.yes',value:true}),_ember['default'].Object.create({label:'common.no',value:false})]), // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component.fetchLanguage();var isDefaultQuestionGrade=component.get('model') && component.get('model.metadata') && component.get('model.metadata.is_default_gradable_eval_result_correct')?component.get('model.metadata.is_default_gradable_eval_result_correct'):undefined;if(isDefaultQuestionGrade === undefined && !component.get('model.metadata')){var metadata={is_default_gradable_eval_result_correct:component.get('isDefaultGrade')};component.get('model').set('metadata',metadata);}component._super.apply(component,arguments);component.set('assessmentSettingSteps',component.get('tourService').getAssessmentSettingsTourSteps());},fetchLanguage:function fetchLanguage(){var controller=this;controller.get('lookupService').getLanguages().then(function(languages){var primaryLanguageId=controller.get('primaryLanguage')?parseInt(controller.get('primaryLanguage')):controller.get('model.primaryLanguage');var languageLists=languages.filter(function(language){return language.id === primaryLanguageId;});controller.set('selectedLanguage',languageLists && languageLists.length?languageLists[0]:null);controller.set('languageList',languages);});}});});