define('gooru-web/components/reports/pull-up/accordion/collection-accordion',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['collection-accordion'],classNameBindings:['isExpanded:expanded:collapsed'], //------------------------------------------------------------------------
//Dependencies
i18n:_ember['default'].inject.service(), /**
   * @requires service:performance
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), //------------------------------------------------------------------------
//Actions
actions:{onClickCollectionTitle:function onClickCollectionTitle(collection){var component=this;var collectionId=collection.id;var collectionType=collection.collectionType;var sessionId=collection.sessionId;var collectionBody=component.$('.collection-content .collection-body');if(component.get('isExpanded')){component.toggleProperty('isExpanded');collectionBody.slideUp();}else {collectionBody.slideDown();component.set('isLoading',true);var resourcePromise=[];if(collectionType === 'assessment'){resourcePromise = component.fetchAssessmentResources(collectionId,sessionId);}else if(collectionType === 'collection'){resourcePromise = component.fetchCollectionResources(collectionId,sessionId);}return resourcePromise.then(function(resources){component.set('resources',resources);component.toggleProperty('isExpanded');component.toggleProperty('isLoading');});}}}, //------------------------------------------------------------------------
//Methods
/**
   * @function fetchAssessmentResources
   * @return list of selected assessment resources
   */fetchAssessmentResources:function fetchAssessmentResources(collectionId,sessionId){var component=this;var userId=component.get('userId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var courseId=component.get('courseId');var classId=component.get('classId');var assessmentPromise=_ember['default'].RSVP.resolve(component.get('performanceService').getUserPerformanceResourceInAssessment(userId,courseId,unitId,lessonId,collectionId,sessionId,classId));return _ember['default'].RSVP.hash({assessmentResources:assessmentPromise}).then(function(hash){return hash.assessmentResources;});}, /**
   * @function fetchCollectionResources
   * @return list of selected collection resources
   */fetchCollectionResources:function fetchCollectionResources(collectionId,sessionId){var component=this;var userId=component.get('userId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var courseId=component.get('courseId');var classId=component.get('classId');var collectionPromise=_ember['default'].RSVP.resolve(component.get('performanceService').getUserPerformanceResourceInCollection(userId,courseId,unitId,lessonId,collectionId,sessionId,classId));return _ember['default'].RSVP.hash({collectionResources:collectionPromise}).then(function(hash){return hash.collectionResources;});}, //------------------------------------------------------------------------
//Dependencies
/**
   * @property {Boolean}
   * show/hide expanded view
   */isExpanded:false, /**
   * @property {Boolean}
   * show/hide loading spinner
   */isLoading:true, /**
   * @property {Array}
   * Store collections data
   */collection:[], /**
   * @property {Array}
   * Store selected collection resource data
   */resources:[], /**
   * @property {String}
   * Store formatted timespent value
   */collectionTimespent:_ember['default'].computed('collection',function(){var component=this;var timespent=component.get('collection.timeSpent');return timespent?(0,_gooruWebUtilsUtils.formatTime)(timespent):'--';}), /**
   * @property {Color}
   * Grade color code
   */colorStyle:_ember['default'].computed('collection.score',function(){var component=this;var score=component.get('collection.score');return (0,_gooruWebUtilsUtils.getBarGradeColor)(score);})});});