define('gooru-web/components/cards/gru-add-course-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/config/config','gooru-web/utils/endpoint-config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebConfigConfig,_gooruWebUtilsEndpointConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['add-course','featured-course-card'], // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},actions:{onPlayCourse:function onPlayCourse(courseId){var playerURL=(0,_gooruWebUtilsEndpointConfig.getEndpointUrl)() + '/content/courses/play/' + courseId + '?source=' + _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.RGO;window.open(playerURL,_gooruWebConfigConfig.PLAYER_WINDOW_NAME);},onAddCourse:function onAddCourse(courseId,course){var component=this;course.set('tabindex',component.get('tabindex'));component.sendAction('onAddCourse',courseId,course);},onRemixCourse:function onRemixCourse(courseId,course){var component=this;course.set('tabindex',component.get('tabindex'));component.sendAction('onRemixCourse',courseId,course);}}, // -------------------------------------------------------------------------
// Properties
/**
   * course object
   * @type {Object}
   */course:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('course.taxonomy.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('course.taxonomy'));}),isPremiumCourse:_ember['default'].computed('course',function(){var controller=this;var course=controller.get('course');var courseVersion=course.version;return courseVersion === 'premium';})});});