define('gooru-web/components/player/gru-navigation',['exports','ember'],function(exports,_ember){ /**
 * Player navigation
 *
 * Component responsible for showing simple navigation controls (i.e. back/next)
 * for the player. It may embed other components for interacting with the player.
 *
 * @module
 * @see controllers/player.js
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-navigation'],classNameBindings:['showReactionBar:with-reaction-bar:without-reaction-bar'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user change the emotion
     * @see gru-emotion-picker
     */changeEmotion:function changeEmotion(emotionScore){this.sendAction('onChangeEmotion',emotionScore);}, /**
     * Action triggered when the user open de navigator panel
     */openNavigator:function openNavigator(){this.sendAction('onOpenNavigator');}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {string} on change emotion action
   */onChangeEmotion:'onChangeEmotion', /**
   * @property {number} The rating score for the current resource
   */ratingScore:0, /**
   * Indicates when the collection is already submitted
   * @property {boolean}
   */submitted:false, /**
   * Indicates if changes can be made
   * @property {boolean} readOnly
   */readOnly:_ember['default'].computed.alias('submitted'), /**
   * @property {Collection
   */collection:null, /**
   * Indicates if the reaction bar is visible
   * @property {boolean}
   */showReactionBar:true, /**
   * Indicates if the navigator menu is going to be collapsed
   * @property {Boolean}
   */collapsedMenu:false // -------------------------------------------------------------------------
// Methods
});});