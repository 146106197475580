define('gooru-web/validations/change-password',['exports','ember-cp-validations'],function(exports,_emberCpValidations){exports['default'] = (0,_emberCpValidations.buildValidations)({oldPassword:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.password-required'}), /* allowing any character for now
    validator('format', {
      regex: /^[a-z0-9]+$/i,
      message: '{{description}}',
      descriptionKey: 'common.errors.password-special-characters'
    }),
*/(0,_emberCpValidations.validator)('length',{min:5,max:16,message:'{{description}}',descriptionKey:'common.errors.password-length'})],newPassword:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'change-password.new-password-required'}),(0,_emberCpValidations.validator)('length',{min:5,max:16,message:'{{description}}',descriptionKey:'common.errors.password-length'}) /* allowing any character for now
    validator('format', {
      regex: /^[a-z0-9]+$/i,
      message: '{{description}}',
      descriptionKey: 'common.errors.password-special-characters'
    }),
*/ /*  validator('confirmation', {
      on: 'password',
      message: '{{description}}',
      descriptionKey: 'common.errors.password-not-match'
    }) */],confirmPassword:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.password-confirm'}),(0,_emberCpValidations.validator)('confirmation',{on:'newPassword',message:'{{description}}',descriptionKey:'common.errors.password-not-match'})]});});