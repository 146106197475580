define('gooru-web/routes/profile/network/followers',['exports','ember','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsUiHelperMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Methods
model:function model(){var profile=this.modelFor('profile').profile;this.setTitle('Followers',null,true);var myFollowings=_ember['default'].A([]);var loggedUserId=this.get('session.userId');if(profile.get('id') !== loggedUserId && loggedUserId !== 'anonymous'){myFollowings = this.get('profileService').readFollowing(this.get('session.userId'));} //followers
var followers=this.get('profileService').readFollowers(profile.get('id'));return _ember['default'].RSVP.hash({followers:followers,myFollowings:myFollowings});},setupController:function setupController(controller,model){controller.set('followers',model.followers);controller.set('myFollowings',model.myFollowings);}});});