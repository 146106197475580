define('gooru-web/components/player/questions/gru-hs-text',['exports','ember','gooru-web/components/player/questions/gru-question'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruQuestion){ /**
 * Hot Spot Text
 *
 * Component responsible for controlling the logic and appearance of a hot spot
 * text question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-hs-text'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
setupInstanceProperties:_ember['default'].on('init',function(){var component=this;component.setAnswers();}),setupSubscriptions:_ember['default'].on('didInsertElement',function(){var component=this;var readOnly=component.get('readOnly');component.setUserAnswer();if(!readOnly){if(component.get('userAnswer')){component.notify(true);}this.$('li.answer').on('click',function(){var $this=$(this);var answerId=$this.data('id');var selected=component.get('selectedAnswers');var idx=selected.indexOf(answerId);$this.toggleClass('selected');if(idx === -1){selected.push(answerId);}else {selected.splice(idx,1);}component.notify(false);});}}),removeSubscriptions:_ember['default'].on('willDestroyElement',function(){this.$('li.answer').off('click');}), // -------------------------------------------------------------------------
// Properties
/**
   * @property {String} itemType
   */itemType:'hs-text', /*
   * @prop {Array} selectedAnswers - Array of ids for each one of the answers selected by the user
   */selectedAnswers:null, /*
   * @prop {String} instructions - Question instructions
   */instructions:_ember['default'].computed(function(){var action=this.get('i18n').t(this.get('instructionsActionTextKey')).string;return this.get('i18n').t('gru-hs-text.instructions',{action:action});}), /*
   * @typedef answers
   * @prop {String} id - answer id
   * @prop {String} content - markup string containing the answer text
   */answers:_ember['default'].computed.map('question.answers',function(answer){return {id:answer.get('id'),content:answer.get('text')};}), // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
/**
   * Notifies answer events
   * @param {boolean} onLoad if this was called when loading the component
   */notify:function notify(onLoad){var component=this;var questionUtil=component.get('questionUtil');var selected=component.get('selectedAnswers');var cleared=!selected.length;var correct=questionUtil.isCorrect(selected);component.notifyAnswerChanged(selected,correct);if(cleared){component.notifyAnswerCleared(selected);}else {if(onLoad){component.notifyAnswerLoaded(selected,correct);}else {component.notifyAnswerCompleted(selected,correct);}}}, /**
   * Set the user answer
   */setUserAnswer:function setUserAnswer(){if(this.get('hasUserAnswer')){var userAnswer=this.get('userAnswer');userAnswer.forEach(function(answerId){var selector='li.answer[data-id=\'' + answerId + '\']';var $answer=_ember['default'].$(selector);$answer.toggleClass('selected');});}}, /**
   * Set answers
   */setAnswers:function setAnswers(){var userAnswer=this.get('userAnswer');this.set('selectedAnswers',userAnswer || []);}});});