define('gooru-web/components/content/courses/edit/gru-accordion-course',['exports','ember','gooru-web/models/content/builder/item','gooru-web/models/content/unit','gooru-web/components/content/courses/play/gru-accordion-course'],function(exports,_ember,_gooruWebModelsContentBuilderItem,_gooruWebModelsContentUnit,_gooruWebComponentsContentCoursesPlayGruAccordionCourse){ /**
 * Content Builder: Accordion Course
 *
 * Component responsible for listing a set of units
 *
 * @module
 * @augments /components/content/courses/play/gru-accordion-course
 *
 */exports['default'] = _gooruWebComponentsContentCoursesPlayGruAccordionCourse['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/unit
   */courseService:_ember['default'].inject.service('api-sdk/course'), // -------------------------------------------------------------------------
// Actions
actions:{addUnit:function addUnit(){var unit=_gooruWebModelsContentUnit['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null});var builderItem=_gooruWebModelsContentBuilderItem['default'].create({isEditing:true,data:unit}); // Close all units before presenting the form for the new unit
this.actions.closeAllUnits.apply(this);this.get('items').pushObject(builderItem);this.refreshOrderList();},cancelAddUnit:function cancelAddUnit(builderItem){this.get('items').removeObject(builderItem);builderItem.destroy();this.refreshOrderList();},removeUnit:function removeUnit(builderItem){this.get('items').removeObject(builderItem);this.refreshOrderList();},remixUnit:function remixUnit(unit){var builderItem=_gooruWebModelsContentBuilderItem['default'].create({isEditing:false,data:unit});this.actions.closeAllUnits.apply(this);this.get('items').pushObject(builderItem);this.refreshOrderList();},sortUnits:function sortUnits(){var items=this.get('items');items.forEach(function(item){item.set('isExpanded',false);});this.actions.sortItems.call(this);},saveUnitsOrder:function saveUnitsOrder(){var courseId=this.get('model.id');var orderList=this.get('orderList');if(orderList && orderList.length > 1){this.get('courseService').reorderCourse(courseId,orderList).then((function(){this.actions.finishSort.call(this);}).bind(this));}else {this.actions.finishSort.call(this);}}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){$('[data-toggle="tooltip"]').tooltip();}});});