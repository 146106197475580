define('gooru-web/services/api-sdk/activity',['exports','ember','gooru-web/serializers/activity','gooru-web/adapters/activity'],function(exports,_ember,_gooruWebSerializersActivity,_gooruWebAdaptersActivity){ /**
 * @typedef {Object} ActivityService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {ActivitySerializer} activitySerializer
   */activitySerializer:null, /**
   * @property {ActivityAdapter} activityAdapter
   */activityAdapter:null, /**
   * @property {CollectionService}
   */quizzesCollectionService:_ember['default'].inject.service('quizzes/collection'),init:function init(){this._super.apply(this,arguments);this.set('activitySerializer',_gooruWebSerializersActivity['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('activityAdapter',_gooruWebAdaptersActivity['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Creates a new activity
   *
   * @param activityId object with the Activity data
   * @returns {Promise}
   */createActivity:function createActivity(activityData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedActivityData=service.get('activitySerializer').serializeCreateActivity(activityData);service.get('activityAdapter').createActivity({body:serializedActivityData}).then(function(responseData,textStatus,request){var activityId=request.getResponseHeader('location');activityData.set('id',activityId);resolve(activityData);},function(error){reject(error);});});}, /**
   * Gets an Activity by id
   * @param {string} activityId
   * @returns {Promise}
   */readActivity:function readActivity(activityId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('activityAdapter').readActivity(activityId).then(function(responseData){resolve(service.get('activitySerializer').normalizeReadActivity(responseData));},reject);});}, /**
   * Get a list of OA subtype
   * @returns {Promise}
   */getSubTypes:function getSubTypes(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('activityAdapter').getSubTypes().then(function(responseData){resolve(service.get('activitySerializer').normalizeSubTypes(responseData));},reject);});}, /**
   * Creates a new activity
   *
   * @param activityId object with the Activity data
   * @returns {Promise}
   */updateActivity:function updateActivity(activityId,activityData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedActivityData=service.get('activitySerializer').serializeCreateActivity(activityData);service.get('activityAdapter').updateActivity(activityId,serializedActivityData).then(function(){resolve(activityData);},function(error){reject(error);});});}, /**
   * Creates a reference in a specific offline activity
   * @param activityId
   * @param referenceData
   * @returns {Promise}
   *
   */createReferences:function createReferences(referenceData){var service=this;var serializedReferenceData=service.get('activitySerializer').serializeReferenceData(referenceData);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('activityAdapter').createReferences(referenceData.oaId,serializedReferenceData).then(function(responseData,textStatus,request){var refId=request.getResponseHeader('location');referenceData.set('id',refId);resolve(referenceData);},function(error){reject(error);});});}, /**
   * Delete activity
   *
   * @param activityId The activity id to delete
   * @returns {Ember.RSVP.Promise}
   */deleteActivity:function deleteActivity(activity){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('activityAdapter').deleteActivity(activity.id).then(function(){resolve();},reject);});}, /**
   * Notify an activity change at quizzes
   * @param {string} activityId
   */notifyQuizzesActivityChange:function notifyQuizzesActivityChange(activityId){var quizzesCollectionService=this.get('quizzesCollectionService');_ember['default'].Logger.info('Notifying activity change');return quizzesCollectionService.notifyCollectionChange(activityId,'activity');}, /**
   * Delete activity
   *
   * @param activityId The activity id to delete
   * @returns {Ember.RSVP.Promise}
   */deleteReference:function deleteReference(reference){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('activityAdapter').deleteReference(reference.oaId,reference.id).then(function(){resolve();},reject);});}});});