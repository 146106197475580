define('gooru-web/components/profile/gru-navigation-tabs',['exports','ember','gooru-web/utils/utils','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['profile gru-navigation-tabs'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     *
     * Triggered when an menu item is selected
     * @param item
     */selectItem:function selectItem(item){this.highlightMenuItem(item);if(this.get('onItemSelected')){this.sendAction('onItemSelected',item);}}, /**
     *
     * Triggered when the user clicks follow/unfollow button
     */toggleFollowingStatus:function toggleFollowingStatus(){if(this.get('onFollowChanged')){this.sendAction('onFollowChanged');}}}, // -------------------------------------------------------------------------
// Events
/**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){var component=this;var firstOccurancePath=(0,_gooruWebUtilsUtils.getRoutePathFirstOccurrence)();var item=_gooruWebConfigConfig.PROFILE_NAV_MENU_ITEMS.includes(firstOccurancePath)?firstOccurancePath:component.get('selectedMenuItem');component.highlightMenuItem(item);}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {String|Function} onItemSelected - event handler for when an menu item is selected
   */onItemSelected:null, /**
   * @property {String} selectedMenuItem - menu Item selected
   */selectedMenuItem:null, /**
   * @property {boolean} roles is student or not for proficiency tabs view
   */isStudent:_ember['default'].computed('profile',function(){var component=this;return component.get('profile').get('role') === 'student';}), /**
   * @property {boolean} roles is student or not for proficiency tabs view
   */isProficiencyVisible:_ember['default'].computed('profile',function(){var component=this;var isProficiencyTabVisible=false;var isMyProfile=component.get('isMyProfile');var isStudentProfile=component.get('isStudent');var loggedInUser=component.get('currentLoginUser');if(loggedInUser){var isTeacher=loggedInUser.get('role') === 'teacher';var isStudent=loggedInUser.get('role') === 'student';if(isStudentProfile && (isTeacher && !isMyProfile || isStudent && isMyProfile)){isProficiencyTabVisible = true;}}return isProficiencyTabVisible;}), /**
   * @property {boolean} roles is teacher only view about and proficiency tabs
   */isShowTabs:_ember['default'].computed('profile',function(){var component=this;var isTabsVisible=true;var isStudentProfile=component.get('isStudent');var isFromLearnerProfile=component.get('isFromLearnerProfile');var loggedInUser=component.get('currentLoginUser');var source=component.get('source');if(loggedInUser){var isTeacher=loggedInUser.get('role') === 'teacher';if(isStudentProfile && isTeacher && isFromLearnerProfile || source === 'study-player'){isTabsVisible = false;}}return isTabsVisible;}), /**
   * @property {boolean} isShowGuardianInvite is true only show the guardian tab
   */isShowGuardianInvite:_ember['default'].computed.alias('configuration.GRU_FEATURE_FLAG.isShowGuardianInvite'), // -------------------------------------------------------------------------
// Methods
highlightMenuItem:function highlightMenuItem(item){var text=undefined;if(item === 'about' && this.get('isMyProfile')){text = _gooruWebConfigConfig.PROFILE_DROPDOWN['about-me'];}else {text = _gooruWebConfigConfig.PROFILE_DROPDOWN[item];if(!text){text = _gooruWebConfigConfig.PROFILE_DROPDOWN.profile;}}this.set('selectedMenuText',text);this.$('.profile-menu-item').removeClass('selected');this.$('.profile-menu-item.' + item).addClass('selected');}});});