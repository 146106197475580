define('gooru-web/components/reports/class-assessment/gru-student-view',['exports','ember','gooru-web/utils/question-result'],function(exports,_ember,_gooruWebUtilsQuestionResult){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','class-assessment','gru-student-view'],actions:{ /**
     * @function actions:selectQuestion
     * @param {Number} questionId
     */selectQuestion:function selectQuestion(questionId){this.get('onSelectQuestion')(questionId);}, /**
     * When clicking at the student header
     * @param {string} studentId
     */selectStudent:function selectStudent(studentId){this.get('onSelectStudent')(studentId);}, /**
     * Sort students view
     * @function actions:sort
     */sortStudentView:function sortStudentView(sort){this.set('sortAlphabetically',sort);if(this.get('sortAlphabetically')){this.set('studentPerformanceListSorting',['student.lastFirstName']);}else {this.set('studentPerformanceListSorting',['score:desc','student.lastFirstName']);}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @prop { Collection } assessment
   */assessment:null, /**
   * @prop { User[] } students - Students taking the assessment
   */students:null, /**
   * @prop { ReportData } reportData
   */reportData:null, /**
   * Indicates if the report is displayed in anonymous mode
   * @property {boolean} anonymous
   */anonymous:false, /**
   * Returns a convenience structure to display the student view
   *
   *
   * Sample response
   * The "questionId#" corresponds to the actual question id
   *
   *  [
   *    {
   *      student: {User}
   *      reportData: {
   *        questionId1 : QuestionResult,
   *        questionId2 : QuestionResult,
   *        questionId3 : QuestionResult
   *      },
   *    },
   *    {
   *      student: {User}
   *      reportData: {
   *        questionId1 : QuestionResult,
   *        questionId2 : QuestionResult,
   *        questionId3 : QuestionResult
   *      },
   *    }
   *  ]
   *
   * @return [] students performance info
   */studentPerformanceList:_ember['default'].computed('students.[]','reportData.data',function(){var component=this;var students=component.get('students');var reportData=component.get('reportData.data');return students.map(function(student){var studentReportData=reportData[student.get('id')] || {};var studentResourceResults=component.getReportDataResults(studentReportData);return _ember['default'].Object.create({student:student,reportData:studentResourceResults,score:(0,_gooruWebUtilsQuestionResult.correctPercentage)(studentResourceResults)});});}), /**
   * Indicate if the table is to be sorted alphabetically using the students full name, if not, sort by average of score.
   *
   * @property {Boolean}
   */sortAlphabetically:false, /**
   * Array containing the criteria that controls the sorting, default is sort alphabetically, default is defined by property '@sortAlphabetically'
   *
   * @property {Array}
   */studentPerformanceListSorting:['score:desc','student.lastFirstName'], /**
   * Property containing t he sorted list
   *
   * @property {Ember.computed}
   */sortedStudentPerformance:_ember['default'].computed.sort('studentPerformanceList','studentPerformanceListSorting'), // -------------------------------------------------------------------------
// Methods
/**
   *
   * Returns only the QuestionResult instances or an empty object when the student has no value for a question
   *
   * studentReportData param looks like
   *
   *    {
   *      questionId1 : QuestionResult,
   *      questionId2 : QuestionResult,
   *      questionId3 : QuestionResult
   *    }
   *
   * @param {Object} studentReportData
   * @returns {QuestionResult[]}
   */getReportDataResults:function getReportDataResults(studentReportData){var component=this;var questions=component.get('assessment.resources');return questions.map(function(question){var reportData=studentReportData[question.get('id')];reportData.set('questionType',question.get('questionType'));return reportData;});}});});