define('gooru-web/components/gru-icon-popover',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-icon-popover','material-icons'],classNameBindings:['name'],tagName:'i', // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Icon name
   * @property {string}
   */name:null,attributeBindings:['dataToggle:data-toggle'],dataToggle:'popover',placement:'top',clipboardEvents:['success','error'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {string} type
   */key:null, /**
   * @property {string} template to be used for the popover window
   */template:_ember['default'].computed('key',function(){return '<div class="gru-icon-popover-content">\n    <span class=\'lead\'><b>' + this.get('i18n').t('gru-icon-popover.' + this.get('key') + '-title') + '</b></span>\n    <p>' + this.get('i18n').t('gru-icon-popover.' + this.get('key') + '-content') + '</p>\n   </div>';}), // -------------------------------------------------------------------------
// Methods
getTemplate:function getTemplate(){return '<div class="gru-icon-popover-window popover" role="tooltip">\n      <div class="arrow"></div>\n      <h3 class="popover-title"></h3>\n      <div class="popover-content"></div>\n    </div>';}, // -------------------------------------------------------------------------
// Events
/**
   * Overwrites didInsertElement hook to add clipboard and popover functionality
   */didInsertElement:function didInsertElement(){var component=this;component.$().popover({animation:false,placement:component.get('placement'),html:true,template:component.get('getTemplate')(),content:function content(){return component.get('template');}});}, // -------------------------------------------------------------------------
// Events
/**
   * Overwrites willDestroyElement hook. Destroys popover instance
   */willDestroyElement:function willDestroyElement(){this.$().popover('destroy');}});});