define('gooru-web/models/performance/course-competency-completion',['exports','ember'],function(exports,_ember){ /**
 * Model for Course competency completion data
 * @property {Object}
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Course Id of competency completion
   * @property {String}
   */courseId:null, /**
   * Total number of competency completion
   * @property {Integer}
   */totalCount:null, /**
   * Completed competency count
   * @property {Integer}
   */completedCount:null});});