define('gooru-web/components/player/gru-diagnostic-player',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // ------------------------------------------------------------------
// Attributes
classNames:['gru-diagnostic-player'], // -------------------------------------------------------------------
// Dependencies
/**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {ClassService} Service to retrieve class information
   */classService:_ember['default'].inject.service('api-sdk/class'),navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), // -------------------------------------------------------------------
// Properties
milestones:_ember['default'].A(),'class':null,preference:_ember['default'].computed('class',function(){return this.get('class.preference');}),mapLocation:null,diagnosticDetails:_ember['default'].computed('mapLocation.context',function(){return this.get('mapLocation.context.diagnostic') || null;}),isDiagnosticEnd:false,contentVisibility:null,isFailed:false,isPathLoading:false,hideLandingText:false,endObserver:_ember['default'].observer('isDiagnosticEnd',function(){var component=this;var routerCheckLooper=component.get('routerCheckLooper');if(component.get('isDiagnosticEnd') && !routerCheckLooper){component.set('routerCheckLooper',setInterval(function(){return component.checkRouterPath(component);},5000));}}),routerCheckLooper:null,loopingCount:0,currentClass:null, // ------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.disableConfirmText();},didDestroyElement:function didDestroyElement(){clearInterval(this.get('routerCheckLooper'));}, // -------------------------------------------------------------------
// Methods
fetchMilestoneDetails:function fetchMilestoneDetails(){var component=this;component.set('isLoading',true);var classId=component.get('class.id');_ember['default'].RSVP.hash({members:component.get('classService').readClassMembers(classId),contentVisibility:component.get('classService').readClassContentVisibility(classId)}).then(function(_ref){var members=_ref.members;var contentVisibility=_ref.contentVisibility;component.set('isLoading',false);component.set('contentVisibility',contentVisibility);var aClass=(0,_gooruWebUtilsUtils.getObjectCopy)(component.get('class'));aClass.set('memberGradeBounds',members.memberGradeBounds);component.set('currentClass',aClass);});},checkRouterPath:function checkRouterPath(component){if(!component.get('isDestroyed')){if(component.get('loopingCount') <= 3){component.incrementProperty('loopingCount');component.set('isPathLoading',true);var navigateMapService=component.get('navigateMapService');var diagnostic=component.get('diagnosticDetails') || {};var params={sessionId:diagnostic.session_id};navigateMapService.generateStudentRoute(params).then(function(result){if(result.status === 'complete'){component.set('isPathLoading',false);component.fetchMilestoneDetails();clearInterval(component.get('routerCheckLooper'));}else if(result.status === 'failed'){component.set('isFailed',true);clearInterval(component.get('routerCheckLooper'));}});}else {component.set('isFailed',true);component.set('isPathLoading',false);clearInterval(component.get('routerCheckLooper'));}}},disableConfirmText:function disableConfirmText(){var component=this;setTimeout(function(){component.set('hideLandingText',true);},5000);}});});