define('gooru-web/components/content/courses/gru-course-edit',['exports','ember','gooru-web/mixins/content/edit','gooru-web/mixins/modal','gooru-web/config/config','gooru-web/utils/utils','gooru-web/mixins/tenant-settings-mixin','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsContentEdit,_gooruWebMixinsModal,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebMixinsTenantSettingsMixin,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsContentEdit['default'],_gooruWebMixinsModal['default'],_gooruWebMixinsConfiguration['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
     * @requires service:notifications
     */notifications:_ember['default'].inject.service(), /**
     * @requires service:i18n
     */i18n:_ember['default'].inject.service(), /**
     * @requires service:api-sdk/course
     */courseService:_ember['default'].inject.service('api-sdk/course'), /**
     * @property {ProfileService} Profile service API SDK
     */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
     * @property {MediaService} Media service API SDK
     */mediaService:_ember['default'].inject.service('api-sdk/media'), /**
     * @type {SessionService} Service to retrieve session information
     */session:_ember['default'].inject.service('session'), /**
     * @requires service:taxonomy
     */taxonomyService:_ember['default'].inject.service('taxonomy'), /**
     * @property {limit}
     */ // this should come from config json
limit:400, /**
     * @property {offset}
     */offset:0,isShowFacets:_ember['default'].computed(function(){return this.get('isShowFacetsWidget');}), // -------------------------------------------------------------------------
// Attributes
classNames:['content','courses','gru-course-edit'],tagName:'article',selfId:_ember['default'].computed.alias('session.userId'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
       * Toggle section when user click on add icon
       */onToggleAddCollaborator:function onToggleAddCollaborator(){var component=this;component.$('.sub-sec-coteach .add-collaborator-panel').slideToggle();}, /**
       * Triggered from a co-teacher card of class mgt
       */removeCoteacher:function removeCoteacher(collaborator){var component=this;var courseCollaborators=component.get('collaborators');courseCollaborators = courseCollaborators.removeObject(collaborator);var courseCollaboratorIds=courseCollaborators.map(function(collaborator){return collaborator.id;});component.updateCollaboratorsForCourse(courseCollaboratorIds).then(function(){component.set('collaborators',courseCollaborators);});}, /**
       * Action triggered when add list of users as co-teacher
       */onAddCollaborators:function onAddCollaborators(){var selectedCollaborators=arguments.length <= 0 || arguments[0] === undefined?_ember['default'].A([]):arguments[0];var component=this;var collaborators=component.get('collaborators') || _ember['default'].A([]);var courseCollaborators=collaborators.concat(selectedCollaborators);var courseCollaboratorIds=courseCollaborators.map(function(collaborator){return collaborator.get('id');});component.updateCollaboratorsForCourse(courseCollaboratorIds).then(function(){component.set('collaborators',courseCollaborators);});component.send('onToggleAddCollaborator');}, /**
       * Course Edit page back button logic for send to previous page state
       */courseEditBackButton:function courseEditBackButton(){var component=this;var isShowFacets=component.get('isShowFacets');if(component.get('classId') && !component.get('isLibraryContent')){component.get('router').transitionTo('teacher.class.course-map',component.get('classId'));}else {var previewRoute=isShowFacets?'facets-search':'library-search';component.get('router').transitionTo(previewRoute,{queryParams:{profileId:component.get('session.userId'),type:'my-content'}});}}, /**
       * Edit Content
       */editContent:function editContent(){var courseForEditing=this.get('course').copy();this.set('tempCourse',courseForEditing);this.set('isEditing',true);}, /**
       * Cancel edit content
       */cancelEdit:function cancelEdit(){var courseForCancelEditing=this.get('course').copy();this.set('isEditing',false);this.set('tempCourse',courseForCancelEditing);}, /**
       * Delete course
       */deleteCourse:function deleteCourse(){var myId=this.get('session.userId');var model={content:this.get('course'),deleteMethod:(function(){return this.get('courseService').deleteCourse(this.get('course.id'));}).bind(this),type:_gooruWebConfigConfig.CONTENT_TYPES.COURSE,redirect:{route:'library-search',params:{profileId:myId,type:'my-content'}}};this.actions.showModal.call(this,'content.modals.gru-delete-content',model,null,null,null,false);}, /**
       * Save Content
       */updateContent:function updateContent(){var component=this;var editedCourse=component.get('tempCourse');var course=component.get('course');var etlHrs=editedCourse.get('hours');var etlMins=editedCourse.get('minutes');(0,_gooruWebUtilsUtils.etlSecCalculation)(editedCourse,etlHrs,etlMins);editedCourse.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){var imageIdPromise=new _ember['default'].RSVP.resolve(editedCourse.get('thumbnailUrl'));if(editedCourse.get('thumbnailUrl') && editedCourse.get('thumbnailUrl') !== course.get('thumbnailUrl')){imageIdPromise = component.get('mediaService').uploadContentFile(editedCourse.get('thumbnailUrl'));}imageIdPromise.then(function(imageId){editedCourse.set('thumbnailUrl',imageId);component.get('courseService').updateCourse(editedCourse).then(function(){course.merge(editedCourse,['title','isVisibleOnProfile','thumbnailUrl','description','taxonomy','subject','mainSubject','audience','useCase','hours','minutes','summary','primaryLanguage','metadata']);component.set('isEditing',false);})['catch'](function(error){var message=component.get('i18n').t('common.errors.course-not-updated').string;component.get('notifications').error(message);_ember['default'].Logger.error(error);});});component.set('didValidate',true);}});}, /**
       * Save setting for visibility of collection in profile
       */publishToProfile:function publishToProfile(){var courseForEditing=this.get('course').copy();this.set('tempCourse',courseForEditing);this.actions.updateContent.call(this);}, /**
       *
       * @param {TaxonomyRoot} subject
       */selectSubject:function selectSubject(subject){this.set('tempCourse.mainSubject',subject);}, /**
       *
       * @param {TaxonomyTagData[]} taxonomy
       */selectTaxonomy:function selectTaxonomy(taxonomy){this.set('tempCourse.taxonomy',taxonomy);}, /**
       * Sets the current course builder location
       * @param unitId
       * @param lessonId
       */setLocation:function setLocation(unitId){var lessonId=arguments.length <= 1 || arguments[1] === undefined?undefined:arguments[1];this.sendAction('onLocationChange',unitId,lessonId);},onSelected:function onSelected(publisher){this.set('selectedPublisher',publisher);this.set('tempCourse.publisherId',publisher.id);},removePublish:function removePublish(){this.set('selectedPublisher',null);this.set('tempCourse.publisherId',null);}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;this._super.apply(this,arguments);component.setMainSubject();if(component.get('collaborators')){component.readCollaboratorsProfile();}},didInsertElement:function didInsertElement(){var _this=this;var component=this;var Pubisherlimit={offset:this.get('offset'),limit:this.get('limit')};component.get('courseService').getPublisherList(Pubisherlimit).then(function(pubisherList){component.set('publisherAll',pubisherList);var selectedPublisher=pubisherList.findBy('id',_this.get('tempCourse.publisherId'));_this.set('selectedPublisher',selectedPublisher);_ember['default'].run.scheduleOnce('afterRender',function(){if(component.get('tempCourse.publisherId')){component.$('.publish-list input[value = ' + component.get('tempCourse.publisherId') + ']').prop('checked',true);}});});}, // -------------------------------------------------------------------------
// Properties
/**
     * Course model as instantiated by the route. This is the model used when not editing
     * or after any course changes have been saved.
     * @property {Course}
     */course:null,hasCollaborator:false, /**
     * @property {Array} collaborators
     * Property for list of class collaborators
     */collaborators:_ember['default'].computed.alias('course.collaborator'), /**
     * Copy of the course model used for editing.
     * @property {Course}
     */tempCourse:{}, /**
     * @property {string} action name when the location is changed
     */onLocationChange:null, /**
     * @property {dummy} this handles undefined parent property setting ember upgrade fix
     */tempCourseAudience:_ember['default'].observer('tempCourse.audience',function(){_ember['default'].Logger.log('audience',this.get('tempCourse.audience'));}), /**
     * @property {dummy} this to find isCollaborator
     */isCollaborator:_ember['default'].observer('tempCourse.audience','collaborators',function(){var collaborators=this.get('collaborators');var userId=this.get('selfId');var found=collaborators.findBy('id',userId);if(found){this.set('hasCollaborator',true);}}), /**
     * @property {string} selected lesson id
     */selectedLessonId:null,publisherAll:_ember['default'].A(),selectedPublisher:{}, // -------------------------------------------------------------------------
// Methods
setMainSubject:function setMainSubject(){var component=this;var subjectId=component.get('course.subject');if(subjectId){component.get('taxonomyService').findSubjectById(subjectId).then(function(subject){component.get('course').set('mainSubject',subject);});}else {component.get('course').set('mainSubject',null);}}, /**
     * @function updateCollaborators
     * Method to update collaborator list
     */updateCollaboratorsForCourse:function updateCollaboratorsForCourse(collaborators){var component=this;var courseService=component.get('courseService');var courseId=component.get('course.id');collaborators = collaborators.length?collaborators:null;return courseService.updateCollaborators(courseId,collaborators);},readCollaboratorsProfile:function readCollaboratorsProfile(){var component=this;var collaborators=component.get('collaborators');if(component.get('selfId') === component.get('course.creatorId')){collaborators.push(component.get('selfId'));}component.get('profileService').readMultipleProfiles(component.get('collaborators')).then(function(collaboratorProfiles){component.set('collaborators',collaboratorProfiles);});}});});