define('gooru-web/components/reports/assessment/questions/gru-silent-reading',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * SERP Encoding Assessment
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-silent-reading'], // -------------------------------------------------------------------------
// Properties
showCorrect:false, /**
   * @property {Array} answers
   * List of answers
   */answers:_ember['default'].computed.alias('answerObj'), /**
   * @property {Array} baseAnswers
   * List of base answers
   */baseAnswers:_ember['default'].computed.alias('question.answers'), /**
   * @property {Array} exemplars
   * List of question exemplars
   */exemplars:_ember['default'].computed.alias('question.exemplarDocs') // -------------------------------------------------------------------------
// Events
});});