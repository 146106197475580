define('gooru-web/components/grade-dropdown',['exports','ember','gooru-web/utils/dropdown-item','gooru-web/components/app-dropdown'],function(exports,_ember,_gooruWebUtilsDropdownItem,_gooruWebComponentsAppDropdown){ /**
 * @typedef {object} GradeDropdown
 */exports['default'] = _gooruWebComponentsAppDropdown['default'].extend({ /**
   * @property {[]} grades
   */grades:_ember['default'].A(), /**
   * @property {DropdownItem[]} dropdown items tree for rendering
   */tree:_ember['default'].A(), /**
   * It only supports multiple
   * @see AppDropdown.multiple
   *
   * @property {bool}
   */multiple:true, /**
   * @see AppDropdown.didInsertElement
   */didInsertElement:function didInsertElement(){this._super(); //calling app-dropdown didInsertElement
this.initDropdownItems();}, /**
   * @see AppDropdown.willDestroyElement
   */willDestroyElement:function willDestroyElement(){this._super(); //calling app-dropdown didInsertElement
this.get('tree').clear();this.get('items').clear();}, /**
   * Initialize the dropdown items
   */initDropdownItems:function initDropdownItems(){var component=this,grades=component.get('grades'),items=component.get('items'),tree=component.get('tree');this.get('tree').clear();this.get('items').clear();grades.forEach(function(grade){var levels=grade.get('levels'),hasLevels=levels && levels.length,item={id:grade.get('id'),label:grade.get('name'),data:{grade:grade, //grade
levels:_ember['default'].A() //more dropdown items for levels
}};var dropdownItem=_gooruWebUtilsDropdownItem['default'].create(item);items.addObject(dropdownItem);tree.addObject(dropdownItem);if(hasLevels){_ember['default'].$.each(levels,function(index,level){var levelItem={id:level,label:level,data:{grade:grade}};var levelDropdownItem=_gooruWebUtilsDropdownItem['default'].create(levelItem);dropdownItem.get('data').levels.addObject(levelDropdownItem); //adding to items to control selections
items.addObject(levelDropdownItem);});}});},actions:{ /**
     * When an items is selected
     * @param {DropdownItem} item
     */onItemSelected:function onItemSelected(item){var component=this,selected=item.get('selected'),levelDropdownItems=item.get('data').levels,hasLevels=levelDropdownItems && levelDropdownItems.get('length');item.set('selected',!selected);if(hasLevels){ //when has levels
levelDropdownItems.map(function(levelDropdownItem){levelDropdownItem.set('selected',!selected);});}if(component.get('onChangeAction')){component.sendAction('onChangeAction',component.get('selectedItems'));}}}});});