define('gooru-web/routes/student/class/class-activities',['exports','ember','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsUiHelperMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {Service} session service
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:api-sdk/class-activity
   */classActivityService:_ember['default'].inject.service('api-sdk/class-activity'), // -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Methods
model:function model(){var route=this;var currentClass=route.modelFor('student.class')['class'];route.setTitle('Class Activities',currentClass.title);var userId=route.get('session.userId');var classId=currentClass.get('id');var forMonth=moment().format('MM');var forYear=moment().format('YYYY');var startDate=forYear + '-' + forMonth + '-01';var endDate=moment(startDate).endOf('month').format('YYYY-MM-DD');return _ember['default'].RSVP.hash({classActivities:route.get('classActivityService').getStudentScheduledActivities(userId,classId,startDate,endDate)});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){controller.get('classController').selectMenuItem('class-activities');controller.set('classActivitiesOfMonth',model.classActivities);controller.set('selectedDate',moment().format('YYYY-MM-DD'));controller.initialize();}, /**
   * Reset data on deactive
   * @param controller
   */resetController:function resetController(controller){var todayDate=moment();controller.set('classActivities',_ember['default'].A([]));controller.set('classActivitiesOfMonth',_ember['default'].A([]));controller.set('startDate',todayDate.format('YYYY-MM-DD'));controller.set('endDate',null);controller.set('isToday',true);controller.set('isDaily',true);controller.set('isMonthly',false);controller.set('isWeekly',false);controller.set('isActive',false);controller.set('showItemsToGrade',false);controller.set('forMonth',todayDate.format('MM'));controller.set('forYear',todayDate.format('YYYY'));controller.set('tab',null);}});});