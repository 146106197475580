define('gooru-web/routes/profile/edit',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), /**
   * @property {Ember.Service} Service to do retrieve countries
   */lookupService:_ember['default'].inject.service('api-sdk/lookup'),isGuestAccount:_ember['default'].computed.alias('session.isGuest'), //
// Actions
actions:{willTransition:function willTransition(transition){var route=this;var model=route.controller.get('profile');if(model.get('hasDirtyAttributes')){var confirmation=confirm(route.get('i18n').t('validations.unsavedChanges').string);if(confirmation){model.rollbackAttributes();}else {transition.abort();}}}}, // -------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(transition){var userId=transition.params.profile.userId;var myId=this.get('session.userId');if(userId !== myId || this.get('isGuestAccount')){transition.abort();this.transitionTo('profile.about',userId);}},model:function model(){var route=this;var profile=this.modelFor('profile').profile;var profileStateId=profile.stateId;return route.get('lookupService').readCountries().then(function(countries){var usCountryId=countries.findBy('code',_gooruWebConfigConfig.COUNTRY_CODES.US).id;var usStates=route.get('lookupService').readStates(usCountryId);var usDistricts=null;if(profileStateId && profileStateId !== ''){usDistricts = route.get('lookupService').readDistricts(profileStateId);}return _ember['default'].RSVP.hash({countries:countries,states:usStates,districts:usDistricts,profile:profile});});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){var profile=model.profile;if(profile.get('schoolDistrictId')){profile.set('schoolDistrict',null);}controller.set('existingUsername',false);controller.set('profile',profile);controller.set('tempProfile',profile.copy());controller.set('countries',model.countries);controller.set('states',model.states);controller.set('districts',model.districts);}});});