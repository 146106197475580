define('gooru-web/components/class/analytics/performance/gru-data-picker',['exports','ember'],function(exports,_ember){ /**
 *Data Picker
 *
 *  Component responsible for letting the user select and update a performance option
 *  from a predefined list of options to display different analytic data.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-data-picker'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Set a new option as selected and update the component appearance accordingly
     *
     * @function actions:setOption
     * @param {string} newOption - newly selected option
     * @returns {undefined}
     */setOption:function setOption(newOption){if(!newOption.get('readOnly')){if(newOption.get('selected')){this.cleanupOption(newOption);}else {this.selectOption(newOption);}this.sendAction('onOptionsChange',this.get('selectedOptions'));}}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * List of  options to be displayed by the component
   *
   * @constant {Array}
   */options:_ember['default'].A([_ember['default'].Object.create({value:'score',selected:true,readOnly:false,isDisabled:false}),_ember['default'].Object.create({value:'completion',selected:false,readOnly:false,isDisabled:false}),_ember['default'].Object.create({value:'timeSpent',selected:false,readOnly:false,isDisabled:false}),_ember['default'].Object.create({value:'reaction',selected:false,readOnly:false,isDisabled:false}),_ember['default'].Object.create({value:'attempts',selected:false,readOnly:false,isDisabled:false})]), /**
   * @property {String|Function} onOptionsChange - event handler for when the selected option is changed
   */onOptionsChange:null, /**
   * Min options for select
   *
   * @constant {Number}
   */min:1, /**
   * Max options for select
   *
   * @property {Number}
   */max:1, /**
  * Icon displayed
  *
  * @property {String}
  */'icon-default':'fa-circle', /**
   * Icon selected
   *
   * @property {String}
   */'icon-selected':'fa-circle', /**
   *Computed property to calculate if the length of selectedOptions is less than the max value accepted
   *
   * @property
   */isLessThanMaxValue:_ember['default'].computed('max','selectedOptions.length',function(){return this.get('selectedOptions.length') < this.get('max');}), /**
   *Computed property to calculate if the length of selectedOptions is grater than the min value accepted
   *
   * @property
   */isGreaterThanMinValue:_ember['default'].computed('min','selectedOptions.length',function(){return this.get('selectedOptions.length') > this.get('min');}), /**
   *Computed property to calculate if the max length of selectedOptions is equal than the min value accepted
   *
   * @property
   */areSingleSelected:_ember['default'].computed('min','max',function(){return this.get('min') === this.get('max');}), /**
   *Computed property that return all options selected
   *
   * @property
   */selectedOptions:_ember['default'].computed('options.@each.selected',function(){return this.get('options').filterBy('selected',true);}), // -------------------------------------------------------------------------
// Methods
/**
   *When unselected  option
   *
   */cleanupOption:function cleanupOption(option){if(this.get('isGreaterThanMinValue')){option.set('selected',false);}}, /**
   *When select a option
   *
   */selectOption:function selectOption(option){if(this.get('areSingleSelected')){this.cleanSelectedOptions(this.get('selectedOptions'));this.addOption(option);}else {if(this.get('isLessThanMaxValue')){this.addOption(option);}}}, /**
   * Selected a option.
   *
   */addOption:function addOption(option){option.set('selected',true);}, /**
   * Unselected a option.
   *
   */cleanSelectedOptions:function cleanSelectedOptions(selectedOptions){selectedOptions.forEach(function(option){option.set('selected',false);});}});});