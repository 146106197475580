define('gooru-web/models/performance/collection-performance-summary',['exports','ember','gooru-web/models/performance/performance-summary'],function(exports,_ember,_gooruWebModelsPerformancePerformanceSummary){ /**
 * Collection performance summary model
 *
 * @typedef {Object} CollectionPerformanceSummary
 */exports['default'] = _gooruWebModelsPerformancePerformanceSummary['default'].extend({ /**
     * @property {String} classId
     */collectionId:null, /**
     * @property {string} collectionType assessment|collection|assessment-external
     */collectionType:null, /**
     * @property {number}
     */attempts:null, /**
     * @property {number}
     */views:null, /**
     * @property {string}
     */status:null, /**
     * @property {Boolean} Value that tells whether the performance data belongs to an assessment
     */isAssessment:_ember['default'].computed.equal('collectionType','assessment'), /**
     * @property {Boolean} Value that tells whether the performance data belongs to an assessment
     */isExternalAssessment:_ember['default'].computed.equal('collectionType','assessment-external'), /**
     * @property {Boolean} Value that tells whether the performance data belongs to a collection
     */isCollection:_ember['default'].computed.equal('collectionType','collection')});});