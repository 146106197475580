define('gooru-web/components/century-skills/gru-century-skills-legend',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-century-skills-legend'],tagName:'header' // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Properties
// ----------------------------
// Methods
});});