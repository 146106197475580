define('gooru-web/components/gru-user-sign-up-cancel',['exports','ember','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebMixinsModal){ /**
 * User sign up cancel
 *
 * Component that lets the user return to or end the sign up flow
 * May be embedded into a modal window (@see gru-modal).
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-user-sign-up-cancel'],classNameBindings:['component-class'], // -------------------------------------------------------------------------
// Properties
/**
   * @type {?String} specific class
   */'component-class':null});});