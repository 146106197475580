define('gooru-web/routes/study-player-external',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebConfigConfig){function _toConsumableArray(arr){if(Array.isArray(arr)){for(var i=0,arr2=Array(arr.length);i < arr.length;i++) arr2[i] = arr[i];return arr2;}else {return Array.from(arr);}}exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
queryParams:{collectionId:{refreshModel:true}}, /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), /**
   * @property {Ember.Service} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {Ember.Service} Service to retrieve a collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'),offlineActivityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @type {UnitService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {UnitService} Service to retrieve unit information
   */unitService:_ember['default'].inject.service('api-sdk/unit'), /**
   * @type {LessonService} Service to retrieve lesson information
   */lessonService:_ember['default'].inject.service('api-sdk/lesson'), /**
   * @type {ClassService} Service to retrieve class information
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @type {SkylineInitialService} Service to retrieve skyline initial service
   */skylineInitialService:_ember['default'].inject.service('api-sdk/skyline-initial'), /**
   * @type {reportService} Service to retrieve report information
   */reportService:_ember['default'].inject.service('api-sdk/report'),learningToolService:_ember['default'].inject.service('api-sdk/learning-tools'), // -------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(transition){this._super.apply(this,arguments);if(!this.get('session.isAnonymous')){var queryParams=transition.queryParams;if(queryParams && queryParams.classId){return this.doCheckClassDestination(queryParams.classId);}}},model:function model(params){var route=this;window.currentlyPlayingClass = null;return route.get('navigateMapService').getMapLocation(params).then(function(mapLocation){var courseId=mapLocation.get('context.courseId');var unitId=mapLocation.get('context.unitId');var lessonId=mapLocation.get('context.lessonId');params.collectionId = params.collectionId || mapLocation.get('context.itemId') || mapLocation.get('context.collectionId'); //override the context if next API return's any collection suggestions
var lessonSuggestionStates=[].concat(_toConsumableArray(Object.values(_gooruWebConfigConfig.DIAGNOSTIC_LESSON_SUGGESTION_EVENTS)),_toConsumableArray(Object.values(_gooruWebConfigConfig.DEPENDENT_LESSON_SUGGESTION_EVENTS)));var isLessonSuggestion=lessonSuggestionStates.includes(mapLocation.get('context.status')) || mapLocation.get('context.diagnostic') || mapLocation.get('context.source');if(mapLocation.get('suggestions') && mapLocation.get('suggestions').length > 0){var suggestions=mapLocation.get('suggestions')[0];params.collectionId = suggestions.get('id');params.type = suggestions.get('type');}var contentType=params.type;var timeSpentPromise=_ember['default'].RSVP.Promise.resolve([]);if(params.classId && route.get('session.role') === _gooruWebConfigConfig.ROLES.STUDENT){var dataParam={classId:params.classId,userId:route.get('session.userId'),to:moment().format('YYYY-MM-DD')};timeSpentPromise = route.get('reportService').fetchStudentTimespentReport(dataParam);}return _ember['default'].RSVP.hash({ //loading breadcrumb information and navigation info
course:!isLessonSuggestion?route.get('courseService').fetchById(courseId):null,unit:!isLessonSuggestion?route.get('unitService').fetchById(courseId,unitId):null,lesson:!isLessonSuggestion?route.get('lessonService').fetchById(courseId,unitId,lessonId):null,collection:contentType === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_COLLECTION?route.get('collectionService').readExternalCollection(params.collectionId):contentType === _gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY?route.get('offlineActivityService').readActivity(params.collectionId):route.get('assessmentService').readExternalAssessment(params.collectionId),studentTimespentData:timeSpentPromise}).then(function(hash){ //setting query params using the map location
params.type = params.type || mapLocation.get('context.itemType') || mapLocation.get('context.collectionType');params.classId = params.classId || mapLocation.get('context.classId');params.unitId = params.unitId || mapLocation.get('context.unitId');params.lessonId = params.lessonId || mapLocation.get('context.lessonId');params.pathId = params.pathId || mapLocation.get('context.pathId');params.collectionSubType = params.subtype || mapLocation.get('context.collectionSubType');if(!isLessonSuggestion){ // Set the correct unit sequence number
hash.course.children.find(function(child,index){var found=false;if(child.get('id') === hash.unit.get('id')){found = true;hash.unit.set('sequence',index + 1);}return found;}); // Set the correct lesson sequence number
hash.unit.children.find(function(child,index){var found=false;if(child.get('id') === hash.lesson.get('id')){found = true;hash.lesson.set('sequence',index + 1);}return found;});}var collection=hash.collection;var learningToolId=collection.get('learningToolId')?collection.get('learningToolId'):null;return _ember['default'].RSVP.hash({toolDetails:learningToolId?route.get('learningToolService').getLearningToolInformation(learningToolId):null}).then(function(_ref){var toolDetails=_ref.toolDetails;return _ember['default'].RSVP.hash({course:hash.course,unit:hash.unit,lesson:hash.lesson,collection:collection,mapLocation:mapLocation,collectionId:params.collectionId,type:params.type,studentTimespentData:hash.studentTimespentData,toolDetails:toolDetails,isLUContent:!!learningToolId});});});});},setupController:function setupController(controller,model){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_LOADING_COMPLETED,'*');window.currentlyPlayingClass = this.get('class');this._super.apply(this,arguments);var isAnonymous=model.isAnonymous;var mapLocation=model.mapLocation;var isLUContent=model.isLUContent;controller.setProperties({course:model.course,unit:model.unit,lesson:model.lesson,collection:model.collection,showConfirmation:model.collection && !(model.collection.get('isCollection') || isAnonymous), //TODO: move to computed
mapLocation:model.mapLocation,classId:mapLocation.get('context.classId'), //setting query params variables using the map location
unitId:mapLocation.get('context.unitId'),lessonId:mapLocation.get('context.lessonId'),collectionId:model.collectionId,type:model.type,content:mapLocation.content,'class':this.get('class'),studentTimespentData:model.studentTimespentData,toolDetails:model.toolDetails,isLUContent:isLUContent}); //calling to controllers method
controller.fetchActivityFeedbackCateory();controller.initial();if(isLUContent){controller.LUContentURLGeneration();}},deactivate:function deactivate(){this.get('controller').resetValues();},resetController:function resetController(controller){controller.set('isShowOaLandingPage',true);},doCheckClassDestination:function doCheckClassDestination(classId){var route=this;var classPromise=classId?route.get('classService').readClassInfo(classId):_ember['default'].RSVP.resolve({});return classPromise.then(function(classData){route.set('class',classData);if(route.findClassIsPermium(classData)){return route.get('skylineInitialService').fetchState(classId).then(function(skylineInitialState){var destination=skylineInitialState.get('destination');if(destination === _gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.classSetupInComplete){return route.transitionTo('student.class.setup-in-complete',classId);}else if(destination === _gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.showDirections || destination === _gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.ILPInProgress){return route.transitionTo('student.class.proficiency',classId);}else if(destination === _gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.diagnosticPlay){return route.transitionTo('student.class.diagnosis-of-knowledge',classId);}});}});}, /**
   * Method used to identify course is permium or not
   * @return {Boolean}
   */findClassIsPermium:function findClassIsPermium(aClass){var setting=aClass.get('setting');var isPremiumCourse=setting?setting['course.premium']:false;return isPremiumCourse;}});});