define('gooru-web/components/charts/gru-performance-chart',['exports','ember','gooru-web/utils/utils','gooru-web/utils/math'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebUtilsMath){ /**
 * Performance and Completion Chart
 *
 * Component responsible for showing the Performance and Completion Chart.
 * This component takes the dimensions of height and width from the parent element.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['charts','gru-performance-chart'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){this.$('[data-toggle="tooltip"]').tooltip();}, // -------------------------------------------------------------------------
// Properties
/**
   * Indicates if it is a teacher
   * @property {boolean}
   */isTeacher:false, /**
   * @property {Performance} Performance summary
   */performanceSummary:null, /**
   * @property {integer} assessmentCount
   */assessmentCount:null, /**
   * @property {String} Route to go after clicking on percentage
   */routeToGo:null, /**
   * @property {Text} score text
   * Computed property for the performance score text to be displayed
   */scoreText:_ember['default'].computed('performanceSummary.score',function(){var scorePercentage=this.get('performanceSummary.score');if(this.get('assessmentCount') === 0){scorePercentage = null;}return scorePercentage >= 0 && scorePercentage !== null?scorePercentage + '%':this.get('i18n').t('common.not_started').string;}),isNotStarted:_ember['default'].computed('performanceSummary.score',function(){var scorePercentage=this.get('performanceSummary.score');if(this.get('assessmentCount') === 0){scorePercentage = null;}return !(scorePercentage >= 0 && scorePercentage !== null);}), /**
   * @property {Text} score text
   * Computed property for the performance score text to be displayed
   */scoreVal:_ember['default'].computed('performanceSummary.score',function(){var scorePercentage=this.get('performanceSummary.score');if(this.get('assessmentCount') === 0){scorePercentage = null;}return scorePercentage >= 0 && scorePercentage !== null?'' + scorePercentage:'--';}), /**
   * @property {Boolean} hasStarted
   * Computed property to know if course has started
   */hasStarted:_ember['default'].computed('performanceSummary',function(){return this.get('performanceSummary') !== null;}), /**
   * @property {Number} completionPercentage
   * Computed property to calculate the completion percentage
   */completionPercentage:_ember['default'].computed('performanceSummary',function(){var completed=this.get('performanceSummary.totalCompleted') || this.get('performanceSummary.completionTotal');var total=this.get('performanceSummary.total') || this.get('performanceSummary.completionTotal');return completed?(0,_gooruWebUtilsMath.roundFloat)(completed / total * 100):0;}), /**
   * @property {Number} tooltipText
   * Computed property to show the tooltipText
   */tooltipText:_ember['default'].computed('performanceSummary',function(){var completed=this.get('performanceSummary.totalCompleted') || this.get('performanceSummary.completionTotal') || 0;var total=this.get('performanceSummary.total') || this.get('performanceSummary.completionTotal');var percentage=completed?(0,_gooruWebUtilsMath.roundFloat)(completed / total * 100):0;var tooltipText=percentage + '% ' + this.get('i18n').t('common.completed').string;if(this.get('assessmentCount') === 0){tooltipText = 'NA';}return tooltipText;}), /**
   * @property {String} widthStyle
   * Computed property to know the width of the bar
   */widthStyle:_ember['default'].computed('completionPercentage',function(){var completed=this.get('performanceSummary.totalCompleted') || this.get('performanceSummary.completionTotal') || 0;var total=this.get('performanceSummary.total') || this.get('performanceSummary.completionTotal');var percentage=completed?(0,_gooruWebUtilsMath.roundFloat)(completed / total * 100):0;return _ember['default'].String.htmlSafe('width: ' + percentage + '%;');}), /**
   * @property {String} barColor
   * Computed property to know the color of the small bar
   */colorStyle:_ember['default'].computed('performanceSummary',function(){var score=this.get('performanceSummary.score');return _ember['default'].String.htmlSafe('background-color: ' + (0,_gooruWebUtilsUtils.getBarGradeColor)(score) + ';');}), /**
   * @property {Boolean} isFull
   * Computed property to know if the completion is full
   */isFull:_ember['default'].computed('completionPercentage',function(){return this.get('completionPercentage') >= 100;})});});