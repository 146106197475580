define('gooru-web/components/proficiency/competency-info-pull-up',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({classNames:['competency-info-pull-up'],classNameBindings:['isInspectCompetency:open','isExpand:expand','isTeacherReport:teacher-view'], /**
   * @property {Boolean} isExpand
   */isExpand:false, /**
   * @property {String} studentId
   */studentId:_ember['default'].computed('studentProfile',function(){return this.get('studentProfile.id') || null;}), /**
   * @property {String} competencyStatus
   */competencyStatus:_ember['default'].computed('competency',function(){var component=this;var competency=component.get('competency');if(competency && competency.source){if(competency.source.indexOf('ActivityStream') === 0 && (competency.status === 4 || competency.status === 3)){return competency && _gooruWebConfigConfig.COMPETENCY_STATUS[7];}else {return competency && _gooruWebConfigConfig.COMPETENCY_STATUS[competency.status];}}else {return competency && _gooruWebConfigConfig.COMPETENCY_STATUS[competency.status];}}), /**
   * @property {String} competencyCode
   */competencyCode:null, /**
   * Maintains the value of popover position
   * @type {String}
   */tagPopoverDefaultPosition:'bottom', // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});this.setupTooltip();},actions:{ /**
     * @function expandPullUp
     * Method to toggle expand view for mobile
     */expandPullUp:function expandPullUp(){var component=this;component.toggleProperty('isExpand');},closePullUp:function closePullUp(){var component=this;component.toggleProperty('isInspectCompetency');component.set('isExpand',false);component.sendAction('onClosePullUp');},playContent:function playContent(queryParams,contentId,content){var component=this;component.sendAction('playContent',queryParams,contentId,content);}}, // -------------------------------------------------------------------------
// Methods
setupTooltip:function setupTooltip(){var component=this;var $anchor=this.$('.lo-content');var isMobile=window.matchMedia('only screen and (max-width: 768px)');var tagPopoverDefaultPosition=this.get('tagPopoverDefaultPosition');$anchor.attr('data-html','true');$anchor.popover({placement:tagPopoverDefaultPosition,content:function content(){return component.$('.tag-tooltip').html();},trigger:'manual',container:'body'});if(isMobile.matches){$anchor.on('click',function(){var $this=$(this);if(!$this.hasClass('list-open')){ // Close all tag tooltips by simulating a click on them
$('.competency-info-pull-up > .content.list-open').click();$this.addClass('list-open').popover('show');}else {$this.removeClass('list-open').popover('hide');}});}else {$anchor.on('mouseenter',function(){$(this).popover('show');});$anchor.on('mouseleave',function(){$(this).popover('hide');});}}});});