define('gooru-web/components/student/independent/gru-independent-learning-navigation',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){ /**
 * Student independent learning navigation
 *
 * Component responsible for enabling more flexible navigation options for the student independent learning.
 * For example, where {@link student/independent/gru-independent-learning-navigation.js}} allows access the student independent learning information and navigate through the menu options.
 * @module
 * @see controllers/student-independent-learning.js
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-independent-learning-navigation'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     *
     * Triggered when an menu item is selected
     * @param item
     */selectItem:function selectItem(item){if(this.get('onItemSelected')){this.selectItem(item);this.sendAction('onItemSelected',item);}}}, // -------------------------------------------------------------------------
// Events
/**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){var item=this.get('selectedMenuItem');this.selectItem(item);},willDestroyElement:function willDestroyElement(){this._super.apply(this,arguments);this.set('selectedMenuItem',null);}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {String|Function} onItemSelected - event handler for when an menu item is selected
   */onItemSelected:null, /**
   * @property {String} selectedMenuItem - menu Item selected
   */selectedMenuItem:null, // -------------------------------------------------------------------------
// Observers
/**
   * Refreshes the left navigation with the selected menu item
   */refreshSelectedMenuItem:(function(){var item=this.get('selectedMenuItem');this.selectItem(item);}).observes('selectedMenuItem'), // -------------------------------------------------------------------------
// Methods
/**
   * Triggered when a menu item is selected. Set the class icon for the item selected showing in the mobiles dropdown menu.
   * @param {string} item
   */selectItem:function selectItem(item){if(item){var itemElement='.' + item;this.$('.tab').removeClass('active');this.$(itemElement).addClass('active');}}});});