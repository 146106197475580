define('gooru-web/components/proficiency/inspect/student-min-proficiency',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['student-min-proficiency'],reportService:_ember['default'].inject.service('api-sdk/report'), // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Properties
studentList:_ember['default'].A([]),classData:null,classMembers:_ember['default'].A([]),summaryData:_ember['default'].A(),isLoading:false, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.fetchProficiencySummary();}, //--------------------------------------------------------------------------
//Actions
actions:{goBacktoPerformance:function goBacktoPerformance(){var component=this;var classId=component.get('classData.id');component.get('router').transitionTo('teacher.class.atc',classId);},onSearchStudent:function onSearchStudent(){var terms=event.target.value;this.searchStudentList(terms);}}, // ---------------------------------------------------------------------------
// Methods
fetchProficiencySummary:function fetchProficiencySummary(){var component=this;var classData=component.get('classData');var classMembers=component.get('classMembers');component.set('isLoading',true);var params={classId:classData.id,courseId:classData.courseId};component.get('reportService').fetchMinProficiencySummary(params).then(function(proficiencyData){proficiencyData.forEach(function(item){var activeStudent=classMembers.findBy('id',item.userId);item.set('studentDetail',activeStudent);});component.set('isLoading',false);component.setProperties({summaryData:proficiencyData,studentList:proficiencyData});});},searchStudentList:function searchStudentList(terms){var component=this;var convertText=function convertText(text){return text?text.toLowerCase():text;};var studentList=component.get('summaryData');var filteredStudent=_ember['default'].copy(studentList).filter(function(student){var studentDetail=student.studentDetail;return convertText(studentDetail.lastName).startsWith(convertText(terms)) || convertText(studentDetail.firstName).startsWith(convertText(terms));});component.set('studentList',filteredStudent);}});});