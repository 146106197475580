define('gooru-web/controllers/player-offline-activity',['exports','ember','gooru-web/config/config','gooru-web/mixins/modal','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsModal,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsTenantSettingsMixin['default'],_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Attributes
queryParams:['caContentId','classId','role','isPreview','source','isIframeMode','isDeepLink'], // -------------------------------------------------------------------------
// Dependencies
oaAnalyticsService:_ember['default'].inject.service('api-sdk/offline-activity/oa-analytics'),session:_ember['default'].inject.service('session'),oaService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {activityFeedbackService}
   */activityFeedbackService:_ember['default'].inject.service('api-sdk/activity-feedback'), // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on start player
onStartPlayer:function onStartPlayer(){var controller=this;controller.set('isShowStartPlayer',false);}, //Action triggered when click on close player
onClosePlayer:function onClosePlayer(){var controller=this;var classId=controller.get('classId');var source=controller.get('source');var isIframeMode=controller.get('isIframeMode'); //Redirect to CA if class ID is available otherwise go back to last accessed url
if(isIframeMode){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}else if(classId){controller.transitionToRoute('student.class.class-activities',classId);}else if(source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.RGO){window.close();}else {window.history.back();}},toggleDetailPullup:function toggleDetailPullup(){this.toggleProperty('isFullView');},showTimer:function showTimer(){this.toggleProperty('isShowStudyTimerWidget');},onCompleteOA:function onCompleteOA(){this.set('isShowCompletionConfirmation',true);},onCancelCompletion:function onCancelCompletion(){this.set('isShowCompletionConfirmation',false);},onCompleteSubmission:function onCompleteSubmission(){this.set('tiggerAction',true);this.set('isShowCompletionConfirmation',false);this.set('isEnableCompleteButton',false);}, /**
     * Action triggered to remix the collection
     * @param content
     */remixOaAcitivity:function remixOaAcitivity(offlineActivity){var component=this;if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {component.get('oaService').readActivity(offlineActivity.id).then(function(result){var model={content:result,lessonId:offlineActivity.lessonId,unitId:offlineActivity.unitId,courseId:offlineActivity.courseId,isCollection:false,isOfflineActivity:true,onRemixSuccess:component.get('onRemixLessonItem')};component.send('showModal','content.modals.gru-oa-remix',model);});}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} caContentId
   * DCA content ID
   */caContentId:null, /**
   * @property {collection} collection - The current Collection
   */collection:null, /**
   * @property {UUID} offlineActivityId
   */offlineActivityId:null, /**
   * @property {UUID} classId
   */classId:null, /**
   * @property {Object} role
   * Default role value
   */role:_gooruWebConfigConfig.ROLES.STUDENT, /**
   * @property {Boolean} isShowStartPlayer
   */isShowStartPlayer:_ember['default'].computed('offlineActivitySubmissions',function(){var controller=this;var offlineActivitySubmissions=controller.get('offlineActivitySubmissions');var oaTaskSubmissions=offlineActivitySubmissions?offlineActivitySubmissions.tasks.objectAt(0):null;return !(oaTaskSubmissions && oaTaskSubmissions.taskId);}), /**
   * @property {Object} offlineActivity
   */offlineActivity:{}, /**
   * @property {Boolean} isPreview
   */isPreview:false, /**
   * @property {Boolean} isTeacher
   */isTeacher:_ember['default'].computed.equal('role',_gooruWebConfigConfig.ROLES.TEACHER), /**
   * @property {Object} offlineActivitySubmissions
   * Property for selected offline activity submissions
   */offlineActivitySubmissions:null,isFullView:false,isShowStudyTimerWidget:false,activityTasks:_ember['default'].computed.alias('offlineActivity.tasks'),isActivitySubmitted:_ember['default'].computed('offlineActivitySubmissions',function(){var activitySubmissions=this.get('offlineActivitySubmissions');var isSumbitted=activitySubmissions && activitySubmissions.sessionId;return isSumbitted;}),offlineActivityWatcher:_ember['default'].observer('offlineActivity.tasks.@each.isAddedMandatorySubmission','offlineActivity.tasks.@each.isTaskSubmitted','offlineActivity.tasks','isActivitySubmitted',function(){var activityTasks=this.get('offlineActivity.tasks');if(activityTasks && activityTasks.length > 0 && !this.get('isActivitySubmitted')){var isInCompleteTaskAvailable=activityTasks.filter(function(task){return !task.isAddedMandatorySubmission && task.isEvidenceRequired;});var isUnSubmittedTaskAvailable=activityTasks.filter(function(task){return !task.isTaskSubmitted && task.isEvidenceRequired;});var enableCompletionButton=!(isInCompleteTaskAvailable.length || isUnSubmittedTaskAvailable.length);this.set('isEnableCompleteButton',enableCompletionButton);}else {this.set('isEnableCompleteButton',false);}}),isEnableCompleteButton:false,isShowCompletionConfirmation:false,tiggerAction:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadOfflineActivitySubmissions
   * Method to load offline activity submissions
   */loadOfflineActivitySubmissions:function loadOfflineActivitySubmissions(){var controller=this;controller.set('isLoading',true);_ember['default'].RSVP.hash({offlineActivitySubmissions:!(controller.get('isPreview') && (controller.get('isTeacher') || controller.get('source') === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.RGO))?controller.fetchTasksSubmissions():null}).then(function(_ref){var offlineActivitySubmissions=_ref.offlineActivitySubmissions;controller.set('offlineActivitySubmissions',offlineActivitySubmissions);controller.set('isLoading',false);});}, /**
   * @function fetchTasksSubmissions
   * Method to fetch student submitted oa task data
   */fetchTasksSubmissions:function fetchTasksSubmissions(){var component=this;var classId=component.get('classId');var oaId=component.get('caContentId');var userId=component.get('session.userId');return component.get('oaAnalyticsService').getSubmissionsToGrade(classId,oaId,userId);}, /**
   * @function fetchActivityFeedbackCateory
   * Method to fetch learning activity feedback
   */fetchActivityFeedbackCateory:function fetchActivityFeedbackCateory(){var controller=this;var role=controller.get('session.role');var userCategoryId=_gooruWebConfigConfig.FEEDBACK_USER_CATEGORY['' + role];controller.get('activityFeedbackService').getFeedbackCategory(userCategoryId).then(function(categoryLists){controller.set('categoryLists',categoryLists);var type=_gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY;var contentCategory=categoryLists.get('offlineActivities');if(contentCategory && contentCategory.length){controller.set('isShowActivityFeedback',true);controller.set('feedbackCategory',contentCategory.sortBy('feedbackTypeId'));controller.set('format',type);}else {controller.set('isShowActivityFeedback',false);controller.set('feedbackCategory',null);}});}});});