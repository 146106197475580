define('gooru-web/components/cards/gru-rubric-card',['exports','ember','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebMixinsModal){ /**
 * Rubric card
 *
 * Component responsible of showing the rubric information in cards, so that most useful information is summarized there.
 * @module
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['cards','gru-rubric-card'], // -------------------------------------------------------------------------
// Actions
actions:{}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Rubric} rubric
   */rubric:null});});