define('gooru-web/components/content/rubric/gru-category',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','rubric','gru-category'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Enable edit inline
     */editInline:function editInline(){this.showInlinePanel();}, /**
     * show category information
     */showInfo:function showInfo(){this.toggleProperty('isPanelExpanded');}, /**
     * Cancel edit inline
     */cancel:function cancel(){var component=this;var category=component.get('category');if(category.get('isNew') && !category.get('title')){component.sendAction('onCancelNewCategory',category);}this.setProperties({isPanelExpanded:false,isEditingInline:false});component.clearErrorMessages();}, /**
     *Copy category
     */copyCategory:function copyCategory(category,index){this.sendAction('onCopyCategory',category,index);}, /**
     *Delete a category
     */deleteCategory:function deleteCategory(category){this.sendAction('onDeleteCategory',category);}, /**
     *Set if feedback is required
     */setFeedBack:function setFeedBack(){this.set('category.requiresFeedback',!this.get('category.requiresFeedback'));}, /**
     *Save category
     */saveCategory:function saveCategory(){var component=this;component.clearErrorMessages();var tempCategory=component.get('tempCategory');tempCategory.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){if(component.validateLevels(tempCategory)){var category=component.get('category');category.setProperties(tempCategory);component.sendAction('onUpdateCategory',category);component.setProperties({isPanelExpanded:false,isEditingInline:false});}}});}}, // -------------------------------------------------------------------------
// Events
/**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){this._super.apply(this,arguments);var component=this; // Adds tooltip to UI elements (elements with attribute 'data-toggle')
component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'}); //Determinate if the device where the component is showing is a touch device in order to deactivate the tooltips
var isTouch='ontouchstart' in window || navigator.msMaxTouchPoints > 0;if(isTouch){component.$('.actions .item-actions button').tooltip('disable');}if(!this.get('category.title')){component.showInlinePanel();}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Category} category
   */category:null, /**
   * @property {Boolean} isEditingInline
   */isEditingInline:false, /**
   * @property {Boolean} isPanelExpanded
   */isPanelExpanded:false, /**
   * @property {Boolean} preview
   */preview:false, /**
   * Copy of the category used for editing.
   * @property {Category}
   */tempCategory:null, /**
   * Action to send when save is clicked
   * @property {String}
   */onUpdateCategory:null, /**
   * @property {boolean} Show if the category has levels
   */hasLevels:_ember['default'].computed('category.levels[]',function(){return this.get('category.levels.length') > 0 || false;}), /**
   * @property {Boolean} showNoLevelsError
   */showNoLevelsError:false, // -------------------------------------------------------------------------
// Events
/**
   * Initialize tempCategory by coping it from source of category object.
   */init:function init(){this._super.apply(this,arguments);var tempCategory=this.get('category').copy();this.set('tempCategory',tempCategory);}, // -------------------------------------------------------------------------
// Methods
/**
   * Show Inline Edit Panel
   */showInlinePanel:function showInlinePanel(){var modelForEditing=this.get('category').copy();this.setProperties({tempCategory:modelForEditing,isPanelExpanded:true,isEditingInline:true});},validateLevels:function validateLevels(category){var component=this;var areOk=true;var levels=category.get('levels');if(category.get('allowsLevels')){if(levels.length > 0){(function(){var gotFirstName=false;levels.map(function(level,index){if(!level.name && !gotFirstName){areOk = false;$('.name-input.' + index + ' span.name-error').addClass('visible');gotFirstName = true;}});})();}else {areOk = false;component.set('showNoLevelsError',true);}if(category.get('allowsScoring')){(function(){var gotFirstScore=false;levels.map(function(level,index){if(level.score == null && !gotFirstScore){areOk = false;$('.score-input.' + index + ' span.score-error').addClass('visible');gotFirstScore = true;}});})();}}return areOk;},clearErrorMessages:function clearErrorMessages(){$('.name-input span.name-error').removeClass('visible');$('.score-input span.score-error').removeClass('visible');this.set('showNoLevelsError',false);}});});