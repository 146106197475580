define('gooru-web/components/reports/assessment/questions/gru-phrase-reading',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * SERP Encoding Assessment
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-phrase-reading'], // -------------------------------------------------------------------------
// Properties
showCorrect:false, /**
   * @property {Array} answers
   * List of answers
   */answers:_ember['default'].computed.alias('answerObj'), /**
   * @property {Array} baseAnswers
   * List of base answers
   */baseAnswers:_ember['default'].computed.alias('question.answers'), /**
   * @property {Array} exemplars
   * List of question exemplars
   */exemplars:_ember['default'].computed.alias('question.exemplarDocs'),isPaused:false,audio:null,didInsertElement:function didInsertElement(){if(this.get('answers.length')){var audio=this.get('answers').get('firstObject');this.set('audio',new Audio(audio.text));}}, //Actions
actions:{ //Action triggered when play audio
onPlayAudio:function onPlayAudio(){var component=this;var _audio=component.get('audio');_audio.play();component.set('isPaused',true);_audio.onended = function(){component.set('isPaused',false);};_audio.ontimeupdate = function(){component.$('.answer-container .audio-progress .progress-filling').css('width',_audio.currentTime / _audio.duration * 100 + '%');};},onPauseAudio:function onPauseAudio(){var audio=this.get('audio');audio.pause();this.set('isPaused',false);}}});});