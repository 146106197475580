define('gooru-web/services/api-sdk/offline-activity/offline-activity',['exports','ember','gooru-web/serializers/offline-activity/offline-activity','gooru-web/adapters/offline-activity/offline-activity','gooru-web/adapters/taxonomy/taxonomy','gooru-web/serializers/taxonomy/taxonomy','gooru-web/utils/utils'],function(exports,_ember,_gooruWebSerializersOfflineActivityOfflineActivity,_gooruWebAdaptersOfflineActivityOfflineActivity,_gooruWebAdaptersTaxonomyTaxonomy,_gooruWebSerializersTaxonomyTaxonomy,_gooruWebUtilsUtils){ /**
 * @typedef {Object} ActivityService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {OfflineActivitySerializer} OfflineActivitySerializer
   */offlineActivitySerializer:null, /**
   * @property {offlineActivityAdapter} offlineActivityAdapter
   */offlineActivityAdapter:null, /**
   * @property {CollectionService}
   */quizzesCollectionService:_ember['default'].inject.service('quizzes/collection'),init:function init(){this._super.apply(this,arguments);this.set('offlineActivitySerializer',_gooruWebSerializersOfflineActivityOfflineActivity['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('offlineActivityAdapter',_gooruWebAdaptersOfflineActivityOfflineActivity['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomyAdapter',_gooruWebAdaptersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Creates a new activity
   *
   * @param activityId object with the Activity data
   * @returns {Promise}
   */createActivity:function createActivity(activityData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedActivityData=service.get('offlineActivitySerializer').serializeCreateActivity(activityData);service.get('offlineActivityAdapter').createActivity({body:serializedActivityData}).then(function(responseData,textStatus,request){var activityId=request.getResponseHeader('location');activityData.set('id',activityId);activityData.set('maxScore',1);resolve(activityData);},function(error){reject(error);});});}, /**
   * Gets an Activity by id
   * @param {string} activityId
   * @returns {Promise}
   */readActivity:function readActivity(activityId,isDefaultShowFW,classFramework){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').readActivity(activityId).then(function(responseData){var offlineActivity=service.get('offlineActivitySerializer').normalizeReadActivity(responseData);var taxonomyIds=(0,_gooruWebUtilsUtils.getTaxonomyIds)(offlineActivity,false);if(isDefaultShowFW && taxonomyIds.length){service.getcrosswalkCompetency(offlineActivity,classFramework,taxonomyIds).then(function(){resolve(offlineActivity);});}else {resolve(offlineActivity);}},reject);});}, /**
   * Get a list of OA subtype
   * @returns {Promise}
   */getSubTypes:function getSubTypes(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').getSubTypes().then(function(responseData){resolve(service.get('offlineActivitySerializer').normalizeSubTypes(responseData));},reject);});}, /**
   * Creates a new activity
   *
   * @param activityId object with the Activity data
   * @returns {Promise}
   */updateActivity:function updateActivity(activityId,activityData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedActivityData=service.get('offlineActivitySerializer').serializeUpdateActivity(activityData);service.get('offlineActivityAdapter').updateActivity(activityId,serializedActivityData).then(function(){resolve(activityData);},function(error){reject(error);});});}, /**
   * Updates the Offline Activity title
   *
   * @param oaId the id of the Offline Activity to be updated
   * @param title the Offline Activity title
   * @returns {Promise}
   */updateActivityTitle:function updateActivityTitle(oaId,title){var service=this;var serializedData=service.get('offlineActivitySerializer').serializeUpdateActivityTitle(title);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').updateActivity(oaId,serializedData).then(function(){resolve();},reject);});}, /**
   * Creates a reference in a specific offline activity
   * @param activityId
   * @param referenceData
   * @returns {Promise}
   *
   */createReferences:function createReferences(referenceData,fileId){var service=this;var serializedReferenceData=service.get('offlineActivitySerializer').serializeReferenceData(referenceData,fileId);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').createReferences(referenceData.oaId,serializedReferenceData).then(function(responseData,textStatus,request){var refId=request.getResponseHeader('location');referenceData.set('id',refId);resolve(referenceData);},function(error){reject(error);});});}, /**
   * Delete activity
   *
   * @param activityId The activity id to delete
   * @returns {Ember.RSVP.Promise}
   */deleteActivity:function deleteActivity(activity){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').deleteActivity(activity.id).then(function(){resolve();},reject);});}, /**
   * Copies an activity by id
   * @param {string} activityId
   * @returns {Ember.RSVP.Promise}
   */copyActivity:function copyActivity(activityId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').copyActivity(activityId).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},reject);});}, /**
   * Reorder activity resources
   *
   * @param activityId the id of the Activity to be updated
   * @param {string[]} questionIds
   * @returns {Promise}
   */reorderActivity:function reorderActivity(activityId,questionIds){var service=this;var serializedData=service.get('offlineActivitySerializer').serializeReorderActivity(questionIds);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').reorderActivity(activityId,serializedData).then(function(){service.notifyQuizzesActivityChange(activityId);resolve();},reject);});}, /**
   * Notify an activity change at quizzes
   * @param {string} activityId
   */notifyQuizzesActivityChange:function notifyQuizzesActivityChange(activityId){var quizzesCollectionService=this.get('quizzesCollectionService');_ember['default'].Logger.info('Notifying activity change');return quizzesCollectionService.notifyCollectionChange(activityId,'activity');}, /**
   * Find  the mastery accrual for the given list of activityIds
   *
   * @param {string} activityIds
   * @returns {Promise}
   */activitysMasteryAccrual:function activitysMasteryAccrual(activityIds){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').activitysMasteryAccrual(activityIds).then(function(responseData){resolve(service.get('offlineActivitySerializer').normalizeActivitysMasteryAccrual(responseData));},reject);});},oaTaskSubmissions:function oaTaskSubmissions(taskSubmissionPayload){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').oaTaskSubmissions(taskSubmissionPayload).then(function(){resolve(true);},reject);});}, /**
   * Delete reference
   *
   * @param activityId The activity id to delete
   * @returns {Ember.RSVP.Promise}
   */deleteReference:function deleteReference(reference){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').deleteReference(reference.oaId,reference.id).then(function(){resolve(reference);},reject);});}, //--------------Tasks------------------
createTask:function createTask(taskPayLoad){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedTaskPayLoad=service.get('offlineActivitySerializer').serializeCreateTask(taskPayLoad);service.get('offlineActivityAdapter').createTask({body:serializedTaskPayLoad}).then(function(responseData,textStatus,request){var id=request.getResponseHeader('location');taskPayLoad.set('id',id);resolve(taskPayLoad);},function(error){reject(error);});});}, /**
   * Remove Task
   *
   * @param {taskPayLoad} task to remove
   * @returns {Ember.RSVP.Promise}
   */removeTask:function removeTask(taskPayLoad){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').removeTask(taskPayLoad.oaId,taskPayLoad.id).then(function(){resolve(taskPayLoad);},reject);});}, /**
   * Update task details
   * @param oaId the id of the Activity to be updated
   * @param taskId the id of the task to be updated
   * @param data task data to be sent in the request body
   * @returns {Promise}
   */updateTask:function updateTask(oaId,taskId,taskPayLoad){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedTaskPayLoad=service.get('offlineActivitySerializer').serializeUpdateTask(taskPayLoad);service.get('offlineActivityAdapter').updateTask(oaId,taskId,serializedTaskPayLoad).then(function(){resolve(taskPayLoad);},function(error){reject(error);});});}, //--------------submissions------------------
createTaskSubmission:function createTaskSubmission(taskTaskSubmission){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedTaskSubmission=service.get('offlineActivitySerializer').serializecreateTaskSubmission(taskTaskSubmission);service.get('offlineActivityAdapter').createTaskSubmission({body:serializedTaskSubmission}).then(function(responseData,textStatus,request){var id=request.getResponseHeader('location');taskTaskSubmission.set('id',id);resolve(taskTaskSubmission);},function(error){reject(error);});});}, /**
   * Remove TaskSubmission
   *
   * @param {taskPayLoad} TaskSubmission to remove
   * @returns {Ember.RSVP.Promise}
   */removeTaskSubmission:function removeTaskSubmission(taskSubmissionPayLoad){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').removeTaskSubmission(taskSubmissionPayLoad.oaId,taskSubmissionPayLoad.oaTaskId,taskSubmissionPayLoad.id).then(function(){resolve(taskSubmissionPayLoad);},reject);});},associateTeacherRubricToOA:function associateTeacherRubricToOA(rubricId,oaId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').associateTeacherRubricToOA(rubricId,oaId).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},function(error){reject(error);});});},associateStudentRubricToOA:function associateStudentRubricToOA(rubricId,oaId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').associateStudentRubricToOA(rubricId,oaId).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},function(error){reject(error);});});}, /**
   * @function updateOACompleted
   * @param {Object} oaData
   * Method to update the student's OA status as completed
   */updateOACompleted:function updateOACompleted(oaData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').updateOACompleted(oaData).then(function(){resolve(true);},function(error){reject(error);});});}, /**
   * Update reference
   *
   * @param reference The reference to update
   * @returns {Ember.RSVP.Promise}
   */updateReferences:function updateReferences(reference){var service=this;var serializedData=service.get('offlineActivitySerializer').serializeReference(reference);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').updateReference(reference.oaId,reference.id,serializedData).then(function(){resolve(reference);},reject);});},getBulkSubmission:function getBulkSubmission(dataParam){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').getBulkSubmission(dataParam).then(function(response){resolve(response);},function(error){reject(error);});});},getCABulkSubmission:function getCABulkSubmission(dataParam){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('offlineActivityAdapter').getCABulkSubmission(dataParam).then(function(response){resolve(response);},function(error){reject(error);});});},getcrosswalkCompetency:function getcrosswalkCompetency(offlineActivity,classFramework,taxonomyIds){var service=this;return service.get('taxonomyAdapter').crosswalkCompetency(classFramework,taxonomyIds).then(function(crosswalkResponse){var frameworkCrossWalkComp=service.get('taxonomySerializer').normalizeCrosswalkCompetency(crosswalkResponse);var standards=offlineActivity.standards;standards.map(function(data){var taxonomyData=frameworkCrossWalkComp.findBy('sourceDisplayCode',data.code);if(taxonomyData){data.code = taxonomyData.targetDisplayCode;data.frameworkCode = taxonomyData.targetFrameworkId;}});});}});});