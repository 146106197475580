define('gooru-web/components/subject-dropdown',['exports','ember','gooru-web/utils/dropdown-item'],function(exports,_ember,_gooruWebUtilsDropdownItem){ /**
 * @typedef {object} SubjectDropdown
 */exports['default'] = _ember['default'].Component.extend({ /**
   *
   * @property {string} size class
   * @see bootstrap button dropdown
   */'btn-group-size':'btn-group-lg', /**
   * @property {[]} subjects
   */subjects:null, /**
   * @property {string} on change action
   */onChangeAction:null,dropdownItems:(function(){var subjects=this.get('subjects');return subjects.map(function(subject){return _gooruWebUtilsDropdownItem['default'].create({id:subject.get('libraryId'),label:subject.get('label'),data:subject});});}).property('subjects.[]'),actions:{onChange:function onChange(items){this.sendAction('onChangeAction',items);}}});});