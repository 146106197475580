define('gooru-web/controllers/not-found',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Attributes
// -------------------------------------------------------------------------
// Properties
/**
   * Maintains number of secs to wait for redirect.
   * @type {Number}
   */counter:10, // -------------------------------------------------------------------------
// Actions
actions:{loginAsGooru:function loginAsGooru(){this.transitionToRoute('/login');}}, // -------------------------------------------------------------------------
// Methods
init:function init(){var controller=this;controller.set('counter',10);controller.timer = setInterval(function(){var count=controller.get('counter') * 1 - 1;if(count <= 0){controller.set('counter',0);clearInterval(controller.timer);controller.transitionToRoute('/login');}else {controller.set('counter',count);}},1000);},destroy:function destroy(){this._super.apply(this,arguments);if(this.timer){clearInterval(this.timer);this.timer = null;}}});});