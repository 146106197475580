define('gooru-web/serializers/learner/learner',['exports','ember','gooru-web/mixins/configuration','gooru-web/config/config','gooru-web/utils/math','gooru-web/utils/utils','gooru-web/models/learner/location','gooru-web/models/learner/performance'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebConfigConfig,_gooruWebUtilsMath,_gooruWebUtilsUtils,_gooruWebModelsLearnerLocation,_gooruWebModelsLearnerPerformance){ /**
 * Serializer to support the Learner CRUD operations for API 3.0
 *
 * @typedef {Object} LearnerSerializer
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{session:_ember['default'].inject.service('session'), /**
   * Normalize the Fetch Locations endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Location[]} an array of learner locations
   */normalizeLocations:function normalizeLocations(payload){var result=[];var serializer=this;var locations=payload.usageData;if(_ember['default'].isArray(locations)){result = locations.map(function(location){return serializer.normalizeLocation(location);});}return result;}, /**
   * Normalize the one Location from the endpoint's response
   *
   * @param payload is part of the response in JSON format
   * @returns {Location}
   */normalizeLocation:function normalizeLocation(payload){var serializer=this;var date;if(payload.lastAccessed){date = (0,_gooruWebUtilsUtils.toLocal)(payload.lastAccessed);}return _gooruWebModelsLearnerLocation['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{collectionId:payload.courseId?null:payload.collectionId,courseId:payload.courseId,lessonId:payload.lessonId,unitId:payload.unitId,type:payload.courseId?_gooruWebConfigConfig.CONTENT_TYPES.COURSE:payload.collectionType,title:payload.courseId?payload.courseTitle:payload.collectionTitle,lastAccessed:payload.lastAccessed?date:null,status:payload.status,currentId:payload.courseId?payload.collectionId:null,currentTitle:payload.courseId?payload.collectionTitle:null,currentType:payload.courseId?payload.collectionType:null,pathType:payload.pathType || null,pathId:payload.pathId || 0,ctxPathType:payload.ctxPathType || null,ctxPathId:payload.ctxPathId || 0});}, /**
   * Normalize the Fetch Performances endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Performance[]} an array of learner performances
   */normalizePerformances:function normalizePerformances(payload){var result=[];var serializer=this;var performances=payload.usageData;if(_ember['default'].isArray(performances)){result = performances.map(function(performance){return serializer.normalizePerformance(performance);});}return result;}, /**
   * Normalize the one performance from the endpoint's response
   *
   * @param payload is part of the response in JSON format
   * @returns {Performance}
   */normalizePerformance:function normalizePerformance(payload){var serializer=this;return _gooruWebModelsLearnerPerformance['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{courseId:payload.courseId,courseTitle:payload.courseTitle,timeSpent:payload.timeSpent,completedCount:payload.completedCount,scoreInPercentage:(0,_gooruWebUtilsMath.roundFloat)(payload.scoreInPercentage),totalCount:payload.totalCount,collectionId:payload.collectionId,collectionTitle:payload.collectionTitle,attempts:payload.attempts});}, /**
   * Normalize the Fetch Performances in Lesson endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Performance[]} an array of learner performances
   */normalizePerformancesLesson:function normalizePerformancesLesson(payload){var result=[];var serializer=this;var content=payload.content;if(_ember['default'].isArray(content)){content.map(function(content){var performances=content.usageData;if(_ember['default'].isArray(performances)){result = performances.map(function(performance){return serializer.normalizePerformanceLesson(performance);});}});}return result;}, /**
   * Normalize the one performance from the endpoint's response
   *
   * @param payload is part of the response in JSON format
   * @returns {Performance}
   */normalizePerformanceLesson:function normalizePerformanceLesson(payload){var serializer=this;return _gooruWebModelsLearnerPerformance['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{reaction:payload.reaction,attemptStatus:payload.attemptStatus,timeSpent:payload.timeSpent,completedCount:payload.completedCount,scoreInPercentage:(0,_gooruWebUtilsMath.roundFloat)(payload.scoreInPercentage),totalCount:payload.totalCount,collectionId:payload.collectionId || payload.assessmentId,attempts:payload.attempts});}, /**
   * Normalize the Fetch Performances in Course endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Performance[]} an array of learner performances
   */normalizePerformancesCourse:function normalizePerformancesCourse(payload){var result=[];var serializer=this;var content=payload.content;if(_ember['default'].isArray(content)){content.map(function(content){var performances=content.usageData;if(_ember['default'].isArray(performances)){result = performances.map(function(performance){return serializer.normalizePerformanceCourse(performance);});}});}return result;}, /**
   * Normalize the Fetch Performances in Unit endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Performance[]} an array of learner performances
   */normalizePerformancesUnit:function normalizePerformancesUnit(payload){var result=[];var serializer=this;var content=payload.content;if(_ember['default'].isArray(content)){content.map(function(content){var performances=content.usageData;if(_ember['default'].isArray(performances)){result = performances.map(function(performance){return serializer.normalizePerformanceUnit(performance);});}});}return result;}, /**
   * Normalize the one performance from the endpoint's response
   *
   * @param payload is part of the response in JSON format
   * @returns {Performance}
   */normalizePerformanceCourse:function normalizePerformanceCourse(payload){var serializer=this;return _gooruWebModelsLearnerPerformance['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{reaction:payload.reaction,attemptStatus:payload.attemptStatus,timeSpent:payload.timeSpent,completedCount:payload.completedCount,scoreInPercentage:(0,_gooruWebUtilsMath.roundFloat)(payload.scoreInPercentage),totalCount:payload.totalCount,unitId:payload.unitId,attempts:payload.attempts});}, /**
   * Normalize the one performance from the endpoint's response
   *
   * @param payload is part of the response in JSON format
   * @returns {Performance}
   */normalizePerformanceUnit:function normalizePerformanceUnit(payload){var serializer=this;return _gooruWebModelsLearnerPerformance['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{reaction:payload.reaction,attemptStatus:payload.attemptStatus,timeSpent:payload.timeSpent,completedCount:payload.completedCount,scoreInPercentage:(0,_gooruWebUtilsMath.roundFloat)(payload.scoreInPercentage),totalCount:payload.totalCount,lessonId:payload.lessonId,attempts:payload.attempts,sourceList:payload.sourceList.map(function(source){return serializer.normalizePerformanceLesson(source);})});}, /**
   * Normalize the Fetch Location in Course endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Location} current location for course
   */normalizeFetchLocationCourse:function normalizeFetchLocationCourse(payload){var serializer=this;var content=payload.content;if(_ember['default'].isArray(content) && content.length){var locationPayload=content[0];return serializer.normalizeLocationCourse(locationPayload);}return '';}, /**
   * Normalize the one location from the endpoint's response
   *
   * @param payload is part of the response in JSON format
   * @returns {Location}
   */normalizeLocationCourse:function normalizeLocationCourse(payload){var serializer=this;return _gooruWebModelsLearnerLocation['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{courseId:payload.courseId,unitId:payload.unitId,lessonId:payload.lessonId,collectionId:payload.collectionId || payload.assessmentId,title:payload.collectionTitle || payload.assessmentTitle,pathId:payload.pathId || 0,pathType:payload.pathType || null,ctxPathId:payload.ctxPathId || 0,ctxPathType:payload.ctxPathType || null});}});});