define('gooru-web/serializers/lookup/lookup',['exports','ember','gooru-web/models/country','gooru-web/models/state','gooru-web/models/district','gooru-web/models/audience','gooru-web/models/depth-of-knowledge','gooru-web/models/license'],function(exports,_ember,_gooruWebModelsCountry,_gooruWebModelsState,_gooruWebModelsDistrict,_gooruWebModelsAudience,_gooruWebModelsDepthOfKnowledge,_gooruWebModelsLicense){ /**
 * Serializer to support the Profile CRUD operations for API 3.0
 *
 * @typedef {Object} ProfileSerializer
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Normalizes audiences
   * @param {} payload
   * @returns {Audience[]}
   */normalizeReadAudiences:function normalizeReadAudiences(payload){var items=payload.audience || [];return items.map(function(audience){return _gooruWebModelsAudience['default'].create({id:audience.id,name:audience.label,order:audience.sequence_id});});}, /**
   * Normalizes depth of knowledge items
   * @param {} payload
   * @returns {DepthOfKnowledge[]}
   */normalizeReadDepthOfKnowledgeItems:function normalizeReadDepthOfKnowledgeItems(payload){var items=payload.depth_of_knowledge || [];return items.map(function(dok){return _gooruWebModelsDepthOfKnowledge['default'].create({id:dok.id,name:dok.label,order:dok.sequence_id});});}, /**
   * Normalizes licenses
   * @param {} payload
   * @returns {License[]}
   */normalizeReadLicenses:function normalizeReadLicenses(payload){var items=payload.license || [];return items.map(function(license){return _gooruWebModelsLicense['default'].create({id:license.id,name:license.label,order:license.sequence_id});});}, /**
   * Normalizes countries
   * @param {} payload
   * @returns {Country[]}
   */normalizeReadCountries:function normalizeReadCountries(payload){var countries=payload.countries || [];return countries.map(function(country){return _gooruWebModelsCountry['default'].create({id:country.id,name:country.name,code:country.code});});}, /**
   * Normalizes states
   * @param {} payload
   * @returns {State[]}
   */normalizeReadStates:function normalizeReadStates(payload){var states=payload.states || [];return states.map(function(state){return _gooruWebModelsState['default'].create({id:state.id,name:state.name,code:state.code});});}, /**
   * Normalizes district
   * @param {} payload
   * @returns {District[]}
   */normalizeReadDistricts:function normalizeReadDistricts(payload){var districts=payload['school-districts'] || [];return districts.map(function(district){return _gooruWebModelsDistrict['default'].create({id:district.id,name:district.name,code:district.code});});}, /**
   * Normalizes Language
   * @param {} payload
   * @returns {Language[]}
   */normalizeLanguages:function normalizeLanguages(payload){var languages=payload.languages || [];return languages.map(function(language){return _ember['default'].Object.create(language);});}, /**
   * Normalizes Educational
   * @param {} payload
   * @returns {Educational[]}
   */normalizeEducations:function normalizeEducations(payload){var educationalUse=payload.educational_use || [];return educationalUse.map(function(educational){return _ember['default'].Object.create(educational);});}, /**
   * Normalizes audiences
   * @param {} payload
   * @returns {Subject[]}
   */normalizeFeatureSubjects:function normalizeFeatureSubjects(payload){var subjects=payload.featuredSubjectCourses || [];return subjects.map(function(subject){return _ember['default'].Object.create(subject);});}, /**
   * Normalizes audiences
   * @param {} payload
   * @returns {KnowMore[]}
   */normalizeKnowMoreAboutUserQuestions:function normalizeKnowMoreAboutUserQuestions(payload){var knowMoreAboutUserQuestions=payload.knowMoreAboutUserQuestions || [];return knowMoreAboutUserQuestions.map(function(knowMore){var knowMoreAboutUserQuestionsList=_ember['default'].Object.create({question:knowMore.question,default_value:knowMore.default_value,input_format:knowMore.input_format,is_editable:knowMore.is_editable,seq_id:knowMore.seq_id,store_key:knowMore.store_key,additionalInfo:knowMore.additional_info,options:(function(){var normalizedOptions=[];if(knowMore.options && knowMore.options.length){knowMore.options.forEach(function(option){normalizedOptions.push({id:option.key,name:option.value});});}return normalizedOptions;})()});return knowMoreAboutUserQuestionsList;});},normalizeSubProgram:function normalizeSubProgram(payload){var navigatorSubPrograms=payload.navigatorSubPrograms || [];return navigatorSubPrograms.map(function(item){return _ember['default'].Object.create({id:item.id,title:item.title,description:item.description,sequence:item.sequence,navigatorProgramId:item.navigator_program_id,images:item.images});});}});});