define('gooru-web/components/search/gru-question-options',['exports','ember','gooru-web/config/question','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebConfigQuestion,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-question-options'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * selectOption selects menu option
     */selectOption:function selectOption(option){this.sendAction('onSelectedOption',option);}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {*} questionTypes
   */questionTypes:_gooruWebConfigQuestion.QUESTION_TYPES, /**
   * Types of question selected
   *  @property {array} selectedOptionTypes
   *
   */selectedOptionTypes:_ember['default'].A([]), /**
   * True if multiple-choice option is selected
   *  @property {boolean} multipleChoiceSelected
   *
   */multipleChoiceSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.multipleChoice);}), /**
   * True if multiple-answer option is selected
   *  @property {boolean} multipleAnswerSelected
   *
   */multipleAnswerSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.multipleAnswer);}), /**
   * True if true-false option is selected
   *  @property {boolean} trueFalseSelected
   *
   */trueFalseSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.trueFalse);}), /**
   * True if fib option is selected
   *  @property {boolean} fibSelected
   *
   */fibSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.fib);}), /**
   * Free response option is selected
   *  @property {boolean} freeResponseSelected
   *
   */freeResponseSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.openEnded);}), /**
   * True if ht-highlight option is selected
   *  @property {boolean} htHighlightSelected
   *
   */htHighlightSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.hotTextHighlight);}), /**
   * True if ht-reorder option is selected
   *  @property {boolean} htReorderSelected
   *
   */htReorderSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.hotTextReorder);}), /**
   * True if hs-text option is selected
   *  @property {boolean} hsTextSelected
   *
   */hsTextSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.hotSpotText);}), /**
   * True if hs-images option is selected
   *  @property {boolean} hsImagesSelected
   *
   */hsImagesSelected:_ember['default'].computed('selectedOptionTypes.[]',function(){var selectedOptions=this.get('selectedOptionTypes');return selectedOptions.includes(_gooruWebConfigQuestion.QUESTION_TYPES.hotSpotImage);}),visibilityContentTypes:_ember['default'].computed.alias('configuration.visibility_content_types'),questionConfigTypes:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());var visibilityContentTypes=tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.visibility_content_types?tenantSettings.ui_element_visibility_settings.visibility_content_types:this.get('visibilityContentTypes');return visibilityContentTypes.other_questions;}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});