define('gooru-web/services/sign-in',['exports','ember','gooru-web/adapters/sign-in','gooru-web/serializers/sign-in'],function(exports,_ember,_gooruWebAdaptersSignIn,_gooruWebSerializersSignIn){ /**
 * Service for the performance
 *
 * @typedef {Object} performanceService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {SignInAdapter} signInAdapter
   */signInAdapter:null, /**
   * @property {SignInSerializer} signInSerializer
   */signInSerializer:null,init:function init(){this._super.apply(this,arguments);this.set('signInAdapter',_gooruWebAdaptersSignIn['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('signInSerializer',_gooruWebSerializersSignIn['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * fetch user tenant accounts
   *
   * @param user object with the user email
   * @returns {Promise}
   */getUserTenantAccounts:function getUserTenantAccounts(user){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('signInAdapter').getUserTenantAccounts(user).then(function(responseData){var tenantLists=service.get('signInSerializer').normalizegetUserTenantAccounts(responseData);resolve(tenantLists);},reject);});}});});