define('gooru-web/components/content/courses/play/gru-accordion-unit',['exports','ember','gooru-web/models/content/builder/item','gooru-web/mixins/content/builder','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebModelsContentBuilderItem,_gooruWebMixinsContentBuilder,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebMixinsTenantSettingsMixin){ /**
 * Course content viewer: Accordion Unit
 *
 * Component responsible for behaving as an accordion and listing a set of lessons.
 * It is meant to be used inside of an {@link ./gru-accordion-course|Accordion Course}
 *
 * @module
 * @augments Ember/Component
 * @mixes mixins/content/builder
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsContentBuilder['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/unit
   */unitService:_ember['default'].inject.service('api-sdk/unit'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','courses','gru-accordion','gru-accordion-unit'],classNameBindings:['model.isEditing:edit:view'],tagName:'li', // -------------------------------------------------------------------------
// Actions
actions:{toggle:function toggle(){var toggleValue=!this.get('model.isExpanded');var id=this.get('model.data.id');this.set('viewUnitDetails',false);this.loadData();this.get('onExpandUnit')(id,toggleValue);this.set('model.isExpanded',toggleValue);},expandLesson:function expandLesson(lessonId,expanded){var id=this.get('model.data.id');this.get('onExpandLesson')(id,lessonId,expanded);}}, // -------------------------------------------------------------------------
// Events
onDidInsertElement:_ember['default'].on('didInsertElement',function(){var expanded=this.get('model.isExpanded');if(expanded){this.scrollHere();this.loadData();}}), // -------------------------------------------------------------------------
// Properties
/**
   * @prop {String} course - Course this unit belongs to
   */course:null, /**
   * @prop {Boolean} isLoaded - Has the data for the unit already been loaded
   */isLoaded:false, /**
   * @prop {Content/Unit} unit
   */unit:_ember['default'].computed.alias('model.data'), /**
   * @property {string} selected lesson id
   */selectedLessonId:null, /**
   * When a unit is expanded/collapsed
   */onExpandUnit:null, /**
   * When a lesson within this unit is expanded/collapsed
   */onExpandLesson:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('unit.taxonomy.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('unit.taxonomy'),false);}), /**
   * @property {Boolean} view unit details
   */viewUnitDetails:false, // -------------------------------------------------------------------------
// Methods
/**
   * Load data for the unit
   *
   * @function actions:loadData
   * @returns {undefined}
   */loadData:function loadData(){var component=this;if(!component.get('isLoaded')){var _ret=(function(){var courseId=component.get('course.id');var unitId=component.get('unit.id');var lessonId=component.get('selectedLessonId');return {v:component.get('unitService').fetchById(courseId,unitId).then(function(unit){component.set('model.data',unit); // Wrap every lesson inside of a builder item
var children=unit.get('children').map(function(lesson){return _gooruWebModelsContentBuilderItem['default'].create({data:lesson,isExpanded:lessonId === lesson.get('id')});});component.set('items',children);component.set('isLoaded',true);})['catch'](function(error){var message=component.get('i18n').t('common.errors.unit-not-loaded').string;component.get('notifications').error(message);_ember['default'].Logger.error(error);})};})();if(typeof _ret === 'object')return _ret.v;}else {return _ember['default'].RSVP.resolve(true);}},scrollHere:function scrollHere(){var $component=_ember['default'].$(this.get('element'));_ember['default'].$('html, body').animate({scrollTop:$component.offset().top - 200},100);}});});