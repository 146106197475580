define('gooru-web/components/player/questions/gru-scientific-free-response',['exports','ember','gooru-web/components/player/questions/gru-question'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruQuestion){ /**
 * Open Ended Question
 *
 * Component responsible for controlling the logic and appearance of an open
 * ended question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-scientific-free-response'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
/**
   * When loading the user answer
   */updateUserAnswer:_ember['default'].on('init',function(){var component=this;component.setAnswers();}), // -------------------------------------------------------------------------
// Properties
/**
   * Indicates when the answer is completed
   * @return {bool}
   */isAnswerCompleted:_ember['default'].computed.bool('answer.length'), /**
   * @property {number} max answer length
   */maxLength:1000, /**
   * @property {number} characters left
   */charactersLeft:(function(){return this.get('maxLength') - this.get('answer').length;}).property('answer'), /**
   * @property {boolean} isShow
   */isShow:false, /**
   * @param answers
   */answers:_ember['default'].computed('question',function(){var component=this;var answers=JSON.parse(JSON.stringify(component.get('question.answerDetails')));var hints=component.get('question.hints');if(answers && hints != null){answers.forEach(function(choice){choice.hint = hints[choice.answer_category + '_explanation'];});}return answers;}), // -------------------------------------------------------------------------
// Observers
/**
   * When the user changes the response
   */updateAnswerObserver:function updateAnswerObserver(){this.notify(false);}, // -------------------------------------------------------------------------
// Methods
/**
   * Set answer
   * */setAnswers:function setAnswers(){if(this.get('hasUserAnswer')){var userAnswer=this.get('userAnswer');this.set('answer',userAnswer);this.notify(true);} // set observer for answer update
this.addObserver('answer',this.updateAnswerObserver);}, /**
   * Notifies answer events
   * @param {boolean} onLoad if this was called when loading the component
   */notify:function notify(onLoad){var component=this,answer=_ember['default'].$.trim(component.get('answer'));var correct=component.get('isAnswerCompleted');component.notifyAnswerChanged(answer,correct);if(component.get('isAnswerCompleted')){if(onLoad){component.notifyAnswerLoaded(answer,correct);}else {component.notifyAnswerCompleted(answer,correct);}}else {component.notifyAnswerCleared(answer);}},toggleAction:function toggleAction(isShow,category){var component=this;component.set('category',category);component.set('isShow',!isShow);}});});