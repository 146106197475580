define('gooru-web/controllers/profile',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'),profileService:_ember['default'].inject.service('api-sdk/profile'),sessionService:_ember['default'].inject.service('api-sdk/session'), /**
   * Inject proficiency controller to get class id
   */proficiencyController:_ember['default'].inject.controller('profile/proficiency'),aboutController:_ember['default'].inject.controller('profile/about'),queryParams:['classId'], // -------------------------------------------------------------------------
// Actions
actions:{toggleFollowingStatus:function toggleFollowingStatus(){var controller=this;if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {if(controller.get('profile.isFollowing')){controller.get('profileService').unfollowUserProfile(controller.get('profile.id')).then(function(){controller.get('profileService').readUserProfile(controller.get('profile.id')).then(function(updatedProfile){controller.set('profile',updatedProfile);});});}else {controller.get('profileService').followUserProfile(controller.get('profile.id')).then(function(){controller.get('profileService').readUserProfile(controller.get('profile.id')).then(function(updatedProfile){controller.set('profile',updatedProfile);});});}}}, /**
     * Action triggered when the user click back button
     * Redirect the user into class management page
     */onClickBackButton:function onClickBackButton(){var controller=this;var classId=controller.get('classId');if(classId){controller.transitionToRoute('/teacher/class/' + classId + '/class-management');}else {var currntUser=controller.get('currentLoginUser');if(currntUser.get('role') === 'teacher'){controller.transitionToRoute('/teacher-home');}else if(currntUser.get('role') === 'student'){controller.transitionToRoute('/student-home');}}}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Indicates if the user is seeing his own profile
   * @property {isMyProfile}
   * @see {Class} profile
   * @returns {bool}
   */isMyProfile:_ember['default'].computed('profile',function(){var controller=this;return controller.get('profile').get('id') === controller.get('currentUserId');}), /**
   * Property to check whether the classmanagement class id is available or not
   */isFromLearnerProfile:_ember['default'].computed('classId',function(){var controller=this;var classId=controller.get('classId');return classId !== null;}), /**
   * Property to check whether the current user is anonymous or not
   */isAnonymousUser:_ember['default'].computed('profile',function(){return this.get('currentUserId') === 'anonymous';}), /**
   * Current user id
   */currentUserId:_ember['default'].computed.alias('session.userId'), /**
   * The profile presented to the user
   * @property {Profile}
   */profile:null, /**
   * The menuItem selected
   * @property {String}
   */menuItem:null, /**
   * @type {Object}
   * Property to store currently logged in user data
   */currentLoginUser:null, /**
   * @type {String}
   * User selected class id
   */classId:null, // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
/**
   * Selected the menu item
   * @param {string} item
   */selectMenuItem:function selectMenuItem(item){this.set('menuItem',item);}});});