define('gooru-web/components/player/gru-activity-feedback',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Service
/**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), /**
   * @property {activityFeedbackService}
   */activityFeedbackService:_ember['default'].inject.service('api-sdk/activity-feedback'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-activity-feedback'],classNameBindings:['isStudyPlayer:study-player-feedback'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, /**
   * Observe the user feedback category
   */feedbackObserver:_ember['default'].observer('isShowFeedback',function(){ // this.$().slideToggle();
this.toggleProperty('isShowBackdrop');}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * action trigger when click feedback tab for show/hide the feedback popup`
     */onToggleFeedbackContent:function onToggleFeedbackContent(){var component=this;if(component.get('isFeedbackModified') && !component.get('isStudyPlayer')){var learningFeedback=component.getFeedbackObject();component.get('activityFeedbackService').submitUserFeedback(learningFeedback).then(function(){ // component.$().slideUp();
component.set('isShowBackdrop',false);component.set('isFeedbackModified',false);});}else { // component.$().slideToggle();
component.toggleProperty('isShowBackdrop');}}, /**
     * action trigger when click backdrop`
     */onCloseFeedback:function onCloseFeedback(){var component=this; // component.$().slideToggle();
component.toggleProperty('isShowBackdrop');component.sendAction('showFeedbackContainer');}}, // -------------------------------------------------------------------------
// Properties
/**
   * Indicates when the back drop is shown
   * @property {boolean}
   */isShowBackdrop:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function getFeedbackObject
   * Method to return feedback objective
   */getFeedbackObject:function getFeedbackObject(){var component=this;var userId=component.get('session.userId');var role=component.get('session.role')?component.get('session.role'):_gooruWebConfigConfig.ROLES.STUDENT;var userCategoryId=_gooruWebConfigConfig.FEEDBACK_USER_CATEGORY['' + role];var userFeedback=_ember['default'].A([]);var categoryLists=component.get('categoryLists');var collection=component.get('feedbackContent');categoryLists.map(function(category){var feedbackObj={feeback_category_id:category.categoryId};if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE){feedbackObj.user_feedback_quantitative = category.rating;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.BOTH){feedbackObj.user_feedback_qualitative = category.comments;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUALITATIVE){feedbackObj.user_feedback_qualitative = category.quality;}userFeedback.pushObject(feedbackObj);});var userFeedbackObj={content_id:collection.get('id'),content_type:component.get('format'),user_category_id:userCategoryId,user_feedbacks:userFeedback,user_id:userId};return userFeedbackObj;}});});