define('gooru-web/components/content/courses/edit/gru-accordion-unit',['exports','ember','gooru-web/models/content/builder/item','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/models/content/lesson','gooru-web/components/content/courses/play/gru-accordion-unit','gooru-web/mixins/modal','gooru-web/config/config','gooru-web/utils/utils','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebModelsContentBuilderItem,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebModelsContentLesson,_gooruWebComponentsContentCoursesPlayGruAccordionUnit,_gooruWebMixinsModal,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebMixinsTenantSettingsMixin){ /**
 * Content Builder: Accordion Unit
 *
 * Component responsible for behaving as an accordion and listing a set of lessons.
 * It is meant to be used inside of an {@link ./gru-accordion-course|Accordion Course}
 *
 * @module
 * @augments components/content/courses/play/gru-accordion-unit
 *
 */exports['default'] = _gooruWebComponentsContentCoursesPlayGruAccordionUnit['default'].extend(_gooruWebMixinsModal['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/unit
   */unitService:_ember['default'].inject.service('api-sdk/unit'), // -------------------------------------------------------------------------
// Actions
actions:{add:function add(){this.loadData().then((function(){this.actions.addLesson.call(this);this.get('onExpandUnit')(this.get('unit.id'),true);this.set('model.isExpanded',true);}).bind(this));},addLesson:function addLesson(){var lesson=_gooruWebModelsContentLesson['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null});var builderItem=_gooruWebModelsContentBuilderItem['default'].create({isEditing:true,data:lesson});this.get('items').pushObject(builderItem);this.refreshOrderList();},cancelAddLesson:function cancelAddLesson(builderItem){this.get('items').removeObject(builderItem);builderItem.destroy();this.refreshOrderList();}, /**
     * Load the data for this unit (data should only be loaded once)
     *
     * @function actions:selectUnit
     */cancelEdit:function cancelEdit(){if(this.get('model.isNew')){this.get('onCancelAddUnit')(this.get('model'));}else {this.set('model.isEditing',false);}},copy:function copy(){var model={content:this.get('unit'),courseId:this.get('course.id'),onRemixSuccess:this.get('onRemixUnit')};this.send('showModal','content.modals.gru-unit-remix',model);}, /**
     * Delete selected unit
     *
     */deleteItem:function deleteItem(builderItem){var component=this;var model={content:this.get('unit'),index:this.get('index'),parentName:this.get('course.title'),deleteMethod:(function(){return this.get('unitService').deleteUnit(this.get('course.id'),this.get('unit.id'));}).bind(this),type:_gooruWebConfigConfig.CONTENT_TYPES.UNIT,callback:{success:function success(){component.get('onDeleteUnit')(builderItem);}}};this.actions.showModal.call(this,'content.modals.gru-delete-content',model);},edit:function edit(){this.loadData().then((function(){var unitForEditing=this.get('unit').copy();this.set('tempUnit',unitForEditing);this.set('model.isEditing',true);}).bind(this));},openDomainPicker:function openDomainPicker(){var component=this;var domains=this.get('tempUnit.taxonomy').slice(0);var subject=this.get('course.mainSubject');var subjectDomains=_gooruWebModelsTaxonomyTaxonomyTagData['default'].filterBySubject(subject,domains);var notInSubjectDomains=_gooruWebModelsTaxonomyTaxonomyTagData['default'].filterByNotInSubject(subject,domains);var shortcuts=_gooruWebModelsTaxonomyTaxonomyTagData['default'].filterBySubject(subject,this.get('course.taxonomy'));var model={selected:subjectDomains,shortcuts:shortcuts,subject:subject,callback:{success:function success(selectedTags){var taxonomyList=component.get('tempUnit.taxonomy');var dataTags=selectedTags.map(function(taxonomyTag){return taxonomyTag.get('data');});_ember['default'].beginPropertyChanges();taxonomyList.clear();taxonomyList.pushObjects(dataTags);taxonomyList = taxonomyList.uniqBy('id');taxonomyList.pushObjects(notInSubjectDomains);_ember['default'].endPropertyChanges();}}};this.actions.showModal.call(this,'taxonomy.modals.gru-domain-picker',model,null,'gru-domain-picker');}, /**
     * Remove Lesson from a list of lessons
     */removeLesson:function removeLesson(builderItem){this.get('items').removeObject(builderItem);this.refreshOrderList();}, /**
     * Remove tag data from the taxonomy list in tempUnit
     */removeTag:function removeTag(taxonomyTag){var tagData=taxonomyTag.get('data');this.get('tempUnit.taxonomy').removeObject(tagData);}, /**
     * Remix Lesson from a list of lessons
     */remixLesson:function remixLesson(lesson){var builderItem=_gooruWebModelsContentBuilderItem['default'].create({isEditing:false,data:lesson});this.get('items').pushObject(builderItem);this.refreshOrderList();},saveUnit:function saveUnit(){var courseId=this.get('course.id');var editedUnit=this.get('tempUnit');var unitService=this.get('unitService');var etlHrs=editedUnit.get('expectedhours');var etlMins=editedUnit.get('expectedminutes');(0,_gooruWebUtilsUtils.etlSecCalculation)(editedUnit,etlHrs,etlMins);editedUnit.validate().then((function(_ref){var validations=_ref.validations;if(validations.get('isValid')){ // Saving an existing unit or a new unit (false id)?
var savePromise=editedUnit.get('id')?unitService.updateUnit(courseId,editedUnit):unitService.createUnit(courseId,editedUnit);savePromise.then((function(){this.get('unit').merge(editedUnit,['id','title','bigIdeas','essentialQuestions','expectedhours','expectedminutes']);this.set('unit.taxonomy',editedUnit.get('taxonomy').toArray());this.set('model.isEditing',false);}).bind(this))['catch']((function(error){var message=this.get('i18n').t('common.errors.unit-not-created').string;this.get('notifications').error(message);_ember['default'].Logger.error(error);}).bind(this));}this.set('didValidate',true);}).bind(this));},sortLessons:function sortLessons(){this.loadData();var items=this.get('items');items.forEach(function(item){item.set('isExpanded',false);});this.actions.sortItems.call(this);},saveLessonsOrder:function saveLessonsOrder(){var courseId=this.get('course.id');var unitId=this.get('unit.id');var orderList=this.get('orderList');if(orderList && orderList.length > 1){this.get('unitService').reorderUnit(courseId,unitId,orderList).then((function(){this.actions.finishSort.call(this);}).bind(this));}else {this.actions.finishSort.call(this);}}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);if(this.get('unit') && !this.get('unit.id')){ // If this a new unit, set the tempUnit value so things don't break in edit mode
var unitForEditing=this.get('unit').copy();this.set('tempUnit',unitForEditing);}}, // -------------------------------------------------------------------------
// Attributes
attributeBindings:['data-id'],'data-id':_ember['default'].computed.alias('unit.id'), // -------------------------------------------------------------------------
// Properties
/**
   * @property {TaxonomyTag[]} selectedTags - List of domain tags assigned to this unit
   */selectedTags:_ember['default'].computed('tempUnit.taxonomy.[]',function(){return this.get('tempUnit.taxonomy').map(function(tagData){return _gooruWebModelsTaxonomyTaxonomyTag['default'].create({isActive:true,isReadonly:true,isRemovable:true,data:tagData});});}), /**
   * @prop {Content/Unit} tempUnit - Temporary unit model used for editing
   */tempUnit:null});});