define('gooru-web/components/activity/course-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/config/config','gooru-web/utils/endpoint-config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebConfigConfig,_gooruWebUtilsEndpointConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['activity-course-card'], // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * course object
   * @type {Object}
   */course:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('course.taxonomy.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('course.taxonomy'));}),actions:{onPlayCourse:function onPlayCourse(courseId){var playerURL=(0,_gooruWebUtilsEndpointConfig.getEndpointUrl)() + '/content/courses/play/' + courseId + '?source=' + _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.RGO;window.open(playerURL,_gooruWebConfigConfig.PLAYER_WINDOW_NAME);},onAddCourse:function onAddCourse(courseId){var component=this;component.sendAction('onAddCourse',courseId);},onRemixCourse:function onRemixCourse(courseId){var component=this;component.sendAction('onRemixCourse',courseId);}}});});