define('gooru-web/components/cards/question-preview-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['cards','question-preview-card'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isShowCorrectAnswer
   */isShowCorrectAnswer:false, /**
   * @property {Boolean} isPause
   */isPause:false, /*
   * Hold the audio details
   */audioRecorder:null, /**
   * @property {Array} to get existing attachments
   */attachments:_ember['default'].computed(function(){return this.get('question.exemplarDocs');}),classData:null,collection:null, /**
   * @property {Boolean} isShowFeedback
   */isShowFeedback:false,didInsertElement:function didInsertElement(){var _this=this;if(this.get('question.type') === 'SERP_ID'){var _loop=function(index){var element=_this.get('question.exemplarDocs')[index];element.correct_answer.map(function(data){_this.hightLightDefaultWord(data.text,index,'identify-digraph');return data;});};for(var index=0;index < this.get('question.exemplarDocs').length;index++) {_loop(index);}}}, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */taxonomyTags:_ember['default'].computed('question.standards.[]',function(){var standards=this.get('question.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), /**
   * @property {answerDetails[]} List of answer of details
   */answerDetails:_ember['default'].computed('question',function(){var component=this;if(component.get('question.answerDetails')){var answerResult=component.get('question.answerDetails');return answerResult.map(function(choice){var data=_ember['default'].Object.create({answer_text:choice.answer_text,answer_category:choice.answer_category,answer_type:'text',correct_answer:''});if(choice.answer_text.indexOf('[]') !== -1 && choice.correct_answer.length > 0){(function(){var answerText=choice.answer_text.split('[]');var correctAnswer=choice.correct_answer;var values='';answerText.forEach(function(item,index){if(correctAnswer[index]){values += item + '[' + correctAnswer[index] + ']';}});data.set('answer_text',values);})();}return data;});}}), //Actions
actions:{ //Action triggered when play audio
onPlayAudio:function onPlayAudio(container,url,index,exemplarIndex){var component=this;if(this.get('question.type') === 'SERP_ID'){(function(){var _audio=component.get('audioRecorder');if(!_audio || component.get('answerIndex') !== index || component.get('playerIndex') !== exemplarIndex){_audio = new Audio(url);component.set('answerIndex',index);component.set('playerIndex',exemplarIndex);}component.set('audioRecorder',_audio);_audio.play();component.set('isPause',true);_audio.ontimeupdate = function(){component.$('.answer-container .' + container + ' .audio-progress .progress-filling').css('width',_audio.currentTime / _audio.duration * 100 + '%');};_audio.addEventListener('ended',function(){component.set('isPause',false);});})();}else {(function(){var _audio=component.get('audioRecorder');if(!_audio || component.get('answerIndex') !== index){_audio = new Audio(url);component.set('answerIndex',index);}component.set('audioRecorder',_audio);_audio.play();component.set('isPause',true);_audio.ontimeupdate = function(){component.$('.answer-container .' + container + ' .audio-progress .progress-filling').css('width',_audio.currentTime / _audio.duration * 100 + '%');};_audio.addEventListener('ended',function(){component.set('isPause',false);});})();}}, //Action triggered when pause audio
onPauseAudio:function onPauseAudio(){var component=this;var audio=component.get('audioRecorder');audio.pause();component.set('isPause',false);}},hightLightDefaultWord:function hightLightDefaultWord(text,answerIndex,selectedClass){var component=this;var innerHTML='';var html='<span class="correct">' + text + '</span>';if(component.$('.' + selectedClass + ' .answer-edit-' + answerIndex)[0]){component.$('.' + selectedClass + ' .answer-edit-' + answerIndex)[0].childNodes.forEach(function(childNode){if(childNode.data){innerHTML = innerHTML + childNode.data.replace(text,$.trim(html));}else if(childNode.data){innerHTML = innerHTML + childNode.data;}else {innerHTML = innerHTML + childNode.outerHTML;}});component.$('.' + selectedClass + ' .answer-edit-' + answerIndex).html(innerHTML);}}});});