define('gooru-web/controllers/student-learner-proficiency',['exports','ember','gooru-web/mixins/student-learner-proficiency','gooru-web/mixins/study-player','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsStudentLearnerProficiency,_gooruWebMixinsStudyPlayer,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsStudentLearnerProficiency['default'],_gooruWebMixinsStudyPlayer['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
     * @property {NavigateMapService}
     */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), /**
     * @type {AttemptService} attemptService
     * @property {Ember.Service} Service to send attempt related events
     */quizzesAttemptService:_ember['default'].inject.service('quizzes/attempt'), /**
     * @property {CourseMapService}
     */courseMapService:_ember['default'].inject.service('api-sdk/course-map'), /**
     * @property {session}
     */session:_ember['default'].inject.service('session'), /**
     * @requires studyPlayerController
     */studyPlayerController:_ember['default'].inject.controller('study-player'), // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on next
onPlayNext:function onPlayNext(){var _this=this;var controller=this;var navigateMapService=controller.get('navigateMapService');var profileId=controller.get('session.userData.gooruUId');var contextId=controller.get('contextId');controller.get('quizzesAttemptService').getAttemptIds(contextId,profileId).then(function(attemptIds){return !attemptIds || !attemptIds.length?{}:_this.get('quizzesAttemptService').getAttemptData(attemptIds[attemptIds.length - 1]);}).then(function(attemptData){return _ember['default'].RSVP.hash({attemptData:attemptData,mapLocationNxt:navigateMapService.getStoredNext()});}).then(function(_ref){var mapLocationNxt=_ref.mapLocationNxt;var attemptData=_ref.attemptData;if(controller.get('hasSuggestion')){mapLocationNxt.context.set('status','content-served');}mapLocationNxt.context.set('score',attemptData.get('averageScore'));return navigateMapService.next(mapLocationNxt.context);}).then(function(_ref2){var context=_ref2.context;var suggestions=_ref2.suggestions;var hasContent=_ref2.hasContent;controller.set('mapLocation.context',context);controller.set('mapLocation.suggestions',suggestions);controller.set('mapLocation.hasContent',hasContent);var suggestedContent=controller.get('suggestedContent');if(suggestedContent){controller.set('isShowSuggestion',true);}else {controller.checknPlayNext();}});},closePullUp:function closePullUp(){var component=this;component.set('isOpenPlayer',false);},playContent:function playContent(queryParams,contentId,content){var component=this;component.set('playerUrl',component.target.get('router').generate('player',contentId,{queryParams:queryParams}));component.set('isOpenPlayer',true);component.set('playerContent',content);}}, // -------------------------------------------------------------------------
// Properties
/**
     * @property {UUID} contextId
     */contextId:null, /**
     * Report Source Type
     * @property {String} source
     */source:null, /**
     * @property {String} role
     */role:null, /**
     * @property {UUID} unitId
     * Unit ID available in query param
     */unitId:null, /**
     * @property {UUID} lessonId
     * Lesson ID available in query param
     */lessonId:null, /**
     * @property {UUID} milestoneId
     * Milestone ID available in query param
     */milestoneId:null, /**
     * @property {Number} pathId
     * Path Id available in query param
     */pathId:0, /**
     * @property {String} pathType
     * Path type available in query param
     */pathType:null, /**
     * @property {UUID} unitId
     * Unit ID available in query param
     */collectionType:null, /**
     * @property {UUID} unitId
     * Unit ID available in query param
     */collectionId:null, /**
     * @property {boolean}
     */hasSignatureCollectionSuggestions:_ember['default'].computed.alias('mapLocation.hasSignatureCollectionSuggestions'), /**
     * @property {boolean}
     */hasSignatureAssessmentSuggestions:_ember['default'].computed.alias('mapLocation.hasSignatureAssessmentSuggestions'), /**
     * @property {boolean}
     */isDone:_ember['default'].computed('mapLocation.context.status',function(){return (this.get('mapLocation.context.status') || '').toLowerCase() === 'done';}), /**
     * @property {boolean}
     */hasAnySuggestion:_ember['default'].computed('hasSignatureAssessmentSuggestions','hasSignatureCollectionSuggestions','showSuggestion',function(){return (this.get('hasSignatureCollectionSuggestions') || this.get('hasSignatureAssessmentSuggestions')) && this.get('showSuggestion');}), /**
     * @property {Json}
     * Computed property to store suggestedContent
     */suggestedContent:_ember['default'].computed('mapLocation',function(){var controller=this;var suggestions=controller.get('mapLocation.suggestions');return suggestions && suggestions[0]?suggestions[0]:null;}), /**
     * @property {Boolean} isPlayerProficiency
     * Property to check whether to show player proficiency or not
     */isPlayerProficiency:_ember['default'].computed('contextId',function(){return !!this.get('contextId');}), /**
     * @property {Boolean} isFullScreen
     */isFullScreen:_ember['default'].computed(function(){var controller=this;var studyPlayerController=controller.get('studyPlayerController');var isFullScreen=studyPlayerController.get('isFullScreen');return isFullScreen;})});});