define('gooru-web/routes/student-independent-learning/assessments',['exports','gooru-web/config/config','gooru-web/routes/student-independent-learning/learning-base'],function(exports,_gooruWebConfigConfig,_gooruWebRoutesStudentIndependentLearningLearningBase){exports['default'] = _gooruWebRoutesStudentIndependentLearningLearningBase['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
/**
   * @property {String} the content type
   */contentType:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT, /**
   * @property {String} the menu item name
   */item:'assessments' // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Methods
});});