define('gooru-web/components/content/collections/gru-collection-list-attachment',['exports','ember','gooru-web/utils/utils','gooru-web/models/content/oa/reference','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebModelsContentOaReference,_gooruWebMixinsModal){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{classNames:['gru-collection-list-attachment'], /**
   */questionService:_ember['default'].inject.service('api-sdk/question'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Properties
/**
   * property is used to assign/get Question Id of the assessments
   */questionId:null, /**
   * @property {model} reference model being edited
   */model:null, /**
   * @property {referenceSubType} supplied object from gru-reference definition of subTypes and associated mimetype for ui binding
   */referenceSubType:(function(){return (0,_gooruWebUtilsUtils.getFileSubType)();}).property(),submissionType:(function(){return (0,_gooruWebUtilsUtils.getFileType)();}).property(), /**
   * @property {subTypeMimeType} returns the mimeType for selected subType
   */subTypeMimeType:(function(){var selectedSubType=this.get('model.subType') || 'others',selectedMimeType=this.get('referenceSubType').findBy('display_name',selectedSubType)?this.get('referenceSubType').findBy('display_name',selectedSubType).mimeType:'';this.set('model.subType',selectedSubType);return selectedMimeType;}).property('model.subType'),isEditing:false, /**
   * List of error messages to present to the user for conditions that the loaded image does not meet
   * @prop {String[]}
   */filePickerErrors:null, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('filePickerErrors',_ember['default'].A());var chooseOne=this.get('i18n').t('teacher-landing.class.class-settings.class-settings-sec.option-choose-one').string;var subTypeSel=_ember['default'].Object.create({display_name:chooseOne});this.set('referenceSubType1',(0,_gooruWebUtilsUtils.getFileSubType)());var referenceInstance=this.getNewReferenceModel();referenceInstance.set('subTypeSel',subTypeSel);this.set('model',referenceInstance);},didInsertElement:function didInsertElement(){this._super.apply(this,arguments);var chooseOne=this.get('i18n').t('teacher-landing.class.class-settings.class-settings-sec.option-choose-one').string;var subTypeSel=_ember['default'].Object.create({display_name:chooseOne});this.set('referenceSubType1',(0,_gooruWebUtilsUtils.getFileSubType)());var referenceInstance=this.getNewReferenceModel();referenceInstance.set('subTypeSel',subTypeSel);this.set('model',referenceInstance);}, /* -------------------------------------------------------------------------
   *** -------------------------------------------------------------------------
   * Actions*/actions:{ /*
     * Action to delete uploaded exemplar attachments
     */deleteReference:function deleteReference(refItem){var component=this;var arr=component.get('references');var attr='filePath';var value=refItem.filePath;this.deleteReferenceItem(arr,attr,value);}, /**
     * @function actions:disableButtons
     */ // eslint-disable-next-line no-dupe-keys
resetFileSelection:function resetFileSelection(){ // Reset the input element in the file picker
// http://stackoverflow.com/questions/1043957/clearing-input-type-file-using-jquery/13351234#13351234
var $fileInput=this.$('input[type="file"]');$fileInput.wrap('<form>').closest('form').get(0).reset();$fileInput.unwrap();this.set('selectedFile',null);}, /**
     *
     * @param {object} subType, UI selection of subtype
     */onSubTypeChange:function onSubTypeChange(subType){this.get('model').set('subType',subType.display_name);}, //GRU-file-picker-events action
selectFile:function selectFile(file){var type='uploaded';var reference=this.get('references');if(file){var subType=(0,_gooruWebUtilsUtils.getFileSubType)(file.type);this.set('model.file',file);this.set('model.type',type);this.set('model.subType',subType);this.set('model.fileName',file.name);this.set('model.references',reference);var model=this.get('model');this.actions.showModal.call(this,'content.modals.gru-edit-attachment',model,null,null,null,false);}}, /**
     * Function to set typeand subtype while on file uploading
     */updateURLRef:function updateURLRef(){var _this=this;var component=this;var editRefModel=component.get('model');this.set('model.type','remote');this.set('model.subType','url');component.validate().then(function(isValid){if(isValid){component.updateReference(editRefModel).then(function(){component.set('isLoading',false);var referenceInstance=component.getNewReferenceModel();_this.set('model',referenceInstance);});}});}}, /**
   * Function delete existing exemplar attachements based on requirement
   */deleteReferenceItem:function deleteReferenceItem(arr,attr,value){var i=arr.length;while(i--) {if(arr[i] && arr[i].hasOwnProperty(attr) && arguments.length > 2 && arr[i][attr] === value){arr.splice(i,1);}}var component=this;var refItem=arr;component.refreshReference(refItem);}, /**
   * Function to reload when upload or deletion of file attachment completed
   */refreshReference:function refreshReference(modelRef){var component=this;var refsCol=modelRef;var newSource=refsCol.slice(0);_ember['default'].set(this,'references',newSource);component.set('references',refsCol);}, /**
   * Function to update file attachment to the API service
   */updateReference:function updateReference(model){var _this2=this;var component=this;return component.get('questionService').createReferences(model,model.location).then(function(createdRefModel){component.updateParent(createdRefModel);var referenceInstance=_this2.getNewReferenceModel();component.set('model',referenceInstance); // needed to break the ref
});}, /**
   * Function to create new Instances of file upload
   */getNewReferenceModel:function getNewReferenceModel(){var component=this;var model=component.get('elementId');return _gooruWebModelsContentOaReference['default'].create(_ember['default'].getOwner(this).ownerInjection(),{questionId:model});}, /**
   * Function to validate uploading attachments
   */validate:function validate(){var component=this;var model=component.get('model');var didValidate;if(model.get('type') === 'uploaded'){didValidate = new _ember['default'].RSVP.Promise(function(resolve){resolve(true);});}else {didValidate = new _ember['default'].RSVP.Promise(function(resolve){if(model){model.validate().then(function(_ref){var validations=_ref.validations;return resolve(validations.get('isValid'));},function(){return resolve(false);});}});}component.set('didValidate',didValidate);return didValidate;}});});