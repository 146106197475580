define('gooru-web/helpers/course-sections-prefix',['exports','ember','gooru-web/utils/utils','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebMixinsTenantSettingsMixin){ /**
 * Show the Unit, Lesson, Assessment and Collection label correctly,
 * this is showing the letter and number prior the name.
 *
 * @example
 *
 * <span>{{course-sections-prefix title="My Unit" type="unit" index=1 complete=false}}</span>
 * @see /app/templates/components
 *
 * @param title {String}
 * @param index {Number}
 * @param type {String} "unit","lesson","collection" and "assessment"
 * @returns {String}
 */exports['default'] = _ember['default'].Helper.extend(_gooruWebMixinsTenantSettingsMixin['default'],{i18n:_ember['default'].inject.service('i18n'),compute:function compute(params,_ref){var index=_ref.index;var type=_ref.type;var longName=_ref.longName;var i18n=this.get('i18n');var lessonLabelCourseMap=this.get('lessonLabelCourseMap');return (0,_gooruWebUtilsUtils.courseSectionsPrefix)(index,type,i18n,longName,lessonLabelCourseMap);}});});