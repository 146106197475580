define('gooru-web/components/player/gru-external-collection-page',['exports','ember','gooru-web/config/config','gooru-web/utils/utils','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-external-collection-page'], // -------------------------------------------------------------------------
// Dependencies
analyticsService:_ember['default'].inject.service('api-sdk/analytics'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
/**
   * Observe the assessment change
   */collectionObserver:_ember['default'].observer('collection',function(){var component=this;component.resetProperties();}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collection.standards.[]',function(){var standards=this.get('collection.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}),didRender:function didRender(){var component=this;component.timeValidator();},didInsertElement:function didInsertElement(){var component=this;component.$('[data-toggle="tooltip"]').tooltip();}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click start
     */onStart:function onStart(){var component=this;component.set('isStarted',true);var externalUrl=component.get('collection.url');component.set('isDisableTimeEditor',false);if(externalUrl){if(component.get('toolDetails')){component.sendAction('onStartContentPlayed');}else {window.open(externalUrl,_gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT);}}}, /**
     * Action triggered when click submit
     */onSubmit:function onSubmit(){var component=this;component.set('isTimeEntered',true);component.updateSelfReport();}, /**
     * Action triggered when click cancel
     */onCancel:function onCancel(){var component=this;var isIframeMode=component.get('isIframeMode');if(isIframeMode){component.sendAction('onClosePlayer');}else {component.redirectTo();}}},timeValidator:function timeValidator(){var component=this;component.$('.time').keyup(function(){var hours=component.get('hours');var mins=component.get('mins');component.set('isValidtime',(0,_gooruWebUtilsUtils.validateTimespent)(hours,mins));component.set('isTyping',true);});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isDisableTimeEditor
   */isDisableTimeEditor:true, /**
   * @property {Boolean} isTimeEntered
   */isTimeEntered:false, /**
   * @property {Boolean} isValidScore
   */isValidtime:null, /**
   * @property {Boolean} isStarted
   */isStarted:null, /**
   * @property {String} time
   */time:'', /**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}), /**
   * @property {String} contentType
   */contentType:'collection-external', // -------------------------------------------------------------------------
// Methods
/**
   * @function getDataParams
   * Method to get structured data params which needs to be pass with post API
   */getDataParams:function getDataParams(){var component=this;var hours=component.get('hours');var mins=component.get('mins');var mapLocation=component.get('mapLocation');var context=mapLocation.get('context');var userId=component.get('session.userId');var dataParams={user_id:userId,class_id:context.get('classId') || null,course_id:context.get('courseId') || null,unit_id:context.get('unitId') || null,lesson_id:context.get('lessonId') || null,collection_type:'collection-external',external_collection_id:context.get('collectionId'),collection_id:context.get('collectionId'),session_id:(0,_gooruWebUtilsUtils.generateUUID)(),partner_id:component.get('session.partnerId') || null,tenant_id:component.get('session.tenantId') || null,content_source:component.get('source') || null,path_id:context.get('pathId') || 0,path_type:context.get('pathType') || null,time_spent:component.roundMilliseconds(hours,mins),time_zone:component.get('timeZone'),evidence:[{TBD:'True'}]};return dataParams;}, /**
   * @function updateSelfReport
   * Method to update score of an external assessment
   */updateSelfReport:function updateSelfReport(){var component=this;var analyticsService=component.get('analyticsService');var dataParams=component.getDataParams();component.set('timeSpent',dataParams.time_spent);if(component.get('session.role') === 'student'){var selfReportedPromise=analyticsService.studentSelfReporting(dataParams);component.set('time','');_ember['default'].RSVP.hash({selfReport:selfReportedPromise}).then(function(){component.set('time',component.getEnteredTime());})['catch'](function(){component.set('time',null);});}else {component.set('time',component.getEnteredTime());}}, /**
   * @function roundMilliseconds
   * Method to round milliseconds
   */roundMilliseconds:function roundMilliseconds(){var hour=arguments.length <= 0 || arguments[0] === undefined?0:arguments[0];var mins=arguments.length <= 1 || arguments[1] === undefined?0:arguments[1];var timeSpentInMilliSec=(hour * 60 * 60 + mins * 60) * 1000;return timeSpentInMilliSec;}, /**
   * @function getEnteredTime
   * Method to get entered score after update
   */getEnteredTime:function getEnteredTime(){var component=this;var isStarted=component.get('isStarted');var time=undefined;if(isStarted){var hours=component.get('hours') || 0;var mins=component.get('mins') || 0;time = hours + ' h ' + mins + ' m';}return time;}, /**
   * @function resetProperties
   * Method to reset component Properties
   */resetProperties:function resetProperties(){var component=this;this._super.apply(this,arguments);component.set('time','');component.set('isTimeEntered',false);component.set('isStarted',false);component.set('isDisableTimeEditor',true);component.set('isValidtime',false);component.set('isShowActivityFeedback',false);}, /**
   * Redirect to right path
   */redirectTo:function redirectTo(){var component=this;var context=component.get('mapLocation.context');var source=component.get('source');if(context.get('classId') && source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP){component.get('router').transitionTo('student.class.course-map',context.get('classId'),{queryParams:{refresh:true}});}else if(context.get('classId') && source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS){component.get('router').transitionTo('student.class.class-activities',context.get('classId'));}else {component.get('router').transitionTo('student.independent.course-map',context.get('courseId'),{queryParams:{refresh:true}});}}});});