define('gooru-web/utils/math',['exports','ember'],function(exports,_ember){exports.roundFloat = roundFloat;exports.isNumeric = isNumeric;exports.isDecimal = isDecimal;exports.sumAll = sumAll;exports.average = average;exports.findPercentage = findPercentage; /**
 * Rounds a float number and fixes the decimals to a specified number of digits.
 * @param n
 * @param decimals by default is 0
 * @returns {Number}
 */function roundFloat(n){var decimals=arguments.length <= 1 || arguments[1] === undefined?0:arguments[1];var rounded=(Math.round(n * 10) / 10).toFixed(decimals);return parseFloat(rounded);} /**
 * Determines if a parameter is of type numeric
 * @see http://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
 * @param n
 * @returns {bool}
 */function isNumeric(n){return !isNaN(parseFloat(n)) && isFinite(n);} /**
 * Determines if a parameter is of a decimal number
 * @param n
 * @returns {bool}
 */function isDecimal(n){return roundFloat(n) === n;} /**
 * Sums all values
 * @param {number[]} values
 * @returns {number}
 */function sumAll(values){return values.reduce(function(a,b){return a + b;});} /**
 * Computes the average value from a set of values
 * @param {number[]} values - array of numbers
 * @returns {number}
 */function average(values){var result=null;if(typeof values.reduce === 'function' && values.length){var sum=sumAll(values);result = sum?sum / values.length:sum;}else {_ember['default'].Logger.warn('Unable to compute average on param: ',values);}return result?roundFloat(result):result;} /**
 * Method to calculate percentage from given number
 * @param {Number} wholeNumber
 * @param {Number} givenNumber
 * @return {Number} percentage value
 */function findPercentage(wholeNumber,givenNumber){var percentage=0;percentage = wholeNumber && givenNumber?givenNumber / wholeNumber * 100:0;return percentage;}});