define('gooru-web/components/content/courses/play/gru-accordion-course',['exports','ember','gooru-web/mixins/content/builder'],function(exports,_ember,_gooruWebMixinsContentBuilder){ /**
 * Course content viewer: Accordion Course
 *
 * Component responsible for listing a set of units
 *
 * @module
 * @augments Ember/Component
 * @mixes mixins/content/builder
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsContentBuilder['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['content','courses','gru-accordion-course','gru-accordion'], // -------------------------------------------------------------------------
// Actions
actions:{expandUnit:function expandUnit(unitId,expanded){var component=this;if(expanded){component.sendAction('onLocationChange',unitId);}else {component.sendAction('onLocationChange',undefined);}component.closeAllUnits();},expandLesson:function expandLesson(unitId,lessonId,expanded){var component=this;if(expanded){component.sendAction('onLocationChange',unitId,lessonId);}else {component.sendAction('onLocationChange',unitId,undefined);}},closeAllUnits:function closeAllUnits(){this.closeAllUnits();}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {string}
   */selectedLessonId:null, /**
   * @property {string} action name when the location is changed
   */onLocationChange:null, // -------------------------------------------------------------------------
// Methods
closeAllUnits:function closeAllUnits(){this.get('items').forEach(function(builderItem){builderItem.set('isExpanded',false);});}});});