define('gooru-web/controllers/student/class/dashboard',['exports','ember','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // ----------------------------------------------------------------------
// Dependencies
studentClassController:_ember['default'].inject.controller('student.class'),session:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/analytics
   */analyticsService:_ember['default'].inject.service('api-sdk/analytics'), /**
   * Rescope Service to perform rescope data operations
   */rescopeService:_ember['default'].inject.service('api-sdk/rescope'), // ----------------------------------------------------------------------
// Properties
/**
   * A link to the content visibility from class controller
   * @see controllers/class.js
   * @property {ClassContentVisibility}
   */contentVisibility:_ember['default'].computed.alias('studentClassController.contentVisibility'),playerContent:null,isOpenPlayer:false,isShowStudentProgressReport:false,userId:_ember['default'].computed.alias('session.userId'),resetPerformance:false, /**
   * @type {Boolean}
   * Property to check whether a class is rescoped
   */isPremiumClass:_ember['default'].computed('currentClass',function(){var controller=this;var currentClass=controller.get('currentClass');var setting=currentClass.get('setting');return setting?setting['course.premium']:false;}), // -------------------------------------------------------------------------
// Observers
/**
   * Observer current class
   */observeCurrentClass:_ember['default'].observer('currentClass','isPremiumClass',function(){var controller=this; //Initially load rescope data
if(controller.get('isPremiumClass')){controller.processSkippedContents();}}), // -----------------------------------------------------------------------
// Hooks
// ----------------------------------------------------------------------
// Actions
actions:{playContent:function playContent(playerUrl,content){var controller=this;controller.set('playerUrl',playerUrl);controller.set('isOpenPlayer',true);controller.set('playerContent',content);},closePullUp:function closePullUp(){var component=this;component.set('refreshData',true);component.set('isOpenPlayer',false);component.set('resetPerformance',true);component.getUserCurrentLocation();component.get('studentClassController').send('reloadData');},openProgressReport:function openProgressReport(){this.set('isShowStudentProgressReport',true);}, /**
     * Action triggered when the user click an accordion item
     */onSelectItem:function onSelectItem(){var controller=this;if(controller.get('isPremiumClass')){var skippedContents=controller.get('skippedContents');var isSkippedContentsAvailable=skippedContents?controller.isSkippedContentsEmpty(skippedContents):false;if(isSkippedContentsAvailable){controller.toggleSkippedContents(skippedContents);}}}}, // ----------------------------------------------------------------------
// Methods
/**
   * @function processSkippedContents
   * Method to fetch and process the skipped contents
   */processSkippedContents:function processSkippedContents(){var controller=this;controller.getSkippedContents().then(function(skippedContents){var isContentAvailable=!!skippedContents;var isSkippedContentAvailable=skippedContents?controller.isSkippedContentsEmpty(skippedContents):false;controller.set('isContentAvailable',isContentAvailable);if(isSkippedContentAvailable){controller.set('isChecked',false);controller.toggleSkippedContents(skippedContents);}if(!skippedContents){controller.set('isChecked',true);}});}, /**
   * @function getSkippedContents
   * Method to get skipped contents
   */getSkippedContents:function getSkippedContents(){var controller=this;var currentClass=controller.get('currentClass');var filter={classId:currentClass.get('id'),courseId:currentClass.get('courseId')};var skippedContentsPromise=_ember['default'].RSVP.resolve(controller.get('rescopeService').getSkippedContents(filter));return _ember['default'].RSVP.hash({skippedContents:skippedContentsPromise}).then(function(hash){controller.set('skippedContents',hash.skippedContents);return hash.skippedContents;})['catch'](function(){controller.set('skippedContents',null);});}, /**
   * @function isSkippedContentsEmpty
   * Method to toggle rescoped content visibility
   */isSkippedContentsEmpty:function isSkippedContentsEmpty(skippedContents){var keys=Object.keys(skippedContents);var isContentAvailable=false;keys.some(function(key){isContentAvailable = skippedContents['' + key].length > 0;return isContentAvailable;});return isContentAvailable;}, /**
   * @function toggleSkippedContents
   * Method to toggle skippedContents
   */toggleSkippedContents:function toggleSkippedContents(skippedContents){var controller=this;var skippedUnitContents=controller.get('skippedContents.units');var units=controller.get('units');var isSkippedAllUnits=units.length === skippedUnitContents.length;if(isSkippedAllUnits){(function(){var skippedUnits=_ember['default'].A([]);skippedUnitContents.map(function(unitId){var skippedUnit=units.findBy('id',unitId);skippedUnits.pushObject(skippedUnit);});isSkippedAllUnits = units.length === skippedUnits.length;})();}else {units.map(function(unit){skippedUnitContents.map(function(unitId){var isSkipped=unit.get('id') === unitId;unit.set('isSkipped',isSkipped);});});}controller.set('isSkippedAllUnits',isSkippedAllUnits);var contentTypes=Object.keys(skippedContents);var formattedContents=controller.getFormattedContentsByType(skippedContents,contentTypes);controller.toggleContentVisibility(formattedContents);}, /**
   * @function getFormattedContentsByType
   * Method to get formatted content type
   */getFormattedContentsByType:function getFormattedContentsByType(contents,types){var controller=this;var formattedContents=_ember['default'].A([]);types.map(function(type){var flag=type.charAt(0);formattedContents = formattedContents.concat(controller.parseSkippedContents(contents['' + type],flag));});return formattedContents;}, /**
   * @function parseSkippedContents
   * Method to parse fetched rescoped contents
   */parseSkippedContents:function parseSkippedContents(contentIds,flag){var parsedContentIds=_ember['default'].A([]);contentIds.map(function(id){parsedContentIds.push('.' + flag + '-' + id);});return parsedContentIds;}, /**
   * @function toggleContentVisibility
   * Method to toggle content visibility
   */toggleContentVisibility:function toggleContentVisibility(contentClassnames){var controller=this;var isChecked=controller.get('isChecked');var $contentComponent=_ember['default'].$(contentClassnames.join());if(isChecked){$contentComponent.show().addClass('rescoped-content');}else {$contentComponent.hide();}},getUserCurrentLocation:function getUserCurrentLocation(){var component=this;var currentClass=component.get('currentClass');var classId=currentClass.get('id');var userId=component.get('userId');var courseId=component.get('course.id');var locationQueryParam={courseId:courseId};if(currentClass.milestoneViewApplicable && currentClass.milestoneViewApplicable === true && currentClass.preference && currentClass.preference.framework){locationQueryParam.fwCode = currentClass.preference.framework;}component.get('analyticsService').getUserCurrentLocation(classId,userId,locationQueryParam).then(function(userLocationObj){component.set('userLocation',userLocationObj);});}});});