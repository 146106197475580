define('gooru-web/instance-initializers/gooru-session-service',['exports','ember','gooru-web/config/environment','gooru-web/utils/utils'],function(exports,_ember,_gooruWebConfigEnvironment,_gooruWebUtilsUtils){exports.initialize = initialize; /**
 * Initialize session service
 */function initialize(application){var sessionService=application.lookup('service:session');var quizzesConfigurationService=application.lookup('service:quizzes/configuration');var configurationService=application.lookup('service:configuration');var configuration=configurationService.configuration;sessionService.reopen({ /**
     * @property {string} Session token
     */token:_ember['default'].computed.alias('data.authenticated.token'), // TODO This property should be removed once API 2.0 is not needed anymore
/**
     * @property {string} Session Token coming from API 3.0
     */'token-api3':_ember['default'].computed.alias('data.authenticated.token-api3'), /**
     * @property {string} Session user data
     */userData:_ember['default'].computed.alias('data.authenticated.user'), /**
     * @property {string} Session user data
     */cdnUrls:_ember['default'].computed.alias('data.authenticated.cdnUrls'), /**
     * @property {string} Session user id
     */userId:_ember['default'].computed('userData',function(){return this.get('userData')?this.get('userData').gooruUId:undefined;}), /**
     * @property {string} Session user id
     */role:_ember['default'].computed('userData',function(){var isStudent=(0,_gooruWebUtilsUtils.isSwitchedLearner)(this.get('userId'));if(isStudent){return 'student';}return this.get('userData.role');}),isGuest:_ember['default'].computed.alias('data.authenticated.isGuest'), /**
     * @property {string} Session user id
     */enabledVideoConference:_ember['default'].computed.alias('data.authenticated.tenant.settings.enabledVideoConference'), /**
     * @property {boolean} Indicates if the session is for an anonymous user
     */isAnonymous:_ember['default'].computed('data.authenticated',function(){return this.get('data.authenticated').isAnonymous;}), /**
     * @property {string} session tenant id
     */tenantId:_ember['default'].computed.alias('data.authenticated.tenant.tenantId'), /**
     * Property used to identify client is gooru or not
     * @return {Boolean}
     */isGooruClientId:_ember['default'].computed('tenantId',function(){var apiConfig=configuration && configuration.API_CONFIG?configuration.API_CONFIG:_gooruWebConfigEnvironment['default']['API-3.0'];var clientId=apiConfig?apiConfig.clientId:null;var tenantId=this.get('tenantId');return tenantId?clientId === tenantId:true;}),isDemoTenant:false, /**
     * @property {string} session partner id
     */partnerId:_ember['default'].computed.alias('data.authenticated.partnerId'), /**
     * @property {Object} tenantSetting hold the tenant settings details
     */tenantSetting:_ember['default'].computed.alias('data.authenticated.tenant.settings'), /**
     * @property {string} session tenant image url
     */tenantLogoUrl:_ember['default'].computed.alias('data.authenticated.tenant.imageUrl'), /**
     * @property {string} session tenant name
     */tenantName:_ember['default'].computed.alias('data.authenticated.tenant.tenantName'), /**
     * @property {string} session tenant short name
     */tenantShortName:_ember['default'].computed.alias('data.authenticated.tenant.tenantShortName'), /**
     * @property {string} session is contentnav only
     */isContentNavOnly:_ember['default'].computed.alias('data.authenticated.tenant.isContentNavOnly'),isInstructionalCoache:_ember['default'].computed('data.authenticated',function(){if(this.get('data.authenticated') && this.get('data.authenticated.permissions')){return this.get('data.authenticated.permissions').includes('VIEW_TEACHER_CLASSES');}}), /**
     * This method authenticates using the default authenticator for an anonymous user
     * @returns {*|Ember.RSVP.Promise}
     */authenticateAsAnonymous:function authenticateAsAnonymous(nonce){return this.authenticate('authenticator:auth-api-3',{isAnonymous:true,nonce:nonce});}, /**
     * This method authenticates using the default authenticator for an anonymous user
     * @returns {*|Ember.RSVP.Promise}
     */authenticateAsAnonymousWithData:function authenticateAsAnonymousWithData(data){return this.authenticate('authenticator:auth-api-3',{hasUserData:true,user:data});}, /**
     * Checks for changes at token-api3
     * @observer
     */tokenObserver:_ember['default'].observer('token-api3',function(){if(quizzesConfigurationService){quizzesConfigurationService.setToken(this.get('token-api3'));quizzesConfigurationService.setCdnUrl(this.get('cdnUrls.content'));}})});}exports['default'] = {name:'gooru-session-service',after:'ember-simple-auth',initialize:initialize};});