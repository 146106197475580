define('gooru-web/routes/reports/student-open-ended-summary',['exports','ember','gooru-web/models/result/context','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsResultContext,_gooruWebConfigConfig){ /**
 *
 * Summary data report of Rubric Grading for an OE Question
 * Gathers and passes the necessary information to the controller
 *
 * @module
 * @augments ember/Route
 */exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Ember.Service} Service to retrieve an assessment result
   */userSessionService:_ember['default'].inject.service('api-sdk/user-session'), /**
   * @type {RubricService} Service to retrieve rubric information
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * @type {ProfileService} Service to retrieve question information
   */questionService:_ember['default'].inject.service('api-sdk/question'), /**
   * @property {Ember.Service} Service to retrieve an assessment result
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Returns to Analytics Report
     * @function actions:goBack
     */goBack:function goBack(){var route=this;var controller=route.get('controller');var context=controller.get('context');var role='teacher';if(controller.get('isStudent')){role = 'student';}var queryParams={collectionId:context.get('collectionId'),type:context.get('collectionType'),userId:context.get('userId'),classId:context.get('classId'),sessionId:context.get('sessionId'),courseId:context.get('courseId'),unitId:context.get('unitId'),lessonId:context.get('lessonId'),role:role,backUrl:controller.get('backUrl')};route.transitionTo('reports.student-collection-analytics',{queryParams:queryParams});}}, // -------------------------------------------------------------------------
// Methods
/**
   * Get model for the controller
   */model:function model(params){var route=this;var context=route.getContext(params);var classId=params.classId;var courseId=params.courseId;var collectionId=params.collectionId;var questionId=params.questionId;var sessionId=params.sessionId;var studentId=params.studentId;var questionPromise=route.get('questionService').readQuestion(questionId);var summaryPromise=route.get('rubricService').getRubricQuestionSummary(studentId,classId,courseId,collectionId,questionId,sessionId);return _ember['default'].RSVP.hash({question:questionPromise,context:context,summary:summaryPromise,sessionId:sessionId});},afterModel:function afterModel(model){var route=this;var context=model.context;var questionId=model.question.get('id');var isCollection=model.context.collectionType === _gooruWebConfigConfig.CONTENT_TYPES.COLLECTION;var session=!isCollection?model.sessionId:null;if(session){context.set('sessionId',session);}var performanceService=route.get('performanceService');return performanceService.findAssessmentResultByCollectionAndStudent(context).then(function(assessmentResult){var questionResults=assessmentResult.resourceResults;var questionResult=questionResults.findBy('resourceId',questionId);model.questionResult = questionResult;});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){controller.set('questionText',model.question.get('description') || model.question.get('title'));controller.set('questionThumbnail',model.question.get('thumbnail'));controller.set('question',model.question);controller.set('rubric',model.question.get('rubric'));controller.set('questionSummary',model.summary);controller.set('context',model.context);controller.set('questionResult',model.questionResult);}, /**
   * Get the player context
   * @param params
   * @returns {Context}
   */getContext:function getContext(params){var collectionType=params.collectionType;var classId=params.classId;var courseId=params.courseId;var collectionId=params.collectionId;var userId=params.studentId;var unitId=params.unitId;var lessonId=params.lessonId;return _gooruWebModelsResultContext['default'].create({collectionType:collectionType,userId:userId,collectionId:collectionId,courseId:courseId,classId:classId,unitId:unitId,lessonId:lessonId});}});});