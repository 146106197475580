define('gooru-web/components/player/gru-suggest-test',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['player','gru-suggest-test'],actions:{playCollection:function playCollection(){this.set('disabledButtons',true);this.sendAction('onPlayCollection');},playSuggestion:function playSuggestion(){this.set('disabledButtons',true);this.sendAction('onPlaySuggestion');}}, // -------------------------------------------------------------------------
// Properties
/**
   * Suggestion type
   * @param {String} (pre-test/post-test/backfill-pretest)
   */type:'', /**
   * Indicate if the suggestion is a back fill after a pre test
   * @param {Boolean} isBackFill
   */isBackFill:_ember['default'].computed.equal('type',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.BACKFILL), /**
   * Indicate if the suggestion is a benchmark after a post test
   * @param {Boolean} isBenchmark
   */isBenchmark:_ember['default'].computed.equal('type',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.BENCHMARK), /**
   * Indicate if the suggestion is a resource
   * @param {Boolean} isResource
   */isResource:_ember['default'].computed.equal('type',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.RESOURCE), /**
   * Indicate if the suggestion is a Signature Collection
   * @param {Boolean} isResource
   */isSignatureCollection:_ember['default'].computed.equal('type',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.SIGNATURE_COLLECTION), /**
   * Indicate if the suggestion is a Signature Assesment
   * @param {Boolean} isResource
   */isSignatureAssessment:_ember['default'].computed.equal('type',_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.SIGNATURE_ASSESSMENT), /**
   * @param {Assessment} assessment
   */assessment:null, /**
   * Disables next and no thanks buttons
   * @property {Boolean} disabledButtons
   */disabledButtons:false, /**
   * @property {Number} Resource count
   */resourceCount:_ember['default'].computed('assessment.resources',function(){return this.get('assessment.resources').filter(function(item){return item.get('isResource');}).length;}), /**
   * Suggested assessment
   * @param {Assessment/Collection} suggestion
   */suggestion:null, /**
   * @property {Number} Question count
   */questionCount:_ember['default'].computed('assessment.resources',function(){return this.get('assessment.resources').filter(function(item){return !item.get('isResource');}).length;})});});