define('gooru-web/components/impersonate/student-impersonate',['exports','ember','gooru-web/config/environment'],function(exports,_ember,_gooruWebConfigEnvironment){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['student-impersonate'], // -------------------------------------------------------------------------
// Properties
studentImpersonateFrame:false,impersonateUrl:'', // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service(), /**
   * @property {Ember.Service} Session service
   */sessionService:_ember['default'].inject.service('api-sdk/session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){_ember['default'].$('#impersonate-frame').on('load',function(){_ember['default'].$('.student-impersonate #impersonate-frame').contents().find('body').addClass('frame-app-readyonly');});},didDestroyElement:function didDestroyElement(){window.impersonate = false;_ember['default'].$('.student-impersonate #impersonate-frame').contents().find('body').removeClass('frame-app-readyonly');var rootElement=_ember['default'].$(_gooruWebConfigEnvironment['default'].rootElement);rootElement.removeClass('app-readonly-mode');}, // -------------------------------------------------------------------------
// Actions
actions:{onClosePullUp:function onClosePullUp(){var rootElement=_ember['default'].$(_gooruWebConfigEnvironment['default'].rootElement);rootElement.removeClass('app-readonly-mode');this.set('studentImpersonateFrame',false);}}});});