define('gooru-web/serializers/performance/collection-performance-summary',['exports','ember','gooru-web/models/performance/collection-performance-summary'],function(exports,_ember,_gooruWebModelsPerformanceCollectionPerformanceSummary){ /**
 * Serializer to support the CollectionPerformanceSummary CRUD operations
 *
 * @typedef {Object} CollectionPerformanceSummary
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Normalize an array of CollectionPerformanceSummary
   *
   * @param payload endpoint response format in JSON format
   * @returns {CollectionPerformanceSummary[]}
   */normalizeAllCollectionPerformanceSummary:function normalizeAllCollectionPerformanceSummary(payload){var serializer=this;if(payload.content && payload.content.length > 0){if(_ember['default'].isArray(payload.content[0].usageData)){return payload.content[0].usageData.map(function(collectionPerformanceSummary){return serializer.normalizeCollectionPerformanceSummary(collectionPerformanceSummary);});}else {return [];}}else {return [];}},normalizeAllILCollectionPerformanceSummary:function normalizeAllILCollectionPerformanceSummary(payload){var serializer=this;if(payload.usageData && payload.usageData.length > 0){if(_ember['default'].isArray(payload.usageData)){return payload.usageData.map(function(collectionPerformanceSummary){return serializer.normalizeCollectionPerformanceSummary(collectionPerformanceSummary);});}else {return [];}}else {return [];}}, /**
   * Normalize a CollectionPerformanceSummary
   * @param {*} data
   * @return {CollectionPerformanceSummary}
   */normalizeCollectionPerformanceSummary:function normalizeCollectionPerformanceSummary(data){return _gooruWebModelsPerformanceCollectionPerformanceSummary['default'].create({id:data.collectionId || data.collection_id,collectionId:data.collectionId || data.collection_id,timeSpent:data.timeSpent,attempts:data.attempts,pathId:data.pathId,views:data.views,score:data.scoreInPercentage,sessionId:data.lastSessionId || data.sessionId,status:data.status,isGraded:data.is_graded});}, /**
   * Normalized collections performance data for lesson.
   * @return {Array}
   */normalizeCollectionsPerformanceDataForLesson:function normalizeCollectionsPerformanceDataForLesson(response){var resultSet=_ember['default'].A();if(response.content !== undefined && response.content.length > 0){response = _ember['default'].A(response.content);response.forEach(function(data){var result=_ember['default'].Object.create(data);var usageData=result.get('usageData');if(usageData && usageData.length > 0){usageData.forEach(function(data){var collectionPerformance=_ember['default'].Object.create({performance:_ember['default'].Object.create({timeSpent:data.timeSpent,scoreInPercentage:data.scoreInPercentage,attempt:data.attempts?data.attempts:null}),collectionId:data.collectionId || data.assessmentId,userUid:result.get('userUid')});resultSet.pushObject(collectionPerformance);});}});}return resultSet;}});});