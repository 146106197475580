define('gooru-web/routes/student/independent/course-map',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), /**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), /**
   * @requires service:api-sdk/learner
   */learnerService:_ember['default'].inject.service('api-sdk/learner'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Open the player with the specific collection/assessment
     *
     * @function actions:playItem
     * @param {string} unitId - Identifier for a unit
     * @param {string} lessonId - Identifier for lesson
     * @param {string} item - collection, assessment, lesson or resource
     */studyPlayer:function studyPlayer(type,unitId,lessonId,item){var route=this;var currentCourse=route.modelFor('student.independent').course;var courseId=currentCourse.get('id');if(type === 'lesson'){route.startLessonStudyPlayer(courseId,unitId,lessonId,item);}else if(type === 'resource'){route.startResourceStudyPlayer(null,courseId,item);}else {route.startCollectionStudyPlayer(courseId,unitId,lessonId,item);}}}, // -------------------------------------------------------------------------
// Methods
model:function model(){var route=this;var userId=route.get('session.userId');var course=this.modelFor('student.independent').course;var units=course.get('children') || [];var userLocation=route.get('learnerService').fetchLocationCourse(course.get('id'),userId);var unitList=route.fetchNonClassUnitPerformance(units);return _ember['default'].RSVP.hash({course:course,unitList:unitList,userLocation:userLocation});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){var userLocation='';if(model.userLocation){var unitId=model.userLocation.get('unitId');var lessonId=model.userLocation.get('lessonId');var collectionId=model.userLocation.get('collectionId');userLocation = unitId + '+' + lessonId + '+' + collectionId;}controller.set('userLocation',userLocation);controller.set('units',model.unitList);controller.set('course',model.course);controller.get('studentIndependentController').selectMenuItem('course-map');controller.init();},resetController:function resetController(controller){controller.set('tab',null);}, /**
   * Navigates to collection
   * @param {string} courseId
   * @param {string} unitId
   * @param {string} lessonId
   * @param {Collection} collection
   */startCollectionStudyPlayer:function startCollectionStudyPlayer(courseId,unitId,lessonId,collection){var route=this;var role=_gooruWebConfigConfig.ROLES.STUDENT;var source=_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.INDEPENDENT_ACTIVITY;var collectionId=collection.get('id');var collectionType=collection.get('collectionType');var queryParams={classId:null,unitId:unitId,lessonId:lessonId,collectionId:collectionId,role:role,source:source,type:collectionType,isIframeMode:true};this.get('navigateMapService').startCollection(courseId,unitId,lessonId,collectionId,collectionType).then(function(){route.controller.set('playerUrl',route.get('router').generate('study-player',courseId,{queryParams:queryParams}));route.controller.set('isOpenPlayer',true);route.controller.set('playerContent',collection);});}, /**
   * Navigates to the next lesson collection
   * @param {string} courseId
   * @param {string} unitId
   * @param {string} lessonId
   */startLessonStudyPlayer:function startLessonStudyPlayer(courseId,unitId,lessonId,collection){var route=this;var role=_gooruWebConfigConfig.ROLES.STUDENT;var queryParams={unitId:unitId,lessonId:lessonId,role:role,source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.INDEPENDENT_ACTIVITY,isIframeMode:true};this.get('navigateMapService').startLesson(courseId,unitId,lessonId).then(function(){route.controller.set('playerUrl',route.get('router').generate('study-player',courseId,{queryParams:queryParams}));route.controller.set('isOpenPlayer',true);route.controller.set('playerContent',collection);});}, /**
   * Navigates to resourse
   * @param {string} classId
   * @param {string} courseId
   * @param {Resource} resource
   */startResourceStudyPlayer:function startResourceStudyPlayer(classId,courseId,resource){var route=this;var queryParams={unitId:resource.get('unitId'),lessonId:resource.get('lessonId'),collectionId:resource.get('assessmentId'),source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP,pathId:resource.get('pathId')};route.get('navigateMapService').startResource(courseId,queryParams.unitId,queryParams.lessonId,queryParams.collectionId,resource.get('id'),queryParams.pathId,classId).then(function(){if(classId){queryParams.classId = classId;}route.transitionTo('resource-player',courseId,resource.id,{queryParams:queryParams});});},fetchNonClassUnitPerformance:function fetchNonClassUnitPerformance(units){var route=this;var currentCourse=route.modelFor('student.independent').course;var courseId=currentCourse.get('id');return _ember['default'].RSVP.hash({unitsPerformance:route.get('learnerService').fetchPerformanceCourse(courseId,_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT)}).then(function(_ref){var unitsPerformance=_ref.unitsPerformance;return new _ember['default'].RSVP.Promise(function(resolve){resolve(route.renderUnitsPerformance(units,unitsPerformance));});});},renderUnitsPerformance:function renderUnitsPerformance(units,unitsPerformance){var unitList=_ember['default'].A([]);units.forEach(function(unit){var unitCopy=unit.copy();var unitPerformance=unitsPerformance.findBy('unitId',unit.get('id'));unitCopy.set('performance',unitPerformance);unitList.pushObject(unitCopy);});return unitList;}});});