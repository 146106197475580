define('gooru-web/components/grading/gru-rubric-panel',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-rubric-panel'],classNameBindings:['showFullRubric:full-rubric'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Change tabs to grading or rubric
     */selectTab:function selectTab(tabName){this.set('tab',tabName);}, /**
     * Hide/show full rubric
     */showFullRubric:function showFullRubric(){this.set('showFullRubric',!this.get('showFullRubric'));}, /**
     * Load the next student
     */loadNext:function loadNext(){this.sendAction('onLoadNext');}, /**
     * Load the previous student
     */loadPrevious:function loadPrevious(){this.sendAction('onLoadPrevious');}, /**
     * Submit a grade
     */submitGrade:function submitGrade(){this.sendAction('onSubmitGrade');}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);if(this.get('grade')){this.set('tab','grading');}else if(this.get('answer')){this.set('tab','answer');}else {this.set('tab','rubric');}}, // -------------------------------------------------------------------------
// Properties
/**
   * Answer values
   * @property {String} answer
   */answer:null, /**
   * Grade values
   * @property {Grade} grade
   */grade:null, /**
   * If answer is selected
   * @property {Boolean} isAnswer
   */isAnswer:_ember['default'].computed.equal('tab','answer'), /**
   * If grading is selected
   * @property {Boolean} isGrading
   */isGrading:_ember['default'].computed.equal('tab','grading'), /**
   * If rubric is selected
   * @property {Boolean} isRubric
   */isRubric:_ember['default'].computed.equal('tab','rubric'), /**
   * Action to send when a grade is submitted
   * @property {String} onSubmitGrade
   */onSubmitGrade:false, /**
   * Rubric to grade
   * @property {Rubric} rubric
   */rubric:null, /**
   * @property {Boolean} showFullRubric
   */showFullRubric:false, /**
   * @property {Boolean} showNavigation
   */showNavigation:true, /**
   * Current tab name selected
   * @property {String} tab
   */tab:'grading', /**
   * If the next button should be disabled
   * @property {boolean} isNextDisabled
   */isNextDisabled:false, /**
   * If the previous button should be disabled
   * @property {boolean} isPreviousDisabled
   */isPreviousDisabled:false});});