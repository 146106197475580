define('gooru-web/components/content/questions/answers/gru-hot-text-highlight',['exports','ember','gooru-web/models/content/answer','gooru-web/config/question'],function(exports,_ember,_gooruWebModelsContentAnswer,_gooruWebConfigQuestion){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-hot-text-highlight'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.setupAnswer();}, // -------------------------------------------------------------------------
// Actions
actions:{changeType:function changeType(type){var component=this;component.set('answers.firstObject.highlightType',type);_ember['default'].run(function(){var $textarea=component.$().find('.gru-textarea textarea');$textarea.focus().val($textarea.val() + ' ').trigger('blur'); // Forces the validation of the textarea
});}}, // -------------------------------------------------------------------------
// Properties
/**
   * Hot Spot Text Answers
   */answers:null, /**
   * Is in edit mode
   */editMode:false, /**
   * Indicates if the answer is for word selections
   */isHotTextHighlightWord:_ember['default'].computed('answers.firstObject.highlightType',function(){return this.get('answers.firstObject.highlightType') === 'word';}), /**
   * Indicates if the answer is for sentence selections
   */isHotTextHighlightSentence:_ember['default'].computed('answers.firstObject.highlightType',function(){return this.get('answers.firstObject.highlightType') === 'sentence';}), // -------------------------------------------------------------------------
// Methods
setupAnswer:function setupAnswer(){var component=this;if(component.get('editMode')){var answers=component.get('answers');if(answers.length === 0){answers.pushObject(_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(component).ownerInjection(),{isCorrect:true,type:'text',text:'',highlightType:_gooruWebConfigQuestion.QUESTION_CONFIG[_gooruWebConfigQuestion.QUESTION_TYPES.hotTextHighlight].defaultType}));}}}});});