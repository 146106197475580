define('gooru-web/components/proficiency/topic-info-pull-up',['exports','ember','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['topic-info-pull-up'], /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.openPullUp();component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});component.setupTooltip();}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action when click on close button
     */closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.sendAction('onCloseTopicInfoPullup');component.set('showPullUp',false);component.set('topic.isActive',false);component.set('topic',null);});}, /**
     * Action when click on competency title
     */onInspectCompetency:function onInspectCompetency(selectedCompetency){var component=this;component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_PROFICIENCY_CHARTS_COMPETENCIES);selectedCompetency = _ember['default'].Object.create(selectedCompetency);var selectedDomain=component.get('selectedDomain');var proficiencyChartData=component.get('proficiencyChartData');var domainCompetencyList=proficiencyChartData.filterBy('domainCode',selectedDomain.domainCode);var competencies=component.get('competencies') || domainCompetencyList;component.sendAction('onSelectCompetency',selectedCompetency,competencies);},onSelectDatamodel:function onSelectDatamodel(dataModel){this.set('isShowCompetencies',dataModel === 'proficiency');}},isShowCompetencies:true, /**
   * Maintains the value of popover position
   * @type {String}
   */tagPopoverDefaultPosition:'bottom', // -------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'0%'},400);},setupTooltip:function setupTooltip(){var component=this;var $anchor=this.$('.lo-content');var isMobile=window.matchMedia('only screen and (max-width: 768px)');var tagPopoverDefaultPosition=this.get('tagPopoverDefaultPosition');$anchor.attr('data-html','true');$anchor.popover({placement:tagPopoverDefaultPosition,content:function content(){return component.$('.tag-tooltip').html();},trigger:'manual',container:'body'});if(isMobile.matches){$anchor.on('click',function(){var $this=$(this);if(!$this.hasClass('list-open')){ // Close all tag tooltips by simulating a click on them
$('.topic-info-pull-up > .content.list-open').click();$this.addClass('list-open').popover('show');}else {$this.removeClass('list-open').popover('hide');}});}else {$anchor.on('mouseenter',function(){$(this).popover('show');});$anchor.on('mouseleave',function(){$(this).popover('hide');});}}});});