define('gooru-web/components/standard-dropdown',['exports','ember','gooru-web/utils/dropdown-item'],function(exports,_ember,_gooruWebUtilsDropdownItem){ /**
 * @typedef {object} StandardDropdown
 */exports['default'] = _ember['default'].Component.extend({ /**
   *
   * @property {string} size class
   * @see bootstrap button dropdown
   */'btn-group-size':'btn-group-lg', /**
   * @property {[]} standards
   */standards:_ember['default'].A(), /**
   * Triggered when selecting an standard from the dropdown
   * @property {string} standard selected action
   */onStandardSelectedAction:null, /**
   * @property {DropdownItem[]} items
   */dropdownItems:(function(){var standards=this.get('standards');return standards.map(function(standard){return _gooruWebUtilsDropdownItem['default'].create({data:standard,id:standard.get('id'),label:standard.get('name'),disabled:standard.get('disabled')});});}).property('standards'),actions:{onStandardSelected:function onStandardSelected(items){this.sendAction('onStandardSelectedAction',items);}}});});