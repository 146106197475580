define('gooru-web/components/class/activities/gru-class-activities-scope-and-sequence',['exports','ember','gooru-web/config/config','gooru-web/utils/utils'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({classNames:['gru-class-activities-scope-and-sequence'], // -------------------------------------------------------------------------
// Dependencies
scopeAndSequenceService:_ember['default'].inject.service('api-sdk/scope-sequence'), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Object} levelAndActionKeys help to define list of action name for each level
   */levelAndActionKeys:{gradesList:{actionKey:'fetchModulesByScopeId',levelKey:'modulesList'},modules:{actionKey:'fetchTopicsByModule',levelKey:'topicsList'},topics:{actionKey:'fetchCompeteciesByTopics',levelKey:'competenciesList'}}, /**
   * @property {Object} resourceType help to hold the list of audience
   */resourceType:_gooruWebConfigConfig.SCOPE_AND_SEQUENCE_RESOURCE_TYPE, /**
   * @property {Object} activeContentType it has active content type
   */activeContentType:null, /**
   * @property {Object} activeScopeAndSequence
   */activeScopeAndSequence:null,onChangeScopeAndSequence:_ember['default'].observer('activeScopeAndSequence',function(){this.lastStateContents();}), /**
   * @property {Array} contentSource is has list of active content source
   */contentSource:_ember['default'].computed.alias('activeSequence'),lasActiveCompetency:null,scopeSeqState:null, // -------------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.lastStateContents();},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{ // Action tigger when click on each accordion
onSelectLevel:function onSelectLevel(levelActionKey,level,params,key,topicList){if(key){topicList.map(function(topic){if(topic.id !== level.id){topic.set('isActive',false);}});}var scopeSeqState=this.get('scopeSeqState') || {};scopeSeqState[levelActionKey.levelKey] = level.id;this.set('scopeSeqState',scopeSeqState);level.set('isActive',!level.get('isActive'));if(!level.get(levelActionKey.levelKey)){this.loadLevelData(levelActionKey,level,params);}}, // Action trigger on toggle competency accordion
onToggleCompetency:function onToggleCompetency(competency,idsList){var _this=this;if(!competency.get('isActive')){this.resetProperties('isActive').then(function(){_this.scrollIntoView(idsList.ids);});}this.set('scopeSeqState.lastState',competency.id);(0,_gooruWebUtilsUtils.setScopeAndSequenceState)(this.get('scopeSeqState'));competency.set('isActive',!competency.get('isActive'));if(competency.get('isActive') && !competency.get('hasContent')){this.loadActivitiesContent(competency);this.set('lasActiveCompetency',competency);}}, // Action trigger on clicking load more button
onLoadMore:function onLoadMore(competency,filterKey){this.loadActivitiesContent(competency,filterKey);}, // Action tigger on clicking show preview
onShowContentPreview:function onShowContentPreview(content){this.sendAction('onShowContentPreview',content);}, // Action trigger on click add class activities from right panel
onAddActivityPop:function onAddActivityPop(content){this.sendAction('onAddActivityPop',content);}, // Action trigger on clicking add activities from the popup
onAddActivity:function onAddActivity(content,startDate,endDate){this.sendAction('onAddActivity',content,startDate,endDate);}, // Action helps to maximize minimize the competency tab
maximizePageSize:function maximizePageSize(competency){competency.set('isZoomed',!competency.get('isZoomed'));}}, // ------------------------------------------------------------------------
// Methods
// Method help to load data for each level of accordion
loadLevelData:function loadLevelData(levelActionKey,level){var _this2=this;var params=arguments.length <= 2 || arguments[2] === undefined?null:arguments[2];var scopeService=this.get('scopeAndSequenceService');var pathParams=params.pathParams;var lastState=(0,_gooruWebUtilsUtils.getScopeAndSequenceState)();var queryParams=params.queryParams?params.queryParams:{}; // Here actionKey is the method name from the {scopeAndSequenceService} service file
scopeService[levelActionKey.actionKey](pathParams,queryParams).then(function(levelData){level.set('' + levelActionKey.levelKey,levelData);if(level && level.modulesList && level.modulesList.length){var activeModuleState=lastState?level.modulesList.findBy('id',lastState.competenciesList):null;var modulesList=activeModuleState || level.modulesList[0];var moduleId=modulesList.get('id');var ssId=level.get('id');var _pathParams={pathParams:{ssId:ssId,moduleId:moduleId}};var levelAndActionKeys=_this2.get('levelAndActionKeys').modules;_this2.send('onSelectLevel',levelAndActionKeys,modulesList,_pathParams);}if(level && level.topicsList && level.topicsList.length){var activeTopicState=lastState?level.topicsList.findBy('id',lastState.competenciesList):null;var topicsList=activeTopicState || level.topicsList[0];var topicId=topicsList.get('id');var ssId=level.get('id');var pathParamsTopics={pathParams:{ssId:ssId,topicId:topicId}};var levelAndActionTopics=_this2.get('levelAndActionKeys').topics;_this2.send('onSelectLevel',levelAndActionTopics,topicsList,pathParamsTopics);}if(level && level.competenciesList && level.competenciesList.length){var activeCompState=lastState?level.competenciesList.findBy('id',lastState.lastState):null;var competenciesList=activeCompState || level.competenciesList[0];var domainIndex=0;var topicIndex=0;var compIndex=0;var idsList={ids:{domainIndex:domainIndex,topicIndex:topicIndex,compIndex:compIndex}};_this2.send('onToggleCompetency',competenciesList,idsList);}});}, // Method help to load activities content
loadActivitiesContent:function loadActivitiesContent(competency){var filterKey=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var resourceType=this.get('resourceType');var filterKeys=[filterKey];if(filterKey){competency.incrementProperty(resourceType[filterKey].name + 'Page');}if(!competency.get('hasContent')){competency.setProperties({teacherspage:0,studentspage:0,hasContent:true,teachersContent:_ember['default'].Object.create({}),studentsContent:_ember['default'].Object.create({}),studentsSkipedLevels:[],teachersSkipedLevels:[]});filterKeys = ['TEACHERS','STUDENTS'];}this.sendAction('onSelectCompetency',competency,filterKeys);}, // Method help to reset the properties
resetProperties:function resetProperties(propKey){var modulesList=this.get('activeScopeAndSequence.modulesList') || [];var promiseAll=modulesList.map(function(module){module.topicsList && module.topicsList.forEach(function(topics){topics.competenciesList && topics.competenciesList.filterBy('' + propKey,true).forEach(function(competency){competency.set('' + propKey,false);});});});return _ember['default'].RSVP.all(promiseAll);},scrollIntoView:function scrollIntoView(ids){setTimeout(function(){var container=$('.gru-class-activities-scope-and-sequence');var moduleId=$('#' + ids.moduleId + '-' + ids.topicId).position();if(moduleId && moduleId.top){var currentScroll=container.scrollTop();container.animate({scrollTop:currentScroll + top - 85},300);}},200);},lastStateContents:function lastStateContents(){var activeSequence=this.get('activeScopeAndSequence');if(activeSequence && !activeSequence.get('modulesList')){this.loadLevelData(this.get('levelAndActionKeys').gradesList,activeSequence,{pathParams:{ssId:activeSequence.get('scopeId')},queryParams:{gradeMasterId:activeSequence.get('id')}});}}});});