define('gooru-web/components/player/questions/gru-decoding-assessment',['exports','gooru-web/components/player/questions/gru-question','ember'],function(exports,_gooruWebComponentsPlayerQuestionsGruQuestion,_ember){ /**
 * Fill in the blank
 *
 * Component responsible for controlling the logic and appearance of a fill in the blank
 * question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-decoding-assessment'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Properties
showCorrect:false, /**
   * @property {Array} answers
   * List of answers
   */answers:_ember['default'].computed.alias('question.answers'),isShowPlay:false, /**
   * @property {Array} baseAnswers
   * List of base answers
   */baseAnswers:_ember['default'].computed.alias('question.answers'), /**
   * @property {Array} exemplars
   * List of question exemplars
   */exemplars:_ember['default'].computed.alias('question.exemplarDocs'), // -------------------------------------------------------------------------
// Events
initInputEvents:(function(){if(this.get('baseQuestion')){this.set('isShowPlay',true);var decodingExcercies=this.get('baseQuestion.answers').map(function(baseAnswer){return {answer_text:baseAnswer.get('text')};});this.injectDecoding(decodingExcercies);}}).on('didInsertElement'), // -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
injectDecoding:function injectDecoding(decodingExcercies){var user={userId:'userId'};var accessibilitySettings=JSON.parse(window.localStorage.getItem('accessibility_settings'));var content={contentId:'contentId',contentTitle:'contentTitle',answers:decodingExcercies,isHighContrast:accessibilitySettings && accessibilitySettings.is_high_contrast_enabled?accessibilitySettings.is_high_contrast_enabled:false};window.serp.languageDecode().select('#serp-decoding-answer-container').dataIn(user,null,content).decoding().render().listener(function(){return;});},actions:{onPlayAudio:function onPlayAudio(container,url){var component=this;var _audio=new Audio(url);_audio.play();_audio.ontimeupdate = function(){component.$('.answer-container .' + container + ' .audio-progress .progress-filling').css('width',_audio.currentTime / _audio.duration * 100 + '%');};}}});});