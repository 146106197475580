define('gooru-web/routes/teacher/class/course-map',['exports','ember','gooru-web/utils/utils','gooru-web/config/parse-event','gooru-web/mixins/ui-helper-mixin','gooru-web/config/config'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigParseEvent,_gooruWebMixinsUiHelperMixin,_gooruWebConfigConfig){function _toConsumableArray(arr){if(Array.isArray(arr)){for(var i=0,arr2=Array(arr.length);i < arr.length;i++) arr2[i] = arr[i];return arr2;}else {return Array.from(arr);}}exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsUiHelperMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {Service} session service
   */session:_ember['default'].inject.service('session'), /**

  /**
   * @type {Service} performance service
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), /**

   * @type {Service} i18n
   */i18n:_ember['default'].inject.service(), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'), /**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {reportService} Service to retrieve report information
   */reportService:_ember['default'].inject.service('api-sdk/report'), /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), /**
   * @requires service:api-sdk/unit0Service
   */unit0Service:_ember['default'].inject.service('api-sdk/unit0'), // -------------------------------------------------------------------------
// Attributes
secondaryClass:null,queryParams:{activeStudentId:null}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Launch an assessment on-air
     *
     * @function actions:launchOnAir
     */launchOnAir:function launchOnAir(collectionId){var currentClass=this.modelFor('teacher.class')['class'];var classId=currentClass.get('id');this.transitionTo('reports.collection',classId,collectionId);}, /**
     * Open the player with the specific collection/assessment
     *
     * @function actions:playItem
     * @param {string} unitId - Identifier for a unit
     * @param {string} lessonId - Identifier for lesson
     * @param {string} collection - collection or assessment
     */playResource:function playResource(unitId,lessonId,collection){if(collection.get('isExternalAssessment')){window.open(collection.get('url'));}else {var currentClass=this.modelFor('teacher.class')['class'];var classId=currentClass.get('id');var courseId=currentClass.get('courseId');var role='teacher';this.transitionTo('context-player',courseId,unitId,lessonId,collection.get('id'),{queryParams:{role:role,type:collection.get('collectionType'),classId:classId}});}}, /**
     * Edit content action, when clicking Edit content on Class Overview
     * @param {Content/Course}
     */editContent:function editContent(id,classId){var queryParams={classId:classId};this.transitionTo('content.courses.edit',id,{queryParams:queryParams});var context={classId:classId,courseId:id,classTitle:this.modelFor('teacher.class')['class'].title};this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.EDIT_COURSE,context);},onSelectedClass:function onSelectedClass(secondaryClass){this.set('secondaryClass',secondaryClass);this.refresh();},didTransition:function didTransition(){ // Load Course performance summary only loading CM tab
this.get('controller').get('classController').loadCoursePerformanceSummary();}}, // -------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(){if(!this.get('secondaryClass')){var currentClass=this.modelFor('teacher.class')['class'];var userId=this.get('session.userId');if(currentClass.isTeacher(userId) && !currentClass.get('courseId')){this.transitionTo('teacher.class.add-course');}}},model:function model(params){var route=this;var secondaryClass=route.get('secondaryClass');if(secondaryClass){secondaryClass['class'].set('isSecondaryClass',true);}var currentClass=secondaryClass?secondaryClass['class']:route.modelFor('teacher.class')['class'];route.setTitle('Learning Journey - Course map',currentClass.title);var course=secondaryClass?secondaryClass.course:route.modelFor('teacher.class').course;var units=course.get('children') || [];var classMembers=currentClass.get('members');var subject=currentClass.get('preference.subject');var taxonomyService=route.get('taxonomyService');var courseId=currentClass.get('courseId');var classId=currentClass.get('id');var userId=this.get('session.userId');route.fetchUnitsPerformance(userId,classId,courseId,units);var unit0Promise=route.get('unit0Service').fetchUnit0({classId:classId,courseId:courseId});var fwCode=currentClass.get('preference.framework') || 'GUT';var milestonePromise=currentClass.get('milestoneViewApplicable')?route.get('courseService').getCourseMilestones(courseId,fwCode):_ember['default'].RSVP.resolve([]);var dataParam={classId:currentClass.get('id'),to:moment().format('YYYY-MM-DD')};var timeSpentPromise=route.get('reportService').fetchStudentsTimespentSummaryreport(dataParam);return _ember['default'].RSVP.hash({course:course,units:units,currentClass:currentClass,classMembers:classMembers,gradeSubject:subject?taxonomyService.fetchSubject(subject):{},milestones:milestonePromise,classMembersTimespent:timeSpentPromise,activeStudentId:params.activeStudentId || null,unit0Content:unit0Promise});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){var units=model.units;var hasUnit0Content=units.findBy('isUnit0',true);if(!hasUnit0Content){units = _ember['default'].A([].concat(_toConsumableArray(model.unit0Content),_toConsumableArray(model.units)));}controller.set('units',units);model.course.set('children',controller.get('units'));controller.set('course',model.course);controller.set('classMembers',model.classMembers);controller.set('currentClass',model.currentClass);controller.get('classController').selectMenuItem('course-map');controller.set('isStudentCourseMap',false);controller.set('gradeSubject',model.gradeSubject);controller.set('classController.gradeSubject',model.gradeSubject);controller.set('classController.isShowExpandedNav',true);controller.set('unit0Content',model.unit0Content);var milestones=model.milestones;var hasUnit0Milestone=milestones.findBy('isUnit0',true);if(!hasUnit0Milestone){milestones = _ember['default'].A([].concat(_toConsumableArray(model.unit0Content),_toConsumableArray(model.milestones)));}controller.set('teacherMilestones',(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(milestones));controller.set('studentMilestones',(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(milestones));controller.set('milestones',milestones);controller.set('classMembersTimespent',model.classMembersTimespent);controller.set('isShowGoLive',false);if(model.activeStudentId){var activeStudent=model.classMembers.findBy('id',model.activeStudentId);controller.send('onSelectStudent',activeStudent);controller.set('activeStudentId',model.activeStudentId);} // Load course content visibility on loading CM tab
controller.get('classController').loadCourseContentVisibility();controller.changeLanguage();controller.loadItemsToGrade();controller.init(); // Load CUL performance only if default view is not Milestone
controller.getUnitLevelPerformance();},resetController:function resetController(controller){controller.set('tab',null);controller.set('studentId',null);controller.set('questionItems',null);controller.set('isAccepted',false);controller.set('itemsToGradeList',_ember['default'].A([]));this.set('secondaryClass',null);controller.set('activeStudentId',null);},fetchUnitsPerformance:function fetchUnitsPerformance(userId,classId,courseId,units){var route=this;_ember['default'].RSVP.hash({ucPerformance:route.get('performanceService').findStudentPerformanceByCourse(userId,classId,courseId,units,{collectionType:_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION}),uaPerformance:route.get('performanceService').findStudentPerformanceByCourse(userId,classId,courseId,units,{collectionType:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT})}).then(function(_ref){var ucPerformance=_ref.ucPerformance;var uaPerformance=_ref.uaPerformance;units.forEach(function(unit){var unitPerformanceAssessment=uaPerformance.findBy('id',unit.get('id'));if(!unitPerformanceAssessment || unitPerformanceAssessment.score === null){unitPerformanceAssessment = ucPerformance.findBy('id',unit.get('id'));}if(unitPerformanceAssessment){unit.set('performance',unitPerformanceAssessment);}});});}});});