define('gooru-web/components/content/activity/gru-tasks',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ /**------------------------------------------------------------------------------------------------
   * Structure
   *    -tasks Layout,
   *        -list of tasks
   *        -- Line item of each task
   *        -- Invoke New Task
   *            -- Invoke Submission Layout
   *                -- List of submissions
   *                -- New Submission
   * ------------------------------------------------------------------------------------------------
   * Models
   *  Parent Models : Receives activityModel, editingActivity Model
   *  Own Models :
   *     editingActivity> Tasks : List of Task Models
   *      editingActivity> Tasks > Task : Task Entity Model
   *        editingActivity> Tasks > Task > submissions : List of Submission Models
   *         editingActivity> Tasks > Task > submissions > submission: Submission Entity Model
   * ------------------------------------------------------------------------------------------------
   *    UI Interaction:
   *         Load from Tasks tab click, or scroll down if mandatory OA is created
   *         Detects empty Tasks collection > Open New Task creation, otherwise show list of tasks, AND
   *         Task Creation form at the end. Saving task adds to task list again with empty task creation form
   *
   * ------------------------------------------------------------------------------------------------*/ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/offline-activity/offline-activity
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * Collection model as instantiated by the route. This is the clean model used when not editing
   * or after any collection changes have been saved.
   * @property {Collection}
   */activityModel:null, /**
   * Collection model as passed by the route. The dirty model used for editing and saving changes to.
   * This is sent to parent for syncing.
   * @property {Collection}
   */tasks:null,sortedTasks:_ember['default'].observer('tasks',function(){var component=this;var tasksCol=component.get('tasks');tasksCol = tasksCol.sortBy('id');return tasksCol;}),isExpanded:false, // -------------------------------------------------------------------------
// Attributes
classNames:['content','gru-task'], // -------------------------------------------------------------------------
// Actions
actions:{addNewTask:function addNewTask(){var component=this;if(component.get('task') !== 'dummy' && !component.get('task')){component.set('task','dummy');}},cancelTask:function cancelTask(){var component=this;component.get('updateParent')();if(component.get('task')){component.set('task',null);}},updateTaskCollection:function updateTaskCollection(task){var component=this;var tasksCol=component.get('tasks');component.removeItemIfFromCollection(tasksCol,task,'id');tasksCol.pushObject(task);tasksCol = tasksCol.sortBy('id');_ember['default'].set(this,'tasks',tasksCol);component.get('updateParent')();if(component.get('task')){component.set('task',null);}},removeLineItem:function removeLineItem(task){var component=this;component.removeTask(task).then(function(task){var tasksCol=component.get('tasks');component.removeItemIfFromCollection(tasksCol,task,'id');component.get('updateParent')();});},updateExpanded:function updateExpanded(isExpanded){_ember['default'].set(this,'isExpanded',isExpanded);}},removeItemIfFromCollection:function removeItemIfFromCollection(col,item,field){var curItem=col.findBy(field,item[field]);if(curItem){col.removeObject(curItem);}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);}, // -------------------------------------------------------------------------
// Properties
removeTask:function removeTask(task){var component=this;return component.get('activityService').removeTask(task);}});});