define('gooru-web/controllers/student/class/proficiency',['exports','ember','gooru-web/config/config','gooru-web/utils/navigation-util','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsNavigationUtil,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsTenantSettingsMixin['default'],{queryParams:['proficiencyType','isNavigatePrograme'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {SkylineInitialService} Service to retrieve skyline initial service
   */skylineInitialService:_ember['default'].inject.service('api-sdk/skyline-initial'),classController:_ember['default'].inject.controller('student.class'), /**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), /**
   * @type {ClassService} Service to retrieve class information
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(),isBaselineSet:false, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when select next action
     */onMoveNext:function onMoveNext(curStep){var controller=this;if(curStep === 'playNext'){controller.startPlaying();}else if(curStep === 'route'){var classId=controller.get('classId');var classService=controller.get('classService');return _ember['default'].RSVP.hash({profileBaseline:_ember['default'].RSVP.resolve(classService.updateProfileBaseline(classId))}).then(function(){_ember['default'].run.later(function(){controller.set('isBaselineSet',true);},3000);});}},closePullUp:function closePullUp(){var component=this;var classId=component.get('classId');var queryParam={queryParams:{isProficiencyValue:true}};component.set('isOpenPlayer',false);component.transitionToRoute('student.class.course-map',classId,queryParam);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isShowRoute0Destination
   */isShowRoute0Destination:false, /**
   * @property {Boolean} isShowInspectDestination
   */isShowInspectDestination:false, /**
   * @property {Boolean} isRoute0Applicable
   */isRoute0Applicable:_ember['default'].computed('class',function(){var controller=this;var classData=controller.get('class');return classData.get('route0Applicable');}),activeSubPrograme:null, /**
   * @property {Number} classGrade
   */classGrade:_ember['default'].computed('class',function(){var controller=this;var classData=controller.get('class');var classGrade=classData.get('gradeCurrent');return classGrade?parseInt(classGrade):null;}), /**
   * @property {UUID} classId
   */classId:_ember['default'].computed.alias('class.id'), /**
   * @property {UUID} courseId
   */courseId:_ember['default'].computed.alias('class.courseId'), /**
   * @property {String} subjectCode
   */subjectCode:_ember['default'].computed.alias('class.preference.subject'), /**
   * Property used to identify destination.
   * @type {String}
   */destination:_ember['default'].computed.alias('skylineInitialState.destination'), /**
   * Property used to identify the status of LP computation
   * @type {Boolean}
   */isLPComputeInprogress:_ember['default'].computed.equal('destination',_gooruWebConfigConfig.CLASS_SKYLINE_INITIAL_DESTINATION.ILPInProgress), /**
   * Maintains the status check interval value
   * @return {Number}
   */pollingStatusCheckInterval:5000, /**
   * Maintains state of chart needs to redraw or not.
   * @type {Boolean}
   */reDrawChart:false, /**
   * Maintains the state check interval object
   */stateCheckInterval:null, /**
   * Prop to check whether the public class contain any study material or not
   * @type {Boolean}
   */isNoStudyMaterial:false, // -------------------------------------------------------------------------
// Methods
initialize:function initialize(){var controller=this;controller._super.apply(controller,arguments);_ember['default'].run.scheduleOnce('afterRender',controller,function(){controller.checkStateOfSkylineInitial();});}, /**
   * Method used to check the status of  skyline initial.
   */checkStateOfSkylineInitial:function checkStateOfSkylineInitial(){var controller=this;var isLPComputeInprogress=controller.get('isLPComputeInprogress');if(isLPComputeInprogress){var pollingStatusCheckInterval=controller.get('pollingStatusCheckInterval');var stateCheckInterval=_ember['default'].run.later(function(){controller.loadSkylineIntialState();},pollingStatusCheckInterval);controller.set('stateCheckInterval',stateCheckInterval);}}, /**
   * Method used to load the skyline intial state data
   */loadSkylineIntialState:function loadSkylineIntialState(){var controller=this;var classId=controller.get('classId');controller.get('skylineInitialService').fetchState(classId).then(function(skylineInitialStateRes){if(!controller.get('isDestroyed')){var skylineInitialState=controller.get('skylineInitialState');skylineInitialState.set('destination',skylineInitialStateRes.get('destination'));skylineInitialState.set('context',skylineInitialStateRes.get('context'));var isLPComputeInprogress=controller.get('isLPComputeInprogress');if(isLPComputeInprogress){controller.checkStateOfSkylineInitial();}else {controller.set('reDrawChart',true);}}});}, /**
   * @function startPlaying
   * Method to play first item that needs to be played
   */startPlaying:function startPlaying(){var controller=this;var classData=controller.get('class');var navigateMapService=controller.get('navigateMapService');var classId=classData.get('id');var courseId=classData.get('courseId');var options={role:_gooruWebConfigConfig.ROLES.STUDENT,source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP,courseId:courseId,classId:classId},nextPromise=null; //start studying
nextPromise = navigateMapService.continueCourse(options.courseId,options.classId);controller.get('notifications').clear();controller.get('notifications').setOptions({positionClass:'toast-top-full-width student-class-proficiency'});nextPromise.then(controller.nextPromiseHandler).then(function(queryParams){if(queryParams.collectionId){queryParams.isIframeMode = true;var playerContent=_ember['default'].Object.create({format:queryParams.type});controller.set('playerUrl',controller.target.get('router').generate('study-player',courseId,{queryParams:queryParams}));controller.set('isOpenPlayer',true);controller.set('playerContent',playerContent);}else {controller.handleNoContent();}})['catch'](function(){controller.handleNoContent();});}, /**
   * @function handleNoContent
   * Method to show the error message for no studymaterial in content
   */handleNoContent:function handleNoContent(){var controller=this;var navigateMapService=controller.get('navigateMapService');if(controller.get('class.isPublic')){controller.set('isNoStudyMaterial',true);}else {controller.set('isNoStudyMaterial',true);navigateMapService.getLocalStorage().removeItem(navigateMapService.generateKey());}}, /**
   * @function nextPromiseHandler
   */nextPromiseHandler:function nextPromiseHandler(resp){var queryParams={role:_gooruWebConfigConfig.ROLES.STUDENT,source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP,courseId:(0,_gooruWebUtilsNavigationUtil.hasSuggestions)(resp)?resp.context.courseId:resp.courseId, // Only in case of suggestions we dont have courseId in suggestion
type:resp.context.current_item_type || null};queryParams = (0,_gooruWebUtilsNavigationUtil.createStudyPlayerQueryParams)((0,_gooruWebUtilsNavigationUtil.hasSuggestions)(resp)?resp.suggestions[0]:resp.context || resp,queryParams);return _ember['default'].RSVP.resolve(queryParams);}});});