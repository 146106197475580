define('gooru-web/routes/player-external',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){ /**
 * External Assessment Player Route
 */exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), /**
   * @requires service:api-sdk/assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @requires service:api-sdk/collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'),learningToolService:_ember['default'].inject.service('api-sdk/learning-tools'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Actions
actions:{onGainedScore:function onGainedScore(score){this.set('gainedScore',score);}, /**
     * Actrion triggered when close external assessment player
     */onClosePlayer:function onClosePlayer(){var route=this;var controller=route.get('controller');var classId=controller.get('classId');var source=controller.get('source');var isIframeMode=controller.get('isIframeMode');var isPreviewReferrer=controller.get('isPreviewReferrer');var contentType=controller.get('type');var collectionId=controller.get('collectionId');route.controllerFor('player-external').stopPlayContentEvent(this.get('gainedScore'),false);if(isPreviewReferrer && (isPreviewReferrer === true || isPreviewReferrer === 'true')){route.transitionTo(contentType === 'collection-external'?'content.external-collections.edit':'content.external-assessments.edit',collectionId,{queryParams:{isLibraryContent:true,isPreviewReferrer:false,editing:false}});}else {if(isIframeMode){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}else if(source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS){route.transitionTo('student.class.class-activities',classId);}else {route.transitionTo('index');}}}}, // -------------------------------------------------------------------------
// Methods
model:function model(params){var route=this;var itemType=params.type;var resource=itemType === 'collection-external'?route.get('collectionService').readExternalCollection(params.collectionId):route.get('assessmentService').readExternalAssessment(params.collectionId);return _ember['default'].RSVP.hash({externalResource:resource}).then(function(_ref){var externalResource=_ref.externalResource;var learningToolId=externalResource.get('learningToolId')?externalResource.get('learningToolId'):null;return _ember['default'].RSVP.hash({toolDetails:learningToolId?route.get('learningToolService').getLearningToolInformation(learningToolId):null}).then(function(_ref2){var toolDetails=_ref2.toolDetails;var mapLocation=_ember['default'].Object.create({context:_ember['default'].Object.create({classId:params.classId,courseId:externalResource.get('courseId'),unitId:externalResource.get('unitId'),collectionId:externalResource.get('id'),lessonId:externalResource.get('lessonId'),itemType:itemType})});return {source:params.source,mapLocation:mapLocation,externalResource:externalResource,itemType:itemType,toolDetails:toolDetails,caContentId:params.caContentId};});});},setupController:function setupController(controller,model){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_LOADING_COMPLETED,'*');controller.set('mapLocation',model.mapLocation);controller.set('source',model.source);controller.set('resourceType',model.itemType);controller.set('externalResource',model.externalResource);controller.set('toolDetails',model.toolDetails);controller.set('caContentId',model.caContentId);if(model.toolDetails){if(model.toolDetails.toolType !== 'LTI'){controller.initialize();}else {controller.intializeLtiTool();}}if(!this.get('session.isAnonymous') && !model.toolDetails){controller.fetchActivityFeedbackCateory();}}});});