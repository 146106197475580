define('gooru-web/components/content/external-collections/gru-external-collection-edit',['exports','ember','gooru-web/mixins/content/edit','gooru-web/mixins/modal','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/models/content/external-collection','gooru-web/config/config','gooru-web/utils/taxonomy','gooru-web/utils/utils'],function(exports,_ember,_gooruWebMixinsContentEdit,_gooruWebMixinsModal,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebModelsContentExternalCollection,_gooruWebConfigConfig,_gooruWebUtilsTaxonomy,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsContentEdit['default'],_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/course
   */externalCollectionService:_ember['default'].inject.service('api-sdk/external-collection'), /**
   * @property {MediaService} Media service API SDK
   */mediaService:_ember['default'].inject.service('api-sdk/media'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','collections','gru-collection-edit'],tagName:'article',isLoading:false,collectionType:'collection-external', // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Edit Content
     */editContent:function editContent(){var component=this;var collectionForEditing=this.get('collection').copy();collectionForEditing.standards.forEach(function(standardObj){_ember['default'].set(standardObj,'isRemovable',true);});this.set('tempCollection',collectionForEditing);this.set('isEditing',true);var aggregatedStandards=[];var unitStandards=this.get('tempCollection.children');var selectedStandards=this.get('collection.standards');var selectedStandardCodes=[];selectedStandards.forEach(function(standardObj){selectedStandardCodes.push(standardObj.code);});unitStandards.forEach(function(unitstandardObj){var unitStandardTag=unitstandardObj.standards;unitStandardTag.forEach(function(onestandardObj){_ember['default'].set(onestandardObj,'tagAlreadyexist',true);_ember['default'].set(onestandardObj,'isRemovable',false);aggregatedStandards.push(onestandardObj);if(selectedStandardCodes.length !== 0){selectedStandardCodes.forEach(function(newstandardObj){if(newstandardObj === onestandardObj.code){_ember['default'].set(onestandardObj,'tagAlreadyexist',false);_ember['default'].set(onestandardObj,'isRemovable',false);aggregatedStandards.push(onestandardObj);}});}});});component.get('tempCollection.standards').forEach(function(suggeststanObj){component.get('tempCollection.standards').removeObject(suggeststanObj);var newtaxonomyObj=_ember['default'].Object.create({code:suggeststanObj.get('code'),frameworkCode:suggeststanObj.get('frameworkCode'),id:suggeststanObj.get('id'),isRemovable:true,tagAlreadyexist:false});component.get('tempCollection.standards').addObject(newtaxonomyObj);});var result=aggregatedStandards.reduceRight(function(r,a){r.some(function(b){return a.code === b.code;}) || r.push(a);return r;},[]);this.set('tempCollection.aggregatedTag',result);}, /**
     * Save Content
     */updateContent:function updateContent(){var component=this;var editedCollection=component.get('tempCollection');var collection=component.get('collection');var etlHrs=editedCollection.get('hours');var etlMins=editedCollection.get('minutes');(0,_gooruWebUtilsUtils.etlSecCalculation)(editedCollection,etlHrs,etlMins);editedCollection.validate().then((function(_ref){var validations=_ref.validations;if(validations.get('isValid')){var imageIdPromise=new _ember['default'].RSVP.resolve(editedCollection.get('thumbnailUrl'));if(editedCollection.get('thumbnailUrl') && editedCollection.get('thumbnailUrl') !== collection.get('thumbnailUrl')){imageIdPromise = component.get('mediaService').uploadContentFile(editedCollection.get('thumbnailUrl'));}imageIdPromise.then(function(imageId){editedCollection.set('thumbnailUrl',imageId);component.get('externalCollectionService').updateExternalCollection(editedCollection.get('id'),editedCollection).then(function(){collection.merge(editedCollection,['title','learningObjectives','isVisibleOnProfile','thumbnailUrl','standards','centurySkills','hours','minutes','url','primaryLanguage']);component.set('isEditing',false);component.get('tempCollection.standards').forEach(function(suggeststanObj){suggeststanObj.set('isRemovable',false);});})['catch'](function(error){var message=component.get('i18n').t('common.errors.collection-not-updated').string;component.get('notifications').error(message);_ember['default'].Logger.error(error);});});}this.set('didValidate',true);}).bind(this));}, /**
     * Save setting for visibility of collection in profile
     */publishToProfile:function publishToProfile(){var collectionForEditing=this.get('collection').copy();this.set('tempCollection',collectionForEditing);this.actions.updateContent.call(this);}, /**
     * Delete collection
     */deleteItem:function deleteItem(){var myId=this.get('session.userId');var model={content:this.get('collection'),isHeaderDelete:true,parentName:this.get('course.title'),deleteMethod:(function(){return this.get('externalCollectionService').deleteExternalCollection(this.get('collection.id'));}).bind(this),type:_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION,redirect:{route:'library-search',params:{profileId:myId,type:'my-content'}}};this.actions.showModal.call(this,'content.modals.gru-delete-content',model,null,null,null,false);},selectSubject:function selectSubject(subject){this.set('selectedSubject',subject);},selectCategory:function selectCategory(category){var component=this;var standardLabel=category === _gooruWebConfigConfig.EDUCATION_CATEGORY.value;component.set('standardLabel',!standardLabel);if(category === component.get('selectedCategory')){component.set('selectedCategory',null);}else {component.set('selectedCategory',category);}component.set('selectedSubject',null);}, /**
     * Remove tag data from the taxonomy list in tempUnit
     */removeTag:function removeTag(taxonomyTag){var tagData=taxonomyTag;this.get('tempCollection.standards').removeObject(tagData);tagData.set('isRemovable',false);tagData.set('tagAlreadyexist',false);this.get('tempCollection.aggregatedTag').addObject(tagData);this.set('tempCollection.aggregatedTag',this.get('tempCollection.aggregatedTag').uniqBy('code'));this.compareAggregatedTags();}, /**
     * Add tag data from the taxonomy list in tempUnit
     */addTag:function addTag(taxonomyTag){ // let tagData = taxonomyTag;
taxonomyTag.set('isRemovable',true);taxonomyTag.set('tagAlreadyexist',false);this.get('tempCollection.standards').addObject(taxonomyTag);this.set('tempCollection.standards',this.get('tempCollection.standards').uniqBy('code'));this.get('tempCollection.aggregatedTag').removeObject(taxonomyTag);var newtaxonomyObj=_ember['default'].Object.create({code:taxonomyTag.get('code'),frameworkCode:taxonomyTag.get('frameworkCode'),isRemovable:false,tagAlreadyexist:false});this.get('tempCollection.aggregatedTag').addObject(newtaxonomyObj);this.compareAggregatedTags();}, /**
     * Remove century skill id
     */removeSkill:function removeSkill(skillItemId){this.get('tempCollection.centurySkills').removeObject(skillItemId);},openTaxonomyModal:function openTaxonomyModal(){this.openTaxonomyModal();},openSkillsModal:function openSkillsModal(){this.openSkillsModal();}, //Action triggered when click back
onClickBack:function onClickBack(){var isPreview=this.get('isPreviewReferrer');var router=this.get('router');var previewRoute='library-search';var myId=this.get('session.userId');var queryParams={profileId:myId,type:'my-content',activeContentType:'collection'};if(isPreview){router.transitionTo(previewRoute,{queryParams:queryParams});}else {window.history.back();}},selectType:function selectType(type){this.set('selectedType',type);},selectFile:function selectFile(file){var component=this;component.set('isErrorScormFile',false);if(file){component.set('isLoading',true);component.get('mediaService').uploadScormFile(file).then(function(response){var tempCollection=component.get('tempCollection');var collection=component.get('collection');if(response && response.scorm_file_info && response.scorm_file_info.resources && response.scorm_file_info.resources.length){var url=component.get('session.cdnUrls.content') + response.filepath + response.scorm_file_info.resources[0].filename;tempCollection.set('url',url);collection.set('url',url);var scorm={scorm:{originalFilename:response.scorm_file_info.resources[0].filename,filepath:response.filepath,fileName:response.original_filename}};tempCollection.set('metadata',scorm);collection.set('metadata',scorm);var selectedType=component.get('selectedType');tempCollection.set('subFormat',selectedType === 1?'url':'scorm');collection.set('subFormat',selectedType === 1?'url':'scorm');component.set('isErrorScormFile',false);}else {component.set('isErrorScormFile',true);}component.set('isLoading',false);});}}}, // -------------------------------------------------------------------------
// Properties
init:function init(){this._super.apply(this,arguments);var collection=this.get('tempCollection');if(!collection){collection = _gooruWebModelsContentExternalCollection['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null,audience:[]});}else {if(collection && !collection.audience){this.set('tempCollection','audience',_ember['default'].A([]));this.set('tempCollection','centurySkills',_ember['default'].A([]));}}this.set('tempCollection',collection);}, /**
   * Collection model as instantiated by the route. This is the model used when not editing
   * or after any collection changes have been saved.
   * @property {Collection}
   */collection:null, /**
   * Course model as instantiated by the route. This is the course that have the assigned collection
   * @property {Course}
   */course:null, /**
   * Copy of the collection model used for editing.
   * @property {Collection}
   */tempCollection:null, /**
   * i18n key for the standard/competency dropdown label
   * @property {string}
   */standardLabelKey:_ember['default'].computed('standardLabel',function(){return this.get('standardLabel')?'common.competency' // changing standards to competency
:'common.competencies';}), /**
   * @property {boolean}
   */standardLabel:true, /**
   * @property {boolean}
   */standardDisabled:_ember['default'].computed.not('selectedSubject'), /**
   * Indicate if the button "Back to course" is available.
   */allowBack:_ember['default'].computed('collection',function(){return this.get('collection.courseId');}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collection.standards.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('collection.standards'),false);}),aggregatedTags:_ember['default'].computed('tempCollection.aggregatedTag.[]',function(){var aggregatedTags=_gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('tempCollection.aggregatedTag'),false,false,true);return aggregatedTags;}),selectedType:_ember['default'].computed('tempCollection',function(){var isScorm=this.get('tempCollection.subFormat')?2:1;return isScorm;}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */editableTags:_ember['default'].computed('tempCollection.standards.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('tempCollection.standards'),false,true);}), /**
   * @property {CenturySkill[]} List of selected century skills
   */tempSelectedSkills:_ember['default'].computed('tempCollection.centurySkills.[]','centurySkills.[]',function(){var selectedCenturySkillsIds=this.get('tempCollection.centurySkills');return this.selectedCenturySkillsData(selectedCenturySkillsIds);}), /**
   * @property {CenturySkill[]} List of selected century skills
   */selectedSkills:_ember['default'].computed('collection.centurySkills.[]','centurySkills.[]',function(){var selectedCenturySkillsIds=this.get('collection.centurySkills');return this.selectedCenturySkillsData(selectedCenturySkillsIds);}), /**
   * List of Century Skills
   * @prop {CenturySkill[]}
   */centurySkills:_ember['default'].A([]), /**
   * @type {String} the selected category
   */selectedCategory:_ember['default'].computed('collection',function(){var standard=this.get('collection.standards.firstObject');var subjectId=standard?(0,_gooruWebUtilsTaxonomy.getSubjectId)(standard.get('id')):null;return subjectId?(0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectId):null;}),selectedSubject:_ember['default'].computed('collection',function(){var standard=this.get('collection.standards.firstObject');if(standard){standard.set('subjectCode',(0,_gooruWebUtilsTaxonomy.getGutCodeFromSubjectId)((0,_gooruWebUtilsTaxonomy.getSubjectId)(standard.get('id'))));}return standard?standard:null;}), // ----------------------------
// Methods
openTaxonomyModal:function openTaxonomyModal(){var component=this;var standards=component.get('tempCollection.standards') || [];var subject=component.get('selectedSubject');var subjectStandards=_gooruWebModelsTaxonomyTaxonomyTagData['default'].filterBySubject(subject,standards);var notInSubjectStandards=_gooruWebModelsTaxonomyTaxonomyTagData['default'].filterByNotInSubject(subject,standards);var model={selected:subjectStandards,shortcuts:null, // TODO: TBD
subject:subject,standardLabel:component.get('standardLabel'),callback:{success:function success(selectedTags){var dataTags=selectedTags.map(function(taxonomyTag){return taxonomyTag.get('data');});var standards=_ember['default'].A(dataTags);standards.pushObjects(notInSubjectStandards.toArray());component.get('tempCollection.standards').pushObjects(standards);component.set('tempCollection.standards',component.get('tempCollection.standards').uniqBy('code'));component.get('tempCollection.standards').forEach(function(suggeststanObj){suggeststanObj.set('isRemovable',true);});}}};this.actions.showModal.call(this,'taxonomy.modals.gru-standard-picker',model,null,'gru-standard-picker');},openSkillsModal:function openSkillsModal(){var component=this;var model={selectedCenturySkills:component.get('tempCollection.centurySkills'),centurySkills:component.get('centurySkills'),callback:{success:function success(selectedCenturySkills){component.set('tempCollection.centurySkills',_ember['default'].A(selectedCenturySkills));}}};this.actions.showModal.call(this,'century-skills.modals.gru-century-skills',model,null,'gru-century-skills');}, /**
   * Returns selectedCenturySkills data
   * @param {Number[]} compareAggregatedTags ids
   * @return {centurySkill[]}
   */compareAggregatedTags:function compareAggregatedTags(){var component=this;component.get('tempCollection.aggregatedTag').forEach(function(suggeststanObj){_ember['default'].set(suggeststanObj,'tagAlreadyexist',true);});component.get('tempCollection.standards').forEach(function(standardObj){var suggestObj=component.get('tempCollection.aggregatedTag').findBy('code',standardObj.code);if(suggestObj !== undefined){_ember['default'].set(suggestObj,'tagAlreadyexist',false);}});}, /**
   * Returns selectedCenturySkills data
   * @param {Number[]} selectedCenturySkills ids
   * @return {centurySkill[]}
   */selectedCenturySkillsData:function selectedCenturySkillsData(selectedCenturySkillsIds){var selectedCenturySkillsData=_ember['default'].A([]);var centurySkills=this.get('centurySkills');if(selectedCenturySkillsIds && centurySkills){for(var i=0;i < selectedCenturySkillsIds.length;i++) {var skillItem=selectedCenturySkillsIds[i];centurySkills.filter(function(centurySkill){if(centurySkill.get('id') === skillItem){selectedCenturySkillsData.pushObject(centurySkill);}});}}return selectedCenturySkillsData;}});});