define('gooru-web/routes/content/activity/edit',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/models/content/activity','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebModelsContentActivity,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],_gooruWebMixinsUiHelperMixin['default'],{queryParams:{editing:{},editingContent:{refreshModel:true},isIndependentOA:{refreshModel:true},isLibraryContent:false,isPreviewReferrer:false}, //ToDo: Store implementation
// -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:/offline-activity//offline-activity
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @requires service:session/session
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:course/course
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @requires service:century-skill/century-skill
   */centurySkillService:_ember['default'].inject.service('century-skill'), // -------------------------------------------------------------------------
// Props
/**
   * holds the activity
   */activityCollection:null, /**
   * The in memory edit copy of activity
   */tempCollection:null, // -------------------------------------------------------------------------
// Events
model:function model(params){var _this=this; //In new Mode : This wont be called as model itself is passed which contains unit, course, and lesson
//In edit mode: activityId value is passed as parameter
//lessonId 'new' refers to an independent OA
if(params.lessonId && params.lessonId !== 'new'){var _ret=(function(){params.activityId = params.lessonId;var route=_this;route.setTitle('Library Search',null);return {v:route.get('activityService').readActivity(params.activityId).then(function(activity){var courseId=activity.get('courseId');var isEditing=params.editing;var editingContent=params.editingContent?params.editingContent:null;var course=null;var isLibraryContent=params.isLibraryContent;if(courseId){course = route.get('courseService').fetchById(courseId);}return _ember['default'].RSVP.hash({activity:activity,course:course,isEditing:!!isEditing,editingContent:editingContent,isLibraryContent:isLibraryContent});})};})();if(typeof _ret === 'object')return _ret.v;}else {return {isIndependentOA:params.isIndependentOA};}},setupController:function setupController(controller,model){var route=this;var activityCollection=undefined;if(!model.activity){ //Changes for new flow
controller.set('isNewActivity',true);activityCollection = _gooruWebModelsContentActivity['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null});activityCollection.formatList = [_ember['default'].Object.create({code:'oa.project.poster',display_name:'dummy'})];controller.set('tempCollection',activityCollection.copy());}else {controller.set('isNewActivity',false);activityCollection = model.activity;controller.set('tempCollection',model.activity.copy());}if(!model.course && this.modelFor('content.courses.edit')){model.course = this.modelFor('content.courses.edit') && this.modelFor('content.courses.edit').course;}controller.set('activityCollection',activityCollection);controller.set('course',model.course);controller.set('isEditing',model.isEditing);controller.set('editingContent',model.editingContent);controller.set('isAssessment',true);this.set('isEditing',model.isEditing);controller.set('isEditing',model.isEditing);controller.set('tempCollection',activityCollection.copy());if(model.isIndependentOA || model.isLibraryContent){controller.set('allowBackToCourse',false);}route.get('centurySkillService').findCenturySkills().then(function(centurySkillsArray){controller.set('centurySkills',centurySkillsArray.toArray());});route.get('activityService').getSubTypes().then(function(formatList){activityCollection.set('formatList',formatList);controller.set('formatList',formatList);});controller.set('model',model);}});});