define('gooru-web/services/api-sdk/library',['exports','ember','gooru-web/serializers/library/library','gooru-web/adapters/library/library'],function(exports,_ember,_gooruWebSerializersLibraryLibrary,_gooruWebAdaptersLibraryLibrary){ /**
 * @typedef {Object} LibraryService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {LibrarySerializer} librarySerializer
   */librarySerializer:null, /**
   * @property {LibraryAdapter} libraryAdapter
   */libraryAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('librarySerializer',_gooruWebSerializersLibraryLibrary['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('libraryAdapter',_gooruWebAdaptersLibraryLibrary['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Fetches the libraries
   *
   * @returns {Promise}
   */fetchLibraries:function fetchLibraries(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('libraryAdapter').fetchLibraries().then(function(response){return resolve(service.get('librarySerializer').normalizeFetchLibraries(response));},reject);});}, /**
   * Returns a library by id
   * @param {string} libraryId - library ID to search for
   * @returns {Promise}
   */fetchById:function fetchById(libraryId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('libraryAdapter').getLibraryById(libraryId).then(function(response){return resolve(service.get('librarySerializer').normalizeLibrary(response));},reject);});}, /**
   * Fetches the Library Content
   * @param libraryId the library id
   * @param {string} contentType  course, collection, assessment, resource, question, rubric
   * @param pagination - pagination values to list library content
   * @returns {Promise}
   */fetchLibraryContent:function fetchLibraryContent(libraryId,contentType,pagination){if(contentType === undefined)contentType = 'course';var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('libraryAdapter').fetchLibraryContent(libraryId,contentType,pagination).then(function(response){return resolve(service.get('librarySerializer').normalizeFetchLibraryContent(contentType,response));},reject);});},postLtiData:function postLtiData(listData){var service=this;service.get('libraryAdapter').postLtiData(listData);}});});