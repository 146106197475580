define('gooru-web/models/content/question',['exports','ember','ember-cp-validations','gooru-web/config/question','gooru-web/models/resource/resource','gooru-web/utils/taxonomy','gooru-web/utils/question/fill-in-the-blank'],function(exports,_ember,_emberCpValidations,_gooruWebConfigQuestion,_gooruWebModelsResourceResource,_gooruWebUtilsTaxonomy,_gooruWebUtilsQuestionFillInTheBlank){var Validations=(0,_emberCpValidations.buildValidations)({title:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.add-question-title'})]},description:{validators:[(0,_emberCpValidations.validator)('presence-html',{messageKey:'common.errors.add-question-description',disabled:_ember['default'].computed.not('model.description')}),(0,_emberCpValidations.validator)('square-bracket',{messageKey:'common.errors.add-question-answer-text',disabled:_ember['default'].computed.not('model.description')})]}}); /**
 * Question model
 * typedef {Object} Question
 */var Question=_ember['default'].Object.extend(Validations,{ /**
   * @property {string}
   */id:null, /**
   * @property {string} title
   */title:null, /**
   * Possible question types
   * @property {string} type
   */type:null, /**
   *  @property {string} questionType - Alias for type property
   */questionType:_ember['default'].computed.alias('type'), /**
   * Resource format, in this case it is question
   * @property {string}
   */format:'question', /**
   * @property {string}
   */text:null,thumbnailAltText:null, /**
   * @property {string}
   */narration:null, /**
   * @property {string}
   */thumbnail:null, /**
   * @property {string}
   */description:_ember['default'].computed.alias('text'), /**
   * Returns the FIB text without the correct answer
   * @property {string}
   */fibText:_ember['default'].computed('text',function(){return _gooruWebUtilsQuestionFillInTheBlank['default'].toFibText(this.get('text'));}), /**
   * @property {number}
   */order:null, /**
   * @property {string} published|unpublished|requested
   */publishStatus:null, /**
   * @property {Array} subQuestions
   */subQuestions:null, /**
   * @property {Boolean} isPublished
   */isPublished:_ember['default'].computed.equal('publishStatus','published'), /**
   * @property { Content/User } Owner of the question
   */owner:null, /**
   * @property { Content/User } Original creator of the question
   */creator:null,sameOwnerAndCreator:_ember['default'].computed('owner.id','creator',function(){if(!this.get('creator')){return true;}else if(this.get('owner.id') === this.get('creator')){return true;}}), /**
   * @property {TaxonomyTagData[]} an array with Taxonomy data
   */standards:[],answerDetails:null,hints:null, /**
   * @property {Number[]} Array with the audience ids
   */audience:[], /**
   * @property {Number[]} Array with the depthOfknowledge ids
   */depthOfknowledge:[], /**
   * @property {Boolean} isVisibleOnProfile - Indicates if the Question is visible on Profile. By default it is false
   */isVisibleOnProfile:false, /**
   * @property {Answer[]} answers - Array of answers
   */answers:_ember['default'].A([]),hintExplanationDetail:'', /**
   * @property {string}
   */exemplar:_ember['default'].computed.alias('hintExplanationDetail'), /**
   * @property {String} category - Category the course belongs to
   */category:_ember['default'].computed('subject',function(){var subjectId=this.get('subject');if(subjectId){return (0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectId);}}), /**
   * @property {String} Taxonomy primary subject ID
   */subject:'', /**
   * @property {String} courseId
   */courseId:null, /**
   * @property {String} unitId
   */unitId:null, /**
   * @property {String} lessonId
   */lessonId:null, /**
   * @property {String} collectionId
   */collectionId:null, /**
   * @property {String} questionWords
   */questionText:null, /**
   * @property {String} hardText
   */softText:null, /**
   * @property {String} hardText
   */hardText:null, /**
   * @property {boolean} indicates if the question is multiple choice type
   * @see components/player/gru-multiple-choice.js
   */isMultipleChoice:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.multipleChoice), /**
   * @property {boolean} indicates if the question is multiple answer type
   * @see components/player/gru-multiple-answer.js
   */isMultipleAnswer:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.multipleAnswer),player_metadata:null, /**
   * @property {boolean} indicates if the question is true false type
   * @see components/player/gru-true-false.js
   */isTrueFalse:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.trueFalse), /**
   * @property {boolean} indicates if the question is open ended type
   * @see components/player/gru-open-ended.js
   */isOpenEnded:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.openEnded), /**
   * @property {boolean} indicates if the question is fill in the blank type
   * @see components/player/gru-fib.js
   */isFIB:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.fib),isScientificFIB:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.scientificfib),isScientificFreeRes:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.scientificFreeResponse), /**
   * @property {boolean} isSerpSayOutLoud if the question is serp say out loud type
   */isSerpSayOutLoud:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.sayOutLoud), /**
   * @property {boolean} isSerpEncoding if the question is serp say out loud type
   */isSerpEncoding:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.encodingAssessment), /**
   * @property {boolean} isSerpUnderline if the question is serp say out loud type
   */isSerpUnderline:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.identifyDigraph), /**
   * @property {boolean} isSerpWPM if the question is serp say out loud type
   */isSerpWPM:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.wordsPerMinute),isLikertScale:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.likertScale), /**
   * @property {boolean} isSerpDecoding if the question is serp say out loud type
   */isSerpDecoding:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.decodingAssessment), /**
   * @property {boolean} isClassic if the question is serp classic question
   */isClassic:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.classic), /**
   * @property {boolean} isSorting if the question is serp classic question
   */isSorting:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.sorting), /**
   * @property {boolean} isChooseOne if the question is serp choose on question
   */isChooseOne:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.chooseOne), /**
   * @property {boolean} isPickNChoose if the question is serp pick n choose question
   */isPickNChoose:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.pickNChoose),isMatchTheFollowing:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.matchTheFollowing), /**
   * Indicates when it is a legacy FIB question
   * @property {boolean}
   */isLegacyFIB:_ember['default'].computed('isFIB','text',function(){var regExp=_gooruWebUtilsQuestionFillInTheBlank['default'].LEGACY_REGEX.global;var questionText=this.get('text');return questionText && this.get('isFIB') && questionText.match(regExp);}), /**
   * @property {boolean} indicates if the question is hot spot text type
   * @see components/player/gru-hot-spot-text.js
   */isHotSpotText:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.hotSpotText), /**
   * @property {boolean} indicates if the question is hot spot image type
   * @see components/player/gru-hot-spot-image.js
   */isHotSpotImage:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.hotSpotImage), /**
   * @property {boolean} indicates if the question is reorder
   * @see components/player/gru-reorder.js
   */isHotTextReorder:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.hotTextReorder), /**
   * @property {boolean} indicates if the question is hot spot text
   * @see components/player/gru-hot-text-highlight.js
   */isHotTextHighlight:_ember['default'].computed.equal('questionType',_gooruWebConfigQuestion.QUESTION_TYPES.hotTextHighlight), /**
   * @property {boolean} indicates if the question is hot text word type
   */isHotTextHighlightWord:_ember['default'].computed.equal('answers.firstObject.highlightType','word'), /**
   * @property {boolean} indicates if the question is hot text sentence type
   */isHotTextHighlightSentence:_ember['default'].computed.equal('answers.firstObject.highlightType','sentence'), /**
   * Indicates if the question supports answer choices
   * @property {boolean}
   */supportAnswerChoices:_ember['default'].computed('isMultipleChoice','isMultipleAnswer','isHotTextReorder','isHotSpotText',function(){return this.get('isMultipleChoice') || this.get('isMultipleAnswer') || this.get('isHotTextReorder') || this.get('isHotSpotText');}), /**
   * Return a copy of the question
   *
   * @function
   * @return {Question}
   */copy:function copy(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key));if(value === 'string' || value === 'number' || value === 'boolean' || value === 'array' || value === 'object'){properties.push(key);}} // Copy the question data
properties = this.getProperties(properties);var answersForEditing=this.get('answers').map(function(answer){return answer.copy();});properties.answers = answersForEditing;var standards=this.get('standards');var audience=this.get('audience');var depthOfknowledge=this.get('depthOfknowledge');var rubric=this.get('rubric');var metadata=this.get('metadata'); // Copy standards and metadata values
properties.standards = standards.slice(0);properties.audience = audience.slice(0);properties.depthOfknowledge = depthOfknowledge.slice(0);if(rubric){properties.rubric = rubric.copy();}if(metadata){var metadatas=JSON.stringify(metadata);properties.metadata = JSON.parse(metadatas);}return Question.create(_ember['default'].getOwner(this).ownerInjection(),properties);}, /**
   * Copy a list of property values from another model to override the current ones
   *
   * @function
   * @param {Question} model
   * @param {String[]} propertyList
   * @return {null}
   */merge:function merge(model){var propertyList=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var properties=model.getProperties(propertyList);this.setProperties(properties);}, /**
   * Returns a player resource
   * @return {Resource}
   */toPlayerResource:function toPlayerResource(){var model=this;return _gooruWebModelsResourceResource['default'].create({id:model.get('id'),order:model.get('order'),title:model.get('title'),resourceFormat:model.get('format'),questionType:model.get('type'),text:model.get('text'),mediaUrl:model.get('thumbnail'),questionText:model.get('questionText'),softText:model.get('softText'),hardText:model.get('hardText'),hints:model.get('questionType') === 'SE_FIB' || model.get('questionType') === 'SE_FRQ'?model.get('hints'):null, //TODO
explanation:null, //TODO
answers:model.get('answers').map(function(answer){return answer.toPlayerAnswer();}),answerDetails:model.get('answerDetails')?model.get('answerDetails').map(function(answer){return answer;}):null,taxonomy:model.get('standards')});}, /**
   * Sets the subject of the course
   *
   * @function
   * @param {TaxonomyRoot} taxonomySubject
   */setTaxonomySubject:function setTaxonomySubject(taxonomySubject){if(!(this.get('isDestroyed') || this.get('isDestroying'))){this.set('subject',taxonomySubject?taxonomySubject.get('id'):null);}}, /**
   * Updates the question from legacy FIB format to new format
   */updateLegacyFIBText:function updateLegacyFIBText(){var text=this.get('text');if(text){var answers=this.get('answers') || [];answers.forEach(function(answer){var newFormat='[' + answer.text + ']';text = text.replace(_gooruWebUtilsQuestionFillInTheBlank['default'].LEGACY_REGEX.single,newFormat);});this.set('text',text);}}});Question.reopenClass({ /**
   * Serializes the question type to be API compliant
   * @param type
   * @returns {string}
   * TODO move to util
   */serializeQuestionType:function serializeQuestionType(type){return (0,_gooruWebConfigQuestion.getQuestionApiType)(type);}, /**
   * Converts several app format values to api values
   * @param {string[]} values values to format
   * TODO move to util
   */serializeAllQuestionType:function serializeAllQuestionType(values){var model=this;return values.map(function(type){return model.serializeQuestionType(type);});}, /**
   * Normalizes the question type to be App compliant
   * @param format
   * @returns {string}
   * TODO move to util
   */normalizeQuestionType:function normalizeQuestionType(apiType){return (0,_gooruWebConfigQuestion.getQuestionTypeByApiType)(apiType);}});exports['default'] = Question;});