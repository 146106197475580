define('gooru-web/models/analytics/current-location',['exports','ember'],function(exports,_ember){ /**
 * Current Location model
 * typedef {Object} CurrectLocation
 */var CurrentLocation=_ember['default'].Object.extend({ /**
   * @property {String} classId - The current class ID
   */classId:null, /**
   * @property {String} courseId - The current course ID
   */courseId:null, /**
   * @property {String} unitId - The current unit ID
   */unitId:null, /**
   * @property {String} lessonId - The current lesson ID
   */lessonId:null, /**
   * @property {String} collectionId - The current collection ID
   */collectionId:null, /**
   * @property {course} course - The current Course
   */course:null, /**
   * @property {unit} unit - The current Unit
   */unit:null, /**
   * @property {lesson} lesson - The current Lesson
   */lesson:null, /**
   * @property {collection} collection - The current Collection
   */collection:null, /**
   * @property {String} collectionType - The current collection Type
   */collectionType:null, /**
   * @property {Number} unitIndex - The current Unit Index
   */unitIndex:_ember['default'].computed('course','unit',function(){var course=this.get('course');var unit=this.get('unit');var index=null;if(course && unit){index = course.getChildUnitIndex(unit);}return index;}), /**
   * @property {Number} lessonIndex - The current Lesson Index
   */lessonIndex:_ember['default'].computed('course','unit','lesson',function(){var course=this.get('course');var unit=this.get('unit');var lesson=this.get('lesson');var index=null;if(course && unit && lesson){index = unit.getChildLessonIndex(lesson);}return index;})});exports['default'] = CurrentLocation;});