define('gooru-web/models/content/external-assessment',['exports','ember','gooru-web/models/content/collection-base','ember-cp-validations','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsContentCollectionBase,_emberCpValidations,_gooruWebConfigConfig){var Validations=(0,_emberCpValidations.buildValidations)({title:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.external-assessment-title-presence'})]},url:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.enter-url'}),(0,_emberCpValidations.validator)('format',{type:'url',regex:new RegExp(/^(ftp|http|https):\/\/[^ "]+$/),message:'{{description}}',descriptionKey:'common.errors.resource-invalid-url'}),(0,_emberCpValidations.validator)('host')]}}); /**
 * Assessment model
 * typedef {Object} Assessment
 */exports['default'] = _ember['default'].Object.extend(Validations,_gooruWebModelsContentCollectionBase['default'],{ /**
   * @property {string} indicates it is an assessment
   */collectionType:'assessment-external', /**
   * @property {string}
   */url:null, /**
   * @property {boolean}
   */isExternalAssessment:_ember['default'].computed.equal('format',_gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT), /**
   * @property {integer}
   */attempts:-1, /**
   * @property {boolean}
   */bidirectional:false, /**
   * @property {string}
   */showFeedback:null, /**
   * @property {boolean}
   */classroom_play_enabled:null, /**
   * @property {string}
   */showKey:null,toPlayerCollection:function toPlayerCollection(){var collection=this._super.apply(this,arguments);collection.set('attempts',this.attempts);collection.set('bidirectional',this.bidirectional);collection.set('showFeedback',this.showFeedback);collection.set('showKey',this.showKey);return collection;}});});