define('gooru-web/components/cards/gru-collection-card',['exports','ember','gooru-web/mixins/modal','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebMixinsModal,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){ /**
 * Collection and Assessment card
 *
 * Component responsible of showing the collection or assessmentO information in cards, so that most useful information is summarized there.
 * @module
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['cards','gru-collection-card'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to open the content player
     * @param {string} collection collection identifier
     */openContentPlayer:function openContentPlayer(collection){this.sendAction('onOpenContentPlayer',collection);},editCollection:function editCollection(){this.sendAction('onEditCollection',this.get('collection'));},remixCollection:function remixCollection(){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {this.sendAction('onRemixCollection',this.get('collection'));}}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Collection/Resource} collection
   */collection:null, /**
   * @property {string} on content player action
   */onOpenContentPlayer:null, /**
   * @property {boolean} Indicates if collection has 1 or more resources
   */hasResources:_ember['default'].computed.gt('collection.resourceCount',0), /**
   * @property {boolean} Indicates if collection has 1 or more questions
   */hasQuestions:_ember['default'].computed.gt('collection.questionCount',0), /**
   * @property {boolean}
   */isAssessment:_ember['default'].computed.alias('collection.isAssessment'), /**
   * @property {String} remixedByUser
   */ //remixedByUser: Ember.computed('collection',function(){
//  return this.get('collection.remixedBy')[0];
//}),
/**
   * @property {Number} remixedUsers
   */ //remixedUsers:Ember.computed('collection',function(){
//  return (this.get('collection.remixedBy').length)-1;
//}),
/**
   * Indicates if the edit functionality is enabled
   * @property {boolean}
   */editEnabled:false, /**
   * Indicates if the edit functionality is enabled
   * @property {boolean}
   */remixEnabled:_ember['default'].computed('editEnabled','collection',function(){var isEditing=this.get('editEnabled');if(this.get('isCollection')){return !isEditing;}else {return !isEditing && !this.get('isExternalAssessment');}}), /**
   * Indicates if the edit functionality is enabled
   * @property {boolean}
   */addEnabled:true, /**
   * @property {string} edit action
   */onEditCollection:null, /**
   * @property {string} remix action
   */onRemixCollection:null,visibility:null,isSmall:false, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collection.standards.[]',function(){var standards=this.get('collection.standards');standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);})});});