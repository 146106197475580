define('gooru-web/components/modals/gru-user-consent-modal',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), /**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), // -------------------------------------------------------------------------
// Attributes
classNames:['modals','gru-user-consent-modal'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isConfirm Accept the personal details
   * provide access to share learn Popup
   */isConfirm:false, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Trigger when confirm popup
     */confirmMessage:function confirmMessage(){var component=this;component.set('isConfirm',true);}, /**
     * Action trigger when click button updating profile details
     */updateUserProfile:function updateUserProfile(isAgree){var component=this;var model=component.get('model');model.onUpdateProfile(isAgree).then(function(){component.triggerAction({action:'closeModal'});});}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);}});});