define('gooru-web/components/gru-i2d-template',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-i2d-template'], // -------------------------------------------------------------------------
// Properties
/**
   * It maintains the property of student list
   * @property {Ember.Array}
   */activeStudents:_ember['default'].computed('students',function(){var activeStudents=this.get('students').filterBy('isActive',true);return activeStudents;}), /**
   * It maintains the property of question column list
   * @property {Ember.Array}
   */questionColumns:_ember['default'].computed(function(){return Array.from({length:15},function(x,i){return 1 + i;});}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when we click on print
     */printTemplate:function printTemplate(){window.print();}}});});