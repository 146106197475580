define('gooru-web/routes/student-independent-learning/learning-base',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Route.extend({templateName:'student-independent-learning/learning-base', // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {LearnerService} Service to retrieve learner information
   */learnerService:_ember['default'].inject.service('api-sdk/learner'), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
/**
   * @property {Number} number of items to load per page
   */PAGE_SIZE:20, /**
   * @property {String} the content type
   */contentType:_gooruWebConfigConfig.CONTENT_TYPES.COURSE, /**
   * @property {String} the menu item name
   */item:null, // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Methods
model:function model(){var userId=this.get('session.userId');var contentType=this.get('contentType');return _ember['default'].RSVP.hash({locations:this.get('learnerService').fetchLocations(userId,contentType),performance:this.get('learnerService').fetchPerformance(userId,contentType)});},setupController:function setupController(controller,model){controller.set('locations',model.locations);controller.set('performance',model.performance);controller.set('contentType',this.get('contentType'));controller.set('pageSize',this.get('PAGE_SIZE'));controller.set('offset',this.get('PAGE_SIZE'));controller.get('studentIndependentController').selectMenuItem(this.get('item'));}});});