define('gooru-web/components/player/gru-external-assessment-footer',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-external-assessment-footer'], // ---------------------------------------------------------------------------
// Properties
hideContinue:false,isFullView:false, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered for the next button
     */playNext:function playNext(){this.sendAction('playNext');}, /**
     * Action triggered for the next button hover
     */onShowFeedback:function onShowFeedback(){this.toggleProperty('isShowFeedback');this.sendAction('showFeedbackContainer');}, /**
     * Action triggered for the next button move out
     */onCloseFeedback:function onCloseFeedback(){this.set('isShowFeedback',false);}, /**
     * Action triggered when toggle screen mode
     */onToggleScreen:function onToggleScreen(){var component=this;component.sendAction('onToggleScreen');},onExit:function onExit(route,id){var component=this;var isIframeMode=component.get('isIframeMode');if(isIframeMode){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}else {component.sendAction('onExit',route,id);}},showTimer:function showTimer(){this.sendAction('showTimer');},onStartPlayer:function onStartPlayer(){this.set('hideContinue',true);this.sendAction('onPlayerAction',_gooruWebConfigConfig.EXTERNAL_PLAYER_ACTIONS.START);},toggleDetailPullup:function toggleDetailPullup(){this.toggleProperty('isFullView');this.sendAction('toggleDetailPullup');}}});});