define('gooru-web/components/reports/pull-up/competency-completion-report',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['pull-up','competency-completion-report'], // -------------------------------------------------------------------------
// Dependencies
competencyService:_ember['default'].inject.service('api-sdk/competency'),searchService:_ember['default'].inject.service('api-sdk/search'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.openPullUp();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when select a competency
onSelectCompetency:function onSelectCompetency(competencyData){var component=this;var domainData=component.get('domainData');if(!competencyData.get('usersCompletionSummary')){component.set('isLoading',true);component.fetchUsersCompetencyPerformanceSummary(domainData.domainCode,competencyData.competencyCode).then(function(usersCompletionSummary){competencyData.set('usersCompletionSummary',component.selectAllStudents(usersCompletionSummary).sortBy('status'));component.set('isLoading',false);});component.getSuggestionCount(competencyData);}component.toggleCompetencyContainer(competencyData);if(competencyData.get('isExpanded') || component.get('activeCompetency.competencyCode') !== competencyData.get('competencyCode')){component.selectAllStudents(competencyData.get('usersCompletionSummary'));}component.set('activeCompetency',competencyData);}, //Action triggered when close pullup
onClosePullup:function onClosePullup(){var component=this;component.closePullUp();}, //Action triggered when click on competency suggestion
onSuggestAtCompetency:function onSuggestAtCompetency(competency){var component=this;if(component.get('activeCompetency.competencyCode') !== competency.get('competencyCode')){component.resetSelectedUserIds();}component.sendAction('onSuggestAtCompetency',competency);}, //Action triggered when filter by content type
onSuggestAtStudents:function onSuggestAtStudents(contentType){var component=this;var activeCompetency=component.get('activeCompetency');var userIds=component.get('selectedUserIds');component.sendAction('onSuggestAtCompetency',activeCompetency,contentType,userIds);}, //Action triggered when select/unselect a student
onSelectStudent:function onSelectStudent(userCompletionData){var component=this;var selectedUserIds=component.get('selectedUserIds');if(userCompletionData.get('selected')){userCompletionData.set('selected',false);selectedUserIds.removeObject(userCompletionData);}else {userCompletionData.set('selected',true);selectedUserIds.pushObject(userCompletionData);}}, //Action triggered when click on the x mark
onClearStudents:function onClearStudents(){var component=this;component.resetSelectedUserIds();},onSelectAllStudent:function onSelectAllStudent(){var component=this;var competencyData=component.get('activeCompetency');if(competencyData.get('isExpanded') || component.get('activeCompetency.competencyCode') !== competencyData.get('competencyCode')){component.selectAllStudents(competencyData.get('usersCompletionSummary'));}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} contentTypes
   */contentTypes:_gooruWebConfigConfig.SEARCH_FILTER_BY_CONTENT_TYPES, /**
   * @property {Object} activeCompetency
   */activeCompetency:null, /**
   * @property {Array} selectedUserIds
   */selectedUserIds:_ember['default'].A([]), /**
   * @property {Boolean} isShowStudentSuggestion
   */isShowStudentSuggestion:_ember['default'].computed('selectedUserIds.[]',function(){return this.get('selectedUserIds.length') >= 0;}), /**
   * @property {Number} maxLimitToSuggestContent
   */maxLimitToSuggestContent:6, // -------------------------------------------------------------------------
// Functions
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.resetSelectedUserIds();component.set('showPullUp',false);});}, /**
   * @function toggleCompetencyContainer
   * Method to toggle competency container view
   */toggleCompetencyContainer:function toggleCompetencyContainer(competencyData){var component=this;var activeCompetency=component.get('activeCompetency');if(activeCompetency && competencyData.get('competencyCode') !== activeCompetency.get('competencyCode')){activeCompetency.set('isExpanded',!activeCompetency.get('isExpanded'));}competencyData.set('isExpanded',!competencyData.get('isExpanded'));}, /**
   * @function fetchUsersCompetencyPerformanceSummary
   * Method to fetch user competency performance summary
   */fetchUsersCompetencyPerformanceSummary:function fetchUsersCompetencyPerformanceSummary(domain,tx_code){var controller=this;var competencyService=controller.get('competencyService');var classId=controller.get('classId');var month=controller.get('activeMonth');var year=controller.get('activeYear');var agent=controller.get('userAgent');var requestBody={classId:classId,domain:domain,tx_code:tx_code,month:month,year:year,agent:agent};return _ember['default'].RSVP.hash({usersPerformanceSummary:competencyService.getUsersCompetencyPerformanceSummary(requestBody)}).then(function(_ref){var usersPerformanceSummary=_ref.usersPerformanceSummary;return usersPerformanceSummary;});}, /**
   * @function resetSelectedUserIds
   * Method to reset selected userIds
   */resetSelectedUserIds:function resetSelectedUserIds(){var component=this;var selectedUsers=component.get('selectedUserIds');selectedUsers.map(function(selectedUser){return selectedUser.set('selected',false);});component.set('selectedUserIds',_ember['default'].A([]));}, /**
   * @function selectAllStudents
   * Method to select all the students in a competency
   */selectAllStudents:function selectAllStudents(usersCompletionSummary){var component=this;var usersCompletionSummarybyDefaultAllSelected=_ember['default'].A([]);component.resetSelectedUserIds();if(usersCompletionSummary){(function(){var selectedUsers=component.get('selectedUserIds');usersCompletionSummarybyDefaultAllSelected = usersCompletionSummary.map(function(userCompletionData){userCompletionData.set('selected',true);selectedUsers.pushObject(userCompletionData);return userCompletionData;});component.set('selectedUserIds',selectedUsers);})();}return usersCompletionSummarybyDefaultAllSelected;}, /**
   * @function fetchLearningMapsContent
   * Method to fetch learning maps content of given gutCode
   */fetchLearningMapsContent:function fetchLearningMapsContent(gutCode){var component=this;var searchService=component.get('searchService');var filters={startAt:0,isCrosswalk:false,length:6};return searchService.fetchLearningMapsCompetencyContent(gutCode,filters);}, /**
   * @function getSuggestionCount
   * Method to get suggestion content count
   */getSuggestionCount:function getSuggestionCount(competencyData){var component=this;var gutCode=competencyData.get('competencyCode');var maxLimitToSuggestContent=component.get('maxLimitToSuggestContent');component.fetchLearningMapsContent(gutCode).then(function(learningMapsContent){var learningMapContents=learningMapsContent.get('contents');var collectionCount=learningMapContents.collection.totalHitCount || 0;competencyData.set('suggestionContentCount',collectionCount > maxLimitToSuggestContent?maxLimitToSuggestContent:collectionCount);});}});});