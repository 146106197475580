define('gooru-web/adapters/learning_tools',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the learning tools CRUD operations in the API 3.0
 *
 * @typedef {Object} LearningToolsAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/learning-registry/v1', /**
   * Fetch learning tool information
   * @returns {Promise.<[]>}
   */getLearningToolInformation:function getLearningToolInformation(toolId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/learning-tools/' + toolId;var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8'};return _ember['default'].$.ajax(url,options);},fetchLearningTool:function fetchLearningTool(){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/learning-tools';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8'};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the product that match with the term
   *
   * @param term the term to search
   * @param resetPagination indicates if the pagination needs a reset
   * @returns {Promise.<Product[]>}
   */fetchProductList:function fetchProductList(term){var params=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var adapter=this;var namespace=this.get('namespace');var url=namespace + '/subscribed/learning-tools';var page=params.page || 0;var pageSize=params.pageSize;var registryType=params.registryType;var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:{search_terms:term || '',offset:page,limit:pageSize,registry_type:registryType}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the product based on productId
   *
   * @param productId
   * @returns {Promise.<Product[]>}
   */fetchProductById:function fetchProductById(productId,type){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/subscribed/product/' + productId;var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:{registry_type:type}};return _ember['default'].$.ajax(url,options);}, /**
   * Fetches the product Ids
   *
   * @returns {Promise.<subscribedContentIds[]>}
   */getProductIds:function getProductIds(type){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/subscribed/learning-tools/ids';var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.defineHeaders(),data:{registry_type:type}};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});