define('gooru-web/components/content/modals/gru-rubric-new',['exports','ember','gooru-web/models/rubric/rubric','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebModelsRubricRubric,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
  /**
   * @property {RubricService} Rubric service API SDK
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(),parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-rubric-new'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Create Rubric
     */create:function create(){var component=this;var rubric=component.get('rubric');component.get('validate').call(component).then((function(_ref){var validations=_ref.validations;if(validations.get('isValid')){component.set('isLoading',true);component.get('rubricService').createRubric(rubric).then(function(rubricId){component.closeModal(rubricId);component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CREATE_RUBRIC);})['catch'](function(error){var message=component.get('i18n').t('common.errors.rubric-not-created').string;component.get('notifications').error(message);_ember['default'].Logger.error(error);});}this.set('didValidate',true);}).bind(this));}}, // -------------------------------------------------------------------------
// Methods
/**
   * Validate rubric element
   */validate:function validate(){var rubric=this.get('rubric');return rubric.validate();}, /**
   * Close gru-rubric-new modal
   */closeModal:function closeModal(rubricId){this.set('isLoading',false);this.triggerAction({action:'closeModal'});var queryParams={queryParams:{editing:true}};this.get('router').transitionTo('content.rubric.edit',rubricId,queryParams);}, /**
   * Show error message
   */showErrorMessage:function showErrorMessage(error){_ember['default'].Logger.error(error);var message=this.get('i18n').t('common.errors.collection-not-created').string;this.get('notifications').error(message);}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var rubric=_gooruWebModelsRubricRubric['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:null});this.set('rubric',rubric);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Rubric} rubric
   */rubric:null, /**
   * Indicate if it's waiting for createRubric callback
   */isLoading:false});});