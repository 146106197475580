define('gooru-web/models/performance/course-performance',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Object.extend({id:null, /**
   * @property {String} Title for the performance
   */title:null, /**
   * @property {String} Student performance type (e.g. unit, lesson, collection, assessment)
   */type:null, /**
   * This will fix the change in gru-accordion-lesson because classPerformance uses collectionType instead of type, this would be only for collection and assessmet
   * @property {String} Student performance type (e.g. collection, assessment)
   */collectionType:_ember['default'].computed.alias('type'), /**
   * @property {Boolean} Value that tells whether the performance data belongs to an assessment
   */isAssessment:_ember['default'].computed.equal('type','assessment'), /**
   * @property {Boolean} Value that tells whether the performance data belongs to an assessment
   */isExternalAssessment:_ember['default'].computed.equal('type','assessment-external'), /**
   * @property {Boolean} Value that tells whether the performance data belongs to a collection
   */isCollection:_ember['default'].computed.equal('type','collection'), /**
   * @property {Boolean} Value that tells whether the performance data belongs to a collection
   */isCollectionOrAssessment:_ember['default'].computed.or('isCollection','isAssessment','isExternalAssessment'), /**
   * @property {Boolean} Value that tells whether the performance data belongs to an Unit
   */isUnit:_ember['default'].computed.equal('type','unit'), /**
   * @property {Boolean} Value that tells whether the performance data belongs to a Lesson
   */isLesson:_ember['default'].computed.equal('type','lesson'), /**
   * @property {Boolean} Value that tells whether the performance data belongs to a unit or a lesson
   */isUnitOrLesson:_ember['default'].computed.or('isUnit','isLesson'), /**
   * @property {Number} The performance score (in percentages e.g. 80%, 100%, 95%, etc)
   */score:0, /**
   * @property {Number} The completion done in the unit, class or collection/assessment, e.g. It is the top number of the fraction 5/10
   */completionDone:null, /**
   * @property {Number} The total of completionin the unit, class or collection/assessment, e.g. It is the bottom number of the fraction 5/10
   */completionTotal:null, /**
   * @property {Number} The registered time spent in the unit, class or collection/assessment
   */timeSpent:null, /**
   * @property {Number} The grading status for the collection/assessmet
   */isGraded:false, /**
   *  @property {Number} The average rating score set for set for the unit, class or collection/assessment
   */ratingScore:null, /**
   *  @property {Number} The number of attempts registered for the unit, class or collection/assessment
   */attempts:null, /**
   *  @property {Number} The grading status for the collection/assessmet
   */gradingStatus:null, /**
   *  @property {boolean} Whether the performance is completed or not.
   */isCompleted:_ember['default'].computed('completionDone','completionTotal',function(){var completionTotal=this.get('completionTotal');var completionDone=this.get('completionDone');return completionDone > 0 && completionDone >= completionTotal; //TODO sometimes completion total is 0
}),completionValue:_ember['default'].computed('completionDone','completionTotal',function(){var completionTotal=this.get('completionTotal');var completionDone=this.get('completionDone');return completionTotal > 0?completionDone * 100 / completionTotal:completionDone > 0?100:null;}),isInprogress:_ember['default'].computed('score','gradingStatus',function(){return this.get('gradingStatus') === 'in-progress';}),hasStarted:_ember['default'].computed('timeSpent','score',function(){return Math.floor(this.get('timeSpent')) > 0 || this.get('score') > 0;}),displayableTimeSpent:_ember['default'].computed('timeSpent',function(){return (0,_gooruWebUtilsUtils.formatTime)(this.get('timeSpent'));})});});