define('gooru-web/routes/content/resources/edit',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/validations/edit-resource','gooru-web/models/content/resource'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebValidationsEditResource,_gooruWebModelsContentResource){var _extends=Object.assign || function(target){for(var i=1;i < arguments.length;i++) {var source=arguments[i];for(var key in source) {if(Object.prototype.hasOwnProperty.call(source,key)){target[key] = source[key];}}}return target;};exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{queryParams:{collectionId:{},editing:{},isLibraryContent:false,hasCollaborator:false,primaryLanguage:{refreshModel:true}}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/resource
   */resourceService:_ember['default'].inject.service('api-sdk/resource'), /**
   * @requires service:api-sdk/collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires service:century-skill/century-skill
   */centurySkillService:_ember['default'].inject.service('century-skill'), /**
   * @requires service:admin-facet/admin-facet
   */adminFacetService:_ember['default'].inject.service('admin-facet'), /**
   * @property {Ember.Service} Service to do retrieve language
   */lookupService:_ember['default'].inject.service('api-sdk/lookup'), // -------------------------------------------------------------------------
// Events
resetController:function resetController(controller,isExiting){if(isExiting){controller.set('collectionId',undefined);controller.set('isCollection',undefined);}}, // -------------------------------------------------------------------------
// Methods
model:function model(params){var route=this;var resource=route.get('resourceService').readResource(params.resourceId).then(function(resource){var sessionUserId=route.get('session.userId'); // Check if the sessionUserId is not equal to resource.creatorId then unauthorized error will be thrown.
if(sessionUserId !== resource.creatorId){route.get('router').transitionTo('facets-search');}return route.get('profileService').readUserProfile(resource.owner).then(function(owner){var EditResourceValidation=_gooruWebModelsContentResource['default'].extend(_gooruWebValidationsEditResource['default']);var editResource=EditResourceValidation.create(_ember['default'].getOwner(route).ownerInjection()); // standards and info are not coming inside modelProperties
var properties=resource.modelProperties().concat(['standards','info','centurySkills','playerMetadata','freeFormTag','facetAttributes','product_id']);editResource.merge(resource,properties);editResource.set('owner',owner);return editResource;});});var isEditing=params.editing;var collection=null;var isLibraryContent=params.isLibraryContent;if(params.collectionId){collection = route.get('collectionService').readCollection(params.collectionId);}return _ember['default'].RSVP.hash({resource:resource,collection:collection,isEditing:!!isEditing,isLibraryContent:isLibraryContent,primaryLanguage:params.primaryLanguage});},setupController:function setupController(controller,model){var route=this;controller.set('resource',model.resource);controller.set('collection',model.collection);controller.set('isEditing',model.isEditing);controller.set('isLibraryContent',model.isLibraryContent);controller.set('primaryLanguage',model.primaryLanguage);route.get('centurySkillService').findCenturySkills().then(function(centurySkillsArray){controller.set('centurySkills',centurySkillsArray.toArray());});route.get('adminFacetService').fetchAdminFacets().then(function(response){controller.set('facets',response.facets.map(function(item){return _extends({},item,{selectedOptions:item.selectedOptions.map(function(option){return _ember['default'].Object.create({id:option,display_name:option});})});}));}); /**
     * Fetch Languages
     */route.get('lookupService').getLanguages().then(function(languages){var primaryLanguageId=controller.get('primaryLanguage')?parseInt(controller.get('primaryLanguage')):controller.get('tempResource.primaryLanguage');var languageLists=languages.filter(function(language){return language.id === primaryLanguageId;});controller.set('selectedLanguage',languageLists && languageLists.length?languageLists[0]:null);controller.set('languageList',languages);controller.set('selectedCatalog',languageLists && languageLists.length?languageLists[0]:null);});controller.set('tempResource',model.resource.copy());}});});