define('gooru-web/adapters/activity',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Activity CRUD operations in the API 3.0
 *
 * @typedef {Object} ActivityAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/oa',collectionNamespace:'/api/nucleus/v1/oa', /**
   * Posts a new activity
   *
   * @param data activity data to be sent in the request body
   * @returns {Promise}
   */createActivity:function createActivity(data){var adapter=this;var url=this.get('namespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data.body)};return _ember['default'].$.ajax(url,options);}, /**
   * Reads an Activity by id
   *
   * @param {string} activityId
   * @returns {Promise}
   */readActivity:function readActivity(activityId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + activityId + '/detail';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Reads an Activity by id
   *
   * @param {string} activityId
   * @returns {Promise}
   */readActivityDCA:function readActivityDCA(activityId,activityType){var adapter=this;var namespace=adapter.get('namespace');if(!activityType){namespace = adapter.get('collectionNamespace');}var url=namespace + '/' + activityId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Reads an External Activity by id
   *
   * @param {string} activityId
   * @returns {Promise}
   */readExternalActivity:function readExternalActivity(activityId){var adapter=this;var namespace=adapter.get('externalNamespace');var url=namespace + '/' + activityId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Update an Activity
   *
   * @param activityId the id of the Activity to be updated
   * @param data Activity data to be sent in the request body
   * @returns {Promise}
   */updateActivity:function updateActivity(activityId,data){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + activityId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes an activity by id
   *
   * @param activityId activity id to be sent
   * @returns {Promise}
   */deleteActivity:function deleteActivity(activityId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + activityId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Adds a question to an activity
   *
   * @param {string} activityId
   * @param {string} questionId
   * @returns {Promise}
   */createReferences:function createReferences(activityId,referencesData){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + activityId + '/references';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',headers:adapter.defineHeaders(),data:JSON.stringify(referencesData)};return _ember['default'].$.ajax(url,options);}, /**
   * Copies an activity by id
   *
   * @param activityId
   * @returns {Promise}
   */copyActivity:function copyActivity(activityId){var adapter=this;var namespace=this.get('copierNamespace');var url=namespace + '/' + activityId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Reorder activity resources
   *
   * @param activityId the id of the Activity to be updated
   * @param data Activity data to be sent in the request body
   * @returns {Promise}
   */reorderActivity:function reorderActivity(activityId,data){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + activityId + '/questions/order';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Find  the mastery accrual for the given list of activityIds
   *
   * @param {string} activityIds
   * @returns {Promise}
   */activitysMasteryAccrual:function activitysMasteryAccrual(activityIds){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/mastery-accrual';var options={type:'POST',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({activityIds:activityIds})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}, /**
   * Get a list of OA subtype
   * @returns {Promise}
   */getSubTypes:function getSubTypes(){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/subtypes';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes an reference by id
   *
   * @param reference id to be sent
   * @returns {Promise}
   */deleteReference:function deleteReference(activityId,referenceId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + activityId + '/references/' + referenceId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}});});