define('gooru-web/models/content/activity',['exports','ember','gooru-web/models/content/collection-base','ember-cp-validations','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsContentCollectionBase,_emberCpValidations,_gooruWebConfigConfig){ //ToDo: Add validation on title, Taxonomy, subtype
var Validations=(0,_emberCpValidations.buildValidations)({title:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'oa.errors.activity-title-presence'})]},subFormat:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'oa.errors.subformat-presence'})]}}); /**
 *  Offline Activity model
 * typedef {Object} Assessment
 */exports['default'] = _ember['default'].Object.extend(Validations,_gooruWebModelsContentCollectionBase['default'],{ /**
   * @property {string} indicates it is an offline activity
   */collectionType:_gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY, /**
   * @property {string}
   */url:null, /**
   * @property {boolean}
   */isActivity:_ember['default'].computed.equal('format',_gooruWebConfigConfig.CONTENT_TYPES.ACTIVITY), /**
   * @property {integer}
   */attempts:-1, /**
   * @property {boolean}
   */bidirectional:false, /**
   * @property {string}
   */showFeedback:null, /**
   * @property {string}
   */reference:null, /**
   * @property {import { module } from '@ember/array';}
   */references:[], /**
   * @property {import { module } from '@ember/array';}
   */tasks:[], /**
   * @property {import { module } from '@ember/array';}
   */rubric:[], /**
   * @property {boolean}
   */classroom_play_enabled:null, /**
   * @property {string}
   */showKey:null,subFormat:null,learningObjectives:null,toPlayerCollection:function toPlayerCollection(){var collection=this._super.apply(this,arguments);collection.set('attempts',this.attempts);collection.set('bidirectional',this.bidirectional);collection.set('showFeedback',this.showFeedback);collection.set('showKey',this.showKey);return collection;}});});