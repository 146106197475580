define('gooru-web/services/api-sdk/bookmark',['exports','ember','gooru-web/serializers/content/bookmark','gooru-web/adapters/content/bookmark'],function(exports,_ember,_gooruWebSerializersContentBookmark,_gooruWebAdaptersContentBookmark){ /**
 * @typedef {Object} BookmarkService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {BookmarkSerializer} bookmarkSerializer
   */bookmarkSerializer:null, /**
   * @property {BookmarkAdapter} bookmarkAdapter
   */bookmarkAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('bookmarkSerializer',_gooruWebSerializersContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('bookmarkAdapter',_gooruWebAdaptersContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Creates a new bookmark
   *
   * @param bookmarkData object with the bookmark data
   * @returns {Promise}
   */createBookmark:function createBookmark(bookmarkData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedBookmarkData=service.get('bookmarkSerializer').serializeCreateBookmark(bookmarkData);service.get('bookmarkAdapter').createBookmark({body:serializedBookmarkData}).then(function(responseData,textStatus,request){var bookmarkId=request.getResponseHeader('location');bookmarkData.set('id',bookmarkId);resolve(bookmarkData);},function(error){reject(error);});});}, /**
   * Fetches the Bookmarks
   * @param resetPagination indicates if the pagination needs a reset
   * @param pagination - pagination values to list bookmarks
   * @returns {Promise}
   */fetchBookmarks:function fetchBookmarks(pagination){var resetPagination=arguments.length <= 1 || arguments[1] === undefined?false:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('bookmarkAdapter').fetchBookmarks(pagination,resetPagination).then(function(response){return resolve(service.get('bookmarkSerializer').normalizeFetchBookmarks(response));},reject);});}, /**
   * Deletes a bookmark
   * @param {String} bookmarkId
   * @returns {Promise|boolean} returns true if deleted
   */deleteBookmark:function deleteBookmark(bookmarkId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('bookmarkAdapter').deleteBookmark(bookmarkId).then(resolve,reject);});}});});