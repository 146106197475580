define('gooru-web/models/rating/rating',['exports','ember-data'],function(exports,_emberData){ /**
 * Rating Model
 *
 * @typedef {Object} Rate
 */exports['default'] = _emberData['default'].Model.extend({ /**
   * Indicates the rate target. Values for this fields could be 'content' and 'user'.
   * @property {string} target
   */target:_emberData['default'].attr('string'), /**
   * Indicates the type of rating. Values for this field are 'star' and 'thumb'.
   * @property {string} type
   */type:_emberData['default'].attr('string'), /**
   * Indicates the rating score. For a 'star' type values are [1,2,3,4,5]. For a 'thumb' type values are [-1, 1].
   * @property {string} score
   */score:_emberData['default'].attr('string'), /**
   * Indicates the gooruOid for 'content' target or gooruUid for 'user' target which the rating is associated with.
   * @property {string} associatedId
   */associatedId:_emberData['default'].attr('string')});});