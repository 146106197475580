define('gooru-web/adapters/rating/rating',['exports','gooru-web/adapters/application'],function(exports,_gooruWebAdaptersApplication){exports['default'] = _gooruWebAdaptersApplication['default'].extend({ /**
   * @property {string} End-point URI
   */namespace:'/gooruapi/rest/v2', /**
   * Builds the end-point URL for the findRecord queryParam, params are optional
   * @param modelName
   * @param id
   * @param snapshot
   * @returns {string}
   */urlForFindRecord:function urlForFindRecord(id){var namespace=this.get('namespace');return namespace + '/content/' + id + '/rating/star';}, /**
   * Builds the end-point URL for the createRecord queryParam
   * @param modelName
   * @param snapshot
   * @returns {string}
   */urlForCreateRecord:function urlForCreateRecord(){var namespace=this.get('namespace');return namespace + '/rating';}});});