define('gooru-web/components/gru-explore-featured-courses',['exports','ember','gooru-web/models/content/bookmark','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsContentBookmark,_gooruWebConfigConfig){ /**
 * explore featured courses component
 *
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/bookmark
   */bookmarkService:_ember['default'].inject.service('api-sdk/bookmark'), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
/**
   * Create a bookmark
   * @param bookmark
   */createBookmark:function createBookmark(bookmark){return this.get('bookmarkService').createBookmark(bookmark);}, /**
   * Show notification on bookmark success
   * @param bookmark
   * @param showType
   */notifyBookmarkSuccess:function notifyBookmarkSuccess(bookmark,showType){this.get('notifications').setOptions({positionClass:'toast-top-full-width',toastClass:'gooru-toast',timeOut:10000});var successMsg=showType?this.get('i18n').t('common.bookmarked-content-success',{contentType:bookmark.get('contentType')}):this.get('i18n').t('common.bookmarked-success');var independentLearningURL=this.get('router').generate('student-independent-learning');var buttonText=this.get('i18n').t('common.take-me-there');this.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + independentLearningURL + '">' + buttonText + '</a>');}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to bookmark a course
     * @param {Course} course
     */onBookmarkCourse:function onBookmarkCourse(_ref,showType){var _this=this;var title=_ref.title;var id=_ref.id;var bookmark=_gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:title,contentId:id,contentType:_gooruWebConfigConfig.CONTENT_TYPES.COURSE});this.createBookmark(bookmark).then(function(){return _this.notifyBookmarkSuccess(bookmark,showType);});}, /**
     *Action triggered when select play the course
     */playCourse:function playCourse(_ref2){var _this2=this;var id=_ref2.id;var title=_ref2.title;var bookmark=_gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(this).ownerInjection(),{title:title,contentId:id,contentType:_gooruWebConfigConfig.CONTENT_TYPES.COURSE});return this.createBookmark(bookmark).then(function(){return _this2.get('router').transitionTo('student.independent',id);});}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array[]} - courses
   */courses:null, /**
   * @property {Profile} user profile
   */profile:null});});