define('gooru-web/components/reports/pull-up/activity/add-performance-data',['exports','ember','gooru-web/mixins/reports/pull-up/pull-up-mixin','gooru-web/utils/utils','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsReportsPullUpPullUpMixin,_gooruWebUtilsUtils,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsPullUpPullUpMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['activities','add-performance-data'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.openPullUp();component.handleAppContainerScroll();},didDestroyElement:function didDestroyElement(){var component=this;component.removeAppContainerScroll();},didRender:function didRender(){this.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, /**
   * Method to handle container scroll
   */handleAppContainerScroll:function handleAppContainerScroll(){_ember['default'].$(document.body).addClass('no-vertical-scroll');}, /**
   * Method to remove container scroll
   */removeAppContainerScroll:function removeAppContainerScroll(){_ember['default'].$(document.body).removeClass('no-vertical-scroll');}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {String} populatedTopPos
   */populatedTopPos:_ember['default'].computed(function(){return (0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.EXTRA_SMALL)?'0%':'52px';})});});