define('gooru-web/components/reports/pull-up/course-competency-report',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['course-competency-report'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires competencyService
   */competencyService:_ember['default'].inject.service('api-sdk/competency'), /**
   * @requires classService
   */classService:_ember['default'].inject.service('api-sdk/class'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.set('isLoading',true);component.loadReportData();},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover',placement:'bottom'});}, // -------------------------------------------------------------------------
// Actions
actions:{ // Action triggered when the user click on the arrow
onClickArrow:function onClickArrow(direction,domainSeq){var component=this;var domainStudentListContainer=component.$('.student-competencies.domain-' + domainSeq);var curPos=domainStudentListContainer.scrollLeft();var nextPos=direction === 'previous'?curPos - 120:curPos + 120;component.toggleClickableArrow(direction,curPos,domainSeq);domainStudentListContainer.animate({scrollLeft:nextPos},600);}, /**
     * Action triggered when select a domain from pull up
     */onSelectDomain:function onSelectDomain(domainSet){var component=this;component.sendAction('onSelectDomain',domainSet);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {isThrowError}
   * Property to look whether there is an error while fetching domain level summary data
   */isThrowError:false, /**
   * @property {classId}
   * Property to store class ID
   */classId:_ember['default'].computed('classData',function(){var component=this;var classData=component.get('classData');return classData.id;}), /**
   * @property {courseId}
   * Property to store course ID
   */courseId:_ember['default'].computed('classData',function(){var component=this;var classData=component.get('classData');return classData.courseId;}), // -------------------------------------------------------------------------
// Methods
/**
   * @function fetchClassMembers
   * Method to fetch class memebers
   */fetchClassMembers:function fetchClassMembers(classId){var component=this;var classService=component.get('classService');var classMembersPromise=_ember['default'].RSVP.resolve(classService.readClassMembers(classId));return _ember['default'].RSVP.hash({classMembers:classMembersPromise}).then(function(_ref){var classMembers=_ref.classMembers;return classMembers.members;});}, /**
   * @function fetchDomainLevelSummary
   * Method to fetch domain level summary data
   */fetchDomainLevelSummary:function fetchDomainLevelSummary(){var component=this;var competencyService=component.get('competencyService');var filters={classId:component.get('classId')};var domainLevelSummaryPromise=_ember['default'].RSVP.resolve(competencyService.getDomainLevelSummary(filters));return _ember['default'].RSVP.hash({domainLevelSummary:domainLevelSummaryPromise}).then(function(_ref2){var domainLevelSummary=_ref2.domainLevelSummary;component.set('isThrowError',false);return domainLevelSummary;})['catch'](function(){component.set('isThrowError',true);});}, /**
   * @function loadReportData
   * Method to load report data
   */loadReportData:function loadReportData(){var component=this;var classStudentsPromsie=component.fetchClassMembers(component.get('classId'));var domainLevelSummaryPromise=component.fetchDomainLevelSummary();return _ember['default'].RSVP.hash({classStudents:classStudentsPromsie,domainLevelSummary:domainLevelSummaryPromise}).then(function(hash){var classStudents=hash.classStudents;var domainLevelSummary=hash.domainLevelSummary;component.constructCompetencyReportData(domainLevelSummary,classStudents);});}, /**
   * @function constructCompetencyReportData
   * Method to construct competency report data
   */constructCompetencyReportData:function constructCompetencyReportData(domainLevelSummary,classStudents){var component=this;var domainLevelStudentSummaryData=_ember['default'].A([]);var maxCompetencyLength=0;if(domainLevelSummary && classStudents.length){(function(){var domainCompetencies=domainLevelSummary.domainCompetencies;var studentsDomainCompetencies=domainLevelSummary.students;domainCompetencies.map(function(domainData){var numberOfCompetencies=domainData.competencies.length;maxCompetencyLength = numberOfCompetencies > maxCompetencyLength?numberOfCompetencies:maxCompetencyLength;domainData.competencyLength = numberOfCompetencies;var studentLevelDomainCompetencyData={domainData:domainData};var domainCode=domainData.domainCode;var parsedStudentCompetenctData=_ember['default'].A([]);classStudents.map(function(student){var studentDomainCompetencies=studentsDomainCompetencies.findBy('id',student.id);var userCompetencyMatrix=studentDomainCompetencies?studentDomainCompetencies.userCompetencyMatrix:{};var currentStudentDomainCompetencies=userCompetencyMatrix.findBy('domainCode',domainCode);var parsedData=component.parseStudentCompetencyData(student,domainData,currentStudentDomainCompetencies.competencies);parsedStudentCompetenctData.push(parsedData);});studentLevelDomainCompetencyData.studentCompetencies = parsedStudentCompetenctData;domainLevelStudentSummaryData.push(studentLevelDomainCompetencyData);});})();}component.set('domainDataSet',domainLevelStudentSummaryData);component.set('maxCompetencyLength',maxCompetencyLength);component.set('isLoading',false);}, /**
   * @function parseStudentCompetencyData
   * Method to parse student, domain and user competencies data
   */parseStudentCompetencyData:function parseStudentCompetencyData(student,domainData,studentDomainCompetencies){var studentDomainCompetencyData={firstName:student.firstName,lastName:student.lastName,userId:student.id,thumbnail:student.avatarUrl,fullName:student.lastName + ', ' + student.firstName,competencies:_ember['default'].A([])};if(studentDomainCompetencies){(function(){var competencies=_ember['default'].A([]);domainData.competencies.map(function(competency){var competencyData={competencyCode:competency.competencyCode,competencySeq:competency.competencySeq,competencyName:competency.competencyName,competencyDesc:competency.competencyDesc,competencyStudentDesc:competency.competencyStudentDesc,status:studentDomainCompetencies['' + competency.competencyCode]};competencies.push(competencyData);});studentDomainCompetencyData.competencies = competencies;})();}return studentDomainCompetencyData;}, /**
   * @function toggleClickableArrow
   * Method to enable/disable previous/next arrows
   */toggleClickableArrow:function toggleClickableArrow(direction,curPos,seq){var component=this;var domainStudentListContainer=component.$('.student-competencies');var visibleSectionWidth=domainStudentListContainer.width();var isNextDisabled=direction === 'next' && curPos + 240 >= visibleSectionWidth;var isPreviousDisabled=direction === 'previous' && curPos <= 120;if(isNextDisabled){component.$('.domain.domain-' + seq + ' .next').addClass('disabled');component.$('.domain.domain-' + seq + ' .previous').removeClass('disabled');}else if(isPreviousDisabled){component.$('.domain.domain-' + seq + ' .previous').addClass('disabled');component.$('.next').removeClass('disabled');}else {component.$('.domain.domain-' + seq + ' .next').removeClass('disabled');component.$('.domain.domain-' + seq + ' .previous').removeClass('disabled');}}});});