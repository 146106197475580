define('gooru-web/components/reports/assessment/questions/gru-identify-digraph',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * SERP Identify Digraph
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-identify-digraph'],answers:_ember['default'].computed('showCorrect','question.answers','answerObj',function(){return this.get('question.answers');}),graderType:_ember['default'].computed.alias('gradetype'),showCorrect:false,didInsertElement:function didInsertElement(){var _this=this;if(this.get('showCorrect')){var _loop=function(index){var element=_this.get('answers')[index];if(element.correctAnswer){element.correctAnswer.map(function(data){_this.hightLightDefaultWord(data,index,true);return data;});}};for(var index=0;index < this.get('question.answers').length;index++) {_loop(index);}}else {var objectResult=this.get('answerObj')?this.get('answerObj'):this.get('question.answerObject');objectResult.map(function(result,index){result.text.split(',').map(function(subresult){var answerResult=_this.get('question.answers').get(index);if(answerResult.correctAnswer.indexOf(subresult) !== -1){_this.hightLightDefaultWord(subresult,index,true);}else {_this.hightLightDefaultWord(subresult,index,false);}return subresult;});});}},hightLightDefaultWord:function hightLightDefaultWord(text,answerIndex,status){var component=this;var innerHTML='';var html='';if(status){html = '<span class="correct">' + text + '</span>';}else {html = '<span class="error">' + text + '</span>';}component.$('.answer-underline .answer-edit-' + answerIndex)[0].childNodes.forEach(function(childNode){if(childNode.data){innerHTML = innerHTML + childNode.data.replace(text,$.trim(html));}else if(childNode.data){innerHTML = innerHTML + childNode.data;}else {innerHTML = innerHTML + childNode.outerHTML;}});component.$('.answer-underline .answer-edit-' + answerIndex).html(innerHTML);}});});