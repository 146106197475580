define('gooru-web/components/gru-image-picker',['exports','ember'],function(exports,_ember){ /**
 * Image picker
 *
 * Component responsible for letting the user select/preview an image using a
 * system file browser dialog. Only certain file types are allowed
 * (@see gru-image-picker.hbs); otherwise an error is presented to the user.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-image-picker'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * @function actions:enableButtons
     */prepareForSubmission:function prepareForSubmission(file){this.set('isFileInputEmpty',false);this.set('image',file);}, /**
     * @function actions:disableButtons
     */disableButtons:function disableButtons(){this.set('isFileInputEmpty',true);}, /**
     * @function actions:resetPicker
     */resetPicker:function resetPicker(){ // Reset the input element in the file picker
// http://stackoverflow.com/questions/1043957/clearing-input-type-file-using-jquery/13351234#13351234
var $fileInput=this.$('input[type="file"]');$fileInput.wrap('<form>').closest('form').get(0).reset();$fileInput.unwrap(); // Prompt the file picker to reset the image preview
this.set('isFileInputEmpty',true);},submitImage:function submitImage(){ // TODO: Save the image by calling an endpoint
_ember['default'].Logger.log('Submit image: ',this.get('image'));}},init:function init(){this._super.apply(this,arguments);this.set('filePickerErrors',_ember['default'].A());}, // -------------------------------------------------------------------------
// Properties
/**
   * Image for submission
   * @prop {Object}
   */image:null, /**
   * Has a file been selected and loaded into the file picker?
   * @prop {Bool}
   */isFileLoaded:_ember['default'].computed.not('isFileInputEmpty'), /**
   * Has the file picker's input field been reset?
   * @prop {Bool}
   */isFileInputEmpty:true, /**
   * List of error messages to present to the user for conditions that the loaded image does not meet
   * @prop {String[]}
   */filePickerErrors:null});});