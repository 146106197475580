define('gooru-web/adapters/content/class',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Class CRUD operations in the API 3.0
 *
 * @typedef {Object} ClassAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/classes',namespaceV2:'/api/nucleus/v2/classes',reportNamespace:'/api/nucleus-download-reports/v1',demoNamespace:'/api/demo/v1',classroomNameSpace:'/api/google-classroom/v1',profileNameSpace:'/api/nucleus/v1/profiles',shortenerUrlNamespace:'/api/url-shortener/v1',sendMailNamespace:'/api/nucleus-utils/v1', /**
   * Archive class
   *
   * @param classId Identifier of the class to be archive
   * @returns {Promise}
   */archiveClass:function archiveClass(classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/archive';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Posts a new class
   *
   * @param data class data to be sent in the request body
   * @returns {Promise}
   */createClass:function createClass(data){var adapter=this;var url=adapter.get('namespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data.body)};return _ember['default'].$.ajax(url,options);}, /**
   * Updates an existing class
   *
   * @param data class data to be sent in the request body
   * @returns {Promise}
   */updateClass:function updateClass(data){var adapter=this;var classId=data.classId;var namespace=adapter.get('namespace');var url=namespace + '/' + classId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data['class'])};return _ember['default'].$.ajax(url,options);}, /**
   * Updates an existing class
   *
   * @param value class data to be sent in the request body
   * @returns {Promise}
   */updateCommunityCollaboration:function updateCommunityCollaboration(value,classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/community/collaboration/settings';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({enableCommunityCollaboration:value})};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes a class by id
   *
   * @param classId class id to be sent
   * @returns {Promise}
   */deleteClass:function deleteClass(classId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + classId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Remove Student from a specific class
   *
   * @param classId class id to be sent
   * @param userId user id to be deleted
   * @returns {Promise}
   */removeStudentFromClass:function removeStudentFromClass(classId,userId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + classId + '/members/' + userId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Join class
   *
   * @param {string} code class code
   * @returns {Promise}
   */joinClass:function joinClass(code){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + code + '/members';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({}) //empty body is required by the BE
};return _ember['default'].$.ajax(url,options);}, /**
   * Gets the list of classes for a user
   * @returns {Promise}
   */getMyClasses:function getMyClasses(param){var adapter=this;var namespace=adapter.get('namespace');var url='' + namespace;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:param};return _ember['default'].$.ajax(url,options);}, /**
   * Gets the class information of a given class id
   * @param classId the class ID to be read
   * @returns {Promise}
   */readClassInfo:function readClassInfo(classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Get the list of members, invitees, collaborators and owner of the class
   * @param classId the class ID to be read
   * @returns {Promise}
   */readClassMembers:function readClassMembers(classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/members';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Gets the class content visibility
   * @param classId the class ID to be read
   * @returns {Promise}
   */readClassContentVisibility:function readClassContentVisibility(classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/courses';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Update content visibility
   * @param []
   * @returns {Promise}
   */updateContentVisibility:function updateContentVisibility(classId,content){var type=arguments.length <= 2 || arguments[2] === undefined?false:arguments[2];var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/courses';var data=undefined;if(type === 'unit'){data = {units:content.units};}else if(type === 'lesson'){data = {lessons:content.lessons};}else if(type === 'assessment' || type === 'collection' || type === 'offline-activity'){var contentData=undefined;if(type === 'assessment'){contentData = content.assessments;}else if(type === 'collection'){contentData = content.collections;}else {contentData = content.offline_activity;}data = {assessments:contentData};}else {data = content;}var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Associates a Course with a Class
   *
   * @param classId the class id
   * @param courseId the course id
   * @returns {Promise}
   */associateCourseToClass:function associateCourseToClass(courseId,classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/courses/' + courseId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Gets the class report status for an archived class
   * @param {string} classId the class id
   * @param {string} courseId the course id
   * @param {string} userId the user id
   * @returns {Promise.<string>} available|queued|in-progress
   */readClassReportStatus:function readClassReportStatus(classId,courseId,userId){var adapter=this;var namespace=adapter.get('reportNamespace');var sessionToken=encodeURIComponent(this.get('session.token-api3'));var url=namespace + '/class/' + classId + '/course/' + courseId + '/download/request?sessionToken=' + sessionToken + '&userId=' + userId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Creates the headers required by API 3.0
   * @returns {{Authorization: string}}
   */defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}, /**
   * apply co-teacher From Class
   * @param classId
   * @param userId the user id to apply owner
   * @returns {Promise}
   */applyOwnerSettings:function applyOwnerSettings(classId,collaboratorId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + classId + '/owner/transfer';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({user_id:collaboratorId})};return _ember['default'].$.ajax(url,options);}, /**
   * Remove co-teacher From Class
   * @param classId
   * @param userId the user id to delete
   * @returns {Promise}
   */removeCoTeacherFromClass:function removeCoTeacherFromClass(classId,collaborator){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + classId + '/collaborators';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({collaborator:collaborator})};return _ember['default'].$.ajax(url,options);}, /**
   * @function joinCoTeacherIntoClass
   * Method to join as a co-teacher into a class
   */joinCoTeacherIntoClass:function joinCoTeacherIntoClass(classCode){var adapter=this;var namespace=this.get('demoNamespace');var url=namespace + '/coteacher';var options={type:'POST',contentType:'application/json',headers:adapter.defineHeaders(),data:JSON.stringify({code:classCode})};return _ember['default'].$.ajax(url,options);}, /**
   * profileBaseLine class
   * @param classId Identifier of the class to be baseLine
   * @param users Array of profileids which needs to be baselined, a empty array is for whole class
   * @returns {Promise}
   */profileBaseLine:function profileBaseLine(classId,users){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/profilebaseline';var options={type:'POST',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({users:users?[users]:[]})};return _ember['default'].$.ajax(url,options);}, /**
   * Updates class settings
   * @param classId Identifier of the class to update
   * @param settings {"grade_lower_bound" : 1, "grade_upper_bound" : 3, "grade_current" : 2, "route0: true}
   * @returns {Promise}
   */classSettings:function classSettings(classId,settings){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/settings/reroute';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify(settings)};return _ember['default'].$.ajax(url,options);}, /**
   * Updates grade settings, Grade Settings for Student
   * @param classId Identifier of the class to update
   * @param settings {"grade_lower_bound" : 1, "grade_upper_bound" : 3, "grade_current" : 2, "route0: true}
   * @example
   * <pre>PUT /api/nucleus/{version}/classes/{class-id}/members/settings/reroute
   { "grade_lower_bound" : 1, "grade_upper_bound" : 3, "users" : ["68492039-3713-42de-90ad-94d5945cd482", "a71fc3aa-38b4-41bd-b7ef-6be7b509d3d7"] }
   * @returns {Promise}
   */classMembersSettings:function classMembersSettings(classId,settings){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/members/settings/reroute';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify(settings)};return _ember['default'].$.ajax(url,options);}, /**
   * Updates grade settings, Grade Settings for Student
   * @param classId Identifier of the class to update
   * @param users {"grade_lower_bound" : 1, "grade_upper_bound" : 3, "grade_current" : 2, "route0: true}
   * @example
   *  PUT /api/nucleus/{version}/classes/{class-id}/members/deactivate
   { "users" : ["68492039-3713-42de-90ad-94d5945cd482", "a71fc3aa-38b4-41bd-b7ef-6be7b509d3d7"] }
   * @returns {Promise}
   */classMembersDeactivate:function classMembersDeactivate(classId,users){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/members/deactivate';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify(users)};return _ember['default'].$.ajax(url,options);}, /**
   * Updates grade settings, Grade Settings for Student
   * @param classId Identifier of the class to update
   * @param users
   * @example
   *  PUT /api/nucleus/{version}/classes/{class-id}/members/activate
   { "users" : ["68492039-3713-42de-90ad-94d5945cd482", "a71fc3aa-38b4-41bd-b7ef-6be7b509d3d7"] }
   * @returns {Promise}
   */classMembersActivate:function classMembersActivate(classId,users){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/members/activate';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify(users)};return _ember['default'].$.ajax(url,options);}, /**
   * @function updatePreference
   * Method to update class preference
   */updatePreference:function updatePreference(classid,preference){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classid + '/preference';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({preference:preference})};return _ember['default'].$.ajax(url,options);}, /**
   * @function updateLanguage
   * Method to update class language
   */updateLanguage:function updateLanguage(classid,language){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classid + '/language/' + language;var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * @function updateProfileBaseline
   * Method to update profile baseline
   */updateProfileBaseline:function updateProfileBaseline(classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/profilebaseline/student';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * @function addStudentsToClass
   * @param {UUID} classId
   * @param {Object} dataParam
   * Method to add students into a class
   */addStudentsToClass:function addStudentsToClass(classId,dataParam){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/students';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify(dataParam)};return _ember['default'].$.ajax(url,options);}, /**
   * @function updateClassSetupFlag
   * @param {UUID} classId
   * @param {Object} setting
   * Method to update class complete setup setting
   */updateClassSetupFlag:function updateClassSetupFlag(classId,setting){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(setting)};return _ember['default'].$.ajax(url,options);},readBulkClassDetails:function readBulkClassDetails(){var classIds=arguments.length <= 0 || arguments[0] === undefined?[]:arguments[0];var adapter=this;var namespace=this.get('namespaceV2');var url=namespace + '/details';var options={type:'POST',contentType:'application/json',headers:adapter.defineHeaders(),data:JSON.stringify({classIds:classIds})};return _ember['default'].$.ajax(url,options);},googleClassroomAuth:function googleClassroomAuth(redirectUrl){var adapter=this;var namespace=this.get('classroomNameSpace');var url=namespace + '/auth/google/classroom/authorize';var options={type:'POST',contentType:'application/json',headers:adapter.defineHeaders(),data:JSON.stringify({redirectUrl:redirectUrl})};return _ember['default'].$.ajax(url,options);},fetchAccessToken:function fetchAccessToken(){var namespace=this.get('classroomNameSpace');var url=namespace + '/auth/google/classroom/token';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders()};return _ember['default'].$.ajax(url,options);},fetchClassRoomList:function fetchClassRoomList(){var adapter=this;var namespace=this.get('classroomNameSpace');var url=namespace + '/google/classroom/class';var options={type:'GET',contentType:'application/json',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * @function updateClassRoomList
   * @param {UUID} classId
   * @param {userId} userId
   * @param {googleUserId} googleUserId
   * Method to update class room settings
   */updateClassRoomList:function updateClassRoomList(classId,listStudent){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/google/classroom/users';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({class_members:listStudent})};return _ember['default'].$.ajax(url,options);},updateGoogleClassSettings:function updateGoogleClassSettings(classId,googleUserId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + classId + '/google/classroom/settings';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({googleClassroomId:googleUserId})};return _ember['default'].$.ajax(url,options);},fetchStudentList:function fetchStudentList(googleUserId){var adapter=this;var namespace=this.get('classroomNameSpace');var url=namespace + '/google/classroom/class/' + googleUserId + '/students';var options={type:'GET',contentType:'application/json',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Delete Google Classroom
   * @returns {Promise}
   */deleteGoogleClassroom:function deleteGoogleClassroom(){var adapter=this;var namespace=this.get('classroomNameSpace');var url=namespace + '/auth/google/classroom/authorize/revoke';var options={type:'DELETE',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Triggers auto Class setup
   *
   * @param data class data to be sent in the request body
   * @returns {Promise}
   */triggerAutoClassSetup:function triggerAutoClassSetup(data){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/setup';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * @function getStudentAvailability
   * @param {Object} dataParam
   *  Method to check student available status
   */getStudentAvailability:function getStudentAvailability(dataParam){var adapter=this;var namespace=adapter.get('profileNameSpace');var url=namespace + '/users/details';var options={type:'POST',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify(dataParam)};return _ember['default'].$.ajax(url,options);}, /**
   * @param data classroom -d data to be sent in the request body
   * @returns {Promise}
   */createClassroomAssignments:function createClassroomAssignments(classroomId,data){var adapter=this;var namespace=this.get('classroomNameSpace');var url=namespace + '/google/classroom/class/' + classroomId + '/assignments';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);},getShortenerUrl:function getShortenerUrl(fullUrl){var adapter=this;var namespace=this.get('shortenerUrlNamespace');var url=namespace + '/url';var options={type:'POST',contentType:'application/json',headers:adapter.defineHeaders(),data:JSON.stringify({url:fullUrl})};return _ember['default'].$.ajax(url,options);},sendWelcomeMail:function sendWelcomeMail(params){var adapter=this;var namespace=this.get('sendMailNamespace');var url=namespace + '/emails';var options={type:'POST',contentType:'application/json',headers:adapter.defineHeaders(),data:JSON.stringify({mail_template_name:params.templateName,to_addresses:params.emailIds,mail_template_context:{teacher_name:params.teacherName,class_name:params.className,class_code:params.classCode,signup_url:params.signupURL}})};return _ember['default'].$.ajax(url,options);}});});