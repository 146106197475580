define('gooru-web/components/gru-grade-items',['exports','ember'],function(exports,_ember){ /**
 * Grade Items component
 *
 * Component responsible to show questions pending grading in the teacher class performance tab
 * This component can be reused across the entire application and can be styled as needed,
 * functionality should remain the same
 *
 * @module
 * @augments Ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-grade-items'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Trigger to open free response question grade pull
     * @param  {Object} itemToGrade
     */openReportGrade:function openReportGrade(itemToGrade){this.sendAction('openReportGrade',itemToGrade);}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * The Items needs to grade
   * @property {GradeItem[]} items
   */gradeableItems:_ember['default'].A(), /**
   * Maintains the state of question items to grade pull up
   * @type {Boolean}
   */showFRQuestionGrade:false // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});