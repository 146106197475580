define('gooru-web/serializers/competency/struggling-competency',['exports','ember','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){ /**
 * Serializer for Struggling Competency endpoints
 *
 * @typedef {Object} StrugglingCompetencySerializer
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{session:_ember['default'].inject.service('session'), /**
   * Normalized data of struggling competency
   * @return {Object}
   */normalizeStrugglingCompetency:function normalizeStrugglingCompetency(payload){var serialize=this;var strugglingCompetency=payload.struggling_competencies?payload.struggling_competencies:[];var strugglingCompetencyPayload=[];if(strugglingCompetency && strugglingCompetency.length){strugglingCompetency.forEach(function(grade){strugglingCompetencyPayload.pushObject(_ember['default'].Object.create({gradeId:grade.grade_id,grade:grade.grade,gradeSeq:grade.grade_Seq,description:grade.description,fwCode:grade.fw_code,domains:serialize.normalizeDomains(grade.domains)}));});}return strugglingCompetencyPayload;}, /**
   * Normalized data of struggling competency domains
   * @return {Object}
   */normalizeDomains:function normalizeDomains(payload){var _this=this;var domains=payload?payload:null;var domainList=[];if(domains && domains.length){domains.map(function(domain){domainList.pushObject(_ember['default'].Object.create({competencies:_this.normalizeCompetency(domain.competencies),domainCode:domain.domain_code,domainId:domain.domain_id,domainName:domain.domain_name,domainSeq:domain.domain_seq}));});}return domainList;}, /**
   * Normalized data of domains competencies
   * @return {Object}
   */normalizeCompetency:function normalizeCompetency(payload){var competencies=payload?payload:null;var competencyList=[];if(competencies && competencies.length){competencies.map(function(competency){competencyList.pushObject(_ember['default'].Object.create({code:competency.comp_code,displayCode:competency.comp_display_code,name:competency.comp_name,sequence:competency.comp_seq,studentsDescription:competency.comp_student_desc,studentsCount:competency.student_count}));});}return competencyList.length?competencyList.sortBy('studentsCount').reverse():competencyList;}, /**
   * Normalized data of student performance
   * @return {Object}
   */normalizeStudentsPerfomance:function normalizeStudentsPerfomance(payload){var basePath=this.get('session.cdnUrls.user');var appRootPath=this.get('appRootPath'); //configuration appRootPath
var students=payload.students?payload.students:[];var studentsList=[];if(students && students.length){students.forEach(function(student){var thumbnailUrl=student.thumbnail?basePath + student.thumbnail:appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.USER_PROFILE;studentsList.pushObject(_ember['default'].Object.create({id:student.id,firstName:student.first_name,lastName:student.last_name,displayName:student.display_name,thumbnail:thumbnailUrl,username:student.username,performanceScore:student.performance}));});}return studentsList;},answerStrugglingCompetency:function answerStrugglingCompetency(payload){var struggles=payload.struggles?payload.struggles:[];var strugglesList=_ember['default'].A();struggles.forEach(function(item){var struggle={manifestCompCode:item.manifest_comp_code,manifestDisplayCode:item.manifest_comp_display_code,originCompCode:item.origin_comp_code,originDisplayCode:item.origin_comp_display_code,struggleCode:item.tx_struggle_code,struggleDesc:item.tx_struggle_desc,struggleDisplayText:item.tx_struggle_display_text,subjectCode:item.tx_subject_code};strugglesList.push(_ember['default'].Object.create(struggle));});return strugglesList;},serializeStruggleList:function serializeStruggleList(params){var result={struggles:[]};params.struggles = params.struggles.flat(1);params.struggles && params.struggles.forEach(function(item){var struggle={manifestCompCode:item.manifestCompCode,manifestDisplayCode:item.manifestDisplayCode,originCompCode:item.originCompCode,originDisplayCode:item.originDisplayCode,struggleCode:item.struggleCode,struggleDesc:item.struggleDesc,struggleDisplayText:item.struggleDisplayText,subjectCode:item.subjectCode};result.struggles.push(struggle);});return result;}});});