define('gooru-web/routes/content/courses/edit',['exports','ember','gooru-web/models/content/builder/item','gooru-web/mixins/private-route-mixin'],function(exports,_ember,_gooruWebModelsContentBuilderItem,_gooruWebMixinsPrivateRouteMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{queryParams:{editing:{refreshModel:true},classId:{},userId:{},isLibraryContent:false}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/course
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Methods
model:function model(params){var course=this.get('courseService').fetchById(params.courseId);var isEditing=params.editing;var isLibraryContent=params.isLibraryContent;return _ember['default'].RSVP.hash({course:course,isEditing:isEditing === 'true',userId:params.userId,classId:params.classId,isLibraryContent:isLibraryContent});},setupController:function setupController(controller,model){var course=model.course;var unitId=controller.get('unitId');course.children = course.children.map(function(unit){ // Wrap every unit inside of a builder item
return _gooruWebModelsContentBuilderItem['default'].create({data:unit,isExpanded:unitId === unit.get('id')});});controller.set('course',course);controller.set('userId',model.userId);controller.set('classId',model.classId);controller.set('isEditing',model.isEditing);controller.set('isLibraryContent',model.isLibraryContent); //Create a tempCourse copy at once the route load
controller.set('tempCourse',course.copy());},deactivate:function deactivate(){this.get('controller').resetValues();this.get('controller').set('isEditing','false');}});});