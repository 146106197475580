define('gooru-web/models/suggest/suggest-context',['exports','ember'],function(exports,_ember){ /**
 * Model for suggest context
 *
 * @typedef {Object} SuggestContext
 *
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string}
   */containerId:null, /**
   * @property {string}
   */userId:null, /**
   * @property {string}
   */courseId:null, /**
   * @property {string}
   */unitId:null, /**
   * @property {string}
   */lessonId:null, /**
   * @property {number}
   */score:null, /**
   * @property {number}
   */timeSpent:null});});