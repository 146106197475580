define('gooru-web/components/cards/gru-course-card',['exports','ember','gooru-web/mixins/modal','gooru-web/models/taxonomy/taxonomy-tag'],function(exports,_ember,_gooruWebMixinsModal,_gooruWebModelsTaxonomyTaxonomyTag){ /**
 * Course card
 *
 * Component responsible of showing the course information in cards, so that most useful information is summarized there.
 * @module
 * @see controllers/profile/about.js
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['cards','gru-course-card'],classNameBindings:['isSmall:small'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     *Action triggered when select edit the course
     */editCourse:function editCourse(){this.sendAction('onEditCourse',this.get('course'));}, /**
     *Action triggered when select play the course
     */playCourse:function playCourse(){this.playCourse();}, /**
     *Action triggered when select remix the course
     */remixCourse:function remixCourse(){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {this.sendAction('onRemixCourse',this.get('course'));}}, /**
     * Action triggered to preview the course
     * @param course
     */previewCourse:function previewCourse(course){var component=this;var isTeacher=this.get('isTeacher');var model=_ember['default'].Object.create({content:course,isTeacher:isTeacher});model.set('remixCourse',function(){return component.remixCourse();});model.set('playCourse',function(){return component.playCourse();});model.set('bookmarkCourse',function(){return component.bookmarkCourse();});component.send('showModal','gru-preview-course',model);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Course} course
   */course:null, /**
   * Bookmark course action
   * @property {string}
   */onBookmarkCourse:null, /**
   * Edit course action
   * @property {string}
   */onEditCourse:null, /**
   * Remix course action
   * @property {string}
   */onRemixCourse:null, /**
   * default tagsVisible
   * @property {number}
   */tagsVisible:2, /**
   * flag that tells if you are owner of card or not.
   * @property {Boolean}
   */isOwner:false, /**
   * Edit enabled is a flag for whether the edit button is enabled or not.
   * @property {Boolean}
   */isEditEnabled:false, /**
   * Edit enabled is a flag for whether the remix button is enabled or not.
   * @property {Boolean} course
   */isRemixEnabled:true, /**
   * Edit enabled is a flag for whether the preview button is enabled or not.
   * @property {Boolean} course
   */isPreviewEnabled:false, /**
   * Show if the visibility icon is visible or not.
   * @property {Boolean}
   */isEyeVisible:true, /**
   * Indicates if it is a small card
   * @property {boolean}
   */isSmall:false, /**
   * Indicates if it is from student/teacher landing page
   * @property {boolean}
   */isFromLandingPage:false, /**
   * Show if the taxonomy tags are visible or not.
   * @property {Boolean}
   */showTaxonomy:true, /**
   * @property {Array} users
   */users:_ember['default'].computed('course',function(){return this.get('course.remixedBy');}), /**
   * @property {String} subjects
   */subjects:_ember['default'].computed('course',function(){ // TODO Verify if this method is required
/*
    var subjectsList = this.get("course.subjects");
    var subjects = "";
    subjectsList.forEach(function(object){
      subjects+=" "+object+" |";
    });
    return subjects.substr(0, subjects.length-1);
    */return '';}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('course.taxonomy.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('course.taxonomy'));}), /**
   * Indicates if the teacher is seeing the card
   * @property {boolean}
   */isTeacher:_ember['default'].computed.equal('profile.role','teacher'), /**
   * @property {Profile} user profile
   */profile:null, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){$('[data-toggle="tooltip"]').tooltip();}, // -------------------------------------------------------------------------
// Methods
/**
   * Selecting to bookmark a course
   */bookmarkCourse:function bookmarkCourse(){this.sendAction('onBookmarkCourse',this.get('course'));}, /**
   * Selecting to play a course
   */playCourse:function playCourse(){this.sendAction('onPlayCourse',this.get('course'));}, /**
   * Selecting to remix a course
   */remixCourse:function remixCourse(){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {var remixModel={content:this.get('course')};this.send('showModal','content.modals.gru-course-remix',remixModel);}}});});