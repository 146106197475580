define('gooru-web/models/result/report-data',['exports','ember','gooru-web/models/result/question','gooru-web/config/question'],function(exports,_ember,_gooruWebModelsResultQuestion,_gooruWebConfigQuestion){ /**
 * Report data model for class assessment report
 *
 * @typedef {Object} ReportData
 *
 */exports['default'] = _ember['default'].Object.extend({ // -------------------------------------------------------------------------
// Events
/**
   *  Initializes the report data
   */init:function init(){var studentIds=this.get('studentIds');var resourceIds=this.get('resourceIds');if(!studentIds.length){_ember['default'].Logger.error('Report data cannot be initialized without students');}if(!resourceIds.length){_ember['default'].Logger.error('Report data cannot be initialized without resources');}this.set('data',this.getEmptyMatrix(studentIds,resourceIds));},willDestroy:function willDestroy(){this.set('data',null);}, // -------------------------------------------------------------------------
// Properties
/**
   * { Object{}{}{} } cumulativeData
   *
   * Internal matrix that serves as a buffer and stores all changes made to the report data.
   * Any changes made to 'userResults', update this matrix first. Then, this matrix is copied and
   * served to 'reportData' (which guarantees that any observers or computed properties on
   * 'reportData' are fired)
   *
   * Sample structure
   *
   * The "question#" corresponds to the actual question id
   *  {
   *    user1 {
   *      question1 : QuestionResult,
   *      question2 : QuestionResult,
   *      question3 : QuestionResult
   *     },
   *    user2 {
   *      question1 : QuestionResult,
   *      question2 : QuestionResult,
   *      question3 : QuestionResult
   *    }
   *  }
   */data:null, /**
   * @property {User[]} student
   */students:null, /**
   * @property {string[]} studentIds - List of student ids
   */studentIds:_ember['default'].computed('students',function(){return this.get('students').map(function(student){return student.get('id');});}), /**
   * @property {Resource[]} resource
   */resources:null, /**
   * @property {string[]} studentIds - List of student ids
   */resourceIds:_ember['default'].computed('resources',function(){return this.get('resources').map(function(resource){return _ember['default'].get(resource,'id');});}), // -------------------------------------------------------------------------
// Methods
/**
   * Takes a map of QuestionResults and for each one that doesn't have a boolean value
   * (i.e. true or false) for "correct", sets its "correct" property to false.
   *
   * @param { Object } QuestionResultMap - A map of QuestionResults (i.e. object where each key is a
   * resource id and the content is a QuestionResult instance)
   * @param resourceIds - An array of resource IDs
   * @return {Object}
   */autoCompleteRow:function autoCompleteRow(QuestionResultMap,resourceIds){resourceIds.forEach(function(resourceId){var questionResult=QuestionResultMap[resourceId];if(!questionResult.get('started')){questionResult.set('correct',false);}});return QuestionResultMap;}, /**
   * Create a matrix of empty objects from a couple of arrays
   * @param {String[]} idsY - An array of ids used for the first dimension of the matrix
   * @param {String[]} idsX - An array of ids used for the second dimension of the matrix
   * @return {Object}
   */getEmptyMatrix:function getEmptyMatrix(idsY,idsX){var matrix={};var yLen=idsY.length;for(var i=0;i < yLen;i++) {matrix[idsY[i]] = this.getEmptyRow(idsX);}return matrix;}, /**
   * Create an object full of empty results
   * @param {String[]} columnIds - An array of ids of all the items in the row
   * @return { QuestionResult[] }
   */getEmptyRow:function getEmptyRow(columnIds){var row={};var rowLen=columnIds.length;for(var i=0;i < rowLen;i++) {row[columnIds[i]] = _gooruWebModelsResultQuestion['default'].create({resourceId:columnIds[i]});}return row;}, /**
   * Merges new data
   * @param {UserResourcesResult[]} userResults
   * @returns {merge}
   */merge:function merge(userResults){var reportData=undefined;var data=this.get('data');var resourceIds=this.get('resourceIds');userResults // Filter in case a student has been removed from the course
.filter(function(result){return data.hasOwnProperty(result.get('user'));}).forEach(function(userResult){var userId=userResult.get('user');var doReset=userResult.get('isAttemptStarted');var doAutoComplete=userResult.get('isAttemptFinished');var resourceResults=userResult.get('resourceResults');if(doReset){data[userId] = this.getEmptyRow(resourceIds);}resourceResults // Filter in case a resource/question has been removed from the collection/assessment
.filter(function(result){return resourceIds.indexOf(_ember['default'].get(result,'resourceId')) > -1;}).forEach(function(resourceResult){if(data[userId]){var questionId=_ember['default'].get(resourceResult,'resourceId');if(data[userId][questionId]){ //if there are several attempts for the same resource the time spent should be added
var totalTimeSpent=_ember['default'].get(resourceResult,'timeSpent') + data[userId][questionId].get('timeSpent');_ember['default'].set(resourceResult,'timeSpent',totalTimeSpent);}data[userId][questionId] = resourceResult;}});if(doAutoComplete){this.autoCompleteRow(data[userId],resourceIds);}},this); // Generate a new object so any computed properties listening on reportData are fired
if(Object.assign){ // Preferred way to merge the contents of two objects:
// https://github.com/emberjs/ember.js/issues/12320
reportData = Object.assign({},data);}else { // Use Ember.merge as a fallback
reportData = _ember['default'].merge({},data);}this.set('data',reportData);return this;}, /**
   * Retrieves all student results by question
   *
   * @param {string} questionId
   * @returns { QuestionResult[] }
   */getResultsByQuestion:function getResultsByQuestion(questionId){var reportData=this.get('data');var questionResults=_ember['default'].A([]);this.get('studentIds').forEach(function(studentId){var userQuestionResults=reportData[studentId];if(userQuestionResults){var questionResult=userQuestionResults[questionId];if(questionResult){questionResults.addObject(questionResult);}else {_ember['default'].Logger.warn('Missing question data ' + studentId + ' question ' + questionId);}}else {_ember['default'].Logger.warn('Missing student data ' + studentId);}});return questionResults;}, /**
   * Retrieves all results by student
   *
   * @param {string} studentId
   * @returns { QuestionResult[] }
   */getResultsByStudent:function getResultsByStudent(studentId){var reportData=this.get('data');var questionResults=_ember['default'].A([]);var userQuestionResults=reportData[studentId];if(userQuestionResults){for(var key in userQuestionResults) {if(userQuestionResults.hasOwnProperty(key)){questionResults.addObject(userQuestionResults[key]);}}}else {_ember['default'].Logger.warning('Missing student data ' + studentId);}return questionResults;}, /**
   * Retrieves all student results
   *
   * @returns { QuestionResult[] }
   */getAllResults:function getAllResults(){var self=this;var questionResults=_ember['default'].A([]);this.get('studentIds').forEach(function(studentId){var studentResults=self.getResultsByStudent(studentId);questionResults.addObjects(studentResults.toArray());});return questionResults;}, /**
   * Return all the student who submitted the answer
   * @param {Resource} question
   * @param {*} answer user answer
   * @returns {User[]}
   */getStudentsByQuestionAndUserAnswer:function getStudentsByQuestionAndUserAnswer(question,answer){var reportData=this.get('data');var questionId=question.get('id');var util=(0,_gooruWebConfigQuestion.getQuestionUtil)(question.get('questionType')).create({question:question});var found=_ember['default'].A([]);this.get('students').forEach(function(student){var studentId=student.get('id');var userQuestionResults=reportData[studentId];if(userQuestionResults){var questionResult=userQuestionResults[questionId];var answered=questionResult && questionResult.get('answered');if(answered){var sameAnswer=util.sameAnswer(answer,questionResult.get('userAnswer'));if(sameAnswer){found.addObject(student);}}}else {_ember['default'].Logger.warning('Missing student data ' + studentId);}});return found;}});});