define('gooru-web/components/content/modals/gru-edit-attachment',['exports','ember'],function(exports,_ember){ //  import ReferenceModel from 'gooru-web/models/content/oa/reference';
exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-edit-attachment'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {MediaService} Media service API SDK
   */mediaService:_ember['default'].inject.service('api-sdk/media'), /**
   * @requires service:api-sdk/offline-activity/offline-activity
   */resourceService:_ember['default'].inject.service('api-sdk/question'), // -------------------------------------------------------------------------
// Properties
/**
   * @property hold uploaded file name
   */filename:null, /**
   * @property {model} reference model being edited
   */model:null, /**
   * @property {Array} references
   * property hold user reference list of data
   */references:_ember['default'].A([]), /**
   * Indicate if it's waiting for createCollection callback
   */isLoading:false, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);if(this.get('model')){var content=this.get('model');this.set('filename',content);}}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * when sumbit filename edit option
     */updateContent:function updateContent(){var component=this;component.validate().then(function(isValid){if(isValid){(function(){component.set('isLoading',true);var editRefModel=component.get('model');component.uploadSelectedFile().then(function(fileId){var references=editRefModel.get('references');var newRefModal=_ember['default'].Object.create({filePath:fileId,fileName:component.get('model.fileName'),subType:component.get('model.subType'),type:component.get('model.type')});references.pushObject(newRefModal);component.triggerAction({action:'closeModal'});});})();}});}, //Action triggered when click close
closeModal:function closeModal(){this.set('isLoading',false);this.triggerAction({action:'closeModal'});}}, /**
   * uploading files
   */uploadSelectedFile:function uploadSelectedFile(){var component=this;var fileToUpload=this.get('model').file;var fileIdPromise=new _ember['default'].RSVP.resolve(fileToUpload);if(fileToUpload){fileIdPromise = component.get('mediaService').uploadContentFile(fileToUpload);}return fileIdPromise;}, /**
   * update reference data in UI without refresh
   */updateParent:function updateParent(model,createdRefModel,fileId){var component=this;var createdNewRef=_ember['default'].Object.create({fileName:createdRefModel.fileName,id:createdRefModel.id,location:fileId,type:createdRefModel.type,subType:createdRefModel.subType});var modelRef=model.get('reference');modelRef.pushObject(createdNewRef);component.refreshReference();}, /**
   * Validates string by passing value
   */validate:function validate(){var component=this;var model=component.get('model');var didValidate;if(model.get('type') === 'uploaded'){didValidate = new _ember['default'].RSVP.Promise(function(resolve){resolve(true);});}else {didValidate = new _ember['default'].RSVP.Promise(function(resolve){if(model){model.validate().then(function(_ref){var validations=_ref.validations;return resolve(validations.get('isValid'));},function(){return resolve(false);});}});}component.set('didValidate',didValidate);return didValidate;}});});