define('gooru-web/services/api-sdk/resource',['exports','ember','gooru-web/serializers/content/resource','gooru-web/adapters/content/resource'],function(exports,_ember,_gooruWebSerializersContentResource,_gooruWebAdaptersContentResource){ /**
 * @typedef {Object} ResourceService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {ResourceSerializer} resourceSerializer
   */resourceSerializer:null, /**
   * @property {ResourceAdapter} resourceAdapter
   */resourceAdapter:null, /**
   * @property {AssessmentService}
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {AssessmentService}
   */collectionService:_ember['default'].inject.service('api-sdk/collection'),init:function init(){this._super.apply(this,arguments);this.set('resourceSerializer',_gooruWebSerializersContentResource['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('resourceAdapter',_gooruWebAdaptersContentResource['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Creates a new resource
   *
   * @param resourceData object with the resource data
   * @returns {Promise}
   */createResource:function createResource(resourceData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedClassData=service.get('resourceSerializer').serializeCreateResource(resourceData);service.get('resourceAdapter').createResource({body:serializedClassData}).then(function(responseData,textStatus,request){var resourceId=request.getResponseHeader('location');resourceData.set('id',resourceId);resolve(resourceData);},function(error){if(error.status === 400){ //when the resource already exists
var data=JSON.parse(error.responseText);var alreadyExists=data && data.duplicate_ids && data.duplicate_ids.length;if(alreadyExists){reject({status:400,resourceId:data.duplicate_ids[0]});}}else {reject(error);}});});}, /**
   * Creates a Fb Checker
   *
   * @param resourceData object with the resource data
   * @returns {Promise}
   */createFbChecker:function createFbChecker(resourceData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('resourceAdapter').createFbChecker(resourceData).then(function(fbData){resolve(fbData);},function(error){reject(error);});});}, /**
   * Finds an resources by id
   * @param {string} resourceId
   * @returns {Ember.RSVP.Promise}
   */readResource:function readResource(resourceId){var service=this;var serializer=service.get('resourceSerializer');return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('resourceAdapter').readResource(resourceId).then(function(responseData /*, textStatus, request */){resolve(serializer.normalizeReadResource(responseData));},reject);});}, /** Fetches the usm details for given usm standard UUIDs
   */getUSMStandardsInfo:function getUSMStandardsInfo(params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('resourceAdapter').getUSMStandardsInfo(params).then(function(responseData /*, textStatus, request */){resolve(responseData);},reject);});}, /**
   * Updates a resource
   *
   * @param resourceId the id of the resource to be updated
   * @param resourceModel the resource model with the data
   * @param {content/Collection|content/Assessment} collection provided when the resource belongs to a collection
   * @returns {Promise}
   */updateResource:function updateResource(resourceId,resourceModel){var collection=arguments.length <= 2 || arguments[2] === undefined?null:arguments[2];var isPublisher=arguments.length <= 3 || arguments[3] === undefined?true:arguments[3];var service=this;var serializedData=service.get('resourceSerializer').serializeUpdateResource(resourceModel,isPublisher);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('resourceAdapter').updateResource(resourceId,serializedData).then(function(){service.notifyQuizzesCollectionChange(collection);resolve();},function(error){if(error.status === 400){ //when the resource already exists
var data=JSON.parse(error.responseText);var alreadyExists=data && data.duplicate_ids && data.duplicate_ids.length;if(alreadyExists){reject({status:400,resourceId:data.duplicate_ids[0]});}}else {reject(error);}});});}, /**
   * Updates the resource title
   *
   * @param resourceId the id of the resource to be updated
   * @param title the resource title
   * @param {content/Collection|content/Assessment} collection provided when the resource belongs to a collection
   * @returns {Promise}
   */updateResourceTitle:function updateResourceTitle(resourceId,title){var collection=arguments.length <= 2 || arguments[2] === undefined?null:arguments[2];var service=this;var serializedData=service.get('resourceSerializer').serializeUpdateResourceTitle(title);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('resourceAdapter').updateResource(resourceId,serializedData).then(function(){service.notifyQuizzesCollectionChange(collection);resolve();},reject);});}, /**
   * Copies a resources by id
   * @param {string} resourceId
   * @param {string} title
   * @returns {Ember.RSVP.Promise}
   */copyResource:function copyResource(resourceId){var title=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('resourceAdapter').copyResource(resourceId,title).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},reject);});}, /**
   * Delete resource
   *
   * @param resourceId resource id to delete
   * @param {content/Collection|content/Assessment} collection provided when the resource belongs to a collection
   * @returns {Ember.RSVP.Promise}
   */deleteResource:function deleteResource(resourceId){var collection=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('resourceAdapter').deleteResource(resourceId).then(function(){service.notifyQuizzesCollectionChange(collection);resolve();},reject);});}, /**
   * Notifies a change at quizzes for the provided collection
   * @param {content/Collection|content/Assessment} collection
   */notifyQuizzesCollectionChange:function notifyQuizzesCollectionChange(collection){if(collection){var collectionId=collection.get('id');return collection.get('isAssessment')?this.get('assessmentService').notifyQuizzesAssessmentChange(collectionId):this.get('collectionService').notifyQuizzesCollectionChange(collectionId);}return _ember['default'].RSVP.resolve(false);}});});