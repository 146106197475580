define('gooru-web/components/content/modals/gru-comprehension-new-question',['exports','ember','gooru-web/config/question'],function(exports,_ember,_gooruWebConfigQuestion){exports['default'] = _ember['default'].Component.extend({ // ------------------------------------------------------------
// Attributes
classNames:['gru-comprehension-new-question'], // ---------------------------------------------------------------
// Properties
/**
   * @property{Array{}} questionTypes
   */questionTypes:_ember['default'].computed(function(){var question=[];Object.keys(_gooruWebConfigQuestion.QUESTION_CONFIG).forEach(function(item){if(!_gooruWebConfigQuestion.QUESTION_CONFIG[item].serpType){question.push(item);}});return question;}) // --------------------------------------------------------------------
// Events
// ------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------
// Methods
});});