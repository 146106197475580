define('gooru-web/adapters/scope-sequence/scope-sequence',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support scope and sequence listing
 *
 * @typedef {Object} ScopeAndSequence
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1',namespacev2:'/api/nucleus/v2',namespaceTaxonomy:'/api/nucleus/v1/taxonomy/frameworks',namespaceStatus:'/api/ds/users/v2/ca', /**
   * Fetch all scope and sequences
   *
   * @param {Object} params
   * @returns {Promise}
   */fetchScopeAndSequence:function fetchScopeAndSequence(params){var adapter=this;var url=adapter.get('namespace') + '/scope-sequences';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch all scope and sequences
   *
   * @param {Object} params
   * @returns {Promise}
   */fetchModulesByScopeId:function fetchModulesByScopeId(pathParams){var params=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var adapter=this;var url=adapter.get('namespace') + '/scope-sequences/' + pathParams.ssId + '/modules';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch list of topics details for a given module Id
   * @param {Number} moduleId
   * @param {Object} params
   * @returns {Promise}
   */fetchTopicsByModule:function fetchTopicsByModule(pathParams){var params=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var adapter=this;var url=adapter.get('namespace') + '/scope-sequences/' + pathParams.ssId + '/modules/' + pathParams.moduleId + '/topics';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch list of topics details for a given module Id
   * @param {Number} moduleId
   * @param {Object} params
   * @returns {Promise}
   */fetchCompeteciesByTopics:function fetchCompeteciesByTopics(pathParams){var params=arguments.length <= 1 || arguments[1] === undefined?{}:arguments[1];var adapter=this;var url=adapter.get('namespace') + '/scope-sequences/' + pathParams.ssId + '/topics/' + pathParams.topicId + '/competencies';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch list of content source that are assigned to the subject
   * @param {Object} params
   * @returns {Promise}
   */fetchContentSources:function fetchContentSources(){var params=arguments.length <= 0 || arguments[0] === undefined?{}:arguments[0];var adapter=this;var url=adapter.get('namespace') + '/libraries/class-activities/content-sources';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch domains by grade
   * @param {Object} pathParams
   * @returns {Promise}
   */fetchDomainsByGrade:function fetchDomainsByGrade(pathParams){var adapter=this;var url=adapter.get('namespaceTaxonomy') + '/' + pathParams.fwId + '/subjects/' + pathParams.subjectId + '/grades/' + pathParams.gradeId + '/domains';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch list of topics by domain id
   * @param {Object} pathParams
   * @returns {Promise}
   */fetchTopicsByDomain:function fetchTopicsByDomain(pathParams){var adapter=this;var url=adapter.get('namespaceTaxonomy') + '/' + pathParams.fwId + '/subjects/' + pathParams.subjectId + '/domains/' + pathParams.domainId + '/topics';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch list of competencies by domain topics
   * @param {Object} pathParams
   * @returns {Promise}
   */fetchCompetenciesByDomainTopic:function fetchCompetenciesByDomainTopic(pathParams){var adapter=this;var url=adapter.get('namespaceTaxonomy') + '/' + pathParams.fwId + '/subjects/' + pathParams.subjectId + '/domains/' + pathParams.domainId + '/topics/' + pathParams.topicId + '/competencies';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Fetch list of competencies by grade
   * @param {Object} pathParams
   * @returns {Promise}
   */fetchCompetenciesByGrade:function fetchCompetenciesByGrade(pathParams){var adapter=this;var url=adapter.get('namespaceTaxonomy') + '/' + pathParams.fwId + '/subjects/' + pathParams.subjectId + '/grades/' + pathParams.gradeId + '/competencies';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);},fetchActivityStatus:function fetchActivityStatus(params){var adapter=this;var url=adapter.get('namespaceStatus') + '/domain/topic/competency/report?classId=' + params;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Help to hold the header for the adapter
   * @returns {Object}
   */defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});