define('gooru-web/components/reports/assessment/questions/gru-true-false',['exports','gooru-web/components/reports/assessment/questions/gru-multiple-choice'],function(exports,_gooruWebComponentsReportsAssessmentQuestionsGruMultipleChoice){ /**
 * True/False
 *
 * Component responsible for show the true/false answer, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _gooruWebComponentsReportsAssessmentQuestionsGruMultipleChoice['default'].extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-true-false'] // -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Methods
});});