define('gooru-web/components/gru-two-tier-header-table-1',['exports','gooru-web/components/gru-two-tier-header-table','gooru-web/config/config','ember'],function(exports,_gooruWebComponentsGruTwoTierHeaderTable,_gooruWebConfigConfig,_ember){exports['default'] = _gooruWebComponentsGruTwoTierHeaderTable['default'].extend({ // -------------------------------------------------------------------------
// Attributes
classNames:[-1], /**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(),didRender:function didRender(){this._super.apply(this,arguments);_ember['default'].run.later(function(){$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});});},didReceiveAttrs:function didReceiveAttrs(){this._super.apply(this,arguments);var component=this;var classNames=['gru-two-tier-header-table-1'];component.set('classNames',classNames);component.setSortedData();},resourceCount:1,caAverage:_ember['default'].computed('data.length','sortCriteria',function(){var cavg=this.get('sortedData')[0].content.length - 1;return cavg - 1;}), /**
   * Get students avatar url if present,
   * if not returns the default profile img
   */setSortedData:function setSortedData(){var appRootPath=this.get('configurationService.configuration.appRootPath')?this.get('configurationService.configuration.appRootPath'):'/';var imageUrl=appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.USER_PROFILE;this.get('sortedData').map(function(data){_ember['default'].set(data,'avatarUrl',data.avatarUrl && data.avatarUrl !== 'undefined'?data.avatarUrl:imageUrl,true);});},updateSortClasses:_ember['default'].observer('sortCriteria',function(){this._super.apply(this,arguments);var sortCriteria=this.get('sortCriteria');if(sortCriteria.secondTierIndex === 1){var totalSecondTierHeaders=this.get('secondTierHeaders').length;var headers=this.$('.second-tier th');var currentHeaderIndex=sortCriteria.firstTierIndex * totalSecondTierHeaders + sortCriteria.secondTierIndex;headers.removeClass('ascending').removeClass('descending');if(currentHeaderIndex >= 0){if(sortCriteria.order > 0){headers.eq(currentHeaderIndex).addClass('ascending');}else {headers.eq(currentHeaderIndex).addClass('descending');}}}}), /**
   * Set default visibility to
   */updateColumnVisibility:_ember['default'].observer('secondTierHeaders.@each.visible',function(){ //this._super(...arguments);
var selectors=[];var offsetStr='-2'; // -2 show only score , -1 score  and time, 0 score and reaction
if(this.isCollectionType){offsetStr = '-0';}else {offsetStr = '-1';}selectors.push('table tr.second-tier th.correct');selectors.push('table tr.data td:nth-child(3n' + offsetStr + ')');var cssSelector=selectors.join(',');this.$(cssSelector).removeClass('hidden');var that=this;if(this.isCollectionType){var scoreCol=$('table tr.second-tier th.correct:first >span');var icn=scoreCol.find('i[data-toggle="tooltip"]'),lbl=scoreCol.find('div.col-label'),lbl1=scoreCol.find('div.sortIcn');lbl.removeClass('hidden');lbl1.removeClass('hidden');var sortIcn=scoreCol.find('.sortIcn');sortIcn.css('display','inline-block');icn.css('display','none');var tsColTitle=this.get('i18n').t('gru-data-picker.timeSpent').string;lbl.text(tsColTitle);sortIcn.addClass('cursor-pointer');}else { //Applicable to score i.e. questions
var innerSpan=this.$(cssSelector).find('span > span.score');innerSpan.map(function(idx,spn){that.resourceCount += 1;if(spn && ($(spn).text() === '' || $(spn).text() === null || $(spn).text() === 'ic_done_all' || $(spn).text() === 'clear')){$(spn).text(that.resourceCount);if(that.resourceCount % 5 === 0){$(spn).addClass('fifth-col');}}else {that.resourceCount = 0;}});if(innerSpan.text() === '' || innerSpan.text() === null){innerSpan.text(this.resourceCount);}else {this.resourceCount = 0;}var scoreCol=$('table tr.second-tier th.correct:first >span');var icn=scoreCol.find('i:first'),lbl=scoreCol.find('div.col-label'),lbl1=scoreCol.find('div.sortIcn');lbl.removeClass('hidden');lbl1.removeClass('hidden');var sortIcn=scoreCol.find('.sortIcn');sortIcn.css('display','inline-block');sortIcn.addClass('cursor-pointer');icn.css('display','none');}})});});