define('gooru-web/controllers/teacher/class/data-dashboard',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // ----------------------------------------------------------------------
// Dependencies
queryParams:{}, // --------------------------------------------------------------------
// Properties
/**
   * Inject the  student class parent controller.
   */studentClassController:_ember['default'].inject.controller('student.class'), /**
   * @property {Array} fwCompetencies
   */fwCompetencies:_ember['default'].computed.alias('studentClassController.fwCompetencies'), /**
   * @property {Array} fwDomains
   */fwDomains:_ember['default'].computed.alias('studentClassController.fwDomains'), /**
   * A link to the parent class controller
   * @see controllers/class.js
   * @property {studentTimespentData}
   */studentTimespentData:_ember['default'].computed.alias('studentClassController.studentTimespentData'), /**
   * @property {Boolean}
   */isPublicClass:_ember['default'].computed.alias('class.isPublic'), /**
   * @property {Object}
   */courseData:_ember['default'].computed.alias('course'),activeComponent:null, // --------------------------------------------------------------------
// Actions
actions:{onGoBack:function onGoBack(){this.transitionToRoute('teacher.class.atc',this.get('currentClass.id'));}} // ---------------------------------------------------------------------
// Methods
});});