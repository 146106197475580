define('gooru-web/services/api-sdk/rubric',['exports','ember','gooru-web/serializers/rubric/rubric','gooru-web/adapters/rubric/rubric'],function(exports,_ember,_gooruWebSerializersRubricRubric,_gooruWebAdaptersRubricRubric){ /**
 * Rubric Service
 *
 * Service responsible for performing CRUD operations on a rubric model
 *
 * @typedef {Object} RubricService
 * @augments Ember/Service
 */exports['default'] = _ember['default'].Service.extend({ // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('serializer',_gooruWebSerializersRubricRubric['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('adapter',_gooruWebAdaptersRubricRubric['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {RubricSerializer} serializer
   */serializer:null, /**
   * @property {RubricAdapter} adapter
   */adapter:null, // -------------------------------------------------------------------------
// Methods
/**
   * Creates a rubric
   * @param {Rubric} rubric
   * @returns {Promise|Rubric} returns the rubric model with the newly assigned ID
   */createRubric:function createRubric(rubric){var data=this.get('serializer').serializeCreateRubric(rubric);return this.get('adapter').createRubric(data).then(function(rubricId){rubric.set('id',rubricId);return rubricId;});}, /**
   * Updates a rubric
   * @param {Rubric} rubric
   * @returns {Promise|Rubric} returns the rubric model with the newly assigned ID
   */updateRubric:function updateRubric(rubric){var data=this.get('serializer').serializeUpdateRubric(rubric);return this.get('adapter').updateRubric(data,rubric.get('id'));}, /**
   * Updates score of question
   * @param {Rubric} rubric
   * @returns {Promise|Rubric} returns the rubric model with the newly assigned ID
   */updateScore:function updateScore(rubricData,questionId){var data=this.get('serializer').serializeUpdateScore(rubricData);return this.get('adapter').updateScore(data,questionId);}, /**
   * Deletes a rubric
   * @param {String} rubricId
   * @returns {Promise|boolean} returns true if deleted
   */deleteRubric:function deleteRubric(rubricId){return this.get('adapter').deleteRubric(rubricId);}, /**
   * Returns rubric
   * @param {string} rubricId
   * @returns {Promise|Rubric}
   */getRubric:function getRubric(rubricId){var service=this;return service.get('adapter').getRubric(rubricId).then(function(data){return service.get('serializer').normalizeRubric(data);});}, /**
   * Returns user rubrics
   * @param {string} userId
   * @returns {Promise|Rubric[]}
   */getUserRubrics:function getUserRubrics(userId){var service=this;return service.get('adapter').getUserRubrics(userId).then(function(data){return service.get('serializer').normalizeGetRubrics(data);});}, /**
   * Copies a rubric
   * @param {String} rubricId
   * @returns {Promise|string} returns the copied id
   */copyRubric:function copyRubric(rubricId){return this.get('adapter').copyRubric(rubricId);}, /**
   * Associates a rubric with a question
   * @param {String} rubricId
   * @param {String} questionId
   * @returns {Promise|boolean} true when successful
   */associateRubricToQuestion:function associateRubricToQuestion(rubricId,questionId){return this.get('adapter').associateRubricToQuestion(rubricId,questionId);}, /**
   * Returns the list of Questions that the teacher needs to grade.
   * @param {string} classId
   * @param {string} courseId
   * @returns {Promise|GradeQuestion}
   */getQuestionsToGrade:function getQuestionsToGrade(classId,courseId){var service=this;return service.get('adapter').getQuestionsToGrade(classId,courseId).then(function(data){return service.get('serializer').normalizeQuestionsToGrade(data);});}, /**
   * Returns the list of Questions that the teacher needs to grade for DCA.
   * @param {string} classId
   * @returns {Promise|GradeQuestion}
   */getQuestionsToGradeForDCA:function getQuestionsToGradeForDCA(classId){var service=this;return service.get('adapter').getQuestionsToGradeForDCA(classId).then(function(data){return service.get('serializer').normalizeQuestionsToGrade(data);});}, /**
   * Returns the list of Students for a Question to be graded
   * @param {string} questionId
   * @param {string} classId
   * @param {string} courseId
   * @param {string} collectionId
   * @returns {Promise|GradeQuestionStudents}
   */getStudentsForQuestion:function getStudentsForQuestion(questionId,classId,courseId,collectionId){var service=this;return service.get('adapter').getStudentsForQuestion(questionId,classId,courseId,collectionId).then(function(data){return service.get('serializer').normalizeStudentsForQuestion(data);});}, /**
   * Returns the list of DCA Students for a Question to be graded
   * @param {string} questionId
   * @param {string} classId
   * @param {string} collectionId
   * @returns {Promise|GradeQuestionStudents}
   */getDCAStudentsForQuestion:function getDCAStudentsForQuestion(questionId,classId,collectionId,activityDate){var service=this;return service.get('adapter').getDCAStudentsForQuestion(questionId,classId,collectionId,activityDate).then(function(data){return service.get('serializer').normalizeStudentsForQuestion(data);});}, /**
   * Returns Answer for Rubric Grading
   * @param {string} studentId
   * @param {string} classId
   * @param {string} courseId
   * @param {string} collectionId
   * @param {string} questionId
   * @param {string} unitId
   * @param {string} lessonId
   * @returns {Promise|GradeQuestionAnswer}
   */getAnswerToGrade:function getAnswerToGrade(studentId,classId,courseId,collectionId,questionId){var unitId=arguments.length <= 5 || arguments[5] === undefined?null:arguments[5];var lessonId=arguments.length <= 6 || arguments[6] === undefined?null:arguments[6];var service=this;return service.get('adapter').getAnswerToGrade(studentId,classId,courseId,collectionId,questionId,unitId,lessonId).then(function(data){return service.get('serializer').normalizeAnswerToGrade(data);});}, /**
   * Returns Answer for Rubric Grading of DCA
   * @param {string} studentId
   * @param {string} classId
   * @param {string} collectionId
   * @param {string} questionId
   * @param {string} activityDate
   * @returns {Promise|GradeQuestionAnswer}
   */getAnswerToGradeForDCA:function getAnswerToGradeForDCA(studentId,classId,collectionId,questionId,activityDate){var service=this;return service.get('adapter').getAnswerToGradeForDCA(studentId,classId,collectionId,questionId,activityDate).then(function(data){return service.get('serializer').normalizeAnswerToGrade(data);});}, /**
   * Set student rubric grades
   * @param {RubricGrade} rubricGrade
   * @returns {Promise|RubricGrade} returns the rubric model with the newly assigned ID
   */setStudentRubricGrades:function setStudentRubricGrades(rubricGrade){var data=this.get('serializer').serializeStudentRubricGrades(rubricGrade);return this.get('adapter').setStudentRubricGrades(data);}, /**
   * Set student rubric grades for DCA
   * @param {RubricGrade} rubricGrade
   * @returns {Promise|RubricGrade} returns the rubric model with the newly assigned ID
   */setStudentRubricGradesForDCA:function setStudentRubricGradesForDCA(rubricGrade){var data=this.get('serializer').serializeStudentRubricGradesForDCA(rubricGrade);return this.get('adapter').setStudentRubricGrades(data);}, /**
   * Gets report of Rubric Grading for a Question
   * @param {string} studentId
   * @param {string} classId
   * @param {string} courseId
   * @param {string} collectionId
   * @param {string} questionId
   * @param {string} sessionId
   * @returns {Promise|RubricGrade}
   */getRubricQuestionSummary:function getRubricQuestionSummary(studentId,classId,courseId,collectionId,questionId,sessionId){var service=this;return service.get('adapter').getRubricQuestionSummary(studentId,classId,courseId,collectionId,questionId,sessionId).then(function(data){return service.get('serializer').normalizeRubricQuestionSummary(data);});}, /**
   * Gets DCA report of Rubric Grading for a Question
   * @param {string} studentId
   * @param {string} classId
   * @param {string} collectionId
   * @param {string} questionId
   * @param {string} sessionId
   * @returns {Promise|RubricGrade}
   */getRubricQuestionSummaryForDCA:function getRubricQuestionSummaryForDCA(studentId,classId,collectionId,questionId,sessionId){var service=this;return service.get('adapter').getRubricQuestionSummaryForDCA(studentId,classId,collectionId,questionId,sessionId).then(function(data){return service.get('serializer').normalizeRubricQuestionSummary(data);});}, /**
   * @function getOaItemsToGrade
   * Method to get OA items to grade
   * @param {Object} requestParam
   * @return {Promise}
   */getOaItemsToGrade:function getOaItemsToGrade(requestParam){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').getOaItemsToGrade(requestParam).then(function(responsePayload){resolve(service.get('serializer').normalizeOaItemsToGrade(responsePayload));},reject);});}, /**
   * @function getOaGradingStudents
   * Method to get List of students to be graded
   * @param {UUID} oaId
   * @param {Object} requestParam
   * @return {Promise}
   */getOaGradingStudents:function getOaGradingStudents(oaId,requestParam){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').getOaGradingStudents(oaId,requestParam).then(function(responsePayload){resolve(responsePayload);},reject);});}});});