define('gooru-web/models/profile/profile',['exports','ember','gooru-web/utils/endpoint-config'],function(exports,_ember,_gooruWebUtilsEndpointConfig){ /**
 * Profile model with the user account information
 *
 * @typedef {Object} ProfileModel
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string} id - The profile id
   */id:null, /**
   * @property {string} firstName - The profile first name
   */firstName:null, /**
   * @property {string} lastName - The profile last name
   */lastName:null, /**
   * @property {string} username - The profile username
   */username:null, /**
   * @property {string} code - The profile code
   */code:_ember['default'].computed('studentId',function(){var studentId=this.get('studentId');if(studentId){return studentId;}else {var uuid=this.get('id').split('-');return uuid.length >= 3?uuid[3]:uuid;}}), /**
   * @property {string} password  - The profile password
   */password:null, /**
   * @property {string} email - The profile email
   */email:null, /**
   * @property {string} gender - Profile gender
   */gender:null, /**
   * @property {string[]} grades - An arrays of string with the grades of the profile
   */grades:[], /**
   * @property {date} dateOfBirth - The profile date of birth
   */dateOfBirth:null, /**
   * @property {string} role - The profile role (teacher, student, etc)
   */role:null, /**
   * @property {date} createdAt - The profile creation date
   */createdAt:null, /**
   * @property {date} lastUpdate - The last profile modification date
   */lastUpdate:null, /**
   * @property {string} countryId - The profile country id
   */countryId:null, /**
   * @property {string} country - The profile country
   */country:null, /**
   * @property {string} stateId - The profile state id
   */stateId:null, /**
   * @property {string} state - The profile state
   */state:null, /**
   * @property {string} schoolId - The profile school id
   */schoolId:null, /**
   * @property {string} school - The profile school
   */school:null, /**
   * @property {string} schoolDistrictId - The profile school district id
   */schoolDistrictId:null, /**
   * @property {string} schoolDistrict - The profile school district
   */schoolDistrict:null, /**
   * @property {string} aboutMe - The profile biography
   */aboutMe:null, /**
   * @property {string} avatarUrl - The avatar image url
   */avatarUrl:null, /**
   * @property {string} rosterId - Profile roster id
   */rosterId:null, /**
   * @property {string} displayName
   */displayName:_ember['default'].computed('username','fullName',function(){return this.get('username')?this.get('username'):this.get('fullName');}), /**
   * @property {string}
   */fullName:_ember['default'].computed('firstName','lastName',function(){return this.get('lastName') + ', ' + this.get('firstName');}), /**
   * @property {String} fullName - The user lastname, firstname
   */lastFirstName:_ember['default'].computed('firstName','lastName',function(){var firstName=this.get('firstName');var lastName=this.get('lastName');return (lastName + ', ' + firstName).trim();}), /**
   * @property {string}
   */fullNameInformal:_ember['default'].computed('firstName','lastName',function(){return this.get('firstName') + ' ' + this.get('lastName');}), /**
   * @property {number} followers - The followers count of the profile
   */followers:0, /**
   * @property {number} followings - The followings count of the profile
   */followings:0, /**
   * @property {boolean} isFollowing - Indicates if current user is following the profile
   */isFollowing:false, /**
   * @property {object} network - The network information object
   */network:null, /**
   * @property {string}studentID
   */studentId:null, /**
   * @property {string} tenant id
   */tenantId:null, /**
   * @property {boolean} use_learning_data
   */use_learning_data:true, /**
   * @property {boolean} - Indicates if current user is a student
   */isStudent:_ember['default'].computed.equal('role','student'), /**
   * @property {boolean} - Indicates if current user is a teacher
   */isTeacher:_ember['default'].computed.equal('role','teacher'), /**
   * @property {boolean} - Indicates if current user is a researcher
   */isResearcher:_ember['default'].computed(function(){var userId=this.get('id');var researcher=_gooruWebUtilsEndpointConfig['default'].getResearcher();if(userId && researcher && researcher.userIds && researcher.userIds.contains(userId)){return true;}return false;}), /**
   * @property {boolean} - Indicates if current user is a provider
   */isProvider:_ember['default'].computed('isTeacher','isStudent',function(){return !this.get('isTeacher') && !this.get('isStudent');}), /**
   * Return a copy of the collection
   *
   * @function
   * @return {Profile}
   */copy:function copy(){var properties=this.getProperties(this.modelProperties());return this.get('constructor').create(_ember['default'].getOwner(this).ownerInjection(),properties);}, /**
   * Copy a list of property values from another model to override the current ones
   *
   * @function
   * @param {Collection|Assessment} model
   * @param {String[]} propertyList
   * @return {null}
   */merge:function merge(model){var propertyList=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var properties=model.getProperties(propertyList);this.setProperties(properties);}, /**
   * Return a list of properties
   *
   * @function
   * @return {Array}
   */modelProperties:function modelProperties(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key));if(value === 'string' || value === 'number' || value === 'boolean'){properties.push(key);}}return properties;}, /**
   * @property {string} state - The universal profile status
   */universalProfileStatus:null,googleClassUserId:null,enableForcePasswordChange:false});});