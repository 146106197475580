define('gooru-web/components/player/questions/gru-identify-digraph',['exports','gooru-web/components/player/questions/gru-question','ember'],function(exports,_gooruWebComponentsPlayerQuestionsGruQuestion,_ember){ /**
 * Indetify Digraph
 *
 * Component responsible for controlling the logic and appearance of a fill in the blank
 * question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-identify-digraph'], // -------------------------------------------------------------------------
// Actions
answers:_ember['default'].computed.alias('question.answers'), // -------------------------------------------------------------------------
// Properties
showCorrect:false,isShowPlay:false, // -------------------------------------------------------------------------
// Events
initInputEvents:(function(){var _this=this;if(this.get('baseQuestion')){this.set('isShowPlay',true);var digraphExercies=this.get('baseQuestion.answers').map(function(baseAnswer){var data=_this.get('baseQuestion.exemplarDocs').findBy('answer_text',baseAnswer.get('text'));if(data){return {answer_text:baseAnswer.get('text'),exemplar_answer:data.correct_answer,correct_answer:baseAnswer.get('correctAnswer')};}});this.injectIdentifyDigraph(digraphExercies);}var _loop=function(index){var element=_this.get('answers')[index];if(element.correctAnswer){element.correctAnswer.map(function(data){_this.hightLightDefaultWord(data,index,true);return data;});}};for(var index=0;index < this.get('question.answers').length;index++) {_loop(index);}}).on('didInsertElement'), // -------------------------------------------------------------------------
// Properties
userId:_ember['default'].computed.alias('session.userId'), // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
injectIdentifyDigraph:function injectIdentifyDigraph(digraphExercies){var user={userId:this.get('userId')};var content={contentId:this.get('baseQuestion.id'),contentTitle:this.get('baseQuestion.title'),answers:digraphExercies};window.serp.languageDecode().select('#serp-identify-digraph-answer-container').dataIn(user,null,content).underline().render().listener(function(){return;});},hightLightDefaultWord:function hightLightDefaultWord(text,answerIndex,status){var component=this;var innerHTML='';var html='';if(status){html = '<span class="correct">' + text + '</span>';}else {html = '<span class="error">' + text + '</span>';}if(component.$('.answer-underline .answer-edit-' + answerIndex)[0]){component.$('.answer-underline .answer-edit-' + answerIndex)[0].childNodes.forEach(function(childNode){if(childNode.data){innerHTML = innerHTML + childNode.data.replace(text,$.trim(html));}else if(childNode.data){innerHTML = innerHTML + childNode.data;}else {innerHTML = innerHTML + childNode.outerHTML;}});component.$('.answer-underline .answer-edit-' + answerIndex).html(innerHTML);}}});});