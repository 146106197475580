define('gooru-web/utils/question/answer-object',['exports','ember'],function(exports,_ember){ /**
 * It defines the answer object structure that is send to BE
 *
 * @typedef {Object} AnswerObject
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Answer text
   * @property {string}
   */text:null, /**
   * @property {boolean}
   */correct:_ember['default'].computed('status',{get:function get(){return this.get('status') === 'correct';},set:function set(key,value){this.set('status',value?'correct':'incorrect');return value;}}), /**
   * Answer status, correct or incorrect
   * @property {string}
   */status:null, /**
   * Answer order
   * @property {number}
   */order:null, /**
   * Answer id
   * @property {string}
   */answerId:null, /**
   * Skipped?
   * @property {boolean}
   */skip:null});});