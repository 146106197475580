define('gooru-web/adapters/offline-activity/oa-analytics',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Offline activity Analytics operations
 *
 * @typedef {Object} OfflineActivityAnalyticsAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus-insights/v2', /**
   * Fetch the list of OA that the teacher needs to grade for a given class
   * @param {string} classId
   * @param {string} userId
   * @returns {Object}
   */getOAToGrade:function getOAToGrade(classId,userId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/rubrics/items';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{classId:classId,userId:userId}};return _ember['default'].$.ajax(url,options);}, /**
   * Get the student submissions
   * @param {string} classId
   * @param {string} activityId Either CA Content ID or OA ID based on request type
   * @param {string} studentId
   * @param {Object} dataParam Dataparam is required only at the CM
   * @returns {Object}
   * If it's CA dataParam is not required otherwise dataParam is mandatory
   */getSubmissionsToGrade:function getSubmissionsToGrade(classId,activityId,studentId,dataParam){var adapter=this;var namespace=this.get('namespace');var url='' + namespace;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:dataParam};if(!dataParam){url += '/dca';}url += '/class/' + classId + '/oa/' + activityId + '/student/' + studentId + '/submissions';return _ember['default'].$.ajax(url,options);}, /**
   * Get the list of Students to-be graded for a given Offline Activity
   * @param {string} classId
   * @param {string} activityId
   * @returns {Object}
   */getStudentListToGrade:function getStudentListToGrade(classId,activityId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/rubrics/items/' + activityId + '/students';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{classId:classId}};return _ember['default'].$.ajax(url,options);}, /**
   * Submit student submission by teacher | student
   * @param {Object} Grade
   * @returns {Promise}
   */submitOAGrade:function submitOAGrade(data){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/rubrics/grades/collections';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * @function getOaCompletedStudents
   * @param {UUID} classId
   * @param {UUID} oaId
   * @param {UUID} itemId CA content ID
   * @param {Object} dataParam is required at the CM request
   * Method to get list of students who have marked an OA as completed
   * If itemId is there, we should treat it as CA request otherwise CM
   */getOaCompletedStudents:function getOaCompletedStudents(classId,oaId,itemId,dataParam){var adapter=this;var namespace=this.get('namespace');var url='' + namespace;var caCompletedListUrl='/dca/class/' + classId + '/oa/' + oaId + '/item/' + itemId + '/students';var cmCompletedListUrl='/class/' + classId + '/oa/' + oaId + '/students';url = url + (itemId?caCompletedListUrl:cmCompletedListUrl);var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};if(dataParam){options.data = dataParam;}return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});