define('gooru-web/components/content/modals/gru-add-rubric-to-oa',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {rubricService}
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * @requires service:api-sdk/offline-activity/offline-activity
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {rubricService}
   */questionService:_ember['default'].inject.service('api-sdk/question'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-add-rubric-to-question'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Select rubric
     */selectRubric:function selectRubric(rubric){this.set('selectedRubric',rubric);$('.gru-add-rubric-to-question .selected').removeClass('selected');$('.' + rubric.id).addClass('selected');}, /**
     * Add to question
     */addTo:function addTo(){var component=this;var model=component.get('model');var rubricId=component.get('selectedRubric.id');if(model.rubricsFor && model.rubricsFor === 'student'){return component.get('activityService').associateStudentRubricToOA(rubricId,model.oaId).then(function(oaRubricId){if(model.callback){if(model.callback){var oaRubric={SourceRubric:component.get('selectedRubric'),NewRubricId:oaRubricId};model.callback.success(oaRubric);}}component.triggerAction({action:'closeModal'});});}else {return component.get('activityService').associateTeacherRubricToOA(rubricId,model.oaId).then(function(oaRubricId){if(model.callback){var oaRubric={SourceRubric:component.get('selectedRubric'),NewRubricId:oaRubricId};model.callback.success(oaRubric);}component.triggerAction({action:'closeModal'});});}}, /**
     * Go to content page
     */goToContent:function goToContent(){var component=this;var model=component.get('model');var queryParams={profileId:model.userId,type:'my-content',activeContentType:'rubric'};component.get('router').transitionTo('library-search',{queryParams:queryParams});component.triggerAction({action:'closeModal'});}}, // -------------------------------------------------------------------------
// Properties
/**
   * Model with the values to use in the modal
   */model:null, /**
   * Filter only rubrics ON
   */filteredRubrics:_ember['default'].computed('model.rubrics',function(){return this.get('model.rubrics').filter(function(rubric){return rubric.title;});})});});