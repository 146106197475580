define('gooru-web/helpers/grade-color',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports.gradeColor = gradeColor; /**
 * Get the color for the grade bracket that a score falls under per the app's grading scale (@see /app/config/config.js#GRADING_SCALE)
 *
 * @example
 *
 * Based on a score value, the DIV element may be styled differently
 * <div class="grade" style="background-color: {{gradeColor score}}">
 *  ...
 * </div>
 * @see /app/templates/components/reports/assessment/gru-summary.hbs#L1
 *
 * @param value - score within the grading scale
 * @returns {String} - hex color string
 */function gradeColor(value /*, hash*/){return _ember['default'].String.htmlSafe((0,_gooruWebUtilsUtils.getGradeColor)(value[0]));}exports['default'] = _ember['default'].Helper.helper(gradeColor);});