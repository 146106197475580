define('gooru-web/components/content/questions/answers/gru-hs-image',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-hs-image'], // -------------------------------------------------------------------------
// Actions
actions:{ //Add new answer choice
addNewChoice:function addNewChoice(){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,isCorrect:false,type:'text'});this.get('answers').pushObject(newChoice);}, //Remove existing answer
removeChoice:function removeChoice(answer){this.get('answers').removeObject(answer);}, //Select correct answer
setCorrect:function setCorrect(answer){_ember['default'].set(answer,'isCorrect',true);}}, // -------------------------------------------------------------------------
// Properties
/**
   * Hotspot Image Question Answers
   * */answers:null, /**
   *  Hotspot Image  max answers
   * */maxAnswers:10, /**
   * Is in edit mode
   */editMode:false, /**
   * @type {Ember.A}
   */hasLimitAnswers:_ember['default'].computed('answers.[]',function(){return this.get('answers').length >= this.get('maxAnswers');})});});