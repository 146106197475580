define('gooru-web/controllers/guest',['exports','ember','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
queryParams:['sessionEnds','nonce'], /**
   * @property {Service} Session
   */session:_ember['default'].inject.service(), /**
   * @property {Service} Session service
   */sessionService:_ember['default'].inject.service('api-sdk/session'), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// Actions
actions:{authenticate:function authenticate(role){var redirection=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var controller=this;var userAccount=undefined;controller.set('session.isDemoTenant',true);if(role === _gooruWebConfigConfig.ROLES.TEACHER){userAccount = atob(controller.get('teacherAccount'));}else {userAccount = atob(controller.get('studentAccount'));}var userInfo=userAccount.split(':');var credentials=_ember['default'].Object.create({username:userInfo[0],password:userInfo[1]});var errorMessage=controller.get('i18n').t('common.errors.sign-in-credentials-not-valid').string; // TODO needs to be revisited, this is a quick fix
controller.get('sessionService').authorize().then(function(){controller.get('sessionService').signInWithUser(credentials,true).then(function(){if(redirection){window.location.href = window.location.origin + redirection;}else {controller.send('signIn');}},function(){controller.get('notifications').warning(errorMessage);var anonymousSessionData=controller.get('anonymousSessionData'); // Authenticate as anonymous if it fails to mantain session
controller.get('session').authenticateAsAnonymousWithData(anonymousSessionData);});});},adminLogin:function adminLogin(){window.open(this.get('adminRootPath'));}}, // -------------------------------------------------------------------------
// Methods
/**
   * init and reset all the properties for the validations
   */resetProperties:function resetProperties(){var controller=this;controller.set('didValidate',false);}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {StudentUser} user
   */studentAccount:_ember['default'].computed.alias('configuration.GUEST_STUDENT_ACCOUNT'), /**
   * @type {TeacherUser} user
   */teacherAccount:_ember['default'].computed.alias('configuration.GUEST_TEACHER_ACCOUNT'), /**
   * Query param
   * @property {Boolean} sessionEnds
   */sessionEnds:false, /**
   * Maintains the state of anonymous session data.
   * @type {Session}
   */anonymousSessionData:null,isGuestDemoTenant:_ember['default'].computed.alias('configuration.GRU_FEATURE_FLAG.isGuestDemoTenant')});});