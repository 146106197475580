define('gooru-web/controllers/content/rubric/edit',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
queryParams:['editing','backUrl','editingContent','primaryLanguage'], // -------------------------------------------------------------------------
// Properties
/**
   * @property  {boolean}
   */editing:false, /**
   * @property {string}
   */backUrl:null, /**
   * @property {string}
   */primaryLanguage:null, // -------------------------------------------------------------------------
// Methods
resetValues:function resetValues(){this.set('editing',null);}});});