define('gooru-web/components/reports/pull-up/struggling-competency-other-grade-level',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ //---------------------------------------------------------------
// Attributes
classNames:['pull-up','struggling-competency-other-grade-level'], //-------------------------------------------------------------
// Property
/**
   * @property {Object} otherGradeCompetency
   * property hold other grade domain list
   */otherGradeCompetency:null, /**
   * @property {Object} gradeDomainIndex
   * property hold other grade index
   */gradeDomainIndex:null, /**
   * Maintains the value of popover position
   * @type {String}
   */tagPopoverDefaultPosition:'bottom', //-------------------------------------------------------------
// Actions
actions:{ // Action trigger when click grade accordion
onToggleGrade:function onToggleGrade(gradeIndex){this.$('.sc-other-grade-panel-accordian-' + gradeIndex).slideToggle(500);}, // Action trigger when click domain inside grade
toggleDomain:function toggleDomain(gradeIndex,domainIndex){this.$('.sc-other-grade-domain-accordion-' + gradeIndex + '-' + domainIndex).slideToggle(500);}, // Action trigger when click close
onClosePullUp:function onClosePullUp(){var component=this;component.$().slideUp({complete:function complete(){component.sendAction('closePullUp');}});}, // Action trigger when click competency inside domain
onSelectCompetency:function onSelectCompetency(competency,domain){var component=this;competency.set('domainName',domain.get('domainName'));component.sendAction('selectCompetency',competency);}}, //------------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){this.openPullUp();this.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});this.setupTooltip();}, //---------------------------------------------------------------------
// Method
openPullUp:function openPullUp(){var component=this;component.$().slideDown();},setupTooltip:function setupTooltip(){var component=this;var $anchor=this.$('.lo-content');var isMobile=window.matchMedia('only screen and (max-width: 768px)');var tagPopoverDefaultPosition=this.get('tagPopoverDefaultPosition');$anchor.attr('data-html','true');$anchor.popover({placement:tagPopoverDefaultPosition,content:function content(){return component.$('.tag-tooltip').html();},trigger:'manual',container:'body'});if(isMobile.matches){$anchor.on('click',function(){var $this=$(this);if(!$this.hasClass('list-open')){ // Close all tag tooltips by simulating a click on them
$('.struggling-competency-other-grade-level > .content.list-open').click();$this.addClass('list-open').popover('show');}else {$this.removeClass('list-open').popover('hide');}});}else {$anchor.on('mouseenter',function(){$(this).popover('show');});$anchor.on('mouseleave',function(){$(this).popover('hide');});}}});});