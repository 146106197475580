define('gooru-web/models/rubric/rubric-grade',['exports','ember','gooru-web/models/rubric/rubric'],function(exports,_ember,_gooruWebModelsRubricRubric){ /**
 * Rubric Grade model
 * typedef {Object} RubricGrade
 */exports['default'] = _gooruWebModelsRubricRubric['default'].extend({ /**
   * @property {string} eventName to create a rubric grade
   */eventName:'resource.rubric.grade', /**
   * @property {String} studentId
   */studentId:null, /**
   * @property {String} classId
   */classId:null, /**
   * @property {String} courseId
   */courseId:null, /**
   * @property {String} unitId
   */unitId:null, /**
   * @property {String} lessonId
   */lessonId:null, /**
   * @property {String} collectionId
   */collectionId:null, /**
   * @property {String} sessionId
   */sessionId:null, /**
   * @property {String} resourceId
   */resourceId:null, /**
   * @property {Integer} Student score for the grade
   */studentScore:null, /**
   * @property {String} comment
   */comment:'', /**
   * @property {gradeCategoryScore[]}
   */categoriesScore:[], /**
   * All grades scores
   * @property {Array} scores
   */scores:_ember['default'].computed.map('categoriesScore.@each.levelScore',function(item){return +(item.get('levelScore') || 0);}), /**
   * Sum of all scores
   * @property {Integer} currentScore
   */currentScore:_ember['default'].computed.sum('scores'), /**
   * @property {Date} Date in which the rubric-grade was created
   */createdDate:null, /**
   * @property {Date} Date in which the rubric-grade was updated
   */updatedDate:null, /**
   * @property {Date} Date in which the rubric was created
   */rubricCreatedDate:null, /**
   * @property {Date} Date in which the rubric was updated
   */rubricUpdatedDate:null, /**
   * If any category has scores
   * @property {Boolean}
   */hasScore:_ember['default'].computed('categoriesScore.@each.levelScore',function(){return this.get('categoriesScore').reduce(function(hasScore,score){return hasScore || score.get('hasScore');},false);})});});