define('gooru-web/adapters/rubric/rubric',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the rubric CRUD operations in the API 3.0
 *
 * @typedef {Object} RubricAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service(),namespace:'/api/nucleus/v2/rubrics',gradingNamespaceForDCA:'/api/nucleus-insights/v2/dca/rubrics',profileNamespace:'/api/nucleus/v2/profiles',copierNamespace:'/api/nucleus/v2/copier',questionsNamespace:'/api/nucleus/v2/questions',gradingNamespace:'/api/nucleus-insights/v2/rubrics', /**
   * Posts a new rubric
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|String} ID of the newly created rubric
   */createRubric:function createRubric(params){var namespace=this.get('namespace');var url='' + namespace;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData,textStatus,request){var rubricId=request.getResponseHeader('location');resolve(rubricId);},reject);});}, /**
   * Updates a rubric
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|Boolean} true when updated
   */updateRubric:function updateRubric(params,rubricId){var namespace=this.get('namespace');var url=namespace + '/' + rubricId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(){resolve(true);},reject);});}, /**
   * Updates rubric score
   *
   * @param params - data to send in the request
   * @param questionId - question ID
   * @returns {Ember.Promise|Boolean} true when updated
   */updateScore:function updateScore(params,questionId){var namespace=this.get('questionsNamespace');var url=namespace + '/' + questionId + '/score';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(){resolve(true);},reject);});}, /**
   * Deletes a rubric
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|boolean} true when deleted
   */deleteRubric:function deleteRubric(rubricId){var namespace=this.get('namespace');var url=namespace + '/' + rubricId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders()};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(){resolve(true);},reject);});}, /**
   * Gets the rubric information
   *
   * @param {string} rubricId
   * @returns {Promise|Object}
   */getRubric:function getRubric(rubricId){var namespace=this.get('namespace');var url=namespace + '/' + rubricId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders()};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});}, /**
   * Gets the user rubrics information
   * @param {string} userId
   * @returns {Promise|Object}
   */getUserRubrics:function getUserRubrics(userId){var profileNamespace=this.get('profileNamespace');var url=profileNamespace + '/' + userId + '/rubrics';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders()};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});}, /**
   * Copies a rubric
   *
   * @param {string} rubricId to be copied
   * @returns {Ember.Promise|String} ID of the copied rubric
   */copyRubric:function copyRubric(rubricId){var copierNamespace=this.get('copierNamespace');var url=copierNamespace + '/rubrics/' + rubricId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify({}) //empty body is required
};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData,textStatus,request){var rubricId=request.getResponseHeader('location');resolve(rubricId);},reject);});}, /**
   * Associates a rubric with a question
   *
   * @param {string} rubricId
   * @param {string} questionId
   * @returns {Ember.Promise|boolean} true when successful
   */associateRubricToQuestion:function associateRubricToQuestion(rubricId,questionId){var questionsNamespace=this.get('questionsNamespace');var url=questionsNamespace + '/' + questionId + '/rubrics/' + rubricId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify({}) //empty body is required
};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(){resolve(true);},reject);});}, /**
   * Gets Questions pending grading
   *
   * @param {string} classId
   * @param {string} courseId
   * @returns {Promise/Object}
   */getQuestionsToGrade:function getQuestionsToGrade(classId,courseId){var adapter=this;var namespace=adapter.get('gradingNamespace');var url=namespace + '/questions';var data={classId:classId,courseId:courseId};var options={type:'GET',contentType:'application/json; charset=utf-8',data:data,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Gets Questions pending grading for DCA
   *
   * @param {string} classId
   * @returns {Promise/Object}
   */getQuestionsToGradeForDCA:function getQuestionsToGradeForDCA(classId){var adapter=this;var namespace=adapter.get('gradingNamespaceForDCA');var url=namespace + '/questions';var data={classId:classId};var options={type:'GET',contentType:'application/json; charset=utf-8',data:data,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Gets the list of Students for a to be graded Question
   *
   * @param {string} questionId
   * @param {string} classId
   * @param {string} courseId
   * @param {string} collectionId
   * @returns {Promise/Object}
   */getStudentsForQuestion:function getStudentsForQuestion(questionId,classId,courseId,collectionId){var adapter=this;var namespace=adapter.get('gradingNamespace');var url=namespace + '/questions/' + questionId + '/students';var data={collectionId:collectionId,classId:classId,courseId:courseId};var options={type:'GET',contentType:'application/json; charset=utf-8',data:data,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Gets the list of DCA Students for a to be graded Question
   *
   * @param {string} questionId
   * @param {string} classId
   * @param {string} collectionId
   * @returns {Promise/Object}
   */getDCAStudentsForQuestion:function getDCAStudentsForQuestion(questionId,classId,collectionId,activityDate){var adapter=this;var namespace=adapter.get('gradingNamespaceForDCA');var url=namespace + '/questions/' + questionId + '/students';var data={collectionId:collectionId,classId:classId,activityDate:activityDate};var options={type:'GET',contentType:'application/json; charset=utf-8',data:data,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Gets Answer for Rubric Grading
   *
   * @param {string} studentId
   * @param {string} classId
   * @param {string} courseId
   * @param {string} collectionId
   * @param {string} questionId
   * @param {string} unitId
   * @param {string} lessonId
   * @returns {Promise/Object}
   */getAnswerToGrade:function getAnswerToGrade(studentId,classId,courseId,collectionId,questionId,unitId,lessonId){var adapter=this;var namespace=adapter.get('gradingNamespace');var url=namespace + '/questions/' + questionId + '/students/' + studentId + '/answers';var data={classId:classId,courseId:courseId,collectionId:collectionId,unitId:unitId,lessonId:lessonId};var options={type:'GET',contentType:'application/json; charset=utf-8',data:data,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Gets Answer for Rubric Grading of DCA
   *
   * @param {string} studentId
   * @param {string} classId
   * @param {string} courseId
   * @param {string} collectionId
   * @param {string} questionId
   * @param {string} activityDate
   * @returns {Promise/Object}
   */getAnswerToGradeForDCA:function getAnswerToGradeForDCA(studentId,classId,collectionId,questionId,activityDate){var adapter=this;var namespace=adapter.get('gradingNamespaceForDCA');var url=namespace + '/questions/' + questionId + '/students/' + studentId + '/answers';var data={classId:classId,collectionId:collectionId,activityDate:activityDate};var options={type:'GET',contentType:'application/json; charset=utf-8',data:data,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Posts a student rubric grade
   *
   * @param data - rubric grade data to be sent in the request body
   * @returns {Promise}
   */setStudentRubricGrades:function setStudentRubricGrades(data){var adapter=this;var namespace=adapter.get('gradingNamespace');var url=namespace + '/grades';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Gets report of Rubric Grading for a Question
   *
   * @param {string} studentId
   * @param {string} classId
   * @param {string} courseId
   * @param {string} collectionId
   * @param {string} questionId
   * @param {string} sessionId
   * @returns {Promise/Object}
   */getRubricQuestionSummary:function getRubricQuestionSummary(studentId,classId,courseId,collectionId,questionId,sessionId){var adapter=this;var namespace=adapter.get('gradingNamespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/collection/' + collectionId + '/question/' + questionId + '/summary';var data={studentId:studentId,sessionId:sessionId};var options={type:'GET',contentType:'application/json; charset=utf-8',data:data,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Gets DCA report of Rubric Grading for a Question
   *
   * @param {string} studentId
   * @param {string} classId
   * @param {string} collectionId
   * @param {string} questionId
   * @param {string} sessionId
   * @returns {Promise/Object}
   */getRubricQuestionSummaryForDCA:function getRubricQuestionSummaryForDCA(studentId,classId,collectionId,questionId,sessionId){var adapter=this;var namespace=adapter.get('gradingNamespaceForDCA');var url=namespace + '/class/' + classId + '/collection/' + collectionId + '/question/' + questionId + '/summary';var data={studentId:studentId,sessionId:sessionId};var options={type:'GET',contentType:'application/json; charset=utf-8',data:data,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * @function getOaItemsToGrade
   * Method to get list of items grade
   * @param {Object} requestParam
   * @return {Promise}
   */getOaItemsToGrade:function getOaItemsToGrade(requestParam){var adapter=this;var url=adapter.get('gradingNamespace') + '/items';var options={type:'GET',contentType:'application/json; charset=utf-8',data:requestParam,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * @function getOaItemsToGrade
   * Method to get list of items grade
   * @param {UUID} itemId
   * @param {Object} requestParam
   * @return {Promise}
   */getOaGradingStudents:function getOaGradingStudents(itemId,requestParam){var adapter=this;var url=adapter.get('gradingNamespace') + '/items/' + itemId + '/students';var options={type:'GET',contentType:'application/json; charset=utf-8',data:requestParam,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});