define('gooru-web/components/player/gru-external-assessment-page',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config','gooru-web/utils/utils'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-external-assessment-page'], // -------------------------------------------------------------------------
// Dependencies
analyticsService:_ember['default'].inject.service('api-sdk/analytics'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.scoreValidator();}, /**
   * Observe the assessment change
   */assessmentObserver:_ember['default'].observer('assessment',function(){var component=this;component.resetProperties();}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click start
     */onStart:function onStart(){var component=this;component.set('startTime',new Date().getTime());var externalUrl=component.get('assessment.url');component.set('isDisableScoreEditor',false);component.$('#percentage-score').focus();if(externalUrl){if(component.get('toolDetails')){component.sendAction('onStartContentPlayed');}else {window.open(externalUrl,_gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT);}}}, /**
     * Action triggered when click submit
     */onSubmit:function onSubmit(){var component=this;component.set('isScoreEntered',true);component.set('stopTime',new Date().getTime());component.updateSelfReport();}, /**
     * Action triggered when change score type
     */onChangeScoreType:function onChangeScoreType(type){var component=this;component.set('defaultScoreType',type);}, /**
     * Action triggered when click cancel
     */onCancel:function onCancel(){var component=this;var isIframeMode=component.get('isIframeMode');if(isIframeMode){component.sendAction('onClosePlayer');}else {component.redirectTo();}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isDisableScoreEditor
   */isDisableScoreEditor:true, /**
   * @property {Boolean} isScoreEntered
   */isScoreEntered:false, /**
   * @property {String} contentType
   */contentType:'assessment-external', /**
   * @property {Boolean} isValidScore
   */isValidScore:false, /**
   * @property {String} defaultScoreType
   */defaultScoreType:'percentage', /**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}), /**
   * @property {Boolean} isTyping
   */isTyping:false, /**
   * @property {Number} startTime
   */startTime:0, /**
   * @property {Number} stopTime
   */stopTime:0, /**
   * @property {String} score
   */score:'', /**
   * @property {Object} dataParams
   */dataParams:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('assessment',function(){var component=this;var standards=component.get('assessment.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), // -------------------------------------------------------------------------
// Methods
/**
   * @function scoreValidator
   * Method to validate entered score
   */scoreValidator:function scoreValidator(){var component=this;component.$('.score-value').keyup(function(){if(component.get('defaultScoreType') === 'percentage'){var percentageScore=component.$('#percentage-score').val();component.set('isValidScore',(0,_gooruWebUtilsUtils.validatePercentage)(percentageScore));}else {var fractionScore=component.$('#fraction-score').val();var fractionMaxScore=component.$('#fraction-max-score').val();component.set('isValidScore',component.validateFractionScore(fractionScore,fractionMaxScore));}component.set('isTyping',true);});}, /**
   * @function getDataParams
   * Method to get structured data params which needs to be pass with post API
   */getDataParams:function getDataParams(){var component=this;var mapLocation=component.get('mapLocation');var percent_score=component.$('#percentage-score').val() || null;var score=component.$('#fraction-score').val() || null;var max_score=component.$('#fraction-max-score').val() || null;var context=mapLocation.get('context');var userId=component.get('session.userId');var dataParams={percent_score:percent_score,score:score,max_score:max_score,user_id:userId,class_id:context.get('classId') || null,course_id:context.get('courseId') || null,unit_id:context.get('unitId') || null,lesson_id:context.get('lessonId') || null,collection_type:context.get('itemType') || null,external_collection_id:context.get('collectionId'),collection_id:context.get('collectionId'),session_id:(0,_gooruWebUtilsUtils.generateUUID)(),time_zone:component.get('timeZone'),partner_id:component.get('session.partnerId') || null,tenant_id:component.get('session.tenantId') || null,content_source:component.get('source') || null,path_id:context.get('pathId') || 0,path_type:context.get('pathType') || null,time_spent:component.roundMilliseconds(component.get('stopTime') - component.get('startTime')),evidence:[{TBD:'True'}]};return dataParams;}, /**
   * @function updateSelfReport
   * Method to update score of an external assessment
   */updateSelfReport:function updateSelfReport(){var component=this;var analyticsService=component.get('analyticsService');var dataParams=component.getDataParams();component.set('percentScore',dataParams.percent_score);if(component.get('session.role') === 'student'){var selfReportedPromise=analyticsService.studentSelfReporting(dataParams);component.set('score','');_ember['default'].RSVP.hash({selfReport:selfReportedPromise}).then(function(){component.set('score',component.getEnteredScore(dataParams));component.set('dataParams',dataParams);var defaultScoreType=component.get('defaultScoreType');var score=defaultScoreType === 'percentage'?dataParams.percent_score:dataParams.score / dataParams.max_score * 100;component.sendAction('onGainedScore',score);})['catch'](function(){component.set('score',null);});}else {component.set('score',component.getEnteredScore(dataParams));}}, /**
   * @function validateFractionScore
   * Method to validate fraction score
   */validateFractionScore:function validateFractionScore(score,maxScore){var isValidFractionScore=false;if(!isNaN(score) && !isNaN(maxScore)){var isIntegerTypeScore=score.indexOf('.');var isIntegerTypeMaxScore=maxScore.indexOf('.');score = parseFloat(score);maxScore = parseFloat(maxScore);var isPositiveScore=score >= 0;var isNotExceedsLimit=maxScore >= 1 && maxScore <= 100;var isValidScore=score <= maxScore;var isIntegerNumber=isIntegerTypeScore === -1 && isIntegerTypeMaxScore === -1;if(isValidScore && isNotExceedsLimit && isPositiveScore && isIntegerNumber){isValidFractionScore = true;}}return isValidFractionScore;}, /**
   * @function roundMilliseconds
   * Method to round milliseconds
   */roundMilliseconds:function roundMilliseconds(milliseconds){return milliseconds - milliseconds % 1000;}, /**
   * @function getEnteredScore
   * Method to get entered score after update
   */getEnteredScore:function getEnteredScore(dataParams){var component=this;var defaultScoreType=component.get('defaultScoreType');var score=null;if(defaultScoreType === 'percentage'){score = dataParams.percent_score + '%';}else {score = dataParams.score + ' of ' + dataParams.max_score;}return score;}, /**
   * @function resetProperties
   * Method to reset component Properties
   */resetProperties:function resetProperties(){var component=this;this._super.apply(this,arguments);component.set('score','');component.set('isScoreEntered',false);component.set('defaultScoreType','percentage');component.set('isDisableScoreEditor',true);component.set('isValidScore',false);component.set('startTime',0);component.set('stopTime',0);component.set('isTyping',false);}, /**
   * Redirect to right path
   */redirectTo:function redirectTo(){var component=this;var context=component.get('mapLocation.context');var source=component.get('source');if(context.get('classId') && source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP){component.get('router').transitionTo('student.class.course-map',context.get('classId'),{queryParams:{refresh:true}});}else if(context.get('classId') && source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS){component.get('router').transitionTo('student.class.class-activities',context.get('classId'));}else {component.get('router').transitionTo('student.independent.course-map',context.get('courseId'),{queryParams:{refresh:true}});}}});});