define('gooru-web/adapters/configuration',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Lookup API 3.0 integration
 *
 * @typedef {Object} LookupAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/config', /**
   * Gets custom configuration
   * @returns {Promise.<[]>}
   */loadConfiguration:function loadConfiguration(key,configBaseUrl){var adapter=this;var namespace=adapter.get('namespace');var version=new Date().getTime();var basePath=configBaseUrl?configBaseUrl:'';var url='' + basePath + namespace + '/' + key + '.json?v=' + version;var options={type:'GET'};return _ember['default'].RSVP.hashSettled({configuration:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.configuration.value;});}, /**
   * Gets reserved words
   * @returns {Promise.<[]>}
   */reservedWords:function reservedWords(configBaseUrl){var adapter=this;var namespace=adapter.get('namespace');var version=new Date().getTime();var basePath=configBaseUrl?configBaseUrl:'';var url='' + basePath + namespace + '/reserved-words.json?v=' + version;var options={type:'GET'};return _ember['default'].RSVP.hashSettled({reservedWords:_ember['default'].$.ajax(url,options)}).then(function(hash){return _ember['default'].Object.create({reservedWords:hash.reservedWords.value});});}});});