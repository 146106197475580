define('gooru-web/controllers/content/courses/play',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
queryParams:['unitId','lessonId','sourceType','libraryId','productId'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Sets the current course builder location
     * @param unitId
     * @param lessonId
     */setLocation:function setLocation(unitId){var lessonId=arguments.length <= 1 || arguments[1] === undefined?undefined:arguments[1];this.set('unitId',unitId || null);this.set('lessonId',lessonId || null);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Profile[]} remixedUsers
   */remixedUsers:null, /**
   * @property {Profile[]} createdUsers
   */createdUsers:null, /**
   * @property {boolean}
   */isOwner:null, /**
   * @property  {string}
   */unitId:null, /**
   * @property {string}
   */lessonId:null, /**
   * @property {string}
   */productId:null, /**
   * @property {string}
   */libraryId:null, // -------------------------------------------------------------------------
// Methods
resetValues:function resetValues(){this.set('unitId',null);this.set('lessonId',null);this.set('sourceType','my-content');this.set('productId',null);this.set('libraryId',null);}});});