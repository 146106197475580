define('gooru-web/components/player/questions/gru-sorting',['exports','ember','gooru-web/components/player/questions/gru-question'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruQuestion){ /**
 * SERP - Sorting
 *
 * Component responsible for controlling the logic and appearance of a multiple
 * answer question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-sorting'], // --------------------------------------------------------------------------
// Properties
answers:_ember['default'].computed('question.answers',function(){return this.get('question.answers');}), // -------------------------------------------------------------------------
// Actions
actions:{}, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.initialize();}, // -----------------------------------------------------------------------
// Methods
initialize:function initialize(){var component=this;component.$(function(){component.$('.list-item-blk li, .column-soft-blk li, .column-hard-blk li').draggable({helper:'clone',revert:'invalid'});component.$('.column-hard-blk, .column-soft-blk').droppable({tolerance:'intersect',drop:function drop(event,ui){$(this).append($(ui.draggable));}});});}});});