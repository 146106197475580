define('gooru-web/components/proficiency/proficiency-subject-panel',['exports','ember','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({classNames:['proficiency-subject-panel'], /**
   * @property {Object} competency
   */subject:null, /**
   * @property {Array} competencyMatrixCoordinates
   */competencyMatrixCoordinates:null, /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), /**
   * @property {Array} domains
   */ // domains: Ember.computed.alias('competencyMatrixCoordinates.domains'),
/**
   * @property {String} framework
   */framework:null,isLoading:true,onWatchDomains:_ember['default'].observer('domains.@each.isExpanded',function(){var component=this;var expandedDiv=component.get('domains').findBy('isExpanded',true);if(component.get('domains')){setTimeout(function(){return component.set('isLoading',false);},100);}if(expandedDiv){setTimeout(function(){var container=$('.domain-list');var top=$('#scroll-domain-' + expandedDiv.domainSeq).position().top;var currentScroll=container.scrollTop();container.animate({scrollTop:currentScroll + top - 85},300);},200);}}),actions:{onClosePullUp:function onClosePullUp(){var component=this;component.sendAction('onClosePullUp');},onDomainSelect:function onDomainSelect(selectedDomain){var component=this;component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_PROFICIENCY_CHARTS_DOMAIN);component.sendAction('onDomainSelect',selectedDomain);},onSelectTopic:function onSelectTopic(topic){var component=this;component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_PROFICIENCY_CHARTS_DOMAIN_COMPTENCY);this.sendAction('onSelectTopic',topic);},onSelectDatamodel:function onSelectDatamodel(datamodel){var component=this;component.set('isShowDomainList',datamodel === 'proficiency');}},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},isShowDomainList:true});});