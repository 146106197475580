define('gooru-web/components/new-cards/gru-independent-card',['exports','ember','gooru-web/config/config','gooru-web/utils/utils','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebMixinsTenantSettingsMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @type {courseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {collectionService} Service to retrieve collection information
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @type {assessmentService} Service to retrieve assessment information
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), // -------------------------------------------------------------------------
// Attributes
classNames:['new-cards','new-gru-independent-card','col-sm-4','col-xs-12','col-md-3','col-lg-3'],actions:{ /**
     * When going to an assessment/collection report
     */toReport:function toReport(){var router=this.get('router');var location=this.get('location');var queryParams={collectionId:location.get('collectionId'),userId:this.get('session.userId'),type:location.get('type'),role:_gooruWebConfigConfig.ROLES.STUDENT,backUrl:router.get('currentPath')};router.transitionTo('reports.student-collection-analytics',{queryParams:queryParams});}, /**
     * When opening the player for current activity
     */openCoursePlayer:function openCoursePlayer(){var component=this;var collectionId=component.get('location.currentId');var type=component.get('location.currentType');var unitId=component.get('location.unitId');var lessonId=component.get('location.lessonId');var courseId=component.get('location.courseId');var title=component.get('location.currentTitle');var queryParams={classId:null,unitId:unitId,lessonId:lessonId,collectionId:collectionId,role:_gooruWebConfigConfig.ROLES.STUDENT,source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.INDEPENDENT_ACTIVITY,type:type,isIframeMode:true};component.get('navigateMapService').startCollection(courseId,unitId,lessonId,collectionId,type).then(function(){var playerContent=_ember['default'].Object.create({title:title,format:type});component.set('playerUrl',component.get('router').generate('study-player',courseId,{queryParams:queryParams}));component.set('isOpenPlayer',true);component.set('playerContent',playerContent);});},openPlayerContent:function openPlayerContent(){var component=this;var location=component.get('location');var id=location.get('collectionId');var type=location.get('type');var queryParams={type:type,role:'student',source:component.get('source'),isIframeMode:true};var playerContent=_ember['default'].Object.create({title:location.get('title'),format:location.get('type')});component.set('playerUrl',component.get('router').generate('player',id,{queryParams:queryParams}));component.set('isOpenPlayer',true);component.set('playerContent',playerContent);},closePullUp:function closePullUp(){var component=this;component.set('isOpenPlayer',false);}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});},init:function init(){var component=this;component._super.apply(component,arguments);var isCourse=component.get('isCourse');var isCollection=component.get('isCollection');if(isCourse){var courseId=component.get('location.courseId');if(courseId){component.get('courseService').fetchById(courseId).then(function(course){if(!component.isDestroyed){component.set('content',course);}});}}else if(isCollection){var collectionId=component.get('location.collectionId');if(collectionId){component.get('collectionService').readCollection(collectionId).then(function(collection){if(!component.isDestroyed){component.set('content',collection);}});}}else {var assessmentId=component.get('location.collectionId');if(assessmentId){component.get('assessmentService').readAssessment(assessmentId).then(function(assessment){if(!component.isDestroyed){component.set('content',assessment);}});}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Content} content information
   */content:null, /**
   * @property {Boolean} if the location is of type course
   */isCourse:_ember['default'].computed.equal('location.type',_gooruWebConfigConfig.CONTENT_TYPES.COURSE), /**
   * @property {Boolean} if the location is of type assessment
   */isAssessment:_ember['default'].computed.equal('location.type',_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT), /**
   * @property {Boolean} if the location is of type collection
   */isCollection:_ember['default'].computed.equal('location.type',_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION), /**
   * @property {Boolean} if the current activity is of type assessment
   */isCurrentAssessment:_ember['default'].computed.equal('location.currentType',_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT), /**
   * @property {LearnerLocation} location information
   */location:null, /**
   * @property {LearnerPerformance} performance information
   */performance:null, /**
   * Percentage value for the score chart
   * @property {String}
   */percentageToShow:_ember['default'].computed('performance.scoreInPercentage',function(){var score=this.get('performance.scoreInPercentage');return score || score === 0?score + '%':'--';}), /**
   * @property {Boolean}
   * Computed property  to identify class is started or not
   */hasStarted:_ember['default'].computed('performance.scoreInPercentage',function(){var scorePercentage=this.get('performance.scoreInPercentage');return scorePercentage !== null && scorePercentage >= 0;}), /**
   * @property {String} source value when playing a collection/assessment
   */source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.INDEPENDENT_ACTIVITY, /**
   * @property {String} time spent chart background color
   */timeSpentColor:_gooruWebConfigConfig.TIME_SPENT_CHART_COLOR, /**
   * @property {[Number]} barChartData
   */barChartData:_ember['default'].computed('performance',function(){var score=this.get('performance.scoreInPercentage');var scoreColor=(0,_gooruWebUtilsUtils.getBarGradeColor)(score);var completed=this.get('performance.completedCount');var total=this.get('performance.totalCount');var percentage=completed?completed / total * 100:0;return [{color:scoreColor,percentage:percentage}];})});});