define('gooru-web/routes/content/courses/play',['exports','ember','gooru-web/models/content/builder/item','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsContentBuilderItem,_gooruWebConfigConfig){exports['default'] = _ember['default'].Route.extend({queryParams:{source:{refreshModel:true}}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/course
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @requires service:api-sdk/profile
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(){ // TODO: authenticate session with ember-simple-auth, if not send to log in
},model:function model(params){var route=this;var createdUsersProfile=[];var remixedUsersProfile=[];var isRGOsource=params.source?params.source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.RGO:false;var sourceType=params.sourceType;return route.get('courseService').fetchById(params.courseId).then(function(course){var collaboratorUsers=course.collaborator;var originalCreatorId=course.originalCreatorId;var creatorId=course.creatorId;collaboratorUsers.addObject(creatorId);if(originalCreatorId && originalCreatorId !== creatorId){var createdUsers=[originalCreatorId];createdUsersProfile = route.get('profileService').readMultipleProfiles(createdUsers);remixedUsersProfile = route.get('profileService').readMultipleProfiles(collaboratorUsers);}else {createdUsersProfile = route.get('profileService').readMultipleProfiles(collaboratorUsers);}return _ember['default'].RSVP.hash({course:course,createdUsers:createdUsersProfile,remixedUsers:remixedUsersProfile,isRGOsource:isRGOsource,sourceType:sourceType});});},setupController:function setupController(controller,model){var isOwner=model.course.isOwner(this.get('session.userId'));var license='Public Domain';model.course.license = license; // end of mock data!
var unitId=controller.get('unitId');model.course.children = model.course.children.map(function(unit){ // Wrap every unit inside of a builder item
return _gooruWebModelsContentBuilderItem['default'].create({data:unit,isExpanded:unitId === unit.get('id')});});controller.set('course',model.course);controller.set('createdUsers',model.createdUsers);controller.set('remixedUsers',model.remixedUsers);controller.set('isOwner',isOwner);controller.set('isRGOsource',model.isRGOsource);controller.set('sourceType',model.sourceType);},deactivate:function deactivate(){this.get('controller').resetValues();}});});