define('gooru-web/components/reports/assessment/questions/gru-hot-text-highlight',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * Hot Text Highlight
 *
 * Component responsible for controlling the logic and appearance of an Hot Text Highlight
 * question inside of the assessment report.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-hot-text-highlight'], // -------------------------------------------------------------------------
// Properties
items:_ember['default'].computed('question.answerObject.[]',function(){var component=this;var question=component.get('question');var questionUtil=component.getQuestionUtil(question);var correctAnswers=questionUtil.getCorrectAnswer();var showCorrect=component.get('showCorrect');var userAnswer=showCorrect?correctAnswers:component.get('userAnswer');var items=questionUtil.getItems();items.forEach(function(item){var selected=!!userAnswer.findBy('text',showCorrect?item.get('text'):item.get('text').trim());item.set('selected',selected);item.set('correct',!!correctAnswers.findBy('text',item.get('text'))); //getItems already return if it is correct or not
});return items;}) // -------------------------------------------------------------------------
// Methods
});});