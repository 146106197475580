define('gooru-web/routes/student/independent',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebConfigConfig){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{queryParams:{refresh:{refreshModel:true}}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {i18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(), /**
   * @type {LearnerService} Service to retrieve learner information
   */learnerService:_ember['default'].inject.service('api-sdk/learner'), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Triggered when a class menu item is selected
     * @param {string} item
     */selectMenuItem:function selectMenuItem(item){var route=this;var controller=route.get('controller');var currentItem=controller.get('menuItem');if(item !== currentItem){controller.selectMenuItem(item);var queryParams={queryParams:{filterBy:'assessment'}};if(item === 'performance'){route.transitionTo('student.independent.performance',queryParams);}else if(item === 'course-map'){route.transitionTo('student.independent.course-map');}else if(item === 'study-player'){(function(){var userLocation=controller.get('userlocation');var courseId=undefined,unitId=undefined,lessonId=undefined,collectionId=undefined;courseId = controller.get('course').id;var suggestionPromise=null;if(userLocation){unitId = userLocation.get('unitId');lessonId = userLocation.get('lessonId');collectionId = userLocation.get('collectionId');suggestionPromise = _ember['default'].RSVP.resolve(_ember['default'].Object.create({}));}else {suggestionPromise = route.get('navigateMapService').continueCourse(courseId);}var queryParams={classId:null,unitId:unitId,lessonId:lessonId,collectionId:collectionId,role:_gooruWebConfigConfig.ROLES.STUDENT,source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.INDEPENDENT_ACTIVITY,type:'collection'};suggestionPromise.then(function(){return route.transitionTo('study-player',courseId,{queryParams:queryParams});});})();}else if(item === 'close'){route.transitionTo('student-independent-learning.learning-base');}}}}, // -------------------------------------------------------------------------
// Methods
/**
   * Get model for the controller
   */model:function model(params){var route=this;var courseId=params.courseId;var userId=this.get('session.userId');var coursePromise=route.get('courseService').fetchById(courseId);var performancePromise=route.get('learnerService').fetchCoursesPerformance(userId,[courseId]);return _ember['default'].RSVP.hash({course:coursePromise,performance:performancePromise}).then(function(hash){var course=hash.course;var performance=hash.performance;var userLocation=route.get('learnerService').fetchLocationCourse(course.get('id'),userId);return _ember['default'].RSVP.hash({course:course,performance:performance,userLocation:userLocation});});}, /**
   * Run after model is set
   */afterModel:function afterModel(user,transition){if(transition.targetName === this.routeName + '.index'){this.transitionTo('student.independent.course-map');}}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){controller.set('performance',model.performance[0]);controller.set('course',model.course);controller.set('units',model.course.get('children') || []);controller.set('userlocation',model.userLocation);}});});