define('gooru-web/models/rubric/grade-question',['exports','ember'],function(exports,_ember){ /**
 * Grade Question model
 *
 * @typedef {Object} Grade Question
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {String} classId
   */classId:null, /**
   * @property {String} courseId
   */courseId:null, /**
   * @property {gradeQuestionItem[]}
   */gradeItems:[]});});