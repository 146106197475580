define('gooru-web/components/reports/pull-up/activity/class-summary-report',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['activity-report','class-summary-report'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/analytics
   */analyticsService:_ember['default'].inject.service('api-sdk/analytics'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.set('isLoading',true);component.fetchClassSummaryData();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on monthly report
onShowMonthlyReport:function onShowMonthlyReport(reportData){var component=this;component.set('monthlyReportContext',reportData);component.set('isShowMonthlySummaryReport',true);}, //Action triggered when click on close pullup
onClosePullUp:function onClosePullUp(){this.set('showPullUp',false);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} classId
   */classId:_ember['default'].computed.alias('classSummary.classId'), /**
   * @property {UUID} userId
   */userId:_ember['default'].computed.alias('session.userId'), /**
   * @property {Array} classSummaryReportData
   */classSummaryReportData:_ember['default'].A([]), /**
   * @property {Boolean} isLoading
   */isLoading:false, /**
   * @property {String} reportPeriod
   */reportPeriod:_ember['default'].computed('monthlyReportContext',function(){var component=this;var reportData=component.get('monthlyReportContext');return reportData.get('year') + '-' + reportData.get('month') + '-01';}), // -------------------------------------------------------------------------
// Methods
/**
   * @function fetchClassSummaryData
   * Method to fetch class summary report data
   */fetchClassSummaryData:function fetchClassSummaryData(){var component=this;var analyticsService=component.get('analyticsService');var classId=component.get('classId');var userId=component.get('userId');return _ember['default'].RSVP.hash({classSummaryReportData:analyticsService.getDCAYearlySummary(classId,userId)}).then(function(_ref){var classSummaryReportData=_ref.classSummaryReportData;if(!component.isDestroyed){component.set('classSummaryReportData',classSummaryReportData.sortBy('month').sortBy('year').reverse());component.set('isLoading',false);}});}});});