define('gooru-web/components/library/gru-partner-library-card',['exports','ember'],function(exports,_ember){ /**
 * Library card
 *
 * Component responsible of showing the library information in cards, so that most useful information is summarized there.
 * @module
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-partner-library-card'], // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{selectLibrary:function selectLibrary(){var component=this;var library=component.get('content');if(!component.get('onSelectLibrary')){var queryParams={libraryId:library.get('id'),type:'library',isDeepLink:component.get('isDeepLink')};component.get('router').transitionTo('library-search',{queryParams:queryParams});}else {component.sendAction('onSelectLibrary',library);}}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {Library} content
   */content:null, /**
   * @property {boolean} Indicates if library has 1 or more courses
   */hasCourses:_ember['default'].computed.gt('content.courseCount',0), /**
   * @property {boolean} Indicates if library has 1 or more collections
   */hasCollections:_ember['default'].computed.gt('content.collectionCount',0), /**
   * @property {boolean} Indicates if library has 1 or more assessments
   */hasAssessments:_ember['default'].computed.gt('content.assessmentCount',0), /**
   * @property {boolean} Indicates if library has 1 or more resources
   */hasResources:_ember['default'].computed.gt('content.resourceCount',0), /**
   * @property {boolean} Indicates if library has 1 or more questions
   */hasQuestions:_ember['default'].computed.gt('content.questionCount',0), /**
   * @property {boolean} Indicates if library has 1 or more rubrics
   */hasRubrics:_ember['default'].computed.gt('content.rubricCount',0), /**
   * @property {boolean} Indicates if library has 1 or more oA
   */hasOfflineActivity:_ember['default'].computed.gt('content.offlineActivityCount',0),redirectUrl:_ember['default'].computed(function(){var shortName=this.get('content.shortName');var libraryUrl='library/' + shortName;var defaultUrl=libraryUrl + '/content/courses';if(this.get('hasCourses')){return defaultUrl;}else if(this.get('hasCollections')){return libraryUrl + '/content/collections';}else if(this.get('hasAssessments')){return libraryUrl + '/content/assessments';}else if(this.get('hasResources')){return libraryUrl + '/content/resources';}else if(this.get('hasQuestions')){return libraryUrl + '/content/questions';}else {return defaultUrl;}}) // -------------------------------------------------------------------------
// Methods
});});