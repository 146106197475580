define('gooru-web/components/grading/gru-task-header',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-task-header'], // -------------------------------------------------------------------------
// Properties
/**
   * Title to show on the header
   * @property {String} promptTitle
   */promptTitle:null, /**
   * Text to show on the header
   * @property {String} promptText
   */promptText:null, /**
  * Thumbnail to show on the header
  * @property {String} promptThumbnail
  */promptThumbnail:null});});