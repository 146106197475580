define('gooru-web/components/content/questions/answers/gru-match-question',['exports','ember','gooru-web/models/content/builder/match-the-following'],function(exports,_ember,_gooruWebModelsContentBuilderMatchTheFollowing){function _defineProperty(obj,key,value){if(key in obj){Object.defineProperty(obj,key,{value:value,enumerable:true,configurable:true,writable:true});}else {obj[key] = value;}return obj;}exports['default'] = _ember['default'].Component.extend({ /**
   * @property {MediaService} Media service API SDK
   */mediaService:_ember['default'].inject.service('api-sdk/media'),classNames:['content','questions','answers','gru-match-question'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.resetValue();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Add new answer choice
addNewChoice:function addNewChoice(){var selectedOptions=this.get('selectedOptions');var newChoice=_gooruWebModelsContentBuilderMatchTheFollowing['default'].create(_ember['default'].getOwner(this).ownerInjection(),{leftValue:null,rightValue:null,leftValueFormat:selectedOptions.get('left') || null,rightValueFormat:selectedOptions.get('right') || null,rightValShuffleOrder:'random',text:'text',isCorrect:true});this.get('answers').pushObject(newChoice);}, // Remove existing answer
removeChoice:function removeChoice(answer){this.get('answers').removeObject(answer);this.resetValue();},selectFile:function selectFile(answer,side,file){var component=this;var imageIdPromise=new _ember['default'].RSVP.resolve(file);imageIdPromise = component.get('mediaService').uploadContentFile(file);imageIdPromise.then(function(filename){if(side === 'left'){answer.set('leftValue',filename);answer.set('leftValueFormat','image');}else {answer.set('rightValue',filename);answer.set('rightValueFormat','image');}component.resetValue();});},resetImage:function resetImage(answer,side){if(side === 'left'){answer.set('leftValue',null);answer.set('leftValueFormat',null);}else {answer.set('rightValue',null);answer.set('rightValueFormat',null);}this.resetValue();},onSelectOption:function onSelectOption(option,keyName){var selectedOptions=this.get('selectedOptions');selectedOptions.setProperties(_defineProperty({},keyName,option.id));var answers=this.get('answers');answers.forEach(function(item){item.setProperties({leftValueFormat:selectedOptions.get('left') || null,rightValueFormat:selectedOptions.get('right') || null});});}}, // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Properties
/**
   * Question answers
   */answers:null, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false, /**
   * List of error messages to present to the user for conditions that the loaded image does not meet
   * @prop {String[]}
   */filePickerErrors:_ember['default'].A(), /**
   * @param {Boolean } isLeftSideFileUpload - value used to check if can able to upload file on left side
   */isLeftSideFileUpload:true, /**
   * @param {Boolean } isRightSideFileUpload - value used to check if can able to upload file on right side
   */isRightSideFileUpload:true, /**
   * @param {Boolean } isLeftSideDisableInput - value used to check if the left side input box is disable or not
   */isLeftSideDisableInput:false, /**
   * @param {Boolean } isRightSideDisableInput - value used to check if the right side input box is disable or not
   */isRightSideDisableInput:false,matchOptions:_ember['default'].A([{id:'image',name:'Image'},{id:'text',name:'Text'}]),selectedOptions:_ember['default'].computed('answers',function(){var defaultOptions=_ember['default'].Object.create({left:'text',right:'text'});var answers=this.get('answers');if(answers && answers.length){defaultOptions.set('left',answers[0].leftValueFormat);defaultOptions.set('right',answers[0].rightValueFormat);}return defaultOptions;}), // -------------------------------------------------------------------------
// Methods
resetValue:function resetValue(){var component=this;var answers=component.get('answers');var leftValueImageAnswers=answers?answers.filterBy('leftValueFormat','image'):[];var rightValueImageAnswers=answers?answers.filterBy('rightValueFormat','image'):[];var leftValueTextAnswers=answers?answers.filterBy('leftValueFormat','text'):[];var rightValueTextAnswers=answers?answers.filterBy('rightValueFormat','text'):[];component.set('isLeftSideDisableInput',leftValueImageAnswers.length > 0);component.set('isRightSideDisableInput',rightValueImageAnswers.length > 0);component.set('isLeftSideFileUpload',!(leftValueTextAnswers.length > 0));component.set('isRightSideFileUpload',!(rightValueTextAnswers.length > 0));}});});