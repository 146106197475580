define('gooru-web/components/gru-pull-up-ex',['exports','ember'],function(exports,_ember){ /**
 * Gooru APP pull up component
 *
 * Component responsible for generating the skeleton pull up component,
 * which  have header, footer(Optional) and close button .
 * @augments ember/Component
 * see the sample usage below
 * {{#gru-pull-up showPullUp=showPullUp  as |pull-up| }}
 * {{/gru-pull-up}}
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gruPullUp','gru-pull-out'],classNameBindings:['pullUpType'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user close the pull up.
     **/closeAll:function closeAll(){this.sendAction('onClosePullUp');}, /**
     * Action triggered when the user invoke the pull up.
     **/onPullUpClose:function onPullUpClose(){this.set('showPullUpEx',false);this.set('isShowMore',false);this.sendAction('onClosePullUp');}}, // -------------------------------------------------------------------------
// Properties
/**
   * Propery to hide the default pullup.
   * @property {showPullUp}
   */showPullUpEx:false, /**
   * Propery to show header in pullup component.
   * @property {showPisShowHeaderullUp}
   */header:{isShowHeader:true}, /**
   * Propery to show pullup body content.
   * @property {isShowBody}
   */body:{isShowBody:true}, /**
   * @property {pullUpType}
   * Property to identify which pull up currently rendering
   */pullUpType:'',didInsertElement:function didInsertElement(){this.animatePullUp();}, //--------------------------------------------------------------------------
// Observer
//
/**
   * Observer to check the showPullUp property in component
   **/onChange:_ember['default'].observer('showPullUpEx',function(){ //this.onClosePullUp();
this.set('showPullUpEx',this.get('isShowMore')?true:this.get('showPullUpEx'));if(!this.get('isShowMore')){this.animatePullOut();}}),animatePullUp:function animatePullUp(){if(this.get('showPullUpEx')){_ember['default'].$('.gru-pull-up').animate({top:'10%'},850);}else {_ember['default'].$('.gru-pull-up').animate({top:'100%'});}},animatePullOut:function animatePullOut(){var _this=this;if(this.get('showPullUpEx')){(function(){_ember['default'].$('body.application').addClass('no-vertical-scroll');var component=_this;var right=undefined,pullUpWidth=undefined;if($('body').hasClass('gruHelpPullup')){pullUpWidth = 550;right = '' + pullUpWidth - component.$().width();}else {pullUpWidth = 650;right = '' + pullUpWidth - component.$().width();}if(component.get('hasDocument')){pullUpWidth = 1050;right = '' + pullUpWidth - component.$().width();} //Add class for mobile responsive
component.$().addClass('gru-pull-out-xs');component.$().removeClass('gru-pull-out-hidden');component.$().animate({right:right + 'px'},{complete:function complete(){component.$().css('right','calc(' + pullUpWidth + 'px - 100%)');}});})();}else {(function(){var component=_this;component.$().animate({right:'-101%'},{complete:function complete(){component.$().removeClass('gru-pull-out-xs');component.$().addClass('gru-pull-out-hidden');_ember['default'].$('body.application').removeClass('no-vertical-scroll');}});})();}}});});