define('gooru-web/components/library/gru-library-card',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['library','gru-library-card'], // -------------------------------------------------------------------------
// Events
/**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){this._super.apply(this,arguments);var component=this; // Adds tooltip to UI elements (elements with attribute 'data-toggle')
component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'}); //Determinate if the device where the component is showing is a touch device in order to deactivate the tooltips
var isTouch='ontouchstart' in window || navigator.msMaxTouchPoints > 0;if(isTouch){component.$('.actions .item-actions button').tooltip('disable');}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} indicate if disabled card buttons
   */disabledActions:true, /**
   * Object to show in the library card
   */model:null});});