define('gooru-web/utils/question/multiple-choice',['exports','ember','gooru-web/utils/question/question','gooru-web/utils/question/answer-object'],function(exports,_ember,_gooruWebUtilsQuestionQuestion,_gooruWebUtilsQuestionAnswerObject){ /**
 * It contains convenience methods for grading and retrieving useful information
 * for MC questions
 *
 * # Answer object (structure required by the BE)
 *
 *   It is an array containing a single json object
 *
 *   text contains the text entered by the user
 *   status could be correct or incorrect based on the user selection
 *   order is always 1
 *   answerId corresponds to the answer choice id selected
 *   skip is always false
 *
 * [{"text":"Apple","status":"correct","order":1,"answerId":1234,"skip":false}]
 *
 * # User answer (structure used by the FE)
 *
 *   It corresponds to the answerId selected by the user
 *
 *  ie 123020
 *
 * @typedef {Object} MultipleChoiceUtil
 */exports['default'] = _gooruWebUtilsQuestionQuestion['default'].extend({ // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
/**
   * Indicates if the answer is correct
   * It overrides the default implementation
   *
   * @param {string} answer user answer
   * @return {boolean}
   *
   * @see '# User Answer' section at class comment
   */isCorrect:function isCorrect(answer){return this.isAnswerChoiceCorrect(answer);}, /**
   * Indicates if the answer choice is correct
   * @param { boolean } answerChoice
   */isAnswerChoiceCorrect:function isAnswerChoiceCorrect(answerChoice){return this.getCorrectAnswer() === answerChoice;}, /**
   * Gets the correct answer
   *
   * @return {string} the correct answer choice id
   *
   * @see '# User Answer' section at class comment
   */getCorrectAnswer:function getCorrectAnswer(){var answers=this.get('question.answers');var correctAnswer=answers.filterBy('isCorrect',true);return correctAnswer.get('length')?correctAnswer.get('firstObject.id'):undefined;}, /**
   * Returns a unique key representing the answer
   * For multiple choice the answer id is already unique
   * @param {number} answer i.e 1
   * @returns {number} i.e 1
   *
   * @see '# User Answer' section at class comment
   */answerKey:function answerKey(answer){return answer;}, /**
   * Converts the model user answer into an answerObject format
   *
   * @param {string} userAnswer answer choice id
   * @return {AnswerObject[]}
   *
   * @see '# User Answer' section at class comment
   * @see '# Answer Object' section at class comment
   */toAnswerObjects:function toAnswerObjects(userAnswer){var util=this;var answer=util.getAnswerById(userAnswer);var answerObject=_gooruWebUtilsQuestionAnswerObject['default'].create({text:answer.get('text'),correct:util.isCorrect(userAnswer),order:1,answerId:userAnswer,skip:false});return _ember['default'].A([answerObject]);}, /**
   * Converts an answerObject format to model userAnswer
   *
   * @param {AnswerObject[]} answerObjects
   * @return {string} answer id
   *
   * @see '# User Answer' section at class comment
   * @see '# Answer Object' section at class comment
   */toUserAnswer:function toUserAnswer(answerObjects){var userAnswer=null;if(answerObjects.get('length')){var answerObject=_ember['default'].get(answerObjects,'firstObject');userAnswer = _ember['default'].get(answerObject,'answerId');}return userAnswer;}});});