define('gooru-web/adapters/content/unit',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Unit CRUD operations in the API 3.0
 *
 * @typedef {Object} UnitAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service(),namespace:'/api/nucleus/v1/courses',copierNamespace:'/api/nucleus/v1/copier/courses', /**
   * Posts a new unit
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|String} ID of the newly created unit
   */createUnit:function createUnit(params){var courseId=params.courseId;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params.unit)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData,textStatus,request){var unitId=request.getResponseHeader('location');resolve(unitId);},function(error){reject(error);});});}, /**
   * Updates an existing unit
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|String} ID
   */updateUnit:function updateUnit(params){var courseId=params.courseId;var unitId=params.unitId;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params.unit)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(){resolve('');},function(error){reject(error);});});}, /**
   * Get unit data for the corresponding unit ID
   *
   * @param params - data to send in the request
   * @returns {Promise|Object}
   */getUnitById:function getUnitById(params){var courseId=params.courseId;var unitId=params.unitId;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders()};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},function(error){reject(error);});});}, /**
   * Deletes a unit by id
   *
   * @param params - data to send in the request
   * @returns {Promise}
   */deleteUnit:function deleteUnit(params){var courseId=params.courseId;var unitId=params.unitId;var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Copies a unit by id
   *
   * @param params - data to send in the request
   * @returns {Promise}
   */copyUnit:function copyUnit(params){var courseId=params.courseId;var unitId=params.unitId;var adapter=this;var namespace=this.get('copierNamespace');var url=namespace + '/' + courseId + '/units/' + unitId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({target_course_id:courseId})};return _ember['default'].$.ajax(url,options);}, /**
   * Reorder unit lessons
   *
   * @param courseId the id of the course
   * @param unitId the id of the unit to be updated
   * @param data
   * @returns {Promise}
   */reorderUnit:function reorderUnit(courseId,unitId,data){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/order';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});