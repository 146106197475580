define('gooru-web/models/profile',['exports','ember-data'],function(exports,_emberData){ /**
 * Model to represent the Profiles objects with the User information
 *
 * @typedef {Object} Profile
 */exports['default'] = _emberData['default'].Model.extend({ /**
   * @property {string} profileId
   */profileId:_emberData['default'].attr('string'), /**
   * @property {string} aboutMe
   */aboutMe:_emberData['default'].attr('string'), /**
   * @property {Object} followersList - List of all the id's of followers that the user has
   */followersList:_emberData['default'].attr(), /**
   * @property {Object} followersDetails - Details of all the followers that the user has
   */followersDetails:_emberData['default'].attr(), /**
   * @property {Number} followers - Number of followers that the user has
   * TODO: Change this to a computed property that reads the length of followers in followersDetails
   */followers:_emberData['default'].attr('number'), /**
   * @property {Object} followingsList - List of all the id's of followings that the user has
   */followingsList:_emberData['default'].attr(), /**
   * @property {Object} followingsDetails - Details of all the followings that the user has
   */followingsDetails:_emberData['default'].attr(), /**
   * @property {Number} followings - Number of people that the user is following
   * TODO: Change this to a computed property that reads the length of followings in followersDetails
   */followings:_emberData['default'].attr('number'), /**
   * @property {User} user
   */user:_emberData['default'].belongsTo('user/user')});});