define('gooru-web/components/taxonomy/modals/gru-domain-picker',['exports','ember'],function(exports,_ember){ /**
 * Domain Picker
 *
 * Component responsible for displaying two panels (course and domain) for a tree
 * of browse items. Uses @see ../gru-taxonomy-picker
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/taxonomy
   */taxonomyService:_ember['default'].inject.service('taxonomy'), // -------------------------------------------------------------------------
// Attributes
classNames:['taxonomy','modals','gru-domain-picker'], // -------------------------------------------------------------------------
// Actions
actions:{loadTaxonomyData:function loadTaxonomyData(path){return new _ember['default'].RSVP.Promise((function(resolve){var subject=this.get('model.subject');var courseId=path[0];return this.get('taxonomyService').getCourseDomains(subject,courseId).then(function(domains){resolve(domains);});}).bind(this));},updateSelectedTags:function updateSelectedTags(selectedTags){this.get('model.callback').success(selectedTags);this.triggerAction({action:'closeModal'});}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('panelHeaders',[this.get('i18n').t('common.course').string,this.get('i18n').t('common.domain').string]);}, // -------------------------------------------------------------------------
// Properties
/**
   * Object with necessary picker information:
   * - callback: {Object} Callback object made up of two properties: success and fail
   *             callback.success will be called on the 'updateSelectedTags' action.
   * - selected: {TaxonomyTagData[]} List of references to a set of taxonomy tag data
   * - subject: {TaxonomyRoot} Currently selected subject
   * @prop {Object}
   */model:null, /**
   * Headers to display at the top of each one of the panels (course & domain).
   * There *must* be one for each panel.
   * @prop {String[]}
   */panelHeaders:[]});});