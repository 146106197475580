define('gooru-web/components/gru-i2d-preview',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-i2d-preview'], /**
   * @property {I2dService} Media service API SDK
   */i2dService:_ember['default'].inject.service('api-sdk/i2d'), /**
   * @type {i18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Properties
/**
   * It maintains the images which needs to display
   * @prop {Array}
   */previewContents:null, /**
   * List of valid file extensions.
   * @prop {String}
   */mimeType:'image/*', /**
   * It maintains the preview content
   * @prop {Array}
   */activeContent:null, /**
   * It maintains the question maxscores
   * @prop {Array}
   */questionsMaxScore:_ember['default'].computed('questions.[]',function(){return this.get('questions').map(function(question){return question.maxScore;});}), /**
   * It maintains the whether to show reupload option or not
   * @prop {Boolean}
   */showReUpload:_ember['default'].computed('activeContent.isUploadReadyForReview','activeContent.conversionErros',function(){return this.get('activeContent.isUploadReadyForReview') || this.get('activeContent.conversionErros');}), /**
   * It maintains the whether to show student score table or not
   * @prop {Boolean}
   */showStudentScore:_ember['default'].computed('activeContent.isUploadReadyForReview','activeContent.conversionErros',function(){return this.get('activeContent.isUploadReadyForReview') && !this.get('activeContent.conversionErros');}), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.send('slideTo',component.get('activePreviewIndex'));component.$('[data-toggle="popover"]').popover({trigger:'hover'});},didInsertElement:function didInsertElement(){var component=this;if(component.get('showScoreReview')){component.fetchImageData();}}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when we click on previous
     */onClickPrev:function onClickPrev(){var component=this;component.$('.image-to-data-preview-container #image-preview-carousel-wrapper .carousel-control').addClass('in-active');var selectedElement=component.$('.image-to-data-preview-container #image-preview-carousel-wrapper .item.active');var previewContents=component.get('previewContents');var currentIndex=selectedElement.data('item-index');var selectedIndex=currentIndex - 1;if(currentIndex === 0){selectedIndex = previewContents.length - 1;}component.set('activePreviewIndex',selectedIndex);component.$('.image-to-data-preview-container #image-preview-carousel-wrapper').carousel('prev');}, /**
     * Action triggered when we click on next
     */onClickNext:function onClickNext(){var component=this;component.$('.image-to-data-preview-container #image-preview-carousel-wrapper .carousel-control').addClass('in-active');var selectedElement=component.$('.image-to-data-preview-container #image-preview-carousel-wrapper .item.active');var previewContents=component.get('previewContents');var currentIndex=selectedElement.data('item-index');var selectedIndex=currentIndex + 1;if(previewContents.length - 1 === currentIndex){selectedIndex = 0;}component.set('activePreviewIndex',selectedIndex);component.$('.image-to-data-preview-container #image-preview-carousel-wrapper').carousel('next');}, /**
     * Action triggered when we click on thumbnail
     */slideTo:function slideTo(index){var component=this;component.$('.image-to-data-preview-container #image-preview-carousel-wrapper .carousel-control').addClass('in-active');component.$('.image-to-data-preview-container #image-preview-carousel-wrapper').carousel(index);}, /**
     * Action triggered when we click on thumbnail
     */onSelectImage:function onSelectImage(content,index){var component=this;component.set('activeContent',content);component.set('activePreviewIndex',index);if(component.get('showScoreReview')){component.fetchImageData();}else {component.send('slideTo',index);}}, /**
     * Action triggered when we click on confirm upload button
     */onConfirmUpload:function onConfirmUpload(){var component=this;component.sendAction('onConfirmUpload');}, /**
     * Action triggered when we click on confirm score button
     */onConfirmScore:function onConfirmScore(){var component=this;var isInvalidScore=false;var students=component.get('studentList');students.forEach(function(student){student.get('questions').forEach(function(question){if(question.get('score') == null){isInvalidScore = true;question.set('invalidScore',true);}else {question.set('invalidScore',false);}});});if(isInvalidScore){component.get('notifications').setOptions({positionClass:'toast-top-full-width',toastClass:'gooru-toast',timeOut:10000});var message=component.get('i18n').t('notifications.invalid-score');component.get('notifications').error('' + message);}else {component.sendAction('onConfirmScore');}}, /**
     * Action triggered when user clicks on reupload button
     */onReUpload:function onReUpload(){var component=this;component.set('selectedFile',component.get('activeContent'));}, /**
     * Action triggered when file loaded from file picker
     */prepareForReUpload:function prepareForReUpload(file){var component=this;component.sendAction('onPrepareForReUpload',file);}}, // -------------------------------------------------------------------------
// Methods
/**
   * Method is used to fetch image data
   */fetchImageData:function fetchImageData(){var component=this;var uploadContent=component.get('activeContent');if(uploadContent.get('id')){component.set('isLoading',true);component.get('i2dService').fetchImageData(uploadContent.get('id')).then(function(content){var uploadStatus=content.get('uploadInfo.status');var parsedData=content.get('parsedData');var conversionErros=content.get('conversionInfo.errorCodes');uploadContent.set('conversionErros',conversionErros);component.set('questions',component.parseQuestions(parsedData).sortBy('sequence'));component.set('studentList',component.parseScores(parsedData));uploadContent.set('uploadStatus',uploadStatus);uploadContent.set('isUploadReadyForReview',uploadStatus === _gooruWebConfigConfig.I2D_CONVERSION_STATUS.READY_FOR_REVIEW);component.set('isLoading',false);});}}, /**
   * Method is used to parse questions
   * @return {Array}
   */parseQuestions:function parseQuestions(data){var questions=data.uniqBy('questionId');return questions.map(function(question){return _ember['default'].Object.create({id:question.questionId,title:question.questionTitle,type:question.questionType,maxScore:question.maxScore,sequence:question.questionSequence});});}, /**
   * Method is used to parse scores
   * @return {Array}
   */parseScores:function parseScores(data){var component=this;var users=data.uniqBy('userId').filter(function(user){return user && user.get('userId');});return users.map(function(user){var student=component.parseUserDetails(user);var studentData=data.filterBy('userId',student.get('userId'));student.set('questions',studentData);return student;});}, /**
   * Method is used to parse user details
   * @return {Object}
   */parseUserDetails:function parseUserDetails(user){return _ember['default'].Object.create({userId:user.get('userId'),username:user.get('username'),email:user.get('email'),firstName:user.get('firstName'),lastName:user.get('lastName')});}});});