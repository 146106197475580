define('gooru-web/components/reports/assessment/questions/gru-fib',['exports','ember','gooru-web/mixins/reports/assessment/questions/question','gooru-web/utils/question/fill-in-the-blank'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion,_gooruWebUtilsQuestionFillInTheBlank){ /**
 * Fill in the blank
 *
 * Component responsible for controlling the logic and appearance of a fill in the blank
 * question inside of the assessment report.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-fib'], // -------------------------------------------------------------------------
// Properties
answer:_ember['default'].computed('question.answerObject.[]','anonymous',function(){var component=this;var question=component.get('question');var questionUtil=this.getQuestionUtil(question);var questionText=_ember['default'].get(question,'fibText') || _ember['default'].get(question,'text');var questionTextParts=questionText.split(_gooruWebUtilsQuestionFillInTheBlank['default'].LEGACY_REGEX.text);var userAnswers=component.get('userAnswer');var anonymous=component.get('anonymous');if(component.get('showCorrect')){userAnswers = questionUtil.getCorrectAnswer();}var elementClass='anonymous'; // use the anwerobject overall status value to decide whether to underline
// the user answer in Green or in Red. If overall status value reports user
// got it incorrect, then try to look at individual answer object..needed
//    as there can be >=1 blanks at FIB and we try to point out as best as
//    we can as to the specific blank that is incorrectly answered by user
var userAnswerObjects=questionUtil.toAnswerObjects(userAnswers);var answers=undefined;if(userAnswerObjects){answers = userAnswerObjects.map(function(userAnswerObject,index){if(!userAnswerObject.skip && userAnswerObject.status === 'correct'){elementClass = anonymous?'anonymous':'correct';}else {var userAnswerCorrect=questionUtil.isAnswerChoiceCorrect(userAnswerObject.get('text'),index);elementClass = anonymous?'anonymous':userAnswerCorrect?'correct':'incorrect';}return {text:userAnswerObject.get('text'),'class':'answer ' + elementClass};});}var sentences=questionTextParts.map(function(questionTextPart){return {text:questionTextPart,'class':'sentence'};});sentences = userAnswers && userAnswers.length?sentences:[];return this.mergeArrays(sentences,answers);}), // -------------------------------------------------------------------------
// Methods
/**
   * Merge sentences and answers arrays
   * @return {Array}
   */mergeArrays:function mergeArrays(sentences,answers){if(answers){answers.forEach(function(item,index){sentences.insertAt(index * 2 + 1,item);});return sentences;}}});});