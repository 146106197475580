define('gooru-web/components/gru-featured-courses',['exports','ember','gooru-web/utils/sort-featured-courses'],function(exports,_ember,_gooruWebUtilsSortFeaturedCourses){ /**
 * featured courses component
 *
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-featured-courses'],attributeBindings:['dataSpy:data-spy','dataTarget:data-target','dataOffset:data-offset'],dataSpy:'scroll',dataTarget:'.navbar',dataOffset:'50', // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Remix course action, when clicking remix at the course card
     * @param {Content/Course}
     */onRemixCourse:function onRemixCourse(course){ /*
      *@TODO: get the correct information from the Course Searches from taxonomy so we can *normalize it accordingly so it can be copied.
      */this.sendAction('onRemixCourse',course);}}, // -------------------------------------------------------------------------
// Properties
courses:null,formattedContent:_ember['default'].computed('courses',function(){var _this=this;return (0,_gooruWebUtilsSortFeaturedCourses.getSubjects)(this.get('courses')).map(function(subjectBucket,index){return _ember['default'].Object.create({category:subjectBucket.subject.slice(subjectBucket.subject.indexOf('.') + 1,subjectBucket.subject.lastIndexOf('.')),subject:subjectBucket.taxonomySubject,courses:(0,_gooruWebUtilsSortFeaturedCourses.sortFeaturedCourses)(_this.get('courses'))[index]});});}), // -------------------------------------------------------------------------
// Methods
didInsertElement:function didInsertElement(){this._super.apply(this,arguments);$('#subject-navbar').affix({offset:200});}});});