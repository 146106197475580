define('gooru-web/models/performance/activity-performance-summary',['exports','ember'],function(exports,_ember){ /**
 * Activity performance summary model
 *
 * @typedef {Object} ActivityPerformanceSummary
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string} user id for the student
   */userId:null, /**
   * @property {Date}
   */date:null, /**
   * @property {CollectionPerformanceSummary}
   */collectionPerformanceSummary:null});});