define('gooru-web/components/reports/pull-up/domain-competency-report',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['domain-competency-report'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;var domainDataSet=component.get('domainSet');component.set('studentCompetencyData',domainDataSet.studentCompetencies.sortBy('lastName'));}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click sort by first name
     */sortByFirstName:function sortByFirstName(){var component=this;var studentCompetencyData=component.get('studentCompetencyData');component.set('studentCompetencyData',studentCompetencyData.sortBy('firstName'));component.set('sortByFirstnameEnabled',true);component.set('sortByLastnameEnabled',false);}, /**
     * Action triggered when click sort by last name
     */sortByLastName:function sortByLastName(){var component=this;var studentCompetencyData=component.get('studentCompetencyData');component.set('studentCompetencyData',studentCompetencyData.sortBy('lastName'));component.set('sortByLastnameEnabled',true);component.set('sortByFirstnameEnabled',false);}, /**
     * Action triggered when click next/previous arrows
     */onClickArrow:function onClickArrow(direction){var component=this;var studentCompetenciesListContainer=component.$('.competencies-list');var curPos=studentCompetenciesListContainer.scrollLeft();var nextPos=direction === 'previous'?curPos - 120:curPos + 120;studentCompetenciesListContainer.animate({scrollLeft:nextPos},400);}, /**
     * Action triggered when select a competency from competency report
     */onSelectCompetency:function onSelectCompetency(competency,userId){var component=this;component.sendAction('onSelectCompetency',competency,userId);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {sortByFirstnameEnabled}
   * Property to enable/disable sort by first name
   */sortByFirstnameEnabled:false, /**
   * @property {sortByLastnameEnabled}
   * Property to enable/disable sort by last name
   */sortByLastnameEnabled:true});});