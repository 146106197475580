define('gooru-web/components/content/modals/gru-welcome-message',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @dependency {Service} Session service
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-welcome-message'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Check if 'Dont show again' checkbox is selected and create variable on localStorage
     */close:function close(){var $checkboxShowAgain=this.$('#dontShowAgain');if($checkboxShowAgain.prop('checked')){var _localStorage=this.getLocalStorage();var userId=this.get('session.userId');var localStorageItem=userId + '_dontShowWelcomeModal';_localStorage.setItem(localStorageItem,true);}}} // -------------------------------------------------------------------------
// Methods
});});