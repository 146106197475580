define('gooru-web/components/activity/resource-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config','gooru-web/utils/endpoint-config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig,_gooruWebUtilsEndpointConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['activity-resource-card'], // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * resource object
   * @type {Object}
   */resource:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('resource.standards.[]',function(){var standards=this.get('resource.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user play a resource
     */onPlayResource:function onPlayResource(resourceId){var playerURL=(0,_gooruWebUtilsEndpointConfig.getEndpointUrl)() + '/content/resources/play/' + resourceId + '?source=' + _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.RGO;window.open(playerURL,_gooruWebConfigConfig.PLAYER_WINDOW_NAME);}}});});