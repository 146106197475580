define('gooru-web/components/taxonomy/gru-preview-taxonomy-tag-list',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){ /**
 * Preview Taxonomy tag list from search
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['taxonomy','gru-preview-taxonomy-tag-list'], // --------------------------------------------
// Actions
// --------------------------------------------
// Properties
/**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('standards.[]',function(){var standards=this.get('standards');standards = standards.filter(function(standard){ // Filter out learning targets (they're too long)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), /**
   * Indicates the total of tags visible
   * @property {number}
   */tagsVisible:null, /**
   * @property {TaxonomyTag[]} taxonomy tag
   */visibleTags:_ember['default'].computed('tags.[]',function(){var tagsVisible=this.get('tagsVisible') || 999999; //long number so it show all when no provided
return this.get('tags').filter(function(tag,index){return index < tagsVisible;});}), /**
   * @property {number}
   */totalTags:_ember['default'].computed.alias('tags.length'), /**
   * Indicates how many tags are not visible
   * @property {number}
   */nonVisibleTags:_ember['default'].computed('totalTags',function(){var totalTags=this.get('totalTags');var tagsVisible=this.get('tagsVisible') || totalTags;var nonVisibleTags=totalTags - tagsVisible;return nonVisibleTags > 0?nonVisibleTags:0;}) // -------------------------------------------------------------------------
// Methods
});});