define('gooru-web/components/player/questions/gru-match-question',['exports','ember','gooru-web/components/player/questions/gru-question'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruQuestion){exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({classNames:['player','gru-match-the-following-question'], // -------------------------------------------------------------------------
// Events
initSortableList:_ember['default'].on('didInsertElement',function(){var component=this;component.setAnswers();if(!component.get('hasUserAnswer')){component.shuffle();}this.set('areAnswersShuffled',true);}),removeSubscriptions:_ember['default'].on('willDestroyElement',function(){this.$('.sortable').off('sortupdate');}),isPreviewCard:false, // -------------------------------------------------------------------------
// Properties
/**
   * Convenient structure to render the question answer choices
   * @property {*}
   */answers:_ember['default'].computed('question.answers.[]',function(){var answers=this.get('question.answers').sortBy('order');return answers;}),leftArray:_ember['default'].computed('answers',function(){var left=_ember['default'].A();this.get('answers').map(function(answer){var answerObject=_ember['default'].Object.create({sequence:answer.order,leftValue:answer.leftValue,leftValueFormat:answer.leftValueFormat});left.pushObject(answerObject);});return left;}),rightArray:_ember['default'].computed('answers',function(){var right=_ember['default'].A();this.get('answers').map(function(answer){var answerObject=_ember['default'].Object.create({sequence:answer.order,rightValue:answer.rightValue,rightValueFormat:answer.rightValueFormat});right.pushObject(answerObject);});return right;}), /**
   * Return true if the answers list are shuffled
   * @property {Boolean}
   */areAnswersShuffled:false, // -------------------------------------------------------------------------
// Methods
/**
   * Set answers
   */setAnswers:function setAnswers(){var component=this;var sortable=this.$('.sortable');var readOnly=component.get('readOnly');sortable.sortable({containment:'parent'});if(readOnly){sortable.sortable('disable');}if(component.get('hasUserAnswer')){component.notify(true);} // Manually add subscriptions to sortable element -makes it easier to test
sortable.on('sortupdate',function(){component.notify(false);});}, /**
   * Notifies answer events
   * @param {boolean} onLoad if this was called when loading the component
   */notify:function notify(onLoad){var component=this;var questionUtil=this.get('questionUtil');var $items=component.$('.sortable').find('li');var answers=$items.map(function(idx,item){return $(item).data('id');}).toArray();var correct=questionUtil.isCorrect(answers);component.notifyAnswerChanged(answers,correct);if(onLoad){component.notifyAnswerLoaded(answers,correct);}else {component.notifyAnswerCompleted(answers,correct);}}, /**
   * Take the list of items and shuffle all his members
   */shuffle:function shuffle(){var component=this;var $items=component.$('.sortable');return $items.each(function(){var items=$items.children();return items.length?$(this).html(component.disorder(items)):$items;});}, /**
   * Disorder elements
   */disorder:function disorder(list){var j,x,i=list.length;while(i) {j = parseInt(Math.random() * i);i -= 1;x = list[i];list[i] = list[j];list[j] = x;}return list;}});});