define('gooru-web/serializers/events/events',['exports','ember','gooru-web/config/question','gooru-web/utils/utils','gooru-web/config/environment','gooru-web/serializers/taxonomy/taxonomy'],function(exports,_ember,_gooruWebConfigQuestion,_gooruWebUtilsUtils,_gooruWebConfigEnvironment,_gooruWebSerializersTaxonomyTaxonomy){var ConfigEvent=_gooruWebConfigEnvironment['default'].events || {};exports['default'] = _ember['default'].Object.extend({ /**
   * @property {TaxonomySerializer} taxonomySerializer
   */taxonomySerializer:null,init:function init(){this._super.apply(this,arguments);this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Serializes a assessment result
   * @param {AssessmentResult} assessmentResult
   * @param {Context} context
   * @param {string} apiKey
   * @returns {*[]}
   */serializeCollection:function serializeCollection(assessmentResult,context,eventType,apiKey){var serializer=this;var totalNonOpenEndedQuestions=assessmentResult.get('totalNonOpenEndedQuestions');var contextObject=serializer.getContextValuesForCollection(context,eventType,totalNonOpenEndedQuestions);var startedAt=assessmentResult.get('startedAt');var submittedAt=assessmentResult.get('submittedAt');var startTime=(0,_gooruWebUtilsUtils.toTimestamp)(startedAt);var endTime=!submittedAt?startTime:(0,_gooruWebUtilsUtils.toTimestamp)(submittedAt);return [{eventId:context.get('parentEventId'),eventName:'collection.play',session:{apiKey:apiKey,sessionId:context.get('sessionId')},startTime:startTime,endTime:endTime,user:{gooruUId:context.get('userId')},context:contextObject,version:{logApi:ConfigEvent.apiVersion},metrics:{},payLoadObject:{gradingType:'System',isStudent:context.get('isStudent'),sourceId:context.get('sourceId')}}];}, /**
   * Serializes a result
   * @param {ResourceResult} resourceResult
   * @param {Context} context
   * @param {string} eventType start|stop
   * @param {string} apiKey
   * @returns {*[]}
   */serializeResource:function serializeResource(resourceResult,context,eventType,apiKey){var serializer=this;var resource=resourceResult.get('resource');var resourceType=resource.get('isQuestion')?'question':'resource';var reactionType=resourceResult.get('reaction');var contextObject=serializer.getContextValuesForResult(context,resource.get('id'),eventType,resourceType,reactionType);var startedAt=resourceResult.get('startedAt');var submittedAt=resourceResult.get('submittedAt');var startTime=(0,_gooruWebUtilsUtils.toTimestamp)(startedAt);var endTime=!submittedAt?startTime:(0,_gooruWebUtilsUtils.toTimestamp)(submittedAt);endTime = endTime < startTime?startTime:endTime; //endTime can't be lower than start time - GG-2111
var serialized={eventId:resourceResult.get('resourceEventId'),eventName:'collection.resource.play',session:{apiKey:apiKey,sessionId:context.get('sessionId')},startTime:startTime,endTime:endTime,user:{gooruUId:context.get('userId')},context:contextObject,version:{logApi:ConfigEvent.apiVersion},metrics:{}};if(resource.get('isQuestion')){var question=resourceResult.get('question');var util=(0,_gooruWebConfigQuestion.getQuestionUtil)(question.get('questionType')).create({question:question});var userAnswer=resourceResult.get('userAnswer');serialized.payLoadObject = {questionType:resourceResult.get('question.questionType'),attemptStatus:eventType === 'stop'?resourceResult.get('attemptStatus'):undefined,answerObject:util.toJSONAnswerObjects(userAnswer),isStudent:context.get('isStudent'),taxonomyIds:serializer.get('taxonomySerializer').serializeTaxonomyForEvents(resource.get('taxonomy')),sourceId:context.get('sourceId')};}else {serialized.payLoadObject = { //TODO looks for resource parameters
isStudent:context.get('isStudent'),taxonomyIds:serializer.get('taxonomySerializer').serializeTaxonomyForEvents(resource.get('taxonomy')),sourceId:context.get('sourceId')};}return [serialized];},serializeReaction:function serializeReaction(resourceResult,context,apiKey){var serializer=this;var resource=resourceResult.get('resource');var reactionType=resourceResult.get('reaction'); // Extracts the reaction value (reactionType) from the resourceResult
var startTime=(0,_gooruWebUtilsUtils.toTimestamp)(resourceResult.get('startedAt'));var contextObject=serializer.getContextValuesForReaction(context,resource.get('id'),reactionType);var serialized={eventId:resourceResult.get('resourceEventId'),eventName:'reaction.create',session:{apiKey:apiKey,sessionId:context.get('sessionId')},user:{gooruUId:context.get('userId')},startTime:startTime,endTime:startTime, // Setting the same startTime for the endTime
context:contextObject,version:{logApi:ConfigEvent.apiVersion},metrics:{},payLoadObject:{isStudent:context.get('isStudent'),sourceId:context.get('sourceId')}};return [serialized];}, /**
   * Gets context values
   * @param {Context} context
   * @param {string} eventType start|stop
   * @param {string} resourceType question|resource
   * @param {string} reactionType
   * @returns {*}
   */getContextValuesForResult:function getContextValuesForResult(context,resourceId,eventType,resourceType,reactionType){return {contentGooruId:resourceId,parentGooruId:context.get('collectionId'),classGooruId:context.get('classId'),parentEventId:context.get('parentEventId'),type:eventType,courseGooruId:context.get('courseId'),unitGooruId:context.get('unitId'),lessonGooruId:context.get('lessonId'),collectionType:context.get('collectionType'),resourceType:resourceType,clientSource:'web',reactionType:reactionType};}, /**
   * Gets context values for collection
   * @param {Context} context
   * @param {string} eventType
   * @param {number} questionCount
   * @returns {*}
   */getContextValuesForCollection:function getContextValuesForCollection(context,eventType,questionCount){return {type:eventType,contentGooruId:context.get('collectionId'),classGooruId:context.get('classId'),courseGooruId:context.get('courseId'),unitGooruId:context.get('unitId'),lessonGooruId:context.get('lessonId'),collectionType:context.get('collectionType'),questionCount:questionCount,clientSource:'web'};},getContextValuesForReaction:function getContextValuesForReaction(context,resourceId,reactionType){return {contentGooruId:resourceId,parentGooruId:context.get('collectionId'),classGooruId:context.get('classId'),parentEventId:context.get('parentEventId'),courseGooruId:context.get('courseId'),unitGooruId:context.get('unitId'),lessonGooruId:context.get('lessonId'),collectionType:context.get('collectionType'),reactionType:reactionType};}});});