define('gooru-web/components/content/modals/gru-resource-remix',['exports','ember','gooru-web/components/content/modals/gru-base-remix'],function(exports,_ember,_gooruWebComponentsContentModalsGruBaseRemix){exports['default'] = _gooruWebComponentsContentModalsGruBaseRemix['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Assessment service API SDK
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {Service} Collection service API SDK
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @property {Service} Resource service API SDK
   */resourceService:_ember['default'].inject.service('api-sdk/resource'),fluencyService:_ember['default'].inject.service('api-sdk/fluency'),tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-resource-remix'],isShowFluencyLevel:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());return tenantSettings && tenantSettings.fluency_level === 'on';}), // -------------------------------------------------------------------------
// Actions
copyContent:function copyContent(resource){return this.get('resourceService').copyResource(resource.get('id'),resource.get('title'));},updateContent:function updateContent(resource){var component=this;var collectionId=component.get('collectionId');if(component.get('model').collection.metadata && component.get('model').collection.metadata.fluency && component.get('isShowFluencyLevel')){var fluData=_ember['default'].Object.create({});fluData.fluency = component.get('model').collection.metadata.fluency;component.get('fluencyService').updateFluencyLevel(fluData,'collections',collectionId);}return collectionId?component.get('collectionService').addResource(collectionId,resource.get('id')):_ember['default'].RSVP.resolve();},showSuccessNotification:function showSuccessNotification(resource){var component=this;var successMsg=component.get('i18n').t('common.remix-resource-success',{resourceTitle:resource.get('title')});var resourceEditUrl=component.get('router').generate('content.resources.edit',resource.get('id'));var edit=component.get('i18n').t('common.edit');component.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + resourceEditUrl + '">' + edit + '</a>');},showFailureNotification:function showFailureNotification(){var message=this.get('i18n').t('common.errors.resource-not-copied').string;this.get('notifications').error(message);},init:function init(){this._super.apply(this,arguments);this.set('collectionId',this.get('model.collectionId'));},collectionId:null,isCollection:null});});