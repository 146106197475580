define('gooru-web/controllers/teacher/class/student-learner-proficiency',['exports','ember','gooru-web/mixins/student-learner-proficiency'],function(exports,_ember,_gooruWebMixinsStudentLearnerProficiency){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsStudentLearnerProficiency['default'],{ // -------------------------------------------------------------------------
// Dependenciess
/**
   * Teacher class controller
   */classController:_ember['default'].inject.controller('teacher/class'), /**
   * @property {Array} fwCompetencies
   */fwCompetencies:_ember['default'].computed.alias('classController.fwCompetencies'), /**
   * @property {Array} fwDomains
   */fwDomains:_ember['default'].computed.alias('classController.fwDomains'),classFramework:_ember['default'].computed.alias('classController.classFramework'),isDefaultShowFW:_ember['default'].computed.alias('classController.isDefaultShowFW'), // -------------------------------------------------------------------------
// Attributes
queryParams:['tab'],tab:null});});