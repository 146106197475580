define('gooru-web/components/datepicker-field',['exports','ember'],function(exports,_ember){ /**
 * Datepicker field component
 * @see datepicker-field.hbs
 *
 *
 * @module
 * @typedef {object} DatepickerField
 */exports['default'] = _ember['default'].Component.extend({ /**
   * @property {string} placeholder
   */placeholder:null, /**
   * @property {string} on select date action
   */onSelectDateAction:'onSelectDate', /**
   * @property {string} selected date,  format: 'mm/DD/YYYY'
   */selectedDate:null,didInsertElement:function didInsertElement(){var component=this;var $component=component.$('.datepicker-icon');$component.datepicker({autoclose:true});$component.datepicker('update',component.get('selectedDate'));$component.on('changeDate',function(){var dateValue=$component.datepicker('getFormattedDate');component.$('.datepicker').val(dateValue);component.set('selectedDate',dateValue);component.sendAction('onSelectDateAction',dateValue);});}});});