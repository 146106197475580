define('gooru-web/components/activity/assessment-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config','gooru-web/utils/endpoint-config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig,_gooruWebUtilsEndpointConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['activity-assessment-card'], // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * assessment object
   * @type {Object}
   */assessment:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('assessment.standards.[]',function(){var standards=this.get('assessment.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}),actions:{onSuggestContent:function onSuggestContent(collection){var component=this;component.sendAction('onSuggestContent',collection);}, /**
     * Action triggered when the user play collection
     * It'll open the player in new tab
     */onPlayAssessment:function onPlayAssessment(assessmentId){var playerURL=(0,_gooruWebUtilsEndpointConfig.getEndpointUrl)() + '/player/' + assessmentId + '?source=' + _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.RGO;window.open(playerURL,_gooruWebConfigConfig.PLAYER_WINDOW_NAME);}}});});