define('gooru-web/utils/taxonomy',['exports','ember','gooru-web/models/taxonomy/taxonomy-item','gooru-web/models/taxonomy/browse-item','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyItem,_gooruWebModelsTaxonomyBrowseItem,_gooruWebConfigConfig){exports.generateTaxonomyTestTree = generateTaxonomyTestTree;exports.generateBrowseTestTree = generateBrowseTestTree;exports.getCategoryFromSubjectId = getCategoryFromSubjectId;exports.getCategoryCodeFromSubjectId = getCategoryCodeFromSubjectId;exports.getSubjectId = getSubjectId;exports.getGutCodeFromSubjectId = getGutCodeFromSubjectId;exports.getCourseId = getCourseId;exports.getDomainId = getDomainId;exports.getDomainCode = getDomainCode;exports.flattenGutToFwCompetency = flattenGutToFwCompetency;exports.flattenGutToFwDomain = flattenGutToFwDomain;exports.getTaxonomySubject = getTaxonomySubject;function _defineProperty(obj,key,value){if(key in obj){Object.defineProperty(obj,key,{value:value,enumerable:true,configurable:true,writable:true});}else {obj[key] = value;}return obj;} /**
 * Generates a taxonomy tree data structure for testing
 * @param {Number} levels - total number of parent/children levels in the tree
 * @param {TaxonomyItem} parent - parent item for all the items created in the current level
 * @param {Number} inc - number by which the number of items in each level will increase
 * @param {Number} currentLevel - current tree level being built (starts at 1)
 * @return {TaxonomyItem[][] ...} - the list of taxonomy items in the first level
 */function generateTaxonomyTestTree(){var levels=arguments.length <= 0 || arguments[0] === undefined?1:arguments[0];var parent=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var inc=arguments.length <= 2 || arguments[2] === undefined?1:arguments[2];var currentLevel=arguments.length <= 3 || arguments[3] === undefined?1:arguments[3];var totalItems=currentLevel * inc;var items=[];if(currentLevel <= levels){for(var i=0;i < totalItems;i++) {var parentId=parent?parent.get('id'):'0';var parentIdNum=parentId.charAt(parentId.length - 1);var itemId=currentLevel + parentIdNum + i;var taxonomyItem=_gooruWebModelsTaxonomyTaxonomyItem['default'].create({id:parentId + '-' + itemId,code:'Code : ' + currentLevel + ' : ' + parentIdNum + ' : ' + i,title:'Item : ' + currentLevel + ' : ' + parentIdNum + ' : ' + i,level:currentLevel,parent:parent});generateTaxonomyTestTree(levels,taxonomyItem,inc,currentLevel + 1);items.push(taxonomyItem);}if(parent){ // Link all items to parent
parent.set('children',items);}return items;}} /**
 * Generates a tree data structure for testing the browse selector (@see gru-browse-selector)
 * @param {Number} levels - total number of parent/children levels in the tree
 * @param {Number} lastLevels - number of sub-levels in the last level of the tree
 * @param {Number} inc - number by which the number of items in each level will increase
 * @return {BrowseItem[][] ...} - the list of browse items in the first level
 */function generateBrowseTestTree(){var levels=arguments.length <= 0 || arguments[0] === undefined?1:arguments[0];var lastLevels=arguments.length <= 1 || arguments[1] === undefined?0:arguments[1];var inc=arguments.length <= 2 || arguments[2] === undefined?1:arguments[2];var startLevel=1;var browseItems=[];var taxonomyItems=generateTaxonomyTestTree(levels + lastLevels,null,inc,startLevel);taxonomyItems.forEach(function(rootTaxonomyItem){var item=_gooruWebModelsTaxonomyBrowseItem['default'].createFromTaxonomyItem(rootTaxonomyItem,levels + lastLevels);browseItems.push(item);});return browseItems;} /**
 * Gets a category object from a subjectId
 * @param {String} subjectId - The subject id with the format 'CCSS.K12.Math'
 * @return {Object} - An object with the category information
 */function getCategoryFromSubjectId(subjectId){var categoryCode=subjectId.split('.')[0];var categories=_ember['default'].A(_gooruWebConfigConfig.TAXONOMY_CATEGORIES);var category=categories.findBy('apiCode',categoryCode);if(!category){categoryCode = subjectId.split('.')[1];category = categories.findBy('apiCode',categoryCode);}return category?category.value:null;} /**
 * Gets a category object from a subjectId
 * @param {String} subjectId - The subject id with the format 'CCSS.K12.Math', 'K12.Math'
 * @return {Object} - An object with the category information
 */function getCategoryCodeFromSubjectId(subjectId){var categoryCode=subjectId.split('.');return categoryCode.length === 3?categoryCode[1]:categoryCode[0];} /**
 * Parse and read subject id for given string
 * @param  {String} id
 * @return {String}
 */function getSubjectId(id){return id.substring(0,id.indexOf('-'));} /**
 * Parse and read gut code id from given subject id
 * @param  {String} id
 * @return {String}
 */function getGutCodeFromSubjectId(subjectId){var subjectCodes=subjectId.split('.');return subjectCodes.length === 3?subjectCodes[1] + '.' + subjectCodes[2]:subjectId;} /**
 * Parse and read course id for given string
 * @param  {String} id
 * @return {String}
 */function getCourseId(id){var ids=id.split('-');return ids[0] + '-' + ids[1];} /**
 * Parse and read domain id for given string
 * @param  {String} id
 * @return {String}
 */function getDomainId(id){var ids=id.split('-');return ids[0] + '-' + ids[1] + '-' + ids[2];} /**
 * Parse and extract domain code from given GUT code
 * @param {String} gutCode
 * @return {String}
 */function getDomainCode(gutCode){var domainCode=null;if(gutCode){var ids=gutCode.split('-');domainCode = ids.objectAt(2);}return domainCode;} /**
 * parse and form a json object
 * @param {Array} competencyMatrixs
 * @return {Array}
 */function flattenGutToFwCompetency(competencyMatrixs){var fwCompetencies=[];competencyMatrixs.map(function(competencyMatrix){var topics=competencyMatrix.get('topics');topics.forEach(function(topic){var competencies=topic.competencies;competencies.forEach(function(competency){fwCompetencies.push(_defineProperty({},competency.competencyCode,competency));});});});return fwCompetencies;} /**
 * parse and form a json object
 * @param {Array} competencyMatrixs
 * @return {Array}
 */function flattenGutToFwDomain(competencyMatrixs){return competencyMatrixs.map(function(competencyMatrix){return _defineProperty({},competencyMatrix.domainCode,competencyMatrix);});} /**
 * parse and form a string
 * @param {string} getTaxonomySubject
 * @return {string}
 */function getTaxonomySubject(competencyCode){var compFWCode=competencyCode.split('.');var compCode=compFWCode.slice(1,3).join('.');var subjectCode=compCode.split('-');return subjectCode[0] || null;}});