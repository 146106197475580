define('gooru-web/routes/teacher/class/students-proficiency',['exports','ember','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsUiHelperMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {ClassService} Service to retrieve class information
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @type {PerformanceService} Service to retrieve class performance summary
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), /**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {UnitService} Service to retrieve unit information
   */unitService:_ember['default'].inject.service('api-sdk/unit'), /**
   * @type {i18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/competency
   */competencyService:_ember['default'].inject.service('api-sdk/competency'), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('taxonomy'), /**
   *  @type {tenantService} Service to retrieve tenant information
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'),queryParams:{landingPage:null,activeMinProfReport:null,selectedUserId:null}, // ---------------------------------------------------------------
// Method
/**
   * Get model for the controller
   */model:function model(){var route=this;var currentClass=route.modelFor('teacher.class')['class'];route.setTitle('Learner Locator',currentClass.title);var tenantPromise=route.get('tenantService').getActiveTenantSetting();var userPreference=route.get('profileService').getProfilePreference();return _ember['default'].RSVP.hash({tenantSettings:tenantPromise,userPreference:userPreference}).then(function(hash){return {tenantSettings:hash.tenantSettings,userPreference:hash.userPreference};});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){controller.get('classController').selectMenuItem('students');controller.set('classController.isShowExpandedNav',true);controller.set('tenantSettings',model.tenantSettings);controller.set('userPreference',model.userPreference);controller.changeLanguage();controller.loadStudentsProficiencyData();controller.init();},resetController:function resetController(controller){controller.resetProperties();}});});