define('gooru-web/controllers/profile/network/following',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
profileController:_ember['default'].inject.controller('profile'),profileService:_ember['default'].inject.service('api-sdk/profile'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Actions
actions:{unFollowUser:function unFollowUser(user){var controller=this;var userId=user.id;var countFollowings=controller.get('countFollowings');controller.get('profileService').unfollowUserProfile(userId).then(function(){if(controller.get('profileController.profile.id') === controller.get('session.userId')){controller.get('followings').removeObject(user);controller.set('countFollowings',countFollowings - 1);controller.set('isFollowing',false);}user.set('followers',user.get('followers') - 1);user.set('isFollowing',false);});},followUser:function followUser(user){var controller=this;var userId=user.id;var countFollowings=controller.get('countFollowings');controller.get('profileService').followUserProfile(userId).then(function(){if(controller.get('profileController.profile.id') === controller.get('session.userId')){controller.set('countFollowings',countFollowings + 1);}user.set('followers',user.get('followers') + 1);user.set('isFollowing',true);});}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {User[]} followings
   */followings:null, /**
   * @property {boolean} isMyProfile
   */isMyProfile:_ember['default'].computed.alias('profileController.isMyProfile'), /**
   * @property {Number} counter of profile followings
   */countFollowings:_ember['default'].computed.alias('profileController.profile.followings'), /**
   * @property {boolean} isFollowing
   */isFollowing:_ember['default'].computed.alias('profileController.profile.isFollowing')});});