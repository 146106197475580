define('gooru-web/serializers/content/alternate-path',['exports','ember','gooru-web/models/content/alternate-path','gooru-web/models/content/resource','gooru-web/mixins/configuration','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsContentAlternatePath,_gooruWebModelsContentResource,_gooruWebMixinsConfiguration,_gooruWebConfigConfig){ /**
 * Serializer for Alternate Path data
 *
 * @typedef {Object} AlternatePathSerializer
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{session:_ember['default'].inject.service('session'), /**
   * Normalize the Alternate Path data
   * @param payload
   * @returns {AlternatePathModel}
   */normalizeAlternatePath:function normalizeAlternatePath(payload){var serializer=this;return _gooruWebModelsContentAlternatePath['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{pathId:payload.id,title:payload.title,thumbnail:payload.thumbnail,contextClassId:payload.ctx_class_id,contextCourseId:payload.ctx_course_id,contextUnitId:payload.ctx_unit_id,contextLessonId:payload.ctx_lesson_id,contextCollectionId:payload.ctx_collection_id,targetCourseId:payload.target_course_id,targetUnitId:payload.target_unit_id,targetLessonId:payload.target_lesson_id,targetCollectionId:payload.target_collection_id,targetResourceId:payload.target_resource_id,targetContentType:payload.target_content_type,targetContentSubtype:payload.target_content_subtype,questionCount:payload.question_count,openEndedQuestionCount:payload.oe_question_count,resourceCount:payload.resource_count});}, /**
   * Normalize the resource data from alternate path into a Resource object
   * @param resourceData
   * @returns {Resource}
   */normalizeReadResource:function normalizeReadResource(resourceData){var serializer=this;var format=_gooruWebModelsContentResource['default'].normalizeResourceFormat(resourceData.targetContentSubtype);var resource=_gooruWebModelsContentResource['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{id:resourceData.targetResourceId,title:resourceData.title,format:format,assessmentId:resourceData.contextCollectionId,classId:resourceData.contextClassId,courseId:resourceData.contextCourseId,unitId:resourceData.contextUnitId,lessonId:resourceData.contextLessonId,pathId:resourceData.pathId});return resource;}, /**
   * @function normalizeSuggestedCollection
   * Method to normalize suggested collection/assessment content
   */normalizeSuggestedCollection:function normalizeSuggestedCollection(content,source){var serializer=this;var pathType=source === 'teacher_suggestions'?'teacher':'system';var basePath=serializer.get('session.cdnUrls.content');var appRootPath=serializer.get('appRootPath'); //configuration appRootPath
var defaultImage=content.suggested_content_type === 'collection'?appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.COLLECTION:appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.ASSESSMENT;var thumbnailUrl=content.thumbnail?basePath + content.thumbnail:defaultImage;return _gooruWebModelsContentAlternatePath['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{id:content.suggested_content_id,format:content.suggested_content_type,url:content.url || null,questionCount:content.question_count || 0,resourceCount:content.resource_count || 0,openEndedQuestionCount:content.oe_question_count || 0,sequence:content.sequence_id || null,title:content.title,assessmentId:content.ctx_collection_id || null,classId:content.ctx_class_id || null,courseId:content.ctx_course_id || null,unitId:content.ctx_unit_id || null,lessonId:content.ctx_lesson_id || null,pathId:content.id,collectionSubType:content.suggested_content_subtype,source:source,thumbnailUrl:thumbnailUrl,isSuggestedContent:true,pathType:pathType,ctxPathId:content.ctx_path_id || 0,ctxPathType:content.ctx_path_type});}, /**
   * @function normalizeSuggestedResource
   * Method to normalize suggesetd resource content
   */normalizeSuggestedResource:function normalizeSuggestedResource(content,source){var serializer=this;var format=_gooruWebModelsContentResource['default'].normalizeResourceFormat(content.suggested_content_type);var resource=_gooruWebModelsContentResource['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{id:content.suggested_content_id,title:content.title,format:format,assessmentId:content.ctx_collection_id || null,classId:content.ctx_class_id || null,courseId:content.ctx_course_id || null,unitId:content.ctx_unit_id || null,lessonId:content.ctx_lesson_id || null,pathId:content.id || null,contentSubFormat:content.content_subformat || null,source:source,isSuggestedContent:true});return resource;}});});