define('gooru-web/adapters/media',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'), /**
   * @property {string} End-point URI
   */namespace:'/api/nucleus-media/v1/uploads', /**
   * Uploads file to S3
   * @param requestType
   * @param query
   * @returns {string}
   */uploadFile:function uploadFile(fileData,type){var isAudio=arguments.length <= 2 || arguments[2] === undefined?false:arguments[2];var formData=new FormData();if(isAudio){formData.append('file',fileData,'blob.mp3');}else {formData.append('file',fileData);}formData.append('entity_type',type);var adapter=this;var url=adapter.get('namespace');var options={type:'POST',contentType:false,processData:false,dataType:'json',headers:adapter.defineHeaders(),data:formData};return _ember['default'].$.ajax(url,options);},uploadScormFile:function uploadScormFile(fileData){var formData=new FormData();formData.append('file',fileData);var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/scorm';var options={type:'POST',contentType:false,processData:false,dataType:'json',headers:adapter.defineHeaders(),data:formData};return _ember['default'].$.ajax(url,options);}, /**
   * Creates the headers required by API 3.0
   * @returns {{Authorization: string}}
   */defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});