define('gooru-web/routes/reports/study-student-external-collection',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){ /**
 *
 * Analytics data for a student related to a collection of resources
 * Gathers and passes the necessary information to the controller
 *
 * @module
 * @augments ember/Route
 */exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @property {Ember.Service} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {Ember.Service} Service to retrieve a collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @type {UnitService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {ClassService} Service to retrieve class information
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), // -------------------------------------------------------------------------
// Methods
/**
   * @param {{ assessmentId: string, resourceId: string }} params
   */model:function model(params){var route=this;var classId=params.classId;var courseId=params.courseId;var contentType=params.type;return route.get('navigateMapService').getMapLocation(params).then(function(mapLocation){return _ember['default'].RSVP.hash({classPromise:classId?route.get('classService').readClassInfo(classId):_ember['default'].RSVP.resolve({}),course:route.get('courseService').fetchById(courseId),collection:contentType === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_COLLECTION?route.get('collectionService').readExternalCollection(params.collectionId):route.get('assessmentService').readExternalAssessment(params.collectionId)}).then(function(hash){return _ember['default'].RSVP.hash({course:hash.course,collection:hash.collection,'class':hash.classPromise,classId:classId,score:params.score,timespent:params.timespent,isIframeMode:params.isIframeMode,mapLocation:mapLocation});});});},setupController:function setupController(controller,model){this._super.apply(this,arguments);controller.setProperties({course:model.course,collection:model.collection,'class':model['class'],classId:model.classId,score:Number(model.score),timespent:Number(model.timespent),isIframeMode:model.isIframeMode,mapLocation:model.mapLocation});controller.fetchActivityFeedbackCateory();controller.initial();}});});