define('gooru-web/routes/sign-up',['exports','ember','gooru-web/mixins/configuration','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebConfigConfig){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Properties
/**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:authentication
   */authenticationService:_ember['default'].inject.service('api-sdk/authentication'), /**
   * @property {Service} tenant
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), /**
   * @property {Ember.Service} Service to do retrieve countries
   */lookupService:_ember['default'].inject.service('api-sdk/lookup'), // -------------------------------------------------------------------------
// Methods
/**
   * Verfiy the domain have any directions before model get execute.
   */beforeModel:function beforeModel(){if(!this.get('session.isAnonymous')){this.transitionTo('index');}else {var route=this;return route.get('session').authenticateAsAnonymous().then(function(){return {}; // Note removed tenant setting json API
});}},model:function model(){var route=this;return route.get('lookupService').readCountries().then(function(countries){var usCountry=countries.findBy('code',_gooruWebConfigConfig.COUNTRY_CODES.US);var usStates=route.get('lookupService').readStates(usCountry.id);return _ember['default'].RSVP.hash({countries:countries,states:usStates,tenantSettings:route.get('tenantService').getActiveTenantSetting()});});}, /**
   * Set all controller properties used in the template
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){this.handleRedirectionBasedOnDomain(controller);controller.set('askConsentFromUser',this.setAskConsentFromUser(model.tenantSettings));this._super(controller,model);controller.set('countries',model.countries);controller.set('states',model.states);controller.resetProperties();}, /**
   * Verfiy the domain have any directions before model get execute.
   */handleRedirectionBasedOnDomain:function handleRedirectionBasedOnDomain(controller){var endpoint=this.get('redirectEndpoint') || window.location.href;var domainURL=new URL(endpoint);var domain=domainURL.hostname;this.get('authenticationService').domainBasedRedirection(domain).then(function(data){if(data && data.statusCode === 303){window.location.href = data.redirectUrl;}else {controller.set('isRedirectionDomainDone',true);}});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when submitting the sign up form
     * @see application.hbs
     * @see gru-header.hbs
     */signUp:function signUp(){this.transitionTo('sign-up-register');}, /**
     * Action triggered when close sign up form from childLayout
     */closeSignUp:function closeSignUp(){this.transitionTo('index');}}, /**
   * Decide  to show the consent based on the tenant settings
   */setAskConsentFromUser:function setAskConsentFromUser(tenantSettings){return tenantSettings && tenantSettings.ask_consent_from_user === 'on';}});});