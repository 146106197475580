define('gooru-web/serializers/performance',['exports','ember'],function(exports,_ember){ /**
 * Serializer for Performance endpoints
 *
 * @typedef {Object} PerformanceSerializer
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Normalized data of user performance units
   * @return {Object}
   */normalizeUserPerformanceUnits:function normalizeUserPerformanceUnits(response){var resultSet=_ember['default'].A();response = _ember['default'].A(response.units);response.forEach(function(data){var result=_ember['default'].Object.create(data);resultSet.pushObject(result);});response.set('units',resultSet);return response;}, /**
   * Normalized data of user performance lessons
   * @return {Object}
   */normalizeUserPerformanceLessons:function normalizeUserPerformanceLessons(response){var resultSet=_ember['default'].A();response = _ember['default'].A(response.lessons);response.forEach(function(data){var result=_ember['default'].Object.create(data);resultSet.pushObject(result);});return resultSet;}, /**
   * Normalized data of user performance collections
   * @return {Object}
   */normalizeUserPerformanceCollections:function normalizeUserPerformanceCollections(response){var resultSet=_ember['default'].A();response = _ember['default'].A(response.collections);response.forEach(function(data){var result=_ember['default'].Object.create(data);resultSet.pushObject(result);});return resultSet;}, /**
   * Normalized data of user performance resource in  assessments
   * @return {Object}
   */normalizeUserPerformanceResourceInAssessment:function normalizeUserPerformanceResourceInAssessment(response){var resultSet=_ember['default'].A();response = _ember['default'].A(response.resources);response.forEach(function(data){var result=_ember['default'].Object.create(data);resultSet.pushObject(result);});return resultSet;}, /**
   * Normalized data of user performance resource in  collections
   * @return {Object}
   */normalizeUserPerformanceResourceInCollection:function normalizeUserPerformanceResourceInCollection(response){var resultSet=_ember['default'].A();response = _ember['default'].A(response.resources);response.forEach(function(data){var result=_ember['default'].Object.create(data);resultSet.pushObject(result);});return resultSet;}});});