define('gooru-web/components/player/questions/gru-true-false',['exports','ember','gooru-web/components/player/questions/gru-question'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruQuestion){ /**
 * True or false Question
 * Component responsible for controlling the logic and appearance of a true
 * or false question inside of the {@link player/gru-question-viewer.js}
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-true-false'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * When the user changes the answer choice selection
     * @param {number} answerId
     * @param {boolean} onLoad if this was called when loading the component
     */selectAnswerChoice:function selectAnswerChoice(answerId,onLoad){var component=this;var questionUtil=this.get('questionUtil');var correct=questionUtil.isCorrect(answerId);component.notifyAnswerChanged(answerId,correct);if(onLoad){component.notifyAnswerLoaded(answerId,correct);}else {component.notifyAnswerCompleted(answerId,correct);}}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);if(this.get('hasUserAnswer')){this.actions.selectAnswerChoice.call(this,this.get('userAnswer'),true);}}, // -------------------------------------------------------------------------
// Properties
/**
   * Returns the "true" answer id
   */trueAnswerId:_ember['default'].computed('question.answers',function(){var answers=this.get('question.answers');var found=answers.filterBy('text','True');return found?found.get('firstObject.id'):'true'; //TODO, is this a data problem?
}), /**
   * Returns the "false" answer id
   */falseAnswerId:_ember['default'].computed('question.answers',function(){var answers=this.get('question.answers');var found=answers.filterBy('text','False');return found?found.get('firstObject.id'):'true'; //TODO, is this a data problem?
}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});