define('gooru-web/components/content/questions/answers/gru-true-false',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-true-false'], // -------------------------------------------------------------------------
// Actions
actions:{ //Select correct answer
setCorrect:function setCorrect(answer){var correctAnswer=this.get('answers').findBy('isCorrect',true);if(correctAnswer){_ember['default'].set(correctAnswer,'isCorrect',false);}_ember['default'].set(answer,'isCorrect',true);}}, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){if(this.get('answers').length === 0){var options=_ember['default'].A([_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:this.get('i18n').t('common.true').string,isCorrect:true,type:'text'}),_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:this.get('i18n').t('common.false').string,isCorrect:false,type:'text'})]);this.set('answers',options);}}, // -------------------------------------------------------------------------
// Properties
/**
   * True/False Question Answers
   * */answers:null});});