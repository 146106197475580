define('gooru-web/components/reports/pull-up/milestone/student-milestone-performance-report',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['milestone-report','student-milestone-performance-report'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {performanceService} Service to retrieve performance information
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), /**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {rescopeService} Service to retrieve rescope information
   */rescopeService:_ember['default'].inject.service('api-sdk/rescope'), /**
   * @type {session} Service to retrieve rescope information
   */session:_ember['default'].inject.service('session'), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'), /**
   * route0 Service to perform route0 data operations
   */route0Service:_ember['default'].inject.service('api-sdk/route0'), /**
   * Help to fetch unit0 content
   */unit0Service:_ember['default'].inject.service('api-sdk/unit0'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadMilestonesPerformanceData();component.setGradeSubject();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on a milestone
onSelectMilestone:function onSelectMilestone(milestone,milestoneIndex){var component=this;if(!component.isDestroyed){component.set('selectedMilestone',milestone);component.set('activeMilestoneIndex',milestoneIndex);component.set('isShowMilestoneReport',true);}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} classId
   */classId:_ember['default'].computed.alias('class.id'), /**
   * @property {UUID} courseId
   */courseId:_ember['default'].computed.alias('class.courseId'), /**
   * @property {UUID} userId
   */userId:_ember['default'].computed(function(){var component=this;return component.get('session.userId');}), /**
   * @property {String} frameworkCode
   */frameworkCode:_ember['default'].computed.alias('class.preference.framework'), /**
   * @property {String} subject
   */subject:_ember['default'].computed.alias('class.preference.subject'), /**
   * @property {Array} milestones
   */milestones:_ember['default'].A([]), /**
   * @property {Number} courseScoreInPercentage
   */courseScoreInPercentage:_ember['default'].computed.alias('class.performanceSummary.score'), /**
   * @property {Object} rescopedContents
   */rescopedContents:null, /**
   * @property {Boolean} isStudent
   */isStudent:_ember['default'].computed.equal('session.role',_gooruWebConfigConfig.ROLES.STUDENT), // -------------------------------------------------------------------------
// Methods
/**
   * @function loadMilestonesPerformanceData
   * Method to load milestones performance data
   */loadMilestonesPerformanceData:function loadMilestonesPerformanceData(){var component=this;return _ember['default'].RSVP.hash({milestones:component.fetchMilestones(),route0Milestone:component.fetchMilestonesRoute0(),milestonesPerformanceScore:component.fetchMilestonesPerformanceScore(),milestonesPerformanceTimespent:component.fetchMilestonesPerformanceTimespent(),grades:component.fetchGradesBySubject(),unit0Content:component.fetchUnit0Content()}).then(function(hash){if(!component.isDestroyed){var milestones=_ember['default'].A([]).concat(hash.unit0Content,hash.route0Milestone?hash.route0Milestone.milestones:_ember['default'].A([]),hash.milestones);milestones = component.filterOutMilestonesBasedOnGrade(hash.grades,milestones);component.parseMilestonesPerformance(milestones,hash.milestonesPerformanceScore,hash.milestonesPerformanceTimespent);}});}, /**
   * @function fetchMilestones
   * Method to fetch milestones
   */fetchMilestones:function fetchMilestones(){var component=this;var courseService=component.get('courseService');var courseId=component.get('courseId');var frameworkCode=component.get('frameworkCode');return courseService.getCourseMilestones(courseId,frameworkCode);},fetchMilestonesRoute0:function fetchMilestonesRoute0(){var component=this;var route0Applicable=component.get('class.route0Applicable');var route0Service=component.get('route0Service');var classId=component.get('classId');var courseId=component.get('courseId');var userId=component.get('userId');var isStudent=component.get('isStudent');var filter={classId:classId,courseId:courseId};if(!isStudent){filter.userId = userId;return route0Applicable?route0Service.fetchInClassByTeacher(filter):null;}return route0Applicable?route0Service.fetchInClass(filter):null;},fetchUnit0Content:function fetchUnit0Content(){var component=this;var unit0Service=component.get('unit0Service');var classId=component.get('classId');var courseId=component.get('courseId');return unit0Service.fetchUnit0({classId:classId,courseId:courseId});}, /**
   * @function fetchMilestonesPerformanceScore
   * Method to fetch milestones performance score
   */fetchMilestonesPerformanceScore:function fetchMilestonesPerformanceScore(){var component=this;var performanceService=component.get('performanceService');var classId=component.get('classId');var courseId=component.get('courseId');var userId=component.get('userId');var frameworkCode=component.get('frameworkCode');return performanceService.getPerformanceForMilestones(classId,courseId,'assessment',userId,frameworkCode);}, /**
   * @function fetchMilestonesPerformanceTimespent
   * Method to fetch milestones performance timespent
   */fetchMilestonesPerformanceTimespent:function fetchMilestonesPerformanceTimespent(){var component=this;var performanceService=component.get('performanceService');var classId=component.get('classId');var courseId=component.get('courseId');var userId=component.get('userId');var frameworkCode=component.get('frameworkCode');return performanceService.getPerformanceForMilestones(classId,courseId,'collection',userId,frameworkCode);}, /**
   * @function fetchGradesBySubject
   * Method to fetch grades by subject code
   */fetchGradesBySubject:function fetchGradesBySubject(){var component=this;var filters={subject:component.get('subject')};var fwkCode=component.get('frameworkCode');if(fwkCode){filters.fw_code = fwkCode;}return component.get('taxonomyService').fetchGradesBySubject(filters);}, /**
   * @function setGradeSubject
   * Method to set grade subject object
   */setGradeSubject:function setGradeSubject(){var component=this;var subject=component.get('subject');return _ember['default'].RSVP.hash({gradeSubject:subject?component.get('taxonomyService').fetchSubject(subject):{}}).then(function(_ref){var gradeSubject=_ref.gradeSubject;component.set('gradeSubject',gradeSubject);});}, /**
   * @function parseMilestonesPerformance
   * Method to parse milestones perforamance data
   */parseMilestonesPerformance:function parseMilestonesPerformance(milestones,milestonesPerformanceScore,milestonesPerformanceTimespent){var component=this;milestones.map(function(milestone){var milestonePerformanceScore=milestonesPerformanceScore.findBy('milestoneId',milestone.get('milestone_id'));if(milestonePerformanceScore){milestone.set('performance',milestonePerformanceScore.get('performance'));}var milestonePerformanceTimespent=milestonesPerformanceTimespent.findBy('milestoneId',milestone.get('milestone_id'));if(milestonePerformanceTimespent){if(milestone.get('performance')){milestone.set('performance.timeSpent',milestonePerformanceTimespent.get('performance.timeSpent'));}else {milestone.set('performance',_ember['default'].Object.create({timeSpent:milestonePerformanceTimespent.get('performance.timeSpent')}));}}});if(!component.isDestroyed){component.set('milestones',milestones);}}, /**
   * @function filterOutMilestonesBasedOnGrade
   * Method to filter oute milestones based on the grade boundaries
   */filterOutMilestonesBasedOnGrade:function filterOutMilestonesBasedOnGrade(grades,milestones){var component=this;var gradeBounds=component.get('class.memberGradeBounds');var userId=component.get('userId');var gradeBound=gradeBounds.findBy(userId);var milestoneData=_ember['default'].A([]);var studentGradeBound=_ember['default'].Object.create(gradeBound.get(userId));var classGradeId=component.get('class.gradeCurrent');component.set('studentGradeBound',studentGradeBound);component.set('grades',grades);var gradeLowerBound=studentGradeBound.get('grade_lower_bound');var gradeUpperBound=studentGradeBound.get('grade_upper_bound');var startGrade=grades.findBy('id',gradeLowerBound);var startGradeIndex=grades.indexOf(startGrade);var endGrade=grades.findBy('id',gradeUpperBound);var endGradeIndex=grades.indexOf(endGrade);var studentGrades=grades.slice(startGradeIndex,endGradeIndex + 1);milestones.forEach(function(milestone,index){if(milestone){var gradeId=milestone.get('grade_id');var grade=studentGrades.findBy('id',gradeId);if(grade || milestone.isRoute0 || milestone.isUnit0){if(gradeId === classGradeId){milestone.set('isClassGrade',true);}milestone.set('milestoneIndex',index + 1);milestoneData.pushObject(milestone);}}});return milestoneData;}});});