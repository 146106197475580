define('gooru-web/controllers/login',['exports','ember','gooru-web/models/login','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebModelsLogin,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
queryParams:['redirectURL'], /**
   * @property {Service} Session
   */session:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Session service
   */signInService:_ember['default'].inject.service('sign-in'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click on submit button
     */getUserTenantAccount:function getUserTenantAccount(){var controller=this;var user=controller.get('user');if(controller.get('didValidate') === false){var email=_ember['default'].$('.gru-input.email input').val();user.set('email',email);}user.validate().then(function(_ref){var validations=_ref.validations;controller.set('isShowErrormessage',false);if(validations.get('isValid')){controller.get('signInService').getUserTenantAccounts(user).then(function(tenantList){controller.set('tenantLists',tenantList);controller.set('didValidate',true);if(tenantList.length > 1){controller.set('isShowUserTenantList',true);}else {controller.send('tenantLogin',tenantList.objectAt(0));}},function(error){var statusCode=error.status;if(statusCode === 404){controller.set('isShowErrormessage',true);}});}});}, /**
     * Action triggered when click on back arrow
     */goBack:function goBack(){this.set('isShowUserTenantList',false);},tenantLogin:function tenantLogin(tenant){var controller=this;var tenantUrl=controller.get('configuration.TENANT_URL');var userEmail=controller.get('user.email');var requestTenantUrl=tenantUrl + '/' + tenant.tenantShortName;if(tenant.tenantShortName === _gooruWebConfigConfig.GOORU_SHORT_NAME){var queryParam={queryParams:{userEmail:userEmail}};if(controller.get('redirectURL')){queryParam.queryParams.redirectURL = controller.get('redirectURL');}controller.transitionToRoute('sign-in',queryParam);}else {var encodedEmail=window.btoa(userEmail);window.localStorage.setItem('userEmail',encodedEmail);window.location.href = requestTenantUrl + '?lt=' + tenant.loginType;}},singUp:function singUp(){var userEmail=this.get('user.email');var queryParam={queryParams:{userEmail:userEmail}};this.transitionToRoute('sign-up',queryParam);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {User} user
   */user:null, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false, /**
   * @param {Boolean } isShowUserTenantList
   */isShowUserTenantList:false, /**
   * @param {Boolean } isShowErrorMessage
   */isShowErrorMessage:false,tabIndex:0, // -------------------------------------------------------------------------
// Methods
/**
   * init and reset all the properties for the validations
   */resetProperties:function resetProperties(){var controller=this;var user=_gooruWebModelsLogin['default'].create(_ember['default'].getOwner(this).ownerInjection(),{email:null});controller.set('user',user);controller.set('didValidate',false);controller.set('isShowUserTenantList',false);controller.set('isShowErrorMessage',false);}});});