define('gooru-web/components/player/questions/gru-say-out-loud',['exports','gooru-web/components/player/questions/gru-question','ember'],function(exports,_gooruWebComponentsPlayerQuestionsGruQuestion,_ember){ /**
 * Fill in the blank
 *
 * Component responsible for controlling the logic and appearance of a fill in the blank
 * question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-say-out-loud'],isShowPlay:false,showCorrect:false, /**
   * @property {Array} answers
   * List of answers
   */answers:_ember['default'].computed.alias('question.answers'), // -------------------------------------------------------------------------
// Actions
initInputEvents:(function(){var _this=this;if(this.get('baseQuestion')){this.set('isShowPlay',true);var decodingExcercies=this.get('baseQuestion.answers').map(function(baseAnswer,index){return {answer_text:baseAnswer.get('text'),correct_answer:baseAnswer.get('correctAnswer'),answer_seq:index + 1};});this.injectDecoding(decodingExcercies);}var _loop=function(index){var element=_this.get('answers')[index];if(element.correctAnswer){element.correctAnswer.map(function(data){_this.hightLightDefaultWord(data,index);return data;});}};for(var index=0;index < this.get('answers').length;index++) {_loop(index);}}).on('didInsertElement'), // -------------------------------------------------------------------------
// Properties
userId:_ember['default'].computed.alias('session.userId'), // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
injectDecoding:function injectDecoding(decodingExcercies){var user={userId:this.get('userId')};var accessibilitySettings=JSON.parse(window.localStorage.getItem('accessibility_settings'));var content={contentId:this.get('baseQuestion.id'),contentTitle:this.get('baseQuestion.title'),answer_type:'say_out_loud',answers:decodingExcercies,isHighContrast:accessibilitySettings && accessibilitySettings.is_high_contrast_enabled?accessibilitySettings.is_high_contrast_enabled:false};window.serp.languageDecode().select('#serp-say-out-loud-answer-container').dataIn(user,null,content).decoding().render().listener(function(){return;});},hightLightDefaultWord:function hightLightDefaultWord(text,answerIndex){var component=this;var innerHTML='';var start=text.split(':')[1];var end=text.split(':')[2];var fulltext=text.split(':')[0];var html='<span class="correct">' + fulltext + '</span>';String.prototype.replaceBetween = function(start,end,what){return this.substring(0,start) + what + this.substring(end);};if(component.$('.answer-sayout .answer-edit-' + answerIndex)[0]){component.$('.answer-sayout .answer-edit-' + answerIndex)[0].childNodes.forEach(function(childNode){if(childNode.data && childNode.data.substring(start,end) === fulltext){innerHTML = innerHTML + childNode.data.replaceBetween(start,end,$.trim(html));}else if(childNode.data){innerHTML = innerHTML + childNode.data;}else {innerHTML = innerHTML + childNode.outerHTML;}});component.$('.answer-sayout .answer-edit-' + answerIndex).html(innerHTML);}}});});