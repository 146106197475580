define('gooru-web/serializers/content/bookmark',['exports','ember','gooru-web/mixins/configuration','gooru-web/models/content/bookmark'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebModelsContentBookmark){ /**
 * Serializer to support the Bookmark CRUD operations for API 3.0
 *
 * @typedef {Object} BookmarkSerializer
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{session:_ember['default'].inject.service('session'), /**
   * Serialize a Bookmark object into a JSON representation required by the Create Bookmark endpoint
   *
   * @param bookmarkModel The Bookmark model to be serialized
   * @returns {Object} returns a JSON Object
   */serializeCreateBookmark:function serializeCreateBookmark(bookmarkModel){return this.serializeBookmark(bookmarkModel);},serializeBookmark:function serializeBookmark(bookmarkModel){var serializedBookmark={title:bookmarkModel.get('title'),content_id:bookmarkModel.get('contentId'),content_type:bookmarkModel.get('contentType')};return serializedBookmark;}, /**
   * Normalize the Fetch Bookmarks endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Bookmarks[]} an array of bookmarks
   */normalizeFetchBookmarks:function normalizeFetchBookmarks(payload){var result=[];var serializer=this;var bookmarks=payload.bookmarks;if(_ember['default'].isArray(bookmarks)){result = bookmarks.map(function(bookmark){return serializer.normalizeBookmark(bookmark);});}return result;},normalizeBookmark:function normalizeBookmark(bookmarkPayload){var serializer=this;return _gooruWebModelsContentBookmark['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{id:bookmarkPayload.id,title:bookmarkPayload.title,contentId:bookmarkPayload.content_id,contentType:bookmarkPayload.content_type});}});});