define('gooru-web/components/player/scorm-player',['exports','ember','gooru-web/config/config','gooru-web/utils/utils'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['scorm-player'], // -------------------------------------------------------------------------
// Dependencies
analyticsService:_ember['default'].inject.service('api-sdk/analytics'), /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
/**
   * Observe the assessment change
   */collectionObserver:_ember['default'].observer('collection',function(){var component=this;component.resetProperties();}), /**
   * Observe the assessment change
   */collectionUrl:_ember['default'].computed('collection',function(){var collection=this.get('collection');var cdnUrl=this.get('session.cdnUrls.content');var url=collection.url.replace(cdnUrl,window.location.origin + '/');return url;}),didRender:function didRender(){var component=this;component.timeValidator();},didInsertElement:function didInsertElement(){var component=this;this.injectScript(_gooruWebConfigConfig.SCORM_PATH);component.$('[data-toggle="tooltip"]').tooltip();$(document).on('visibilitychange',function(){component.sendAction('onVisibilityChange');});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click submit
     */onSubmit:function onSubmit(){var component=this;component.set('isTimeEntered',true);component.updateSelfReport();}, /**
     * Action triggered when click cancel
     */onCancel:function onCancel(){var component=this;var isIframeMode=component.get('isIframeMode');if(isIframeMode){component.sendAction('onClosePlayer');}else {component.redirectTo();}},closePlayer:function closePlayer(){var component=this;$(document).off('visibilitychange');component.set('isCompleted',true);var totalScore=component.get('score');component.sendAction('onStopPlayContentEvent',totalScore?totalScore:0);}}, /**
   * Action triggered when initial load
   */onStart:function onStart(){var component=this;component.set('isStarted',true);component.set('isDisableTimeEditor',false);component.sendAction('onStartContentPlayed');},timeValidator:function timeValidator(){var component=this;component.$('.time').keyup(function(){var hours=component.get('hours');var mins=component.get('mins');component.set('isValidtime',(0,_gooruWebUtilsUtils.validateTimespent)(hours,mins));component.set('isTyping',true);});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isDisableTimeEditor
   */isDisableTimeEditor:true, /**
   * @property {Boolean} isTimeEntered
   */isTimeEntered:false, /**
   * @property {Boolean} isValidScore
   */isValidtime:null, /**
   * @property {Boolean} isStarted
   */isStarted:null, /**
   * @property {String} time
   */time:'',isCompleted:false,score:0,isShowFooter:false, /**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}), /**
   * @property {String} contentType
   */contentType:'collection-external', // -------------------------------------------------------------------------
// Methods
/**
   * @function getDataParams
   * Method to get structured data params which needs to be pass with post API
   */getDataParams:function getDataParams(){var component=this;var hours=component.get('hours');var mins=component.get('mins');var mapLocation=component.get('mapLocation');var context=mapLocation.get('context');var userId=component.get('session.userId');var dataParams={user_id:userId,class_id:context.get('classId') || null,course_id:context.get('courseId') || null,unit_id:context.get('unitId') || null,lesson_id:context.get('lessonId') || null,collection_type:'collection-external',external_collection_id:context.get('collectionId'),collection_id:context.get('collectionId'),session_id:(0,_gooruWebUtilsUtils.generateUUID)(),partner_id:component.get('session.partnerId') || null,tenant_id:component.get('session.tenantId') || null,content_source:component.get('source') || null,path_id:context.get('pathId') || 0,path_type:context.get('pathType') || null,time_spent:component.roundMilliseconds(hours,mins),time_zone:component.get('timeZone'),evidence:[{TBD:'True'}]};return dataParams;}, /**
   * @function updateSelfReport
   * Method to update score of an external assessment
   */updateSelfReport:function updateSelfReport(){var component=this;var analyticsService=component.get('analyticsService');var dataParams=component.getDataParams();component.set('timeSpent',dataParams.time_spent);var selfReportedPromise=analyticsService.studentSelfReporting(dataParams);component.set('time','');_ember['default'].RSVP.hash({selfReport:selfReportedPromise}).then(function(){component.set('time',component.getEnteredTime());})['catch'](function(){component.set('time',null);});}, /**
   * @function roundMilliseconds
   * Method to round milliseconds
   */roundMilliseconds:function roundMilliseconds(){var hour=arguments.length <= 0 || arguments[0] === undefined?0:arguments[0];var mins=arguments.length <= 1 || arguments[1] === undefined?0:arguments[1];var timeSpentInMilliSec=(hour * 60 * 60 + mins * 60) * 1000;return timeSpentInMilliSec;}, /**
   * @function getEnteredTime
   * Method to get entered score after update
   */getEnteredTime:function getEnteredTime(){var component=this;var isStarted=component.get('isStarted');var time=undefined;if(isStarted){var hours=component.get('hours') || 0;var mins=component.get('mins') || 0;time = hours + ' h ' + mins + ' m';}return time;}, /**
   * @function resetProperties
   * Method to reset component Properties
   */resetProperties:function resetProperties(){var component=this;this._super.apply(this,arguments);component.set('time','');component.set('isTimeEntered',false);component.set('isStarted',false);component.set('isDisableTimeEditor',true);component.set('isValidtime',false);component.set('isShowActivityFeedback',false);}, /**
   * Redirect to right path
   */redirectTo:function redirectTo(){var component=this;var context=component.get('mapLocation.context');var source=component.get('source');if(context.get('classId') && source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP){component.get('router').transitionTo('student.class.course-map',context.get('classId'),{queryParams:{refresh:true}});}else if(context.get('classId') && source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS){component.get('router').transitionTo('student.class.class-activities',context.get('classId'));}else {component.get('router').transitionTo('student.independent.course-map',context.get('courseId'),{queryParams:{refresh:true}});}},initialize:function initialize(){var component=this;var contexts=component.get('mapLocation.context'); //eslint-disable-next-line
window.API.apiLogLevel = 1;window.API_1484_11.apiLogLevel = 1;window.API_1484_11.on('Initialize',function(){component.onStart();}); //eslint-disable-next-line
window.API_1484_11.on('SetValue',function(eventName,eventData){if(eventName === 'cmi.score.raw'){component.set('score',eventData);}});window.API_1484_11.on('Terminate',function(){$(document).off('visibilitychange');component.set('isCompleted',true);var totalScore=component.get('score');component.sendAction('onStopPlayContentEvent',totalScore?totalScore:0);});window.API.on('LMSInitialize',function(){component.onStart();});window.API.on('LMSFinish',function(){$(document).off('visibilitychange');component.set('isCompleted',true);var totalScore=component.get('score');component.sendAction('onStopPlayContentEvent',totalScore?totalScore:0);}); //eslint-disable-next-line
window.API.LMSSetValue = function(eventName,eventData){if(eventName === 'cmi.suspend_data' && eventData !== ''){var context={classId:contexts.get('classId') || null,courseId:contexts.get('courseId') || null,eventData:eventData};component.get('parseEventService').postParseEvent(eventName,context);}if(eventName === 'cmi.core.score.raw'){component.set('score',eventData);}};}, /**
   * @function injectScript
   * @param {String} toolScriptPath
   * @param {Number} version
   * @return {Object} scriptElement
   * Method to inject script into dom
   */injectScript:function injectScript(toolScriptPath){var component=this;var script=document.createElement('script');script.type = 'text/javascript';script.src = '' + (window.location.origin + toolScriptPath);document.getElementsByTagName('head')[0].appendChild(script);script.addEventListener('load',function(){component.initialize();});}});});