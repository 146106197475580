define('gooru-web/components/charts/student-domain-performance',['exports','ember','d3'],function(exports,_ember,_d3){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['student-domain-performance'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;var competencyDataSet=component.get('competencies');component.drawChart(competencyDataSet.sortBy('competencyStatus').reverse());}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {Number}
   * Property to hold cell width
   */cellHeight:25, /**
   * @type {Number}
   * Property to hold cell height
   */cellWidth:_ember['default'].computed('competencies',function(){var component=this;var numberOfCompetencies=component.get('competencies.length');var maxChartWidth=component.get('maxChartWidth');return maxChartWidth / numberOfCompetencies;}), /**
   * @type {Number}
   * Property to hold max chart height
   */maxChartWidth:126, // -------------------------------------------------------------------------
// Methods
/**
   * @function drawChart
   * Method to draw individual domain competency chart
   */drawChart:function drawChart(competencyDataSet){var component=this;var cellWidth=component.get('cellWidth');var cellHeight=component.get('cellHeight');var numberOfCompetencies=competencyDataSet.length;var domainSeq=component.get('domainSeq');var studentSeq=component.get('studentSeq');var competencySeq=0;var svgContainer=_d3['default'].select('.chart.render-student-domain-performance-' + studentSeq + '-' + domainSeq).append('svg').attr('width',cellWidth * numberOfCompetencies).attr('height',cellHeight);var cellContainer=svgContainer.append('g').attr('id','cell-container');var cells=cellContainer.selectAll('.competency').data(competencyDataSet);cells.enter().append('rect').attr('class',function(d){return 'competency-' + d.competencySeq + ' fill-' + d.competencyStatus;}).attr('id','competency-cell').attr('width',cellWidth).attr('height',cellHeight).attr('x',function(){competencySeq++;return (competencySeq - 1) * cellWidth;}).attr('y',0);cells.exit().remove();}});});