define('gooru-web/models/result/learning-target',['exports','ember'],function(exports,_ember){ /**
 * Model for a learning target result.
 *
 * @typedef {Object} LearningTargetResult
 *
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {String} id - The standard|learningTarget ID
   */id:null, /**
   * @property {String} description - Description of the learning target
   */description:'', /**
   * @property {Number} mastery - Score/mastery for the learning target
   */mastery:0, /*
   * @property {Number[]} relatedQuestions - Array of QuestionResult ids
   */relatedQuestions:[], /**
   * @property {String} standard - Name/code of the standard this learning target belongs to
   */standard:'', /*
   * @property {ResourceResult[]} suggestedResources
   */suggestedResources:[]});});