define('gooru-web/routes/teacher/class',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],_gooruWebMixinsUiHelperMixin['default'],{queryParams:{refresh:{refreshModel:true},backUrls:{}}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {ClassService} Service to retrieve class information
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @type {PerformanceService} Service to retrieve class performance summary
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), /**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {UnitService} Service to retrieve unit information
   */unitService:_ember['default'].inject.service('api-sdk/unit'), /**
   * @type {i18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/competency
   */competencyService:_ember['default'].inject.service('api-sdk/competency'), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('taxonomy'),multipleClassService:_ember['default'].inject.service('api-sdk/multiple-class'),session:_ember['default'].inject.service('session'),beforeModel:function beforeModel(){if(this.get('session.role') === 'teacher'){return;}else {this.transitionTo('student-home');}}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Triggered when a class menu item is selected
     * @param {string} item
     */selectMenuItem:function selectMenuItem(item){var route=this;var controller=route.get('controller');var currentItem=controller.get('menuItem');var isPremiumClass=controller.get('isPremiumClass');if(item !== currentItem){controller.selectMenuItem(item);if(item === 'class-management'){route.transitionTo('teacher.class.class-management');}else if(item === 'students'){route.transitionTo('teacher.class.students-proficiency');}else if(item === 'course-map'){route.transitionTo('teacher.class.course-map');}else if(item === 'performance' && !isPremiumClass){route.transitionTo('teacher.class.performance');}else if(item === 'class-activities'){route.transitionTo('teacher.class.class-activities');}else if(item === 'atc'){route.transitionTo('teacher.class.atc');}else if(item === 'close'){var backurl=this.get('backUrls');backurl = backurl || controller.get('backUrls');if(backurl){route.transitionTo(backurl);}else {if(controller['class'].isArchived){route.transitionTo('teacher-home'); //, (query - params showArchivedClasses = "true" showActiveClasses = "false") class="back-to" } }
}else {route.transitionTo('teacher-home');}}route.setTitle('Instructor Classroom',null);}}}, /**
     * Gets a refreshed list of content visible
     */updateContentVisible:function updateContentVisible(contentId,visible){var route=this;var controller=route.get('controller');var contentVisibility=controller.get('contentVisibility');contentVisibility.setAssessmentVisibility(contentId,visible?'on':'off');},onRefreshData:function onRefreshData(){this.refresh();}}, // -------------------------------------------------------------------------
// Methods
/**
   * Get model for the controller
   */model:function model(params){var route=this;var classId=params.classId;var classPromise=route.get('classService').readClassInfo(classId);var membersPromise=route.get('classService').readClassMembers(classId);return _ember['default'].RSVP.hash({'class':classPromise,members:membersPromise}).then(function(hash){var aClass=hash['class'];var members=hash.members;var courseId=aClass.get('courseId');var coursePromise=_ember['default'].RSVP.resolve(_ember['default'].Object.create({}));if(courseId){coursePromise = route.get('courseService').fetchById(courseId);}return _ember['default'].RSVP.hash({course:coursePromise,secondaryClassesData:route.loadClassesData(aClass.get('setting'))}).then(function(hash){var contentVisibility=hash.contentVisibility;var course=hash.course;var crossWalkFWC=hash.crossWalkFWC || [];aClass.set('owner',members.get('owner'));aClass.set('collaborators',members.get('collaborators'));aClass.set('memberGradeBounds',members.get('memberGradeBounds'));aClass.set('members',members.get('members'));return {'class':aClass,course:course,members:members,contentVisibility:contentVisibility,crossWalkFWC:crossWalkFWC,secondaryClassesData:hash.secondaryClassesData};});});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){controller.set('class',model['class']);controller.set('course',model.course);controller.set('members',model.members);controller.set('secondaryClassesData',model.secondaryClassesData);controller.set('class.course',model.course);controller.loadCompetencyCompletionStat();}, /**
   * @function loadClassesData
   * Method to load class details for given classIds
   */loadClassesData:function loadClassesData(classSetting){var secondaryClassIds=classSetting && classSetting['secondary.classes']?classSetting['secondary.classes'].list:_ember['default'].A([]);if(secondaryClassIds && secondaryClassIds.length){return this.get('classService').readBulkClassDetails(secondaryClassIds);}return _ember['default'].RSVP.resolve(_ember['default'].A([]));},resetController:function resetController(controller){controller.set('pullUpSecondaryClass',null);controller.set('isShowMilestoneReport',false);controller.set('selectedSecondaryClass',null);}});});