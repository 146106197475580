define('gooru-web/serializers/performance/activity-performance-summary',['exports','ember','gooru-web/models/performance/activity-performance-summary','gooru-web/utils/utils','gooru-web/serializers/performance/collection-performance-summary'],function(exports,_ember,_gooruWebModelsPerformanceActivityPerformanceSummary,_gooruWebUtilsUtils,_gooruWebSerializersPerformanceCollectionPerformanceSummary){ /**
 * Serializer to support the CollectionPerformanceSummary CRUD operations
 *
 * @typedef {Object} CollectionPerformanceSummary
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {CollectionPerformanceSummarySerializer}
   */collectionPerformanceSummarySerializer:null, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('collectionPerformanceSummarySerializer',_gooruWebSerializersPerformanceCollectionPerformanceSummary['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Normalize an array of ActivityPerformanceSummary
   *
   * @param payload endpoint response format in JSON format
   * @returns {ActivityPerformanceSummary[]}
   */normalizeAllActivityPerformanceSummary:function normalizeAllActivityPerformanceSummary(payload){var serializer=this;var activityPerformanceSummaryItems=_ember['default'].A([]);if(payload && _ember['default'].isArray(payload.usageData)){payload.usageData.forEach(function(activityPerformanceSummaryData){var userId=activityPerformanceSummaryData.userId; //process the data for each user
var activitiesData=activityPerformanceSummaryData.activity || [];activitiesData.forEach(function(activityData){var activityPerformanceSummary=serializer.normalizeActivityPerformanceSummary(userId,activityData);activityPerformanceSummaryItems.pushObject(activityPerformanceSummary);});});}return activityPerformanceSummaryItems;}, /**
   * Normalize a ActivityPerformanceSummary
   * @param {*} data
   * @return {ActivityPerformanceSummary}
   */normalizeActivityPerformanceSummary:function normalizeActivityPerformanceSummary(userId,data){var serializer=this;return _gooruWebModelsPerformanceActivityPerformanceSummary['default'].create({userId:userId,date:data.date?(0,_gooruWebUtilsUtils.parseDate)(data.date,'YYYY-MM-DD'):null,activation_date:data.date?data.date:null,dcaContentId:data.dcaContentId || null,collectionPerformanceSummary:serializer.get('collectionPerformanceSummarySerializer').normalizeCollectionPerformanceSummary(data)});}});});