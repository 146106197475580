define('gooru-web/routes/grading-player',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/models/rubric/rubric-grade'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebModelsRubricRubricGrade){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {RubricService} Service to retrieve rubric information
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @type {ProfileService} Service to retrieve question information
   */questionService:_ember['default'].inject.service('api-sdk/question'), /**
   * @type {Session} Session information
   */session:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Navigate to the previous route
     */navigateBack:function navigateBack(){var route=!this.get('history.lastRoute.name')?'index':this.get('history.lastRoute.url');this.transitionTo(route);}}, // -------------------------------------------------------------------------
// Methods
/**
   * Get model for the controller
   */model:function model(params){var _this=this;var classId=params.classId;var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var collectionId=params.collectionId;var questionId=params.questionId;return _ember['default'].RSVP.hash({question:this.get('questionService').readQuestion(questionId),users:this.get('rubricService').getStudentsForQuestion(questionId,classId,courseId,collectionId)}).then(function(_ref){var users=_ref.users;var question=_ref.question;if(users.get('students') && users.get('students').length){var studentId=users.get('students.firstObject');return _ember['default'].RSVP.hash({answer:_this.get('rubricService').getAnswerToGrade(studentId,classId,courseId,collectionId,questionId,unitId,lessonId),question:question,rubric:question.get('rubric.id')?_this.get('rubricService').getRubric(question.get('rubric.id')):null,userIds:users.get('students'),users:_this.get('profileService').readMultipleProfiles(users.get('students')).then(function(profiles){return profiles.map(function(student){return _ember['default'].Object.create({id:student.get('id'),name:student.get('fullNameInformal'),checked:false});});}),currentUserId:studentId,classId:classId,questionId:questionId,courseId:courseId,collectionId:collectionId,unitId:unitId,lessonId:lessonId});}});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){var _this2=this;controller.set('questionText',model.question.get('description') || model.question.get('title'));controller.set('questionThumbnail',model.question.get('thumbnail'));controller.set('classId',model.classId);controller.set('rubric',model.rubric);controller.set('users',model.userIds.map(function(userId){return model.users.findBy('id',userId);}));var userMappings=model.users.reduce(function(mappings,user){mappings[user.id] = {user:user,answer:model.currentUserId === user.id?model.answer:null,grade:_gooruWebModelsRubricRubricGrade['default'].create(_ember['default'].getOwner(_this2).ownerInjection(),model.rubric,{comment:'',studentId:user.id,classId:model.classId,courseId:model.courseId,unitId:model.unitId,lessonId:model.lessonId,collectionId:model.collectionId,resourceId:model.questionId,sessionId:model.currentUserId === user.id?model.answer.get('sessionId'):null,createdDate:new Date(),rubricCreatedDate:model.rubric?model.rubric.get('createdDate'):null,rubricUpdatedDate:model.rubric?model.rubric.get('updatedDate'):null})};return mappings;},{});controller.set('currentUserId',model.currentUserId);controller.set('userMappings',_ember['default'].Object.create(userMappings));}});});