define('gooru-web/components/gru-user-registration',['exports','ember','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebMixinsModal){ /**
 * User registration
 *
 * Component that routes the user either to the log in flow or the sign up flow.
 * May be embedded into a modal window (@see gru-modal).
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-user-registration'],classNameBindings:['component-class'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.$('[data-toggle=\'tooltip\']').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {?String} specific class
   */'component-class':null});});