define('gooru-web/components/student/class/milestone-course-map-route0-preview',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['student-class-milestone-course-map-route0-preview'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:session
   */session:_ember['default'].inject.service(), /**
   * route0 Service to perform route0 data operations
   */route0Service:_ember['default'].inject.service('api-sdk/route0'), // -------------------------------------------------------------------------
// Properties
/**
   * Maintains the state of data loading
   * @type {Boolean}
   */isLoading:false, /**
   * Class Id extract from class object
   * @type {String}
   */classId:_ember['default'].computed.alias('class.id'), /**
   * Course Id which is mapped to this class.
   * @type {String}
   */courseId:_ember['default'].computed.alias('class.courseId'), /**
   * Maintains the state of route0 preview expand or collapse
   * @type {Boolean}
   */isRoute0PreviewExpand:false, /**
   * Maintains the state whether we need to show chart or not.
   * @type {Boolean}
   */showChart:true, // -------------------------------------------------------------------------
// Actions
actions:{updateRoute0:function updateRoute0(status){this.updateRoute0(status);},showRoute0Preview:function showRoute0Preview(){var element='.route0-preview-container';var component=this;if(component.get('isRoute0PreviewExpand')){component.$(element).slideUp(400,function(){component.set('isRoute0PreviewExpand',false);});}else {component.$(element).slideDown(400,function(){component.set('isRoute0PreviewExpand',true);});}}}, // -------------------------------------------------------------------------
// Methods
updateRoute0:function updateRoute0(status){var component=this;var route0=component.get('route0');route0.set('status',status);}});});