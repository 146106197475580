define('gooru-web/components/reports/assessment/questions/gru-syllables-division',['exports','ember','quizzes-addon/mixins/reports/assessment/questions/question'],function(exports,_ember,_quizzesAddonMixinsReportsAssessmentQuestionsQuestion){ /**
 * SERP Underline
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-syllables-division'], // -------------------------------------------------------------------------
// Properties
didInsertElement:function didInsertElement(){var _this2=this;if(this.get('baseAnswers') && this.get('baseAnswers').length){this.get('baseAnswers').forEach(function(element,answerIndex){_this2.$('.syllables-division-edit-' + answerIndex).html(_this2.wrapLetters(element.text));var elementItem=!_this2.get('showCorrect')?_this2.get('answerObj')[answerIndex]:element;var correctAnswer=!_this2.get('showCorrect')?JSON.parse(elementItem.text):element.correctAnswer;for(var index=0;index < correctAnswer.length;index++) {var elements=correctAnswer[index];_this2.hightLightDefaultWord(elements,answerIndex);}});}},showCorrect:false,baseAnswers:_ember['default'].computed('question',function(){return this.get('question.answers');}), // ---------------------------------------------------------------------
// Methods
hightLightDefaultWord:function hightLightDefaultWord(text,answerIndex){var component=this;var start=text.start;var end=text.end;var parentEl=component.$('.syllables-division-edit-' + answerIndex);parentEl.find('b[data-index=' + start + '], b[data-index =' + end + ']').wrapAll('<span class="serp-hl-text-span"></span>');if(text.selectedIndex.length){text.selectedIndex.forEach(function(sIndex){parentEl.find('b[data-index=' + sIndex + ']').addClass('selected');});}component.arrowLine(parentEl[0]);},wrapLetters:function wrapLetters(value){var text='';if(value && value.length){for(var i=0;i < value.length;i++) {text += '<b data-index=' + i + '>' + value[i] + '</b>';}}return text;},arrowLine:function arrowLine(_this){$(_this).find('span').removeClass('left-line');$(_this).find('span').each(function(index,el){if($(el).children('b').length <= 1){if($(el).prev('span')[0]){$(el).addClass('left-line');}}});}});});