define('gooru-web/adapters/authentication/authentication',['exports','ember','gooru-web/config/environment','gooru-web/utils/endpoint-config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebConfigEnvironment,_gooruWebUtilsEndpointConfig,_gooruWebMixinsConfiguration){ /**
 * Adapter for the Authentication (Login) with API 3.0
 *
 * @typedef {Object} AuthenticationAdapter
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{session:_ember['default'].inject.service('session'),namespace:'/api/nucleus-auth/v2',tokenNamespace:'/api/nucleus-auth/v1/token', /**
   * Read the authentication basic credentials details
   * @type {Object}
   */auth:_ember['default'].computed.alias('configuration.AUTH'), /**
   * Post a request to authenticate a normal user or anonymous user.
   * @param data values required to build the post body
   * @returns {Promise}
   */postAuthentication:function postAuthentication(data){var adapter=this;var endpointUrl=_gooruWebUtilsEndpointConfig['default'].getEndpointSecureUrl();var namespace=this.get('namespace');var url='' + endpointUrl + namespace + '/signin';var reqBody={grant_type:data.isAnonymous?'anonymous':'credential'};var useGooruClient=false;var userDemoClient=false;if(data.isAnonymous){if(!data.nonce){useGooruClient = true;}}else {if(!adapter.get('session.isGooruClientId') && !adapter.get('session.isDemoTenant')){reqBody.anonymous_token = adapter.get('session.token-api3');}else {if(adapter.get('session.isDemoTenant')){userDemoClient = true;}else {useGooruClient = true;}}}var auth=adapter.get('auth');var demoTenantAuth=adapter.get('configuration.DEMO_TENANT_AUTH');if(useGooruClient){reqBody.client_key = auth?auth.clientKey:_gooruWebConfigEnvironment['default']['API-3.0'].clientKey;reqBody.client_id = auth?auth.clientId:_gooruWebConfigEnvironment['default']['API-3.0'].clientId;}if(userDemoClient){reqBody.client_key = demoTenantAuth?demoTenantAuth.clientKey:auth.clientKey;reqBody.client_id = demoTenantAuth?demoTenantAuth.clientId:auth.clientKey;}var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:adapter.defineHeaders(data),data:JSON.stringify(reqBody),global:false /* Stop global ajaxError event from triggering */};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(data){if(data.isAnonymous){return data.nonce?{Authorization:'Nonce ' + data.nonce}:{};}else {return {Authorization:'Basic ' + btoa(data.username + ':' + data.password)};}}, /**
   * Post a request to authenticate a google user
   * @param access token required to build the get headers
   * @returns {Promise}
   */postAuthenticationWithToken:function postAuthenticationWithToken(data){var url=this.get('tokenNamespace');var options={type:'GET',contentType:'application/json; charset=utf-8',headers:{Authorization:'Token ' + data.accessToken}};return _ember['default'].$.ajax(url,options);}, /**
   * Get request to check if the token is valid
   * @param access token required to build the get headers
   * @returns {Promise}
   */checkToken:function checkToken(data){var url=this.get('tokenNamespace');var options={type:'GET',contentType:'application/json; charset=utf-8',headers:{Authorization:'Token ' + data.accessToken},global:false /* Stop global ajaxError event from triggering */};return _ember['default'].$.ajax(url,options);}, /**
   * Invalidates the current token
   * @returns {Promise}
   */signOut:function signOut(){var namespace=this.get('namespace');var url=namespace + '/signout';var token=this.get('session.token-api3');var options={type:'DELETE',contentType:'application/json; charset=utf-8',headers:{Authorization:'Token ' + token}};return _ember['default'].$.ajax(url,options);}, /**
   * Post a request to find the domain name have any directions for authentication.
   * @param data values required to build the post body
   * @returns {Promise}
   */domainBasedRedirection:function domainBasedRedirection(data){var namespace=this.get('namespace');var url=namespace + '/redirect';var token=this.get('session.token-api3');var options={type:'POST',contentType:'application/json; charset=utf-8',headers:{Authorization:'Token ' + token},data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}});});