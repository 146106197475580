define('gooru-web/routes/student/class/dashboard',['exports','ember','gooru-web/mixins/learning-journey-mixin','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsLearningJourneyMixin,_gooruWebMixinsUiHelperMixin){function _toConsumableArray(arr){if(Array.isArray(arr)){for(var i=0,arr2=Array(arr.length);i < arr.length;i++) arr2[i] = arr[i];return arr2;}else {return Array.from(arr);}}exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsLearningJourneyMixin['default'],_gooruWebMixinsUiHelperMixin['default'],{ // ---------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/dashboard
   */dashboardService:_ember['default'].inject.service('api-sdk/dashboard'), /**
   * @requires service:api-sdk/analytics
   */analyticsService:_ember['default'].inject.service('api-sdk/analytics'), /**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), // -------------------------------------------------------
// Hooks
model:function model(){var route=this;var classData=route.modelFor('student.class')['class'];route.setTitle('Dashboard',classData.title);var courseId=classData.get('courseId');var fwCode=classData.get('preference.framework') || 'GUT';var userId=route.get('session.userId');var course=route.modelFor('student.class').course;var units=route.modelFor('student.class').units;var unit0Content=route.modelFor('student.class').unit0Content;units = _ember['default'].A([].concat(_toConsumableArray(unit0Content),_toConsumableArray(units)));var setting=classData.get('setting');var isPremiumCourse=setting?setting['course.premium'] && setting['course.premium'] === true:false;var isMilestoneViewEnabledForTenant=route.isMilestoneViewEnabled(classData.preference,classData.setting);var locationQueryParam={courseId:courseId};var destination=null;if(isPremiumCourse && isMilestoneViewEnabledForTenant){locationQueryParam.fwCode = fwCode;var skylineInitialState=route.modelFor('student.class').skylineInitialState;destination = skylineInitialState.get('destination');}var userLocationPromise=route.get('analyticsService').getUserCurrentLocation(classData.get('id'),userId,locationQueryParam);return _ember['default'].RSVP.hash({userLocation:userLocationPromise,classData:classData,course:course,units:units,destination:destination,userProfileData:route.get('profileService').readUserProfile(userId)});}, // ------------------------------------------------------------
// Setup
setupController:function setupController(controller,model){controller.set('currentClass',model.classData);controller.set('userLocation',model.userLocation);controller.set('course',model.course);controller.set('units',model.units);controller.set('destination',model.destination);controller.set('userProfileData',model.userProfileData);},resetController:function resetController(controller){controller.set('isShowStudentProgressReport',false);}});});