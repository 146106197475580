define('gooru-web/mixins/classroom-mixin',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Mixin.create({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/class
   */classService:_ember['default'].inject.service('api-sdk/class'), // -------------------------------------------------------------------------
// property
isTagGoogleClassroom:_ember['default'].computed('googleClassList',function(){var mixin=this;var classInfo=mixin.get('class') || mixin.get('primaryClass') || mixin.get('currentClass');var classSetting=classInfo.get('setting');var googleClassList=mixin.get('googleClassList');var hasSelectedClass=googleClassList?googleClassList.findBy('id',classSetting.google_classroom_id):null;return classSetting.get('google_classroom_id') && hasSelectedClass;}),googleClassroomName:_ember['default'].computed('googleClassList',function(){var mixin=this;var classInfo=mixin.get('class') || mixin.get('primaryClass') || mixin.get('currentClass');var classSetting=classInfo.get('setting');var googleClassList=mixin.get('googleClassList');var hasSelectedClass=googleClassList?googleClassList.findBy('id',classSetting.google_classroom_id):null;return hasSelectedClass?hasSelectedClass.name:'';}), // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Methods
fetchClassList:function fetchClassList(){var mixin=this;mixin.get('classService').fetchAccessToken().then(function(token){if(token){mixin.get('classService').fetchClassRoomList().then(function(classList){if(!mixin.get('isDestroyed')){mixin.set('googleClassList',classList.response);}})['catch'](function(){mixin.set('googleClassList',_ember['default'].A([]));});}})['catch'](function(){mixin.set('googleClassList',_ember['default'].A([]));});},postAssignment:function postAssignment(classroomId,assignmentsInfo){var mixin=this;mixin.get('classService').createClassroomAssignments(classroomId,assignmentsInfo).then();}});});