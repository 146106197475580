define('gooru-web/components/student/class/gru-class-grade',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-class-grade'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click next
     */onMoveNext:function onMoveNext(step){var component=this;component.sendAction('onMoveNext',step);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Number} classGradeLevel
   */classGradeLevel:_ember['default'].computed('gradeLevel',function(){var component=this; //Class grade start from 1 instead of 0 so, minus one from class grade
return component.get('gradeLevel') - 1;}), /**
   * @property {Array} gradeLevels
   */gradeLevels:_ember['default'].computed('classGradeLevel',function(){var component=this;var gradeLevel=component.get('classGradeLevel');var startingLevel=gradeLevel > 3?gradeLevel - 3:1;var numberOfLevelsToShow=6;var gradeLevelPoint=1;var gradeLevels=_ember['default'].A([]);while(gradeLevelPoint <= numberOfLevelsToShow) {gradeLevels.push(startingLevel);startingLevel++;gradeLevelPoint++;}return gradeLevels;})});});