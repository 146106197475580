define('gooru-web/components/reports/assessment/questions/gru-open-ended',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * Open Ended Question
 *
 * Component responsible for controlling the logic and appearance of an open
 * ended question inside of the assessment report
 *
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-open-ended'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
answer:_ember['default'].computed('question.answerObject.[]','showCorrect',function(){var correctAnswer=this.get('question.answers.firstObject.text') || 'N/A';return this.get('showCorrect')?correctAnswer:this.get('userAnswer');}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});