define('gooru-web/controllers/student/independent/course-map',['exports','ember','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin){ /**
 * Content map controller
 *
 * Controller responsible of the logic for the course map page
 */exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
studentIndependentController:_ember['default'].inject.controller('student.independent'), /**
   * Session object of currently logged in user
   * @type {Session}
   */session:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/learner
   */learnerService:_ember['default'].inject.service('api-sdk/learner'), // -------------------------------------------------------------------------
// Attributes
queryParams:['location','tab'], /**.
   * Combination of unit, lesson and resource (collection or assessment)
   * separated by a plus sign
   * @example
   * location='uId001+lId002+cId003'
   */location:null,isFirstLoad:true,tab:null, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Update 'location' (bound query param)
     *
     * @function
     * @param {String} newLocation - String of the form 'unitId[+lessonId[+resourceId]]'
     * @returns {undefined}
     */updateLocation:function updateLocation(newLocation){this.set('location',newLocation);}, /**
     * Locate the user in is actual location
     *
     * @function
     * @param {String} location'
     * @returns {undefined}
     */locateMe:function locateMe(location){this.set('location',location);this.set('showLocation',true);},closePullUp:function closePullUp(){var component=this;component.set('isOpenPlayer',false);component.getUserCurrentLocation();}}, // -------------------------------------------------------------------------
// Events
init:function init(){var controller=this;controller._super.apply(controller,arguments);_ember['default'].run.scheduleOnce('afterRender',this,function(){$('[data-toggle="tooltip"]').tooltip();var tab=controller.get('tab');if(tab && tab === 'report'){controller.openStudentCourseReport();}});}, // -------------------------------------------------------------------------
// Properties
/**
   * @prop {String} userLocation - Location of a user in a course
   * String of the form 'unitId[+lessonId[+resourceId]]'
   */userLocation:null, /**
   * A link to the parent class controller
   * @see controllers/class.js
   * @property {Class}
   */course:_ember['default'].computed.alias('studentIndependentController.course'), /**
   * A link to the parent class controller
   * @see controllers/class.js
   * @property {Object}
   */performance:_ember['default'].computed.alias('studentIndependentController.performance'), /**
   *Show the current location
   */showLocation:true,openingLocation:_ember['default'].computed('location',function(){if(this.get('isFirstLoad')){this.set('isFirstLoad',false);var location=this.get('location') || this.get('userLocation');this.set('location',location);return location;}else {return this.get('location') || '';}}), // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
openStudentCourseReport:function openStudentCourseReport(){var controller=this;controller.set('showCourseReport',true);var params=_ember['default'].Object.create({userId:controller.get('session.userId'),courseId:controller.get('course.id'),course:controller.get('course'),performance:controller.get('performance'),isTeacher:false,isStudent:true,loadUnitsPerformance:true});controller.set('studentCourseReportContext',params);},getUserCurrentLocation:function getUserCurrentLocation(){var controller=this;var userId=controller.get('session.userId');var course=controller.get('course');controller.get('learnerService').fetchLocationCourse(course.get('id'),userId).then(function(userLocationObj){var userLocation='';if(userLocationObj){var unitId=userLocationObj.get('unitId');var lessonId=userLocationObj.get('lessonId');var collectionId=userLocationObj.get('collectionId');userLocation = unitId + '+' + lessonId + '+' + collectionId;controller.set('userLocation',userLocation);controller.set('location',userLocation);controller.set('showLocation',true);}});}});});