define('gooru-web/models/audience',['exports','ember'],function(exports,_ember){ /**
 * Audience model
 *
 * @typedef {Object} Audience
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string}
   */id:null, /**
   * @property {string}
   */name:null, /**
   * @property {number}
   */order:null});});