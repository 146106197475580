define('gooru-web/components/content/modals/gru-add-rubric-to-question',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {rubricService}
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * @property {rubricService}
   */questionService:_ember['default'].inject.service('api-sdk/question'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-add-rubric-to-question'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Select rubric
     */selectRubric:function selectRubric(rubric){this.set('selectedRubric',rubric);$('.gru-add-rubric-to-question .selected').removeClass('selected');$('.' + rubric.id).addClass('selected');}, /**
     * Add to question
     */addTo:function addTo(){var component=this;var model=component.get('model');var rubricId=component.get('selectedRubric.id');var baseQuestionId=model.baseQuestionId;var primaryQuestionId=baseQuestionId?baseQuestionId:model.questionId;return component.get('rubricService').associateRubricToQuestion(rubricId,model.questionId).then(function(){if(model.callback){component.get('questionService').readQuestion(primaryQuestionId).then(function(question){var rubricObj=question.rubric;if(baseQuestionId){var rubricQuestion=question.subQuestions.findBy('id',model.questionId);if(rubricQuestion){rubricObj = rubricQuestion.rubric;}}model.callback.success(rubricObj);});}component.triggerAction({action:'closeModal'});});}, /**
     * Go to content page
     */goToContent:function goToContent(){var component=this;var model=component.get('model');var queryParams={profileId:model.userId,type:'my-content',activeContentType:'rubric'};this.triggerAction({action:'closeModal'});component.get('router').transitionTo('library-search',{queryParams:queryParams});}}, // -------------------------------------------------------------------------
// Properties
/**
   * Model with the values to use in the modal
   */model:null, /**
   * Filter only rubrics ON
   */filteredRubrics:_ember['default'].computed('model.rubrics',function(){return this.get('model.rubrics').filter(function(rubric){return rubric.title;});})});});