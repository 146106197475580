define('gooru-web/routes/student-independent-learning/student-bookmarks',['exports','ember'],function(exports,_ember){ /**
 * Studnet Bookmarks Route
 *
 * @module
 * @augments Ember.Route
 */exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @type {BookmarkService} Service to retrieve bookmark information
   */bookmarkService:_ember['default'].inject.service('api-sdk/bookmark'), // -------------------------------------------------------------------------
// Attributes
/**
   * @property {Number} number of items to load per page
   */PAGE_SIZE:8, // -------------------------------------------------------------------------
// Methods
model:function model(){var route=this;var pagination={offset:0,pageSize:this.get('PAGE_SIZE')};var bookmarksPromise=route.get('bookmarkService').fetchBookmarks(pagination,true);return _ember['default'].RSVP.hash({bookmarks:bookmarksPromise,pagination:pagination}).then(function(hash){var bookmarks=hash.bookmarks;var pagination=hash.pagination;return {bookmarks:bookmarks,pagination:pagination};});},setupController:function setupController(controller,model){controller.set('bookmarks',model.bookmarks);controller.set('CURRENT_ITERATION_SIZE',model.bookmarks.length);controller.set('pagination',model.pagination);controller.set('toggleState',false);}});});