define('gooru-web/routes/profile',['exports','ember','gooru-web/models/profile/profile','gooru-web/validations/edit-profile'],function(exports,_ember,_gooruWebModelsProfileProfile,_gooruWebValidationsEditProfile){exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
queryParams:{classId:{refreshModel:true}}, /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @dependency {i18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(),session:_ember['default'].inject.service('session'), /**
   * Current user id
   */currentLoginId:_ember['default'].computed.alias('session.userId'), // -------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(){ // TODO: authenticate session with ember-simple-auth, if not send to log in
}, /**
   * Get model for the controller
   */model:function model(params){var route=this;var userId=params.userId;if(userId){var _ret=(function(){var isUsername=!/-.*-/.exec(userId);var profilePromise=isUsername?route.get('profileService').readUserProfileByUsername(params.userId):route.get('profileService').readUserProfile(params.userId);var loggedUserId=route.get('currentLoginId');var loginUserProfile=loggedUserId !== 'anonymous'?route.get('profileService').readUserProfile(route.get('currentLoginId')):null;var userPreference=route.get('profileService').getProfilePreference();return {v:profilePromise.then(function(profile){var EditProfileValidation=_gooruWebModelsProfileProfile['default'].extend(_gooruWebValidationsEditProfile['default']);var editProfile=EditProfileValidation.create(_ember['default'].getOwner(route).ownerInjection());editProfile.merge(profile,profile.modelProperties());return _ember['default'].RSVP.hash({profile:editProfile,loginUserProfile:loginUserProfile,userPreference:userPreference});})};})();if(typeof _ret === 'object')return _ret.v;}return _ember['default'].RSVP.hash({profile:null,loginUserProfile:null,userPreference:null});}, /**
   * Set all controller properties from the model
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){controller.set('profile',model.profile);controller.set('steps',model.tourSteps);controller.set('userPreference',model.userPreference);controller.set('currentLoginUser',model.loginUserProfile);}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Triggered when a class menu item is selected
     * @param {string} item
     */selectMenuItem:function selectMenuItem(item){var route=this;var controller=route.get('controller');var currentMenuItem=controller.get('menuItem');controller.selectMenuItem(item);if(currentMenuItem !== item){if(item === 'content'){route.transitionTo('profile.' + item + '.courses');}else if(item === 'network'){route.transitionTo('profile.' + item + '.following');}else {route.transitionTo('profile.' + item);}}}}});});