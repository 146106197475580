define('gooru-web/models/class/class',['exports','ember-data'],function(exports,_emberData){ /**
 * Model that contains the class information
 * @typedef {Object} Class
 */exports['default'] = _emberData['default'].Model.extend({ /**
   * @property {string} Student/Teacher name
   */name:_emberData['default'].attr('string'), /**
   * @property {string} Class Code
   */code:_emberData['default'].attr('string'), /**
   * @property {string} Greetings message
   */greetings:_emberData['default'].attr('string'), /**
   * @property {string} Long description about the class
   */description:_emberData['default'].attr('string'), /**
   * @property {string} Class start date
   */startDate:_emberData['default'].attr('string'), /**
   * @property {string} Class end date
   */endDate:_emberData['default'].attr('string'), /**
   * @property {string} Grades values separated by commas, e.g. 'K,1,2,3'
   */grades:_emberData['default'].attr('string'), /**
   * @property {string} Visibility (true/false)
   */visibility:_emberData['default'].attr('boolean'), /**
   * @property {string} Total members joined to the class
   */totalMembers:_emberData['default'].attr('number'), /**
   * @property {{ id: string, username: string, avatarUrl: string}[]} An array of teachers with very basic teacher information
   */teachers:_emberData['default'].attr(), /**
   * @property {string} Subject of the class
   */subject:_emberData['default'].attr('string'), /**
   * @property {string} Course of the class
   */course:_emberData['default'].attr('string'), /**
   * Indicates if a user is a teacher of this class
   * @param {string} userId user identifier
   * @returns {bool}
   */isTeacher:function isTeacher(userId){return this.get('teachers').filterBy('id',userId).get('length');}, /**
   * Indicates if a user is a student of this class
   * @param {string} userId user identifier
   * @returns {bool}
   */isStudent:function isStudent(userId){return !this.isTeacher(userId);}});});