define('gooru-web/adapters/route0',['exports','ember','gooru-web/adapters/application'],function(exports,_ember,_gooruWebAdaptersApplication){exports['default'] = _gooruWebAdaptersApplication['default'].extend({ /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @type {String}} base url for course map API endpoints
   */namespace:'/api/route0/v1',namespaceV2:'/api/route0/v2', /**
   * Method to fetch route0 for given student in class from the API
   * @function fetchInClass
   * @returns {Promise}
   */fetchInClass:function fetchInClass(filter){var adapter=this;var namespace=adapter.get('namespaceV2');var url=namespace + '/rtd?classId=' + filter.body.classId + '&courseId=' + filter.body.courseId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.get('headers')};return _ember['default'].$.ajax(url,options);}, /**
   * Method to fetch route0 for given student in class from the API
   * @function fetchInClass
   * @param {filters.classId} classId
   * @param {filters.courseId} courseId
   * @param {filters.userId} userId student id
   * @returns {Promise}
   */fetchInClassByTeacher:function fetchInClassByTeacher(filters){var adapter=this;var namespace=adapter.get('namespaceV2');var url=namespace + '/rtd';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.get('headers'),data:filters};return _ember['default'].$.ajax(url,options);},updateRouteAction:function updateRouteAction(action){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/rtd/status';var options={type:'PUT',contentType:'application/json; charset=utf-8',headers:adapter.get('headers'),data:JSON.stringify(action.body)};return _ember['default'].$.ajax(url,options);},fetchAlternatePaths:function fetchAlternatePaths(params){var userId=arguments.length <= 1 || arguments[1] === undefined?undefined:arguments[1];var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/rtd/class/' + params.classId + '/courses/' + params.courseId + '/units/' + params.unitId + '/lessons/' + params.lessonId + '/alternate-paths';if(userId){url += '?userId=' + userId;}var options={type:'GET',contentType:'application/json; charset=utf-8',processData:false,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});