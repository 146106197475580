define('gooru-web/components/player/gru-offline-activity-page',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['oa-study-player','gru-offline-activity-page'], // -------------------------------------------------------------------------
// Dependencies
oaAnalyticsService:_ember['default'].inject.service('api-sdk/offline-activity/oa-analytics'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadOfflineActivitySubmissions();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on start button
onStartPlayer:function onStartPlayer(){var component=this;component.set('isShowLandingPage',false);}, //Action triggered when click on cancel button
onClosePlayer:function onClosePlayer(){var component=this;var classId=component.get('classId');var source=component.get('source');var isIframeMode=component.get('isIframeMode');if(isIframeMode){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}else if(classId && source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP){component.get('router').transitionTo('student.class.course-map',classId,{queryParams:{refresh:true}});}else if(classId && source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS){component.get('router').transitionTo('student.class.class-activities',classId);}else {component.get('router').transitionTo('student-home');}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isShowLandingPage
   * Property to show/hide OA start page
   */isShowLandingPage:_ember['default'].computed('offlineActivitySubmissions.tasks',function(){var component=this;var offlineActivitySubmissions=component.get('offlineActivitySubmissions');var oaTaskSubmissions=offlineActivitySubmissions?offlineActivitySubmissions.tasks.objectAt(0):null;return !(oaTaskSubmissions && oaTaskSubmissions.taskId && component.get('playerEvents'));}), /**
   * @property {Object} mapLocationContext
   * Property for current mapLocation context
   */mapLocationContext:_ember['default'].computed.alias('mapLocation.context'), /**
   * @property {UUID} classId
   * Property for active class ID
   */classId:_ember['default'].computed.alias('mapLocationContext.classId'), /**
   * @property {UUID} courseId
   * Property for active course ID
   */courseId:_ember['default'].computed.alias('mapLocationContext.courseId'), /**
   * @property {UUID} unitId
   * Property for active Item's Unit ID
   */unitId:_ember['default'].computed.alias('mapLocationContext.unitId'), /**
   * @property {UUID} lessonId
   * Property for active item's lesson ID
   */lessonId:_ember['default'].computed.alias('mapLocationContext.lessonId'), /**
   * @property {Object} offlineActivitySubmissions
   * Property for selected offline activity submissions
   */offlineActivitySubmissions:null,playerEvents:null,onWatchAction:_ember['default'].observer('playerEvents',function(){var playerEvents=this.get('playerEvents');if(playerEvents === _gooruWebConfigConfig.EXTERNAL_PLAYER_ACTIONS.START){this.send('onStartPlayer');}}),isShowFullView:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadOfflineActivitySubmissions
   * Method to load selected offline activity submissions
   */loadOfflineActivitySubmissions:function loadOfflineActivitySubmissions(){var component=this;component.set('isLoading',true);_ember['default'].RSVP.hash({offlineActivitySubmissions:component.fetchTasksSubmissions()}).then(function(_ref){var offlineActivitySubmissions=_ref.offlineActivitySubmissions;component.set('offlineActivitySubmissions',offlineActivitySubmissions);component.set('isLoading',false);});}, /**
   * @function fetchTasksSubmissions
   * Method to fetch student submitted oa task data
   */fetchTasksSubmissions:function fetchTasksSubmissions(){var component=this;var classId=component.get('classId');var oaId=component.get('offlineActivity.id');var userId=component.get('session.userId');var courseId=component.get('courseId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var dataParam={courseId:courseId,unitId:unitId,lessonId:lessonId};return component.get('oaAnalyticsService').getSubmissionsToGrade(classId,oaId,userId,dataParam);}});});