define('gooru-web/serializers/taxonomy/taxonomy',['exports','ember','gooru-web/models/taxonomy/taxonomy-root','gooru-web/models/taxonomy/taxonomy-item','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyRoot,_gooruWebModelsTaxonomyTaxonomyItem,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig){ /**
 * Serializer for Taxonomy endpoints
 *
 * @typedef {Object} ProfileSerializer
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'), /**
   * Normalize the Fetch Taxonomy Subjects endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Subject[]} an array of subjects
   */normalizeFetchSubjects:function normalizeFetchSubjects(payload){var result=[];var serializer=this;var subjects=payload.subjects;if(_ember['default'].isArray(subjects)){result = subjects.map(function(subject){return serializer.normalizeSubject(subject);});}return result;},normalizeSubject:function normalizeSubject(subjectPayload){var serializer=this;return _gooruWebModelsTaxonomyTaxonomyRoot['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{id:subjectPayload.id,frameworkId:subjectPayload.standard_framework_id || subjectPayload.frameworkId,title:subjectPayload.title,subjectTitle:subjectPayload.title,code:subjectPayload.code,frameworks:serializer.normalizeFrameworks(subjectPayload.frameworks,subjectPayload.title)});}, /**
   * Method to normalize taxonomy subjects from DS
   */normalizeTaxonomySubjects:function normalizeTaxonomySubjects(subjectPayload){var result=[];var serializer=this;var subjects=subjectPayload.subjects;if(_ember['default'].isArray(subjects)){result = subjects.map(function(subject){return serializer.normalizeSubject(subject);});}return result;},normalizeFrameworks:function normalizeFrameworks(frameworksPayload,parentTitle){var frameworks=[];var serializer=this;if(frameworksPayload && _ember['default'].isArray(frameworksPayload)){frameworks = frameworksPayload.map(function(framework){return serializer.normalizeFramework(framework,parentTitle);});}return frameworks;},normalizeFramework:function normalizeFramework(subjectPayload,parentTitle){var serializer=this;return _gooruWebModelsTaxonomyTaxonomyRoot['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{id:subjectPayload.taxonomy_subject_id,frameworkId:subjectPayload.standard_framework_id,title:subjectPayload.title,subjectTitle:'' + parentTitle});}, /**
   * Normalize the Fetch Taxonomy Courses endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Course[]} an array of courses
   */normalizeFetchCourses:function normalizeFetchCourses(payload){var result=[];var serializer=this;var courses=payload.courses;if(_ember['default'].isArray(courses)){result = courses.map(function(course){return serializer.normalizeCourse(course);});}return result;},normalizeCourse:function normalizeCourse(coursePayload){var serializer=this;return _gooruWebModelsTaxonomyTaxonomyItem['default'].create(_ember['default'].getOwner(serializer).ownerInjection(),{id:coursePayload.id,code:coursePayload.code,title:coursePayload.title});}, /**
   * Normalize the Fetch Taxonomy Domains endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Course[]} an array of domains
   */normalizeFetchDomains:function normalizeFetchDomains(payload){var result=[];var serializer=this;var domains=payload.domains;if(_ember['default'].isArray(domains)){result = domains.map(function(domain){return serializer.normalizeDomain(domain);});}return result;},normalizeDomain:function normalizeDomain(domainPayload){return _gooruWebModelsTaxonomyTaxonomyItem['default'].create({id:domainPayload.id,code:domainPayload.code,title:domainPayload.title,sequence:domainPayload.sequence_id || 0});}, /**
   * Normalize the Fetch Taxonomy Codes endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Object[]} an array of objects, each one with code information.
   */normalizeFetchCodes:function normalizeFetchCodes(payload){var result=[];var serializer=this;var codes=payload.codes;if(_ember['default'].isArray(codes)){result = codes.map(function(code){return serializer.normalizeCode(code);});}return result;},normalizeCode:function normalizeCode(codePayload){return {id:codePayload.id,code:codePayload.code,title:codePayload.title,parentTaxonomyCodeId:codePayload.parent_taxonomy_code_id,codeType:codePayload.code_type};}, /**
   * Serialize a TaxonomyTagData object into a JSON representation required by a core element (course|unit|collection|assessment|resource|question)
   *
   * @param taxonomyData the taxonomyData object
   * @returns {Object} a JSON Object
   */serializeTaxonomy:function serializeTaxonomy(taxonomyData){var taxonomyResult=null;if(taxonomyData && _ember['default'].isArray(taxonomyData) && taxonomyData.length > 0){taxonomyResult = {};taxonomyData.forEach(function(taxonomy){var taxonomyKey=taxonomy.get('id');taxonomyResult[taxonomyKey] = {code:taxonomy.get('code'),title:taxonomy.get('title'),parent_title:taxonomy.hasOwnProperty('parentTitle')?taxonomy.get('parentTitle'):'',description:taxonomy.get('description'),framework_code:taxonomy.get('frameworkCode')};});}return taxonomyResult;}, /**
   * Normalize the core element taxonomy data into a TaxonomyTagData object
   *
   * @param taxonomyArray - array of taxonomy objects
   * @param {string} level
   * @returns {TaxonomyTagData[]} a TaxonomyTagData array
   */normalizeTaxonomyArray:function normalizeTaxonomyArray(taxonomyArray,level){var taxonomyData=[];if(taxonomyArray && taxonomyArray.length){taxonomyArray.forEach(function(taxonomyObject){var isMicroStandard=_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(taxonomyObject.internalCode);taxonomyData.push(_gooruWebModelsTaxonomyTaxonomyTagData['default'].create({id:taxonomyObject.internalCode,code:taxonomyObject.code,title:taxonomyObject.title,parentTitle:taxonomyObject.parentTitle,frameworkCode:taxonomyObject.frameworkCode,taxonomyId:taxonomyObject.id,taxonomyLevel:level?level:isMicroStandard?_gooruWebConfigConfig.TAXONOMY_LEVELS.MICRO:_gooruWebConfigConfig.TAXONOMY_LEVELS.STANDARD}));});}return _ember['default'].A(taxonomyData);}, /**
   * Normalize the core element taxonomy data into a TaxonomyTagData object
   *
   * @param taxonomyObject - object of taxonomy objects
   * @param level taxonomy level
   * @returns {TaxonomyTagData[]} a TaxonomyTagData array
   */normalizeTaxonomyObject:function normalizeTaxonomyObject(taxonomyObject,level){var taxonomyData=[];if(taxonomyObject){for(var key in taxonomyObject) {if(taxonomyObject.hasOwnProperty(key)){var taxonomy=taxonomyObject[key];var isMicroStandard=level?false:_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(key);taxonomyData.push(_gooruWebModelsTaxonomyTaxonomyTagData['default'].create({id:key,code:taxonomy.code,title:taxonomy.title,parentTitle:taxonomy.parent_title?taxonomy.parent_title:'',description:taxonomy.description?taxonomy.description:'',frameworkCode:taxonomy.framework_code || taxonomy.frameworkCode,taxonomyId:key,taxonomyLevel:level?level:isMicroStandard?_gooruWebConfigConfig.TAXONOMY_LEVELS.MICRO:_gooruWebConfigConfig.TAXONOMY_LEVELS.STANDARD}));}}}return _ember['default'].A(taxonomyData);}, /**
   * Serialize a TaxonomyTagData object into a JSON representation only for Resource Player Events
   *
   * @param taxonomyData the taxonomyData object
   * @returns {Object} a JSON Object
   */serializeTaxonomyForEvents:function serializeTaxonomyForEvents(taxonomyData){var taxonomyResult=null;if(taxonomyData && _ember['default'].isArray(taxonomyData) && taxonomyData.length > 0){taxonomyResult = {};taxonomyData.forEach(function(taxonomy){var taxonomyKey=taxonomy.get('id');taxonomyResult[taxonomyKey] = taxonomy.get('code');});}return taxonomyResult;}, /**
   * @function normalizeDomainGradeBoundary
   */normalizeDomainGradeBoundary:function normalizeDomainGradeBoundary(payload){var normalizedData=_ember['default'].A([]);if(payload && payload.domains){normalizedData = payload.domains;}return normalizedData;}, /**
   * @function normalizeGrades
   */normalizeGrades:function normalizeGrades(payload){var resultSet=_ember['default'].A();payload = _ember['default'].A(payload.grades);payload.forEach(function(data){if(data.levels){(function(){var levelItem=_ember['default'].A();data.levels.forEach(function(element){element.levelId = data.id;levelItem.pushObject(_ember['default'].Object.create(element));});data.levels = levelItem;})();}var result=_ember['default'].Object.create(data);resultSet.pushObject(result);});resultSet = resultSet.sortBy('sequence');return resultSet;}, /**
   * Normalize the learning maps taxonomy array
   *
   * @param taxonomyArray - array of taxonomy objects
   * @param {string} level
   * @returns {TaxonomyTagData[]} a TaxonomyTagData array
   */normalizeLearningMapsTaxonomyArray:function normalizeLearningMapsTaxonomyArray(taxonomyObject,level){var taxonomyData=[];if(taxonomyObject){Object.keys(taxonomyObject).forEach(function(internalCode){var isMicroStandard=_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(taxonomyObject.internalCode);var taxonomyInfo=taxonomyObject[internalCode];taxonomyData.push(_gooruWebModelsTaxonomyTaxonomyTagData['default'].create({id:internalCode,code:taxonomyInfo.code,title:taxonomyInfo.title,parentTitle:taxonomyInfo.parentTitle,frameworkCode:taxonomyInfo.frameworkCode,taxonomyId:internalCode,taxonomyLevel:level?level:isMicroStandard?_gooruWebConfigConfig.TAXONOMY_LEVELS.MICRO:_gooruWebConfigConfig.TAXONOMY_LEVELS.STANDARD}));});}return _ember['default'].A(taxonomyData);}, /**
   * Normalize the Fetch Taxonomy Subjects endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Subject[]} an array of subjects
   */normalizeFetchSubject:function normalizeFetchSubject(payload){var result={};if(payload){result = Object.assign(result,payload);result.standardFrameworkId = result.standard_framework_id;delete result.standard_framework_id;}return result;}, /**
   * Normalize the Fetch Taxonomy categories endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {Subject[]} an array of Categories
   */normalizeFetchCategories:function normalizeFetchCategories(payload){var resultSet=_ember['default'].A();payload = _ember['default'].A(payload.subject_classifications);payload.forEach(function(data){var result=_ember['default'].Object.create(data);resultSet.pushObject(result);});return resultSet;}, /**
   * Normalize the Fetch Taxonomy subject frameworks endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {frameworks[]} an array of frameworks
   */normalizeFetchSubjectFrameworks:function normalizeFetchSubjectFrameworks(payload){var resultSet=_ember['default'].A();payload = _ember['default'].A(payload.frameworks);payload.forEach(function(data){var result=_ember['default'].Object.create(data);resultSet.pushObject(result);});return resultSet;},normalizeFWCMatrixs:function normalizeFWCMatrixs(payload){var domainMatrixs=_ember['default'].A(payload.competencyMatrix);return domainMatrixs.map(function(domainMatrix){var domain=_ember['default'].Object.create(domainMatrix.topics[0]);var domainComptencies=domain.get('competencies').map(function(competency){return _ember['default'].Object.create(competency);});domain.set('domainCode',domainMatrix.domainCode);domain.set('domainName',domainMatrix.domainName);domain.set('domainSeq',domainMatrix.domainSeq);domain.set('fwDomainName',domainMatrix.fwDomainName);domain.set('topics',domainMatrix.topics);domain.set('competencies',domainComptencies);return domain;});}, /**
   * @function normalizeCrosswalkCompetency
   */normalizeCrosswalkCompetency:function normalizeCrosswalkCompetency(payload){var crosswalkCompetencies=_ember['default'].A(payload.frameworkCrossWalkComp);return crosswalkCompetencies.map(function(competency){return _ember['default'].Object.create({sourceDisplayCode:competency.source_display_code,sourceTaxonomyCodeId:competency.source_taxonomy_code_id,targetDisplayCode:competency.target_display_code,targetFrameworkId:competency.target_framework_id,targetTaxonomyCodeId:competency.target_taxonomy_code_id,targetLoCode:competency && competency.target_lo_code?competency.target_lo_code:null,targetLoName:competency && competency.target_lo_name?competency.target_lo_name:null});});}});});