define('gooru-web/controllers/reports/study-student-external-collection',['exports','ember','gooru-web/config/config','gooru-web/mixins/activity-feedback-mixin'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebMixinsActivityFeedbackMixin){ /**
 *
 * Controls the access to the analytics data for a
 * student related to a collection of resources
 *
 */exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsActivityFeedbackMixin['default'],{queryParams:['collectionId','classId','courseId','isIframeMode','role','type','score','timespent','isStudyPlayer','source'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @property {NavigateMapService}
   */navigateMapService:_ember['default'].inject.service('api-sdk/navigate-map'), /**
   * @property {Ember.Service} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {Ember.Service} Service to retrieve a collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'),offlineActivityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @type {UnitService} Service to retrieve unit information
   */unitService:_ember['default'].inject.service('api-sdk/unit'), /**
   * @type {LessonService} Service to retrieve lesson information
   */lessonService:_ember['default'].inject.service('api-sdk/lesson'), // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered for the next button
     */playNext:function playNext(){var controller=this;var collection=controller.get('collection');var submittedScoreInPercentage=collection.get('isAssessment')?controller.get('score'):null;controller.playNextContent(submittedScoreInPercentage);}, /**
     * Action triggered for the next button
     */onFeedbackCapture:function onFeedbackCapture(){var controller=this;var userFeedback=controller.get('userCategoryFeedback');if(userFeedback && userFeedback.length){var learningFeedback=controller.getFeedbackObject();controller.get('activityFeedbackService').submitUserFeedback(learningFeedback).then(function(){controller.send('playNext');});}else {controller.send('playNext');}},onExit:function onExit(rouet,id){var controller=this;var isIframeMode=controller.get('isIframeMode');if(isIframeMode){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}else {controller.transitionToRoute(rouet,id);}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {string}
   */classId:null, /**
   * @property {string}
   */courseId:null, /**
   * @property {string}
   */collectionId:null, /**
   * @property {Object} dataParams
   * Submitted external assessment data params
   */dataParams:null, /**
   * @property {Boolean} isShowActivityFeedback
   * Property to evaluate whether the feedback tab should shown
   */isShowActivityFeedback:false, /**
   * @property {array[]} feedbackCategory
   * store feedback category list
   */feedbackCategory:null, // -------------------------------------------------------------------------
// Methods
/**
   * Initialize once Component Initialize
   */initial:function initial(){var controller=this;var type=controller.get('type');var contentCategory=undefined;controller.fetchActivityFeedbackCateory().then(function(){var categoryLists=controller.get('categoryLists');if(type === _gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY){contentCategory = categoryLists.get('offlineActivities');}else if(type === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT){contentCategory = categoryLists.get('externalAssessments');}else {contentCategory = categoryLists.get('externalCollections');}if(contentCategory && contentCategory.length){controller.set('isShowActivityFeedback',true);controller.set('feedbackCategory',contentCategory.sortBy('feedbackTypeId'));controller.set('format',type);}else {controller.set('isShowActivityFeedback',false);controller.set('feedbackCategory',null);}});}, /**
   * @function getFeedbackObject
   * Method to return feedback objective
   */getFeedbackObject:function getFeedbackObject(){var controller=this;var userId=controller.get('session.userId');var role=controller.get('session.role')?controller.get('session.role'):_gooruWebConfigConfig.ROLES.STUDENT;var userCategoryId=_gooruWebConfigConfig.FEEDBACK_USER_CATEGORY['' + role];var userFeedback=_ember['default'].A([]);var categoryLists=controller.get('userCategoryFeedback');var collection=controller.get('collection');categoryLists.map(function(category){var feedbackObj={feeback_category_id:category.categoryId};if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE){feedbackObj.user_feedback_quantitative = category.rating;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.BOTH){feedbackObj.user_feedback_qualitative = category.comments;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUALITATIVE){feedbackObj.user_feedback_qualitative = category.quality;}userFeedback.pushObject(feedbackObj);});var contentType=controller.get('type') === 'null'?controller.get('format'):controller.get('type');var userFeedbackObj={content_id:collection.get('id'),content_type:contentType,user_category_id:userCategoryId,user_feedbacks:userFeedback,user_id:userId};return userFeedbackObj;}, /**
   * Navigate to study player to play next collection/assessment
   */toPlayer:function toPlayer(){var context=this.get('mapLocation.context');var queryParams={role:_gooruWebConfigConfig.ROLES.STUDENT,source:this.get('source'),isIframeMode:this.get('isIframeMode')};var classId=context.get('classId');if(classId){queryParams.classId = classId;}this.transitionToRoute('study-player',context.get('courseId'),{queryParams:queryParams});}, /**
   * @function playNextContent
   * Method to play next content
   */playNextContent:function playNextContent(submittedScoreInPercentage){var controller=this;var navigateMapService=this.get('navigateMapService');var context=this.get('mapLocation.context');context.score = submittedScoreInPercentage;navigateMapService.getStoredNext().then(function(){return navigateMapService.next(context);}).then(function(mapLocation){var status=(mapLocation.get('context.status') || '').toLowerCase();if(status === 'done'){controller.setProperties({isDone:true,hasAnySuggestion:false});}else {controller.toPlayer();}});}});});