define('gooru-web/controllers/reports/student-external-collection',['exports','ember','gooru-web/config/config','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){exports['default'] = _ember['default'].Controller.extend({queryParams:['collectionId','isIframeMode','role','type','score','timespent','isPreviewReferrer'], // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), /**
   * @property {activityFeedbackService}
   */activityFeedbackService:_ember['default'].inject.service('api-sdk/activity-feedback'), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isAnonymous
   */isAnonymous:_ember['default'].computed.alias('session.isAnonymous'), /**
   * @property {Boolean} isShowActivityFeedback
   * Property to evaluate whether the feedback tab should shown
   */isShowActivityFeedback:false, /**
   * @property {array[]} feedbackCategory
   * store feedback category list
   */feedbackCategory:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collection.standards.[]',function(){var standards=this.get('collection.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), // -------------------------------------------------------------------------
// Actions
actions:{closePlayer:function closePlayer(){var controller=this;controller.set('isShowActivityFeedback',false);var isIframeMode=controller.get('isIframeMode');var isPreviewReferrer=controller.get('isPreviewReferrer');var contentType=controller.get('type');var collectionId=controller.get('collectionId');if(isPreviewReferrer && (isPreviewReferrer === true || isPreviewReferrer === 'true')){controller.transitionToRoute(contentType === 'collection-external'?'content.external-collections.edit':'content.external-assessments.edit',collectionId,{queryParams:{isLibraryContent:true,isPreviewReferrer:false,editing:false}});}else {if(isIframeMode){window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}}}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function fetchActivityFeedbackCateory
   * Method to fetch learning activity feedback
   */fetchActivityFeedbackCateory:function fetchActivityFeedbackCateory(){var controller=this;var role=controller.get('session.role');var userCategoryId=_gooruWebConfigConfig.FEEDBACK_USER_CATEGORY['' + role];controller.get('activityFeedbackService').getFeedbackCategory(userCategoryId).then(function(categoryLists){controller.set('categoryLists',categoryLists);var isCollection=controller.get('collection.isCollection');var type=isCollection?_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT;var contentCategory=categoryLists.get(type + 's');if(contentCategory && contentCategory.length){controller.set('isShowActivityFeedback',true);controller.set('feedbackCategory',contentCategory.sortBy('feedbackTypeId'));controller.set('format',type);}else {controller.set('isShowActivityFeedback',false);controller.set('feedbackCategory',null);}});}});});