define('gooru-web/components/proficiency/pull-out-accordion-collection',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:performance
   */performanceService:_ember['default'].inject.service('performance'), // -------------------------------------------------------------------------
// Attributes
classNames:['pull-out-accordion-collection'], // -------------------------------------------------------------------------
// Properties
/**
   * collection
   * @return {Object}
   */collection:null, /**
   * user id
   * @type {String}
   */userId:null, /**
   *  Indicates loading icon enabled or not
   * @type {Boolean}
   */loading:false, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     *
     * Triggered when an menu item is selected
     * @param item
     */selectCompetencyItems:function selectCompetencyItems(collection){var component=this;component.set('loading',true);var userId=component.get('userId');var collectionId=collection.get('id');var sessionId=collection.get('sessionId');var summaryReportPromise=null;if(collection.get('collectionType') === 'assessment'){summaryReportPromise = component.get('performanceService').getUserPerformanceResourceInAssessment(userId,null,null,null,collectionId,sessionId,null);}else {summaryReportPromise = component.get('performanceService').getUserPerformanceResourceInCollection(userId,null,null,null,collectionId,sessionId,null);}_ember['default'].RSVP.hash({resources:summaryReportPromise}).then(function(_ref){var resources=_ref.resources;component.set('loading',false);component.set('resources',resources);component.resetAccordionArrowBasedOnState();});}}, // -------------------------------------------------------------------------
// Methods
resetAccordionArrowBasedOnState:function resetAccordionArrowBasedOnState(){var component=this;var element=component.$('#' + component.get('elementId') + '-heading > .panel-title a i');if(element.hasClass('arrow_drop_up')){element.addClass('arrow_drop_down').removeClass('arrow_drop_up');element.text('arrow_drop_down');}else {element.addClass('arrow_drop_up').removeClass('arrow_drop_down');element.text('arrow_drop_up');}}});});