define('gooru-web/components/reports/assessment/questions/gru-classic',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-classic'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {boolean} showCorrect help to hide/show correct answer to users
   */showCorrect:false,userAnswers:_ember['default'].computed('answerObj',function(){return this.get('answerObj')?this.get('answerObj').map(function(item){return {correctAnswer:item.text?[item.text.slice(1,-1)]:[]};}):[];}),baseAnswers:_ember['default'].computed('question',function(){return this.get('question.answers');}), // -----------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.defaultHightlight();}, // -----------------------------------------------------------------------
// Methods
/**
   * Help to highlight existing answer
   */defaultHightlight:function defaultHightlight(){var component=this;var answers=component.get('baseAnswers');var userAnswers=component.get('userAnswers');var looperObj=component.get('showCorrect')?answers:userAnswers;looperObj.forEach(function(item,index){var answerEl=component.$('.answer-edit-' + index + ' .answer-item-text');var answerText=item.correctAnswer.length?item.correctAnswer[0]:item.text;var replacedText=answerText.replace(/(\[.?\])/gi,function(match){return '<span class="' + (match.length > 2?'active':'') + '">' + (match.length > 2?match[1]:'_') + '</span>';});answerEl.html(component.wrapLetters(replacedText));});}, /**
   * Help to wrap span tag for each letters
   */wrapLetters:function wrapLetters(text){var letterGroups='';var childEl=$('<p>' + text + '</p>')[0].childNodes;childEl.forEach(function(item){if(item.nodeName === '#text'){for(var i=0;i < item.data.length;i++) {letterGroups += '<span>' + item.data[i] + '</span>';}return;}letterGroups += item.outerHTML;});return letterGroups;}});});