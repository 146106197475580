define('gooru-web/components/reports/pull-up/activity/add-question-score',['exports','ember','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['add-data','add-question-score'], /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when add question score
onAddQuestionScore:function onAddQuestionScore(questionScore){var component=this;component.set('question.score',questionScore);component.set('question.isScored',true);}, //Action triggered when toggle question
onToggleQuestion:function onToggleQuestion(seq){var component=this;this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CS_ACTIVITY_REPORT_UPLOAD_DATA_Q_SCORE);if(component.get('question.active')){component.set('question.active',false);}else {component.sendAction('onToggleQuestion',seq);}}, //Action triggered when scroll multi scores
onScrollScore:function onScrollScore(direction){var component=this;var scrollListConatiner=component.$('.scores-list');var currentPos=scrollListConatiner.scrollLeft();var scrollToPos=direction === 'left'?currentPos - 56:currentPos + 56;scrollListConatiner.animate({scrollLeft:scrollToPos},200);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Object} question
   */question:null, /**
   * @property {Array} questionScores
   */questionScores:_ember['default'].computed('question.maxScore',function(){var component=this;var questionMaxScore=component.get('question.maxScore');var questionScores=_ember['default'].A([]);for(var scoreVal=0;scoreVal <= questionMaxScore;scoreVal++) {questionScores.push(scoreVal);}return questionScores;}), /**
   * @property {Boolean} isBooleanScore
   */isBooleanScore:_ember['default'].computed.lte('question.maxScore',1), /**
   * @property {Boolean} isShowScrollableScores
   */isShowScrollableScores:_ember['default'].computed(function(){var component=this;var numberOfScores=component.get('questionScores.length');return numberOfScores > 5;}), /**
   * @property {Boolean} isOverwriteScore
   */isOverwriteScore:false, /**
   * @property {Boolean} disableScoreOverwite
   */disableScoreOverwite:_ember['default'].computed('isOverwriteScore','question.type',function(){var component=this;var isOverwriteScore=component.get('isOverwriteScore');var questionType=component.get('question.type');return isOverwriteScore && questionType === 'OE';})});});