define('gooru-web/components/gru-copy-value',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){ /**
 * Copy value
 *
 * Component responsible for copying a value to the clipboard
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @dependency service:i18n
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
tagName:'span',classNames:['gru-copy-value'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var clipboard=new Clipboard('.copy-btn',{text:function text(){return $('#valueToCopy').val();}});$('.copy-btn').tooltip({placement:'bottom'});clipboard.on('success',function(){$('.copy-btn').tooltip('hide');});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {String} value to be copied
   */value:null, /**
   * @property {String} code with text to be displayed in the tooltip
   */tooltipCode:null, /**
   * Return the respective text to be displayed as a tooltip
   */tooltip:_ember['default'].computed('tooltipCode',function(){if(this.get('tooltipCode')){return this.get('i18n').t(this.get('tooltipCode')).string;}else {return '';}}) // -------------------------------------------------------------------------
// Methods
});});