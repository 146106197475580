define('gooru-web/components/century-skills/gru-century-skills-content',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-century-skills-content'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * select or not the skill item.
     * @function actions:selectSkillItem
     * @param {CenturySkill} skillItem
     */selectSkillItem:function selectSkillItem(skillItem){var component=this;var selectedCenturySkills=component.get('tempSelectedCenturySkills');var skillItemId=skillItem.get('id');if(component.isSelected(skillItemId)){selectedCenturySkills.removeObject(skillItemId);}else {selectedCenturySkills.pushObject(skillItemId);}},saveSelectedSkills:function saveSelectedSkills(){var selectedCenturySkills=this.get('tempSelectedCenturySkills');this.get('onSave')(selectedCenturySkills);}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);var tempSelectedCenturySkills=component.get('selectedCenturySkills').copy();if(tempSelectedCenturySkills){this.set('tempSelectedCenturySkills',tempSelectedCenturySkills);}},willDestroyElement:function willDestroyElement(){this._super.apply(this,arguments);this.set('centurySkills',null);this.set('selectedCenturySkills',null);this.set('tempSelectedCenturySkills',null);}, // -------------------------------------------------------------------------
// Properties
/**
   * List of Century Skills
   * @prop {CenturySkill[]}
   */centurySkills:_ember['default'].A([]), /**
   * List of selected Century Skills
   * @prop {CenturySkill[]}
   */selectedCenturySkills:_ember['default'].A([]), /**
   * @property {centurySkill[]} cognitive group of century skills
   */cognitiveSkillsGroup:_ember['default'].computed('centurySkills.[]','tempSelectedCenturySkills.[]',function(){var component=this;return this.get('centurySkills').filter(function(centurySkill){centurySkill.set('isSelected',component.isSelected(centurySkill.get('id')));return centurySkill.get('group') === _gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_COGNITIVE_SKILLS_AND_STRATEGIES;});}), /**
   * @property {centurySkill[]} content group of century skills
   */contentSkillsGroup:_ember['default'].computed('centurySkills.[]','tempSelectedCenturySkills.[]',function(){var component=this;return this.get('centurySkills').filter(function(centurySkill){centurySkill.set('isSelected',component.isSelected(centurySkill.get('id')));return centurySkill.get('group') === _gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_CONTENT_KNOWLEDGE;});}), /**
   * @property {centurySkill[]} learning group of century skills
   */learningSkillsGroup:_ember['default'].computed('centurySkills.[]','tempSelectedCenturySkills.[]',function(){var component=this;return this.get('centurySkills').filter(function(centurySkill){centurySkill.set('isSelected',component.isSelected(centurySkill.get('id')));return centurySkill.get('group') === _gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_LEARNING_SKILLS_AND_TECHNIQUES;});}), // ----------------------------
// Methods
/**
   * Returns if a skillItem is in the selectedCenturySkills
   * @param {Number} skillItem id
   * @return {Boolean}
   */isSelected:function isSelected(skillItemId){var selectedCenturySkills=this.get('tempSelectedCenturySkills');return selectedCenturySkills.includes(skillItemId);}, /**
   * List of temp selected Century Skills ids
   * @prop {Number[]}
   */tempSelectedCenturySkills:_ember['default'].A([])});});