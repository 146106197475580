define('gooru-web/models/performance/performance-summary',['exports','ember'],function(exports,_ember){ /**
 * Performance summary model
 * Base performance summary model for class, assessment, collection
 *
 * @typedef {Object} PerformanceSummary
 */exports['default'] = _ember['default'].Object.extend({ /**
     * @property {String} id - Gooru id for the goal
     */id:null, /**
     * @property {number} time spent in seconds
     */timeSpent:null, /**
     * @property {number} score
     */score:null});});