define('gooru-web/components/proficiency/inspect/student-class-proficiency',['exports','ember','gooru-web/utils/utils','gooru-web/config/parse-event'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigParseEvent){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['inspect','student-class-proficiency'],parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), /**
   * @property {Array} filteredWeekLocal
   * Property for filtered student list
   */filteredWeekLocal:_ember['default'].A([]), // -------------------------------------------------------------------------
// Actions
actions:{onDownloadCSV:function onDownloadCSV(){window.print();this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_CLASS_PROFICIENCY_DOWNLOAD);},onSearchWeeklyLocal:function onSearchWeeklyLocal(searchWeekLocal){var testing=this.get('filteredWeekLocal');if(!testing.length){this.set('filteredWeekLocal',(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(this.get('studentsDomainPerformance')));}var filteredStudents=(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(this.get('filteredWeekLocal')).filter(function(student){searchWeekLocal = searchWeekLocal.toLowerCase();return student.firstName && student.firstName.toLowerCase().startsWith(searchWeekLocal) || student.lastName && student.lastName.toLowerCase().startsWith(searchWeekLocal);});this.set('studentsDomainPerformance',filteredStudents);}, //Action triggered when select a student card
onSelectStudentCard:function onSelectStudentCard(student){var component=this;component.sendAction('onSelectStudent',student);}}, // -------------------------------------------------------------------------
// Propeties
/**
   * @property {Number} totalCompetencies
   */totalCompetencies:0});});