define('gooru-web/adapters/map/navigate-map',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the navigate map operations
 *
 * @typedef {Object} NavigateMapAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service(),namespace:'/api/navigate-map/v3', /**
   * Calls the next map navigation api
   *
   * @param {*} userId
   * @returns {Promise|Object}
   */next:function next(context){var namespace=this.get('namespace');var url=namespace + '/next';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',headers:this.defineHeaders(),data:JSON.stringify(context)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(resolve,reject);});}, /**
   * Calls the get current map location api
   *
   * @param {string} courseId
   * @param {string} classId
   * @returns {Promise|Object}
   */getCurrentMapContext:function getCurrentMapContext(courseId){var classId=arguments.length <= 1 || arguments[1] === undefined?undefined:arguments[1];var namespace=this.get('namespace');var url=namespace + '/context';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders(),data:{course_id:courseId,class_id:classId}};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(resolve,reject);});}, /**
   * Calls the teacher suggestions navigation api
   *
   * @param {Object} context
   * @returns {Promise|Object}
   */teacherSuggestions:function teacherSuggestions(context){var namespace=this.get('namespace');var url=namespace + '/teacher/suggestions';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(context)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(resolve,reject);});}, /**
   * @function generateStudentRoute help to generate route once the diagnostic completed for the domain
   */generateStudentRoute:function generateStudentRoute(params){var namespace=this.get('namespace');var url=namespace + '/diagnostic/route/check';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});