define('gooru-web/components/content/rubric/gru-rubric-creation',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','rubric','gru-rubric-creation'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {MediaService} Media service API SDK
   */mediaService:_ember['default'].inject.service('api-sdk/media'), // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('resource.url',this.get('rubric.url'));}, // -------------------------------------------------------------------------
// Actions
actions:{ /***
     * Select tab option
     */selectOption:function selectOption(type){ // Clean inputs and properties
var component=this;var tempSavedUrl=this.get('savedUrl');var savedUrl=this.get('rubric.url');this.set('rubric.url',tempSavedUrl || null);this.get('rubric').validate().then(function(_ref){var validations=_ref.validations;component.set('resource.url',validations.get('isValid')?tempSavedUrl:null);});this.set('rubric.uploaded',type === 'fromComputer');this.$('.gru-input.url input').val(tempSavedUrl);this.set('savedUrl',savedUrl);}, /***
     * Select file
     */selectFile:function selectFile(file){var _this=this;this.set('rubric.file',file);if(file){this.handleResourceUpload(this.get('rubric')).then(function(rubric){if(_this.get('rubric.uploaded')){_this.set('rubric',rubric);var resource=_this.get('resource');resource.set('url',rubric.get('url'));_this.set('emptyFileError',false);}else {_this.set('savedUrl',rubric.get('url'));}});}}, /***
     * Add URL
     */addURL:function addURL(url){var _this2=this;this.set('emptyFileError',false);this.get('rubric').validate().then(function(_ref2){var validations=_ref2.validations;if(validations.get('isValid') && !_this2.get('rubric.uploaded')){ // For preview to work
var resource=_this2.get('resource');resource.set('url',url);}});}}, // -------------------------------------------------------------------------
// Properties
/**
   * If the file upload should show an error
   * @property {Boolean}
   */emptyFileError:false, /**
   * Resource used for the preview url component
   * @property {Object}
   */resource:_ember['default'].Object.create({}), /**
   * Rubric being edited
   * @property {Rubric}
   */rubric:null, /**
   * Input value from URL tab
   * @property {String}
   */savedUrl:'', /**
   * @type {String} list of all valid extension per gooru-web/config/config#UPLOAD_TYPES
   */validExtensions:_ember['default'].computed(function(){var extensions=_gooruWebConfigConfig.UPLOADABLE_TYPES.map(function(typeObject){return typeObject.validExtensions;});return extensions.join(' ');}), // -------------------------------------------------------------------------
// Methods
/**
   * Create a resource (url/upload)
   * @param {Resource}
   * @return {Promise.<Resource>}
   */handleResourceUpload:function handleResourceUpload(rubric){var _this3=this;return new _ember['default'].RSVP.Promise(function(resolve){_this3.get('mediaService').uploadContentFile(rubric.file).then(function(filename){rubric.set('url',filename);resolve(rubric);});});},didRender:function didRender(){var $fileInput=this.$('input[type="file"]');if($fileInput){for(var i=0;i < $fileInput.length;i++) {$fileInput[i].title = $fileInput[i].id;}}}});});