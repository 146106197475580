define('gooru-web/components/reports/pull-up/student-activity-report',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['report','student-activity-report'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.openPullUp();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on close pullup
onClosePullUp:function onClosePullUp(isCloseAll){var component=this;component.closePullUp(isCloseAll);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} showPullUp
   */showPullUp:false, /**
   * @property {Object} reportPeriod
   */reportPeriod:null, /**
   * @property {String} title
   */title:'', //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);}, /**
   * Method to close pullup
   */closePullUp:function closePullUp(isCloseAll){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);if(isCloseAll){component.sendAction('onClosePullUp');}});}});});