define('gooru-web/models/content/answer',['exports','ember','ember-cp-validations','gooru-web/models/resource/answer'],function(exports,_ember,_emberCpValidations,_gooruWebModelsResourceAnswer){var Validations=(0,_emberCpValidations.buildValidations)({text:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:_ember['default'].computed('model.isReadingType',function(){return this.get('model.isReadingType')?'common.errors.add-question-reading-text':'common.errors.add-question-answer-text';}),disabled:_ember['default'].computed('model',function(){return !!this.get('model.leftValueFormat') || !!this.get('model.rightValueFormat');})}),(0,_emberCpValidations.validator)('hot-text-highlight',{answerNotSelectedKey:'common.errors.highlight-text-not-selected',wrongFormatKey:'common.errors.highlight-text-wrong-format'})]},leftValue:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,disabled:_ember['default'].computed('model.leftValueFormat',function(){return !this.get('model.leftValueFormat');})})]},rightValue:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,disabled:_ember['default'].computed('model.rightValueFormat',function(){return !this.get('model.rightValueFormat');})})]},scalePointLabels:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,disabled:_ember['default'].computed('model.scalePointLabels',function(){return !this.get('model.scalePointLabels') || !this.get('model.scalePointLabels').length;})})]},items:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,disabled:_ember['default'].computed('model.items',function(){return !this.get('model.items') || !this.get('model.items').length;})})]}}); /**
 * Answer model
 * typedef {Object} Answer
 */var Answer=_ember['default'].Object.extend(Validations,{ /**
   * @property {Number} sequence - The order sequence of the answer
   */sequence:0, /**
   * @property {Boolean} isCorrect - Indicates if the answer is correct or not
   */isCorrect:false, /**
   * @property {String} text - The answer text
   */text:null, /**
   * @property {String} type - The answer type
   */type:null, /**
   * @property {String} highlightType - The highlight type for hot text highlight answers
   */highlightType:null,correctAnswers:_ember['default'].A([]), /**
   * @property {Array} correctAnswer - The correctAnswer type for SERP answers
   */correctAnswer:[], /**
   * @property {Array} baseWords - The correctAnswer type for SERP answers
   */baseWords:[],answer_text:'',correct_answer:'',answer_category:'',answer_type:'',struggles:null,isReadingType:false,answersList:_ember['default'].A([]),textImage:null,additionalLetters:_ember['default'].A([]),scalePoint:null,scalePointLabels:_ember['default'].A([]),items:_ember['default'].A([]),uiDisplayGuide:null, /**
   * Return a copy of the answer
   *
   * @function
   * @return {Answer}
   */copy:function copy(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key));if(value === 'string' || value === 'number' || value === 'boolean' || value === 'array' || value === 'instance' || value === 'object'){properties.push(key);}} // Copy the question data
properties = this.getProperties(properties);return Answer.create(_ember['default'].getOwner(this).ownerInjection(),properties);}, /**
   * Returns a player answer object.
   * @returns {Answer}
   */toPlayerAnswer:function toPlayerAnswer(){var answer=this;return _gooruWebModelsResourceAnswer['default'].create({id:answer.get('id'),text:answer.get('text'),answerType:answer.get('type'),order:answer.get('sequence'),isCorrect:answer.get('isCorrect'),highlightType:answer.get('highlightType'),correctAnswer:answer.get('correctAnswer'),baseWords:answer.get('baseWords'),groupOne:answer.get('groupOne'),groupTwo:answer.get('groupTwo'),additionalLetters:answer.get('additionalLetters'),scalePoint:answer.get('scalePoint'),scalePointLabels:answer.get('scalePointLabels'),items:answer.get('items'),uiDisplayGuide:answer.get('uiDisplayGuide'),leftValue:answer.get('leftValue'),rightValue:answer.get('rightValue'),leftValueFormat:answer.get('leftValueFormat'),rightValueFormat:answer.get('rightValueFormat'),rightValShuffleOrder:answer.get('rightValShuffleOrder')});}});exports['default'] = Answer;});