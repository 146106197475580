define('gooru-web/components/reports/class-assessment/gru-questions-detail',['exports','ember'],function(exports,_ember){ /**
 * Questions summary component
 *
 * Component responsible for laying out the question details for the class assessment report
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['reports','class-assessment','gru-questions-detail'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * When clicking at any navigation bubble
     * @param bubbleOption
     */bubbleSelect:function bubbleSelect(bubbleOption){this.set('selectedQuestion',bubbleOption.get('value'));}, /**
     * Show performance results
     */showResult:function showResult(){if(this.get('anonymous')){this.set('showResult',!this.get('showResult'));}}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Component model, this was necessary because this component is rendered in a modal
   * @see gru-modal.js
   */model:null, /**
   * @property {Collection} assessment
   */assessment:_ember['default'].computed.alias('model.assessment'), /**
   * @prop { ReportData } reportData - Representation of the data to show in the reports as a 3D matrix
   * Any changes on the content feed will cause the report data to update
   */reportData:_ember['default'].computed.alias('model.reportData'), /**
   * Indicates if the report is displayed in anonymous mode
   * @property {boolean} anonymous
   */anonymous:_ember['default'].computed.alias('model.anonymous'), /**
   * Indicates when the report is display in anonymous mode if show all performance results
   * @property {boolean} showResult
   */showResult:false, /**
   * @prop { User[] } students - Group of students taking an assessment
   */students:_ember['default'].computed.alias('model.students'), /**
   * Returns a convenience structure to display the question navigation bubbles
   * @returns {Array}
   */questionsNavOptions:_ember['default'].computed('assessment.resources.[]',function(){var questions=this.get('assessment.resources');var selectedQuestion=this.get('selectedQuestion');return questions.map(function(question,index){return _ember['default'].Object.create({label:index + 1,status:null, //no status needed
value:question,selected:selectedQuestion && selectedQuestion.get('id') === question.get('id')});});}), /**
   * @property {Resource} selected question
   */selectedQuestion:_ember['default'].computed.alias('model.selectedQuestion') // -------------------------------------------------------------------------
// Methods
});});