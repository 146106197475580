define('gooru-web/controllers/reset-password',['exports','ember','gooru-web/models/profile/profile','gooru-web/validations/reset-password','gooru-web/config/environment','gooru-web/models/forgot-password'],function(exports,_ember,_gooruWebModelsProfileProfile,_gooruWebValidationsResetPassword,_gooruWebConfigEnvironment,_gooruWebModelsForgotPassword){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// Actions
actions:{resetPassword:function resetPassword(){var controller=this;var profile=controller.get('profile');var token=controller.get('token');if(controller.get('didValidate') === false){var password=_ember['default'].$('.gru-input.password input').val();var confirmPassword=_ember['default'].$('.gru-input.rePassword input').val();profile.set('password',password);profile.set('rePassword',confirmPassword);}profile.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){controller.get('profileService').resetPassword(profile.get('password'),token).then(function(){controller.set('didValidate',true);controller.set('showSecondStep',true);controller.set('isLinkExpired',false);},function(error){controller.set('isLinkExpired',true);_ember['default'].Logger.error(error);});}});},redirectToSignIn:function redirectToSignIn(){this.transitionToRoute('sign-in');},redirectToLogin:function redirectToLogin(){this.transitionToRoute('login');},togglePasswordVisibility:function togglePasswordVisibility(){this.toggleProperty('isPasswordVisible');},toggleRePasswordVisibility:function toggleRePasswordVisibility(){this.toggleProperty('isRePasswordVisible');},handlePasswordInput:function handlePasswordInput(event){this.set('passwordValue',event.target.value);if(this.get('isPasswordVisible')){this.set('isPasswordVisible',false);}},sendEmail:function sendEmail(){var controller=this;var userId=controller.get('userId');controller.get('profileService').forgotResendPassword(userId).then(function(){this.transitionToRoute('login');},function(error){controller.set('emailError',error.email_id);controller.keydownEvents();});}}, // -------------------------------------------------------------------------
// Methods
/**
   * init and reset all the properties for the validations
   */resetProperties:function resetProperties(){var controller=this;var url=window.document.URL;var urlParams=new URLSearchParams(url);var userId=urlParams.get('user_id');if(userId){controller.get('profileService').readUserProfile(userId).then(function(response){ // Check if response contains user profile data
if(response){ // Update controller properties with profile data
controller.set('firstName',response.firstName);controller.set('lastName',response.lastName);} // Create a new Profile instance with reset password validations
var resetPasswordProfile=_gooruWebModelsProfileProfile['default'].extend(_gooruWebValidationsResetPassword['default']);var profile=resetPasswordProfile.create(_ember['default'].getOwner(controller).ownerInjection(),{password:null,rePassword:null,firstName:controller.get('firstName'),lastName:controller.get('lastName')}); // Create a new User instance
var user=_gooruWebModelsForgotPassword['default'].create(_ember['default'].getOwner(controller).ownerInjection(),{email:null}); // Set controller properties
controller.set('profile',profile);controller.set('showSecondStep',false);controller.set('isLinkExpired',false);controller.set('user',user);})['catch'](function(error){ // Handle error if user profile data cannot be fetched
_ember['default'].Logger.error(error,'Error fetching user profile:');});}}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {String} Error with email field
   */emailError:false, /**
   * @type {User} user
   */user:null, /**
   * @type {Profile} profile
   */profile:null, /**
   * Token from the forgot password flow
   * @property {String}
   */token:null, /**
   * User id from the forgot password flow
   * @property {String}
   */userId:null, /**
   * User id from the forgot password flow
   * @property {String}
   */firstName:null, /**
   * User id from the forgot password flow
   * @property {String}
   */lastName:null, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false, /**
   * @param {Boolean } showSecondStep - value used to check if Second Step is showing or not
   */showSecondStep:false, /**
   * @param {Boolean } isPasswordVisible - value used to check if Second Step is showing or not
   */isPasswordVisible:false, /**
   * @param {Boolean } isRePasswordVisible - value used to check if Second Step is showing or not
   */isRePasswordVisible:false, /**
   * @param {Boolean } isLinkExpired - value used to check if input has been expired or not
   */isLinkExpired:false, /**
   * Marketing site url
   * @property {string}
   */isGoogleAccountError:_ember['default'].computed('user.validations.attrs.email.isValid',function(){var valid=this.get('user.validations.attrs.email.isValid');var message=this.get('user.validations.attrs.email.error.message');return !valid && message && message.indexOf('Google') >= 0;}),marketingSiteUrl:_ember['default'].computed(function(){return _gooruWebConfigEnvironment['default'].marketingSiteUrl;})});});