define('gooru-web/components/content/modals/gru-assessment-confirmation',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-assessment-confirmation'],classNameBindings:['component-class'], // -------------------------------------------------------------------------
// Actions
actions:{start:function start(){this.get('model').onStart();this.triggerAction({action:'closeModal'});},cancel:function cancel(){this.get('model').onCancel();}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {boolean} flag for determining button and the formatted result behaviour
   */noAttempts:_ember['default'].computed.equal('model.attempts',0), /**
   * @property {boolean} flag for determining unlimited behaviour
   */unlimited:_ember['default'].computed.equal('model.attempts',-1), /**
    * @property {boolean} flag for determining button behaviour
    */disableStart:_ember['default'].computed('unlimited','noAttempts',function(){return !this.get('unlimited') && this.get('noAttempts');})});});