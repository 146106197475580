define('gooru-web/services/api-sdk/collaborator',['exports','ember','gooru-web/adapters/collaborator/collaborator'],function(exports,_ember,_gooruWebAdaptersCollaboratorCollaborator){exports['default'] = _ember['default'].Service.extend({init:function init(){this._super.apply(this,arguments);this.set('collaboratorAdapter',_gooruWebAdaptersCollaboratorCollaborator['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Updates collaborators for courses, collections, assessments
   * @param {string} id entity id
   * @param {string} type could be courses/collections/assessments
   * @param {number[]} userIds
   * @returns {Promise.<boolean>}
   */updateCollaborators:function updateCollaborators(id,type,userIds){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collaboratorAdapter').updateCollaborators(id,type,userIds).then(function(){return resolve(true);},reject);});}, /**
   * Updates collaborators for courses
   * @param {string} id course id
   * @param {number[]} userIds
   * @returns {Promise.<boolean>}
   */updateCourseCollaborators:function updateCourseCollaborators(id,userIds){return this.updateCollaborators(id,'courses',userIds);}, /**
   * Updates collaborators for collections
   * @param {string} id collection id
   * @param {number[]} userIds
   * @returns {Promise.<boolean>}
   */updateCollectionCollaborators:function updateCollectionCollaborators(id,userIds){return this.updateCollaborators(id,'collections',userIds);}, /**
   * Updates collaborators for assessments
   * @param {string} id course id
   * @param {number[]} userIds
   * @returns {Promise.<boolean>}
   */updateAssessmentCollaborators:function updateAssessmentCollaborators(id,userIds){return this.updateCollaborators(id,'assessments',userIds);}});});