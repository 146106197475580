define('gooru-web/components/content/questions/answers/gru-reorder',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){ /**
 *
 * Component for building (i.e. adding/removing answers) of a (drag/drop) re-order question
 *
 * @module
 * @augments Ember/Component
 *
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-reorder'], // -------------------------------------------------------------------------
// Actions
actions:{ //Add new answer choice
addNewChoice:function addNewChoice(){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,isCorrect:true,type:'text'});this.get('answers').pushObject(newChoice);}, // Remove existing answer
removeChoice:function removeChoice(answer){this.get('answers').removeObject(answer);}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Question answers
   */answers:null, /**
   * Max number of answers
   */maxAnswers:10, /**
   * @property {boolean}
   */disableEditorButtons:_ember['default'].computed.not('showAdvancedEditor'), /**
   * Max number of answers
   * */hasReachedAnswersLimit:_ember['default'].computed('answers.[]',function(){return this.get('answers').length >= this.get('maxAnswers');}) // -------------------------------------------------------------------------
// Method
});});