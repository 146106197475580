define('gooru-web/components/charts/proficiency-view',['exports','ember','d3'],function(exports,_ember,_d3){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['proficiency-view'], /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('taxonomy'), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyProvider:_ember['default'].inject.service('api-sdk/taxonomy'),competencyService:_ember['default'].inject.service('api-sdk/competency'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.loadDomainTopicSummary();}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Number} maxNumberOfCompetencies
   */maxNumberOfCompetencies:_ember['default'].computed('domainTopicSummary',function(){return Math.max.apply(Math,this.get('domainTopicSummary').map(function(domain){return domain.totalCompetencies;}));}), /**
   * @property {Number} chartHeight
   */chartHeight:150, /**
   * @property {Number} cellWidth
   */cellWidth:15, /**
   * @property {Number} cellHeight
   */cellHeight:_ember['default'].computed('maxNumberOfCompetencies',function(){var component=this;var maxNumberOfCompetencies=component.get('maxNumberOfCompetencies');var chartHeight=component.get('chartHeight');return chartHeight / maxNumberOfCompetencies;}),domainTopicSummary:_ember['default'].A([]),skylinePoints:_ember['default'].A([]),isLoading:false,gradeBoundaryPoints:_ember['default'].A([]),showHighline:false, // -------------------------------------------------------------------------
// Methods
loadDomainTopicSummary:function loadDomainTopicSummary(){var component=this;component.set('isLoading',true);if(!component.isDestroyed){component.drawChart();component.set('isLoading',false);}},drawChart:function drawChart(){var component=this;var domainDataSet=component.get('domainDataSet');var cellWidth=component.get('cellWidth');var chartWidth=domainDataSet.length * cellWidth;var chartHeight=component.get('chartHeight');var svg=_d3['default'].select('.chart.proficiency-view-' + component.get('studentSeq')).append('svg').attr('width',chartWidth).attr('height',chartHeight);var domainGroup=svg.append('g').attr('id','chart-container-' + component.get('studentSeq'));domainDataSet.map(function(domain){component.drawDomainVerticalChart(domain,domainGroup);});component.drawSkyline();if(component.get('showHighline')){component.drawGradeBoundaryLine();}}, /**
   * @function drawDomainVerticalChart
   * Method to draw domain vertical chart
   */drawDomainVerticalChart:function drawDomainVerticalChart(dataSet,domainGroup){var component=this;var domainSeq=dataSet.domainSeq;var cellWidth=component.get('cellWidth');var cellHeight=component.get('cellHeight');var competencySeq=-1;var xSeq=(domainSeq - 1) * cellWidth;var competencies=new Array(dataSet.get('total')).fill({});var cells=domainGroup.selectAll('.competency').data(competencies);cells.enter().append('rect').attr('class',function(competency,index){var curPos=index + 1;var statusNumber=curPos <= dataSet.get('mastered')?'4':curPos <= dataSet.get('mastered') + dataSet.get('in-progress')?'1':'0';return 'competency-cell competency-' + (index + 1) + '\n           fill-' + statusNumber + ' ';}).attr('width',cellWidth).attr('height',cellHeight).attr('x',xSeq).attr('y',function(){competencySeq++;return competencySeq * cellHeight;});cells.exit().remove();}, /**
   * @function drawSkyline
   * Method to draw skyline over the competency cell
   */drawSkyline:function drawSkyline(){var component=this;var cellWidth=component.get('cellWidth');var cellHeight=component.get('cellHeight');component.$('#skyline-group').remove();var skylineGroup=_d3['default'].select('.chart.proficiency-view-' + component.get('studentSeq') + ' svg').append('g').attr('id','skyline-group');var curBarPos=0;var points='';this.get('domainDataSet').forEach(function(domain){var x1=curBarPos * cellWidth;var x2=x1 + cellWidth;var y1=domain.get('mastered') * cellHeight;var y2=y1;points += ' ' + (x1 + ',' + y1) + ' ' + (x2 + ',' + y2);curBarPos++;});skylineGroup.append('polyline').attr('points',points).attr('class','skyline');},drawGradeBoundaryLine:function drawGradeBoundaryLine(){var _this=this;var component=this;var gradeBoundaryPoints=component.get('gradeBoundaryPoints');var curBarPos=0; // let strokeDash = 0;
_d3['default'].select('#gradeline-group').remove();var gradeContainer=_d3['default'].select('.chart.proficiency-view-' + component.get('studentSeq') + ' svg').append('g').attr('id','gradeline-group');var points='';gradeBoundaryPoints.forEach(function(domain){if(domain.get('isExpanded')){ // Get topic bar competency pos
domain.topics.map(function(topic){var x1=curBarPos * _this.cellWidth;var x2=x1 + _this.cellWidth;var y1=topic.hiLineCompSeq * _this.expandedCellHeight;var y2=y1;points += ' ' + (x1 + ',' + y1) + ' ' + (x2 + ',' + y2);curBarPos++; // strokeDash += x1 === x2 ? Math.max(y1, y2) : Math.max(x1, x2);
});}else {var x1=curBarPos * _this.cellWidth;var x2=x1 + _this.cellWidth;var y1=domain.hiLineCompSeq * _this.get('cellHeight');var y2=y1; // strokeDash += Math.max(x1, y1, x2, y2);
points += ' ' + (x1 + ',' + y1) + ' ' + (x2 + ',' + y2);curBarPos++;}});gradeContainer.append('polyline').attr('points',points).attr('class','grade-line');}});});