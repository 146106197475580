define('gooru-web/utils/dropdown-item',['exports','ember'],function(exports,_ember){ /**
 * @typedef {object} DropdownItem
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string} identifier
   */id:null, /**
   * @property {string} label
   */label:null, /**
   * @property {bool} disabled
   */disabled:false, /**
   * @property {bool} selected
   */selected:false, /**
   * @property {object} data
   */data:null});});