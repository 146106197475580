define('gooru-web/controllers/login-tenant-url',['exports','ember','gooru-web/models/login-tenant-url','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebModelsLoginTenantUrl,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Session
   */session:_ember['default'].inject.service(), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Session service
   */signInService:_ember['default'].inject.service('sign-in'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when click on submit button
     */tenantLogin:function tenantLogin(){var controller=this;var tenantUrl=controller.get('tenantUrl');if(controller.get('didValidate') === false){var url=_ember['default'].$('.gru-input.url input').val();tenantUrl.set('url',url);}tenantUrl.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){var _url=controller.get('configuration.TENANT_URL');var _tenantUrl=controller.get('tenantUrl');controller.set('didValidate',true);window.location.href = _url + '/' + _tenantUrl.url;}});}, /**
     * Action triggered when click on back arrow
     */goBack:function goBack(){window.history.back();}}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {User} user
   */tenantUrl:null, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false, /**
   * @type {string} domainName
   */domainName:_ember['default'].computed.alias('configuration.TENANT_DOMAIN_NAME'),tabIndex:0, // -------------------------------------------------------------------------
// Methods
/**
   * init and reset all the properties for the validations
   */resetProperties:function resetProperties(){var controller=this;var tenantUrl=_gooruWebModelsLoginTenantUrl['default'].create(_ember['default'].getOwner(this).ownerInjection(),{url:null});controller.set('tenantUrl',tenantUrl);controller.set('didValidate',false);}});});