define('gooru-web/components/reports/pull-up/competency-content-report',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['competency-content-report'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/competency
   */competencyService:_ember['default'].inject.service('api-sdk/competency'), /**
   * @requires service:api-sdk/search
   */searchService:_ember['default'].inject.service('api-sdk/search'), /**
   * @requires service:api-sdk/search
   */suggestService:_ember['default'].inject.service('api-sdk/suggest'), /**
   * Session object of logged in user
   * @type {Object}
   */session:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.set('learningMapData',null);component.loadCompetencyPerformanceData();}, // -------------------------------------------------------------------------
// Actions
actions:{onConfirmSuggest:function onConfirmSuggest(collection,collectionType,competencyCode){var component=this;var studentList=component.get('studentListForSuggestion');if(studentList && studentList.length){studentList.map(function(student){component.suggestContent(student.userId,collection,collectionType,competencyCode);});}else {var userId=component.get('userId');component.suggestContent(userId,collection,collectionType,competencyCode);}},playContent:function playContent(queryParams,contentId,content){var component=this;component.sendAction('playContent',queryParams,contentId,content);}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadCompetencyPerformanceData
   * Method to load individual competency performance of the user
   */loadCompetencyPerformanceData:function loadCompetencyPerformanceData(){var component=this;var userId=component.get('userId');if(userId){return _ember['default'].RSVP.hash({collectionPerformances:component.fetchUserCompetencyPerformance()}).then(function(_ref){var collectionPerformances=_ref.collectionPerformances;component.set('collectionPerformances',collectionPerformances);});}}, /**
   * @function fetchUserCompetencyPerformance
   * Method to fetch competency performance of an user
   */fetchUserCompetencyPerformance:function fetchUserCompetencyPerformance(){var component=this;var userId=component.get('userId');var competencyService=component.get('competencyService');var competencyCode=component.get('competency.competencyCode');return new _ember['default'].RSVP.resolve(competencyService.getUserPerformanceCompetencyCollections(userId,competencyCode));},suggestContent:function suggestContent(userId,collection,collectionType,competencyCode){var component=this;var contextParams={user_id:userId,class_id:component.get('classId'),suggested_content_id:collection.get('id'),suggestion_origin:'teacher',suggestion_originator_id:component.get('session.userId'),suggestion_criteria:'performance',suggested_content_type:collectionType,suggested_to:'student',suggestion_area:'proficiency',tx_code:competencyCode,tx_code_type:'competency'};component.get('suggestService').suggestContent(contextParams);}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Array} collectionPerformances
   */collectionPerformances:_ember['default'].A([]), /**
   * @property {Boolean} isLoading
   */isLoading:false, /**
   * @property {Boolean} isStudent
   */isStudent:false, /**
   * @property {String} classId
   */classId:_ember['default'].computed.alias('class.id'), /**
   * @property {Boolean} showSignatureAssessment
   */showSignatureAssessment:_ember['default'].computed('competency',function(){var component=this;return component.get('competency.showSignatureAssessment');}),onCompetencyChange:_ember['default'].observer('competency',function(){var component=this;component.set('learningMapData',null);var showSignatureAssessment=component.get('competency.showSignatureAssessment');component.set('showSignatureAssessment',showSignatureAssessment);component.loadCompetencyPerformanceData();})});});