define('gooru-web/components/grading/gru-student-roster',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['grading','gru-student-roster'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Select student
     */selectStudent:function selectStudent(student){this.sendAction('onChangeUser',student.get('id'));}, /**
     * Close student roster
     */close:function close(){this.sendAction('onClose');}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Student} Current user on review
   */currentUser:null, /**
   * @property {Boolean} If grading player is showing a full rubric
   * @see controllers/grading-player
   */fullRubric:false, /**
   * @property {[]} List of student
   */students:null, // -------------------------------------------------------------------------
// Observers
closeStudentRoster:_ember['default'].observer('fullRubric',function(){if(this.get('fullRubric')){this.sendAction('onClose');}})});});