define('gooru-web/serializers/performance/course-competency-completion',['exports','ember','gooru-web/models/performance/course-competency-completion'],function(exports,_ember,_gooruWebModelsPerformanceCourseCompetencyCompletion){ /**
 * normalizeCourseCompetencyCompletion Serializer Methods for course competency completion
 * @property {Object}
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Normalize an array of CourseCompetencyCompletion
   *
   * @param {Json} Response data from REST API request
   * @returns {CourseCompetencyCompletion[]}
   */normalizeAllCourseCompetencyCompletion:function normalizeAllCourseCompetencyCompletion(data){var serializer=this;if(data && _ember['default'].isArray(data.usageData)){return data.usageData.map(function(courseCompetencyCompletion){return serializer.normalizeCourseCompetencyCompletion(courseCompetencyCompletion);});}else {return [];}}, /**
   * Normalize the course competency completion data to it's model
   * @param  {Json}  Json response data for course competency completion
   * @return {Object} Create model object for course competency completion
   */normalizeCourseCompetencyCompletion:function normalizeCourseCompetencyCompletion(data){return _gooruWebModelsPerformanceCourseCompetencyCompletion['default'].create(data);}});});