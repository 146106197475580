define('gooru-web/components/reports/assessment/gru-questions',['exports','ember','gooru-web/mixins/configuration','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Selects Performance Option or not
     * @function actions:selectPerformanceOption
     */selectPerformanceOption:function selectPerformanceOption(showPerformance){if(this.get('isTeacher') || !this.get('isAnswerKeyHidden')){this.set('showPerformance',showPerformance);}}, /**
     * View Open Ended report
     * @param questionId {String}
     */viewOEReport:function viewOEReport(questionId){this.sendAction('onViewOEReport',questionId);}, /**
     * Action get triggered when change score button  clicked
     */onChangeScore:function onChangeScore(){this.get('listOfQuestions').clear();this.set('isChangeScoreEnabled',true);},onShowMore:function onShowMore(){this.toggleProperty('isExpanded');}, /**
     * Action get triggered when change score confirm button clicked
     */onChangeScoreConfirm:function onChangeScoreConfirm(){var questionScoreUpdateData=this.get('listOfQuestions');if(questionScoreUpdateData.length > 0){this.sendAction('onUpdateQuestionScore',questionScoreUpdateData);}else {this.set('isChangeScoreEnabled',false);}}, /**
     * Action get triggered when change score was cancelled
     */onChangeScoreNotConfirm:function onChangeScoreNotConfirm(){this.get('listOfQuestions').clear();this.set('isChangeScoreEnabled',false);}, /**
     * It will maintain the list of questions  which need to be update the score.
     * @param  {Boolean} status
     * @param  {Object} item   Question Ember object
     */changeQuestionScore:function changeQuestionScore(status,item){var listOfQuestions=this.get('listOfQuestions');var question=listOfQuestions.findBy('resource_id',item.resourceId);if(question){question.set('resource_attempt_status',status?'correct':'incorrect');}else {question = _ember['default'].Object.create({resource_id:item.resourceId,resource_attempt_status:status?'correct':'incorrect'});listOfQuestions.pushObject(question);}},onShowPullUp:function onShowPullUp(file){this.set('activeFile',file);this.set('isShowFilePullUp',true);},onClosePullup:function onClosePullup(){this.set('activeFile',null);this.set('isShowFilePullUp',false);}}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:session
   */session:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','gru-questions'],classNameBindings:['isAnswerKeyHidden:key-hidden','showPerformance:performance-view'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {boolean} isAnswerKeyHidden - Should the answer key be hidden?
   */isAnswerKeyHidden:false, /**
   * List of questions to be displayed by the component
   *
   * @constant {Array}
   */results:null, /**
   * Indicate if the table show the performance columns
   *
   * @property {Boolean}
   */showPerformance:true,isShowFilePullUp:false,scoreExpert:_ember['default'].computed('content.score',function(){var contentScore=this.get('content.score') || 0;var totalScore=0;if(contentScore < 50){totalScore = 0;}else if(contentScore > 50 && contentScore < 100){totalScore = 1;}else {totalScore = contentScore / 100;}return totalScore;}),hasMoreText:_ember['default'].computed('content.resource.text',function(){var questionText=this.get('content.resource.text');return questionText && questionText.length > 100 && this.get('enableMorebtn');}),activeFile:_ember['default'].A(),answerHead:_ember['default'].computed('i18n',function(){var labelText=this.get('i18n').t(_gooruWebConfigConfig.ANSWER_HEAD[this.get('content.resource.type')]).string;return labelText?labelText:this.get('i18n').t('report.answer-label').string;}), /**
   * @property {boolean}
   * Property to check whether rendering question is SERP or not
   */isSerp:_ember['default'].computed('content.resource',function(){return this.get('content.resource.type')?this.get('content.resource.type').includes('SERP'):false;}), /**
   * Indicates if the reaction bar is visible
   * @property {boolean}
   */showReactionBar:true, /**
   * Indicates if the view is open ended
   * @property {boolean}
   */isOpenEnded:_ember['default'].computed('viewMode',function(){return this.get('viewMode') === 'open-ended';}), /**
   * Indicates change score button got enabled.
   * @property {boolean}
   */isChangeScoreEnabled:false, /**
   * Update question score list
   * @return {Array} list of question scores need to be update.
   */listOfQuestions:_ember['default'].A(),enableMorebtn:false,isExpanded:false, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */taxonomyTags:_ember['default'].computed('content.resource.standards',function(){var standards=this.get('content.resource.standards');if(standards && standards.length){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}),hideAnswerStatus:_ember['default'].computed('content.resource',function(){if(this.get('content.resource')){var resourceItem=this.get('content.resource');return !(resourceItem.get('isOpenEnded') || resourceItem.get('isScientificFreeRes') || resourceItem.get('isScientificFIB') || resourceItem.get('isSerpWPM'));}}),scoreEvaluate:_ember['default'].computed('content',function(){var score=this.get('content.score');if(score || score === 0){if(score === 100){return _gooruWebConfigConfig.ANSWER_SCORE_TYPE_ENUM.correct;}else if(score === 0){return _gooruWebConfigConfig.ANSWER_SCORE_TYPE_ENUM.incorrect;}else {return _gooruWebConfigConfig.ANSWER_SCORE_TYPE_ENUM.partiallyCorrect;}}else {return 'not-started';}}),didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}});});