define('gooru-web/mixins/quizzes/context',['exports','ember','quizzes-addon/models/context/context','gooru-web/config/config'],function(exports,_ember,_quizzesAddonModelsContextContext,_gooruWebConfigConfig){ /**
 * Convenience mixin for accesing the quizzes context service
 *
 * @typedef {Object} ConfigurationMixin
 */exports['default'] = _ember['default'].Mixin.create({ /**
   * @property {Ember.Service} Service to create/get quizzes contexts
   */quizzesContextService:_ember['default'].inject.service('quizzes/context'), /**
   * Create or get a quizzes context
   * @param {object} params All route params
   * @param {Collection} collection
   * @param {boolean} hasContext if class id and context mapping should be added
   */createContext:function createContext(params,collection,hasContext){var context=_quizzesAddonModelsContextContext['default'].create({collectionId:collection.get('id'),title:collection.get('title'),isCollection:collection.get('isCollection')});if(hasContext){var contextInfo=null;var version=undefined;if(params.caContentId){contextInfo = btoa(JSON.stringify({dcaContentId:params.caContentId}));}if(params.source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS){version = '1';}context.setProperties({classId:params.classId,contextMapping:{courseId:params.courseId || collection.get('courseId'),unitId:params.unitId || collection.get('unitId'),milestoneId:params.milestoneId || collection.get('milestoneId'),lessonId:params.lessonId || collection.get('lessonId'),eventSource:collection && collection.enableDiagnostic?'ca-diagnostic':params.source,version:version,contextInfo:contextInfo}});}return this.get('quizzesContextService').createContext(context);},loadReportView:function loadReportView(params,collection){var mixin=this;return mixin.createContext(params,collection,true).then(function(_ref){var id=_ref.id;var contextId=id;var classId=params.classId;var milestoneId=params.milestoneId;var queryParams={courseId:params.courseId,collectionId:params.collectionId,type:params.type,role:params.role,lessonId:params.lessonId,unitId:params.unitId,contextId:contextId,source:params.source,minScore:params.minScore,pathType:params.pathType || 'null',ctxPathType:params.ctxPathType || 'null',isIframeMode:params.isIframeMode};if(classId){queryParams.classId = classId;}if(milestoneId){queryParams.milestoneId = milestoneId;}return queryParams;});}});});