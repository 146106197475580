define('gooru-web/components/proficiency/inspect/student-standard-list-pull-up',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------
// Attributes
classNames:['student-standard-list-pull-up'], // ------------------------------------------------------------------
// Dependencies
/**
   * @requires competencyService
   */competencyService:_ember['default'].inject.service('api-sdk/competency'), /**
   * Class Activities Service
   */classActivitiesService:_ember['default'].inject.service('api-sdk/class-activity'), /**
   * @property {service} suggestService
   */suggestService:_ember['default'].inject.service('api-sdk/suggest'),session:_ember['default'].inject.service(), /**
   * @property {service} searchService
   */searchService:_ember['default'].inject.service('api-sdk/search'), // ------------------------------------------------------------------
// Properties
showCompetencyInfo:false,selectedCompetency:null,'class':null,activeCompetency:null,isLoading:false,totalSuggestions:_ember['default'].computed('activeCompetency',function(){var students=this.get('activeCompetency.students');return students?students.reduce(function(a,b){return a + b.suggestions;},0):0;}),showSuggestConfirmation:false,suggestedCollection:null,suggestedCollectionType:null,userId:_ember['default'].computed.alias('session.userId'),suggestions:_ember['default'].A(),selectedStudent:null,watchSelectedCompetency:_ember['default'].observer('selectedCompetency',function(){if(this.get('selectedCompetency')){this.fetchUsersListByCompetency();this.fetchLearningMapsContent();}}),filteredWeekLocal:_ember['default'].A([]), /**
   * @property {String} previewContentType
   * property hold the content type of the preview
   */previewContentType:null, /**
   * @property {Array} previewContent
   * property hold the content type of the preview
   */previewContent:null, /**
   * @property {Boolean} isShowContentPreview
   * property help to show the preview collection from the competency pullup
   */isShowContentPreview:false, /**
   * @property {Boolean} isShowStrugglingCompetencyReport
   * property hold the show / hide activity for grade competency
   */isShowStrugglingCompetencyReport:false, /**
   * @property {boolean} isShowCompetencyStudentList
   */isShowCompetencyStudentList:false, // ---------------------------------------------------------------------
// Actions
actions:{onSearchWeeklyLocal:function onSearchWeeklyLocal(searchWeekLocal){var filteredStudents=(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(this.get('activeCompetency.students')).filter(function(student){searchWeekLocal = searchWeekLocal.toLowerCase();return student.firstName && student.firstName.toLowerCase().startsWith(searchWeekLocal) || student.lastName && student.lastName.toLowerCase().startsWith(searchWeekLocal);});this.set('filteredWeekLocal',filteredStudents);},onClosePullup:function onClosePullup(){this.toggleProperty('showCompetencyInfo');this.sendAction('onClosePullUp');},onPrint:function onPrint(){window.print();},onSuggestContent:function onSuggestContent(student){this.set('selectedStudent',[student]);student.set('avatarUrl',student.thumbnailUrl);this.set('showSuggestConfirmation',true);this.set('isShowStrugglingCompetencyReport',true);},onCancelSuggest:function onCancelSuggest(){this.set('showSuggestConfirmation',false);},onConfirmSuggest:function onConfirmSuggest(){this.suggestContent();this.set('showSuggestConfirmation',false);}, // Action trigger when click ah play button
onPreviewContent:function onPreviewContent(content){var component=this;component.set('previewContentType',content.get('format') || content.get('collectionType'));component.set('previewContent',content);component.set('isShowContentPreview',true);}, // Action trigger when click add to class activity
onAddCollectionToDCA:function onAddCollectionToDCA(content){var component=this;var classId=component.get('class.id');var contentType=content.get('format');var contentId=content.get('id');var date=moment().format('YYYY-MM-DD');var selectedStudent=component.get('selectedStudent');component.get('classActivitiesService').addActivityToClass(classId,contentId,contentType,date).then(function(newContentId){component.saveUsersToCA(newContentId,selectedStudent);content.set('isAdded',true);});}, /**
     * Action get triggered when schedule content to CA got clicked
     */onScheduleContentToDCA:function onScheduleContentToDCA(content){var component=this;var datepickerEle=component.$('.ca-datepicker-schedule-container');datepickerEle.show();component.set('selectedContentForSchedule',content);component.set('endDate',null);}, /**
     * It will takes care of content will schedule for the specific date.
     * @param  {String} scheduleDate
     */onScheduleDate:function onScheduleDate(scheduleDate,scheduleEndDate){var component=this;var classId=component.get('class.id');var contentType=component.get('selectedContentForSchedule.format');var contentId=component.get('selectedContentForSchedule.id');var datepickerEle=component.$('.ca-datepicker-schedule-container');datepickerEle.hide();var forMonth=moment(scheduleDate).format('MM');var forYear=moment(scheduleDate).format('YYYY');component.get('classActivitiesService').addActivityToClass(classId,contentId,contentType,scheduleDate,forMonth,forYear,scheduleEndDate).then(function(newContentId){var selectedStudent=component.get('selectedStudent');component.saveUsersToCA(newContentId,selectedStudent);component.set('selectedContentForSchedule.isScheduled',true);});}, /**
     * It will takes care of content will schedule for the specific month.
     * @param  {Moment} Month
     * @param  {Moment} Year
     */onScheduleForMonth:function onScheduleForMonth(forMonth,forYear){var component=this;var classId=component.get('classId');var contentType=component.get('selectedContentForSchedule.format');var contentId=component.get('selectedContentForSchedule.id');var datepickerEle=component.$('.ca-datepicker-schedule-container');datepickerEle.hide();component.get('classActivitiesService').addActivityToClass(classId,contentId,contentType,null,forMonth,forYear).then(function(newContentId){var selectedStudent=component.get('selectedStudent');component.saveUsersToCA(newContentId,selectedStudent);component.set('selectedContentForSchedule.isScheduled',true);});}, /**
     * Action triggered when the user click on close
     */onCloseDatePicker:function onCloseDatePicker(){var datepickerEle=_ember['default'].$('.ca-datepicker-schedule-container');datepickerEle.hide();}, // action trigger when close struggling competency pull up
onClosePullUp:function onClosePullUp(isCloseAll){if(isCloseAll){this.set('isShowOtherGradeCompetency',false);this.set('isShowGradeCompetency',false);}this.set('isShowContentPreview',false);this.set('isShowStrugglingCompetencyReport',false);this.set('isShowCompetencyStudentList',false);}}, // ---------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){if(this.get('selectedCompetency')){this.fetchUsersListByCompetency();this.fetchLearningMapsContent();}this.set('isShowCompetencyStudentList',true);}, // --------------------------------------------------------------------
// Methods
fetchUsersListByCompetency:function fetchUsersListByCompetency(){var component=this;component.set('isLoading',true);var params={classId:component.get('class.id'),competency:component.get('selectedCompetency.competencyCode')};component.get('competencyService').fetchStudentsByCompetency(params).then(function(usersList){component.set('isLoading',false);component.set('activeCompetency',usersList);component.$('.student-standard-list-container').toggleClass('pullUp');component.set('filteredWeekLocal',(0,_gooruWebUtilsUtils.getObjectsDeepCopy)(usersList.students));});},suggestContent:function suggestContent(){var component=this;var userId=component.get('selectedStudent.firstObject').userId;var collection=component.get('suggestedCollection');var collectionType=component.get('suggestedContentType');var competencyCode=component.get('selectedCompetency.competencyCode');var contextParams={user_id:userId,class_id:component.get('class.id'),suggested_content_id:collection.get('id'),suggestion_origin:'teacher',suggestion_originator_id:component.get('session.userId'),suggestion_criteria:'performance',suggested_content_type:collectionType,suggested_to:'student',suggestion_area:'proficiency',tx_code:competencyCode,tx_code_type:'competency'};component.get('suggestService').suggestContent(contextParams);}, /**
   * @function fetchLearningMapsContent
   * Method to fetch learning maps content
   */fetchLearningMapsContent:function fetchLearningMapsContent(){var component=this;var searchService=component.get('searchService');var competencyCode=component.get('selectedCompetency.competencyCode');var filters={isDisplayCode:true};return _ember['default'].RSVP.hash({learningMapData:searchService.fetchLearningMapsContent(competencyCode,filters)}).then(function(_ref){var learningMapData=_ref.learningMapData;if(!(component.get('isDestroyed') || component.get('isDestroying'))){var signatureContentList=learningMapData.signatureContents;var showSignatureAssessment=component.get('showSignatureAssessment') && signatureContentList.assessments.length > 0;component.set('showSignatureAssessment',showSignatureAssessment);var signatureContent=showSignatureAssessment?signatureContentList.assessments:signatureContentList.collections;var content=signatureContent.objectAt(0);if(content){component.set('suggestedCollection',_ember['default'].Object.create(content));component.set('suggestedContentType',showSignatureAssessment?'assessment':'collection');}}});},saveUsersToCA:function saveUsersToCA(newContentId,selectedStudent){var component=this;var classId=component.get('class.id');var contentId=newContentId;var students=selectedStudent;var users=students.map(function(student){return student.get('userId');});component.get('classActivitiesService').addUsersToActivity(classId,contentId,users);}});});