define('gooru-web/services/api-sdk/struggling-competency',['exports','ember','gooru-web/adapters/competency/struggling-competency','gooru-web/serializers/competency/struggling-competency','gooru-web/adapters/taxonomy/taxonomy','gooru-web/serializers/taxonomy/taxonomy'],function(exports,_ember,_gooruWebAdaptersCompetencyStrugglingCompetency,_gooruWebSerializersCompetencyStrugglingCompetency,_gooruWebAdaptersTaxonomyTaxonomy,_gooruWebSerializersTaxonomyTaxonomy){ /**
 * Service for the struggling competency
 *
 * @typedef {Object} strugglingCompetencyService
 */exports['default'] = _ember['default'].Service.extend({strugglingCompetencyAdapter:null,strugglingCompetencySerializer:null,init:function init(){this._super.apply(this,arguments);this.set('strugglingCompetencyAdapter',_gooruWebAdaptersCompetencyStrugglingCompetency['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('strugglingCompetencySerializer',_gooruWebSerializersCompetencyStrugglingCompetency['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomyAdapter',_gooruWebAdaptersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Get students struggling competency
   * @returns {Promise.<[]>}
   */fetchStrugglingCompetency:function fetchStrugglingCompetency(params,isDefaultShowFW,classFramework){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('strugglingCompetencyAdapter').fetchStrugglingCompetency(params).then(function(response){var strugglingCompetency=service.get('strugglingCompetencySerializer').normalizeStrugglingCompetency(response); // resolve(strugglingCompetency);
if(isDefaultShowFW){(function(){var taxonomyIds=[];strugglingCompetency.map(function(data){if(data.domains && data.domains.length){data.domains.map(function(domain){domain.competencies.map(function(competency){var taxonomyId=data.fwCode.concat('.',competency.code);taxonomyIds.pushObject(taxonomyId);});});}});if(taxonomyIds.length){service.getcrosswalkCompetency(strugglingCompetency,classFramework,taxonomyIds).then(function(){resolve(strugglingCompetency);});}else {resolve(strugglingCompetency);}})();}else {resolve(strugglingCompetency);}},reject);});}, /**
   * Get students performance
   * @returns {Promise.<[]>}
   */fetchStudentsPerfomance:function fetchStudentsPerfomance(params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('strugglingCompetencyAdapter').fetchStudentsPerfomance(params).then(function(response){resolve(service.get('strugglingCompetencySerializer').normalizeStudentsPerfomance(response));},reject);});},fetchAnswerStuggling:function fetchAnswerStuggling(params,subjectCode){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('strugglingCompetencyAdapter').fetchAnswerStuggling(params,subjectCode).then(function(response){resolve(service.get('strugglingCompetencySerializer').answerStrugglingCompetency(response));},reject);});},fetchCheckedAnswerStuggling:function fetchCheckedAnswerStuggling(params){var service=this;var stuggles=service.get('strugglingCompetencySerializer').serializeStruggleList(params);return new _ember['default'].RSVP.Promise(function(resolve){service.get('strugglingCompetencyAdapter').fetchCheckedAnswerStuggling(stuggles).then(function(){resolve({});},function(){resolve({});});});},getcrosswalkCompetency:function getcrosswalkCompetency(strugglingCompetency,classFramework,taxonomyIds){var service=this;return service.get('taxonomyAdapter').crosswalkCompetency(classFramework,taxonomyIds).then(function(crosswalkResponse){var frameworkCrossWalkComp=service.get('taxonomySerializer').normalizeCrosswalkCompetency(crosswalkResponse);strugglingCompetency.map(function(data){if(data.domains && data.domains.length){data.domains.map(function(domain){domain.competencies.map(function(competency){var taxonomyData=frameworkCrossWalkComp.findBy('sourceDisplayCode',competency.displayCode);if(taxonomyData){competency.displayCode = taxonomyData.targetDisplayCode;}});});}});});}});});