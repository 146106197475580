define('gooru-web/services/api-sdk/lesson',['exports','ember','gooru-web/mixins/store','gooru-web/serializers/content/lesson','gooru-web/adapters/content/lesson','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsStore,_gooruWebSerializersContentLesson,_gooruWebAdaptersContentLesson,_gooruWebConfigConfig){ /**
 * Lesson Service
 *
 * Service responsible for retrieving all data related to the lesson model
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-viewer.js
 *
 * @typedef {Object} LessonService
 * @augments Ember/Service
 */exports['default'] = _ember['default'].Service.extend(_gooruWebMixinsStore['default'],{ // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('serializer',_gooruWebSerializersContentLesson['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('adapter',_gooruWebAdaptersContentLesson['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UnitSerializer} serializer
   */serializer:null, /**
   * @property {UnitAdapter} adapter
   */adapter:null, // -------------------------------------------------------------------------
// Methods
/**
   * Create a unit for a course
   * @param {String} courseId - ID of the course the lesson belongs to
   * @param {Content/Unit} unitId - ID of the unit the lesson belongs to
   * @param {Content/Lesson} lesson - Lesson model
   * @returns {Promise|String} returns the lesson model with the newly assigned ID
   */createLesson:function createLesson(courseId,unitId,lesson){var lessonData=this.get('serializer').serializeCreateLesson(lesson);return this.get('adapter').createLesson({courseId:courseId,unitId:unitId,lesson:lessonData}).then(function(lessonId){lesson.set('id',lessonId);return lesson;})['catch'](function(error){return error;});},createLessonPlan:function createLessonPlan(courseId,unitId,lessonId,lessonPlan){var _this=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var lessonData=_this.get('serializer').serializeCreateLessonPlan(lessonPlan);return _this.get('adapter').createLessonPlan({courseId:courseId,unitId:unitId,lessonId:lessonId,lessonPlan:lessonData}).then(function(response){resolve(response),reject;});});}, /**
   * Update existing lesson
   * @param {String} courseId - ID of the course the lesson belongs to
   * @param {String} unitId - ID of the unit the lesson belongs to
   * @param {Content/Lesson} lesson - Lesson model
   * @returns {Promise|String} returns the lesson model
   */updateLesson:function updateLesson(courseId,unitId,lesson){var lessonData=this.get('serializer').serializeUpdateLesson(lesson);return this.get('adapter').updateLesson({lessonId:lesson.get('id'),unitId:unitId,courseId:courseId,lesson:lessonData}).then(function(){return lesson;})['catch'](function(error){return error;});},updateLessonPlan:function updateLessonPlan(courseId,unitId,lessonId,lessonPlan,index){var lessonData=this.get('serializer').serializeUpdateLessonPlan(lessonPlan);return this.get('adapter').updateLessonPlan({unitId:unitId,courseId:courseId,lessonId:lessonId,lessonPlan:lessonData,index:index}).then(function(){return lessonPlan;})['catch'](function(error){return error;});}, /**
   * Returns a lesson by id
   * @param {string} courseId - course the lesson belongs to
   * @param {string} unitId - unit the lesson belongs to
   * @param {string} lessonId - lesson ID to search for
   * @returns {Promise|Content/Lesson}
   */fetchById:function fetchById(courseId,unitId,lessonId){var service=this;return service.get('adapter').getLessonById({courseId:courseId,unitId:unitId,lessonId:lessonId}).then(function(lessonData){return service.get('serializer').normalizeLesson(lessonData);})['catch'](function(error){return error;});}, /**
   * Associates a Collection/Assesment with a lesson
   *
   * @param lessonId the lesson id
   * @param collectionId the collection/assesment id
   * @returns {Promise}
   */associateAssessmentOrCollectionToLesson:function associateAssessmentOrCollectionToLesson(courseId,unitId,lessonId,collectionId,isCollection){var oaType=arguments.length <= 5 || arguments[5] === undefined?null:arguments[5];var service=this;var activityType=isCollection?_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').associateAssessmentOrCollectionToLesson({courseId:courseId,unitId:unitId,lessonId:lessonId,collectionId:collectionId,type:oaType?oaType:activityType}).then(resolve,reject);});}, /**
   * Disassociates a Collection/Assesment with a lesson
   *
   * @param lessonId the lesson id
   * @param collectionId the collection/assesment id
   * @returns {Promise}
   */disassociateAssessmentOrCollectionToLesson:function disassociateAssessmentOrCollectionToLesson(courseId,unitId,lessonId,collectionId,isCollection){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').disassociateAssessmentOrCollectionToLesson({courseId:courseId,unitId:unitId,lessonId:lessonId,collectionId:collectionId,type:isCollection?_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT}).then(resolve,reject);});}, /**
   * Delete lesson
   *
   * @param courseId - course the unit belongs to
   * @param unitId The Unit id to delete
   * @param lessonId the lesson id
   * @returns {Ember.RSVP.Promise}
   */deleteLesson:function deleteLesson(courseId,unitId,lessonId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').deleteLesson({courseId:courseId,unitId:unitId,lessonId:lessonId}).then(resolve,reject);});},deleteLessonPlan:function deleteLessonPlan(courseId,unitId,lessonId,lessonPlanId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').deleteLessonPlan({courseId:courseId,unitId:unitId,lessonId:lessonId,lessonPlanId:lessonPlanId}).then(resolve,reject);});}, /**
   * Copies a Lesson by id
   * @param {string} lessonId
   * @returns {Ember.RSVP.Promise}
   */copyLesson:function copyLesson(courseId,unitId,lessonId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').copyLesson({courseId:courseId,unitId:unitId,lessonId:lessonId}).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},reject);});}, /**
   * Reorder lesson lessons
   *
   * @param courseId the id of the Course
   * @param unitId the id of the Unit to be updated
   * @param lessonId the id of the lesson to be updated
   * @param {string[]} collectionIds
   * @returns {Promise}
   */reorderLesson:function reorderLesson(courseId,unitId,lessonId,collectionIds){var service=this;var serializedData=service.get('serializer').serializeReorderLesson(collectionIds);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').reorderLesson(courseId,unitId,lessonId,serializedData).then(resolve,reject);});},itemLesson:function itemLesson(param){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('adapter').itemLesson(param).then(function(response){resolve(service.get('serializer').serializeItemLesson(response));});});}});});