define('gooru-web/serializers/performance/milestone-performance',['exports','ember','ember-data'],function(exports,_ember,_emberData){ /**
 * Serializer for Milestone Performance model
 *
 * @typedef {Object} MilestonePerformanceSerializer
 */exports['default'] = _emberData['default'].JSONAPISerializer.extend({ /**
   * Normalized  performance data for milestones.
   * @return {Array}
   */normalizePerformanceDataForMilestones:function normalizePerformanceDataForMilestones(response){var resultSet=_ember['default'].A();if(response.content !== undefined && response.content.length > 0){response = _ember['default'].A(response.content);response.forEach(function(data){var result=_ember['default'].Object.create(data);var usageData=result.get('usageData');if(usageData && usageData.length > 0){var _data=usageData.objectAt(0);var completedInPrecentage=_data.totalCount > 0?Math.round(_data.completedCount / _data.totalCount * 100):undefined;var milestonePerformance=_ember['default'].Object.create({performance:_ember['default'].Object.create({timeSpent:_data.timeSpent,completedCount:_data.completedCount,scoreInPercentage:_data.scoreInPercentage,totalCount:_data.totalCount,completedInPrecentage:completedInPrecentage > 100?100:completedInPrecentage}),milestoneId:result.get('milestoneId'),userUid:result.get('userUid')});resultSet.pushObject(milestonePerformance);}});}return resultSet;}, /**
   * Normalized  performance data for milestone units.
   * @return {Array}
   */normalizePerformanceDataForMilestoneUnits:function normalizePerformanceDataForMilestoneUnits(response){var milestoneUnitsPerforamance=_ember['default'].A([]);if(response.content !== undefined && response.content.length > 0){var usersUnitsPerformance=response.content;usersUnitsPerformance.forEach(function(userUnitsPerformance){var result=_ember['default'].Object.create(userUnitsPerformance);var unitsPerforamanceData=result.get('usageData');if(unitsPerforamanceData && unitsPerforamanceData.length > 0){unitsPerforamanceData.map(function(unitPerforamanceData){var completedInPrecentage=unitPerforamanceData.totalCount > 0?Math.round(unitPerforamanceData.completedCount / unitPerforamanceData.totalCount * 100):undefined;var milestoneUnitPerformance=_ember['default'].Object.create({unitId:unitPerforamanceData.unitId,performance:_ember['default'].Object.create({timeSpent:unitPerforamanceData.timeSpent,completedCount:unitPerforamanceData.completedCount,scoreInPercentage:unitPerforamanceData.scoreInPercentage,totalCount:unitPerforamanceData.totalCount,completedInPrecentage:completedInPrecentage > 100?100:completedInPrecentage}),milestoneId:result.get('milestoneId'),userUid:result.get('userUid')});milestoneUnitsPerforamance.pushObject(milestoneUnitPerformance);});}});}return milestoneUnitsPerforamance;}, /**
   * Normalized  lesson performance data for milestone.
   * @return {Array}
   */normalizeLessonsPerformanceDataForMilestone:function normalizeLessonsPerformanceDataForMilestone(responsePayload){var normalizedLessonsPerformance=_ember['default'].A([]);if(responsePayload.content !== undefined && responsePayload.content.length){var usersLessonsPerformance=responsePayload.content;usersLessonsPerformance.map(function(userLessonsPerformance){if(userLessonsPerformance.usageData && userLessonsPerformance.usageData.length){var lessonsPerforamanceData=userLessonsPerformance.usageData;lessonsPerforamanceData.map(function(lessonPerformanceData){var normalizedLessonPerformanceData=_ember['default'].Object.create({lessonId:lessonPerformanceData.lessonId,performance:_ember['default'].Object.create({scoreInPercentage:lessonPerformanceData.scoreInPercentage,timeSpent:lessonPerformanceData.timeSpent,completedCount:lessonPerformanceData.completedCount,totalCount:lessonPerformanceData.totalCount,attempts:lessonPerformanceData.attempts,hasStarted:lessonPerformanceData.scoreInPercentage >= 0}),userUid:userLessonsPerformance.userUid});normalizedLessonsPerformance.pushObject(normalizedLessonPerformanceData);});}});}return normalizedLessonsPerformance;}});});