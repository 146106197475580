define('gooru-web/components/gru-preview-collection',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/mixins/session','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebMixinsSession,_gooruWebModelsTaxonomyTaxonomyTagData){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsSession['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-preview-collection'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to remix the collection
     * @param content
     */remixCollection:function remixCollection(){this.get('model').remixCollection();}, /**
     * Action triggered to bookmark the collection
     * @param content
     */bookmarkCollection:function bookmarkCollection(){this.get('model').bookmarkCollection();}, /**
     * Action triggered to play the collection
     * @param content
     */playCollection:function playCollection(){this.get('model').playCollection();this.triggerAction({action:'closeModal'});}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);component.set('collection',component.get('model.content'));component.set('isTeacher',component.get('model.isTeacher'));component.set('isStudent',component.get('model.isStudent'));}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Collection} collection
   */collection:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collection.standards.[]',function(){var standards=this.get('collection.standards');standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), /**
   * Indicates if the teacher is seeing the collection/assessment card
   * @property {boolean}
   */isTeacher:null, /**
   * Indicates if the student is seeing the collection/assessment card
   * @property {boolean}
   */isStudent:null, /**
   * @property {boolean} Indicates if collection has 1 or more resources
   */hasResources:_ember['default'].computed.gt('collection.resourceCount',0), /**
   * @property {boolean} Indicates if collection has 1 or more questions
   */hasQuestions:_ember['default'].computed.gt('collection.questionCount',0)});});