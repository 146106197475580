define('gooru-web/controllers/resource-player',['exports','ember'],function(exports,_ember){ /**
 * Study Player Controller
 *
 * @module
 * @augments ember/PlayerController
 */exports['default'] = _ember['default'].Controller.extend({queryParams:['collectionUrl','unitId','lessonId','collectionId','pathId','source','collectionType','classId','isIframeMode'],actions:{}, // ------------------------------------------------------------------------
// Dependencies
/**
   * @dependency {i18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Properties
/**
   * @property {boolean}
   */isDone:false, /**
   * Show the next button and send events
   * @property {Boolean} sendEvents
   */sendEvents:_ember['default'].computed.not('collectionUrl'), /**
   * Indicates if it should default player header
   * @property {boolean}
   */showPlayerHeader:true});});