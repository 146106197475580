define('gooru-web/components/reports/assessment/questions/gru-scientific-free-response',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * Open Ended Question
 *
 * Component responsible for controlling the logic and appearance of an open
 * ended question inside of the assessment report
 *
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-scientific-free-response'], // -------------------------------------------------------------------------
// Properties
answer:_ember['default'].computed('answerObj',function(){var answerDetails=this.get('answerObj');var userAnswers=_ember['default'].A([]);if(answerDetails){answerDetails.map(function(answer){var answerValue=answer.text?answer.text.split(':'):answer.category;userAnswers.pushObject({category:answer.text?answerValue?answerValue[0]:'':answerValue,value:answer.text?answerValue?answerValue[1]:'':answer.value});});}return userAnswers;}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});