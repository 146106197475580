define('gooru-web/routes/content/questions/edit',['exports','ember','gooru-web/mixins/private-route-mixin'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{queryParams:{collectionId:{},isCollection:{},isPreviewReferrer:{},editingContent:{refreshModel:true},editing:{refreshModel:true},isLibraryContent:false,primaryLanguage:{refreshModel:true}}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/question
   */questionService:_ember['default'].inject.service('api-sdk/question'), /**
   * @requires service:api-sdk/assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @requires service:api-sdk/collection
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
resetController:function resetController(controller,isExiting){if(isExiting){controller.set('collectionId',undefined);controller.set('isCollection',undefined);controller.set('isPreviewReferrer',undefined);}this.get('controller').set('isEditing','false');}, // -------------------------------------------------------------------------
// Methods
model:function model(params){var route=this;var questionId=params.questionId;var collectionId=params.collectionId;var isCollection=params.isCollection === 'true';var isPreviewReferrer=params.isPreviewReferrer === 'false';var isEditing=params.editing;var isLibraryContent=params.isLibraryContent;var editingContent=params.editingContent;var question=null;var collection=null;if(questionId){question = route.get('questionService').readQuestion(questionId);}if(collectionId){if(isCollection){collection = route.get('collectionService').readCollection(collectionId);}else {collection = route.get('assessmentService').readAssessment(collectionId);}}var difficultyLevel=route.get('questionService').getDifficultyLevel();return _ember['default'].RSVP.hash({question:question,collection:collection,isCollection:isCollection,isPreviewReferrer:isPreviewReferrer,isEditing:isEditing === 'true',isLibraryContent:isLibraryContent,editingContent:editingContent,difficultyLevel:difficultyLevel,primaryLanguage:params.primaryLanguage});},setupController:function setupController(controller,model){controller.set('question',model.question);controller.set('collection',model.collection);controller.set('isCollection',model.isCollection);controller.set('isPreviewReferrer',model.isPreviewReferrer);controller.set('isEditing',model.isEditing);controller.set('isLibraryContent',model.isLibraryContent);controller.set('editingContent',model.editingContent);controller.set('difficultyLevel',model.difficultyLevel);controller.set('primaryLanguage',model.primaryLanguage);}});});