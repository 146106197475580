define('gooru-web/controllers/email-verification',['exports','ember','gooru-web/models/email-verification'],function(exports,_ember,_gooruWebModelsEmailVerification){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * Maintains the session object.
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// Properties
/**
   * Token for Resend mail
   * @property {String}
   */accessToken:_ember['default'].computed.alias('session.token-api3'), /**
   * Token from the update email verify flow
   * @property {String}
   */token:'', /**
   * @type {User} user
   */user:null, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false, /**
   * @param {Boolean } isVerified - value used to check if email-verify updated or not
   */isVerified:false, /**
   * @param {Boolean } invalidMail - value used to check if email-address is valid or not
   */invalidMail:false, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Resend mail triggered
     */resendMail:function resendMail(){var controller=this;var token=controller.get('accessToken');var user=controller.get('user');user.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){return _ember['default'].RSVP.hash({mailVerify:controller.get('profileService').sendMailVerify(user.get('email'),token)}).then(function(){controller.transitionToRoute('sign-up-register');})['catch'](function(isValid){if(isValid){controller.set('invalidMail',true);setTimeout(function(){return controller.set('invalidMail',false);},5000);}});}controller.set('didValidate','true');});}, /**
     * Redirected to Signin
     */userLogin:function userLogin(){this.transitionToRoute('login');}}, // -------------------------------------------------------------------------
// Methods
/**
   * Update email verification
   */updateEmailVerification:function updateEmailVerification(token){var controller=this;var isLogin=controller.get('not_verified');var user=_gooruWebModelsEmailVerification['default'].create(_ember['default'].getOwner(this).ownerInjection(),{email:null});if(isLogin){controller.set('isAccess',false);controller.set('user',user);}else {controller.get('profileService').updateMailVerify(token).then(function(){controller.set('isVerified',true);})['catch'](function(){controller.set('isAccess',true);controller.set('user',user);});}}});});