define('gooru-web/components/cards/gru-video-authorize-card',['exports','ember','gooru-web/mixins/tenant-settings-mixin','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ /**
   * Class attributes
   */classNames:['gru-video-authorize-card'], /**
   * @return videConferenceService
   */videConferenceService:_ember['default'].inject.service('api-sdk/video-conference'), /**
   * @property {Boolean} isConferenceAllow hold the toggleProperty
   */isConferenceAllow:false, /**
   * @property {String} meetingTool hold the preferred  meeting tool name
   */meetingTool:_ember['default'].computed(function(){return this.preferredMeetingTool();}),actions:{ /**
     * @function onDeny action trigger when click on deny
     */onDeny:function onDeny(){this.sendAction('onDeny');}, /**
     * @function onAllow action trigger when click on deny
     */onAllow:function onAllow(){var currentPath=window.location.href;var params={redirectUrl:currentPath.indexOf('?') !== -1?currentPath + '&videoConference=true':currentPath + '?videoConference=true'};this.authorize(params);}},authorize:function authorize(params){var _this=this;if(this.preferredMeetingTool() === _gooruWebConfigConfig.MEETING_TOOLS.zoom){this.get('videConferenceService').authorizeZoom(params).then(function(redirectUrl){_this.sendAction('onAllow');_this.openAuthorizeWindow(redirectUrl);});}else {this.get('videConferenceService').authorizeConference(params).then(function(redirectUrl){_this.sendAction('onAllow');_this.openAuthorizeWindow(redirectUrl);});}},openAuthorizeWindow:function openAuthorizeWindow(redirectUrl){window.open(redirectUrl,'_blank','toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=50px,width=800,height=700');}});});