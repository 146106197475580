define('gooru-web/components/content/modals/gru-lesson-suggestion',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-lesson-suggestion'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/course-map
   */courseMapService:_ember['default'].inject.service('api-sdk/course-map'),session:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Properties
dependentLesson:null, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.animateComponent();component.fetchDependentLesson();_ember['default'].$('body').addClass('system-suggested');},willDestroyElement:function willDestroyElement(){this._super.apply(this,arguments);_ember['default'].$('body').removeClass('system-suggested');}, // -------------------------------------------------------------------------
// Actions
actions:{onContinueLessonSuggestion:function onContinueLessonSuggestion(){this.sendAction('onContinueLessonSuggestion');}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function animateComponent
   * Method to animate the component
   */animateComponent:function animateComponent(){var component=this;component.$().animate({bottom:'50px'},850);},fetchDependentLesson:function fetchDependentLesson(){var _this=this;var pathParams={userId:this.get('session.userId'),classId:this.get('classId')};var milestoneId=this.get('milestoneId');var lessonId=this.get('lessonId');this.get('courseMapService').fetchMilestoneDependentPath(milestoneId,pathParams).then(function(dependentLessons){_this.set('dependentLesson',dependentLessons.findBy('lesson_id',lessonId));});} // -------------------------------------------------------------------------
// Properties
});});