define('gooru-web/mixins/reports/portfolio/portfolio-mixin',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Mixin.create({ // -------------------------------------------------------------------------
// Dependencies
portfolioService:_ember['default'].inject.service('api-sdk/portfolio'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered while selecting an attempt
onSelectAttempt:function onSelectAttempt(attempt){var mixin=this;mixin.set('activeAttempt',attempt);mixin.loadActivityPerformance(attempt);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} userId
   */userId:_ember['default'].computed(function(){return this.get('session.userId');}), /**
   * @property {Object} activeAttempt
   * Property for active/selected attempt object
   */activeAttempt:_ember['default'].computed('activityAttempts.[]',function(){var mixin=this;var activityAttempts=mixin.get('activityAttempts');return activityAttempts?activityAttempts.objectAt(0):_ember['default'].Object.create({});}), /**
   * @property {Object} latestAttempt
   * Property for latest attempt
   */latestAttempt:_ember['default'].computed('activityAttempts.[]',function(){var mixin=this;var activityAttempts=mixin.get('activityAttempts');return activityAttempts?activityAttempts.objectAt(0):_ember['default'].Object.create({});}), /**
   * @property {Object} currentAttempt
   * Property for current attempt
   */currentAttempt:_ember['default'].computed('currentAttempts.[]',function(){var mixin=this;var currentAttempts=mixin.get('currentAttempts');return currentAttempts?currentAttempts.objectAt(0):_ember['default'].Object.create({});}), /**
   * @property {Number} totalNumberOfAttempts
   * Property for total number of attempts done by student
   */totalNumberOfAttempts:_ember['default'].computed('activityAttempts.[]',function(){return this.get('activityAttempts.length');}), // -------------------------------------------------------------------------
// Methods
/**
   * @function loadActivityAttempts
   * @return {Array} activityAttempts
   * Method to load list of activity attempts
   */loadActivityAttempts:function loadActivityAttempts(){var mixin=this;return _ember['default'].RSVP.hash({activityAttempts:mixin.fetchActivityAttempts()}).then(function(_ref){var activityAttempts=_ref.activityAttempts;if(!mixin.isDestroyed){(function(){mixin.set('activityAttempts',activityAttempts);var currentAttempts=undefined;var otherAttempts=undefined;var startDate=new Date(mixin.get('startDate')).getTime();var endDate=new Date(mixin.get('endDate')).getTime();if(startDate && endDate){currentAttempts = activityAttempts.filter(function(d){var currentDate=new Date(d.updatedAt).getTime();return startDate < currentDate && endDate > currentDate;});otherAttempts = activityAttempts.filter(function(d){var otherDate=new Date(d.updatedAt).getTime();return startDate > otherDate || endDate < otherDate;});}else if(endDate){currentAttempts = activityAttempts.filter(function(d){var currentDate=new Date(d.updatedAt).getTime();return endDate < currentDate;});otherAttempts = activityAttempts.filter(function(d){var otherDate=new Date(d.updatedAt).getTime();return endDate > otherDate;});}mixin.set('currentAttempts',currentAttempts);mixin.set('otherAttempts',otherAttempts);})();}return activityAttempts;});}, /**
   * @function loadActivityPerformance
   * @param {Object} activityAttempt
   * @return {Object} activityPerformance
   * Method to load performance summary of an activity
   */loadActivityPerformance:function loadActivityPerformance(activityAttempt){var mixin=this;return _ember['default'].RSVP.hash({activityPerformance:mixin.fetchActivityAttemptPerformance(activityAttempt)}).then(function(_ref2){var activityPerformance=_ref2.activityPerformance;if(!mixin.isDestroyed){mixin.set('activeAttempt.timespent',activityPerformance.timespent);mixin.set('activityPerformance',activityPerformance);}return activityPerformance;});}, /**
   * @function fetchActivityAttempts
   * @return {Promise.activityAttempts}
   * Method to fetch activity attempts promise
   */fetchActivityAttempts:function fetchActivityAttempts(){var mixin=this;var userId=mixin.get('userId');var itemId=mixin.get('contentId');var requestParams={userId:userId,itemId:itemId};return mixin.get('portfolioService').getAllAttemptsByItem(requestParams);}, /**
   * @function fetchActivityAttemptPerformance
   * @param {Object} activityAttempt
   * @return {Promise.activityPerformance}
   * Method to fetch activity performance by given attempt
   */fetchActivityAttemptPerformance:function fetchActivityAttemptPerformance(activityAttempt){var mixin=this;var activityAttempts=this.get('activityAttempts');var currentAttempts=activityAttempts.sortBy('updatedAt');var currentAttempt=activityAttempt?activityAttempt:currentAttempts[currentAttempts.length - 1];var userId=mixin.get('userId');var sessionId=currentAttempt?currentAttempt.get('sessionId'):activityAttempts[activityAttempts.length - 1].get('sessionId');if(mixin.get('isNotShowListAttempt')){sessionId = activityAttempt.get('sessionId');}var itemId=activityAttempt.get('id');var contentSource=activityAttempt.get('contentSource');var activityType=activityAttempt.get('type').includes(_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT)?_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT:_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION;var requestParams={userId:userId,itemId:itemId,sessionId:sessionId,contentSource:contentSource};return mixin.get('portfolioService').getActivityPerformanceBySession(requestParams,activityType);}});});