define('gooru-web/adapters/competency/struggling-competency',['exports','ember'],function(exports,_ember){ /**
 * Adapter for struggling Competency
 *
 * @typedef {Object} StrugglingCompetencyAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/ds/users/v2/competencies/struggling',struggleNamespace:'/api/nucleus/v2', /**
   * get struggling competency based on competency
   * @return {Promise}
   */fetchStrugglingCompetency:function fetchStrugglingCompetency(params){var adapter=this;var namespace=adapter.get('namespace');var grade=params.grade || null;var classId=params.classId || null;var month=params.month || null;var year=params.year || null;var url=namespace + '?grades=' + grade + '&class=' + classId + '&month=' + month + '&year=' + year;var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8'};return _ember['default'].$.ajax(url,options);}, /**
   * get student performance based on competency
   * @return {Promise}
   */fetchStudentsPerfomance:function fetchStudentsPerfomance(params){var adapter=this;var namespace=adapter.get('namespace');var competency=params.competency || null;var classId=params.classId || null;var month=params.month || null;var year=params.year || null;var url=namespace + '/performance?class=' + classId + '&competency=' + competency + '&month=' + month + '&year=' + year;var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8'};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};},fetchAnswerStuggling:function fetchAnswerStuggling(params,subjectCode){var adapter=this;var struggleNamespace=adapter.get('struggleNamespace');var url=struggleNamespace + '/taxonomy/subjects/' + subjectCode + '/struggles';var options={type:'POST',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:JSON.stringify(params.data)};return _ember['default'].$.ajax(url,options);},fetchCheckedAnswerStuggling:function fetchCheckedAnswerStuggling(params){var adapter=this;var struggleNamespace=adapter.get('struggleNamespace');var url=struggleNamespace + '/taxonomy/struggles';var options={type:'POST',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'text',processData:false,data:JSON.stringify(params)};return _ember['default'].$.ajax(url,options);}});});