define('gooru-web/services/api-sdk/multiple-class',['exports','ember','gooru-web/adapters/class/multiple-class','gooru-web/serializers/class/multiple-class'],function(exports,_ember,_gooruWebAdaptersClassMultipleClass,_gooruWebSerializersClassMultipleClass){exports['default'] = _ember['default'].Service.extend({ /**
   * @property {MultipleClassAdapter} mulitpleClassAdapter
   */mulitpleClassAdapter:null, /**
   * @property {MulitpleClassSerializer} mulitpleClassSerializer
   */mulitpleClassSerializer:null,init:function init(){this.set('mulitpleClassAdapter',_gooruWebAdaptersClassMultipleClass['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('mulitpleClassSerializer',_gooruWebSerializersClassMultipleClass['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * @function fetchMultipleClassList
   * @param {UUID} classId
   * Method to fetch mutliple class
   */fetchMultipleClassList:function fetchMultipleClassList(classId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('mulitpleClassAdapter').fetchMultipleClassList(classId).then(function(multipleClasses){resolve(service.get('mulitpleClassSerializer').normalizeMulitpleClass(multipleClasses));},reject);});}, /**
   * @function updateMultipleClass
   * @param {UUID} classId
   * @param {Object} secondaryclasses
   */updateMultipleClass:function updateMultipleClass(classId,requestBody){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('mulitpleClassAdapter').updateMultipleClass(classId,requestBody).then(resolve,reject);});}});});