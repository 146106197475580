define('gooru-web/components/reports/pull-up/signature-content-report',['exports','ember','gooru-web/config/config','gooru-web/utils/taxonomy'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsTaxonomy){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['signature-content-container'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {service} searchService
   */searchService:_ember['default'].inject.service('api-sdk/search'), /**
   * @property {service} taxonomyService
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'), /**
   * @property {service} assessmentService
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {service} collectionService
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @property {service} suggestService
   */suggestService:_ember['default'].inject.service('api-sdk/suggest'), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Object} competency
   */competency:null, /**
   * @property {String} standardCode
   */standardCode:_ember['default'].computed.alias('competency.competencyCode'),onCompetencyChange:_ember['default'].observer('competency','showGutCompetency',function(){var component=this;component.loadData();}), /**
   * @property {Object} signatureContent
   */signatureContent:null, /**
   * @property {boolean} showSignatureAssessment
   */showSignatureAssessment:false, /**
   * @property {Object} content
   */content:null, /**
   * @property {Array} prerequisites
   */prerequisites:null, /**
   * @property {Array} microCompetencies
   */microCompetencies:null, /**
   * @property {boolean} isLoading
   */isLoading:false, /**
   * @property {number} domainId
   */domainId:_ember['default'].computed('standardCode',function(){var code=this.get('standardCode');return (0,_gooruWebUtilsTaxonomy.getDomainId)(code);}), /**
   * @property {number} subjectId
   */subjectId:_ember['default'].computed('standardCode',function(){var code=this.get('standardCode');return (0,_gooruWebUtilsTaxonomy.getSubjectId)(code);}), /**
   * @property {number} courseId
   */courseId:_ember['default'].computed('standardCode',function(){var code=this.get('standardCode');return (0,_gooruWebUtilsTaxonomy.getCourseId)(code);}), /**
   * @property {boolean} flag for determining unlimited behaviour
   */unlimited:_ember['default'].computed.equal('content.attempts',-1), /**
   * @property {String} collectionType
   */collectionType:_ember['default'].computed('showSignatureAssessment',function(){var component=this;var showSignatureAssessment=component.get('showSignatureAssessment');return showSignatureAssessment?'assessment':'collection';}), /**
   * @property {String} source value when playing a collection/assessment
   */source:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.MASTER_COMPETENCY, /**
   * @property {Array} students
   */students:_ember['default'].computed('student',function(){var component=this;var studentListForSuggestion=component.get('studentListForSuggestion');var students=studentListForSuggestion?studentListForSuggestion:[component.get('student')];return students;}),isMappedWithGutCode:_ember['default'].computed('competency.isMappedWithFramework','showGutCompetency',function(){return this.get('competency.isMappedWithFramework') && !this.get('showGutCompetency');}),actions:{onSuggestContent:function onSuggestContent(collection,collectionType){var component=this;component.set('suggestedCollection',collection);component.set('suggestedCollectionType',collectionType);component.set('showSuggestConfirmation',true);},onCancelSuggest:function onCancelSuggest(){var component=this;component.set('showSuggestConfirmation',false);},onConfirmSuggest:function onConfirmSuggest(){var component=this;var collection=component.get('suggestedCollection');var collectionType=component.get('suggestedCollectionType');var competencyCode=component.get('standardCode');component.set('showSuggestConfirmation',false);component.sendAction('onConfirmSuggest',collection,collectionType,competencyCode);}, //Action triggered when click collection/assessment title
onPreviewContent:function onPreviewContent(content){var component=this;var collectionType=content.get('suggestedContentType') || component.get('collectionType');component.set('previewContent',content.get('collection'));component.set('previewContentType',collectionType);component.set('isShowContentPreview',true);},playContent:function playContent(queryParams,contentId,content){var component=this;component.sendAction('playContent',queryParams,contentId,content);}},init:function init(){var component=this;component._super.apply(component,arguments);component.loadData();},loadData:function loadData(){var component=this;component.fetchLearningMapsContent();component.fetchCodes();if(component.get('userId')){component.fetchTeacherSuggestions();}},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, /**
   * @function fetchCodes
   * Method to fetch all competency code for domain
   */fetchCodes:function fetchCodes(){var component=this;var frameworkId=component.get('classFramework')?component.get('classFramework'):component['class'].preference.framework;var competncyId=component.get('competency.competencyCode');return _ember['default'].RSVP.hash({competencyCodes:component.get('taxonomyService').fetchCompetencyCodes(frameworkId,competncyId)}).then(function(_ref){var competencyCodes=_ref.competencyCodes;component.set('microCompetencies',competencyCodes);});}, /**
   * @function fetchLearningMapsContent
   * Method to fetch learning maps content
   */fetchLearningMapsContent:function fetchLearningMapsContent(){var component=this;var searchService=component.get('searchService');var taxonomyService=component.get('taxonomyService');var competencyCode=component.get('standardCode');var filters={isDisplayCode:true};var languageId=component.get('class.primaryLanguage');component.set('competencyGutCode',competencyCode);component.set('isLoading',true);return _ember['default'].RSVP.hash({learningMapData:searchService.fetchLearningMapsContentCompetency(competencyCode,filters,null,languageId),prerequisites:taxonomyService.fetchPrerequisites(competencyCode)}).then(function(_ref2){var learningMapData=_ref2.learningMapData;var prerequisites=_ref2.prerequisites;if(!(component.get('isDestroyed') || component.get('isDestroying'))){component.set('learningMapData',learningMapData);var signatureContentList=learningMapData.signatureContents;var signatureContent=undefined;component.checkPrerequisiteCompetencyStatus(prerequisites.competencyPrerequisites);if(signatureContentList){var showSignatureAssessment=component.get('showSignatureAssessment') && signatureContentList.assessments.length > 0;component.set('showSignatureAssessment',showSignatureAssessment);signatureContent = showSignatureAssessment?signatureContentList.assessments:signatureContentList.collections;if(!component.get('userId')){component.set('isLoading',false);}if(signatureContent){var content=signatureContent.objectAt(0);if(content){component.set('signatureContent',_ember['default'].Object.create(content));component.fetchContentSettings(content.id);}}}}});}, /**
   * @function checkPrerequisiteCompetencyStatus
   * Method to check prerequisite competency status
   */checkPrerequisiteCompetencyStatus:function checkPrerequisiteCompetencyStatus(prerequisites){var component=this;var domainCompetencyList=component.get('proficiencyChartData');var prerequisitesList=_ember['default'].A([]);if(prerequisites && domainCompetencyList){prerequisites.forEach(function(competency){domainCompetencyList.forEach(function(domain){domain.topics.forEach(function(topic){var filteredCompetency=topic.competencies.findBy('competencyCode',competency);if(filteredCompetency){var competencyList={status:filteredCompetency?filteredCompetency.status:0,title:filteredCompetency.framework?filteredCompetency.framework.frameworkCompetencyName:filteredCompetency.competencyName,code:filteredCompetency.framework?filteredCompetency.framework.frameworkCompetencyDisplayCode:filteredCompetency.competencyDisplayCode};prerequisitesList.push(competencyList);}});});});component.set('prerequisites',prerequisitesList);}}, /**
   * @function fetchContentSettings
   * Method to fetch content setting
   */fetchContentSettings:function fetchContentSettings(contentId){var component=this;var collectionType=component.get('collectionType');var contentPromise=null;if(collectionType === 'assessment'){contentPromise = component.get('assessmentService').readAssessment(contentId);}else {contentPromise = component.get('collectionService').readCollection(contentId);}return _ember['default'].RSVP.hash({content:contentPromise}).then(function(_ref3){var content=_ref3.content;var signatureContent=component.get('signatureContent');signatureContent.set('collection',content);})['catch'](function(){var signatureContent=component.get('signatureContent');signatureContent.set('collection',null);});},fetchTeacherSuggestions:function fetchTeacherSuggestions(){var component=this;var userId=component.get('userId');var competencyCode=component.get('competency.competencyCode');var params={scope:'proficiency'};component.get('suggestService').fetchAcrossClassSuggestionsByCode(userId,competencyCode,params).then(function(content){component.set('suggestions',content.get('suggestions'));component.set('isLoading',false);});}});});