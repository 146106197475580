define('gooru-web/services/api-sdk/network',['exports','ember','gooru-web/serializers/network/network','gooru-web/adapters/network/network'],function(exports,_ember,_gooruWebSerializersNetworkNetwork,_gooruWebAdaptersNetworkNetwork){ /**
 * Service to support the Profile CRUD operations
 *
 * @typedef {Object} ProfileService
 */exports['default'] = _ember['default'].Service.extend({session:_ember['default'].inject.service(),networkSerializer:null,networkAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('networkSerializer',_gooruWebSerializersNetworkNetwork['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('networkAdapter',_gooruWebAdaptersNetworkNetwork['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Gets the current user Profile information
   *
   * @returns {Promise}
   */readMyNetwork:function readMyNetwork(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('networkAdapter').readMyNetwork().then(function(response){resolve(service.get('networkSerializer').normalizeReadNetwork(response));},function(error){reject(error);});});}, /**
   * Gets user network information of a given user id
   *
   * @returns {Promise}
   */readUserNetwork:function readUserNetwork(userId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('networkAdapter').readUserNetwork(userId).then(function(response){resolve(service.get('networkSerializer').normalizeReadNetwork(response));},function(error){reject(error);});});}});});