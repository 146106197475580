define('gooru-web/components/proficiency/inspect/student-data-standard-chart',['exports','ember','gooru-web/mixins/student-learner-proficiency'],function(exports,_ember,_gooruWebMixinsStudentLearnerProficiency){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsStudentLearnerProficiency['default'],{ // ----------------------------------------------------------------------
// Attributes
classNames:['student-data-standard-chart'], // ---------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service(), /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyDataService:_ember['default'].inject.service('api-sdk/taxonomy'), /**
   * competency service dependency injection
   * @type {Object}
   */competencyService:_ember['default'].inject.service('api-sdk/competency'), // --------------------------------------------------------------------
// Properties
activeSubject:_ember['default'].computed('class',function(){return _ember['default'].Object.create({id:this.get('class.preference.subject'),title:null});}),studentProfile:_ember['default'].computed('session',function(){return {id:this.get('session.userId')};}),isTeacherMatrix:true,framework:_ember['default'].computed('class',function(){return this.get('class.preference.framework');}),classGradesList:_ember['default'].A(),isLoading:false,chartData:_ember['default'].A(),didInsertElement:function didInsertElement(){this.loadDataBySubject();}, // --------------------------------------------------------------------------
// Actions
actions:{onLoadedProficiency:function onLoadedProficiency(){this.set('isLoading',true);this.parseDomainData();},goBack:function goBack(){this.set('class.isDisable',false);window.history.back();},isShowLoaderSet:function isShowLoaderSet(value){this.set('isShowLoader',value);}}, // ------------------------------------------------------------------------
// Methods
parseDomainData:function parseDomainData(){var _this=this;this.fetchDomainGradeBoundary().then(function(competencyList){var proficiencyChartData=_this.get('proficiencyChartData');proficiencyChartData.forEach(function(domain){var competenciesSeq=-1;domain.set('compSeqList',_ember['default'].A());domain.set('classGradeCompetencies',0);domain.topics.forEach(function(topic){topic.competencies.forEach(function(competency){competenciesSeq++;if(competencyList.indexOf(competency.competencyCode) !== -1){domain.get('compSeqList').push(competenciesSeq);competency.set('isClassGrade',true);domain.incrementProperty('classGradeCompetencies');}});});});_this.set('chartData',proficiencyChartData);_this.set('isLoading',false);});}, /**
   * @function fetchDomainGradeBoundary
   * Method to fetch domain grade boundary
   */fetchDomainGradeBoundary:function fetchDomainGradeBoundary(){var component=this;var params={classId:component.get('class.id'),courseId:component.get('class.courseId')};return component.get('competencyService').fetchLJCompetency(params);}});});