define('gooru-web/services/taxonomy',['exports','ember','gooru-web/services/api-sdk/taxonomy','gooru-web/models/taxonomy/taxonomy-item','gooru-web/config/config','gooru-web/utils/taxonomy'],function(exports,_ember,_gooruWebServicesApiSdkTaxonomy,_gooruWebModelsTaxonomyTaxonomyItem,_gooruWebConfigConfig,_gooruWebUtilsTaxonomy){ /**
 * Service for the Taxonomy Singleton elements container
 *
 * @typedef {Object} TaxonomyService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @private {Number} - Starting taxonomy item level for the standards
   */STANDARDS_BASE_LEVEL:3, /**
   * @property {APITaxonomyService} - the taxonomy service
   */apiTaxonomyService:null, /**
   * @property {Object} An object that contains the hierarchy of taxonomy. It gets populated progressively during
   * application browsing, as data is getting retrieved it gets stored in this property to prevent redundant hits
   * to the API
   */taxonomyContainer:null, /**
   * @property {Object} taxonomySubjectContainer
   * An object to store taxonomy subjects which is fetched from the ds-user API
   */taxonomySubjectContainer:null, /**
   * @property {Object} taxonomyCategoriesContainer
   * An object to store taxonomy categories
   */taxonomyCategoriesContainer:null,init:function init(){this._super.apply(this,arguments);this.set('taxonomyContainer',{});this.set('apiTaxonomyService',_gooruWebServicesApiSdkTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomySubjectContainer',{});this.set('taxonomyClassificationContainer',null);this.set('crosswalkFWCompetencies',_ember['default'].Object.create({}));}, /**
   * Gets the Taxonomy Subjects for a Category from the cached taxonomy. If the subjects are not available then fetch
   * them from the Taxonomy API.
   *
   * @param {String} category - The classification type
   * @returns {Promise}
   */getSubjects:function getSubjects(category){var service=this;var apiTaxonomyService=service.get('apiTaxonomyService');return new _ember['default'].RSVP.Promise(function(resolve){var taxonomyContainer=service.get('taxonomyContainer');if(taxonomyContainer[category]){resolve(taxonomyContainer[category]);}else {service.getCategories().then(function(categories){var promises=categories.map(function(category){return apiTaxonomyService.fetchSubjects(category.get('id')).then(function(subjects){taxonomyContainer[category.get('code')] = subjects;});});_ember['default'].RSVP.all(promises).then(function(){resolve(taxonomyContainer[category]);});});}});}, /**
   * Gets the Taxonomy Subjects for a Category from the cached taxonomy. If the subjects are not available then fetch
   * them from the Taxonomy API.
   *
   * @param {String} category - The classification type
   * @returns {Promise}
   */getTaxonomySubjects:function getTaxonomySubjects(taxonomyCategory){var service=this;var apiTaxonomyService=service.get('apiTaxonomyService');return new _ember['default'].RSVP.Promise(function(resolve){var taxonomySubjectContainer=service.get('taxonomySubjectContainer');if(taxonomySubjectContainer[taxonomyCategory]){resolve(taxonomySubjectContainer[taxonomyCategory]);}else {return apiTaxonomyService.fetchTaxonomySubjects(taxonomyCategory).then(function(subjects){taxonomySubjectContainer[taxonomyCategory] = subjects;resolve(taxonomySubjectContainer[taxonomyCategory]);});}});}, /**
   * Gets the Taxonomy Subjects by user id
   *
   * @param {String} userId
   * @returns {Promise}
   */getTaxonomySubjectsByUserId:function getTaxonomySubjectsByUserId(userId){var service=this;var apiTaxonomyService=service.get('apiTaxonomyService');return new _ember['default'].RSVP.Promise(function(resolve){return apiTaxonomyService.getTaxonomySubjectsByUserId(userId).then(function(subjects){resolve(subjects);});});}, /**
   * Gets the Taxonomy Courses for a Subject from the cached taxonomy. If the courses are not available then fetch
   * them from the Taxonomy API.
   *
   * @param {TaxonomyRoot} subject - The taxonomy subject
   * @returns {Promise}
   */getCourses:function getCourses(subject){var service=this;var apiTaxonomyService=service.get('apiTaxonomyService');return new _ember['default'].RSVP.Promise(function(resolve){if(subject){if(subject.get('courses') && subject.get('courses.length') > 0){resolve(subject.get('courses'));}else {apiTaxonomyService.fetchCourses(subject.get('frameworkId'),subject.get('id')).then(function(courses){subject.set('courses',courses);resolve(courses);});}}else {resolve(null);}});}, /**
   * Gets the Taxonomy Domains for a Course from the cached taxonomy. If the domains are not available then fetch
   * them from the Taxonomy API.
   *
   * @param {TaxonomyRoot} subject - The subject
   * @param {String} courseId - ID of course for which to find the domains
   * @returns {Promise}
   */getCourseDomains:function getCourseDomains(subject,courseId){var apiTaxonomyService=this.get('apiTaxonomyService');var course=subject.get('courses').findBy('id',courseId);return new _ember['default'].RSVP.Promise(function(resolve){if(!course.get('children').length){ // No domains found ... ask for them
apiTaxonomyService.fetchDomains(subject.get('frameworkId'),subject.get('id'),courseId).then(function(domains){course.set('children',domains); // Set reference to parent
domains.forEach(function(domain){domain.setProperties({parent:course,level:2});});resolve(domains);});}else {resolve(course.get('children'));}});}, /**
   * Gets the Taxonomy Codes for a Domain from the cached taxonomy. If the codes are not available then fetch
   * them from the Taxonomy API.
   *
   * @param {TaxonomyRoot} subject - The subject
   * @param {String} courseId - ID of course the domain belongs to
   * @param {String} domainId - ID of domain for which to find the standards
   * @returns {Promise}
   */getDomainCodes:function getDomainCodes(subject,courseId,domainId){var service=this;var apiTaxonomyService=this.get('apiTaxonomyService');var domain;for(var i=subject.get('courses').length - 1;i >= 0;--i) {domain = subject.get('courses')[i].find([courseId,domainId]);if(domain){break;}}return new _ember['default'].RSVP.Promise(function(resolve){if(!domain || !domain.get('children').length){ // No standards found ... ask for them
apiTaxonomyService.fetchCodes(subject.get('frameworkId'),subject.get('id'),courseId,domainId).then(function(codes){var standards=service.createStandardsHierarchy(codes);domain.set('children',standards); // Set reference to parent
standards.forEach(function(standard){standard.set('parent',domain);});resolve(standards);});}else {resolve(domain.get('children'));}});}, /**
   * Finds a Taxonomy Subject by category and subject ID
   *
   * @param {String} category - The classification type
   * @param {String} subjectId - The subject id
   * @returns {TaxonomyRoot}
   */findSubjectById:function findSubjectById(subjectId){var loadCourses=arguments.length <= 1 || arguments[1] === undefined?false:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve){var category=(0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectId);if(category){service.getSubjects(category).then(function(){var result=service.findSubject(category,subjectId);if(result && loadCourses){service.getCourses(result).then(function(){resolve(result);});}else {resolve(result);}});}else {resolve(null);}});},findSubject:function findSubject(categoryId,subjectId){var result=null;var service=this;var taxonomyContainer=service.get('taxonomyContainer');if(taxonomyContainer){var categorySubjects=taxonomyContainer[categoryId];if(categorySubjects){result = categorySubjects.find(function(subject){if(subjectId.includes(subject.id)){return subject;}});if(!result){categorySubjects.forEach(function(subject){if(!result){ // Array forEach function does not have a short circuit, so we are testing is the value has not been found, otherwise just jump to the next element
result = subject.get('frameworks').findBy('id',subjectId);}});}}}return result;},createStandardsHierarchy:function createStandardsHierarchy(codes){var sortedTaxonomyItems=this.sortCodes(codes);var standardsWithoutCategory;var standardsCategories;this.attachChildren(sortedTaxonomyItems[3],3,sortedTaxonomyItems[2],sortedTaxonomyItems[1]);this.attachChildren(sortedTaxonomyItems[2],2,sortedTaxonomyItems[1],sortedTaxonomyItems[0]);standardsWithoutCategory = this.attachStandards(sortedTaxonomyItems[1],sortedTaxonomyItems[0],[]);standardsCategories = this.attachStandardsWithoutCategory(standardsWithoutCategory,sortedTaxonomyItems[0]);return standardsCategories;},sortCodes:function sortCodes(codes){var BASE_LEVEL=this.get('STANDARDS_BASE_LEVEL'); // standards base level
var NUM_BUCKETS=4;var codesLen=codes.length;var buckets=[];for(var i=NUM_BUCKETS - 1;i >= 0;--i) { // Make an array of arrays to store the different levels of standards
buckets[i] = [];}for(var i=0;i < codesLen;i++) {var code=codes[i]; // NOTE: temporarily assign the parentTaxonomyCodeId to the parent property
// It will be replaced in the next phase of the process.
var taxonomyItem=_gooruWebModelsTaxonomyTaxonomyItem['default'].create({id:code.id,code:code.code,title:code.title,parent:code.parentTaxonomyCodeId});switch(code.codeType){case _gooruWebConfigConfig.CODE_TYPES.STANDARD_CATEGORY:taxonomyItem.set('level',BASE_LEVEL);buckets[0].push(taxonomyItem);break;case _gooruWebConfigConfig.CODE_TYPES.STANDARD:taxonomyItem.set('level',BASE_LEVEL + 1);buckets[1].push(taxonomyItem);break;case _gooruWebConfigConfig.CODE_TYPES.SUB_STANDARD:taxonomyItem.set('level',BASE_LEVEL + 2);buckets[2].push(taxonomyItem);break;case _gooruWebConfigConfig.CODE_TYPES.LEARNING_TARGET_L0:case _gooruWebConfigConfig.CODE_TYPES.LEARNING_TARGET_L1:case _gooruWebConfigConfig.CODE_TYPES.LEARNING_TARGET_L2:taxonomyItem.set('level',BASE_LEVEL + 3);buckets[3].push(taxonomyItem);break;default:_ember['default'].Logger.error('Unknown code_type: ' + code.codeType);}}return buckets;},attachChildren:function attachChildren(children,levelOffset,firstLevelParents,secondLevelParents){var _this=this;var BASE_LEVEL=this.get('STANDARDS_BASE_LEVEL'); // standards base level
if(children.length){(function(){var siblings=[],remaining=[];var lead=children.pop();var parentId=lead.get('parent'); // parentTaxonomyCodeId
var parent=firstLevelParents.findBy('id',parentId);children.forEach(function(taxonomyItem){if(taxonomyItem.get('parent') === parentId){siblings.push(taxonomyItem);}else {remaining.push(taxonomyItem);}});if(!parent){var grandparent=secondLevelParents.findBy('id',parentId);if(grandparent){grandparent.set('hasMicroStandard',true); // Use a "fake" parent to close any gaps in the hierarchy
parent = _gooruWebModelsTaxonomyTaxonomyItem['default'].create({id:'empty-' + parentId,level:BASE_LEVEL + (levelOffset - 1), // Level for fake parent
parent:grandparent // Save reference to standard
});grandparent.set('children',[parent].concat(grandparent.get('children')));}else {_ember['default'].Logger.warn('Parent with ID ' + parentId + ' not found for items at level: ' + levelOffset);_this.attachChildren(remaining,levelOffset,firstLevelParents,secondLevelParents);}} // Add the lead in with its siblings
siblings.push(lead);siblings.forEach(function(taxonomyItem){taxonomyItem.set('parent',parent);}); // Concat the list of children with any existing children the parent may already have
parent.set('children',siblings.concat(parent.get('children')));_this.attachChildren(remaining,levelOffset,firstLevelParents,secondLevelParents);})();}},attachStandards:function attachStandards(standards,categories,listWithoutCategory){var _this2=this;if(!standards.length){return listWithoutCategory;}else {var lead=standards.pop();if(!lead.get('parent')){listWithoutCategory.unshift(lead);return this.attachStandards(standards,categories,listWithoutCategory);}else {var _ret2=(function(){var siblings=[],remaining=[];var parentId=lead.get('parent');var parent=categories.findBy('id',parentId);standards.forEach(function(taxonomyItem){if(taxonomyItem.get('parent') === parentId){siblings.push(taxonomyItem);}else {remaining.push(taxonomyItem);}});if(!parent){_ember['default'].Logger.warn('Category with ID ' + parentId + ' not found standards');return {v:_this2.attachStandards(remaining,categories,listWithoutCategory)};} // Add the lead in with its siblings
siblings.push(lead);siblings.forEach(function(taxonomyItem){taxonomyItem.set('parent',parent);}); // Concat the list of children with any existing children the parent may already have
parent.set('children',siblings.concat(parent.get('children')));return {v:_this2.attachStandards(remaining,categories,listWithoutCategory)};})();if(typeof _ret2 === 'object')return _ret2.v;}}},attachStandardsWithoutCategory:function attachStandardsWithoutCategory(standards,categories){var BASE_LEVEL=this.get('STANDARDS_BASE_LEVEL'); // standards base level
var defaultCategory=_gooruWebModelsTaxonomyTaxonomyItem['default'].create({id:'empty-category',level:BASE_LEVEL,children:standards});standards.forEach(function(taxonomyItem){taxonomyItem.set('parent',defaultCategory);});categories.push(defaultCategory);return categories;},fetchSubjectsByIds:function fetchSubjectsByIds(taxonomyIds){var _this3=this;var chain=_ember['default'].A([]);var codes=_ember['default'].A([]);taxonomyIds.forEach(function(taxonomyId){codes.push(taxonomyId.substring(0,taxonomyId.indexOf('-')));});codes = codes.uniq();codes.forEach(function(code){chain.push(_this3.findSubjectById(code,false));});return _ember['default'].RSVP.all(chain);}, /**
   * Gets the Taxonomy classifications  from the cached taxonomy. If the classifications are not available then fetch
   * them from the Taxonomy API.
   *
   * @returns {Promise}
   */getCategories:function getCategories(){var service=this;var apiTaxonomyService=service.get('apiTaxonomyService');return new _ember['default'].RSVP.Promise(function(resolve){var taxonomyCategoriesContainer=service.get('taxonomyCategoriesContainer');if(taxonomyCategoriesContainer){resolve(taxonomyCategoriesContainer);}else {return apiTaxonomyService.fetchCategories().then(function(categories){service.set('taxonomyCategoriesContainer',categories);resolve(categories);});}});},fetchCrossWalkFWC:function fetchCrossWalkFWC(frameworkCode,subjectCode){var service=this;var apiTaxonomyService=service.get('apiTaxonomyService');return new _ember['default'].RSVP.Promise(function(resolve){var crosswalkFWCompetencies=service.get('crosswalkFWCompetencies');if(crosswalkFWCompetencies.get('framework') === frameworkCode && crosswalkFWCompetencies.get('subject') === subjectCode){resolve(crosswalkFWCompetencies);}else {return apiTaxonomyService.fetchCrossWalkFWC(frameworkCode,subjectCode).then(function(crosswalkFWCompetencyMatrix){crosswalkFWCompetencies.set('framework',frameworkCode);crosswalkFWCompetencies.set('subject',subjectCode);service.set('crosswalkFWCompetencies',crosswalkFWCompetencyMatrix);resolve(crosswalkFWCompetencyMatrix);});}});}});});