define('gooru-web/services/api-sdk/course',['exports','ember','gooru-web/mixins/store','gooru-web/serializers/content/course','gooru-web/adapters/content/course'],function(exports,_ember,_gooruWebMixinsStore,_gooruWebSerializersContentCourse,_gooruWebAdaptersContentCourse){ /**
 * Service to support the Course CRUD operations
 *
 * @typedef {Object} CourseService
 */exports['default'] = _ember['default'].Service.extend(_gooruWebMixinsStore['default'],{serializer:null,adapter:null,courseContainer:{}, /**
   * @requires service:api-sdk/lesson
   */lessonService:_ember['default'].inject.service('api-sdk/lesson'), /**
   * @requires service:api-sdk/unit
   */unitService:_ember['default'].inject.service('api-sdk/unit'), /**
   * @property {Service} profileService
   */profileService:_ember['default'].inject.service('api-sdk/profile'),init:function init(){this._super.apply(this,arguments);this.set('serializer',_gooruWebSerializersContentCourse['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('adapter',_gooruWebAdaptersContentCourse['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Creates a new course
   *
   * @param courseModel The Course model to be saved
   * @returns {Promise}
   */createCourse:function createCourse(courseModel){var courseData=this.get('serializer').serializeCreateCourse(courseModel);return this.get('adapter').createCourse({body:courseData}).then(function(courseId){courseModel.set('id',courseId);return courseModel;})['catch'](function(error){return error;});}, /**
   * Returns a course by id
   * @param {string} courseId
   * @returns {Promise|Content/Course}
   */fetchById:function fetchById(courseId,allowCachedCourse){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var courseContainer=service.get('courseContainer');if(allowCachedCourse && courseContainer[courseId]){resolve(courseContainer[courseId]);}else {return service.get('adapter').getCourseById(courseId).then(function(courseData){var course=service.get('serializer').normalizeCourse(courseData);courseContainer[courseId] = course;resolve(courseContainer[courseId]);})['catch'](function(error){reject(error);});}});}, /**
   * Fetch course  details wihtout profile Informations
   *
   */fetchByIdWithOutProfile:function fetchByIdWithOutProfile(courseId){var service=this;return service.get('adapter').getCourseById(courseId).then(function(courseData){var course=service.get('serializer').normalizeCourse(courseData);return course;})['catch'](function(error){return error;});}, /**
   * Fetch course card level detail
   *
   */fetchCoursesCardData:function fetchCoursesCardData(courses){var service=this;if(courses && courses.length > 0){ /*      let resultObj = [];
      courses.forEach(it => {
        let course = {
          courseId: it,
          title: 'My Test Course',
          thumbnailUrl:
            'http://cdn.gooru.org/a6ff0d51-7770-439a-b703-7af60f4f111c.png'
        };
        resultObj.push(course);
      });

      return Ember.RSVP.resolve(resultObj);
*/return service.get('adapter').getCourseCards(courses).then(function(courseCardsData){var courses=service.get('serializer').normalizeCourseCards(courseCardsData);return courses;})['catch'](function(error){return error;});}else {return _ember['default'].RSVP.resolve([]);}}, /**
   * Update existing course
   *
   * @param courseModel The Course model to update
   * @returns {Promise|Content/Course} Course model updated
   */updateCourse:function updateCourse(courseModel){var updateAll=arguments.length <= 1 || arguments[1] === undefined?true:arguments[1];var courseData=this.get('serializer').serializeUpdateCourse(courseModel,updateAll);return this.get('adapter').updateCourse({courseId:courseModel.get('id'),course:courseData}).then(function(){return courseModel;})['catch'](function(error){return error;});}, /**
   * Updates the course title
   *
   * @param courseId the id of the course to be updated
   * @param title
   * @returns {Promise}
   */updateCourseTitle:function updateCourseTitle(courseId,title){var service=this;var serializedData=service.get('serializer').serializeUpdateCourseTitle(title);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').updateCourse({courseId:courseId,course:serializedData}).then(resolve,reject);});}, /**
   * Delete course
   *
   * @param courseId The Course id to delete
   * @returns {Ember.RSVP.Promise}
   */deleteCourse:function deleteCourse(courseId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').deleteCourse(courseId).then(resolve,reject);});}, /**
   * Copies a course by id
   * @param {string} courseId
   * @returns {Ember.RSVP.Promise}
   */copyCourse:function copyCourse(courseId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').copyCourse(courseId).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},reject);});}, /**
   * Reorder course units
   *
   * @param courseId the id of the Course to be updated
   * @param {string[]} unitIds
   * @returns {Promise}
   */reorderCourse:function reorderCourse(courseId,unitIds){var service=this;var serializedData=service.get('serializer').serializeReorderCourse(unitIds);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').reorderCourse(courseId,serializedData).then(resolve,reject);});}, /**
   * Returns the assessment|collection course structure
   * @param {string} courseId
   * @param {string} collectionType collection|assessment
   * @returns {Promise.<Content/Course>}
   */getCourseStructure:function getCourseStructure(courseId,collectionType){var service=this;return service.get('adapter').getCourseStructure(courseId,collectionType).then(function(courseData){return service.get('serializer').normalizeCourseStructure(courseData,collectionType);});}, /**
   * Returns the milestones for the course.
   * @param {string} courseId
   * @param {string} fwCode
   * @returns {Promise.<Milestones>}
   */getCourseMilestones:function getCourseMilestones(courseId,fwCode,class_id,user_id){var service=this;return service.get('adapter').getCourseMilestones(courseId,fwCode,class_id,user_id).then(function(courseData){return service.get('serializer').normalizeCourseMilestones(courseData,fwCode);});}, /**
   * Update co-teacher for course
   * @param courseId
   * @param collaboratorIds the teacher ids
   * @returns {Promise}
   */updateCollaborators:function updateCollaborators(courseId,collaboratorIds){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').updateCollaborators(courseId,collaboratorIds).then(resolve,reject);});}, /**
   * Returns the lessons for the course milestone.
   * @param {string} courseId
   * @param {string} milestoneId
   * @returns {Promise.<Lessons>}
   */getCourseMilestoneLessons:function getCourseMilestoneLessons(courseId,milestoneId){var service=this;return service.get('adapter').getCourseMilestoneLessons(courseId,milestoneId).then(function(courseData){return service.get('serializer').normalizeCourseMilestoneLessons(courseData,milestoneId);});},getPublisherList:function getPublisherList(Pubisherlimit){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('adapter').getPublisherList(Pubisherlimit).then(function(payload){resolve(service.get('serializer').normalizePublisherList(payload));},reject);});},getReadActivity:function getReadActivity(requestParams){var service=this;return service.get('adapter').getReadActivity(requestParams).then(function(courseData){return courseData;});},NotLinkedActivity:function NotLinkedActivity(requestParams){var service=this;return service.get('adapter').NotLinkedActivity(requestParams).then(function(courseData){return courseData;});},fetchAttemptCount:function fetchAttemptCount(classId,memberId,params){var service=this;return service.get('adapter').fetchAttemptCount(classId,memberId,params).then(function(data){return data;});},getAttempt:function getAttempt(classId,memberId){var service=this;return service.get('adapter').getAttempt(classId,memberId).then(function(data){return data;});}});});