define('gooru-web/utils/question/hot-spot-image',['exports','gooru-web/utils/question/multiple-answer','gooru-web/utils/question/answer-object','ember'],function(exports,_gooruWebUtilsQuestionMultipleAnswer,_gooruWebUtilsQuestionAnswerObject,_ember){ /**
 * It contains convenience methods for grading and retrieving useful information
 * for HS Image
 *
 *
 * # Answer object (structure required by the BE)
 *
 *   It is an array containing a json object for each user selection, it includes all possible choices
 *   event they were not selected by the user
 *
 *   text contains the image url
 *   status could be correct, incorrect or null based on the user selection, null when no selected
 *   order represents the order of this user selection
 *   answerId corresponds to the answer choice id selected
 *   skip indicates if the option was selected or not
 *
 *
 *  [ {"text":"http//:ht_img/1.jpg","status":null,"order":1,"answerId":1234,"skip":true},
 *  {"text":"http//:ht_img/2.jpg","status":"incorrect","order":2,"answerId":1234,"skip":false},
 *  {"text":"http//:ht_img/3.jpg","status":null,"order":3,"answerId":1234,"skip":true} ]
 *
 * # User answer (structure used by the FE)
 *
 *   It corresponds to an array representing the user selection, answerIds selected
 *
 *   [ "1", "2", "6"]
 *
 * @typedef {Object} HotSpotImageUtil
 */exports['default'] = _gooruWebUtilsQuestionMultipleAnswer['default'].extend({ // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
/**
   * Indicates if the answer choice is correct
   * @param { string } answerChoice
   */isAnswerChoiceCorrect:function isAnswerChoiceCorrect(answerChoice){var correctAnswer=this.getCorrectAnswer();return correctAnswer.includes(answerChoice);}, /**
   * Gets the correct answer
   * @return {string[]} returns the correct answer choice ids
   */getCorrectAnswer:function getCorrectAnswer(){var answers=this.get('question.answers');var correctAnswers=answers.filterBy('isCorrect',true);return correctAnswers.map(function(answer){return answer.get('id');});}, /**
   * Returns a unique key representing the answer
   * For hot spot image the answer is an array of ids
   * @param { string[] } answer
   * @returns {string} i.e id1,id2,id3
   */answerKey:function answerKey(answer){return answer.sort().join();}, /**
   * Converts the model user answer into an answerObject format
   *
   * @param { string[] } userAnswer
   * @return {AnswerObject[]}
   *
   * @see '# User Answer' section at class comment
   * @see '# Answer Object' section at class comment
   */toAnswerObjects:function toAnswerObjects(userAnswer){var util=this;var answers=this.get('question.answers');return answers.map(function(answer){var answerId=answer.get('id');var selected=userAnswer.includes(answerId);var answerObject=_gooruWebUtilsQuestionAnswerObject['default'].create({text:answer.get('text'),order:answer.get('order'),answerId:answerId,skip:!selected});if(selected){answerObject.set('correct',util.isAnswerChoiceCorrect(answerId));}return answerObject;});}, /**
   * Converts an answerObject format to model userAnswer
   *
   * @param {AnswerObject[]} answerObjects
   * @return { string[] } answer selections
   *
   * @see '# User Answer' section at class comment
   * @see '# Answer Object' section at class comment
   */toUserAnswer:function toUserAnswer(answerObjects){return !answerObjects || !answerObjects.length?null //if not respond is provided
:answerObjects.filterBy('skip',false).map(function(answerObject){return _ember['default'].get(answerObject,'answerId');});}});});