define('gooru-web/utils/gru-theme',['exports','ember'],function(exports,_ember){ /**
 * This util class holds the information for a Gooru Theme.
 * Gooru themes are useful to setup the application branding, messages and locale
 *
 * @typedef {object} GruTheme
 */exports['default'] = _ember['default'].Object.extend({ // -------------------------------------------------------------------------
// Properties
/**
   * @property {string} theme id
   */id:null, /**
   * css url
   * @property {string}
   */cssUrl:_ember['default'].computed('id',function(){var id=this.get('id');return 'assets/themes/' + id + '/styles.css';})});});