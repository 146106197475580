define('gooru-web/adapters/content/assessment',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Assessment CRUD operations in the API 3.0
 *
 * @typedef {Object} AssessmentAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/assessments',collectionNamespace:'/api/nucleus/v1/collections',copierNamespace:'/api/nucleus/v1/copier',externalNamespace:'/api/nucleus/v1/assessments-external',updateNamespace:'/api/nucleus/v1', /**
   * Posts a new assessment
   *
   * @param data assessment data to be sent in the request body
   * @returns {Promise}
   */createAssessment:function createAssessment(data){var adapter=this;var url=this.get('namespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data.body)};return _ember['default'].$.ajax(url,options);}, /**
   * Reads an Assessment by id
   *
   * @param {string} assessmentId
   * @returns {Promise}
   */readAssessment:function readAssessment(assessmentId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + assessmentId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Reads an Assessment by id
   *
   * @param {string} assessmentId
   * @returns {Promise}
   */readAssessmentDCA:function readAssessmentDCA(assessmentId,assessmentType){var adapter=this;var namespace=adapter.get('namespace');if(!assessmentType){namespace = adapter.get('collectionNamespace');}var url=namespace + '/' + assessmentId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Reads an External Assessment by id
   *
   * @param {string} assessmentId
   * @returns {Promise}
   */readExternalAssessment:function readExternalAssessment(assessmentId){var adapter=this;var namespace=adapter.get('externalNamespace');var url=namespace + '/' + assessmentId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Update an Assessment
   *
   * @param assessmentId the id of the Assessment to be updated
   * @param data Assessment data to be sent in the request body
   * @returns {Promise}
   */updateAssessment:function updateAssessment(assessmentId,data){var type=arguments.length <= 2 || arguments[2] === undefined?'assessments':arguments[2];var adapter=this;var namespace=this.get('updateNamespace');var url=namespace + '/' + type + '/' + assessmentId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes an assessment by id
   *
   * @param assessmentId assessment id to be sent
   * @returns {Promise}
   */deleteAssessment:function deleteAssessment(assessmentId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + assessmentId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes an assessment by id
   *
   * @param assessmentId assessment id to be sent
   * @returns {Promise}
   */deleteExternalAssessment:function deleteExternalAssessment(assessmentId){var adapter=this;var namespace=this.get('externalNamespace');var url=namespace + '/' + assessmentId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Adds a question to an assessment
   *
   * @param {string} assessmentId
   * @param {string} questionId
   * @returns {Promise}
   */addQuestion:function addQuestion(assessmentId,questionId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + assessmentId + '/questions';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',headers:adapter.defineHeaders(),data:JSON.stringify({id:questionId})};return _ember['default'].$.ajax(url,options);}, /**
   * Copies an assessment by id
   *
   * @param assessmentId
   * @returns {Promise}
   */copyAssessment:function copyAssessment(assessmentId){var type=arguments.length <= 1 || arguments[1] === undefined?'assessments':arguments[1];var adapter=this;var namespace=this.get('copierNamespace');var url=namespace + '/' + type + '/' + assessmentId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Reorder assessment resources
   *
   * @param assessmentId the id of the Assessment to be updated
   * @param data Assessment data to be sent in the request body
   * @returns {Promise}
   */reorderAssessment:function reorderAssessment(assessmentId,data){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + assessmentId + '/questions/order';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Find  the mastery accrual for the given list of assessmentIds
   *
   * @param {string} assessmentIds
   * @returns {Promise}
   */assessmentsMasteryAccrual:function assessmentsMasteryAccrual(assessmentIds){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/mastery-accrual';var options={type:'POST',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:JSON.stringify({assessmentIds:assessmentIds})};return _ember['default'].$.ajax(url,options);},diagnosticAssessment:function diagnosticAssessment(data,assessmentId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + assessmentId + '/diagnostic/setting';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});