define('gooru-web/components/player/questions/gru-fib',['exports','ember','gooru-web/components/player/questions/gru-question','gooru-web/utils/question/fill-in-the-blank'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruQuestion,_gooruWebUtilsQuestionFillInTheBlank){ /**
 * Fill in the blank
 *
 * Component responsible for controlling the logic and appearance of a fill in the blank
 * question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-fib'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
initInputEvents:(function(){var component=this;component.setAnswersEvents();}).on('didInsertElement'), // -------------------------------------------------------------------------
// Properties
/**
   * Replace "_______" to an input
   * @param question
   *
   */answers:_ember['default'].computed('question.text',function(){var component=this;var answers=component.get('question.fibText');var readOnly=component.get('readOnly');var disabled=readOnly?'disabled':'';if(component.get('hasUserAnswer')){var userAnswer=component.get('userAnswer');userAnswer.forEach(function(choice){var input='<input type="text" value="' + choice + '" ' + disabled + '/>';answers = answers.replace(_gooruWebUtilsQuestionFillInTheBlank['default'].LEGACY_REGEX.single,input);});return answers;}else {var input='<input type="text" value="" ' + disabled + '/>';return answers.replace(_gooruWebUtilsQuestionFillInTheBlank['default'].LEGACY_REGEX.global,input);}}), // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
/**
   * Notify input answers
   * @param {boolean} onLoad if this was called when loading the component
   */notifyInputAnswers:function notifyInputAnswers(onLoad){var component=this,inputs=component.$('.fib-answers input[type=text]'),answers=inputs.map(function(index,input){var answer=_ember['default'].$(input).val();return _ember['default'].$.trim(answer);}).toArray();var answerCompleted=answers.join('').length > 0; //to check that at least 1 answer has text
var questionUtil=component.get('questionUtil');var correct=questionUtil.isCorrect(answers);component.notifyAnswerChanged(answers,correct);if(answerCompleted){if(onLoad){component.notifyAnswerLoaded(answers,correct);}else {component.notifyAnswerCompleted(answers,correct);}}else {component.notifyAnswerCleared(answers);}}, /**
   * Set answers
   */setAnswersEvents:function setAnswersEvents(){var component=this;var inputs=component.$('.fib-answers');if(component.get('hasUserAnswer')){component.notifyInputAnswers(true);}inputs.on('keyup','input[type=text]',function(){component.notifyInputAnswers(false);});}});});