define('gooru-web/components/reports/pull-up/milestone/student-milestone-summary-report',['exports','ember','gooru-web/utils/performance-data','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsPerformanceData,_gooruWebUtilsUtils){function _toConsumableArray(arr){if(Array.isArray(arr)){for(var i=0,arr2=Array(arr.length);i < arr.length;i++) arr2[i] = arr[i];return arr2;}else {return Array.from(arr);}}exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['milestone-report','student-milestone-summary-report'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/performance
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), /**
   * @requires service:api-sdk/course
   */courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:api-sdk/rescope
   */rescopeService:_ember['default'].inject.service('api-sdk/rescope'), /**
   * @requires service: api-sdk/course-map
   */courseMapService:_ember['default'].inject.service('api-sdk/course-map'), /**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadMilestoneReportData();if(component.get('isTeacher')){component.fetchMilestones();}}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when switch between next/previous milestone
onSlideMilestone:function onSlideMilestone(direction){var component=this;var activeMilestoneIndex=component.get('activeMilestoneIndex');activeMilestoneIndex = direction === 'left'?activeMilestoneIndex - 1:activeMilestoneIndex + 1;component.set('activeMilestoneIndex',activeMilestoneIndex);component.set('activeMilestone',component.get('milestones').objectAt(activeMilestoneIndex));setTimeout(function(){component.loadMilestoneReportData();},1000);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} classId
   */classId:_ember['default'].computed.alias('class.id'), /**
   * @property {UUID} courseId
   */courseId:_ember['default'].computed.alias('class.courseId'), /**
   * @property {UUID} milestoneId
   */milestoneId:_ember['default'].computed('activeMilestone',function(){return this.get('activeMilestone.milestone_id') || this.get('activeMilestone.milestoneId');}), /**
   * @property {String} frameworkCode
   */frameworkCode:_ember['default'].computed.alias('class.preference.framework'), /**
   * @property {Array} milestones
   */milestones:_ember['default'].A([]), /**
   * @property {Object} activeMilestone
   */activeMilestone:{}, /**
   * @property {Array} milestoneLessons
   */milestoneLessons:_ember['default'].A([]), /**
   * @property {Boolean} isMilestoneNotStarted
   */isMilestoneNotStarted:_ember['default'].computed('activeMilestone.performance',function(){return !this.get('activeMilestone.performance');}), /**
   * @property {Number} activeMilestoneIndex
   */activeMilestoneIndex:_ember['default'].computed(function(){var component=this;var activeMilestone=component.get('activeMilestone');var milestones=component.get('milestones');return milestones.indexOf(activeMilestone);}), /**
   * @property {String} placeholderText
   */placeholderText:_ember['default'].computed('activeMilestone.performance','diagnosticLessonCount',function(){var component=this;var milestonePerformance=component.get('activeMilestone.performance');var placeholderText=component.get('i18n').t('profile.proficiency.not-started').string;if(milestonePerformance){var completedCount=milestonePerformance.get('completedCount');var totalCount=milestonePerformance.get('totalCount');var diagnosticLessonCount=component.get('diagnosticLessonCount');var isDoneDiagnostic=component.get('isDoneDiagnostic');placeholderText = completedCount || totalCount?completedCount === totalCount?component.get('i18n').t('common.completed').string + '!':(isDoneDiagnostic?completedCount + diagnosticLessonCount:completedCount) + '/' + (totalCount + diagnosticLessonCount) + ' ' + component.get('i18n').t('common.lessonObj.zero').string:null;}return placeholderText;}), /**
   * @property {Boolean} isSlideLeftDisabled
   */isSlideLeftDisabled:_ember['default'].computed.equal('activeMilestoneIndex',0), /**
   * @property {Boolean} isSlideRightDisabled
   */isSlideRightDisabled:_ember['default'].computed('activeMilestoneIndex','milestones',function(){var component=this;return component.get('activeMilestoneIndex') === component.get('milestones.length') - 1;}), /**
   * @property {Array} rescopedCollectionIds
   */rescopedCollectionIds:_ember['default'].A([]), /**
   * @property {Object} rescopedContents
   */rescopedContents:null, /**
   * @property {Boolean} isDisableMilestoneSlider
   */isDisableMilestoneSlider:_ember['default'].computed.equal('milestones.length',0), /**
   * @property {Boolean} isTeacher
   */isTeacher:false,diagnosticLessons:_ember['default'].A(),diagnosticLessonCount:0,isDoneDiagnostic:false,isUnit0Milestone:false, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadMilestoneReportData
   */loadMilestoneReportData:function loadMilestoneReportData(){var component=this;component.set('isLoading',true);if(this.get('isRoute0MileStone') || this.get('activeMilestone.isRoute0') || this.get('activeMilestone.isUnit0')){var activeMilestone=this.get('activeMilestone');component.set('milestoneLessons',activeMilestone.get('lessons'));component.loadMilestoneReportPerformanceData();component.set('isLoading',false);component.set('isUnit0Milestone',!!activeMilestone.isUnit0);}else {var rescopedContents=component.get('rescopedContents');var pathParams={classId:component.get('classId'),userId:component.get('userId')};return _ember['default'].RSVP.hash({milestoneLessons:component.fetchMilestoneLessons(),rescopedContents:rescopedContents || component.fetchRescopedContents(),dependentPaths:component.get('courseMapService').fetchMilestoneDependentPath(component.get('milestoneId'),pathParams)}).then(function(hash){if(!component.isDestroyed){component.set('rescopedContents',hash.rescopedContents);component.set('milestoneLessons',hash.milestoneLessons);component.parseDiagnosticLessons(component.get('diagnosticLessons'),hash.milestoneLessons);component.parseDependentLessons(hash.dependentPaths,hash.milestoneLessons);component.loadMilestoneReportPerformanceData();component.parseRescopedContents();component.set('isLoading',false);}});}}, /**
   * @function loadMilestoneReportPerformanceData
   */loadMilestoneReportPerformanceData:function loadMilestoneReportPerformanceData(){var component=this;if(component.get('activeMilestone.performance') || component.get('isTeacher')){component.loadMilestonePerformanceScore();component.loadMilestonePerformanceTimespent();}}, /**
   * @function loadMilestonePerformanceScore
   */loadMilestonePerformanceScore:function loadMilestonePerformanceScore(){var component=this;var collectionType='assessment';component.fetchMilestonePerformance(collectionType).then(function(milestoneLessonsPerformance){component.parseLessonsPerformanceScore(milestoneLessonsPerformance);});}, /**
   * @function loadMilestonePerformanceTimespent
   */loadMilestonePerformanceTimespent:function loadMilestonePerformanceTimespent(){var component=this;var collectionType='collection';component.fetchMilestonePerformance(collectionType).then(function(milestoneLessonsPerformance){component.parseLessonsPerformanceTimespent(milestoneLessonsPerformance);});}, /**
   * @function fetchMilestones
   */fetchMilestones:function fetchMilestones(){var component=this;var frameworkCode=component.get('frameworkCode');var courseId=component.get('courseId');return _ember['default'].RSVP.hash({milestones:component.get('courseService').getCourseMilestones(courseId,frameworkCode)}).then(function(_ref){var milestones=_ref.milestones;if(!component.isDestroyed){component.set('milestones',component.renderMilestonesBasedOnStudentGradeRange(milestones));}});}, /**
   * @function fetchMilestonePerformance
   */fetchMilestonePerformance:function fetchMilestonePerformance(collectionType){var component=this;var performanceService=component.get('performanceService');var classId=component.get('classId');var courseId=component.get('courseId');var milestoneId=component.get('milestoneId');var userId=component.get('userId');var frameworkCode=component.get('frameworkCode');return performanceService.getLessonsPerformanceByMilestoneId(classId,courseId,milestoneId,collectionType,userId,frameworkCode);}, /**
   * @function fetchMilestoneLessons
   */fetchMilestoneLessons:function fetchMilestoneLessons(){var component=this;var courseService=component.get('courseService');var courseId=component.get('courseId');var milestoneId=component.get('milestoneId');return courseService.getCourseMilestoneLessons(courseId,milestoneId);}, /**
   * @function fetchRescopedContents
   * Method to get rescoped contents
   */fetchRescopedContents:function fetchRescopedContents(){var component=this;var classId=component.get('classId');var courseId=component.get('courseId');return _ember['default'].RSVP.hash({rescopedContents:component.get('rescopeService').getSkippedContents({classId:classId,courseId:courseId})}).then(function(_ref2){var rescopedContents=_ref2.rescopedContents;return rescopedContents;})['catch'](function(){return null;});}, /**
   * @function parseLessonsPerformanceScore
   */parseLessonsPerformanceScore:function parseLessonsPerformanceScore(lessonsPerformance){var component=this;var lessons=component.get('milestoneLessons');if(lessonsPerformance && lessonsPerformance.length && !component.isDestroyed){lessonsPerformance.map(function(lessonPerformance){var lessonData=lessons.findBy('lesson_id',lessonPerformance.get('lessonId'));if(lessonData){lessonData.set('performance',lessonPerformance.get('performance'));}});if(component.get('isTeacher')){var activeMilestone=component.get('activeMilestone');var milestonePerformanceScore=(0,_gooruWebUtilsPerformanceData.aggregateMilestonePerformanceScore)(lessonsPerformance);if(activeMilestone.get('performance')){activeMilestone.set('performance.score',milestonePerformanceScore);activeMilestone.set('performance.scoreInPercentage',milestonePerformanceScore);}else {activeMilestone.set('performance',_ember['default'].Object.create({score:milestonePerformanceScore,scoreInPercentage:milestonePerformanceScore}));}}}}, /**
   * @function parseLessonsPerformanceTimespent
   */parseLessonsPerformanceTimespent:function parseLessonsPerformanceTimespent(lessonsPerformance){var component=this;var lessons=component.get('milestoneLessons');var milestoneTimespent=0;if(lessonsPerformance && lessonsPerformance.length && !component.isDestroyed){lessonsPerformance.map(function(lessonPerformance){var lessonTimespent=lessonPerformance.get('performance.timeSpent');var lessonData=lessons.findBy('lesson_id',lessonPerformance.get('lessonId'));if(lessonData){if(lessonData.get('performance')){lessonData.set('performance.timeSpent',lessonTimespent);}else {lessonData.set('performance',_ember['default'].Object.create({timeSpent:lessonTimespent}));}}milestoneTimespent += lessonTimespent;});}if(!component.isDestroyed){var activeMilestone=component.get('activeMilestone');if(activeMilestone.get('performance')){activeMilestone.set('performance.timeSpent',milestoneTimespent);}else {activeMilestone.set('performance',_ember['default'].Object.create({timeSpent:milestoneTimespent}));}}}, /**
   * @function parseRescopedContents
   */parseRescopedContents:function parseRescopedContents(){var component=this;var rescopedContents=component.get('rescopedContents');if(rescopedContents){(function(){var milestoneLessons=component.get('milestoneLessons');var rescopedLessons=rescopedContents.lessons;if(!component.isDestroyed && rescopedLessons){rescopedLessons.map(function(rescopedLesson){var milestoneLesson=milestoneLessons.findBy('lesson_id',rescopedLesson);if(milestoneLesson){milestoneLesson.set('isRescopedLesson',true);}});component.extractRescopedCollections();}})();}}, /**
   * @function extractRescopedCollections
   * Method to extract all content type ids which is rescoped
   */extractRescopedCollections:function extractRescopedCollections(){var component=this;var rescopedContents=component.get('rescopedContents');var rescopedCollectionIds=_ember['default'].A([]);rescopedCollectionIds.pushObjects(rescopedContents.assessments);rescopedCollectionIds.pushObjects(rescopedContents.collections);rescopedCollectionIds.pushObjects(rescopedContents.assessmentsExternal);rescopedCollectionIds.pushObjects(rescopedContents.collectionsExternal);rescopedCollectionIds.pushObjects(rescopedContents.offlineActivities);if(!component.isDestroyed){component.set('rescopedCollectionIds',rescopedCollectionIds);}}, /**
   * This Method is responsible for milestone display based on students class grade.
   * @return {Array}
   */renderMilestonesBasedOnStudentGradeRange:function renderMilestonesBasedOnStudentGradeRange(milestones){var component=this;var milestoneData=_ember['default'].A([]);var classGradeId=component.get('class.gradeCurrent');milestones.forEach(function(milestone){var gradeId=milestone.get('grade_id');if(classGradeId === gradeId){milestone.set('isClassGrade',true);}milestoneData.pushObject(milestone);});return milestoneData;},parseDiagnosticLessons:function parseDiagnosticLessons(diagnosticLessons,lessons){var _this=this;if(diagnosticLessons){diagnosticLessons.forEach(function(domainItem){var lessonSuggestions=domainItem.lessonSuggestions || [];var activeDomains=lessons.filter(function(lesson){var context=domainItem.context;return lesson.tx_domain_code === context.domainCode;});if(activeDomains.length){(function(){var activeLesson=activeDomains.get(0);if(activeLesson){var diagnosticStats=domainItem.diagnosticStats;if(lessonSuggestions.length){_this.set('diagnosticLessonCount',lessonSuggestions.length);_this.set('isDoneDiagnostic',diagnosticStats.get('status') === 'complete');lessonSuggestions = lessonSuggestions.map(function(lessonSuggetion){var newLessonItem=(0,_gooruWebUtilsUtils.getObjectCopy)(activeLesson);newLessonItem.setProperties({lesson_id:lessonSuggetion.lesson_id,collections:lessonSuggetion.collections,lesson_title:lessonSuggetion.title,tx_domain_name:activeLesson.tx_domain_name,isDiagnosticLesson:true,unit_id:domainItem.context.ctxUnitId});return newLessonItem;});}if(diagnosticStats){var sugesstionStatusItem={lesson_id:null,lesson_title:_this.get('i18n').t('diagnostic.title').string,isDiagnostic:true,diagnosticStatus:diagnosticStats.status,firstCollHasSuggsType:'teacher'};lessonSuggestions.pushObject(_ember['default'].Object.create(sugesstionStatusItem));}lessons.splice.apply(lessons,[lessons.indexOf(activeLesson),0].concat(_toConsumableArray(lessonSuggestions)));}})();}});}}, /**
   * @function parseDependentLessons
   * Help to merge the dependent lessons to the milestone lessons
   */parseDependentLessons:function parseDependentLessons(dependentLessons,lessons){var dependentPaths=dependentLessons || _ember['default'].A([]);dependentPaths.sortBy('id').forEach(function(depLesson){var activeLesson=lessons.findBy('lesson_id',depLesson.cxtLessonId);if(activeLesson){depLesson.setProperties({lesson_title:depLesson.lesson_title,tx_domain_name:activeLesson.tx_domain_name,isDiagnosticLesson:true,isDependentLesson:true,rescope:false,firstCollHasSuggsType:'system',unit_id:activeLesson.unit_id,lesson_id:depLesson.lesson_id});lessons.splice(lessons.indexOf(activeLesson),0,depLesson);activeLesson.set('prevLeCollHasSuggsType','system');activeLesson.set('diagnosticEnd',true);}});}});});