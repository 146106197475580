define('gooru-web/components/century-skills/modals/gru-century-skills',['exports','ember'],function(exports,_ember){ /**
 * 21st century skills
 *
 * Component responsible for displaying three panels of 21st century skills
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['century-skills','modals','gru-century-skills'], // -------------------------------------------------------------------------
// Actions
actions:{updateSelectedSkills:function updateSelectedSkills(selectedCenturySkills){this.get('model.callback').success(selectedCenturySkills);this.triggerAction({action:'closeModal'});}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);var selectedCenturySkills=component.get('model.selectedCenturySkills');var centurySkills=component.get('model.centurySkills');if(selectedCenturySkills){this.set('selectedCenturySkills',selectedCenturySkills);}if(centurySkills){this.set('centurySkills',centurySkills);}},willDestroyElement:function willDestroyElement(){this._super.apply(this,arguments);this.set('centurySkills',null);this.set('selectedCenturySkills',null);}, // -------------------------------------------------------------------------
// Properties
/**
   * List of Century Skills
   * @prop {CenturySkill[]}
   */centurySkills:_ember['default'].A([]), /**
   * List of selected Century Skills ids
   * @prop {Number[]}
   */selectedCenturySkills:_ember['default'].A([])});});