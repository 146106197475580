define('gooru-web/adapters/rescope/rescope',['exports','ember','gooru-web/adapters/application'],function(exports,_ember,_gooruWebAdaptersApplication){ /**
 * Adapter to fetch rescope related information from the data scope
 */exports['default'] = _gooruWebAdaptersApplication['default'].extend({ /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @type {String}} base url for course map API endpoints
   */namespace:'/api/rescope/v1/scope', /**
   * Method to fetch skipped items from the API
   * @function getSkippedContents
   * @returns {Promise}
   */getSkippedContents:function getSkippedContents(filter){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/skipped';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.get('headers'),data:filter};return _ember['default'].$.ajax(url,options);}, /**
   * @function getRescopeItems
   * Method to fetch rescoped items from the API
   */getRescopeItems:function getRescopeItems(params){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/skipped';var options={type:'POST',contentType:'application/json; charset=utf-8',headers:adapter.get('headers'),data:JSON.stringify(params)};return _ember['default'].$.ajax(url,options);}});});