define('gooru-web/components/reports/class-assessment/gru-table-view',['exports','ember','gooru-web/utils/utils','gooru-web/utils/question-result','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebUtilsQuestionResult,_gooruWebMixinsConfiguration){ /**
 * Class assessment table view
 *
 * Component responsible for filtering and transforming the class assessment data
 * into a format readily consumable by the gru-two-tier-header-table component.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['reports','class-assessment','gru-table-view'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * @function actions:selectQuestion
     * @param {Number} questionId
     */selectQuestion:function selectQuestion(questionId){this.get('onSelectQuestion')(questionId);}, /**
     * @function actions:selectStudent
     * @param {string} studentId
     */selectStudent:function selectStudent(studentId){this.get('onSelectStudent')(studentId);}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('questionProperties',this.initQuestionProperties());this.set('studentsHeader',this.initStudentsHeader());},willDestroyElement:function willDestroyElement(){this.set('questionProperties',null);this.set('studentsHeader',null);}, // -------------------------------------------------------------------------
// Properties
/**
   * @prop { Collection } assessment
   */assessment:null, /**
   * @prop { Object[] } assessmentQuestions - An array made up of all the questions in the assessment
   *
   * Each question object will consist of:
   * - label: visual representation of the header
   * - value: internal header identifier
   *
   * The questions will be ordered in the array in ascending order per the order value
   */assessmentQuestions:_ember['default'].computed('assessment.resources.[]',function(){var labelPrefix=this.get('i18n').t('reports.gru-table-view.first-tier-header-prefix').string;var questions=this.get('assessment.resources').sortBy('order').map(function(question,index){return {value:question.get('id'),label:labelPrefix + (index + 1)};}); // Add column used for showing totals at the beginning of the array
questions.unshift({value:-1,label:this.get('i18n').t('reports.gru-table-view.totals').string});return questions;}), /**
   * @prop { String[] } assessmentQuestionsIds - An array with the ids of all the questions in the assessment
   */assessmentQuestionsIds:_ember['default'].computed('assessmentQuestions.[]',function(){return this.get('assessmentQuestions').map(function(question){return question.value;});}), /**
   * @prop { Object[] } questionProperties - An array made up of question properties
   *
   * Each property object will consist of:
   * - filter: information to use for the corresponding filter checkbox
   * - label: visual representation of the header
   * - value: internal header identifier
   * - visible: should the property be visible or not?
   * - renderFunction: function to process values of this property for output
   * - aggregateFunction: if there's an aggregate column, this function will be
   *   used to aggregate all the values for this property that are in the same row
   * - aggregateRenderFunction: if there's an aggregate column, this function will
   *   take the result of the aggregateFunction and process it for output
   * - sortFunction: sort function for values of this property
   */questionProperties:null, /**
   * @prop { String[] } questionPropertiesIds - An array with the ids of all the question properties
   */questionPropertiesIds:_ember['default'].computed('questionProperties',function(){return this.get('questionProperties').map(function(questionProperty){return questionProperty.value;});}), /**
   * @prop { ReportData } reportData - Unordered 3D matrix of data to use as content for the table component
   */reportData:null, /**
   * Indicates if the report is displayed in anonymous mode
   * @property {boolean} anonymous
   */anonymous:false, /**
   * @prop { User[] } students - Students taking the assessment
   */students:null, /**
   * @prop { String? } studentsHeader - Header for the students names
   */studentsHeader:null, /**
   * @prop { String[] } studentsIds - An array with the ids of all the students taking the assessment
   */studentsIds:_ember['default'].computed('students.[]',function(){return this.get('students').map(function(student){return student.id;});}), /**
   * @prop { Object[] } tableData - Ordered data to use as content for the table component
   * It merges the existing table frame with any updated table data.
   *
   * Each object in the array will consist of:
   * - id: row id
   * - header: row header
   * - content: an array of objects making up the row content where each object is made up of:
   *   - value: table cell un-formatted content
   *   - output: table cell content formatted for output (the formatting is done by
   *             the question property's render function)
   */tableData:_ember['default'].computed('anonymous','tableFrame','reportData.data',function(){var studentsIds=this.get('studentsIds');var studentsIdsLen=studentsIds.length;var questionsIds=this.get('assessmentQuestionsIds');var questionsIdsLen=questionsIds.length;var questionProperties=this.get('questionProperties');var questionPropertiesIds=this.get('questionPropertiesIds');var questionPropertiesIdsLen=questionPropertiesIds.length;var reportData=this.get('reportData.data'); // Copy the table frame contents
var data=this.get('tableFrame').slice(0);var totalIndex,propertyValues; // function start after the complete data recieved from previous component
_ember['default'].run.schedule('afterRender',this,function(){ // Get the value of each question property, for each question, for each student
for(var i=0;i < studentsIdsLen;i++) { // Array for storing all values of the same question property
propertyValues = [];for(var k=0;k < questionPropertiesIdsLen;k++) { // Put all values for the same property into an array
propertyValues[k] = [];}for(var j=0;j < questionsIdsLen;j++) {var labelPrefix=this.get('i18n').t('reports.gru-table-view.first-tier-header-prefix').string;if(questionsIds[j] === -1){ // Save this position to fill it in last (cells with propertyValues)
totalIndex = j;continue;}for(var k=0;k < questionPropertiesIdsLen;k++) {var renderFunction=questionProperties[k].renderFunction;var questionResult=reportData[studentsIds[i]][questionsIds[j]];var value=questionResult[questionPropertiesIds[k]];var label=undefined; //label used for the score tooltip
if(k === 0){label = labelPrefix + j;}data[i].content[j * questionPropertiesIdsLen + k] = {label:label,value:value,output:!renderFunction?value:renderFunction(value)};propertyValues[k].push(questionResult);}}for(var k=0;k < questionPropertiesIdsLen;k++) { // Set the value in the aggregate (totals) column;
var value=questionProperties[k].aggregateFunction(propertyValues[k]);var aggregateRenderFunction=questionProperties[k].aggregateRenderFunction; // For displaying the aggregate value, use the question property's aggregateRenderFunction.
// If there's no aggregateRenderFunction, use the property's renderFunction by default.
data[i].content[totalIndex * questionPropertiesIdsLen + k] = {value:value,output:aggregateRenderFunction?aggregateRenderFunction(value):questionProperties[k].renderFunction(value)};} // Compute the aggregate values
}});return data;}), /**
   * @prop {Object[]} tableFrame - The table frame that encloses the table data
   * @return {Object[]}
   */tableFrame:_ember['default'].computed('anonymous','students.[]',function(){var anonymous=this.get('anonymous');return this.get('students').map(function(student){return {id:student.get('id'),header:anonymous?student.get('code'):student.get('fullName'),lastFirstName:anonymous?student.get('code'):student.get('lastFirstName'),content:[]};});}), // -------------------------------------------------------------------------
// Methods
/**
   * Initialize the question properties array with values -including i18n labels
   * @return {Object[]}
   */initQuestionProperties:function initQuestionProperties(){return [_ember['default'].Object.create({filter:{label:this.get('i18n').t('reports.gru-table-view.scores').string,disabled:true},label:this.get('i18n').t('reports.gru-table-view.score').string,value:'correct',visible:true,renderFunction:_gooruWebUtilsUtils.getAnswerResultIcon,aggregateFunction:_gooruWebUtilsQuestionResult.correctPercentage,aggregateRenderFunction:_gooruWebUtilsUtils.getScoreString}),_ember['default'].Object.create({filter:{label:this.get('i18n').t('reports.gru-table-view.study-time').string},label:this.get('i18n').t('reports.gru-table-view.time').string,value:'timeSpent',renderFunction:_gooruWebUtilsUtils.formatTime,aggregateFunction:_gooruWebUtilsQuestionResult.totalTimeSpent}),_ember['default'].Object.create({filter:{label:this.get('i18n').t('reports.gru-table-view.reactions').string},label:this.get('i18n').t('reports.gru-table-view.reaction').string,value:'reaction',renderFunction:function renderFunction(value){return (0,_gooruWebUtilsUtils.getReactionIcon)(value);},aggregateFunction:_gooruWebUtilsQuestionResult.averageReaction})];}, /**
   * Initialize the students header object with values including an i18n label
   * @return {Object[]}
   */initStudentsHeader:function initStudentsHeader(){return {label:this.get('i18n').t('reports.gru-table-view.student').string,value:'fullName',sortFunction:_gooruWebUtilsUtils.alphabeticalStringSort};}});});