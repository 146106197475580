define('gooru-web/adapters/network/network',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Network (followers and following) CRUD operations in the API 3.0
 *
 * @typedef {Object} NetworkAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/profiles', /**
   * Gets my network information
   *
   * @returns {Promise}
   */readMyNetwork:function readMyNetwork(){var adapter=this;var namespace=adapter.get('namespace');var myUserId=this.get('session.userId');var url=namespace + '/' + myUserId + '/network';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{details:'followers'}};return _ember['default'].$.ajax(url,options);}, /**
   * Gets network information for a given user id
   *
   * @returns {Promise}
   */readUserNetwork:function readUserNetwork(userId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + userId + '/network';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:{details:''}};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});