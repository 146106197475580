define('gooru-web/adapters/content/question',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Question CRUD operations in the API 3.0
 *
 * @typedef {Object} QuestionAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v2/questions',namespaceV1:'/api/nucleus/v1/questions',copierNamespace:'/api/nucleus/v1/copier/questions',tagNamespace:'/api/nucleus/v2/lookups',lookupV1:'/api/nucleus/v1/lookups', /**
   * Posts a new question
   *
   * @param data question data to be sent in the request body
   * @returns {Promise}
   */createQuestion:function createQuestion(data){var adapter=this;var url=this.get('namespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data.body)};return _ember['default'].$.ajax(url,options);}, /**
   * Reads a question by id
   *
   * @param {string} questionId
   * @returns {Promise}
   */readQuestion:function readQuestion(questionId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + questionId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Update a question
   *
   * @param questionId the id of the question to be updated
   * @param data question data to be sent in the request body
   * @returns {Promise}
   */updateQuestion:function updateQuestion(questionId,data){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + questionId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes a question by id
   *
   * @param questionId question id to be sent
   * @returns {Promise}
   */deleteQuestion:function deleteQuestion(questionId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + questionId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Copies a question by id
   *
   * @param data question data to be sent in the request body
   * @returns {Promise}
   */copyQuestion:function copyQuestion(questionId){var adapter=this;var namespace=this.get('copierNamespace');var url=namespace + '/' + questionId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);},addQuestion:function addQuestion(questionId,subQuestionId){var adapter=this;var namespace=this.get('namespaceV1');var url=namespace + '/' + questionId + '/sub-questions/' + subQuestionId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);},fetchReadactivity:function fetchReadactivity(courseId,sourceQuestionId){var adapter=this;var namespace=adapter.get('namespaceV1');var url=namespace + '/related-questions';var data={courseId:courseId};if(sourceQuestionId){data.sourceQuestionId = sourceQuestionId;}var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders(),data:data};return _ember['default'].$.ajax(url,options);}, /**
   * Reorder assessment resources
   *
   * @param questionId the id of the question to be updated
   * @param data question data to be sent in the request body
   * @returns {Promise}
   */reorderQuestions:function reorderQuestions(questionId,data){var adapter=this;var namespace=this.get('namespaceV1');var url=namespace + '/' + questionId + '/sub-questions/order';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Reads a question by id
   *
   * @param {string} questionId
   * @returns {Promise}
   */getTags:function getTags(text,limit,offset){var adapter=this;var namespace=adapter.get('tagNamespace');var url=namespace + '/tags?q=' + text + '&limit=' + limit + '&offset=' + offset;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * get difficulty level
   *
   * @param {string} null
   * @returns {Promise}
   */getDifficultyLevel:function getDifficultyLevel(){var adapter=this;var namespace=adapter.get('lookupV1');var url=namespace + '/difficulty-levels';var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});