define('gooru-web/components/reports/class-assessment/gru-question-performance',['exports','ember','gooru-web/config/question','gooru-web/utils/question-result','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigQuestion,_gooruWebUtilsQuestionResult,_gooruWebConfigConfig){ /**
 * Question Performance Component
 *
 * Component responsible for displaying the question performance information for several students
 *
 * @module
 * @augments Ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['reports','class-assessment','gru-question-performance'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {User[]} students
   */students:null, /**
   * @prop { ReportData } reportData - Representation of the data to show in the reports as a 3D matrix
   * Any changes on the content feed will cause the report data to update
   */reportData:null, /**
   * @property {Resource} question
   */question:null, /**
   * Indicates if the report is displayed in anonymous mode
   * @property {boolean} anonymous
   */anonymous:null, /**
   * Indicates when the report is display in anonymous mode if show all performance results
   * @property {boolean} showResult
   */showResult:null, /**
 * Indicates if is anonymous and show the performance Results
 * @property {boolean} anonymousAndShowResult
 */anonymousAndShowResult:_ember['default'].computed.and('anonymous','showResult'), /**
 * Indicates if is anonymous and show the performance Results
 * @property {boolean} anonymousAndShowResult
 */anonymousAndNotShowResult:_ember['default'].computed('anonymous','showResult',function(){return this.get('anonymous') && !this.get('showResult');}), /**
   * Question results for this question, all students
   *
   * @property {QuestionResult[]}
   */questionResults:_ember['default'].computed('question','reportData.data',function(){var reportData=this.get('reportData');return reportData.getResultsByQuestion(this.get('question.id'));}), /**
   * Returns a convenient structure to display the x-bar-chart
   */questionBarChartData:_ember['default'].computed('questionResults.[]','anonymousAndNotShowResult',function(){var questionResults=this.get('questionResults');var totals=(0,_gooruWebUtilsQuestionResult.stats)(questionResults);var total=totals.get('total');var anonymousAndNotShowResult=this.get('anonymousAndNotShowResult');return _ember['default'].Object.create({data:[{color:anonymousAndNotShowResult?_gooruWebConfigConfig.ANONYMOUS_COLOR:_gooruWebConfigConfig.INCORRECT_COLOR,percentage:totals.get('incorrectPercentageFromTotal')},{color:anonymousAndNotShowResult?_gooruWebConfigConfig.ANONYMOUS_COLOR:_gooruWebConfigConfig.CORRECT_COLOR,percentage:totals.get('correctPercentageFromTotal')}],completed:totals.get('totalCompleted'),total:total});}), /**
   * Convenience structure to display the answers distribution
   * @property {*} answer distributions
   */answersData:_ember['default'].computed('questionResults.[]',function(){var component=this;var reportData=component.get('reportData');var question=component.get('question');var questionUtil=(0,_gooruWebConfigQuestion.getQuestionUtil)(question.get('questionType')).create({question:question});var answers=(0,_gooruWebUtilsQuestionResult.userAnswers)(this.get('questionResults'));var distribution=questionUtil.distribution(answers);var answersData=_ember['default'].A([]);distribution.forEach(function(answerDistribution){var userAnswer=answerDistribution.get('answer');var students=reportData.getStudentsByQuestionAndUserAnswer(question,userAnswer);var correct=questionUtil.isCorrect(userAnswer);var percentage=answerDistribution?answerDistribution.get('percentage'):0;answersData.addObject(_ember['default'].Object.create({correct:correct,userAnswer:userAnswer,percentage:percentage,students:students,charData:_ember['default'].A([_ember['default'].Object.create({color:correct?_gooruWebConfigConfig.CORRECT_COLOR:_gooruWebConfigConfig.INCORRECT_COLOR,percentage:percentage})])}));});return answersData;}), // -------------------------------------------------------------------------
// Methods
/**
   * willDestroyElement event
   */willDestroyElement:function willDestroyElement(){var component=this;component.set('showResult',false);component.set('anonymous',false);}});});