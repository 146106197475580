define('gooru-web/serializers/scope-sequence/scope-sequence',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){ /**
 * Serializer to support the scope and sequence listing
 *
 * @typedef {Object} scopeAndSequenceSerializer
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @return {Object} Property helps to hold the session
   */session:_ember['default'].inject.service('session'), /**
   * @return {Array} normalizeScopeAndSequence
   */normalizeScopeAndSequence:function normalizeScopeAndSequence(payload){var _this=this;var result=_ember['default'].A([]);var basePath=this.get('session.cdnUrls.content');var appRootPath=this.get('appRootPath'); //configuration appRootPath
var thumbnailUrl=payload.thumbnail?basePath + payload.thumbnail:appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.USER_PROFILE;var scopeAndSequences=payload && payload.scope_and_sequences?payload.scope_and_sequences:[];if(scopeAndSequences && scopeAndSequences.length){scopeAndSequences.forEach(function(item){var scopeAndSequence=_ember['default'].Object.create({id:item.id,name:item.name,subject:item.subject,framework:item.framework,description:item.description,thumbnailUrl:thumbnailUrl,deprecated:item.deprecated,gradesCovered:item.gradesCovered,originalScopeAndSequenceId:item.originalScopeAndSequenceId,remixAllowed:item.remixAllowed,premium:item.premium,publisherInfo:item.publisherInfo?_this.normalizeScopeAndSequencePublisher(item.publisherInfo):null});result.pushObject(scopeAndSequence);});}return result;}, /**
   * @return {Array} normalizeScopeAndSequenceModules
   */normalizeScopeAndSequenceModules:function normalizeScopeAndSequenceModules(payload){var moduleList=_ember['default'].A();var payloadModules=payload.modules?payload.modules:[];if(payloadModules && payloadModules.length){payloadModules.forEach(function(item){var module=_ember['default'].Object.create(item);moduleList.pushObject(module);});}return moduleList;}, /**
   * @return {Array} normalizeScopeAndSequenceTopics
   */normalizeScopeAndSequenceTopics:function normalizeScopeAndSequenceTopics(payload){var result=_ember['default'].A();var topics=payload.topics?payload.topics:[];if(topics && topics.length){topics.forEach(function(item){var topic=_ember['default'].Object.create(item);result.pushObject(topic);});}return result;}, /**
   * @return {Array} normalizeCompetencies
   */normalizeCompetencies:function normalizeCompetencies(payload){var result=_ember['default'].A();payload = payload.competencies?payload.competencies:[];if(payload && payload.length){payload.forEach(function(item){if(item.competencyMetadata){item.competencyMetadata = JSON.parse(item.competencyMetadata);}result.pushObject(_ember['default'].Object.create(item));});}return result;}, /**
   * @return {Object} normalizeScopeAndSequencePublisher
   */normalizeScopeAndSequencePublisher:function normalizeScopeAndSequencePublisher(payload){var basePath=this.get('session.cdnUrls.user');var appRootPath=this.get('appRootPath'); //configuration appRootPath
var thumbnailUrl=payload.thumbnail?basePath + payload.thumbnail:appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES.USER_PROFILE;return _ember['default'].Object.create({name:payload.name,thumbnailUrl:thumbnailUrl});}, /**
   * @return {Array} normalizeContentSource
   */normalizeContentSource:function normalizeContentSource(payload){var result=_ember['default'].A();payload = payload.ca_content_sources?payload.ca_content_sources:[];if(payload && payload.length){payload.forEach(function(item){if(item.key === 'tenant-library' || item.key === 'open-library'){var libraries=item.libraries;libraries.forEach(function(library){library.key = item.key;library.seq_id = item.seq_id;result.pushObject(_ember['default'].Object.create(library));});return;}result.pushObject(_ember['default'].Object.create(item));});}return result;}, /**
   * @return {Array} normalizeGradeDomains
   */normalizeGradeDomains:function normalizeGradeDomains(payload){var result=_ember['default'].A([]);var domainPaylod=payload.domains?payload.domains:[];if(domainPaylod && domainPaylod.length){domainPaylod.forEach(function(item){var domain=_ember['default'].Object.create({description:item.description,name:item.domain_name,id:item.id,sequenceId:item.sequence_id});result.pushObject(domain);});}return result;}, /**
   * @return normalizeDomainTopics
   */normalizeDomainTopics:function normalizeDomainTopics(payload){var result=_ember['default'].A([]);var topicsPaylod=payload.topics?payload.topics:[];if(topicsPaylod && topicsPaylod.length){topicsPaylod.forEach(function(item){var topic=_ember['default'].Object.create({title:item.title,id:item.id,code:item.code,sequenceId:item.sequence_id});result.pushObject(topic);});}return result;}, /**
   * @return normalizeTopicCompetencies
   */normalizeTopicCompetencies:function normalizeTopicCompetencies(payload){var result=_ember['default'].A([]);var competenciesPayload=payload.competencies?payload.competencies:[];if(competenciesPayload && competenciesPayload.length){competenciesPayload.forEach(function(item){var competency=_ember['default'].Object.create({id:item.id,code:item.code,title:item.title,codeType:item.code_type,isSelectable:item.is_selectable,sequenceId:item.sequence_id,competencyMetadata:item.competency_metadata?item.competency_metadata:null});result.pushObject(competency);});}return result;}, /**
   * @return normalizeGradeCompetencies
   */normalizeGradeCompetencies:function normalizeGradeCompetencies(payload){var result=_ember['default'].A([]);var competenciesPayload=payload.competencies?payload.competencies:[];if(competenciesPayload && competenciesPayload.length){competenciesPayload.forEach(function(item){var competency=_ember['default'].Object.create({description:item.description,displayCode:item.display_code,id:item.id,title:item.title,sequenceId:item.sequence_id});result.pushObject(competency);});}return result;},normalizeFetchStatus:function normalizeFetchStatus(payload){var competenciesPayload=payload.competencies?payload.competencies:null;var domainsPayload=payload.domains?payload.domains:null;var topicsPayload=payload.topics?payload.topics:null;var competency=_ember['default'].Object.create({competencies:competenciesPayload,domains:domainsPayload,topics:topicsPayload});return competency;}});});