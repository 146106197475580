define('gooru-web/routes/login-tenant-url',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * The session service.
   * @property session
   * @readOnly
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Methods
model:function model(params){return params;},beforeModel:function beforeModel(){var route=this;if(!route.get('session.isAnonymous')){return route.transitionTo('index');}}, /**
   * Set all controller properties used in the template
   * @param controller
   * @param model
   */setupController:function setupController(controller){controller.resetProperties();}});});