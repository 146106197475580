define('gooru-web/components/reports/pull-up/activity/add-student-performance-data',['exports','ember','gooru-web/config/config','gooru-web/utils/utils','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['add-data','add-student-performance-data'], /**
   * @property {I2dService} Media service API SDK
   */i2dService:_ember['default'].inject.service('api-sdk/i2d'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when teacher close the pull up.
     */onClosePullUp:function onClosePullUp(){this.sendAction('onClosePullUp');}, /**
     * Action triggered when teacher selected a option.
     */onSelectOption:function onSelectOption(option){this.set('selectedOption',option.name);}}, // -------------------------------------------------------------------------
// Properties
/**
   * It maintains the assessment Id.
   * @prop {String}
   */collectionId:_ember['default'].computed.alias('collection.id'), /**
   * It maintains the format
   * @prop {String}
   */format:_ember['default'].computed.alias('collection.format'), /**
   * It maintains the id of course.
   * @prop {String}
   */courseId:_ember['default'].computed.alias('course.id'), /**
   * It maintains the activity id.
   * @prop {String}
   */activityId:_ember['default'].computed.alias('activityData.id'), /**
   * It maintains the class id.
   * @prop {String}
   */classId:_ember['default'].computed.alias('class.id'), /**
   * It maintains the class code.
   * @prop {String}
   */classCode:_ember['default'].computed.alias('class.code'), /**
   * It maintains the uploaded files.
   * @prop {Array}
   */uploadedFiles:_ember['default'].A([]), /**
   * It maintains the content is whether assessment or not
   * @prop {String}
   */isAsessmentAddData:_ember['default'].computed('contentType',function(){var component=this;var contentType=component.get('contentType');return contentType === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT || contentType === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT;}), /**
   * It decides whether we need to allow i2d flow or not
   * @prop {Boolean}
   */allowI2D:_ember['default'].computed.alias('configuration.GRU_FEATURE_FLAG.i2dFlow'), /**
   * It maintains the state of selected option
   * @prop {String}
   */selectedOption:_ember['default'].computed('allowI2D',function(){return !this.get('allowI2D')?'question':null;}), // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);if(component.get('isAsessmentAddData') && component.get('allowI2D')){component.searchImageUpload();}component.set('uploadedFiles',_ember['default'].A());}, // -------------------------------------------------------------------------
//Methods
/**
   * It fetch uploaded data for the selected add-data content
   */searchImageUpload:function searchImageUpload(){var component=this;component.set('isLoading',true);component.get('i2dService').searchImage(component.serializeUploadContext()).then(function(result){component.set('isLoading',false);if(result.length){var errorList=result.filter(function(item){return item.status < 4;});if(errorList.length){component.set('errorOnConversion',true);}else {component.set('selectedOption','upload-image');}component.set('showScoreReview',true);component.set('showToggle',true);component.serializeUploadedFiles(result);}});}, /**
   * Serialize data of uploaded images
   */serializeUploadedFiles:function serializeUploadedFiles(uploads){var component=this;var uploadedFiles=_ember['default'].A([]);uploads.map(function(item){uploadedFiles.pushObject(_ember['default'].Object.create({url:item.get('imagePath'),isUploadSuccess:true,id:item.get('id'),name:(0,_gooruWebUtilsUtils.cleanFilename)(item.get('imagePath'))}));});component.set('uploadedFiles',uploadedFiles);}, /**
   * Serialize context of image upload
   * @return {Object}
   */serializeUploadContext:function serializeUploadContext(){var component=this;var imageUploadContext=_ember['default'].Object.create({ctx_class_id:component.get('classId'),ctx_class_code:component.get('classCode'),ctx_source:'ca',item_real_id:component.get('collectionId'),item_code:'' + component.get('classCode') + component.get('activityId'),item_type:component.get('format'),ctx_course_id:null,ctx_item_id:component.get('activityId'),ctx_unit_id:null,ctx_lesson_id:null,ctx_collection_id:null,ctx_path_id:0,ctx_path_type:null});component.set('imageUploadContext',imageUploadContext);return imageUploadContext;}});});