define('gooru-web/mixins/reports/assessment/questions/question',['exports','ember','gooru-web/config/question'],function(exports,_ember,_gooruWebConfigQuestion){ /**
 * This mixin is only used by the assessment report question components
 * It has the default definition and convenience methods for all question types
 * @see reports/assessment/questions/gru-multiple-choice.js
 */exports['default'] = _ember['default'].Mixin.create({ // -------------------------------------------------------------------------
// Properties
/**
   * Question information
   * @property {Resource} question
   */question:null, /**
   * @property {*} selected user answer
   */userAnswer:null, /**
   * @property {boolean} indicates if it should display the correct question answer
   */showCorrect:null, /**
   * @property {boolean} indicates if it is in anonymous mode
   */anonymous:null, // -------------------------------------------------------------------------
// Methods
/**
   * Returns the question util for the question
   * @param question
   */getQuestionUtil:function getQuestionUtil(question){return (0,_gooruWebConfigQuestion.getQuestionUtil)(_ember['default'].get(question,'questionType')).create({question:question});}});});