define('gooru-web/routes/player',['exports','ember','gooru-web/mixins/modal','gooru-web/mixins/configuration','gooru-web/mixins/quizzes/context','quizzes-addon/routes/player','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsModal,_gooruWebMixinsConfiguration,_gooruWebMixinsQuizzesContext,_quizzesAddonRoutesPlayer,_gooruWebConfigConfig){ /**
 * @typedef { Ember.Route } PlayerRoute
 *
 * @module
 * @augments ember/Route
 */exports['default'] = _quizzesAddonRoutesPlayer['default'].extend(_gooruWebMixinsModal['default'],_gooruWebMixinsConfiguration['default'],_gooruWebMixinsQuizzesContext['default'],{templateName:'player', // -------------------------------------------------------------------------
// Dependencies
/**
     * @property {Ember.Service} Service to retrieve an assessment
     */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
     * @type {ProfileService} Service to retrieve profile information
     */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
     * @property {Ember.Service} Service to retrieve a collection
     */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
     * @property {Collection} carries a  new collection Object called from the service.
     */collectionObj:null, /**
     * @type {UnitService} Service to retrieve course information
     */courseService:_ember['default'].inject.service('api-sdk/course'), /**
     * @type {UnitService} Service to retrieve unit information
     */unitService:_ember['default'].inject.service('api-sdk/unit'), /**
     * @type {LessonService} Service to retrieve lesson information
     */lessonService:_ember['default'].inject.service('api-sdk/lesson'), /**
     * @property {Ember.Service} session service
     */session:_ember['default'].inject.service('session'), /**
     * @type {reportService} Service to retrieve report information
     */reportService:_ember['default'].inject.service('api-sdk/report'), /**
     * @type {ClassService} Service to retrieve class information
     */classService:_ember['default'].inject.service('api-sdk/class'), //initialization
init:function init(){var component=this;component._super.apply(component,arguments);}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
       * When closing the player
       */onClosePlayer:function onClosePlayer(transitionTo,courseId){var route=this;var controller=route.get('controller');var classId=controller.get('classId');var $appContainer=_ember['default'].$('.app-container');var locationToLoad=null;if($appContainer.hasClass('navigator-on')){$appContainer.removeClass('navigator-on');}if(transitionTo === 'coursemap' && classId){ //If classId available redirectTo course-map
locationToLoad = 'class/' + classId;}else if(transitionTo === 'ILActivity' && courseId){ //If courseId is available redirectTo IL course-map
locationToLoad = 'course/' + courseId;}if(locationToLoad){window.location = '/student/' + locationToLoad + '/course-map';}else {var redirectTo=transitionTo === 'ILActivity'?'student-independent-learning/studying':!route.get('history.lastRoute.name')?'index':route.get('history.lastRoute.url');var isPreviewReferrer=controller.get('isPreviewReferrer');if(isPreviewReferrer && (isPreviewReferrer === true || isPreviewReferrer === 'true') && redirectTo !== 'index'){redirectTo = redirectTo.indexOf('?') !== -1?redirectTo + '&isPreviewReferrer=true':redirectTo + '?isPreviewReferrer=true';route.transitionTo(redirectTo);}else {if(route.get('session.role') === _gooruWebConfigConfig.ROLES.TEACHER){route.defaultTransitionToLibraryBasedOnType();}else if(route.get('session.role') === _gooruWebConfigConfig.ROLES.STUDENT){route.transitionTo('student-home');}else {var queryParams={queryParams:{type:'gooru-catalog'}};route.transitionTo('library-search',queryParams);}}}}, /**
       * Action triggered to remix the collection
       */onRemixCollection:function onRemixCollection(){var collection=this.get('collectionObj');if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {var remixModel={content:collection};if(collection.get('isCollection')){this.send('showModal','content.modals.gru-collection-remix',remixModel);}else {this.send('showModal','content.modals.gru-assessment-remix',remixModel);}}}, /**
       * When the submission is complete
       */onFinish:function onFinish(){var controller=this.get('controller');var source=controller.get('eventContext.source');if(source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DIAGNOSTIC){this.transitionTo('student.class.proficiency',controller.get('classId'));}else {var queryParams={collectionId:controller.get('collection.id'),type:controller.get('type'),role:controller.get('role'),classId:controller.get('classId'),contextId:controller.get('contextResult.contextId'),isIframeMode:controller.get('isIframeMode'),isLTIResourceLinkLaunch:controller.get('isLTIResourceLinkLaunch')};var reportController=this.controllerFor('reports.student-collection'); //this doesn't work when refreshing the page, TODO
reportController.set('backUrl',this.get('history.lastRoute.url'));var isIframe=controller.get('isIframeMode');if(isIframe){_ember['default'].$(document.body).addClass('iframe-panel');}this.transitionTo('reports.student-collection',{queryParams:queryParams});}},startAssessment:function startAssessment(){var controller=this.get('controller');controller.startAssessment();}, /**
       * Navigates to the assessment report
       */navigateToReport:function navigateToReport(){var route=this;var controller=route.get('controller');var context=controller.get('context');var collection=controller.get('collection');var queryParams={collectionId:context.get('collectionId'),userId:controller.get('session.userId'),type:collection.get('collectionType'),role:controller.get('role')};if(context.get('classId')){queryParams.classId = context.get('classId');}if(context.get('courseId')){queryParams.courseId = context.get('courseId');queryParams.unitId = context.get('unitId');queryParams.lessonId = context.get('lessonId');}var reportController=route.controllerFor('reports.student-collection'); //this doesn't work when refreshing the page, TODO
reportController.set('backUrl',route.get('history.lastRoute.url'));route.transitionTo('reports.student-collection',{queryParams:queryParams});}, /**
       * On navigator remix collection button click
       * @param {Collection} collection
       */remixCollection:function remixCollection(collection){var remixModel={content:collection};if(collection.get('isCollection')){this.send('showModal','content.modals.gru-collection-remix',remixModel);}else {this.send('showModal','content.modals.gru-assessment-remix',remixModel);}}}, // -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Methods
/**
     * @param {{ collectionId: string, resourceId: string }} params
     */model:function model(params){return this.playerModel(params);},setupController:function setupController(controller,model){this._super.apply(this,arguments);var isAnonymous=model.isAnonymous;var isTeacher=this.get('profile.role') === _gooruWebConfigConfig.ROLES.TEACHER;controller.set('isTeacher',isTeacher);controller.set('isAnonymous',isAnonymous);controller.set('class',this.get('classData'));}, /**
     * Loads the player model
     * @param params
     * @returns {Promise.<TResult>}
     */playerModel:function playerModel(params){var route=this;var userId=route.get('session.userId');if(userId !== 'anonymous'){route.get('profileService').readUserProfile(userId).then(function(updatedProfile){route.set('profile',updatedProfile);});}if(!route.get('session.isAnonymous')){if(params && params.classId){var classPromise=params.classId?route.get('classService').readClassInfo(params.classId):_ember['default'].RSVP.resolve({});classPromise.then(function(classData){route.set('classData',classData);});}}var collectionId=params.collectionId;var type=params.type;var role=params.role || _gooruWebConfigConfig.ROLES.TEACHER;params.lessonId = params.lessonId === 'undefined'?null:params.lessonId;params.unitId = params.unitId === 'undefined'?null:params.unitId; // Add more undefined to sanitize as required
params.sourceUrl = location.host;params.partnerId = this.get('session.partnerId');params.tenantId = this.get('session.tenantId');params.notCheckAttempts = !(params.source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP || params.source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS);var timeSpentPromise=_ember['default'].RSVP.Promise.resolve([]);if(params.classId && !params.isTeacher){var dataParam={classId:params.classId,userId:route.get('session.userId'),to:moment().format('YYYY-MM-DD')};timeSpentPromise = route.get('reportService').fetchStudentTimespentReport(dataParam);}return route.loadCollection(collectionId,type).then(function(collection){route.set('collectionObj',collection);params.type = collection.get('collectionType');return route.createContext(params,collection,role === _gooruWebConfigConfig.ROLES.STUDENT);}).then(function(_ref){var id=_ref.id;params.contextId = id;params.role = route.get('profile.role');params.isTeacher = route.get('profile.role') === _gooruWebConfigConfig.ROLES.TEACHER;params.profileId = route.get('session.userData.gooruUId');return timeSpentPromise.then(function(studentTimespentData){params.studentTimespentData = studentTimespentData;params.classData = route.get('classData');return route.quizzesModel(params);});});}, /**
     * Loads the collection
     * @param {string} collectionId
     * @param {string} type
     * @returns {Promise.<Collection>}
     */loadCollection:function loadCollection(collectionId,type){var route=this;var isCollection=type === 'collection';var isAssessment=type === 'assessment';var loadAssessment=isAssessment || !type;var loadCollection=isCollection || !type;return _ember['default'].RSVP.hashSettled({assessment:loadAssessment?route.get('assessmentService').readAssessment(collectionId):false,collection:loadCollection?route.get('collectionService').readCollection(collectionId):false}).then(function(hash){var collectionFound=hash.assessment.state === 'rejected' || hash.assessment.value === false;return collectionFound?hash.collection.value:hash.assessment.value;});},deactivate:function deactivate(){this.get('controller').resetValues();},defaultTransitionToLibraryBasedOnType:function defaultTransitionToLibraryBasedOnType(){var route=this,defaultRoute='library-search',myId=this.get('session.userId'),type=this.get('controller').get('type'),queryParams={profileId:myId,type:'my-content',activeContentType:type};route.transitionTo(defaultRoute,{queryParams:queryParams});}});});