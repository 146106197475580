define('gooru-web/components/reports/assessment/questions/gru-scientific-fib',['exports','ember','gooru-web/mixins/reports/assessment/questions/question','gooru-web/utils/question/fill-in-the-blank'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion,_gooruWebUtilsQuestionFillInTheBlank){ /**
 * Fill in the blank
 *
 * Component responsible for controlling the logic and appearance of a fill in the blank
 * question inside of the assessment report.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-scientific-fib'], // -------------------------------------------------------------------------
// Properties
userAnswers:_ember['default'].computed('userAnswer',function(){var component=this;var userAnswers=_ember['default'].A([]);var userAnswer=component.get('userAnswer');if(userAnswer){userAnswer.map(function(answer){var answerValue=answer.split(':');userAnswers.pushObject({category:answerValue[0],value:answerValue[1]});});}return userAnswers;}),answer:_ember['default'].computed('question.answerObject.[]','userAnswers',function(){var component=this;var answerDetails=component.get('question.answerDetails');var userAnswers=component.get('userAnswers')?component.get('userAnswers'):'';var answerData=answerDetails.map(function(answerDetail){var input='<input type=\'text\' value=\'\' disabled/>';var questionTextParts=answerDetail.answer_text.indexOf(input) !== -1?answerDetail.answer_text.split(input):answerDetail.answer_text.split(_gooruWebUtilsQuestionFillInTheBlank['default'].SCIENTIFIC_FIB_REGEX.global);var answerCategory=answerDetail.answer_category;if(userAnswers.length){var userAnswerValue=userAnswers.filterBy('category',answerDetail.answer_category);var _answers=userAnswerValue.map(function(answer){return {text:answer.value !== ''?answer.value:' ','class':'answer'};});component.set('answers',_answers);}else {var correctAnswer=answerDetail.correct_answer;var _answers2=correctAnswer.map(function(userAnswerObject){return {text:userAnswerObject,'class':'answer correct'};});component.set('answers',_answers2);}var sentences=Array.isArray(questionTextParts) && questionTextParts.map(function(questionTextPart){return {text:questionTextPart,'class':'sentence'};});var answers=component.get('answers');sentences = answers && answers.length?sentences:[];return component.mergeArrays(sentences,answers,answerCategory);});return answerData;}), // -------------------------------------------------------------------------
// Methods
/**
   * Merge sentences and answers arrays
   * @return {Array}
   */mergeArrays:function mergeArrays(sentences,answers,answerCategory){var mergeArrays=_ember['default'].A();answers.forEach(function(item,index){mergeArrays.pushObject(sentences && sentences.get(index));mergeArrays.pushObject(item);});var answerByCategory=_ember['default'].Object.create({category:answerCategory,value:mergeArrays});return answerByCategory;}});});