define('gooru-web/components/content/activity/gru-references',['exports','ember','gooru-web/config/config','gooru-web/utils/utils'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({classNames:['gru-references'], /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:api-sdk/offline-activity/offline-activity
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * Collection model as instantiated by the route. This is the clean model used when not editing
   * or after any collection changes have been saved.
   * @property {Collection}
   */activityModel:null, /**
   * Activity model dirty copy passed by caller, for saving exemplar and reference
   */model:null, /**
   * Collection model as passed by the route. The dirty model used for editing and saving changes to.
   * This is sent to parent for syncing.holds list of references in the activity
   * @property {references}
   */references:null, /**
   * property used to show student title
   */studentTitle:_gooruWebConfigConfig.ROLES.STUDENT, /**
   * property used to show teacher title
   */teacherTitle:_gooruWebConfigConfig.ROLES.TEACHER, /**
   * @property {studentReferences[]} List of student reference details
   */studentReferences:_ember['default'].computed('references',function(){return this.get('references').filterBy('userType',_gooruWebConfigConfig.ROLES.STUDENT).copy();}), /**
   * @property {teacherReferences[]} List of teacher reference details
   */teacherReferences:_ember['default'].computed('references',function(){return this.get('references').filterBy('userType',_gooruWebConfigConfig.ROLES.TEACHER).copy();}), /**
   * @property for  type of user
   */userType:'',isEditing:false,isExemplarEditing:false,oaId:null, /**
   * @property {Boolean} refOaId
   * Property used to change update references based on condition
   */isEditReference:false, /**
   * @property {Boolean} refOaId
   * Property to check oa id is valid or not
   */refOaId:null, /**
   * @property {Boolean} isValid
   * Property to check validation of field
   */isValid:false,exemplarUUID:_ember['default'].computed(function(){return 'exemplar' + (0,_gooruWebUtilsUtils.generateUUID)();}).property(),referenceUUID:_ember['default'].computed(function(){return 'reference' + (0,_gooruWebUtilsUtils.generateUUID)();}).property(), // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);}, // -------------------------------------------------------------------------
// Properties
createNewReference:null,actions:{ /**
     * Action to save reference, and exemplar fields
     */updateContent:function updateContent(){this.get('updateContent')(this.get('model'));this.set('isEditing',false);},cancelEdit:function cancelEdit(){this.set('isEditing',false);var srcModelValue=this.get('activityModel.reference');this.set('model.reference',srcModelValue);},editContent:function editContent(){this.set('isEditing',true);}, /**
     * Action to save reference, and exemplar fields
     */updateExemplarContent:function updateExemplarContent(){this.get('updateContent')(this.get('model'));this.set('isExemplarEditing',false);},cancelExemplarEdit:function cancelExemplarEdit(){this.set('isExemplarEditing',false);var srcModelValue=this.get('activityModel.exemplar');this.set('model.exemplar',srcModelValue);},editExemplarContent:function editExemplarContent(){this.set('isExemplarEditing',true);}, /**
     * Action to save/ add reference
     */updateReferenceCollection:function updateReferenceCollection(reference){var component=this;var refsCol=component.get('references');refsCol.pushObject(reference);component.refreshReference();component.get('updateParent')();}, /**
     * Action to delete reference
     */deleteReference:function deleteReference(refitem){var component=this;if(refitem && refitem.length > 0){refitem = refitem[0];}else {return;}component.deleteReferenceItem(refitem);}, /**
     * Action to delete url reference
     */deleteUrlReference:function deleteUrlReference(refitem){var component=this;component.deleteReferenceItem(refitem);}, /**
     * Action to edit reference
     */editReference:function editReference(refitem){var component=this;component.set('isEditReference',true);component.set('refOaId',refitem.id);}, /**
     * Action to update reference
     */updateReference:function updateReference(refitem){var component=this;if(refitem.fileName){component.set('isEditReference',false);component.set('refOaId',null);component.set('isValid',false);return component.get('activityService').updateReferences(refitem);}else {component.set('isValid',true);}}}, /**
   * Action to delete reference item
   */deleteReferenceItem:function deleteReferenceItem(refitem){var component=this;var userType=refitem.get('userType');component.get('activityService').deleteReference(refitem).then(function(refItem){var refsCol=userType === 'student'?component.get('studentReferences'):component.get('teacherReferences');refsCol.removeObject(refItem);component.refreshReference(refsCol,userType);component.get('updateParent')();});}, /**
   * Action to refresh reference
   */refreshReference:function refreshReference(refsCol,userType){var component=this;if(userType === _gooruWebConfigConfig.ROLES.STUDENT){component.set('studentReferences',refsCol);}else {component.set('teacherReferences',refsCol);}},parsedReference:_ember['default'].computed('references.[]',function(){var component=this;var uploadedCol=component.get('references').filter(function(item){return item.type === 'uploaded';});var displayData=_ember['default'].A([]);var displayItem={refData:uploadedCol,count:uploadedCol.length};displayData.pushObject(displayItem);return displayData;})});});