define('gooru-web/mixins/content/edit',['exports','ember'],function(exports,_ember){ /**
 * Properties/functionality in common for content creation
 *
 * @mixin
 */exports['default'] = _ember['default'].Mixin.create({actions:{ /**
     * Cancel Edit Content
     */cancelEdit:function cancelEdit(){this.set('isEditing',false);this.set('isErrorScormFile',false);}}, // -------------------------------------------------------------------------
// Events
addSubscriptions:_ember['default'].on('didInsertElement',function(){this._super.apply(this,arguments);var $container=this.$();var $header=$container.find('> header');var $window=_ember['default'].$(window);var headerTopOffset=$header.offset().top; // Add fix header behaviour
_ember['default'].$(window).on('scroll.edit',function(){var scrollTop=$window.scrollTop();var headerWidth=$header.css('width');var headerPaddingLeft=$header.css('paddingLeft');headerWidth = headerWidth && headerWidth.split('px')[0] || '100%';if(scrollTop >= headerTopOffset){if(!$container.hasClass('fixed-header')){ // Add inline styles to preserve the same look
$header.css({width:headerWidth,paddingLeft:headerPaddingLeft});$container.addClass('fixed-header');}}else {if($container.hasClass('fixed-header')){ // Remove any inline styles
$header.prop('style','');$container.removeClass('fixed-header');}}});}),removeSubscriptions:_ember['default'].on('willDestroyElement',function(){_ember['default'].$(window).off('scroll.edit');}), // -------------------------------------------------------------------------
// Properties
/**
   * Indicate if a course information is in edit mode
   * @property {Boolean}
   */isEditing:false});});