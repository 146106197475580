define('gooru-web/components/content/activity/gru-tasks-edit',['exports','ember','gooru-web/models/content/oa/task'],function(exports,_ember,_gooruWebModelsContentOaTask){exports['default'] = _ember['default'].Component.extend({ /**------------------------------------------------------------------------------------------------
   * Create/edit task, update parent for changes
   * ------------------------------------------------------------------------------------------------*/ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/offline-activity/offline-activity
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * Offline activity Id, which associates task with OA, supplied by caller
   */oaId:null, /**
   * Instance of TaskModel
   */model:null,isEditing:true, /**
   * @param {Boolean } didValidate - value used to check if input has been validated or not
   */didValidate:false,isExpandedChild:false,description:_ember['default'].computed.alias('model.description'), /**
   * Toggle Options
   * @property {Ember.Array}
   */switchOptions:_ember['default'].A([_ember['default'].Object.create({label:'common.yes',value:true}),_ember['default'].Object.create({label:'common.no',value:false})]), // -------------------------------------------------------------------------
// Attributes
classNames:['content','gru-tasks-edit'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Updates parent for changes
     */updateParent:function updateParent(task){var component=this;component.get('updateParent')(task);}, /**
     * Save content
     */updateContent:function updateContent(){var component=this;component.saveTask().then(function(task){component.sendAction('updateParent',task);});},removeLineItem:function removeLineItem(){var component=this;component.get('removeLineItem')(component.get('model'));}, /**
     * Reset dirty model with clean model
     */cancelChanges:function cancelChanges(){var component=this;component.get('cancelTask')();component.collapseAll();component.showAllHeaders();},updateSubmissionCollection:function updateSubmissionCollection(){ //ToDo: Impl
},expandTitle:function expandTitle(){var component=this;component.collapseAll();component.showAllHeaders();var componentHead=component.$('.panel-default > a .associated-rubric');componentHead.addClass('hidden');},updateEvidence:function updateEvidence(isChecked){this.set('model.isEvidenceRequired',isChecked);}}, /**
   * Hides all expanded collapsible sections
   */collapseAll:function collapseAll(){$('#accordion > .gru-tasks-edit > .panel-default > .panel-collapse.collapse.in').removeClass('in');}, /**
   * Shows all the header pans associated with collapsible panels
   */showAllHeaders:function showAllHeaders(){$('#accordion > .gru-tasks-edit > .panel-default > a   .associated-rubric').removeClass('hidden');}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);},didInsertElement:function didInsertElement(){this._super.apply(this,arguments);var component=this;if(!(component.get('model') && component.get('model').id)){(function(){var componentHead=component.$('.panel-default > a');_ember['default'].run(function(){return componentHead.click();}); //show new task form expanded
})();}if(!component.get('model.id')){component.set('model.isEvidenceRequired',true);}},didReceiveAttrs:function didReceiveAttrs(){this._super.apply(this,arguments);var component=this;component.prepareModelForComponent();}, /**
   * Prepares a new Model for new task creation for a new task
   * For edit flow uses existing model
   * In both the cases model is enriched with Owner for validation purpose
   */prepareModelForComponent:function prepareModelForComponent(){var component=this;if(component.get('model') && component.get('model').id){var modelOrig=component.get('model'),model=_gooruWebModelsContentOaTask['default'].create(_ember['default'].getOwner(component).ownerInjection(),modelOrig);component.set('model',model);}else {var taskInstance=_gooruWebModelsContentOaTask['default'].create(_ember['default'].getOwner(component).ownerInjection());taskInstance.set('oaId',component.get('oaId'));taskInstance.set('oaTaskSubmissions',_ember['default'].A([]));component.set('model',taskInstance);}}, // -------------------------------------------------------------------------
// Properties
/**
   * Save tasks as per configured mode: edit/create, default mode is create new
   * Returns promise
   */validate:function validate(){var component=this;var model=component.get('model'); // model = TaskModel.create(Ember.getOwner(this).ownerInjection(), modelo);
var didValidate=new _ember['default'].RSVP.Promise(function(resolve){if(model){model.validate().then(function(_ref){var validations=_ref.validations;return resolve(validations.get('isValid'));},function(){return resolve(false);});}});component.set('didValidate',didValidate);return didValidate;},saveTask:function saveTask(){var component=this;var model=component.get('model');return new _ember['default'].RSVP.Promise(function(resolve){component.validate().then(function(isValid){if(isValid){if(model && model.id){resolve(component.get('activityService').updateTask(model.oaId,model.id,model));}else {resolve(component.get('activityService').createTask(model));}}});});}});});