define('gooru-web/services/pb-session-token',['exports','ember'],function(exports,_ember){ /**
 * Service for the PB Session Token API.
 * This service is responsible for fetching and refreshing the PB Session Token.
 *
 * @typedef {Object} PB Session Token Service
 * @property {SessionService} session
 * @property {string} token
 * @property {number} expires
 * @property {string} tokenEndpoint
 * @method getToken
 */exports['default'] = _ember['default'].Service.extend({session:_ember['default'].inject.service('session'),token:null,expires:null,tokenEndpoint:'/api/v1/pb-session-token-manager/getToken',getToken:function getToken(){var service=this;return _ember['default'].$.ajax(service.get('tokenEndpoint'),{method:'POST',headers:service.getRequestHeaders(),contentType:'application/json',data:service.getRequestPayload()}).then(function(response){if(response.success){service.set('token',response.data.sessionId);service.set('expires',response.data.expires);_ember['default'].Logger.info('PB Session token refreshed');return service.get('token');}else {throw new Error('API request failed');}})['catch'](function(error){_ember['default'].Logger.error('API request failed:',error);throw error;});},getRequestHeaders:function getRequestHeaders(){var service=this;return {Authorization:'Token ' + service.get('session.token-api3')};},getRequestPayload:function getRequestPayload(){var sessionJson=JSON.parse(window.localStorage.getItem('ember_simple_auth-session'));var sessionAuthDataJson=sessionJson.authenticated;return JSON.stringify({districtUid:window.localStorage.getItem('district_uid'),scopes:['nsds.generate-passage'],productUserId:sessionAuthDataJson.user.gooruUId,productShortName:'CN'});}});});