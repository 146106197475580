define('gooru-web/components/teacher/class/ca-unscheduled-items-pullup',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['backdrop-pull-ups','teacher-ca-unscheduled-items-pullup'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/class-activity
   */classActivityService:_ember['default'].inject.service('api-sdk/class-activity'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user invoke the close pull up.
     **/onPullUpClose:function onPullUpClose(){this.closePullUp();}, /**
     * Action triggered when the user invoke the toggle pull up.
     **/onPullUpOpen:function onPullUpOpen(){var component=this;if(component.$().hasClass('active')){this.closePullUp();}else {this.openPullUp();}}, /**
     * It will takes care of content will schedule for the specific date.
     * @param  {String} scheduleDate
     */onScheduleDate:function onScheduleDate(scheduleDate){var component=this;var classActivity=component.get('selectedClassActivityForSchedule');var scheduleMonth=parseInt(moment(scheduleDate).format('MM'));var scheduleYear=parseInt(moment(scheduleDate).format('YYYY'));var forMonth=parseInt(classActivity.get('forMonth'));var forYear=parseInt(classActivity.get('forYear'));var activationDate=classActivity.get('activation_date');if(!activationDate && forMonth === scheduleMonth && forYear === scheduleYear){var isToday=moment().format('YYYY-MM-DD') === scheduleDate;this.sendAction('onChangeVisibility',classActivity,scheduleDate,isToday);}else {(function(){var classId=component.get('classId');var content=classActivity.get('collection');var contentType=content.get('format');var contentId=content.get('id');var datepickerEle=component.$('.schedule-ca-datepicker-container');datepickerEle.hide();component.get('classActivityService').addActivityToClass(classId,contentId,contentType,scheduleDate).then(function(newContentId){if(!component.isDestroyed){var data=component.serializerSearchContent(content,newContentId,scheduleDate);component.sendAction('addedContentToDCA',data,scheduleDate);}});})();}}, /**
     * It will takes care of content will schedule for the specific month.
     * @param  {Number} forMonth
     * @param  {Number} forYear
     */onScheduleForMonth:function onScheduleForMonth(forMonth,forYear){var component=this;var classId=component.get('classId');var classActivity=component.get('selectedClassActivityForSchedule');var content=classActivity.get('collection');var contentType=content.get('format');var contentId=content.get('id');var datepickerEle=component.$('.schedule-ca-datepicker-container');datepickerEle.hide();component.get('classActivityService').addActivityToClass(classId,contentId,contentType,null,forMonth,forYear).then(function(newContentId){if(!component.isDestroyed){var data=component.serializerSearchContent(content,newContentId,null,forMonth,forYear);component.sendAction('addedContentToDCA',data,null,forMonth,forYear);}});}, /**
     * Action get triggered when schedule content to CA got clicked
     */onScheduleContentToDCA:function onScheduleContentToDCA(classActivity,event){var component=this;var datepickerEle=component.$('.schedule-ca-datepicker-container');var datepickerCtnEle=component.$('.schedule-ca-datepicker-container .ca-date-picker-container');datepickerCtnEle.removeClass('ca-datepicker-orientation-top');datepickerCtnEle.removeClass('ca-datepicker-orientation-bottom');datepickerCtnEle.removeClass('ca-datepicker-orientation-center');var selectedContentEle=component.$(event.target);var position=selectedContentEle.position();var top=position.top + 10 - datepickerEle.height();var left=position.left + 20 - datepickerEle.width();var componentHeight=component.$().height();if(left < 0){left = position.left - datepickerEle.width() / 2;datepickerCtnEle.addClass('ca-datepicker-orientation-center');}var windowHeight=$(window).height();var allowedTop=windowHeight - componentHeight + top;if(allowedTop < 0){datepickerCtnEle.addClass('ca-datepicker-orientation-bottom');top = position.top + 25;}else {datepickerCtnEle.addClass('ca-datepicker-orientation-top');}datepickerEle.css({top:top,left:left});if(!selectedContentEle.hasClass('active')){selectedContentEle.addClass('active');datepickerEle.show();}else {selectedContentEle.removeClass('active');datepickerEle.hide();}this.set('selectedClassActivityForSchedule',classActivity);}, /**
     * @function removeClassActivity
     */removeClassActivity:function removeClassActivity(classActivity){this.sendAction('onRemoveClassActivity',classActivity);}}, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.closeCADatePickerOnScroll();},didRender:function didRender(){this.handleAppContainerScroll();},didDestroyElement:function didDestroyElement(){this.handleAppContainerScroll();}, // -------------------------------------------------------------------------
// Properties
/**
   * ClassId belongs to this class.
   * @type {String}
   */classId:null, /**
   * It maintains the list of un scheduled class activities.
   * @type {Array}
   */unScheduledClassActivities:_ember['default'].A([]), //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().addClass('active');component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(){var component=this;component.$().animate({top:'90%'},400,function(){component.$().css('top','calc(100% - 35px)');component.$().removeClass('active');});},handleAppContainerScroll:function handleAppContainerScroll(){var activePullUpCount=_ember['default'].$(document.body).find('.backdrop-pull-ups').length;if(activePullUpCount > 0){_ember['default'].$(document.body).addClass('no-vertical-scroll');}else if(activePullUpCount === 0){_ember['default'].$(document.body).removeClass('no-vertical-scroll');}},serializerSearchContent:function serializerSearchContent(content,contentId,date){return _ember['default'].Object.create({id:contentId,added_date:date,activityDate:date,collection:content,usersCount:-1,isActive:false});},closeCADatePickerOnScroll:function closeCADatePickerOnScroll(){var component=this;component.$('.ca-unscheduled-content-items').on('scroll',function(){if(_ember['default'].$('.ca-datepicker-popover-container').is(':visible')){_ember['default'].$('.ca-datepicker-popover-container').hide();_ember['default'].$('.ca-datepicker-popover').removeClass('active');}});}});});