define('gooru-web/components/reports/pull-up/teacher-external-assessment-report-list-view',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','backdrop-pull-ups','teacher-external-assessment-list-view'], // -------------------------------------------------------------------------
// Properties
/**
   * Students report data
   * @type {Array}
   */studentReportData:_ember['default'].A(), /**
   * Maintain the status of sort by firstName
   * @type {String}
   */sortByFirstnameEnabled:false, /**
   * Maintain the status of sort by lastName
   * @type {String}
   */sortByLastnameEnabled:true, /**
   * Maintain the status of sort by score
   * @type {String}
   */sortByScoreEnabled:false, /**
   * Maintain the status of sort by timeSpent
   * @type {String}
   */sortByTimeSpentEnabled:false, /**
   * Maintains the context object
   * @type {Object}
   */contextParams:_ember['default'].computed('context',function(){var context=this.get('context');var params=_ember['default'].Object.create({classId:context.classId,courseId:context.courseId,unitId:context.unitModel.get('id'),lessonId:context.lessonModel.get('id'),collectionId:context.id});return params;}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when select a student report
     */studentReport:function studentReport(collection,user){var studentPerformance={score:user.score,hasStarted:user.hasStarted,timeSpent:user.timeSpent || null};this.sendAction('studentReport',collection,user.id,studentPerformance);}, /**
     * Action triggered when sort by student first name
     */sortByFirstName:function sortByFirstName(){var component=this;component.toggleProperty('sortByFirstnameEnabled');if(component.get('sortByFirstnameEnabled')){component.set('studentReportData',component.get('studentReportData').sortBy('firstName'));}else {component.set('studentReportData',component.get('studentReportData').sortBy('firstName').reverse());}}, /**
     * Action triggered when sort by student last name
     */sortByLastName:function sortByLastName(){var component=this;component.toggleProperty('sortByLastnameEnabled');if(component.get('sortByLastnameEnabled')){component.set('studentReportData',component.get('studentReportData').sortBy('lastName'));}else {component.set('studentReportData',component.get('studentReportData').sortBy('lastName').reverse());}}, /**
     * Action triggered when sort by student score
     */sortByScore:function sortByScore(){var component=this;component.toggleProperty('sortByScoreEnabled');if(component.get('sortByScoreEnabled')){component.set('studentReportData',component.get('studentReportData').sortBy('score').reverse());}else {component.set('studentReportData',component.get('studentReportData').sortBy('score'));}}, /**
     * Action triggered when sort by student timeSpent
     */sortByTimeSpent:function sortByTimeSpent(){var component=this;component.toggleProperty('sortByTimeSpentEnabled');var studentReportData=undefined;if(component.get('sortByTimeSpentEnabled')){studentReportData = component.get('studentReportData').sortBy('timeSpent').reverse();}else {studentReportData = component.get('studentReportData').sortBy('timeSpent');}component.set('studentReportData',studentReportData);}, /**
     * Action triggered when de-select a student
     */onDeSelectUser:function onDeSelectUser(student){this.get('studentsSelectedForSuggest').removeObject(student);student.set('selectedForSuggestion',false);}, /**
     * Action triggered when select a student
     */onSelectUser:function onSelectUser(student){this.get('studentsSelectedForSuggest').pushObject(student);student.set('selectedForSuggestion',true);}, /**
     * Action triggered when open suggestion popup
     */onOpenSuggestionPullup:function onOpenSuggestionPullup(){this.set('showSuggestionPullup',true);},onClosePullUp:function onClosePullUp(){var component=this;component.set('showSuggestionPullup',false);component.sendAction('onClosePullUp');}, /**
     * Action triggered when close suggestion popup
     */onCloseSuggest:function onCloseSuggest(){this.set('studentsSelectedForSuggest',_ember['default'].A());this.get('studentReportData').filterBy('selectedForSuggestion',true).map(function(data){data.set('selectedForSuggestion',false);});}}});});