define('gooru-web/models/content/class-activity',['exports','ember'],function(exports,_ember){ /**
 * Class Activity model
 * typedef {Object} ClassActivity
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {String} id - The class activity id
   */id:null, /**
   * @property {Date} date of class activity
   */date:null, /**
   * @property {Collection}
   */collection:null, /**
   * @property {ActivityPerformanceSummary}
   */activityPerformanceSummary:null, /**
   * @property { { courseId: string, unitId: string, lessonId: string, collectionId: string }}
   */context:null, /**
   * @property {boolean}
   */isActive:_ember['default'].computed.bool('date')});});