define('gooru-web/components/player/chrono-view/chrono-header-xs',['exports','ember','d3'],function(exports,_ember,_d3){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['chrono-header-xs'], /**
   * @property {String} color - Hex color value for the default bgd color
   */defaultBarColor:'#E3E5EA', /**
   * @property {draw observer}
   * draw the timeline activities whenever data model changes
   */draw:_ember['default'].observer('activities.[]','activities.@each.selected',function(){this.set('isLoading',false);this.drawTimeLineActivities();}), /**
   * @property {activities}
   */activities:null, /**
   * @property {isLoading}
   */isLoading:false, /**
   * @property {activityEndDate}
   */activityEndDate:_ember['default'].computed('activities',function(){var lastIndex=this.get('activities').length - 1;var lastAccesedResource=this.get('activities').objectAt(lastIndex);return this.uiDateFormat(lastAccesedResource.get('lastAccessedDate'));}), /**
   * @property {activityDate}
   */activityDate:_ember['default'].computed('startDate',function(){return this.uiDateFormat(this.get('startDate'));}), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.drawTimeLineActivities();component.paginateNext();},didRender:function didRender(){var component=this;var leftTimeLineHeight=component.$('.left-timeline-activities')[0].scrollHeight;component.$('.student-activities').scrollTop(leftTimeLineHeight - 294);},willDestroyElement:function willDestroyElement(){var component=this;component.clearChart();},actions:{onOpenCourseReport:function onOpenCourseReport(){this.sendAction('onOpenCourseReport');}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function uiDateFormat
   * Method to parse given date
   */uiDateFormat:function uiDateFormat(givenDate){givenDate = givenDate || new Date();if(typeof givenDate === 'string'){givenDate = new Date(givenDate);}var dateLocale='en-us',dateMonth=givenDate.toLocaleString(dateLocale,{month:'short'}),dateYear=givenDate.toLocaleString(dateLocale,{year:'2-digit'}),dateDisplay={mon:dateMonth,year:dateYear};return dateDisplay;}, /**
   * @function calculateLeftNodes
   * Function to calculate left t(imeline
   */calculateLeftNodes:function calculateLeftNodes(){var component=this;var resources=this.get('activities');var selectedIndex=this.get('selectedIndex');var leftTimeLine=resources.slice(0,selectedIndex);component.set('leftTimeLine',leftTimeLine);component.drawNodes(leftTimeLine,'left');component.drawPath('left');component.handleView('left');}, /**
   * @function calculateRightNodes
   * Function to calculate right timeline
   */calculateRightNodes:function calculateRightNodes(){var component=this;var resources=this.get('activities');var selectedIndex=this.get('selectedIndex');var rightTimeLine=resources.slice(selectedIndex + 1,resources.length);component.set('rightTimeLine',rightTimeLine);component.drawNodes(rightTimeLine,'right');component.drawPath('right');component.handleView('right');}, /**
   * @function drawTimeLineActivities
   * Method to draw timeline activities
   */drawTimeLineActivities:function drawTimeLineActivities(){var component=this;component.clearChart();var selectedActivity=component.get('activities').findBy('selected',true);var selectedIndex=component.get('activities').indexOf(selectedActivity);if(selectedIndex > -1){component.set('selectedIndex',selectedIndex);}component.calculateLeftNodes();component.calculateRightNodes();component.drawActiveResource();},drawActiveResource:function drawActiveResource(){var component=this;var selectedActivity=component.get('activities').findBy('selected',true);var selectedIndex=component.get('activities').indexOf(selectedActivity);if(selectedIndex > -1){var svg=_d3['default'].select('#active-resource').select('svg');if(!svg[0][0]){svg = _d3['default'].select('#active-resource').append('svg').attr('class','center-activities');}svg.attr('viewBox','-10 -5  59 50');var activeResourceGroup=svg.append('g');activeResourceGroup.append('circle').attr('class',function(){return 'active node-' + selectedIndex + ' active-resource';});activeResourceGroup.append('foreignObject').attr('class','active-resource').append('xhtml:div').attr('class',function(){var className=selectedActivity.get('collectionType') === 'collection'?'collection-img':'assessment-img';return 'resource-icon ' + className;});var currentNodeX=35; //constant x position
var previousIndex=selectedIndex - 1;var nextIndex=selectedIndex + 1;var previousResource=component.get('activities').objectAt(previousIndex);var nextResource=component.get('activities').objectAt(nextIndex);if(nextResource){var nextNode=this.$('.right-node-' + nextIndex);var nextNodeX=parseInt(nextNode.attr('cx'));if(currentNodeX === nextNodeX){this.drawHorizontalLine({x:currentNodeX - 15,y:25},nextResource.pathId,'center');}else {this.drawCurve({x:currentNodeX - 10,y:45},{x:10,y:currentNodeX,curve:nextNodeX - currentNodeX},nextResource.pathId,'center');}}if(previousResource){var previousNode=this.$('.left-node-' + previousIndex);var prevNodeX=parseInt(previousNode.attr('cx'));if(currentNodeX === prevNodeX){this.drawHorizontalLine({x:currentNodeX - 15,y:-20},previousResource.pathId,'center');}else {this.drawCurve({x:15,y:45},{x:currentNodeX - prevNodeX,y:-5,curve:10},previousResource.pathId,'center');}}}}, /**
   * @function drawPath
   * Function to draw path
   */drawPath:function drawPath(position){var _this=this;var resources=position === 'right'?this.get('rightTimeLine'):this.get('leftTimeLine');resources.forEach(function(resource){var index=_this.get('activities').map(function(x){return x;}).indexOf(resource);var node=_this.$('.' + position + '-node-' + index);var nodeX=parseInt(node.attr('cx'));var nodeY=parseInt(node.attr('cy'));if(index < _this.get('activities').length - 1){var nextIndex=index + 1;var nextResource=_this.get('activities').get(nextIndex);var nextNode=_this.$('.' + position + '-node-' + nextIndex);var nextNodeX=parseInt(nextNode.attr('cx'));if(nextResource && nextNode.length > 0){if(nodeX === nextNodeX){_this.drawHorizontalLine({x:nodeX,y:nodeY,length:14},nextResource.pathId,position);}else {_this.drawCurve({x:nodeX,y:nodeY},{x:15,y:nodeY + 10,curve:nextNodeX - nodeX},nextResource.pathId,position);}}}});}, /**
   * @function drawHorizontalLine
   * Function to draw horizontal line
   */drawHorizontalLine:function drawHorizontalLine(startPoint,isSuggestion,position){_d3['default'].select('.' + position + '-activities').append('path').attr('class',function(){return isSuggestion?'suggestion-line':'line';}).attr('d','M' + startPoint.x + ' ' + (startPoint.y + 10) + ' v6 10');}, /**
   * @function drawCurve
   * Function to draw curve line
   */drawCurve:function drawCurve(startPoint,points,isSuggestion,position){_d3['default'].select('.' + position + '-activities').append('path').attr('class',function(){return isSuggestion?'suggestion-curve':'curve';}).attr('d','M ' + startPoint.x + ' ' + points.y + ' q 5 0 ' + points.curve + ' ' + points.x);}, /**
   * @function drawNodes
   * Function to draw nodes
   */drawNodes:function drawNodes(timeLine,position){var component=this;var resources=component.get('activities');var svg=_d3['default'].select('#' + position + '-activities').select('svg');if(!svg[0][0]){svg = _d3['default'].select('#' + position + '-activities').append('svg').attr('class',position + '-activities');}var node=svg.selectAll('.student-node').data(timeLine).enter();var group=node.append('g');group.append('circle').attr('class',function(d){var index=resources.map(function(x){return x;}).indexOf(d);var className=d.pathId?'suggestion-activity':'activity';return className + ' ' + position + '-node-' + index;}).attr('cx',function(d){var position=undefined;if(d.pathId){position = d.pathType === 'teacher'?25:45;}else {position = 35;}return position;}).attr('cy',function(d,i){var xAxis=85 + i * 30;return xAxis;});group.append('foreignObject').attr('width',24).attr('height',24).attr('x',function(d){var position=undefined;if(d.pathId){position = d.pathType === 'teacher'?13:33;}else {position = 23;}return position;}).attr('y',function(d,i){var xAxis=73 + i * 30;return xAxis;}).append('xhtml:div').attr('class',function(d){var className=d.collectionType === 'collection'?'collection-img':'assessment-img';return 'resource-icon ' + className;});group.on('click',function(d){component.set('positionToCenter',true);component.sendAction('onSelectCard',d);});}, /**
   * @function handleView
   * Function to handle svg view
   */handleView:function handleView(position){var component=this;var svg=component.$('.' + position + '-activities')[0];var bbox=svg.getBBox();var width=59;var height=bbox.height;var xPosition=5;var yPosition=bbox.y;svg.setAttribute('viewBox',xPosition + '  ' + yPosition + '  ' + width + ' ' + height);svg.setAttribute('width',width + 'px');svg.setAttribute('height',height + 'px');}, /**
   * @function paginateNext
   * Function to call paginate when scroll reaches at the end
   */paginateNext:function paginateNext(){var component=this;component.$('.student-activities').scroll(function(){if(component.$('.student-activities').scrollLeft() === 0){if(!component.get('isLoading')){component.sendAction('onPaginateNext');component.set('isLoading',true);}}});}, /**
   * @function clearChart
   * Function to clear svg
   */clearChart:function clearChart(){var component=this;component.$('svg').empty();}});});