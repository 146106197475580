define('gooru-web/routes/reports/collection',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/mixins/quizzes/context','quizzes-addon/routes/reports/context','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebMixinsQuizzesContext,_quizzesAddonRoutesReportsContext,_gooruWebConfigConfig){ /**
 * Route for collection/assessment report
 *
 * Gathers and passes initialization data for class performance
 * from analytics to the controller
 *
 * @module
 * @augments ember/Route
 */exports['default'] = _quizzesAddonRoutesReportsContext['default'].extend(_gooruWebMixinsPrivateRouteMixin['default'],_gooruWebMixinsQuizzesContext['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'),tourService:_ember['default'].inject.service('tours'),collectionService:_ember['default'].inject.service('api-sdk/collection'),assessmentService:_ember['default'].inject.service('api-sdk/assessment'),classService:_ember['default'].inject.service('api-sdk/class'),queryParams:{isClassActivity:false,isCourseMap:false,avgScoreData:null},queryParamsList:null, // -------------------------------------------------------------------------
// Actions
actions:{navigateBack:function navigateBack(){var route=!this.get('history.lastRoute.name')?'index':this.get('history.lastRoute.url');var isClassActivity=this.get('queryParamsList.isClassActivity');var isCourseMap=this.get('queryParamsList.isCourseMap');if(isClassActivity === 'true' || isCourseMap === 'true'){var message={data:_gooruWebConfigConfig.GO_LIVE_EVENT_MESSAGE.CLOSE_GO_LIVE_PULLUP};window.parent.postMessage(message,window.location.origin);}else {this.transitionTo(route);}}}, // -------------------------------------------------------------------------
// Methods
model:function model(params){var route=this;var collectionId=params.collectionId;var classId=params.classId;var anonymous=params.anonymous;var collection=undefined;var collectionType=params.collectionType;route.set('queryParamsList',params); // Get initialization data from analytics
var collectionPromise=undefined;if(collectionType === 'assessment' || collectionType === 'assessment-external'){collectionPromise = route.get('assessmentService').readAssessment(collectionId);}else {collectionPromise = route.get('collectionService').readCollection(collectionId);}var classPromise=_ember['default'].RSVP.resolve({});if(!route.get('session.isAnonymous')){if(params && params.classId){classPromise = route.get('classService').readClassInfo(classId);}}return collectionPromise.then(function(assessment){collection = assessment;return route.createContext(params,collection,true);}).then(function(_ref){var id=_ref.id;params.type = collection.get('collectionType');params.contextId = id;params.anonymous = anonymous;return route.get('classService').readClassMembers(classId);}).then(function(classMembers){params.students = classMembers.members;return classPromise.then(function(classData){params.currentClass = classData;return route.quizzesModel(params).then(function(model){return Object.assign(model,{classId:classId});});});});},setupController:function setupController(controller,model){this._super.apply(this,arguments);controller.set('classId',model.classId);}});});