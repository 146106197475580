define('gooru-web/serializers/learning-tools',['exports','ember'],function(exports,_ember){ /**
 * Serializer to support the Collection CRUD operations for API 3.0
 *
 * @typedef {Object} LearningToolSerializer
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),toolFunctions:{NTL:'noteToolCall'},normalizeLearningTool:function normalizeLearningTool(payload){var _this=this;var learningTools=payload?payload.learningTools:[];learningTools.forEach(function(element){element.callFunction = _this.toolFunctions[element.code];});return learningTools.map(function(tool){return _ember['default'].Object.create(tool);});}, /**
   * Normalize the Fetch product list endpoint's response
   *
   * @param payload is the endpoint response in JSON format
   * @returns {ProductList[]} an array of Product
   */normalizeProductList:function normalizeProductList(payload){var result=[];var serializer=this;var productList=payload.subscribedContents;if(_ember['default'].isArray(productList)){result = productList.map(function(product){return serializer.normalizeProduct(product);});}return result;},normalizeProduct:function normalizeProduct(product){ // const basePath = serializer.get('session.cdnUrls.user');
var basePath='https://cdn.gooru.org/';var thumbnailUrl=product.thumbnail?basePath + product.thumbnail:product.partnerLogo;product.image = thumbnailUrl;product.subjects = _ember['default'].Object.create(product.subjects);product.gradeList = product.gradeList;return _ember['default'].Object.create(product);}});});