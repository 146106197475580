define('gooru-web/components/reports/gru-assessment-report',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/mixins/reports/wpm-reading-question-mixin'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebMixinsReportsWpmReadingQuestionMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsWpmReadingQuestionMixin['default'],{ // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Handle event triggered by gru-summary
     * Scroll to specific question
     * TODO make this method generic
     */bubbleSelect:function bubbleSelect(bubbleOption){var animationSpeed=1000; // milliseconds
var selectorTable=$('.gru-assessment-report #resource-' + bubbleOption.label);var $elTable=$(selectorTable);var $tableVisible=$('.gru-assessment-report').find('table:visible');var selectorList=$('.gru-assessment-report #mobile-resource-' + bubbleOption.label);var $elList=$(selectorList);var $cardsVisible=$('.gru-assessment-report').find('.question-cards:visible');var isModal=$('.gru-assessment-report').parents('.gru-modal'); //Check if the assessment report is showing into a modal
if(isModal.length){if($elTable.length){$('.gru-modal').animate({scrollTop:$elTable.offset().top - $('.gru-modal').offset().top},animationSpeed);}}else {var $parentContainer=$('.controller.analytics.collection.student');var parentTopOffset=$parentContainer.length?$parentContainer.offset().top:0;var $body=$('html,body'); //Check if the questions details are showing on table (md or sm devices) or  a list (xs devices)
if($tableVisible.length){$body.animate({scrollTop:$elTable.offset().top - parentTopOffset},animationSpeed);}else if($cardsVisible.length){$body.animate({scrollTop:$elList.offset().top - parentTopOffset},animationSpeed);}else {_ember['default'].Logger.error('No element was found for selectorTable: ' + selectorTable);}}},selectAttempt:function selectAttempt(attempt){this.sendAction('onSelectAttempt',attempt);}, /**
     * View Open Ended report
     * @param questionId {String}
     */viewOEReport:function viewOEReport(questionId){this.sendAction('onViewOEReport',questionId);}, /**
     * When update the question score
     * @param data {JSON}
     */onUpdateQuestionScore:function onUpdateQuestionScore(data){this.sendAction('onUpdateQuestionScore',data);}}, // -------------------------------------------------------------------------
// Attributes
classNames:['reports','gru-assessment-report'], // -------------------------------------------------------------------------
// Events
/**
   * Listening for model to update component properties
   */onInit:_ember['default'].on('init',function(){if(this.get('model')){var assessmentResult=this.get('model').assessmentResult;this.set('assessmentResult',assessmentResult);this.set('profile',this.get('model').profile);this.set('areAnswersHidden',assessmentResult.get('areAnswersHidden'));this.set('isAnswerKeyHidden',assessmentResult.get('isAnswerKeyHidden'));this.set('isTeacher',assessmentResult.get('isTeacher'));}this.get('assessmentResult').fixResultsOrder();}), // -------------------------------------------------------------------------
// Properties
/**
   * @property {AssessmentResult} assessment
   */assessmentResult:null, /**
   * @property {Profile} profile
   */profile:null, /**
   * @property {boolean} areAnswersHidden - Should answer results be hidden?
   */areAnswersHidden:false, /**
   * @property {boolean} isAnswerKeyHidden - Should the answer key be hidden?
   */isAnswerKeyHidden:false, /**
   * @property {string} on select attempt action name
   */onSelectAttempt:null, /**
   * @property {boolean} isRealTime
   */isRealTime:_ember['default'].computed('model',function(){return this.get('model.assessmentResult.isRealTime');}), /**
   * @property {boolean} showAttempts
   */showAttempts:_ember['default'].computed('model',function(){return this.get('model.assessmentResult.showAttempts') !== undefined?this.get('model.assessmentResult.showAttempts'):true;}), /**
   * Indicates if the reaction bar is visible
   * @property {boolean}
   */showReactionBar:true, /**
   * Return ordered non open ended questions array
   * @return {Ember.Array}
   */orderedQuestions:_ember['default'].computed('assessmentResult.nonOpenEndedQuestionResults.[]',function(){var resourceResultsOrdered=this.get('assessmentResult.nonOpenEndedQuestionResults');var correctAnswers=resourceResultsOrdered.findBy('correct',true);var inCorrectAnswers=resourceResultsOrdered.findBy('correct',false);if(correctAnswers || inCorrectAnswers){this.set('hasAnsweredQuestions',true);}resourceResultsOrdered.sortBy('resource.order');return resourceResultsOrdered;}), /**
   * Return ordered open ended questions array
   * @return {Ember.Array}
   */orderedOpenEndedQuestions:_ember['default'].computed('assessmentResult.openEndedQuestionResults.[]',function(){var resourceResultsOrdered=this.get('assessmentResult.openEndedQuestionResults');resourceResultsOrdered.sortBy('resource.order');return resourceResultsOrdered;}), /**
   * Return ordered resources array
   * @return {Ember.Array}
   */orderedResources:_ember['default'].computed('assessmentResult.resources[]',function(){var resourceResultsOrdered=this.get('assessmentResult.resources');resourceResultsOrdered.sortBy('resource.order');return resourceResultsOrdered;}),orderedResourceResults:_ember['default'].computed('assessmentResult.resourceResults[]',function(){var resourceResultsOrdered=this.get('assessmentResult.resourceResults');resourceResultsOrdered.sort(function(a,b){return _ember['default'].get(a,'resource.order') - _ember['default'].get(b,'resource.order');});return resourceResultsOrdered;}), /**
   * Indicates the visibility of change score button
   * @property {Boolean}
   */isChangeScoreEnabled:false, /**
   * check question list  has answered items
   * @type {Boolean}
   */hasAnsweredQuestions:false, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collections.standards.[]',function(){var standards=this.get('collections.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), /**
   * @property {collections[]}
   */collections:_ember['default'].computed('assessmentResult',function(){var component=this;if(component.get('assessmentResult')){return component.get('assessmentResult.collection');}}),didInsertElement:function didInsertElement(){var component=this;if(!this.get('isClassActivityPage') && !component.isDestroyed){this.fetchReadingContent();}}});});