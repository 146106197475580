define('gooru-web/validations/sign-up',['exports','ember-cp-validations'],function(exports,_emberCpValidations){exports['default'] = (0,_emberCpValidations.buildValidations)({username:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.add-username'}),(0,_emberCpValidations.validator)('length',{min:4,max:254,message:'{{description}}',descriptionKey:'common.errors.username-length'}),(0,_emberCpValidations.validator)('reserved-words')]},firstName:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.sign-up-first-name'}),(0,_emberCpValidations.validator)('length',{min:2,message:'{{description}}',descriptionKey:'common.errors.sign-up-first-name-length'})]},lastName:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.sign-up-last-name'}),(0,_emberCpValidations.validator)('length',{min:2,message:'{{description}}',descriptionKey:'common.errors.sign-up-last-name-length'})]},password:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.password-required'}), /*  allowing any character for now
    validator('format', {
      regex: /^[a-z0-9]+$/i,
      message: '{{description}}',
      descriptionKey: 'common.errors.password-special-characters'
    }),
*/(0,_emberCpValidations.validator)('length',{min:5,max:16,message:'{{description}}',descriptionKey:'common.errors.password-length'})],rePassword:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.password-confirm'}), /*  allowing any character for now
    validator('format', {
      regex: /^[a-z0-9]+$/i,
      message: '{{description}}',
      descriptionKey: 'common.errors.password-special-characters'
    }),
*/(0,_emberCpValidations.validator)('confirmation',{on:'password',message:'{{description}}',descriptionKey:'common.errors.password-not-match'})],email:[(0,_emberCpValidations.validator)('format',{regex:/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,message:'{{description}}',descriptionKey:'common.errors.sign-up-valid-email'})]});});