define('gooru-web/services/api-sdk/question',['exports','ember','gooru-web/serializers/content/question','gooru-web/adapters/content/question','gooru-web/adapters/taxonomy/taxonomy','gooru-web/serializers/taxonomy/taxonomy','gooru-web/utils/utils'],function(exports,_ember,_gooruWebSerializersContentQuestion,_gooruWebAdaptersContentQuestion,_gooruWebAdaptersTaxonomyTaxonomy,_gooruWebSerializersTaxonomyTaxonomy,_gooruWebUtilsUtils){ /**
 * @typedef {Object} QuestionService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {QuestionSerializer} questionSerializer
   */questionSerializer:null, /**
   * @property {QuestionAdapter} questionAdapter
   */questionAdapter:null, /**
   * @property {AssessmentService}
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {RubricService}
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * @property {AssessmentService}
   */collectionService:_ember['default'].inject.service('api-sdk/collection'),init:function init(){this._super.apply(this,arguments);this.set('questionSerializer',_gooruWebSerializersContentQuestion['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('questionAdapter',_gooruWebAdaptersContentQuestion['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomyAdapter',_gooruWebAdaptersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Creates a new question
   *
   * @param questionData object with the question data
   * @returns {Promise}
   */createQuestion:function createQuestion(questionData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedClassData=service.get('questionSerializer').serializeCreateQuestion(questionData);service.get('questionAdapter').createQuestion({body:serializedClassData}).then(function(responseData,textStatus,request){var questionId=request.getResponseHeader('location');questionData.set('id',questionId);resolve(questionData);},reject);});}, /**
   * Finds an questions by id
   * @param {string} questionId
   * @returns {Ember.RSVP.Promise}
   */readQuestion:function readQuestion(questionId,isDefaultShowFW,classFramework){var service=this;var serializer=service.get('questionSerializer');return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('questionAdapter').readQuestion(questionId).then(function(responseData /*, textStatus, request */){var questionInfo=serializer.normalizeReadQuestion(responseData);var taxonomyIds=(0,_gooruWebUtilsUtils.getTaxonomyIds)(questionInfo,false);if(isDefaultShowFW && taxonomyIds.length){service.getcrosswalkCompetency(questionInfo,classFramework,taxonomyIds).then(function(){resolve(questionInfo);});}else {resolve(questionInfo);}},reject);});}, /**
   * Updates a question
   *
   * @param questionId the id of the question to be updated
   * @param questionModel the question model with the data
   * @param {content/Collection|content/Assessment} collection provided when the question belongs to a collection
   * @returns {Promise}
   */updateQuestion:function updateQuestion(questionId,questionModel){var collection=arguments.length <= 2 || arguments[2] === undefined?null:arguments[2];var service=this;var serializedData=service.get('questionSerializer').serializeUpdateQuestion(questionModel);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('questionAdapter').updateQuestion(questionId,serializedData).then(function(){if(questionModel.get('isOpenEnded') || questionModel.get('isScientificFreeRes') || questionModel.get('isScientificFIB') || questionModel.get('isSerpDecoding') || questionModel.get('isSerpSayOutLoud') || questionModel.get('isSerpUnderline') || questionModel.get('isSerpWPM')){var _ret=(function(){var rubric=questionModel.get('rubric');if(rubric && questionModel.get('isScientificFreeRes') || questionModel.get('isScientificFIB') || questionModel.get('isSerpDecoding') || questionModel.get('isSerpSayOutLoud') || questionModel.get('isSerpUnderline') || questionModel.get('isSerpWPM')){var maxScore=questionModel.get('maxScore');if(rubric){rubric.set('maxScore',parseInt(maxScore));rubric.set('increment',1);}}if(rubric && rubric.get('rubricOn')){ //Scenario: Rubric ON - Scoring OFF
return {v:_ember['default'].RSVP.resolve()};}else if(rubric && rubric.get('scoring')){if(rubric && rubric.get('title')){ //Scenario: Rubric OFF - Scoring ON - From Rubric ON
return {v:service.get('rubricService').deleteRubric(rubric.get('id')).then(function(){return service.get('rubricService').updateScore(rubric,questionId);})};}else { //Scenario: Rubric OFF - Scoring ON - From Rubric OFF
return {v:service.get('rubricService').updateScore(rubric,questionId)};}}else if(rubric && rubric.get('title')){ //Scenario: Rubric OFF - Scoring OFF - From Rubric ON
return {v:service.get('rubricService').deleteRubric(rubric.get('id'))};}else { //Scenario: Rubric OFF - Scoring OFF - From Rubric OFF
return {v:service.get('rubricService').updateScore(rubric,questionId)};}})();if(typeof _ret === 'object')return _ret.v;}else {return _ember['default'].RSVP.resolve();}}).then(function(){service.notifyQuizzesCollectionChange(collection);resolve();},reject);});}, /**
   * Updates the question title
   *
   * @param questionId the id of the question to be updated
   * @param title
   * @param {content/Collection|content/Assessment} collection provided when the question belongs to a collection
   * @returns {Promise}
   */updateQuestionTitle:function updateQuestionTitle(questionId,title){var collection=arguments.length <= 2 || arguments[2] === undefined?null:arguments[2];var service=this;var serializedData=service.get('questionSerializer').serializeUpdateQuestionTitle(title);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('questionAdapter').updateQuestion(questionId,serializedData).then(function(){service.notifyQuizzesCollectionChange(collection);resolve();},reject);});}, /**
   * Delete question
   *
   * @param questionId question id to delete
   * @param {content/Collection|content/Assessment} collection provided when the question belongs to a collection
   * @returns {Ember.RSVP.Promise}
   */deleteQuestion:function deleteQuestion(questionId){var collection=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('questionAdapter').deleteQuestion(questionId).then(function(){service.notifyQuizzesCollectionChange(collection);resolve();},reject);});}, /**
   * Copies a question by id
   * @param {string} questionId
   * @returns {Ember.RSVP.Promise}
   */copyQuestion:function copyQuestion(questionId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('questionAdapter').copyQuestion(questionId).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},reject);});}, /**
   * Add a question by id
   * @param {string} questionId
   * @param {string} subQuestionId
   * @returns {Ember.RSVP.Promise}
   */addQuestion:function addQuestion(questionId,subQuestionId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('questionAdapter').addQuestion(questionId,subQuestionId).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},reject);});}, /**
   * Reorder questions resources
   *
   * @param questionId the id of the questions to be updated
   * @param {string[]} questionIds
   * @returns {Promise}
   */reorderQuestions:function reorderQuestions(questionId,subQuestionIds){var assessment=arguments.length <= 2 || arguments[2] === undefined?null:arguments[2];var service=this;var serializedData=service.get('questionSerializer').serializeReorderQuestions(subQuestionIds);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('questionAdapter').reorderQuestions(questionId,serializedData).then(function(){if(assessment){service.notifyQuizzesCollectionChange(assessment);}resolve();},reject);});}, /**
   * Notifies a change at quizzes for the provided collection
   * @param {content/Collection|content/Assessment} collection
   */notifyQuizzesCollectionChange:function notifyQuizzesCollectionChange(collection){if(collection){var collectionId=collection.get('id');return collection.get('isAssessment')?this.get('assessmentService').notifyQuizzesAssessmentChange(collectionId):this.get('collectionService').notifyQuizzesCollectionChange(collectionId);}return _ember['default'].RSVP.resolve(false);},fetchReadactivity:function fetchReadactivity(questionId,sourceQuestionId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('questionAdapter').fetchReadactivity(questionId,sourceQuestionId).then(function(responseData){resolve(responseData);},reject);});}, /**
   * Finds an questions by id
   * @param {string} questionId
   * @returns {Ember.RSVP.Promise}
   */getTags:function getTags(text,limit,offset){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('questionAdapter').getTags(text,limit,offset).then(function(responseData){resolve(responseData.tags);},function(){resolve([]);});});}, /**
   * get difficulty level
   * @param {string} null
   * @returns {Ember.RSVP.Promise}
   */getDifficultyLevel:function getDifficultyLevel(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('questionAdapter').getDifficultyLevel().then(function(response){resolve(response.difficultyLevels);},function(){resolve([]);});});},getcrosswalkCompetency:function getcrosswalkCompetency(question,classFramework,taxonomyIds){var service=this;return service.get('taxonomyAdapter').crosswalkCompetency(classFramework,taxonomyIds).then(function(crosswalkResponse){var frameworkCrossWalkComp=service.get('taxonomySerializer').normalizeCrosswalkCompetency(crosswalkResponse);var standards=question.standards;standards.map(function(data){var taxonomyData=frameworkCrossWalkComp.findBy('sourceDisplayCode',data.code);if(taxonomyData){data.code = taxonomyData.targetDisplayCode;data.frameworkCode = taxonomyData.targetFrameworkId;}});});}});});