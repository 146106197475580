define('gooru-web/models/rubric/grade-question-item',['exports','ember'],function(exports,_ember){ /**
 * Grade Question Item model
 *
 * @typedef {Object} Grade Question Item
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {String} unitId
   */unitId:null, /**
   * @property {String} lessonId
   */lessonId:null, /**
   * @property {String} collectionId
   */collectionId:null, /**
   * @property {String} collectionType
   */collectionType:null, /**
   * @property {String} resourceId
   */resourceId:null, /**
  * @property {number} studentCount
  */studentCount:null});});