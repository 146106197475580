define('gooru-web/components/content/activity/reference/gru-reference-line-item',['exports','ember','gooru-web/utils/utils','gooru-web/models/content/oa/reference','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebModelsContentOaReference,_gooruWebMixinsModal){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{classNames:['gru-reference-line-item'], /**
   * @requires service:api-sdk/offline-activity/offline-activity
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Array} parsedReference
   * property hold user reference list of data
   */parsedReference:_ember['default'].A([]), // -------------------------------------------------------------------------
// Props
/**
   * Offline activity Id, which associates task with OA, supplied by caller
   */oaId:null, /**
   * @property {model} reference model being edited
   */model:null, /**
   * @property {Boolean} isLoading
   */isLoading:false, /**
   * @property {Boolean} isValidRefItem
   */isValidRefItem:_ember['default'].computed('model.fileName','model.location',function(){return this.get('model.fileName') && this.get('model.location') && (0,_gooruWebUtilsUtils.validURL)(this.get('model.location'));}), /**
   * @property {referenceSubType} supplied object from gru-reference definition of subTypes and associated mimetype for ui binding
   */referenceSubType:(function(){return (0,_gooruWebUtilsUtils.getOASubType)();}).property(),submissionType:(function(){return (0,_gooruWebUtilsUtils.getOAType)();}).property(), /**
   * @property {subTypeMimeType} returns the mimeType for selected subType
   */subTypeMimeType:(function(){var selectedSubType=this.get('model.subType') || 'others',selectedMimeType=this.get('referenceSubType').findBy('display_name',selectedSubType)?this.get('referenceSubType').findBy('display_name',selectedSubType).mimeType:'';this.set('model.subType',selectedSubType);return selectedMimeType;}).property('model.subType'),isEditing:null, /**
   * List of error messages to present to the user for conditions that the loaded image does not meet
   * @prop {String[]}
   */filePickerErrors:null, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('filePickerErrors',_ember['default'].A());var chooseOne=this.get('i18n').t('teacher-landing.class.class-settings.class-settings-sec.option-choose-one').string;var subTypeSel=_ember['default'].Object.create({display_name:chooseOne});this.set('referenceSubType1',(0,_gooruWebUtilsUtils.getOASubType)());var referenceInstance=this.getNewReferenceModel();referenceInstance.set('subTypeSel',subTypeSel);this.set('model',referenceInstance);},didInsertElement:function didInsertElement(){this._super.apply(this,arguments);var chooseOne=this.get('i18n').t('teacher-landing.class.class-settings.class-settings-sec.option-choose-one').string;var subTypeSel=_ember['default'].Object.create({display_name:chooseOne});this.set('referenceSubType1',(0,_gooruWebUtilsUtils.getOASubType)());var referenceInstance=this.getNewReferenceModel();referenceInstance.set('subTypeSel',subTypeSel);this.set('model',referenceInstance);}, // -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// Actions
actions:{deleteReference:function deleteReference(refItem){this.deleteReference(refItem);},prepareForSubmission:function prepareForSubmission(file){this.set('selectedFile',file);this.get('onSelectFile')(file);}, /**
     * @function actions:disableButtons
     */ // eslint-disable-next-line no-dupe-keys
resetFileSelection:function resetFileSelection(){ // Reset the input element in the file picker
// http://stackoverflow.com/questions/1043957/clearing-input-type-file-using-jquery/13351234#13351234
var $fileInput=this.$('input[type="file"]');$fileInput.wrap('<form>').closest('form').get(0).reset();$fileInput.unwrap();this.set('selectedFile',null);}, /**
     *
     * @param {object} subType, UI selection of subtype
     */onSubTypeChange:function onSubTypeChange(subType){this.get('model').set('subType',subType.display_name);}, /**
     * edit action //ToDo://revisit here
     */editContent:function editContent(){this.set('model',this.get('model').copy());this.set('isEditing',true);},cancelEdit:function cancelEdit() /* params */{ //ToDo: Handle cancel
}, //GRU-file-picker-events action
selectFile:function selectFile(file){var component=this;var type='uploaded';var userType=component.get('userType');var reference=component.get('references');if(file){var subType=(0,_gooruWebUtilsUtils.getFileSubType)(file.type);var model=component.get('model');model.setProperties({file:file,type:type,subType:subType,fileName:file.name,userType:userType,references:reference,callback:{success:function success(){component.$('.ember-text-field').val('');}}});this.actions.showModal.call(this,'content.modals.gru-edit-content-filename',model,null,null,null,false);}},updateURLRef:function updateURLRef(){var _this=this;var component=this;var editRefModel=component.get('model');this.set('model.type','remote');this.set('model.subType','url');this.set('model.userType',this.get('userType'));this.set('isLoading',true);this.set('didValidate',true);component.updateReference(editRefModel).then(function(){component.set('isLoading',false);var referenceInstance=component.getNewReferenceModel();_this.set('model',referenceInstance);_this.set('didValidate',false);})['catch'](function(){component.set('isLoading',false);});}}, /**
   * update fileName to API
   */updateReference:function updateReference(model){var _this2=this;var component=this;return component.get('activityService').createReferences(model,model.location).then(function(createdRefModel){component.updateReferenceDetails(createdRefModel);var referenceInstance=_this2.getNewReferenceModel();component.set('model',referenceInstance); // needed to break the ref
});}, /**
   * update reference data in UI without refresh
   */updateReferenceDetails:function updateReferenceDetails(reference){var component=this;var refsCol=component.get('references');refsCol.pushObject(reference);}, /**
   *get oaId value from model
   */getNewReferenceModel:function getNewReferenceModel(){return _gooruWebModelsContentOaReference['default'].create(_ember['default'].getOwner(this).ownerInjection(),{oaId:this.get('oaId')});},didRender:function didRender(){var $fileInput=this.$('input[type="file"]');if($fileInput){for(var i=0;i < $fileInput.length;i++) {$fileInput[i].title = $fileInput[i].id;}}}});});