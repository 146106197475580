define('gooru-web/controllers/student/class/milestone',['exports','ember','gooru-web/config/parse-event','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebConfigParseEvent,_gooruWebMixinsTenantSettingsMixin){ /**
 * MileStone Course map controller
 *
 * Controller responsible of the logic for the course map milestone view
 */exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
queryParams:['location','isProficiencyValue'],location:null, /**
   * Inject the  student class parent controller.
   */studentClassController:_ember['default'].inject.controller('student.class'),session:_ember['default'].inject.service('session'),rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * route0 Service to perform route0 data operations
   */route0Service:_ember['default'].inject.service('api-sdk/route0'),parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Object} currentLocation
   */currentLocation:_ember['default'].computed(function(){return this.get('studentClassController.class.currentLocation');}), /**
   * @property {Number} barChartData
   */barChartData:_ember['default'].computed(function(){return this.get('studentClassController.barChartData');}), /**
   * Overall class level performance percentage.
   */performancePercentage:_ember['default'].computed(function(){return this.get('studentClassController.performancePercentage');}), /**
   * Milestone progress decorator property
   */mileStone:_ember['default'].computed(function(){return {iconClass:'msaddonTop',offset:{left:'-20px',top:'9px'}};}), /**
   * Maintains the state of whether need to show all the rescoped content or not.
   * @type {Boolean}
   */showAllRescopedContent:false, /**
   * @property {Object} activeReportPeriod
   */activeReportPeriod:_ember['default'].computed(function(){var component=this;return _ember['default'].Object.create({text:component.get('i18n').t('common.progress-report'),value:'progress-report',type:'default'});}), /**
   * @property {UUID} userId
   * Current logged in user Id
   */userId:_ember['default'].computed.alias('session.userId'), /**
   * @property {UUID} classId
   */classId:_ember['default'].computed.alias('studentClassController.class.id'), /**
   * @property {UUID} courseId
   */courseId:_ember['default'].computed.alias('studentClassController.course.id'), /**
   * Set course activated date
   **/courseStartDate:_ember['default'].computed('course.createdDate',function(){if(this.get('course.createdDate')){return moment(this.get('course.createdDate')).format('YYYY-MM-DD');}return moment().subtract(1,'M').format('YYYY-MM-DD');}), /**
   * @property {Boolean} isPublicClass
   */isPublic:_ember['default'].computed.alias('class.isPublic'), /**
   * @property {Boolean} isAllContentsAreRescoped
   */isAllContentsAreRescoped:false, /**
   * A link to the parent class controller
   * @see controllers/class.js
   * @property {studentTimespentData}
   */studentTimespentData:_ember['default'].computed('studentClassController.studentTimespentData','studentTimeSpend',function(){return this.get('proficiencyStatus')?this.get('studentTimeSpend'):this.get('studentClassController.studentTimespentData');}), /**
   * A link to the content visibility from class controller
   * @see controllers/class.js
   * @property {ClassContentVisibility}
   */contentVisibility:_ember['default'].computed.alias('studentClassController.contentVisibility'),unit0Milestones:_ember['default'].A([]),classFramework:_ember['default'].computed.alias('studentClassController.classFramework'),isDefaultShowFW:_ember['default'].computed.alias('studentClassController.isDefaultShowFW'), // -------------------------------------------------------------------------
// Actions
actions:{resetPerformance:function resetPerformance(){var component=this;component.get('studentClassController').send('reloadData');}, /**
     * This method will take care of routing page to student locate me view.
     */mileStoneHandler:function mileStoneHandler(){var component=this;component.transitionToRoute('student-locate',{queryParams:{classId:component.get('class.id'),courseId:component.get('class.courseId')}});},onToggleRescope:function onToggleRescope(){this.toggleProperty('showAllRescopedContent');}, //Action triggered when toggle course info
onToggleCourseInfo:function onToggleCourseInfo(){$('.course-info-toggle-container').slideToggle();this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_STUDENT_LJ_INFO);}, //Action triggered in init for check all the milestone contents are rescoped
checkAllContentsAreRescoped:function checkAllContentsAreRescoped(milestoneData){var milestones=milestoneData;var rescopeMilestone=milestones.filterBy('rescope',true);var isAllContentsAreRescoped=milestones.length === rescopeMilestone.length;this.set('isAllContentsAreRescoped',isAllContentsAreRescoped);}, /**
     * Action triggered when the user click an report
     */onToggleReportPeriod:function onToggleReportPeriod(){this.set('isShowStudentProgressReport',true);this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.MILESTONE_PROGRESS_REPORT);}}, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadSelfGradeItems
   * Method to load self grading items
   * @return {Promise}
   */loadSelfGradeItems:function loadSelfGradeItems(){var controller=this;controller.fetchOaItemsToGradeByStudent().then(function(selfGradeItems){controller.set('selfGradeItems',selfGradeItems.get('gradeItems'));});}, /**
   * @function fetchOaItemsToGradeByStudent
   * Method to fetch list of OA items to be graded by student
   * @return {Promise}
   */fetchOaItemsToGradeByStudent:function fetchOaItemsToGradeByStudent(){var controller=this;var requestParam={classId:controller.get('classId'),type:'oa',source:'coursemap',courseId:controller.get('courseId'),userId:controller.get('userId')};return controller.get('rubricService').getOaItemsToGrade(requestParam);}});});