define('gooru-web/components/new-cards/gru-teacher-class-card',['exports','ember','gooru-web/utils/math','gooru-web/config/config','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebUtilsMath,_gooruWebConfigConfig,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Session service
   */session:_ember['default'].inject.service('session'), /**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'), // -------------------------------------------------------------------------
// Attributes
classNames:['new-cards gru-teacher-class-cards'],classNameBindings:['isPendingSetup:pending-setup'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     *
     * Triggered when an menu item is selected
     * @param item
     */selectItem:function selectItem(item){var classData=this.get('class');var classId=classData.id;var localStorage=window.localStorage;var currentDateTime=new Date().toISOString();var lastSelected={classId:classId,currentDateTime:currentDateTime};var userId=this.get('session.userId');var lastAccessedClassIdList=JSON.parse(localStorage.getItem('last_accessed_classId_' + userId));if(!lastAccessedClassIdList || lastAccessedClassIdList === null){var lastSelectedClassIdList=[];lastSelectedClassIdList.push(lastSelected);localStorage.setItem('last_accessed_classId_' + userId,JSON.stringify(lastSelectedClassIdList));}else {lastAccessedClassIdList.push(lastSelected);localStorage.setItem('last_accessed_classId_' + userId,JSON.stringify(lastAccessedClassIdList));}if(this.get('isPendingSetup')){this.sendAction('onShowCompleteSetup',classData);}else if(this.get('onItemSelected')){this.sendAction('onItemSelected',item,classId);}}, /**
     * Clicking on card panel will take to CA
     */launchCA:function launchCA(){var classData=this.get('class');var classId=classData.id;var localStorage=window.localStorage;var userId=this.get('session.userId');var lastAccessedClassIdList=JSON.parse(localStorage.getItem('last_accessed_classId_' + userId));var currentDateTime=new Date().toISOString();var lastSelected={classId:classId,currentDateTime:currentDateTime};if(!lastAccessedClassIdList || lastAccessedClassIdList === null){var lastSelectedClassIdList=[];lastSelectedClassIdList.push(lastSelected);localStorage.setItem('last_accessed_classId_' + userId,JSON.stringify(lastSelectedClassIdList));}else {lastAccessedClassIdList.push(lastSelected);localStorage.setItem('last_accessed_classId_' + userId,JSON.stringify(lastAccessedClassIdList));}this.send('selectItem','class-activities');},selectSingleBox:function selectSingleBox(item){this.sendAction('selectSingle',item);}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Class} class information
   */'class':null, /**
   * @property {Course} course information
   */course:null,isDeepLinks:_ember['default'].computed(function(){return this.get('isDeepLink') === 'true';}), /**
   * @property {Boolean}
   * Computed property  to identify class  CM is started or not
   */hasCMStarted:_ember['default'].computed('class.performanceSummary',function(){var scorePercentage=this.get('class.performanceSummary.score');return scorePercentage !== null && (0,_gooruWebUtilsMath.isNumeric)(scorePercentage);}), /**
   * @property {Boolean}
   * Computed property  to identify class CA is started or not
   */hasCAStarted:_ember['default'].computed('class.performanceSummaryForDCA',function(){var scorePercentage=this.get('class.performanceSummaryForDCA.scoreInPercentage');return scorePercentage !== null && (0,_gooruWebUtilsMath.isNumeric)(scorePercentage);}), /**
   * The class is premium
   * @property {String}
   */isPremiumClass:_ember['default'].computed('class',function(){var controller=this;var currentClass=controller.get('class');var classSetting=currentClass.get('setting');return classSetting?classSetting['course.premium']:false;}), /**
   * @property {Boolean} isPendingSetup
   * Property for to determine the class setup is completed or not
   */isPendingSetup:_ember['default'].computed('class.setting','class.isPendingSetup',function(){var classSetting=this.get('class.setting');return classSetting?classSetting['class.setup.complete'] === false:false;}), /**
   * @property {Boolean} cardBackgroundImage
   * Property help to maintain card images
   */cardBackgroundImage:_ember['default'].computed('course',function(){var appRootPath=this.get('appRootPath');var coverImage=this.get('class.coverImage');var thumbnail=coverImage?coverImage:this.get('course.thumbnailUrl');var randomNumber=parseInt(Math.random() * 3,0);var thumbnailImage=appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES['CLASS_DEFAULT_' + randomNumber];if(thumbnail){thumbnailImage = thumbnail === '/' + _gooruWebConfigConfig.DEFAULT_IMAGES.COURSE?appRootPath + _gooruWebConfigConfig.DEFAULT_IMAGES['CLASS_DEFAULT_' + randomNumber]:thumbnail;}return thumbnailImage;})});});