define('gooru-web/components/grading/gru-rubric-on',['exports','ember','gooru-web/models/rubric/grade-category-score'],function(exports,_ember,_gooruWebModelsRubricGradeCategoryScore){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['gru-rubric-on'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * @function showCategory
     * Expand/collapse a category
     */showCategory:function showCategory(category){category.set('selected',!category.get('selected'));}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.updateCategories();}, // -------------------------------------------------------------------------
// Properties
/**
   * Rubric to grade
   * @property {Rubric} rubric
   */rubric:null, /**
   * Categories from grade
   * @property {Map} gradeCategories
   */gradeCategories:_ember['default'].computed('grade.categoriesScore.@each.levelObtained','title',function(){return this.get('grade.categoriesScore').reduce(function(categoriesMap,category){categoriesMap[category.get('title')] = category;return categoriesMap;},{});}), /**
   * Categories from rubric
   * @property {RubricCategory[]} rubricCategories
   */rubricCategories:_ember['default'].computed.alias('rubric.categories'), /**
   * Student grade results
   * @property {RubricGrade} grade
   */grade:null, // -------------------------------------------------------------------------
// Observers
/**
   * Maintain categories updated when changing students
   */observeGrade:_ember['default'].observer('grade',function(){this.updateCategories();}), // -------------------------------------------------------------------------
// Methods
/**
   * Update categories to show, create object from rubric and student's grade
   */updateCategories:function updateCategories(){var _this=this;var rubricCategories=this.get('rubricCategories');var gradeCategories=this.get('gradeCategories');this.set('categories',rubricCategories.map(function(rubricCategory){var gradeCategory=gradeCategories[rubricCategory.get('title')]; // If the grade doesn't exist for the category, create one
if(!gradeCategory){gradeCategory = _gooruWebModelsRubricGradeCategoryScore['default'].create(_ember['default'].getOwner(_this).ownerInjection(),{title:rubricCategory.get('title'),levelObtained:null,levelScore:null,levelMaxScore:rubricCategory.get('totalPoints')});gradeCategories[rubricCategory.get('title')] = gradeCategory;_this.set('grade.categoriesScore',Object.values(gradeCategories));} // Create list of levels to show in dropdown
var levels=rubricCategory.get('levels').map(function(level){return {id:level.name,name:level.name};});levels = [{id:null,name:''}].concat(levels); // Create Object class for showing grading/category info
var CategoryInfo=_ember['default'].Object.extend({ // Observer to update levelScore when levelObtained is changed
levelSelectedOberver:_ember['default'].observer('grade.levelObtained',function(){var grade=this.get('grade');var levelName=this.get('grade.levelObtained');var info=this.get('info').get('levels').findBy('name',levelName);grade.set('levelScore',info?info.score:null);})});return CategoryInfo.create(_ember['default'].getOwner(_this).ownerInjection(),{info:rubricCategory,grade:gradeCategory,selected:false,levels:levels});}));}});});