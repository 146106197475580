define('gooru-web/services/api-sdk/rating',['exports','ember','gooru-web/mixins/store'],function(exports,_ember,_gooruWebMixinsStore){exports['default'] = _ember['default'].Service.extend(_gooruWebMixinsStore['default'],{ /**
   * Creates or updates the rating score associated to a Resource.
   * @param resourceId is the resource Id which the rate is associated with.
   * @param score is the rate score that will be applied to the resource.
   * @returns {*|Ember.RSVP.Promise}
   */rateResource:function rateResource(resourceId,score){var model=this.get('store').createRecord('rating/rating',{target:'content',type:'star',score:score,associatedId:resourceId});return model.save();}, /**
   * Returns the rating score for the specified resource
   * @param resourceId is the resource Id with the rate
   * @returns {Promise.<T>}
   */findRatingForResource:function findRatingForResource(resourceId){var service=this;return service.get('store').findRecord('rating/rating',resourceId).then(function(ratingRecord){ // Removes the resource record from the store to force the reload from the API endpoint
service.get('store').unloadRecord(ratingRecord);return ratingRecord;});}});});