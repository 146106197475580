define('gooru-web/serializers/content/class',['exports','ember','gooru-web/models/profile/profile','gooru-web/models/content/classes','gooru-web/models/content/class','gooru-web/models/content/class-content-visibility','gooru-web/serializers/profile/profile','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebModelsProfileProfile,_gooruWebModelsContentClasses,_gooruWebModelsContentClass,_gooruWebModelsContentClassContentVisibility,_gooruWebSerializersProfileProfile,_gooruWebMixinsConfiguration){ /**
 * Serializer to support the Class CRUD operations for API 3.0
 *
 * @typedef {Object} ClassSerializer
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsConfiguration['default'],{init:function init(){this._super.apply(this,arguments);this.set('profileSerializer',_gooruWebSerializersProfileProfile['default'].create(_ember['default'].getOwner(this).ownerInjection()));},session:_ember['default'].inject.service('session'), /**
   * Serialize a Class object into a JSON representation required by the Create Class endpoint
   *
   * @param classModel The Class model to be serialized
   * @returns {Object} returns a JSON Object
   */serializeCreateClass:function serializeCreateClass(classModel){var classData=this.serializeClass(classModel);return classData;}, /**
   * Serialize a content visibility object into a JSON representation required by the Update content Visibility endpoint
   *
   * @param id The id of content to change
   * @param visibility Indicate if is visible = on/off
   *  @param type Content object type
   * @returns {Object} returns a JSON Object
   */serializeUpdateContentVisibility:function serializeUpdateContentVisibility(id,visibility,type){var content=undefined;if(type === 'assessment' || type === 'assessment-external'){content = {assessments:[{id:id,visible:visibility?'on':'off'}]};}else if(type === 'collection' || type === 'collection-external'){content = {collections:[{id:id,visible:visibility?'on':'off'}]};}else if(type === 'offline-activity'){content = {offline_activity:[{id:id,visible:visibility?'on':'off'}]};}else if(type === 'unit'){content = {units:[{id:id,visible:visibility?'on':'off'}]};}else {content = {lessons:[{id:id,visible:visibility?'on':'off'}]};}return content;}, /**
   * Serialize a Class object into a JSON representation required by the Update Class endpoint
   *
   * @param classModel The Class model to be serialized
   * @returns {Object} returns a JSON Object
   */serializeUpdateClass:function serializeUpdateClass(classModel){var classData=this.serializeClass(classModel,true);classData.greeting = classModel.get('greeting');return classData;},serializeClass:function serializeClass(classModel){var update=arguments.length <= 1 || arguments[1] === undefined?false:arguments[1];var setting=classModel.get('setting');var maValue=setting?setting.mastery_applicable:null;var correctAns=setting?setting.show_correct_answer:null;var tmpEvidence=setting?setting.show_evidence:null;if(setting){if(maValue !== undefined){setting['mastery.applicable'] = maValue.toString();delete setting.mastery_applicable;}else if(correctAns !== undefined){setting['show.correct.answer'] = correctAns;delete setting.show_correct_answer;}else if(tmpEvidence !== undefined){setting['show.evidence'] = tmpEvidence;delete setting.show_evidence;}}var data={title:classModel.get('title'),class_sharing:classModel.get('classSharing'),min_score:classModel.get('minScore') || 0,setting:setting || null};if(!update){data.content_visibility = classModel.get('contentVisibility') || _gooruWebModelsContentClass['default'].VISIBLE_ALL;}return data;}, /**
   * Serialize a Class object into a JSON representation required by the Update Class endpoint
   *
   * @param classModel The Class model to be serialized
   * @returns {Object} returns a JSON Object
   */serializeUpdateClassCoverImage:function serializeUpdateClassCoverImage(classModel){var classData=this.serializeClassCoverImage(classModel);return classData;},serializeClassCoverImage:function serializeClassCoverImage(classModel){var data={cover_image:classModel.get('coverImage')};return data;},normalizeReadBulkClassDetails:function normalizeReadBulkClassDetails(classes){var _this=this;var normalizedClassDetails=_ember['default'].A([]);classes = classes?classes.class_details:null;if(classes && classes.length){classes.map(function(classData){normalizedClassDetails.push(_this.normalizeReadClassInfo(classData));});}return normalizedClassDetails;}, /**
   * Normalize the Read Class info endpoint response
   *
   * @param payload is the endpoint response in JSON format
   * @param {Profile[]} teachers
   * @returns {ClassModel} a class model object
   */normalizeReadClassInfo:function normalizeReadClassInfo(payload){var teachers=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];var creatorId=payload.creator_id;var collaborators=payload.collaborator || []; //when teachers are not provided is creates an onwers from creatorId
var teachersWrapper=_ember['default'].A(teachers || [_gooruWebModelsProfileProfile['default'].create({id:creatorId})]);var defReadSetting={mastery_applicable:false,show_correct_answer:true};var defReadSettingObj=_ember['default'].Object.create(defReadSetting);var basePath=this.get('session.cdnUrls.content');var coverImage=payload.cover_image?basePath + payload.cover_image:null;return _gooruWebModelsContentClass['default'].create(_ember['default'].getOwner(this).ownerInjection(),{id:payload.id,creatorId:payload.creator_id,owner:teachersWrapper.findBy('id',payload.creator_id),code:payload.code,title:payload.title,description:payload.description,courseId:payload.course_id,courseTitle:payload.course_title,greeting:payload.greeting,grade:Array.isArray(payload.grade)?payload.grade.objectAt(0):payload.grade,classSharing:payload.class_sharing,coverImage:coverImage,minScore:payload.min_score === 0?null:payload.min_score,startDate:payload.created_at,endDate:payload.end_date,creatorSystem:'',contentVisibility:payload.content_visibility || _gooruWebModelsContentClass['default'].VISIBLE_NONE,isArchived:payload.is_archived,isPublic:payload.is_public,route0Applicable:payload.route0_applicable,forceCalculateILP:payload.force_calculate_ilp,gradeLowerBound:payload.grade_lower_bound,gradeCurrent:payload.grade_current,gradeUpperBound:payload.grade_upper_bound,primaryLanguage:payload.primary_language,roster_id:payload.roster_id,collaborators:collaborators.map(function(collaboratorId){return _gooruWebModelsProfileProfile['default'].create({id:collaboratorId});}),courseVersion:payload.course_version,setting:payload.setting?_ember['default'].Object.create(payload.setting):defReadSettingObj,preference:payload.preference?_ember['default'].Object.create(payload.preference):null,milestoneViewApplicable:payload.milestone_view_applicable,memberCount:payload.member_count || 0,lastUpdated:payload.updated_at});}, /**
   * Normalize the response from class members endpoint
   * @param payload is the endpoint response in JSON format
   * @returns {ClassMembersModel} a class members model object
   */normalizeReadClassMembers:function normalizeReadClassMembers(payload){var serializer=this;return _ember['default'].Object.create({owner:this.get('profileSerializer').normalizeReadProfile(payload.details.findBy('id',payload.owner[0])),collaborators:serializer.filterCollaborators(payload),members:serializer.filterMembers(payload),memberGradeBounds:serializer.filterMembersGradeBounds(payload),details:payload.details,invitees:payload.invitees});}, /**
   * Normalize the response from class members endpoint
   * @param payload is the endpoint response in JSON format
   * @returns {ClassMembersModel} a class members model object
   */normalizeReadClassContentVisibility:function normalizeReadClassContentVisibility(payload){return _gooruWebModelsContentClassContentVisibility['default'].create({contentVisibility:payload.content_visibility,course:_ember['default'].Object.create(payload.course)});}, /**
   * Normalize the user classes endpoint response
   * @param payload The endpoint response in JSON format
   * @returns {Classes} a classes model object
   */normalizeClasses:function normalizeClasses(payload){var serializer=this;var teachers=serializer.normalizeTeachers(payload.teacher_details || []);return _gooruWebModelsContentClasses['default'].create(_ember['default'].getOwner(this).ownerInjection(),{ownerList:payload.owner,collaboratorList:payload.collaborator,memberList:payload.member,memberCount:payload.member_count,classes:(function(){var normalizedClasses=[];if(payload.classes && payload.classes.length){payload.classes.forEach(function(theClass){normalizedClasses.push(serializer.normalizeReadClassInfo(theClass,teachers));});}return normalizedClasses;})()});}, /**
   *
   * @param teachersData
   * @returns {Array}
   */normalizeTeachers:function normalizeTeachers(teachersData){var profileSerializer=this.get('profileSerializer');return teachersData.map(function(teacherData){return profileSerializer.normalizeReadProfile(teacherData);});},filterCollaborators:function filterCollaborators(payload){return this.filterElements(payload,'collaborator');},filterMembers:function filterMembers(payload){return this.filterElements(payload,'member');},filterMembersGradeBounds:function filterMembersGradeBounds(payload){var membersGradeBounds=payload.member_grade_bounds || [];return membersGradeBounds.map(function(memberGradeBounds){return _ember['default'].Object.create(memberGradeBounds);});},filterElements:function filterElements(payload,property){var serializer=this;var elements=payload[property];if(_ember['default'].isArray(elements) && elements.length > 0){return elements.map(function(elementId){return serializer.get('profileSerializer').normalizeReadProfile(payload.details.findBy('id',elementId));}).compact();}else {return [];}},normalizeStudentList:function normalizeStudentList(studentList){var listStudent=_ember['default'].A([]);studentList.students.forEach(function(student){listStudent.pushObject(_ember['default'].Object.create(student));});return listStudent;},normalizeStudentStatusList:function normalizeStudentStatusList(studentList){var listStudent=_ember['default'].A([]);Object.keys(studentList.users).map(function(key){return listStudent.pushObject({id:studentList.users[key]?studentList.users[key].id:null,email:studentList.users[key]?studentList.users[key].email:key,userName:studentList.users[key]?studentList.users[key].username:null,firstName:studentList.users[key]?studentList.users[key].first_name:null,lastName:studentList.users[key]?studentList.users[key].last_name:null,status:!!studentList.users[key]});});return listStudent;}});});