define('gooru-web/controllers/grading-player',['exports','ember'],function(exports,_ember){ /**
 * @typedef {object} Grading Player Controller
 */exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {RubricService} Service to retrieve rubric information
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'),queryParams:['classId','courseId','unitId','lessonId','collectionId','questionId'],actions:{ /**
     * Open student roster
     */openStudentRoster:function openStudentRoster(){this.set('showRoster',true);}, /**
     * Close student roster
     */closeRoster:function closeRoster(){this.set('showRoster',false);}, /**
     * Triggered when current user has been changed
     */changeUser:function changeUser(userId){this.changeAnswer(userId);}, /**
     * Load next student
     */onLoadNext:function onLoadNext(){this.loadStudent(true);}, /**
     * Load previous student
     */onLoadPrevious:function onLoadPrevious(){this.loadStudent(false);}, /**
     * Submit a grade
     */onSubmitGrade:function onSubmitGrade(){this.submitGrade();}}, // -------------------------------------------------------------------------
// Properties
/**
   * The class id
   * @property {String} classId
   */classId:null, /**
   * The course id
   * @property {String} courseId
   */courseId:null, /**
   * Current answer
   * @property {RubricAnswer} currentAnswer
   */currentAnswer:_ember['default'].computed('currentUserId','userMappings',function(){return this.get('userMappings')[this.get('currentUserId')].answer;}), /**
   * Current grade
   * @property {RubricGrade} currentGrade
   */currentGrade:_ember['default'].computed('currentUserId','userMappings',function(){return this.get('userMappings')[this.get('currentUserId')].grade;}), /**
   * Current user
   * @property {User} currentUser
   */currentUser:_ember['default'].computed('currentUserId','userMappings',function(){return this.get('userMappings')[this.get('currentUserId')].user;}), /**
   * Current user
   * @property {String} currentUserId
   */currentUserId:null, /**
   * The unit id
   * @property {String} unitId
   */unitId:null, /**
   * The lesson id
   * @property {String} lessonId
   */lessonId:null, /**
   * The collection id
   * @property {String} collectionId
   */collectionId:null, /**
   * The question id
   * @property {String} questionId
   */questionId:null, /**
   * If the student roster should be hidden
   * @property {Boolean} showRoster
   */showRoster:false, /**
   * Current student id
   * @property {String} studentId
   */studentId:null, /**
   * If the response should be hidden
   * @property {Boolean} hideResponse
   */hideResponse:false, /**
   * Mappings for users, answers and grades
   * @property {Map} userMappings
   */userMappings:null, /**
   * Index of the current user
   * @property {Integer} currentUserIndex
   */currentUserIndex:_ember['default'].computed('currentUserId','users.[]',function(){var user=this.get('currentUser');var users=this.get('users');return users.indexOf(user);}), /**
   * If the next button should be disabled
   * @property {boolean} isNextDisabled
   */isNextDisabled:_ember['default'].computed('currentUserIndex','users.[]',function(){var currentIndex=this.get('currentUserIndex');var users=this.get('users');return currentIndex === users.length - 1;}), /**
   * If the previous button should be disabled
   * @property {boolean} isPreviousDisabled
   */isPreviousDisabled:_ember['default'].computed('currentUserIndex',function(){var currentIndex=this.get('currentUserIndex');return currentIndex === 0;}), // -------------------------------------------------------------------------
// Methods
/**
   * Find answer to grade
   */getAnswerToGrade:function getAnswerToGrade(studentId,classId,courseId,collectionId,questionId,unitId,lessonId){return this.get('rubricService').getAnswerToGrade(studentId,classId,courseId,collectionId,questionId,unitId,lessonId);}, // -------------------------------------------------------------------------
// Methods
/**
   * Change user answer
   */changeAnswer:function changeAnswer(userId){var controller=this;var answer=controller.get('userMappings')[userId].answer;if(answer){controller.set('currentUserId',userId);}else {controller.getAnswerToGrade(userId,controller.get('classId'),controller.get('courseId'),controller.get('collectionId'),controller.get('questionId'),controller.get('unitId'),controller.get('lessonId')).then(function(newAnswer){var userMapping=controller.get('userMappings')[userId];userMapping.answer = newAnswer;userMapping.grade.set('sessionId',newAnswer.get('sessionId'));controller.set('currentUserId',userId);});}}, /**
   * Load the next student information
   * @param loadNext if it should load the next or previous student
   */loadStudent:function loadStudent(loadNext){var diff=loadNext?1:-1;var users=this.get('users');var currentUserIndex=this.get('currentUserIndex');var nextIndex=currentUserIndex + diff;if(nextIndex < users.length && nextIndex > -1){this.changeAnswer(users.get(nextIndex).get('id'));}}, /**
   * Submit a graded answer
   */submitGrade:function submitGrade(){var _this=this;this.get('currentGrade').set('updatedDate',new Date());this.get('rubricService').setStudentRubricGrades(this.get('currentGrade')).then(function(){_this.get('currentUser').set('checked',true);_this.loadStudent(true);if(_this.get('isNextDisabled')){_this.transitionToRoute('teacher.class.course-map',_this.get('classId'));}});}});});