define('gooru-web/routes/content/resources/play',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Route.extend({queryParams:{source:{refreshModel:true}}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/resource
   */resourceService:_ember['default'].inject.service('api-sdk/resource'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), //-------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(params){var sessionJSON=window.localStorage.getItem('ember_simple_auth-session');var resourceShareLinkId=params.params['content.resources.play'].resourceId;if(sessionJSON){var sessionObj=JSON.parse(sessionJSON);if(sessionObj && sessionObj.authenticated && sessionObj.authenticated.isAnonymous){localStorage.setItem('resourceShareLinkId',resourceShareLinkId);this.get('router').transitionTo('login');}}},model:function model(params){var route=this;var isRGOsource=params.source?params.source === _gooruWebConfigConfig.PLAYER_EVENT_SOURCE.RGO:false;var resource=this.get('resourceService').readResource(params.resourceId).then(function(resource){var regex=/.*resource$/;if(regex.test(resource.type)){route.get('router').transitionTo('player-resource',params.resourceId,{queryParams:{isShare:true}});}return route.get('profileService').readUserProfile(resource.owner).then(function(owner){resource.set('owner',owner);return _ember['default'].RSVP.resolve({resource:resource,notAccessible:false});});})['catch'](function(){return _ember['default'].RSVP.resolve({resource:resource,notAccessible:true});});return _ember['default'].RSVP.hash({resource:resource,isRGOsource:isRGOsource});},setupController:function setupController(controller,model){controller.set('resource',model.resource.resource);controller.set('notAccessible',model.resource.notAccessible);controller.set('isRGOsource',model.isRGOsource);}});});