define('gooru-web/components/student/class/gru-student-show-all-class',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -----------------------------------------------------------------
// Attributes
classNames:['gru-student-show-all-class'], // ----------------------------------------------------------------------
// Properties
isShowAllCourses:false,activeClassList:_ember['default'].A(),archivedClassList:_ember['default'].A(),independedCourses:_ember['default'].A(),contentItem:null,activeBucket:null,pageContent:_ember['default'].computed('contentItem',function(){var component=this;return component.get(component.get('contentItem')) || component.get('activeBucket.courseList');}), // ---------------------------------------------------------------------
// Hooks
// ----------------------------------------------------------------------
// Actions
actions:{onGoBack:function onGoBack(){this.set('isShowAllCourses',false);}} // ----------------------------------------------------------------------
// Methods
});});