define('gooru-web/components/library/gru-partner-product-card',['exports','ember'],function(exports,_ember){ /**
 * Library card
 *
 * Component responsible of showing the library information in cards, so that most useful information is summarized there.
 * @module
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-partner-product-card'], // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);},didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Actions
actions:{selectProduct:function selectProduct(){var component=this;var product=component.get('content');var queryParams={productId:product.get('productId'),type:'product',isDeepLink:component.get('isDeepLink')};component.get('router').transitionTo('library-search',{queryParams:queryParams});}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {Library} content
   */content:null,visibleGrade:_ember['default'].computed('content.gradeList',function(){var gradeList=this.get('content.gradeList');return gradeList.get('firstObject');}),gradeListTitle:_ember['default'].computed('content.gradeList',function(){var gradeList=this.get('content.gradeList');var gradeTitle=[];var title=gradeList.map(function(grade){return grade.title;});gradeTitle = gradeTitle.concat(title);return gradeTitle;}) // -------------------------------------------------------------------------
// Methods
});});