define('gooru-web/components/content/courses/play/gru-accordion-lesson',['exports','ember','gooru-web/mixins/content/builder','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsContentBuilder,_gooruWebMixinsTenantSettingsMixin){ /**
 * Course content viewer: Accordion Lesson
 *
 * Component responsible for behaving as an accordion and listing a set of collections/assessments.
 * It is meant to be used inside of an {@link ./gru-accordion-unit|Accordion Unit}
 *
 * @module
 * @augments Ember/Component
 * @mixes mixins/content/builder
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsContentBuilder['default'],_gooruWebMixinsTenantSettingsMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:i18n
   */i18n:_ember['default'].inject.service(), /**
   * @requires service:api-sdk/lesson
   */lessonService:_ember['default'].inject.service('api-sdk/lesson'), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['content','courses','gru-accordion','gru-accordion-lesson'],classNameBindings:['model.isEditing:edit:view'],tagName:'li', // -------------------------------------------------------------------------
// Actions
actions:{toggle:function toggle(){var toggleValue=!this.get('model.isExpanded');var id=this.get('model.data.id');this.get('onExpandLesson')(id,toggleValue);this.loadData();this.set('model.isExpanded',toggleValue);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @prop {String} course - Course this lesson belongs to
   */course:null, /**
   * @prop {Boolean} isLoaded - Has the data for the lesson already been loaded
   */isLoaded:false, /**
   * @prop {Content/Lesson} lesson
   */lesson:_ember['default'].computed.alias('model.data'), /**
   * @prop {String} unitId - ID of the unit this lesson belongs to
   */unitId:null, /**
   * When a lesson within this unit is expanded/collapsed
   */onExpandLesson:null, // -------------------------------------------------------------------------
// Methods
/**
   * Load data for the unit
   *
   * @function actions:loadData
   * @returns {undefined}
   */loadData:function loadData(){if(!this.get('isLoaded')){var courseId=this.get('course.id');var unitId=this.get('unitId');var lessonId=this.get('lesson.id');return this.get('lessonService').fetchById(courseId,unitId,lessonId).then((function(lesson){this.set('model.data',lesson);this.set('items',lesson.get('children'));this.set('isLoaded',true);}).bind(this))['catch']((function(error){var message=this.get('i18n').t('common.errors.lesson-not-loaded').string;this.get('notifications').error(message);_ember['default'].Logger.error(error);}).bind(this));}else {return _ember['default'].RSVP.resolve(true);}},scrollHere:function scrollHere(){var $component=_ember['default'].$(this.get('element'));_ember['default'].$('html, body').animate({scrollTop:$component.offset().top - 200},100);}, // -------------------------------------------------------------------------
// Events
/**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){var component=this;var unitId=this.get('unitId');var lessonId=this.get('lesson.id');var isEdit=unitId && lessonId;var expand=component.get('model.isExpanded') && isEdit && !this.get('isSorting');if(expand){component.scrollHere();component.loadData();}}});});