define('gooru-web/components/player/questions/gru-comprehension',['exports','ember','gooru-web/models/result/question'],function(exports,_ember,_gooruWebModelsResultQuestion){exports['default'] = _ember['default'].Component.extend({ // ------------------------------------------------------------------
// Attributes
classNames:['gru-comprehension'], // -------------------------------------------------------------------
// Properties
questionDetails:null, // isInputDisabled: false,
subQuestionList:_ember['default'].computed('baseQuestion',function(){var subQuestion=this.get('baseQuestion.subQuestions') || _ember['default'].A();return subQuestion.map(function(item){item.setProperties({playerQuestion:item.toPlayerResource(),questionResult:_gooruWebModelsResultQuestion['default'].create({})});return item;});}) // -------------------------------------------------------------------
// Events
// ------------------------------------------------------------------
// Actions
// ------------------------------------------------------------------
// Methods
});});