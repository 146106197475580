define('gooru-web/models/map/content',['exports','ember'],function(exports,_ember){ /**
 * MapContext model
 *
 * @typedef {Object} MapContext
 */var MapContentModel=_ember['default'].Object.extend({ /**
   * @property {string} contentId
   */id:null, /**
   * @property {string} title
   */title:null, /**
   * @property {string} description
   */description:null, /**
   * @property {string} url
   */url:null, /**
   * @property {string} thumbnail
   */thumbnail:null});exports['default'] = MapContentModel;});