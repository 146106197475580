define('gooru-web/components/player/offline-activity/gru-offline-activity-submission',['exports','ember','gooru-web/utils/utils','gooru-web/config/config'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['offline-activity-player','gru-offline-activity-submission'],classNameBindings:['isPreview:player-preview','isOaCompleted:oa-completed'], // -------------------------------------------------------------------------
// Dependencies
oaAnalyticsService:_ember['default'].inject.service('api-sdk/offline-activity/oa-analytics'),oaService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadTaskSubmissionData();component.afterRender();if(!component.get('isTeacher')){component.doCheckOaCompleted();}},actions:{ //Action triggered when toggle timespent entry container
onToggleTimespent:function onToggleTimespent(){var component=this;component.toggleProperty('isTimespentExpanded');component.$('.timespent-container').slideToggle();}, //Action triggered when save entered timespent
onSaveTimespent:function onSaveTimespent(){var component=this;component.set('isTimespentExpanded',false);component.submitTaskDetails(component.createTaskTimeSubmissionPayload());component.$('.timespent-container').slideUp();}, //Action triggered when click on complete submission
onCompleteSubmission:function onCompleteSubmission(){var component=this;component.markOACompleted().then(function(){component.set('isOaCompleted',true);component.set('isShowCompletionConfirmation',false);});}, //Action triggered when click on cancel button in the confirmation popup
onCancelCompletion:function onCancelCompletion(){this.set('isShowCompletionConfirmation',false);}, //Action triggered when click on confirm/yes button in the confirmation popup
onShowCompletionConfirmation:function onShowCompletionConfirmation(){var component=this;component.set('isShowCompletionConfirmation',true);}, //Action triggered when click on self grade
onTriggerSelfGrade:function onTriggerSelfGrade(){var component=arguments.length <= 0 || arguments[0] === undefined?this:arguments[0];var classId=component.get('classId');var content=component.get('offlineActivity');var contentSource=component.get('contentSource');var contentType=_gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY;var selfGradeItemContext=_ember['default'].Object.create({classId:classId,content:content,contentType:contentType,contentSource:contentSource,dcaContentId:component.get('caContentId')});var itemsToGrade=_ember['default'].A([selfGradeItemContext]);component.set('itemsToGrade',itemsToGrade);component.set('selfGradeItemContext',selfGradeItemContext);component.set('isShowOaSelfGrading',true);}, //Action triggered once self grading is done
onDoneOaGrading:function onDoneOaGrading(){var component=this;component.set('isEnableSelfGrading',false);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} classId
   */classId:null, /**
   * @property {UUID} classId
   */courseId:null, /**
   * @property {UUID} classId
   */unitId:null, /**
   * @property {UUID} classId
   */lessonId:null, /**
   * @property {UUID} userId
   */userId:_ember['default'].computed.alias('session.userId'), /**
   * @property {Number} oaTimespentHour
   */oaTimespentHour:0, /**
   * @property {Number} oaTimespentMinute
   */oaTimespentMinute:0, /**
   * @property {Boolean} isTimespentExpanded
   */isTimespentExpanded:true, /**
   * @property {Number} timespentInMilliSec
   */timespentInMilliSec:_ember['default'].computed('oaTimespentHour','oaTimespentMinute',function(){var component=this;var hour=component.get('oaTimespentHour') || 0;var minute=component.get('oaTimespentMinute') || 0;return (0,_gooruWebUtilsUtils.getTimeInMillisec)(hour,minute);}), /**
   * @property {Number} timespentInMilliSecCopy
   * Property for copied version of timespent in millisec
   */timespentInMilliSecCopy:0, /**
   * @property {Boolean} isEnableSaveTimespent
   */isEnableSaveTimespent:_ember['default'].computed('timespentInMilliSec',function(){var component=this;var timespentInMilliSec=component.get('timespentInMilliSec');var timespentInMilliSecCopy=component.get('timespentInMilliSecCopy');return timespentInMilliSec > timespentInMilliSecCopy && component.validateOATimespent();}), /**
   * @property {Boolean} isPreview
   * Property to show the player in read only mode or not
   */isPreview:false, /**
   * @property {String} contentSource
   * Assign player event source as based on caContentId property
   */contentSource:_ember['default'].computed('caContentId',function(){var component=this;var caContentId=component.get('caContentId');return caContentId?_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.DAILY_CLASS:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP;}), /**
   * @property {Boolean} isShowCompletionConfirmation
   * Property to check whether to show or not the completeion confirmation popup
   */isShowCompletionConfirmation:false, /**
   * @property {Boolean} isOaCompleted
   * Property to evaluate whether the OA is completed or not
   */isOaCompleted:false, /**
   * @property {Boolean} isStudent
   */isStudent:_ember['default'].computed.equal('session.role',_gooruWebConfigConfig.ROLES.STUDENT), /**
   * property used to show student title
   */studentTitle:_gooruWebConfigConfig.ROLES.STUDENT, /**
   * property used to show teacher title
   */teacherTitle:_gooruWebConfigConfig.ROLES.TEACHER, /**
   * @property {studentActivityReferences[]} List of user reference details
   */studentActivityReferences:_ember['default'].computed('offlineActivity.references',function(){var reference=this.get('offlineActivity.references');if(reference && reference.length){var filtercontent=reference.filterBy('userType',_gooruWebConfigConfig.ROLES.STUDENT);return _ember['default'].Object.create({references:filtercontent});}return {};}), /**
   * @property {teacherActivityReferences[]} List of teacher reference details
   */teacherActivityReferences:_ember['default'].computed('offlineActivity.references',function(){var reference=this.get('offlineActivity.references');if(reference && reference.length){var filtercontent=reference.filterBy('userType',_gooruWebConfigConfig.ROLES.TEACHER);return _ember['default'].Object.create({references:filtercontent});}return {};}), /**
   * @property {UUID} oaId
   * Property for active Offline Activity UUID
   */oaId:_ember['default'].computed.alias('offlineActivity.id'), /**
   * @property {Boolean} isEnableCompletionButton
   * Property to check whether to enable or not the mark complete button
   */isEnableCompletionButton:_ember['default'].computed('activityTasks.@each.isAddedMandatorySubmission','activityTasks.@each.isTaskSubmitted','activityTasks',function(){var component=this;var activityTasks=component.get('activityTasks') || _ember['default'].A([]);if(activityTasks && activityTasks.length > 0){var isInCompleteTaskAvailable=activityTasks.filter(function(task){return !task.isAddedMandatorySubmission && task.isEvidenceRequired;});var isUnSubmittedTaskAvailable=activityTasks.filter(function(task){return !task.isTaskSubmitted && task.isEvidenceRequired;});var enableCompletionButton=!(isInCompleteTaskAvailable.length || isUnSubmittedTaskAvailable.length);return enableCompletionButton;}else {return false;}}), /**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}), /**
   * @property {Boolean} isEnableSelfGrading
   * Property to enable/disable self grading flow
   */isEnableSelfGrading:_ember['default'].computed('isSelfGradingDone','isOaCompleted',function(){var component=this;return !component.get('isSelfGradingDone') && component.get('isOaCompleted');}), /**
   * @property {Boolean} isSelfGradingDone
   * Property to check whether self grading is done or not
   */isSelfGradingDone:false, /**
   * @property {Array} studentRubric
   * Property will contain attached student rubric item
   */studentRubric:_ember['default'].computed.filterBy('offlineActivity.rubric','grader','Self'), /**
   * @property {Array} teacherRubric
   * Property will contain attached teacher rubric item
   */teacherRubric:_ember['default'].computed.filterBy('offlineActivity.rubric','grader','Teacher'), /**
   * @property {Boolean} isCourseMapGrading
   * Property to determine whether it's a course map grading or CA
   */isCourseMapGrading:_ember['default'].computed.equal('contentSource',_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP), /**
   * @property {Boolean} isShowOaSelfGrading
   * Property to show/hide OA grading container
   */isShowOaSelfGrading:_ember['default'].computed('activityTasks','isOaCompleted',function(){var component=this;return component.get('activityTasks.length') && component.get('isOaCompleted');}), /**
   * @property {Object} selfGradeItemContext
   * Property for OA's grading context
   */selfGradeItemContext:_ember['default'].computed(function(){var component=this;var classId=component.get('classId');var content=component.get('offlineActivity');var contentSource=component.get('contentSource');var contentType=_gooruWebConfigConfig.CONTENT_TYPES.OFFLINE_ACTIVITY;return _ember['default'].Object.create({classId:classId,content:content,contentType:contentType,contentSource:contentSource,dcaContentId:component.get('caContentId')});}), /**
   * @property {Array} itemsToGrade
   * Property for list of items needs to be graded
   */itemsToGrade:_ember['default'].computed('selfGradeItemContext',function(){return _ember['default'].A([this.get('selfGradeItemContext')]);}),isParentSubmit:false,tiggerAction:false,triggerObserver:_ember['default'].observer('tiggerAction',function(){if(this.get('tiggerAction')){this.send('onCompleteSubmission');}}), // -------------------------------------------------------------------------
// Methods
/**
   * @function loadTaskSubmissionData
   * Method to load student submitted oa task data
   */loadTaskSubmissionData:function loadTaskSubmissionData(){var component=this;component.set('isLoading',true);return _ember['default'].RSVP.hash({tasksSubmissions:!component.get('isPreview')?component.get('offlineActivitySubmissions') || component.fetchTasksSubmissions():null}).then(function(_ref){var tasksSubmissions=_ref.tasksSubmissions;if(!component.isDestroyed){(function(){var activityTasks=component.get('offlineActivity.tasks');if(tasksSubmissions){var studentTasksSubmissions=tasksSubmissions.get('tasks');var oaRubrics=tasksSubmissions.get('oaRubrics');var submittedTimespentInMillisec=oaRubrics?oaRubrics.get('studentGrades.timeSpent'):0;studentTasksSubmissions.map(function(taskSubmission){var activityTask=activityTasks.findBy('id',taskSubmission.get('taskId'));if(activityTask){var activityTaskSubmissions=taskSubmission.get('submissions');activityTask.set('studentTaskSubmissions',activityTaskSubmissions);if(activityTaskSubmissions.length){var taskSubmissionText=activityTaskSubmissions.findBy('submissionType','free-form-text');activityTask.set('submissionText',taskSubmissionText?taskSubmissionText.get('submissionInfo'):null);}}});if(submittedTimespentInMillisec){component.set('timespentInMilliSecCopy',submittedTimespentInMillisec);component.formatMillisecondsToHourMinute((0,_gooruWebUtilsUtils.formatTime)(submittedTimespentInMillisec));}if(tasksSubmissions.get('oaRubrics.studentGrades')){var studentGrades=tasksSubmissions.get('oaRubrics.studentGrades');component.set('isSelfGradingDone',!!studentGrades.get('grader'));}component.set('offlineActivitySubmissions',tasksSubmissions);}component.set('activityTasks',activityTasks);component.set('isLoading',false);})();}});}, /**
   * @function doCheckOaCompleted
   * Method to check whether the OA is completed by the student or not
   */doCheckOaCompleted:function doCheckOaCompleted(){var component=this;return component.fetchOaCompletedStudents().then(function(students){var userId=component.get('userId');var isCompletedByStudent=students.includes(userId);component.set('isOaCompleted',isCompletedByStudent);});}, /**
   * @function fetchTasksSubmissions
   * Method to fetch student submitted oa task data
   */fetchTasksSubmissions:function fetchTasksSubmissions(){var component=this;var classId=component.get('classId');var oaId=component.get('caContentId') || component.get('oaId');var userId=component.get('userId');var dataParam=undefined;if(component.get('isStudyPlayer')){var courseId=component.get('courseId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');dataParam = {courseId:courseId,unitId:unitId,lessonId:lessonId};}return component.get('oaAnalyticsService').getSubmissionsToGrade(classId,oaId,userId,dataParam);}, /**
   * @function formatMillisecondsToHourMinute
   * Method to extract hour and minute from fullstring
   */formatMillisecondsToHourMinute:function formatMillisecondsToHourMinute(timeString){var component=this;var hour=0;var minute=0;if(timeString){var splittedTime=timeString.split(' ');var firstHalfString=splittedTime[0];var secodHalfString=splittedTime[1];if(firstHalfString.includes('h')){hour = firstHalfString.slice(0,-1);}else if(firstHalfString.includes('m')){minute = firstHalfString.slice(0,-1);}if(secodHalfString.includes('m')){minute = secodHalfString.slice(0,-1);}}component.set('oaTimespentHour',hour);component.set('oaTimespentMinute',minute);}, /**
   * @function validateOATimespent
   * Method to validate OA timespent
   */validateOATimespent:function validateOATimespent(){var component=this;var isValidTimeSpent=false;var hour=component.get('oaTimespentHour') || 0;var minute=component.get('oaTimespentMinute') || 0;if(hour || minute){isValidTimeSpent = hour > 0?minute >= 0 && minute < 60:minute > 0 && minute <= 60;}return isValidTimeSpent;}, /**
   * @function markOACompleted
   * @return {Promise}
   * Method to update the OA status for active student
   */markOACompleted:function markOACompleted(){var component=this;var classId=component.get('classId');var caContentId=component.get('caContentId');var oaId=component.get('oaId');var contentSource=component.get('contentSource');var studentId=component.get('userId');var studentRubric=component.get('studentRubric').objectAt(0) || null;var oaData={class_id:classId,oa_id:oaId,content_source:contentSource,student_id:studentId,marked_by:_gooruWebConfigConfig.ROLES.STUDENT,path_id:0,path_type:null,time_zone:component.get('timeZone'),student_rubric_id:null};if(studentRubric){oaData.student_rubric_id = studentRubric.get('id');}if(component.get('isStudyPlayer')){oaData.course_id = component.get('courseId');oaData.unit_id = component.get('unitId');oaData.lesson_id = component.get('lessonId');}else {oaData.oa_dca_id = parseInt(caContentId);}return component.get('oaService').updateOACompleted(oaData);}, /**
   * @function fetchOaCompletedStudents
   * @return {Promise}
   * Method to get list of students who have marked an OA as completed
   */fetchOaCompletedStudents:function fetchOaCompletedStudents(){var component=this;var classId=component.get('classId');var oaId=component.get('oaId'); //content Id will be undefined to treat it as a CM request
var caContentId=component.get('caContentId') || undefined;var courseId=component.get('courseId');var unitId=component.get('unitId');var lessonId=component.get('lessonId');var dataParam=null;if(component.get('isStudyPlayer')){dataParam = {courseId:courseId,unitId:unitId,lessonId:lessonId};}return component.get('oaAnalyticsService').getOaCompletedStudents(classId,oaId,caContentId,dataParam);},afterRender:function afterRender(){var component=this;var activityTasks=component.get('offlineActivity.tasks') || _ember['default'].A([]);if(activityTasks && activityTasks.length > 0){activityTasks.map(function(act){return act.set('focus',false);});activityTasks.get('firstObject').set('focus',true); //first task to focus
}}, /**
   * @function submitTaskDetails
   * Method to send student task submissions into Analytics
   */submitTaskDetails:function submitTaskDetails(taskSubmissionPayload){var component=this;component.get('oaService').oaTaskSubmissions(taskSubmissionPayload);}, /**
   * @function createTaskSubmissionPayload
   * Method to create task submission request payload
   */createTaskTimeSubmissionPayload:function createTaskTimeSubmissionPayload(){var component=this;var userId=component.get('userId');var contentSource=component.get('contentSource');var classId=component.get('classId');var caContentId=component.get('caContentId');var taskSubmissions=[];var timespentInMilliSec=component.get('timespentInMilliSec');var submissionPayload={student_id:userId,class_id:classId,oa_id:component.get('oaId'),content_source:contentSource,submissions:taskSubmissions,time_spent:timespentInMilliSec};if(component.get('isStudyPlayer')){submissionPayload.course_id = component.get('courseId');submissionPayload.unit_id = component.get('unitId');submissionPayload.lesson_id = component.get('lessonId');}else {submissionPayload.oa_dca_id = parseInt(caContentId);}return submissionPayload;}});});