define('gooru-web/components/class/overview/routesuggestion-coursemap',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({classNames:['routesuggestion-coursemap'],actions:{routeAction:function routeAction(actiontype){this.attrs.routeSuggestAction(actiontype);},selectUnit:function selectUnit(){ //ToDo: Add impl on player
}, /**
     * Trigger the 'onLocationUpdate' event handler
     *
     * @function actions:updateLocation
     * @param {string} newLocation - String of the form 'unitId[+lessonId[+resourceId]]'
     */updateLocation:function updateLocation(newLocation){if(this.get('onLocationUpdate')){this.get('onLocationUpdate')(newLocation);}}}, /**
   * @property {isEnablePlayer}
   * Property to show/hide player icon in suggestion coursemap
   */isEnablePlayer:true,taxonomyList:[], /**
   * Find user location in route0
   * if present then pass that route0location to course+unit+lesson+c for selection
   */isLocationInRoute0:_ember['default'].computed('userLocation',{get:function get(){var component=this;if(component.userLocation && component.userLocation !== ''){(function(){var parslocationarr=component.userLocation.split('+');component.route0.milestones.map(function(milestone){return milestone.units.find(function(unit){return unit.unitId === parslocationarr[0];});});})();}else { // can no location be treated as route0 init ?
}}})});});