define('gooru-web/components/content/activity/task/gru-submissions',['exports','ember','gooru-web/utils/utils','gooru-web/models/content/oa/task/submission'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebModelsContentOaTaskSubmission){exports['default'] = _ember['default'].Component.extend({ /**------------------------------------------------------------------------------------------------
   * Structure
   *    -tasks Layout,
   *        -list of tasks
   *        -- Line item of each task
   *        -- Invoke New Task
   *            -- Invoke Submission Layout
   *                -- List of submissions
   *                -- New Submission
   * ------------------------------------------------------------------------------------------------
   * Models
   *  Parent Models : Receives activityModel, editingActivity Model
   *  Own Models :
   *     editingActivity> Tasks : List of Task Models
   *      editingActivity> Tasks > Task : Task Entity Model
   *        editingActivity> Tasks > Task > submissions : List of Submission Models
   *         editingActivity> Tasks > Task > submissions > submission: Submission Entity Model
   * ------------------------------------------------------------------------------------------------
   *    UI Interaction:
   *         Load from Tasks tab click, or scroll down if mandatory OA is created
   *         Detects empty Tasks collection > Open New Task creation, otherwise show list of tasks, AND
   *         Task Creation form at the end. Saving task adds to task list again with empty task creation form
   *
   * ------------------------------------------------------------------------------------------------*/ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/offline-activity/offline-activity
   */activityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * Collection model as instantiated by the route. This is the clean model used when not editing
   * or after any collection changes have been saved.
   * @property {Collection}
   */activityModel:null, /**
   * Collection model as passed by the route. The dirty model used for editing and saving changes to.
   * This is sent to parent for syncing.
   * @property {Collection}
   */submissions:null,oaId:null,oaTaskId:null, // -------------------------------------------------------------------------
// Attributes
classNames:['content','gru-task'], // -------------------------------------------------------------------------
// Actions
actions:{updateSubmissionCollection:function updateSubmissionCollection(submission){var component=this;var taskSubmissionsCol=component.get('submissions');taskSubmissionsCol.pushObject(submission);component.refreshSubmission();},removeLineItem:function removeLineItem(submission){var component=this;component.set('isLoading',true);if(submission.refData && submission.refData.length > 0){submission = submission.refData[0];}else {return;}component.removeSubmission(submission).then(function(submission){var tasksSubmissionsCol=component.get('submissions');tasksSubmissionsCol.removeObject(submission);component.refreshSubmission();component.set('isLoading',false);});}, /**
     * Save content
     */updateContent:function updateContent(taskSubmissionSubType){var component=this;component.saveTaskSubmission(taskSubmissionSubType).then(function(submission){component.send('updateSubmissionCollection',submission);});}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);}, // -------------------------------------------------------------------------
// Properties
removeSubmission:function removeSubmission(tasksSubmission){var component=this;tasksSubmission.set('oaTaskId',component.get('oaTaskId'));tasksSubmission.set('oaId',component.get('oaId'));return component.get('activityService').removeTaskSubmission(tasksSubmission);},parsedSubmissions:_ember['default'].computed('submissions',function(){var component=this;var uploadedCol=component.get('submissions').filter(function(f){return f.taskSubmissionType === 'uploaded';});var displayData=_ember['default'].A([]);var allTypes=(0,_gooruWebUtilsUtils.getOASubType)();allTypes.forEach(function(ref){var filterCol=uploadedCol.filter(function(f){return f.taskSubmissionSubType === ref.display_name;});var displayItem={taskSubmissionSubType:ref.display_name,refData:filterCol,mimeType:ref.mimeType,count:filterCol.length};displayData.pushObject(displayItem);});return displayData;}),saveTaskSubmission:function saveTaskSubmission(taskSubmissionSubType){var component=this;var model=_gooruWebModelsContentOaTaskSubmission['default'].create({oaTaskId:this.get('oaTaskId')});model.set('oaTaskId',component.get('oaTaskId'));model.set('oaId',component.get('oaId'));model.set('taskSubmissionType','uploaded');model.set('taskSubmissionSubType',taskSubmissionSubType);return component.get('activityService').createTaskSubmission(model);},refreshSubmission:function refreshSubmission(){var component=this;var subCol=component.get('submissions');var newSource=subCol.slice(0);Object.assign(newSource,subCol);_ember['default'].set(this,'submissions',newSource);},initRefreshSubmission:function initRefreshSubmission(){var component=this;var subCol=component.get('submissions');var newSource=subCol.slice(0);_ember['default'].set(this,'submissions',newSource);}});});