define('gooru-web/serializers/century-skill/century-skill',['exports','ember','gooru-web/config/config','gooru-web/models/century-skill/century-skill'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebModelsCenturySkillCenturySkill){ /**
 *
 * @typedef {Object} CenturySkillSerializer
 */exports['default'] = _ember['default'].Object.extend({init:function init(){this._super.apply(this,arguments);}, /**
   * Normalize the century skills endpoint response
   * @param payload The endpoint response in JSON format
   * @returns {CenturySkill[]} a list of century skill model objects
   */normalizeCenturySkills:function normalizeCenturySkills(payload){var serializer=this;var centurySkills=payload['21_century_skills'];var cognitiveSkillsGroup=centurySkills[_gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_COGNITIVE_SKILLS_AND_STRATEGIES];var contentSkillsGroup=centurySkills[_gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_CONTENT_KNOWLEDGE];var learningSkillsGroup=centurySkills[_gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_LEARNING_SKILLS_AND_TECHNIQUES];var normalizedCenturySkills=[];cognitiveSkillsGroup.forEach(function(cognitiveSkill){normalizedCenturySkills.push(serializer.normalizeReadCenturySkillInfo(cognitiveSkill,_gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_COGNITIVE_SKILLS_AND_STRATEGIES));});contentSkillsGroup.forEach(function(contentSkill){normalizedCenturySkills.push(serializer.normalizeReadCenturySkillInfo(contentSkill,_gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_CONTENT_KNOWLEDGE));});learningSkillsGroup.forEach(function(learningSkill){normalizedCenturySkills.push(serializer.normalizeReadCenturySkillInfo(learningSkill,_gooruWebConfigConfig.CENTURY_SKILLS_GROUPS.KEY_LEARNING_SKILLS_AND_TECHNIQUES));});return normalizedCenturySkills;}, /**
   * Normalize the Read Century Skill info endpoint response
   *
   * @param payload is the endpoint response in JSON format
   * @param {String} group of century skill
   * @returns {CenturySkillModel} a centurySkill model object
   */normalizeReadCenturySkillInfo:function normalizeReadCenturySkillInfo(payload){var group=arguments.length <= 1 || arguments[1] === undefined?null:arguments[1];return _gooruWebModelsCenturySkillCenturySkill['default'].create(_ember['default'].getOwner(this).ownerInjection(),{id:payload.id,label:payload.label,hewlettDeepLearningModel:payload.hewlett_deep_learning_model,conleyFourKeysModel:payload.conley_four_keys_model,p21FrameworkModel:payload.p21_framework_model,nationalResearchCenterModel:payload.national_research_center_model,group:group});}});});