define('gooru-web/routes/teacher/class/class-management',['exports','ember','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsUiHelperMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsUiHelperMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
queryParams:{googleConnect:{refreshModel:true}}, /**
   * @property {Ember.Service} Service to do retrieve language
   */lookupService:_ember['default'].inject.service('api-sdk/lookup'), /**
   * @property {Boolean} isLevelDropdown
   */isLevelDropdown:false, /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'),fluencyService:_ember['default'].inject.service('api-sdk/fluency'),session:_ember['default'].inject.service('session'),tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Methods
model:function model(params){var route=this;if(params.googleConnect){var data={eventName:'close'};window.opener.postMessage(data,'*');window.close();}var currentClass=route.modelFor('teacher.class')['class'];route.setTitle('Class Settings',currentClass.title);var subject=currentClass.get('preference.subject');var filters={subject:subject};var fwkCode=currentClass.get('preference.framework');if(fwkCode){filters.fw_code = fwkCode;}var fluData={subject_code:subject,fw_id:fwkCode,tenant_id:this.get('session.tenantId')};var taxonomyService=route.get('taxonomyService');return _ember['default'].RSVP.hash({languages:route.get('lookupService').getLanguages(),subjectTaxonomyGrades:subject?route.get('taxonomyService').fetchGradesBySubject(filters):[],'class':currentClass,gradeSubjectFWK:subject?taxonomyService.fetchSubject(subject):[],frameworks:subject?taxonomyService.fetchSubjectFWKs(subject):[],fluencyDropdownValue:route.isShowFluencyLevel() && subject && fwkCode?route.get('fluencyService').getFluencyLevel(fluData):null});}, /**
   * Set all controller properties from the model
   * @param controller
   */setupController:function setupController(controller,model){controller.resetValues();controller.loadSecondaryClassList();controller.set('languages',model.languages);controller.set('subjectTaxonomyGrades',model.subjectTaxonomyGrades);var mathLevels=this.parseMathlevel(model['class'],model.subjectTaxonomyGrades);if(!mathLevels.length){controller.set('isLevelDropdown',true);}controller.set('levelItems',mathLevels);controller.set('class',model['class']);controller.set('gradeSubjectFWK',model.gradeSubjectFWK);controller.set('frameworks',model.frameworks);controller.set('tempClass',model['class'].copy());controller.set('tempClass.preference',controller.get('class.preference'));controller.set('tempClass.setting',controller.get('class.setting'));controller.get('classController').selectMenuItem('class-management');if(model.fluencyDropdownValue){(function(){var f_data=_ember['default'].A();model.fluencyDropdownValue.fluency.forEach(function(item){item.fluency_display_title = item.fluency_display_code + ' - ' + item.fluency_description;f_data.push(_ember['default'].Object.create(item));});controller.set('fluencyDropdownValue',f_data);})();}controller.setupDisplayProperties();window.addEventListener('message',function(event){if(event.data && event.data.eventName === 'close'){controller.updateGoogleClassroom();}},false);controller.callTenantSettings();},parseMathlevel:function parseMathlevel(classData,subjectTaxonomyGrades){var controller=this;var levelItems=_ember['default'].A();subjectTaxonomyGrades && subjectTaxonomyGrades.forEach(function(grade){if(grade.levels){levelItems = levelItems.concat(grade.levels);}});controller.set('levelItems',levelItems);return levelItems;},isShowFluencyLevel:function isShowFluencyLevel(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());return tenantSettings && tenantSettings.fluency_level === 'on';}});});