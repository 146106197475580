define('gooru-web/components/gru-notify',['exports','ember'],function(exports,_ember){ /**
 * Notify
 *
 * Component used to notify when questions answers have been loaded
 * (@see gru-collection-list-item.hbs)
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({tagName:'span',onLoad:null, // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.sendAction('onLoad');}});});