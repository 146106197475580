define('gooru-web/components/player/questions/gru-multiple-answer',['exports','ember','gooru-web/components/player/questions/gru-question'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruQuestion){ /**
 * Multiple Answer Question
 *
 * Component responsible for controlling the logic and appearance of a multiple
 * answer question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-multiple-answer'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * When the user changes the answer choice selection
     * @param {string} answerId
     * @param {boolean} onLoad if this was called when loading the component
     */selectAnswerChoice:function selectAnswerChoice(answerId){var component=this;component.setUserAnswerChoice(answerId);component.notify(false);}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);var userSelection=this.get('userAnswer') || _ember['default'].A([]);this.set('userSelection',userSelection);if(this.get('hasUserAnswer')){this.notify(true);}}, // -------------------------------------------------------------------------
// Properties
/**
   * Convenient structure to render options
   * @property {[]}
   */answers:_ember['default'].computed('question.answers','userAnswer',function(){var component=this;var answers=this.get('question.answers');var userAnswer=this.get('userAnswer');return answers.map(function(answer){var answerId=answer.get('id');var userSelectionItem=userAnswer?userAnswer.findBy('id',answerId):null;return {id:answerId,text:answer.get('text'),groupValue:userSelectionItem?component.userSelectionItemToChoice(userSelectionItem):null};});}),didInsertElement:function didInsertElement(){var $fileInput=this.$('input[type="radio"]');if($fileInput){$fileInput.attr('title','checkbox');}}, // -------------------------------------------------------------------------
// Observers
resetUserSelection:_ember['default'].observer('question',function(){this.set('userSelection',_ember['default'].A());}), // -------------------------------------------------------------------------
// Methods
/**
   * Notifies answer events
   * @param {boolean} onLoad if this was called when loading the component
   */notify:function notify(onLoad){var component=this;var questionUtil=component.get('questionUtil');var userSelection=component.get('userSelection').toArray();var correct=questionUtil.isCorrect(userSelection);component.notifyAnswerChanged(userSelection,correct);if(component.isAnswerCompleted()){if(onLoad){component.notifyAnswerLoaded(userSelection,correct);}else {component.notifyAnswerCompleted(userSelection,correct);}}}, /**
   * Indicates when the answer is completed
   * @return {boolean}
   */isAnswerCompleted:function isAnswerCompleted(){var component=this,userSelection=component.get('userSelection'),totalAnswerChoices=component.get('question.answers.length');return userSelection.get('length') === totalAnswerChoices;}, /**
   * Sets the user answer choice
   * @param {string} answerChoice containing the user selection yes|120202 or no|20200392
   */setUserAnswerChoice:function setUserAnswerChoice(answerChoice){var userSelection=this.get('userSelection');var userSelectionItem=this.choiceToUserSelectionItem(answerChoice);var id=userSelectionItem.id;var selection=userSelectionItem.selection;var found=userSelection.findBy('id',id);if(found){found.selection = selection;}else {userSelection.addObject(userSelectionItem);}}, /**
   * Converts the answer choice string to a  user selection item
   * @param {string} answerChoice  in the format value|id, i.e yes|answer_1
   * @returns {{id: *, selection: boolean}}
   */choiceToUserSelectionItem:function choiceToUserSelectionItem(answerChoice){var values=answerChoice.split('|');var id=values[1];var selection=values[0] === 'yes';return {id:id,selection:selection};}, /**
   * Converts user selection item to answer choice
   * @param {{id: *, selection: boolean}} userSelectionItem
   *
   * @return {string} in the format value|id, i.e yes|answer_1
   */userSelectionItemToChoice:function userSelectionItemToChoice(userSelectionItem){var selection=userSelectionItem.selection?'yes':'no';return selection + '|' + userSelectionItem.id;}});});