define('gooru-web/adapters/goal/goal',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the goal CRUD operations in the API 3.0
 *
 * @typedef {Object} GoalAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service(),namespace:'/api/nucleus/v1/goals', /**
   * Posts a new goal
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|String} ID of the newly created goal
   */createGoal:function createGoal(params){var namespace=this.get('namespace');var url='' + namespace;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData,textStatus,request){var goalId=request.getResponseHeader('location');resolve(goalId);},function(error){reject(error);});});}, /**
   * Updates a goal
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|Boolean} true when updated
   */updateGoal:function updateGoal(params,goalId){var namespace=this.get('namespace');var url=namespace + '/' + goalId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(){resolve(true);},function(error){reject(error);});});}, /**
   * Deletes a goal
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|boolean} true when deleted
   */deleteGoal:function deleteGoal(goalId){var namespace=this.get('namespace');var url=namespace + '/' + goalId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders()};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(){resolve(true);},function(error){reject(error);});});}, /**
   * Gets user goals
   *
   * @param {string} userId
   * @returns {Promise|Object}
   */getGoalsByUser:function getGoalsByUser(userId){var namespace=this.get('namespace');var url=namespace + '/user/' + userId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders()};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},function(error){reject(error);});});},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});