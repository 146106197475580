define('gooru-web/models/performance/class-performance-summary',['exports','ember','gooru-web/models/performance/performance-summary','gooru-web/utils/math'],function(exports,_ember,_gooruWebModelsPerformancePerformanceSummary,_gooruWebUtilsMath){ /**
 * Class performance summary model
 *
 * @typedef {Object} ClassPerformanceSummary
 */exports['default'] = _gooruWebModelsPerformancePerformanceSummary['default'].extend({ /**
   * @property {String} classId
   */classId:null, /**
   * @property {number} total learning items completed
   */totalCompleted:null, /**
   * @property {number} total learning items
   */total:null, /**
   * @property {number} completed percentage
   */completedPercentage:_ember['default'].computed('totalCompleted','total',function(){var total=this.get('total');return total?(0,_gooruWebUtilsMath.roundFloat)(this.get('totalCompleted') / this.get('total') * 100):0;})});});