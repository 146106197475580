define('gooru-web/services/api-sdk/media',['exports','ember','gooru-web/config/config','gooru-web/adapters/media'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebAdaptersMedia){ /**
 * @typedef {Object} MediaService
 */exports['default'] = _ember['default'].Service.extend({session:_ember['default'].inject.service('session'),mediaAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('mediaAdapter',_gooruWebAdaptersMedia['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Uploads a file to the content cdn
   *
   * @param fileData object with the data
   * @returns {Promise}
   */uploadContentFile:function uploadContentFile(fileData){var isAudio=arguments.length <= 1 || arguments[1] === undefined?false:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('mediaAdapter').uploadFile(fileData,_gooruWebConfigConfig.ENTITY_TYPE.CONTENT,isAudio).then(function(response){resolve(service.get('session.cdnUrls.content') + response.filename);},function(){resolve(null);});});}, /**
   * Uploads a file to the user cdn
   *
   * @param fileData object with the data
   * @returns {Promise}
   */uploadUserFile:function uploadUserFile(fileData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('mediaAdapter').uploadFile(fileData,_gooruWebConfigConfig.ENTITY_TYPE.USER).then(function(response){resolve(service.get('session.cdnUrls.user') + response.filename);},function(error){reject(error);});});},uploadScormFile:function uploadScormFile(fileData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('mediaAdapter').uploadScormFile(fileData).then(function(response){resolve(response);},function(){resolve(null);});});}});});