define('gooru-web/components/reports/assessment/questions/gru-hs-text',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * Hot spot text
 *
 * Component responsible for show the hot spot text, which option is selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-hs-text'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
answers:_ember['default'].computed('question.answerObject.[]','anonymous',function(){var component=this;var question=component.get('question');var questionUtil=component.getQuestionUtil(question);var userAnswers=component.get('userAnswer');var anonymous=component.get('anonymous');if(component.get('showCorrect')){userAnswers = questionUtil.getCorrectAnswer();}var answers=question.get('answers');return answers.map(function(answer){var userAnswerCorrect=false;var selected=false;if(userAnswers.includes(answer.get('id'))){userAnswerCorrect = questionUtil.isAnswerChoiceCorrect(answer.get('id'));selected = true;}var elementClass=anonymous?'anonymous':userAnswerCorrect?'correct':'incorrect';return {text:answer.get('text'),selected:selected,'class':elementClass};});}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});