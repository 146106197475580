define('gooru-web/services/performance',['exports','ember','gooru-web/adapters/performance/performance','gooru-web/serializers/performance'],function(exports,_ember,_gooruWebAdaptersPerformancePerformance,_gooruWebSerializersPerformance){ /**
 * Service for the performance
 *
 * @typedef {Object} performanceService
 */exports['default'] = _ember['default'].Service.extend({performanceAdapter:null,performanceSerializer:null,init:function init(){this._super.apply(this,arguments);this.set('performanceAdapter',_gooruWebAdaptersPerformancePerformance['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('performanceSerializer',_gooruWebSerializersPerformance['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Get performance of user performance units
   * @returns {Promise.<[]>}
   */getUserPerformanceUnits:function getUserPerformanceUnits(userId,courseId,classId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('performanceAdapter').getUserPerformanceUnits(userId,courseId,classId).then(function(response){resolve(service.get('performanceSerializer').normalizeUserPerformanceUnits(response));},reject);});}, /**
   * Get performance of user performance lessons
   * @returns {Promise.<[]>}
   */getUserPerformanceLessons:function getUserPerformanceLessons(userId,courseId,unitId,classId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('performanceAdapter').getUserPerformanceLessons(userId,courseId,unitId,classId).then(function(response){resolve(service.get('performanceSerializer').normalizeUserPerformanceLessons(response));},reject);});}, /**
   * Get performance of user performance collections
   * @returns {Promise.<[]>}
   */getUserPerformanceCollections:function getUserPerformanceCollections(userId,courseId,unitId,lessonId,classId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('performanceAdapter').getUserPerformanceCollections(userId,courseId,unitId,lessonId,classId).then(function(response){resolve(service.get('performanceSerializer').normalizeUserPerformanceCollections(response));},reject);});}, /**
   * Get performance of user  resource in assessments
   * @returns {Promise.<[]>}
   */getUserPerformanceResourceInAssessment:function getUserPerformanceResourceInAssessment(userId,courseId,unitId,lessonId,collectionId,sessionId,classId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('performanceAdapter').getUserPerformanceResourceInAssessment(userId,courseId,unitId,lessonId,collectionId,sessionId,classId).then(function(response){resolve(service.get('performanceSerializer').normalizeUserPerformanceResourceInAssessment(response));},reject);});}, /**
   * Get performance of user  resource in collection
   * @returns {Promise.<[]>}
   */getUserPerformanceResourceInCollection:function getUserPerformanceResourceInCollection(userId,courseId,unitId,lessonId,collectionId,sessionId,classId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('performanceAdapter').getUserPerformanceResourceInCollection(userId,courseId,unitId,lessonId,collectionId,sessionId,classId).then(function(response){resolve(service.get('performanceSerializer').normalizeUserPerformanceResourceInCollection(response));},reject);});}, /**
   * Get active user competency summary report
   * @returns {Promise.<[]>}
   */getUserCompetencySummary:function getUserCompetencySummary(userId,competencyCode){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('learnersAdapter').getUserCompetencySummary(userId,competencyCode).then(function(response){resolve(service.get('learnersSerializer').normalizeUserCompetencySummary(response));},reject);});}});});