define('gooru-web/helpers/truncate',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports.truncate = truncate; /**
 * Convenience helper to truncate texts
 *
 * {{truncate text='my text' maxLength=10 type='name' suffix=true}}
 *
 * maxLength, used if type is not provided
 * type, optional parameters, indicates the type of truncation, it looks into configuration
 * suffix, default value is true, used to add ... as text suffix
 *
 * @param {[]} params
 * @param {{}} hash, it has helper parameters
 * @returns {*}
 */function truncate(params,hash){var text=hash.text;var maxLength=hash.maxLength;var type=hash.type;var suffix=hash.suffix;return (0,_gooruWebUtilsUtils.truncate)(text,maxLength,type,suffix);}exports['default'] = _ember['default'].Helper.helper(truncate);});