define('gooru-web/components/reports/pull-up/activity/monthly-summary-report',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['activity-report','monthly-summary-report'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/analytics
   */analyticsService:_ember['default'].inject.service('api-sdk/analytics'), /**
   * @requires service:api-sdk/class-activity
   */classActivityService:_ember['default'].inject.service('api-sdk/class-activity'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadClassActivityData();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when click on sort by date
onSortByDate:function onSortByDate(){var component=this;if(component.get('isSortedByDate')){component.set('activitySummaryList',component.get('activitySummaryList').sortBy('date'));}else {component.set('activitySummaryList',component.get('activitySummaryList').sortBy('date').reverse());}component.toggleProperty('isSortedByDate');}, //Action triggered when click on sort by title
onSortByTitle:function onSortByTitle(){var component=this;if(component.get('isSortedByTitle')){component.set('activitySummaryList',component.get('activitySummaryList').sortBy('title').reverse());}else {component.set('activitySummaryList',component.get('activitySummaryList').sortBy('title'));}component.toggleProperty('isSortedByTitle');}, //Action triggered when click on filter by assessment
onFilterByAssessment:function onFilterByAssessment(){var component=this;if(component.get('isAssessmentFilterActive')){component.set('activitySummaryList',component.get('collectionSummaryList'));}else if(component.get('isCollectionFilterActive')){component.set('activitySummaryList',component.get('monthlySummaryList'));}component.toggleProperty('isAssessmentFilterActive');}, //Action triggered when click on filter by collection
onFilterByCollection:function onFilterByCollection(){var component=this;if(component.get('isCollectionFilterActive')){component.set('activitySummaryList',component.get('assessmentSummaryList'));}else if(component.get('isAssessmentFilterActive')){component.set('activitySummaryList',component.get('monthlySummaryList'));}component.toggleProperty('isCollectionFilterActive');}, //Action triggered when carousel through months
onCarouselMonth:function onCarouselMonth(direction){var component=this;var activeIndex=component.get('activeIndex');activeIndex = direction === 'prev'?activeIndex - 1:activeIndex + 1;component.set('activeIndex',activeIndex);component.set('reportContext',component.get('classSummary').objectAt(activeIndex));component.loadClassActivityData();}, //Action triggered when click on the content performance
onOpenContentReport:function onOpenContentReport(activityContent){var component=this;var contentType=activityContent.get('contentType');var params={userId:component.get('userId'),classId:component.get('classId'),collectionId:activityContent.get('content.id'),type:contentType,isStudent:true,collection:activityContent.get('content'),activityDate:moment(activityContent.get('date')).format('YYYY-MM-DD'),studentPerformance:activityContent.get('performanceSummary'),startDate:moment(activityContent.get('start_date')).format('YYYY-MM-DD'),endDate:moment(activityContent.get('end_date')).format('YYYY-MM-DD')};if(contentType === 'assessment-external'){component.set('isShowStudentExternalAssessmentReport',true);}else if(contentType === 'collection-external'){component.set('isShowStudentExternalCollectionReport',true);}else {component.set('showStudentDcaReport',true);}component.set('studentReportContextData',params);}, //Action triggered when click close pullup
onClosePullUp:function onClosePullUp(isCloseAll){var component=this;component.set('showStudentDcaReport',false);component.set('isShowStudentExternalAssessmentReport',false);component.set('isShowStudentExternalCollectionReport',false);if(isCloseAll){component.sendAction('onClosePullUp',isCloseAll);}}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Booean} isShowStudentExternalAssessmentReport
   */isShowStudentExternalAssessmentReport:false, /**
   * @property {Boolean} showStudentDcaReport
   */showStudentDcaReport:false, /**
   * @property {Boolean} isShowStudentExternalCollectionReport
   */isShowStudentExternalCollectionReport:false, /**
   * @property {Number} activeIndex
   */activeIndex:_ember['default'].computed('classSummary',function(){var component=this;var classSummary=component.get('classSummary');var reportContext=component.get('reportContext');return classSummary.indexOf(reportContext);}), /**
   * @property {Boolean} isPrevEnabled
   */isPrevEnabled:_ember['default'].computed.gt('activeIndex',0), /**
   * @property {Boolean} isNextEnabled
   */isNextEnabled:_ember['default'].computed('activeIndex',function(){var component=this;var activeIndex=component.get('activeIndex');var totalMonths=component.get('classSummary.length') - 1;return activeIndex < totalMonths;}), /**
   * @property {Booelan} isAssessmentFilterActive
   */isAssessmentFilterActive:true, /**
   * @property {Boolean} isCollectionFilterActive
   */isCollectionFilterActive:true, /**
   * @property {Boolean} isSortedByDate
   */isSortedByDate:true, /**
   * @property {String} defaultSortCriteria
   */defaultSortCriteria:'date', /**
   * @property {Boolean} isSortedByTitle
   */isSortedByTitle:false, /**
   * @property {Object} reportContext
   */reportContext:null, /**
   * @property {Number} scoreInPercentage
   */scoreInPercentage:_ember['default'].computed.alias('reportContext.scoreInPercentage'), /**
   * @property {Number} timespent
   */timespent:_ember['default'].computed.alias('reportContext.timespent'), /**
   * @property {Number} month
   */month:_ember['default'].computed.alias('reportContext.month'), /**
   * @property {Number} year
   */year:_ember['default'].computed.alias('reportContext.year'), /**
   * @property {UUID} userId
   */userId:_ember['default'].computed.alias('session.userId'), /**
   * @property {Array} assessmentSummaryList
   */assessmentSummaryList:_ember['default'].A([]), /**
   * @property {Array} collectionSummaryList
   */collectionSummaryList:_ember['default'].A([]), /**
   * @property {Array} monthlySummaryList
   */monthlySummaryList:_ember['default'].A([]), /**
   * @property {Array} activitySummaryList
   */activitySummaryList:_ember['default'].computed('monthlySummaryList',function(){var component=this;var monthlySummaryList=component.get('monthlySummaryList');return monthlySummaryList.sortBy(component.get('defaultSortCriteria')).reverse();}), // -------------------------------------------------------------------------
// Methods
/**
   * @function loadClassActivityData
   * Method to load class activity data
   */loadClassActivityData:function loadClassActivityData(){var component=this;component.set('isLoading',true);component.fetchClassActivities().then(function(classActivities){component.parseClassActivitiesMonthlySummary(classActivities);});}, /**
   * @function fetchClassActivities
   * Method to fetch class activities
   */fetchClassActivities:function fetchClassActivities(){var component=this;var classActivityService=component.get('classActivityService');var classId=component.get('classId');var userId=component.get('userId');var forMonth=component.get('month');var forYear=component.get('year');var startDate=forYear + '-' + forMonth + '-01';var endDate=moment(startDate).endOf('month').format('YYYY-MM-DD');return classActivityService.getStudentScheduledActivities(userId,classId,startDate,endDate);}, /**
   * @function parseClassActivitiesMonthlySummary
   * Method to parse class activity monthly summary
   */parseClassActivitiesMonthlySummary:function parseClassActivitiesMonthlySummary(classActivities){var component=this;var collectionSummaryList=_ember['default'].A([]);var assessmentSummaryList=_ember['default'].A([]);var monthlySummaryList=_ember['default'].A([]);classActivities.map(function(activity){var activityContent=activity.get('collection');var activityContentType=activityContent.get('format') || activityContent.get('collectionType');var activityPerformanceData=_ember['default'].Object.create({content:activityContent,contentType:activityContentType,performanceSummary:activityContent.get('performance'),date:activity.get('date'),month:activity.get('forMonth'),year:activity.get('forYear'),id:activity.get('id'),title:activityContent.get('title'),start_date:activity.get('activation_date'),end_date:activity.get('end_date')});if(activityContentType === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT || activityContentType === _gooruWebConfigConfig.CONTENT_TYPES.EXTERNAL_ASSESSMENT){assessmentSummaryList.pushObject(activityPerformanceData);}else {collectionSummaryList.pushObject(activityPerformanceData);}});if(!component.isDestroyed){monthlySummaryList = assessmentSummaryList.concat(collectionSummaryList);component.set('assessmentSummaryList',assessmentSummaryList);component.set('collectionSummaryList',collectionSummaryList);component.set('monthlySummaryList',monthlySummaryList);component.set('isLoading',false);}return monthlySummaryList;}});});