define('gooru-web/components/reports/pull-up/diagnostic-question-report',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','backdrop-pull-ups','pull-up-diagnostic-question-report'], // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user invoke the pull up.
     **/onPullUpClose:function onPullUpClose(closeAll){this.closePullUp(closeAll);},onToggleAnswerSection:function onToggleAnswerSection(element){var component=this;if(!component.$(element).hasClass('slide-up')){component.$(element).find('.user-answer-list').slideUp();component.$(element).addClass('slide-up');}else {component.$(element).find('.user-answer-list').slideDown();component.$(element).removeClass('slide-up');}}}, // -------------------------------------------------------------------------
// Events
/**
   * Function to triggered once when the component element is first rendered.
   */didInsertElement:function didInsertElement(){this.openPullUp();}, // -------------------------------------------------------------------------
// Properties
/**
   * Selected question for this report
   * @type {Object}
   */selectedQuestion:_ember['default'].computed.alias('context.selectedQuestion'), /**
   * Propery to hide the default pullup.
   * @property {showPullUp}
   */showPullUp:false, /**
   * List of class members
   * @type {Object}
   */classMembers:_ember['default'].computed.alias('context.classMembers'),evidenceData:_ember['default'].A([]), /**
   * This will have details about selected question report
   * @return {Object}
   */studentCorrectAns:_ember['default'].computed('context.contents',function(){var component=this;var correctAns=component.get('context.contents').filter(function(content){return content.status === 'correct';});return correctAns;}),studentWrongAns:_ember['default'].computed('context.contents',function(){var component=this;var wrongAns=component.get('context.contents').filter(function(content){return content.status === 'incorrect';});return wrongAns;}),selectedQuestionReport:_ember['default'].computed('context.contents','context.selectedQuestion',function(){var component=this;var studentReportData=component.get('context.contents');var selectedQuestion=component.get('context.selectedQuestion');var reportData=studentReportData.findBy('status','correct') || {};_ember['default'].set(reportData,'question',_ember['default'].Object.create(selectedQuestion));return reportData;}),evidenceDatas:_ember['default'].computed('evidenceData',function(){var component=this;return !!(component.get('evidenceData') && component.get('evidenceData').length);}), //--------------------------------------------------------------------------
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(closeAll){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);if(closeAll){component.sendAction('onClosePullUp');}});}});});