define('gooru-web/components/content/questions/answers/gru-hs-text',['exports','ember','gooru-web/models/content/answer'],function(exports,_ember,_gooruWebModelsContentAnswer){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','questions','answers','gru-hs-text'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Add new answer choice
     */addNewChoice:function addNewChoice(){var newChoice=_gooruWebModelsContentAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(),{text:null,isCorrect:false,type:'text'});this.get('answers').pushObject(newChoice);}, /**
     * Remove existing answer
     */removeChoice:function removeChoice(answer){this.get('answers').removeObject(answer);}, /**
     * Select correct answer
     */setCorrect:function setCorrect(answer){_ember['default'].set(answer,'isCorrect',!_ember['default'].get(answer,'isCorrect'));}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * Hot Spot Text Answers
   */answers:null, /**
   * Is in edit mode
   */editMode:false, /**
   * @property {boolean}
   */disableEditorButtons:_ember['default'].computed.not('showAdvancedEditor') // -------------------------------------------------------------------------
// Method
});});