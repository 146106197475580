define('gooru-web/services/api-sdk/cast-event/cast-event',['exports','ember','gooru-web/mixins/configuration','gooru-web/config/config'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebConfigConfig){ /**
 * Service to forward events to cast and catch API
 *
 * @typedef {Object} CastEventsService
 */exports['default'] = _ember['default'].Service.extend(_gooruWebMixinsConfiguration['default'],{ /**
   * @property {Service} Session service
   */sessionService:_ember['default'].inject.service('api-sdk/session'), /**
   * @property {Service} Tenant service
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), /**Method to check current user tenant allowed to cast events or not
   * @return {Object} Promise
   */isCastEventEnabled:function isCastEventEnabled(){var _this=this;return new _ember['default'].RSVP.Promise(function(resolve){_this.get('tenantService').getActiveTenantSetting().then(function(tenantSettings){if(tenantSettings && tenantSettings.enable_cast_and_catch_api && tenantSettings.enable_cast_and_catch_api === 'on'){resolve(true);}else {resolve(false);}});});}, /**@param {String} eventName
   * @param {Object} data
   * @return {Object} Promise
   */cast:function cast(eventName,data){var service=this;var context={session:{}};if(eventName === _gooruWebConfigConfig.CAST_EVENTS.LOGIN){context.cdns = service.get('sessionService').get('session.cdnUrls');context.tenant = {};context.tenant.id = service.get('sessionService').get('session.tenantId');}var user=service.get('sessionService').get('session.userData');context.session.token = service.get('sessionService').get('session.token-api3');data.basePath = this.get('configuration.endpoint.secureUrl');return new _ember['default'].RSVP.Promise(function(resolve,reject){window.castAPI.eventName(eventName).dataIn(user,context,data).cast().listener(function(response){if(response){return resolve(response);}else {return reject();}});});},castEvent:function castEvent(eventName,data){var service=this;service.isCastEventEnabled().then(function(res){if(res){service.cast(eventName,data).then(function(res){if(res !== 'success'){_ember['default'].Logger.warn('Failed to post event');}});}});}});});