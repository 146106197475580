define('gooru-web/components/charts/gru-bubble-chart',['exports','ember'],function(exports,_ember){ /**
 *Bubble Chart
 *
 * Component responsible for showing the bubble chart.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['charts','gru-bubble-chart'] // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Methods
});});