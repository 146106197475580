define('gooru-web/adapters/content/lesson',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Lesson CRUD operations in the API 3.0
 *
 * @typedef {Object} LessonAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service(),namespace:'/api/nucleus/v1/courses',copierNamespace:'/api/nucleus/v1/copier/courses',itemNamespace:'/api/ds/users/v2/content/portfolio', /**
   * Posts a new lesson
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|String} ID of the newly created lesson
   */createLesson:function createLesson(params){var courseId=params.courseId;var unitId=params.unitId;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params.lesson)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData,textStatus,request){var lessonId=request.getResponseHeader('location');resolve(lessonId);},function(error){reject(error);});});},createLessonPlan:function createLessonPlan(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId + '/lesson-plans';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params.lessonPlan)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(response){resolve(response);},reject);});}, /**
   * Updates an existing lesson
   *
   * @param params - data to send in the request
   * @returns {Ember.Promise|String}
   */updateLesson:function updateLesson(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params.lesson)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(){resolve('');},function(error){reject(error);});});},updateLessonPlan:function updateLessonPlan(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var indexId=params.index;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId + '/lesson-plans/' + indexId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify(params.lessonPlan)};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(response){resolve(response);},reject);});}, /**
   * Get lesson data for the corresponding lesson ID
   *
   * @param params - data to send in the request
   * @returns {Promise|Object}
   */getLessonById:function getLessonById(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:this.defineHeaders()};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},function(error){reject(error);});});}, /**
   * Associates a collection/assesment with a lesson
   *
   * @param params - data to send in the request
   * @returns {Promise}
   */associateAssessmentOrCollectionToLesson:function associateAssessmentOrCollectionToLesson(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var collectionId=params.collectionId;var type=params.type;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId + '/collections';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify({type:type,collection_id:collectionId})};return _ember['default'].$.ajax(url,options);},disassociateAssessmentOrCollectionToLesson:function disassociateAssessmentOrCollectionToLesson(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var collectionId=params.collectionId;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId + '/collections/' + collectionId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:this.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes a lesson by id
   *
   * @param params - data to send in the request
   * @returns {Promise}
   */deleteLesson:function deleteLesson(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);},deleteLessonPlan:function deleteLessonPlan(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var lessonPlanId=params.lessonPlanId;var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId + '/lesson-plans/' + lessonPlanId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Copies a lesson by id
   *
   * @param params - data to send in the request
   * @returns {Promise}
   */copyLesson:function copyLesson(params){var courseId=params.courseId;var unitId=params.unitId;var lessonId=params.lessonId;var adapter=this;var namespace=this.get('copierNamespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({target_course_id:courseId,target_unit_id:unitId})};return _ember['default'].$.ajax(url,options);}, /**
   * Reorder lesson collections
   *
   * @param courseId the id of the course
   * @param unitId the id of the unit to be updated
   * @param lessonId the id of the lesson to be updated
   * @param data
   * @returns {Promise}
   */reorderLesson:function reorderLesson(courseId,unitId,lessonId,data){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + courseId + '/units/' + unitId + '/lessons/' + lessonId + '/order';var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);},itemLesson:function itemLesson(param){var adapter=this;var itemNamespace=this.get('itemNamespace');var url=itemNamespace + '/items/exists';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(param)};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});