define('gooru-web/components/proficiency/learner-proficiency-pull-up',['exports','ember','gooru-web/config/config','gooru-web/utils/utils','gooru-web/utils/taxonomy'],function(exports,_ember,_gooruWebConfigConfig,_gooruWebUtilsUtils,_gooruWebUtilsTaxonomy){exports['default'] = _ember['default'].Component.extend({classNames:['learner-proficiency-pull-up'], // -------------------------------------------------------------------------
// Dependencies
/**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('taxonomy'), /**
   * Competency service dependency injection
   * @type {Object}
   */competencyService:_ember['default'].inject.service('api-sdk/competency'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadData();_ember['default'].$('body').css('overflow','hidden');},willDestroyElement:function willDestroyElement(){_ember['default'].$('body').css('overflow','auto');}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when select a month from chart
     */onSelectMonth:function onSelectMonth(date){var timeLine={month:date.getMonth() + 1,year:date.getFullYear()};this.set('timeLine',timeLine);}, /**
     * Action triggered when select a subject
     */onSelectSubject:function onSelectSubject(subject){var component=this;component.set('activeSubject',subject);}, /**
     * Action triggered at once the baseline is drawn
     */onShownBaseLine:function onShownBaseLine(createdDate){var component=this;component.set('timeSeriesStartDate',createdDate?new Date(createdDate):component.get('courseStartDate'));component.set('isShowTimeSeries',true);}, /**
     * Action triggered when select a competency
     */onSelectCompetency:function onSelectCompetency(competency,domainCompetencyList){var component=this;var userId=component.get('student.id');component.sendAction('onSelectCompetency',competency,userId,domainCompetencyList);},isShowLoaderSet:function isShowLoaderSet(value){this.set('isShowLoader',value);}}, // -------------------------------------------------------------------------
// Methods
/**
   * This method will load the initial set  of data
   */loadData:function loadData(){var component=this;component.fetchCategories().then(function(){var categories=component.get('categories');var categoryCode=component.get('categoryCode');var selectedCategory=categories.findBy('code',categoryCode);if(selectedCategory){component.set('selectedCategory',selectedCategory);component.fetchSubjectsByCategory(selectedCategory);}});}, /**
   * @function fetchCategories
   * Method  fetch list of taxonomy categories
   */fetchCategories:function fetchCategories(){var component=this;return new _ember['default'].RSVP.Promise(function(reslove){component.get('taxonomyService').getCategories().then(function(categories){var category=categories.objectAt(0);component.set('selectedCategory',category);component.set('categories',categories);reslove();});});}, /**
   * @function fetchSubjectsByCategory
   * @param subjectCategory
   * Method to fetch list of subjects using given category level
   */fetchSubjectsByCategory:function fetchSubjectsByCategory(category){var component=this;component.get('taxonomyService').getTaxonomySubjects(category.get('id')).then(function(subjects){var subject=component.getActiveSubject(subjects);component.set('taxonomySubjects',subjects);component.set('activeSubject',subject);});}, /**
   * @function getActiveSubject
   * Method to get active subject by using subject bucket
   */getActiveSubject:function getActiveSubject(subjects){var component=this;var defaultSubject=subjects.findBy('id',_gooruWebConfigConfig.DEFAULT_K12_SUBJECT);var activeSubject=defaultSubject?defaultSubject:subjects.objectAt(0);var subjectBucket=component.get('subjectBucket');subjects.map(function(subject){if(subjectBucket){if(subjectBucket && subjectBucket.split(subject.id).length > 1){activeSubject = subject;}}});return activeSubject;}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {JSON}
   * Property to store currently selected month and year
   */timeLine:_ember['default'].computed(function(){var curDate=new Date();return {month:curDate.getMonth() + 1,year:curDate.getFullYear()};}), /**
   * @property {Array}
   * Property to store list of taxonomy subjects
   */taxonomySubjects:_ember['default'].A([]), /**
   * @property {JSON}
   * Property to store active category
   */selectedCategory:null, /**
   * @property {Object}
   * Property to store active subject
   */activeSubject:null, /**
   * @property {String}
   * Property to store currently selected student's full name
   */studentFullName:_ember['default'].computed('student',function(){var component=this;var firstName=component.get('student.firstName') || '';var lastName=component.get('student.lastName') || '';return lastName + ', ' + firstName;}), /**
   * @property {Date}
   * Property to store course started date or one year before date
   */courseStartDate:_ember['default'].computed('course',function(){var component=this;var course=component.get('course');var courseCreatedDate=new Date();if(course && course.createdDate){courseCreatedDate = new Date(course.createdDate);}else {var curMonth=courseCreatedDate.getMonth();var curYear=courseCreatedDate.getFullYear();var oneYearBeforeFromCurrentDate=courseCreatedDate;courseCreatedDate = new Date(oneYearBeforeFromCurrentDate.setMonth(curMonth - 11));courseCreatedDate = new Date(oneYearBeforeFromCurrentDate.setFullYear(curYear - 1));}return courseCreatedDate;}), /**
   * @property {Boolean} isShowMatrixChart
   */isShowMatrixChart:_ember['default'].computed('taxonomySubjects',function(){var component=this;var course=component.get('course');var isShowMatrixChart=false;if(course.get('id')){var taxonomySubjects=component.get('taxonomySubjects');var subjectBucket=component.get('subjectBucket');var subjectCode=subjectBucket?(0,_gooruWebUtilsUtils.getSubjectIdFromSubjectBucket)(subjectBucket):null;var isSupportedTaxonomySubject=taxonomySubjects.findBy('code',subjectCode);var aggregatedTaxonomy=course.get('aggregatedTaxonomy');isShowMatrixChart = !!(aggregatedTaxonomy && isSupportedTaxonomySubject);}return isShowMatrixChart;}), /**
   * @property {Boolean} isShowTimeSeries
   */isShowTimeSeries:false, /**
   * @property {Date} timeSeriesStartDate
   */timeSeriesStartDate:_ember['default'].computed('class',function(){return this.get('class.startDate');}), /**
   * Parse  category from subject id
   */categoryCode:_ember['default'].computed('course',function(){var course=this.get('course');var subject=this.get('subjectBucket');if(course && course.get('id') && subject){return (0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subject);}})});});