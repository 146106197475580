define('gooru-web/utils/performance-summary',['exports','ember','gooru-web/models/performance/activity-performance-summary','gooru-web/models/performance/collection-performance-summary','gooru-web/utils/math'],function(exports,_ember,_gooruWebModelsPerformanceActivityPerformanceSummary,_gooruWebModelsPerformanceCollectionPerformanceSummary,_gooruWebUtilsMath){exports.aggregateCollectionPerformanceSummaryItems = aggregateCollectionPerformanceSummaryItems;exports.aggregateClassActivityPerformanceSummaryItems = aggregateClassActivityPerformanceSummaryItems;exports.aggregateOfflineClassActivityPerformanceSummaryItems = aggregateOfflineClassActivityPerformanceSummaryItems; /**
 * Aggregates all users collection activity summary items
 * @param {CollectionPerformanceSummary[]} collectionPerformanceSummaryItems
 * @returns {CollectionPerformanceSummary}
 */function aggregateCollectionPerformanceSummaryItems(collectionPerformanceSummaryItems){var timeSpentValues=collectionPerformanceSummaryItems.map(function(item){var timeSpent=item.get('timeSpent');return timeSpent;}).filter(function(timeSpent){return timeSpent !== undefined; // throw away any instances which are not undefined
});var scoreValues=collectionPerformanceSummaryItems.map(function(item){var score=item.get('score');return score;}).filter(function(score){return score !== undefined; // throw away any instances which are not undefined
});var attempts=collectionPerformanceSummaryItems.map(function(item){var attempts=item.get('attempts');return attempts;}).filter(function(attempts){return attempts !== undefined; // throw away any instances which are not undefined
});var collectionId=collectionPerformanceSummaryItems[0].get('collectionId');var lastIndex=collectionPerformanceSummaryItems.length - 1;var sessionId=collectionPerformanceSummaryItems[lastIndex].get('sessionId');var isGraded=collectionPerformanceSummaryItems[lastIndex].get('isGraded');return _gooruWebModelsPerformanceCollectionPerformanceSummary['default'].create({collectionId:collectionId,sessionId:sessionId,timeSpent:timeSpentValues.length > 0?(0,_gooruWebUtilsMath.sumAll)(timeSpentValues):null,score:scoreValues.length > 0?(0,_gooruWebUtilsMath.average)(scoreValues):null,attempts:attempts.length > 0?(0,_gooruWebUtilsMath.sumAll)(attempts):null,isGraded:isGraded,averageTimespent:timeSpentValues.length > 0?(0,_gooruWebUtilsMath.average)(timeSpentValues):null});} /**
 * Aggregates all users class activity summary items
 * @param {Ember.A|ActivityPerformanceSummary[]} activityPerformanceSummaryItems
 * @returns {ActivityPerformanceSummary[]}
 */function aggregateClassActivityPerformanceSummaryItems(activityPerformanceSummaryItems){var aggregatedClassActivities=_ember['default'].A([]);var dates=activityPerformanceSummaryItems.map(function(a){return a.get('date').getTime();}).uniq();dates.forEach(function(date){ //gets all user activities within the same date
var activitiesPerDate=activityPerformanceSummaryItems.filter(function(a){return a.get('date').getTime() === date;});var dateCollectionPerformanceSummaryItems=activitiesPerDate.mapBy('collectionPerformanceSummary');var collectionIds=dateCollectionPerformanceSummaryItems.mapBy('collectionId').uniq();collectionIds.forEach(function(collectionId){ //gets all user performance items for the same collection
var collectionPerformanceSummaryItems=dateCollectionPerformanceSummaryItems.filterBy('collectionId',collectionId);var aggregatedActivity=_gooruWebModelsPerformanceActivityPerformanceSummary['default'].create({date:new Date(date),activation_date:moment(date).format('YYYY-MM-DD'),collectionPerformanceSummary:aggregateCollectionPerformanceSummaryItems(collectionPerformanceSummaryItems)});aggregatedClassActivities.pushObject(aggregatedActivity);});});return aggregatedClassActivities;} /**
 * Aggregates all users of offline class activity summary items
 * @param {Ember.A|ActivityPerformanceSummary[]} activityPerformanceSummaryItems
 * @returns {ActivityPerformanceSummary[]}
 */function aggregateOfflineClassActivityPerformanceSummaryItems(activityPerformanceSummaryItems){var aggregatedClassActivities=_ember['default'].A([]);var dcaContentIds=activityPerformanceSummaryItems.mapBy('dcaContentId').uniq();dcaContentIds.forEach(function(dcaContentId){var activities=activityPerformanceSummaryItems.filterBy('dcaContentId',dcaContentId);var dcaContentCollectionPerformanceSummaryItems=activities.mapBy('collectionPerformanceSummary');var collectionIds=dcaContentCollectionPerformanceSummaryItems.mapBy('collectionId').uniq();collectionIds.forEach(function(collectionId){ //gets all user performance items for the same collection
var collectionPerformanceSummaryItems=dcaContentCollectionPerformanceSummaryItems.filterBy('collectionId',collectionId);var aggregatedActivity=_gooruWebModelsPerformanceActivityPerformanceSummary['default'].create({dcaContentId:dcaContentId,collectionPerformanceSummary:aggregateCollectionPerformanceSummaryItems(collectionPerformanceSummaryItems)});aggregatedClassActivities.pushObject(aggregatedActivity);});});return aggregatedClassActivities;}});