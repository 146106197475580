define('gooru-web/components/content/rubric/gru-preview-url',['exports','ember','gooru-web/mixins/content/protocol'],function(exports,_ember,_gooruWebMixinsContentProtocol){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsContentProtocol['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['content','rubric','gru-preview-url'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {Number} the calculated resource content height
   */calculatedResourceContentHeight:null, /**
   * @property {string} bind the height css style for the component
   */resourceHeight:_ember['default'].computed('calculatedResourceContentHeight',function(){var height=this.get('calculatedResourceContentHeight');var heightString=height > 0?height + 'px':'100%';return _ember['default'].String.htmlSafe('height: ' + heightString);}), /**
   * @property {string} Resource URL
   */url:_ember['default'].computed.alias('resource.url'), /**
   * @property {boolean} isLinkOut check whether it can be render inside iframe or not
   */isLinkOut:_ember['default'].computed('resource',function(){var currentProtocol=this.get('currentProtocol');var resourceProtocol=this.get('resourceProtocol');var isUploaded=this.get('isUploaded');if(currentProtocol === 'https:' && resourceProtocol === 'http:' && !isUploaded && this.get('resource').format !== 'html'){return true;}return false;})});});