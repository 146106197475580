define('gooru-web/models/user/user',['exports','ember','ember-data','ember-cp-validations','gooru-web/config/config'],function(exports,_ember,_emberData,_emberCpValidations,_gooruWebConfigConfig){var UserValidations=(0,_emberCpValidations.buildValidations)({firstName:[(0,_emberCpValidations.validator)('presence',true)],lastName:[(0,_emberCpValidations.validator)('presence',true)],username:{description:'Username',validators:[(0,_emberCpValidations.validator)('presence',true),(0,_emberCpValidations.validator)('length',{min:4,max:16}),(0,_emberCpValidations.validator)('format',{regex:/^\w+$/,message:'{description} cannot use special characters'}),(0,_emberCpValidations.validator)('username')]},password:[(0,_emberCpValidations.validator)('presence',true),(0,_emberCpValidations.validator)('length',{min:5,max:14}),(0,_emberCpValidations.validator)('format',{regex:/^\w+$/,message:'{description} cannot use special characters'})],rePassword:[(0,_emberCpValidations.validator)('presence',true),(0,_emberCpValidations.validator)('length',{min:5,max:14}),(0,_emberCpValidations.validator)('format',{regex:/^\w+$/,message:'{description} cannot use special characters'}),(0,_emberCpValidations.validator)(function(value,options,model /* ,attribute*/){return value !== model.get('password')?'Passwords don\'t match':true;})],email:[(0,_emberCpValidations.validator)('presence',true),(0,_emberCpValidations.validator)('format',{type:'email',message:'Not a valid email'}),(0,_emberCpValidations.validator)('email')],dateOfBirth:[(0,_emberCpValidations.validator)('presence',true)],role:[(0,_emberCpValidations.validator)('presence',true)]}); /**
 * Model to represent the Users obtained from the end-point
 */exports['default'] = _emberData['default'].Model.extend(UserValidations,{ // Values used to capture and to read data to/from SignUp end-point
/**
   * @property {string} firstName
   */firstName:_emberData['default'].attr('string'), /**
   * @property {string} lastName
   */lastName:_emberData['default'].attr('string'), /**
   * @property {string} username
   */username:_emberData['default'].attr('string'), /**
   * @property {string} email
   */email:_emberData['default'].attr('string'), /**
   * @property {string} organization
   */organization:_emberData['default'].attr('string'), /**
   * @property {string} school
   */school:_emberData['default'].attr('string'), /**
   * @property {string} country
   */country:_emberData['default'].attr('string'), // Values only used to read data from SignUp end-point
/**
   * @property {string} gooruId
   */gooruUId:_emberData['default'].attr('string'), /**
   * @property {string} usernameDispaly
   */usernameDisplay:_emberData['default'].attr('string'), /**
   * @property {string} avatarUrl
   */avatarUrl:_emberData['default'].attr('string',{defaultValue:_gooruWebConfigConfig.DEFAULT_IMAGES.USER_PROFILE}), /**
   * @property {string} userRoleSetString
   */userRoleSetString:_emberData['default'].attr('string'), /**
   * @property {string} accountCreatedType
   */accountCreatedType:_emberData['default'].attr('string'), /**
   * @property {number} accountTypeId
   */accountTypeId:_emberData['default'].attr('number'), /**
   * @property {number} active
   */active:_emberData['default'].attr('number'), /**
   * @property {number} confirmStatus
   */confirmStatus:_emberData['default'].attr('number'), /**
   * @property {string} createdOn
   */createdOn:_emberData['default'].attr('string'), /**
   * @property {string} partyUid
   */partyUid:_emberData['default'].attr('string'), /**
   * @property {number} viewFlag
   */viewFlag:_emberData['default'].attr('number'), // Values only used to capture data to be sent to SignUp end-point
/**
   * @property {string} gender
   */gender:_emberData['default'].attr('string'), /**
   * @property {string} dateOfBirth
   */dateOfBirth:_emberData['default'].attr('string'), /**
   * @property {string} password
   */password:_emberData['default'].attr('string'), /**
   * @property {string} role
   */role:_emberData['default'].attr('string'), /**
   * @property {Meta} metadata
   */metadata:_emberData['default'].belongsTo('meta',{async:true}), /**
   * @property {string} districtName
   */districtName:_emberData['default'].attr('string'), /**
   * @property {number}totalFollowers
   */totalFollowers:_emberData['default'].attr('number'), /**
   * @property {number}totalFollowing
   */totalFollowing:_emberData['default'].attr('number'),fullName:_ember['default'].computed('firstName','lastName',function(){return this.get('lastName') + ', ' + this.get('firstName');}),fullNameInformal:_ember['default'].computed('firstName','lastName',function(){return this.get('firstName') + ' ' + this.get('lastName');}), /**
   * @property {string} internal code used for anonymous reports
   */code:_ember['default'].computed(function(){ //return parseInt(Math.random() * 10000); //TODO this should come from BE, it is not defined yet
return this.get('username');})});}); // constants