define('gooru-web/components/reports/pull-up/dca-student-external-assessment-report',['exports','ember','gooru-web/utils/utils','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/models/result/context','gooru-web/config/config'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebModelsResultContext,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({classNames:['reports','backdrop-pull-ups','dca-student-external-assessment-report'], /**
   * @requires {Ember.Service} session management
   */session:_ember['default'].inject.service('session'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires {AssessmentService} Service to retrieve an assessment
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), // -------------------------------------------------------------------------
// Properties
/**
   * Indicates the status of the spinner
   * @property {Boolean}
   */isLoading:false,showPullUp:false, /**
   * calculate  the class average by student performance score as a width
   * @property {string}
   */studentAverage:_ember['default'].computed('performanceScore',function(){var component=this;var score=component.get('performanceScore');return _ember['default'].String.htmlSafe('width: ' + score + '%;');}), /**
   * @property {String} barColor
   * Computed property to know the color of the small bar
   */performanceColorStyle:_ember['default'].computed('performanceScore',function(){var component=this;var score=component.get('performanceScore');component.set('performanceColor',(0,_gooruWebUtilsUtils.getBarGradeColor)(score));return _ember['default'].String.htmlSafe('background-color: ' + (0,_gooruWebUtilsUtils.getBarGradeColor)(score) + ';');}),externalAssessment:_ember['default'].computed('reportData',function(){var component=this;var reportData=component.get('reportData');return reportData.collection;}), /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('externalAssessmentContent.standards.[]',function(){var component=this;var standards=component.get('externalAssessmentContent.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}), /**
   * @property {Number} performanceScore
   */performanceScore:_ember['default'].computed('reportData',function(){var component=this;var reportData=component.get('reportData');return reportData.studentPerformance?reportData.studentPerformance.score:reportData.collection.get('performance.score');}),maxScore:_ember['default'].computed('reportData',function(){return this.get('performanceScore') >= _gooruWebConfigConfig.SCORES.VERY_GOOD;}), // -------------------------------------------------------------------------
// Events
didRender:function didRender(){this.handleAppContainerScroll();},didDestroyElement:function didDestroyElement(){this.handleAppContainerScroll();}, /**
   * Function to triggered once when the component element is first rendered.
   */didInsertElement:function didInsertElement(){this.openPullUp();this.loadExternalAssessmentReportData();},actions:{closeAll:function closeAll(){this.sendAction('onClosePullUp',true);},onPullUpClose:function onPullUpClose(){this.closePullUp();},onClosePullUp:function onClosePullUp(){var component=this;component.closePullUp(true);}}, //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(closeAll){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);if(closeAll){component.sendAction('onClosePullUp',true);}});},handleAppContainerScroll:function handleAppContainerScroll(){var activePullUpCount=_ember['default'].$(document.body).find('.backdrop-pull-ups').length;if(activePullUpCount > 0){_ember['default'].$(document.body).addClass('no-vertical-scroll');}else if(activePullUpCount === 0){_ember['default'].$(document.body).removeClass('no-vertical-scroll');}},loadExternalAssessmentReportData:function loadExternalAssessmentReportData(){var component=this;var context=component.get('reportData');var profilePromise=new _ember['default'].RSVP.resolve(component.get('profileService').readUserProfile(context.userId));var assessmentContentPromise=new _ember['default'].RSVP.resolve(component.get('assessmentService').readExternalAssessment(context.collectionId));return _ember['default'].RSVP.hash({profile:profilePromise,externalAssessment:assessmentContentPromise}).then(function(hash){component.set('profile',hash.profile);component.set('externalAssessmentContent',hash.externalAssessment);});}, /**
   * Get the player context
   * @param params
   * @returns {Context}
   */getContext:function getContext(params){var userId=params.userId;var collectionId=params.collectionId;return _gooruWebModelsResultContext['default'].create({collectionType:params.type,userId:userId,collectionId:collectionId,classId:params.classId});}});});