define('gooru-web/components/gru-breadcrumb',['exports','ember'],function(exports,_ember){ /**
 * Breadcrumb component
 *
 * Component responsible for controlling the logic and appearance of a breadcrumb element
 * This component can be reused across the entire application and can be styled as needed,
 * functionality should remain the same
 *
 * @module
 * @augments Ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-breadcrumb'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * selectItem selects breadcrumb item
     */selectItem:function selectItem(item){this.sendAction('onSelectedItem',item);}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * The breadcrumb items
   * @property {{ value: string, label: string }[]} items
   */items:_ember['default'].A(), /**
   * @property {String|Function} onItemSelected - event handler for when an item is selected
   */onItemSelected:null, /**
   * Determine the column class name based on the length of items
   */itemClassName:_ember['default'].computed('items.[]',function(){var length=this.get('items.length');return 'breadcrumb-col-' + length;}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});