define('gooru-web/components/taxonomy/gru-subject-course-picker',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig){ /**
 * subject course picker component
 *
 * Component responsible for displaying subject courses
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['taxonomy','gru-subject-course-picker'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this.$().on('click','ul.courses li',function(e){e.stopPropagation();});}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Select a subject course
     */selectSubjectCourse:function selectSubjectCourse(course){var component=this;var subject=component.get('selectedSubject');var taxonomyTagData=_gooruWebModelsTaxonomyTaxonomyTagData['default'].create({id:course.get('id'),code:course.get('code'),title:course.get('title'),parentTitle:subject.get('subjectTitle'),frameworkCode:subject.get('frameworkId'),taxonomyLevel:_gooruWebConfigConfig.TAXONOMY_LEVELS.COURSE});component.addRemoveTaxonomyTagData(taxonomyTagData);if(component.get('onTaxonomySelected')){component.sendAction('onTaxonomySelected',this.get('selectedTaxonomy'));}}, /**
     * Remove a specific tag
     */removeTag:function removeTag(tag){var component=this;component.removeTaxonomyTagData(tag.get('data.id'));if(component.get('onTaxonomySelected')){component.sendAction('onTaxonomySelected',this.get('selectedTaxonomy'));}}}, //
// Methods
/**
   * Removes a taxonomy tag data from taxonomy
   * @param id
   */removeTaxonomyTagData:function removeTaxonomyTagData(taxonomyId){var taxonomy=this.get('selectedTaxonomy');var taxonomyTagData=taxonomy.findBy('id',taxonomyId);if(taxonomyTagData){taxonomy.removeObject(taxonomyTagData);}}, /**
   * Adds or removes a taxonomy tag data
   * @param {TaxonomyTagData} taxonomyTagData
   */addRemoveTaxonomyTagData:function addRemoveTaxonomyTagData(taxonomyTagData){var component=this;var taxonomy=component.get('selectedTaxonomy');var taxonomyId=taxonomyTagData.get('id');var existingTaxonomyTagData=taxonomy.findBy('id',taxonomyId);if(existingTaxonomyTagData){taxonomy.removeObject(existingTaxonomyTagData);}else {taxonomy.pushObject(taxonomyTagData);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {string[]} taxonomy ids
   */selectedTaxonomyIds:_ember['default'].computed('selectedTaxonomy.[]',function(){return this.get('selectedTaxonomy').map(function(tagData){return tagData.get('id');});}), /**
   * the subject selected
   * @property {TaxonomyRoot}
   */selectedSubject:null, /**
   * the subject courses to present
   * @property {[]}
   */subjectCourses:null, /**
   * @property {TaxonomyTagData[]}
   */selectedTaxonomy:_ember['default'].A(), /**
   * when a taxonomy is selected
   * @property {string}
   */onTaxonomySelected:null // -------------------------------------------------------------------------
// Observers
});});