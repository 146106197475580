define('gooru-web/components/cards/gru-student-class-join-card',['exports','ember','ember-cp-validations','gooru-web/config/parse-event'],function(exports,_ember,_emberCpValidations,_gooruWebConfigParseEvent){var Validations=(0,_emberCpValidations.buildValidations)({code:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.join-class-code'}),(0,_emberCpValidations.validator)('dependent',{on:['allowedCode'],message:'{{description}}',descriptionKey:'content.classes.join.join-not-allowed'}),(0,_emberCpValidations.validator)('dependent',{on:['validCode'],message:'{{description}}',descriptionKey:'content.classes.join.invalid-code'}),(0,_emberCpValidations.validator)('dependent',{on:['notMember'],message:'{{description}}',descriptionKey:'content.classes.join.already-member'})]},validCode:(0,_emberCpValidations.validator)('presence',true),allowedCode:(0,_emberCpValidations.validator)('presence',true),notMember:(0,_emberCpValidations.validator)('presence',true)});exports['default'] = _ember['default'].Component.extend(Validations,{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-student-class-join-card'], /**
   * @property {Service} session service
   */session:_ember['default'].inject.service('session'), /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), // -------------------------------------------------------------------------
// Actions
actions:{joinClass:function joinClass(){var component=this;component.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){component.set('isLoading',true);component.sendAction('onJoinClass',component.get('code'));var context={classCode:component.get('code')}; // Trigger parse event
component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.JOIN_CLASSROOM,context);}component.set('didValidate',true);});},onCodeFocusOut:function onCodeFocusOut(){this.clearValidations();},onCodeChange:function onCodeChange(hasContent){this.set('allowedButton',hasContent);},onCodeTyping:function onCodeTyping(){this.clearValidations();}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$().on('keyup','.modal-body',function(e){var keyCode=event.keyCode?event.keyCode:event.which;if(keyCode === 13){$(e.target).blur().focus();component.$('.join-class-btn').trigger('click');}});}, // -------------------------------------------------------------------------
// Methods
/**
   * Clear validation messages
   */clearValidations:function clearValidations(){var component=this;component.set('allowedCode',true);component.set('validCode',true);component.set('notMember',true);}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {string}
   */code:null, /**
   * @property {string}
   */onJoinClass:null, /**
   * Indicates if the code is valid, false when the class is not found
   * @property {boolean}
   */validCode:true, /**
   * Indicates if the code is allowed, false if the user can't join that class
   * @property {boolean}
   */allowedCode:true, /**
   * Indicates if user is not a member of this class
   * @property {boolean}
   */notMember:true, /**
   * Indicate if it's waiting for join class callback
   */isLoading:false, /**
   * Indicates if the submit button is allowed, false if the user don't type anything
   * @property {boolean}
   */allowedButton:false, /**
   * Checking is demo account
   */isGuestAccount:_ember['default'].computed.alias('session.isGuest')});});