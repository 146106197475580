define('gooru-web/components/charts/score-fill-gauge-chart',['exports','ember','d3','gooru-web/utils/utils'],function(exports,_ember,_d3,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['charts','score-fill-gauge-chart'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadScoreFillGauge();},observeScoreChanges:_ember['default'].observer('scoreInPercentage','timeSpent',function(){var component=this;component.loadScoreFillGauge();}), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Number} scoreInPercentage
   */scoreInPercentage:null, /**
   * @property {Number} timeSpent
   */timeSpent:null, /**
   * @property {Number} chartHeight
   */chartHeight:240, /**
   * @property {Number} chartWidth
   */chartWidth:'100%', /**
   * @property {Object} chartProperties
   */chartProperties:_ember['default'].computed(function(){return _ember['default'].Object.create({minValue:0, // The gauge minimum value.
maxValue:100, // The gauge maximum value.
waveHeight:0.01, // The wave height as a percentage of the radius of the wave circle.
waveCount:15, // The number of full waves per width of the wave circle.
waveRiseTime:1000, // The amount of time in milliseconds for the wave to rise from 0 to it's final height.
waveAnimateTime:1000, // The amount of time in milliseconds for a full wave to enter the wave circle.
waveOffset:0.25 // The amount to initially offset the wave. 0 = no offset. 1 = offset of one full wave.
});}), /**
   * @property {Number} timeSpentPos
   */timeSpentPos:200, /**
   * @property {Number} scorePos
   */scorePos:120, /**
   * @property {Number} placeholderPos
   */placeholderPos:-1, /**
   * @property {String} placeholderText
   */placeholderText:null, // -------------------------------------------------------------------------
// Methods
/**
   * @function loadScoreFillGauge
   * Method to load score fill gauge chart
   */loadScoreFillGauge:function loadScoreFillGauge(){var component=this;component.clearChart();var scoreInPercentage=component.get('scoreInPercentage');var timeSpent=component.get('timeSpent');var chartProperties=component.get('chartProperties');var elementId='#' + component.elementId;var fillGaugeElement=_d3['default'].select(elementId + ' svg');var fillGaugeElementWidth=parseInt(fillGaugeElement.style('width'));var fillPercent=Math.max(chartProperties.minValue,Math.min(chartProperties.maxValue,scoreInPercentage)) / chartProperties.maxValue;var waveHeightScale=_d3['default'].scale.linear().range([0,chartProperties.waveHeight,0]).domain([0,50,100]);var waveHeight=fillGaugeElementWidth * waveHeightScale(fillPercent * 100);var waveLength=fillGaugeElementWidth * 2 / chartProperties.waveCount;var waveClipCount=1 + chartProperties.waveCount;var waveClipWidth=waveLength * waveClipCount;var chartHeight=component.get('chartHeight');var chartWidth=component.get('chartWidth'); // Data for building the clip wave area.
var data=[];for(var i=0;i <= 40 * waveClipCount;i++) {data.push({x:i / (40 * waveClipCount),y:i / 40});} // Scales for controlling the size of the clipping path.
var waveScaleX=_d3['default'].scale.linear().range([0,waveClipWidth]).domain([0,1]);var waveScaleY=_d3['default'].scale.linear().range([0,waveHeight]).domain([0,1]); // Scales for controlling the position of the clipping path.
var waveRiseScale=_d3['default'].scale.linear() // The clipping area size is the height of the fill circle + the wave height, so we position the clip wave
// such that the it will won't overlap the fill circle at all when at 0%, and will totally cover the fill
// circle at 100%.
.range([chartHeight,0]).domain([0,1]);var waveAnimateScale=_d3['default'].scale.linear().range([0,waveClipWidth - fillGaugeElementWidth * 2]) // Push the clip area one full wave then snap back.
.domain([0,1]); // Center the gauge within the parent SVG.
var gaugeGroup=fillGaugeElement.append('g').attr('transform','translate(0, 0)'); // The clipping wave area.
var clipArea=_d3['default'].svg.area().x(function(d){return waveScaleX(d.x);}).y0(function(d){return waveScaleY(Math.sin(Math.PI * 2 * chartProperties.waveOffset * -1 + Math.PI * 2 * (1 - chartProperties.waveCount) + d.y * 2 * Math.PI));}).y1(function(){return chartHeight;});var waveGroup=gaugeGroup.append('defs').append('clipPath').attr('id','clipWave' + elementId);var wave=waveGroup.append('path').datum(data).attr('d',clipArea); // The inner circle with the clipping wave attached.
var fillCircleGroup=gaugeGroup.append('g').attr('clip-path','url(#clipWave' + elementId + ')');fillCircleGroup.append('rect').attr('width',chartWidth).attr('height',chartHeight).attr('x',0).attr('y',0).attr('class','fill-range-' + (0,_gooruWebUtilsUtils.getGradeRange)(scoreInPercentage));if(scoreInPercentage !== null && scoreInPercentage !== undefined){fillGaugeElement.append('text').attr('transform','translate(' + fillGaugeElementWidth / 2 + ')').attr('y',component.get('scorePos')).attr('class','score-percentage').append('tspan').attr('text-anchor','middle').attr('x',0).text(scoreInPercentage + '%');}if(!scoreInPercentage && timeSpent){fillGaugeElement.append('text').attr('transform','translate(' + fillGaugeElementWidth / 2 + ')').attr('y',component.get('scorePos')).attr('class','score-percentage').append('tspan').attr('text-anchor','middle').attr('x',0).text('' + (timeSpent?(0,_gooruWebUtilsUtils.formatTime)(timeSpent):'--'));}if(timeSpent !== null && timeSpent !== undefined){fillGaugeElement.append('text').attr('y',component.get('timeSpentPos')).attr('transform','translate(' + fillGaugeElementWidth / 2 + ')').attr('class','total-timespent').append('tspan').attr('text-anchor','middle').attr('x',0).text('' + (timeSpent?(0,_gooruWebUtilsUtils.formatTime)(timeSpent):'--'));}if(component.get('placeholderText')){fillGaugeElement.append('text').attr('y',component.get('placeholderPos')).attr('transform','translate(' + fillGaugeElementWidth / 2 + ')').attr('class','placeholder-text').append('tspan').attr('text-anchor','middle').attr('x',0).text(component.get('placeholderText'));}var waveGroupXPosition=fillGaugeElementWidth * 2 - waveClipWidth; // Make the wave rise. wave and waveGroup are separate so that horizontal and vertical movement can be controlled independently.
waveGroup.attr('transform','translate(' + waveGroupXPosition + ',' + waveRiseScale(0) + ')').transition().duration(chartProperties.waveRiseTime).attr('transform','translate(' + waveGroupXPosition + ',' + waveRiseScale(fillPercent) + ')').each('start',function(){wave.attr('transform','translate(1,0)');}); // This transform is necessary to get the clip wave positioned correctly when waveRise=true and waveAnimate=false. The wave will not position correctly without this, but it's not clear why this is actually necessary.
/**
     * @function animateWave
     * Method to animate wave
     */function animateWave(){wave.transition().duration(chartProperties.waveAnimateTime).ease('linear').attr('transform','translate(' + waveAnimateScale(1) + ',0)').each('end',function(){wave.attr('transform','translate(' + waveAnimateScale(0) + ',0)');animateWave(chartProperties.waveAnimateTime);});} //Animate wave by default
animateWave();},clearChart:function clearChart(){var component=this;component.$('svg').empty();}});});