define('gooru-web/services/api-sdk/learning-tools',['exports','ember','gooru-web/adapters/learning_tools','gooru-web/serializers/learning-tools'],function(exports,_ember,_gooruWebAdaptersLearning_tools,_gooruWebSerializersLearningTools){ /**
 * @typedef {Object} LearningToolsService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {LearningToolAdapter} LearningToolAdapter
   */LearningToolAdapter:null,learningToolSerializer:null,init:function init(){this._super.apply(this,arguments);this.set('LearningToolsAdapter',_gooruWebAdaptersLearning_tools['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('learningToolSerializer',_gooruWebSerializersLearningTools['default'].create(_ember['default'].getOwner(this).ownerInjection()));},getLearningToolInformation:function getLearningToolInformation(toolId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('LearningToolsAdapter').getLearningToolInformation(toolId).then(function(responseData){resolve(responseData);},reject);});},fetchLearningTool:function fetchLearningTool(){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('LearningToolsAdapter').fetchLearningTool().then(function(responseData){resolve(service.get('learningToolSerializer').normalizeLearningTool(responseData));},reject);});}, /**
   * Search for product
   *
   * @param term the term to search
   * @param params
   * @returns {Promise}
   */fetchProductList:function fetchProductList(term,params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('LearningToolsAdapter').fetchProductList(term,params).then(function(response){resolve(service.get('learningToolSerializer').normalizeProductList(response));},reject);});}, /**
   * Get product information based on productId
   *
   * @param Id
   * @returns {Promise}
   */fetchProductById:function fetchProductById(productId,type){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('LearningToolsAdapter').fetchProductById(productId,type).then(function(response){resolve(service.get('learningToolSerializer').normalizeProduct(response));},reject);});}, /**
   * Get product ids based on type
   *
   * @returns {Promise}
   */getProductIds:function getProductIds(type){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('LearningToolsAdapter').getProductIds(type).then(function(response){resolve(response.subscribedContentIds);},reject);});}});});