define('gooru-web/models/map/map-location',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){ /**
 * MapLocation model
 * This model represent a location in the course map, a location has a context and could have suggestions
 *
 * @typedef {Object} MapNavigation
 */var MapLocationModel=_ember['default'].Object.extend({ /**
   * @property {MapContext} context
   */context:null, /**
   * @property {MapSuggestion[]} suggestions
   */suggestions:null, /**
   * @property {Boolean} hasContent
   */hasContent:true, /**
   * @property {boolean}
   */hasSuggestions:_ember['default'].computed.bool('suggestions.length'), /**
   * @property {boolean}
   */hasPreTestSuggestions:_ember['default'].computed('suggestions.[]',function(){return this.get('suggestions').filterBy('isPreTest').get('length');}), /**
   * @property {boolean}
   */hasPostTestSuggestions:_ember['default'].computed('suggestions.[]',function(){return this.get('suggestions').filterBy('isPostTest').get('length');}), /**
   * @property {boolean}
   */hasBackFillSuggestions:_ember['default'].computed('suggestions.[]',function(){return this.get('suggestions').filterBy('isBackFill').get('length');}), /**
   * @property {boolean}
   */hasBenchmarkSuggestions:_ember['default'].computed('suggestions.[]',function(){return this.get('suggestions').filterBy('isBenchmark').get('length');}), /**
   * @property {boolean}
   */hasResourceSuggestions:_ember['default'].computed('suggestions.[]',function(){return this.get('suggestions').filterBy('isResource').get('length');}), /**
   * Returns the post test suggestion for this location
   * @property {MapSuggestion}
   */postTestSuggestion:_ember['default'].computed('hasPostTestSuggestions',function(){return this.get('hasPostTestSuggestions')?this.getSuggestion(_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.POST_TEST):undefined;}), /**
   * Returns the pre test suggestion for this location
   * @property {MapSuggestion}
   */preTestSuggestion:_ember['default'].computed('hasPreTestSuggestions',function(){return this.get('hasPreTestSuggestions')?this.getSuggestion(_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.PRE_TEST):undefined;}), /**
   * Returns the backfill test suggestion for this location
   * @property {MapSuggestion}
   */backFillSuggestion:_ember['default'].computed('hasBackFillSuggestions',function(){return this.get('hasBackFillSuggestions')?this.getSuggestion(_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.BACKFILL):undefined;}), /**
   * Returns the benchmark suggestion for this location
   * @property {MapSuggestion}
   */benchmarkSuggestion:_ember['default'].computed('hasBenchmarkSuggestions',function(){return this.get('hasBenchmarkSuggestions')?this.getSuggestion(_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.BENCHMARK):undefined;}), /**
   * Returns the resource suggestion for this location
   * @property {MapSuggestion}
   */resourceSuggestion:_ember['default'].computed('hasResourceSuggestions',function(){return this.get('hasResourceSuggestions')?this.getSuggestion(_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.RESOURCE):undefined;}), /**
   * Returns the suggestion by sub type
   * @param {string} subType @see ASSESSMENT_SUB_TYPES
   */getSuggestion:function getSuggestion(subType){return this.get('suggestions').findBy('subType',subType);}, /**
   * Returns the suggestion by sub type
   * @param {string} subType @see ASSESSMENT_SUB_TYPES
   */getSystemSuggestion:function getSystemSuggestion(subType){return this.get('suggestions').findBy('subType',subType);}, /**
   * Returns the Signaure Collection suggestion for this location
   * @property {MapSuggestion}
   */signatureCollectionSuggestions:_ember['default'].computed('hasSignatureCollectionSuggestions',function(){return this.get('hasSignatureCollectionSuggestions')?this.getSystemSuggestion(_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.SIGNATURE_COLLECTION):undefined;}), /**
   * @property {boolean}
   */hasSignatureCollectionSuggestions:_ember['default'].computed('suggestions.[]',function(){return this.get('suggestions').filterBy('subType','signature_collection').get('length');}), /**
   * Returns the signature assessment suggestion for this location
   * @property {MapSuggestion}
   */signatureAssessmentSuggestions:_ember['default'].computed('hasSignatureAssessmentSuggestions',function(){return this.get('hasSignatureAssessmentSuggestions')?this.getSystemSuggestion(_gooruWebConfigConfig.ASSESSMENT_SUB_TYPES.SIGNATURE_ASSESSMENT):undefined;}), /**
   * @property {boolean}
   */hasSignatureAssessmentSuggestions:_ember['default'].computed('suggestions.[]',function(){return this.get('suggestions').filterBy('subType','signature_assessment').get('length');})});exports['default'] = MapLocationModel;});