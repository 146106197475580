define('gooru-web/components/reports/pull-up/competency-report',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['competency-report-pull-up'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.openPullUp();}, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when close pull up
     */onClosePullUp:function onClosePullUp(closeAll){var component=this;component.closePullUp(closeAll);}}, // -------------------------------------------------------------------------
// Properties
/**
   * Propery to hide the default pullup.
   * @property {showPullUp}
   */showPullUp:false, /**
   * @property {title}
   * Property to store pull up title
   */title:'', /**
   * @property {type}
   * Property to store pull up type
   */type:'', /**
   * @property {String} competencyStatus
   */competencyStatus:_ember['default'].computed('competency',function(){var component=this;var competency=component.get('competency');return _gooruWebConfigConfig.COMPETENCY_STATUS[competency.status];}), /**
   * @property {Boolean} isBadgedCompetency
   */isBadgedCompetency:_ember['default'].computed('competency',function(){var component=this;var competency=component.get('competency');return competency.status === 5;}), // -------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},init:function init(){var component=this;component._super.apply(component,arguments);var title=component.get('title');if(title && !title.string){component.set('title',title.replace(/\./g,' | '));}},closePullUp:function closePullUp(closeAll){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);if(closeAll){component.sendAction('onClosePullUp');}});}});});