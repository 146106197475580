define('gooru-web/services/api-sdk/external-assessment',['exports','ember','gooru-web/serializers/content/external-assessment','gooru-web/adapters/content/external-assessment'],function(exports,_ember,_gooruWebSerializersContentExternalAssessment,_gooruWebAdaptersContentExternalAssessment){ /**
 * @typedef {Object} AssessmentService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {Profile} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {externalAssessmentSerializer} externalAssessmentSerializer
   */externalAssessmentSerializer:null, /**
   * @property {externalAssessmentAdapter} externalAssessmentAdapter
   */externalAssessmentAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('externalAssessmentSerializer',_gooruWebSerializersContentExternalAssessment['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('externalAssessmentAdapter',_gooruWebAdaptersContentExternalAssessment['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Gets an External Assessment by id
   * @param {string} assessmentId
   * @returns {Promise}
   */readExternalAssessment:function readExternalAssessment(assessmentId){var isSetOwner=arguments.length <= 1 || arguments[1] === undefined?true:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('externalAssessmentAdapter').readExternalAssessment(assessmentId).then(function(responseData){var assessment=service.get('externalAssessmentSerializer').normalizeReadExternalAssessment(responseData);if(isSetOwner){var profileService=service.get('profileService');profileService.readUserProfile(assessment.get('ownerId')).then(function(profile){assessment.set('owner',profile);resolve(assessment);});}else {resolve(assessment);}},reject);});}, /**
   * Updates an Assessment
   *
   * @param assessmentId the id of the Assessment to be updated
   * @param assessmentModel the Assessment model with the data
   * @returns {Promise}
   */updateExternalAssessment:function updateExternalAssessment(assessmentId,assessmentModel){var service=this;var serializedData=service.get('externalAssessmentSerializer').serializeUpdateExternalAssessment(assessmentModel);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('externalAssessmentAdapter').updateExternalAssessment(assessmentId,serializedData).then(function(){resolve();},reject);});}, /**
   * Delete assessment
   *
   * @param assessmentId The assessment id to delete
   * @returns {Ember.RSVP.Promise}
   */deleteExternalAssessment:function deleteExternalAssessment(assessment){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('externalAssessmentAdapter').deleteExternalAssessment(assessment.id).then(resolve,reject);});}, /**
   * Creates a new collection
   *
   * @param assessmentData object with the collection data
   * @returns {Promise}
   */createExternalAssessment:function createExternalAssessment(assessmentData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedExternalAssessmentData=service.get('externalAssessmentSerializer').serializeExternalAssessment(assessmentData);service.get('externalAssessmentAdapter').createExternalAssessment(serializedExternalAssessmentData).then(function(responseData,textStatus,request){var createdAssessmentData=_ember['default'].Object.create(assessmentData);var collectionId=request.getResponseHeader('location');createdAssessmentData.set('id',collectionId);resolve(createdAssessmentData);},function(error){reject(error);});});}});});