define('gooru-web/components/content/courses/play/gru-accordion-lesson-item',['exports','ember','gooru-web/mixins/tenant-settings-mixin','gooru-web/mixins/lesson-item-mixin'],function(exports,_ember,_gooruWebMixinsTenantSettingsMixin,_gooruWebMixinsLessonItemMixin){ /**
 * Content Builder: Accordion Lesson Item
 *
 * Component responsible for presenting a collection/assessment within a lesson.
 * It is meant to be used inside of an {@link ./gru-accordion-unit|Accordion Lesson}
 *
 * @module
 * @augments Ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'],_gooruWebMixinsLessonItemMixin['default'],{ // -------------------------------------------------------------------------
// Dependencies
session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','courses','gru-accordion-lesson-item','view'],tagName:'li', // -------------------------------------------------------------------------
// Properties
/**
   * @prop {String} courseId -  ID of the course this collection/assessment belongs to
   */courseId:null, /**
   * @prop {String} unitId - ID of the unit this collection/assessment belongs to
   */unitId:null, /**
   * @prop {String} lessonId - ID of the lesson this collection/assessment belongs to
   */lessonId:null, /**
   * @prop {Content/Collection-Assessment} model
   */model:null, /**
   * @prop {String} role Get current use role from session
   */role:_ember['default'].computed.alias('session.role'), // -------------------------------------------------------------------------
// Actions
actions:{openPlayerContent:function openPlayerContent(){var component=this;var model=component.get('model');var format=model.get('format');var id=model.get('id');var type=model.get('collectionType');var subFormat=model.get('subformat');var queryParams={offlineActivityId:id,type:type,role:component.get('role'),isPreview:true,isIframeMode:true};if(format === 'offline-activity'){component.set('playerUrl',component.get('router').generate('player-offline-activity',id,{queryParams:queryParams}));}else if(subFormat === 'scorm'){queryParams.collectionId = id;queryParams.source = 'ILActivity';component.set('playerUrl',component.get('router').generate('player-external',{queryParams:queryParams}));}else {component.set('playerUrl',component.get('router').generate('player',id,{queryParams:queryParams}));}component.set('isOpenPlayer',true);component.set('playerContent',model);},closePullUp:function closePullUp(){var component=this;component.set('isOpenPlayer',false);}},didInsertElement:function didInsertElement(){var controller=this;var content=controller.get('model');if(content.get('format') === 'assessment-external' || content.get('format') === 'collection-external'){controller.generateLuUrl(content);}}});});