define('gooru-web/adapters/performance/collection-performance-summary',['exports','ember','gooru-web/adapters/application'],function(exports,_ember,_gooruWebAdaptersApplication){ /**
 * Adapter CollectionPerformanceSummary
 *
 * @typedef {Object} CollectionPerformanceSummaryAdapter
 */exports['default'] = _gooruWebAdaptersApplication['default'].extend({session:_ember['default'].inject.service(),namespace:'/api/nucleus-insights/v2', /**
   * Searches student collection performance by course, class, unit, lesson and type
   * Criteria values are not required except for courseId
   *
   * @param {string} studentId
   * @param {{ courseId: number, unitId: string, lessonId: string, collectionType: string }} criteria
   * @returns {Promise}
   */searchStudentCollectionPerformanceSummary:function searchStudentCollectionPerformanceSummary(studentId,criteria){var adapter=this;var collectionType=criteria.collectionType || 'assessment';var url=adapter.get('namespace') + '/study/' + collectionType + '/performance';var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.get('headers'),data:{courseId:criteria.courseId,classId:criteria.classId,lessonId:criteria.lessonId,unitId:criteria.unitId,userId:studentId}};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});},findMyPerformance:function findMyPerformance(userId,courseId,lessonId,unitId){var collectionType=arguments.length <= 4 || arguments[4] === undefined?'assessment':arguments[4];var adapter=this;var url=adapter.get('namespace') + '/learner/course/' + collectionType + 's/performance';var options={type:'GET',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.get('headers'),data:{courseId:courseId,lessonId:lessonId,unitId:unitId,userId:userId}};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});}, /**
   * Finds collection performance summary for the ids provided
   * @param {string} userId user id
   * @param {string[]} collectionIds
   * @param {string} collectionType collection|assessment
   * @param {string} classId optional class id filter
   * @param {string} timePeriod optional time period filter
   * @returns {Ember.RSVP.Promise}
   */findCollectionPerformanceSummaryByIds:function findCollectionPerformanceSummaryByIds(userId,collectionIds,collectionType){var classId=arguments.length <= 3 || arguments[3] === undefined?undefined:arguments[3];var timePeriod=arguments.length <= 4 || arguments[4] === undefined?undefined:arguments[4];var adapter=this;var url=adapter.get('namespace') + '/' + collectionType + '/performance';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',headers:adapter.get('headers'),data:JSON.stringify({userId:userId,classId:classId,timePeriod:timePeriod,collectionIds:collectionIds})};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});}, /**
   * @function getCollectionsPerformanceByLessonId
   * Get Collection Performance Data by  lesson Id
   */getCollectionsPerformanceByLessonId:function getCollectionsPerformanceByLessonId(classId,courseId,unitId,lessonId,collectionType,userUid){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/unit/' + unitId + '/lesson/' + lessonId + '/performance';var options={type:'GET',headers:adapter.get('headers'),contentType:'application/json; charset=utf-8',dataType:'json',data:{collectionType:collectionType,userUid:userUid}};return _ember['default'].$.ajax(url,options);}});});