define('gooru-web/routes/login',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
queryParams:{redirectURL:{},isConfirmProfile:{}}, /**
   * The session service.
   * @property session
   * @readOnly
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:authentication
   */authenticationService:_ember['default'].inject.service('api-sdk/authentication'), // -------------------------------------------------------------------------
// Methods
model:function model(params){return params;},beforeModel:function beforeModel(transition){var route=this;if(!route.get('session.isAnonymous')){return route.transitionTo('index');}else {var nonce=transition.queryParams.nonce;var authenticationService=this.get('authenticationService');return authenticationService.authenticateAsAnonymous(nonce).then(function(data){route.set('anonymousSessionData',data);return route.get('session').authenticateAsAnonymousWithData(data).then(function(){var applicationController=route.controllerFor('application');return _ember['default'].RSVP.all([applicationController.loadTranslationLabels()]);});});}}, /**
   * Set all controller properties used in the template
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){if(model.isConfirmProfile){controller.set('redirectURL',window.atob(model.redirectURL));}controller.resetProperties();}});});