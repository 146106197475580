define('gooru-web/serializers/performance/class-performance-summary',['exports','ember','gooru-web/models/performance/class-performance-summary'],function(exports,_ember,_gooruWebModelsPerformanceClassPerformanceSummary){ /**
 * Serializer to support the ClassPerformanceSummary CRUD operations
 *
 * @typedef {Object} ClassPerformanceSummary
 */exports['default'] = _ember['default'].Object.extend({ /**
   * Normalize an array of ClassPerformanceSummary
   *
   * @param payload endpoint response format in JSON format
   * @returns {ClassPerformanceSummary[]}
   */normalizeAllClassPerformanceSummary:function normalizeAllClassPerformanceSummary(payload){var serializer=this;if(payload && _ember['default'].isArray(payload.usageData)){return payload.usageData.map(function(classPerformanceSummary){return serializer.normalizeClassPerformanceSummary(classPerformanceSummary);});}else {return [];}}, /**
   * Normalize a goal
   * @param {*} data
   * @return {Goal}
   */normalizeClassPerformanceSummary:function normalizeClassPerformanceSummary(data){return _gooruWebModelsPerformanceClassPerformanceSummary['default'].create({id:data.classId,classId:data.classId,timeSpent:data.timeSpent,score:data.scoreInPercentage,sessionId:data.lastSessionId,totalCompleted:data.completedCount,totalScore:data.totalScore,total:data.totalCount || data.completedCount /* using completedCount when no total count found, tmp fix */});}});});