define('gooru-web/components/reports/assessment/questions/gru-encoding-assessment',['exports','ember','gooru-web/mixins/reports/assessment/questions/question'],function(exports,_ember,_gooruWebMixinsReportsAssessmentQuestionsQuestion){ /**
 * SERP Encoding Assessment
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-encoding-assessment'], // -------------------------------------------------------------------------
// Properties
showCorrect:false, /**
   * @property {Boolean} isPause
   */isPause:false, /*
   * Hold the audio details
   */audioRecorder:null,answers:_ember['default'].computed('showCorrect','question.answers','answerObj',function(){return this.get('showCorrect')?this.get('question.answers'):this.get('answerObj')?this.get('answerObj'):this.get('question.answerObject');}), //Actions
actions:{ //Action triggered when play audio
onPlayAudio:function onPlayAudio(container,url,index){var component=this;var _audio=component.get('audioRecorder');if(!_audio || component.get('answerIndex') !== index){_audio = new Audio(url);component.set('answerIndex',index);}component.set('audioRecorder',_audio);_audio.play();component.set('isPause',true);_audio.ontimeupdate = function(){component.$('.answer-container .' + container + ' .audio-progress .progress-filling').css('width',_audio.currentTime / _audio.duration * 100 + '%');};_audio.addEventListener('ended',function(){component.set('isPause',false);});}, //Action triggered when pause audio
onPauseAudio:function onPauseAudio(){var component=this;var audio=component.get('audioRecorder');audio.pause();component.set('isPause',false);}}});});