define('gooru-web/mixins/language',['exports','ember','gooru-web/config/environment'],function(exports,_ember,_gooruWebConfigEnvironment){exports['default'] = _ember['default'].Mixin.create({ // -------------------------------------------------------------------------
// Dependencies
/**
   * device_language_key
   */device_language_key:'deviceLanguage', /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyServices:_ember['default'].inject.service('api-sdk/taxonomy'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Methods
getLocalStorage:function getLocalStorage(){return window.localStorage;},changeLanguages:function changeLanguages(classes){var mixin=this;if(classes.preference && classes.preference.subject){var _ret=(function(){var subjectCode=classes.preference.subject.split('.')[0];var whichLocalSet=mixin.getLocalStorage().getItem(mixin.device_language_key);return {v:_ember['default'].RSVP.hash({gradeList:mixin.get('taxonomyServices').fetchGradesBySubject({subject:classes.preference.subject,fw_code:classes.preference.framework})}).then(function(_ref){var gradeList=_ref.gradeList;var readingLevel=undefined;if(classes.gradeCurrent){var grade=gradeList.findBy('id',classes.gradeCurrent);if(grade){readingLevel = grade.readingLevel;}}mixin.get('profileService').loadScripts(whichLocalSet,subjectCode,readingLevel).then(function(response){if(response && Object.entries(response).length !== 0){mixin.get('i18n').addTranslations(whichLocalSet,response);mixin.setupDefaultLanguage(whichLocalSet);mixin.getLocalStorage().setItem(mixin.device_language_key,whichLocalSet);}});})};})();if(typeof _ret === 'object')return _ret.v;}},changeLanguageHomePage:function changeLanguageHomePage(){var mixin=this;var defaultLocale=_gooruWebConfigEnvironment['default'].i18n.defaultLocale;var deviceLanguage=mixin.getLocalStorage().getItem(mixin.device_language_key);var whichLocalSet=deviceLanguage?deviceLanguage:defaultLocale;mixin.get('profileService').loadScript(whichLocalSet).then(function(response){mixin.get('i18n').addTranslations(whichLocalSet,response);mixin.setupDefaultLanguage(whichLocalSet);mixin.getLocalStorage().setItem(mixin.device_language_key,whichLocalSet);});}, /**
   * Setups the application default language
   * @param {lang}  // default language to use
   */setupDefaultLanguage:function setupDefaultLanguage(lang){if(lang !== undefined){this.set('i18n.locale',lang);if(lang === 'ar'){var rootElement=_ember['default'].$(_gooruWebConfigEnvironment['default'].rootElement);rootElement.addClass('changeDir');rootElement.removeClass('changeDirDefault');}else {var rootElement=_ember['default'].$(_gooruWebConfigEnvironment['default'].rootElement);rootElement.removeClass('changeDir');rootElement.addClass('changeDirDefault');}}}});});