define('gooru-web/components/player/questions/gru-encoding-assessment',['exports','gooru-web/components/player/questions/gru-question','ember'],function(exports,_gooruWebComponentsPlayerQuestionsGruQuestion,_ember){ /**
 * Fill in the blank
 *
 * Component responsible for controlling the logic and appearance of a fill in the blank
 * question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments Ember/Component
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruQuestion['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-encoding-assessment'], // -------------------------------------------------------------------------
// Actions
showCorrect:false,answers:_ember['default'].computed.alias('question.answers'),isShowPlay:false, // -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Methods
actions:{ //Action triggered when play audio
onPlayAudio:function onPlayAudio(container,url){var component=this;var _audio=new Audio(url);_audio.play();_audio.ontimeupdate = function(){component.$('.answer-container .' + container + ' .audio-progress .progress-filling').css('width',_audio.currentTime / _audio.duration * 100 + '%');};}},initInputEvents:(function(){if(this.get('baseQuestion')){this.set('isShowPlay',true);var encodingAnswers=this.get('baseQuestion.answers').map(function(baseAnswer){return {audio_file_url:baseAnswer.get('audioUrl'),answer_text:baseAnswer.get('text')};});this.injectEncoding(encodingAnswers);}}).on('didInsertElement'), // -------------------------------------------------------------------------
// Properties
// -------------------------------------------------------------------------
// Methods
injectEncoding:function injectEncoding(baseAnswers){var accessibilitySettings=JSON.parse(window.localStorage.getItem('accessibility_settings'));var user={userId:'userId'};var content={contentId:'contentId',contentTitle:'contentTitle',answers:baseAnswers,isHighContrast:accessibilitySettings && accessibilitySettings.is_high_contrast_enabled?accessibilitySettings.is_high_contrast_enabled:false};window.serp.languageDecode().select('#serp-encoding-answer-container').dataIn(user,null,content).encoding().render().listener(function(){return;});}});});