define('gooru-web/components/grading/student/gru-self-grading-items',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['self-grading','gru-self-grading-items'], // -------------------------------------------------------------------------
// Dependencies
offlineActivityService:_ember['default'].inject.service('api-sdk/offline-activity/offline-activity'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.loadItemsToGrade();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when select an item to grade
onSelectGradeItem:function onSelectGradeItem(gradeItem){var component=this;component.set('selectedGradeItem',gradeItem);component.set('isShowOaSelfGrading',true);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {UUID} userId
   * UserID of logged in user
   */userId:_ember['default'].computed.alias('session.userId'), /**
   * @property {Boolean} isShowOaSelfGrading
   * Property to check whether to show self grading pull up or not
   */isShowOaSelfGrading:false, /**
   * @property {Array} selfGradeItems
   * List of self grading items
   */selfGradeItems:_ember['default'].A([]), /**
   * @property {Array} itemsToGrade
   * List of items to grade
   */itemsToGrade:_ember['default'].A([]), // -------------------------------------------------------------------------
// Methods
/**
   * @function loadItemsToGrade
   * Method to load items to be graded
   */loadItemsToGrade:function loadItemsToGrade(){var component=this;var selfGradeItems=component.get('selfGradeItems');var itemsToGrade=_ember['default'].A([]);selfGradeItems.map(function(item){var gradeItem=undefined;gradeItem = component.createOaGradeItemObject(item);if(gradeItem){itemsToGrade.push(gradeItem);}});_ember['default'].RSVP.all(itemsToGrade).then(function(gradeItems){component.set('itemsToGrade',gradeItems);});}, /**
   * Creates the grade item information for activity level
   * @param {[]} grade item
   * @param {item} item
   */createOaGradeItemObject:function createOaGradeItemObject(item){var component=this;var activityId=item.get('collectionId');var contentType=item.get('collectionType');var classId=component.get('classId');var itemObject=_ember['default'].Object.create();return new _ember['default'].RSVP.Promise(function(resolve,reject){component.get('offlineActivityService').readActivity(activityId).then(function(oaContent){itemObject.setProperties({classId:classId,content:oaContent,contentType:contentType});resolve(itemObject);},reject);});}});});