define('gooru-web/components/student/class/student-inspect-destination-xs',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['student-inspect-destination-xs'],classNameBindings:['type','isPublic:public-class'],session:_ember['default'].inject.service('session'), /**
   * @property {Boolean} isAllContentsAreRescoped
   */isAllContentsAreRescoped:false, /**
   * taxonomy service dependency injection
   * @type {Object}
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'),actions:{onClosePullUp:function onClosePullUp(){var component=this;component.closePullUp();component.set('isLocationUpdate',false);component.set('showInfo',false);component.set('showLocationInfo',false);},onOpenPullUp:function onOpenPullUp(){var component=this;component.openPullUp();},takeDiagnostic:function takeDiagnostic(){this.sendAction('takeDiagnostic');},changesMyLevel:function changesMyLevel(){this.set('isMathGradeChange',!this.get('isMathGradeChange'));},updateLocation:function updateLocation(show){this.openPullUp();var component=this;component.set('isLocationUpdate',show);component.set('isMathGradeChange',false);},updateMathLevel:function updateMathLevel(grage){this.set('isMathGradeChange',false);this.set('isNextEnable',true);this.set('studentMathGrade',grage);this.set('isShowLoader',true);this.sendAction('updateMathLevel',grage);}, /**
     * Action triggered when move to next screen
     */onMoveNext:function onMoveNext(curStep){this.openPullUp();var component=this;component.sendAction('onMoveNext',curStep);}, /**
     * Action triggered when toggle route0 course-map view expanded/collapsed
     */onToggleRoute0View:function onToggleRoute0View(){var component=this;component.sendAction('onToggleRoute0View');}, /**
     * Action triggered when accept route0 suggestion
     */onRoute0Accept:function onRoute0Accept(){var component=this;component.sendAction('onRoute0Accept');}, /**
     * Action triggered when reject route0 suggestion
     */onRoute0Reject:function onRoute0Reject(){var component=this;component.sendAction('onRoute0Reject');}, //Action triggered in init for check all the milestone contents are rescoped
checkAllContentsAreRescoped:function checkAllContentsAreRescoped(milestoneData){var milestones=milestoneData;var rescopeMilestone=milestones.filterBy('rescope',true);var isAllContentsAreRescoped=milestones.length === rescopeMilestone.length;this.set('isAllContentsAreRescoped',isAllContentsAreRescoped);}},openPullUp:function openPullUp(){var component=this;if(!component.get('isExpanded')){component.$().animate({top:'10%'},400,function(){component.set('isExpanded',true);});}},closePullUp:function closePullUp(){var component=this;if(component.get('isExpanded')){component.$().animate({top:'90%'},400,function(){if(component.get('isPublic')){component.$().css('top','calc(100% - 160px)');}else {component.$().css('top','calc(100% - 140px)');}component.set('isExpanded',false);});}},didInsertElement:function didInsertElement(){if(this.type === 'route'){this.openPullUp();}this.$('.route-item, .location-item').popover('destroy');this.$('.route-item, .location-item').off('click');this.setupTooltip('route-item','show-route-description');this.setupTooltip('location-item','show-location-description');if(this.get('defaultGradeLevel')){this.send('updateLocation',false);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Boolean} isExpanded
   */isExpanded:false,taxonomyGrades:null, /**
   * @property {JSON} activeGrade
   */grade:null, /**
   * @type {Boolean}
   */isMathGradeChange:false,showInfo:false,showLocationInfo:false,isShowLoader:false, /**
   * The class is premium
   * @property {String}
   */isPremiumClass:_ember['default'].computed('class',function(){var controller=this;var currentClass=controller.get('class');var classSetting=currentClass.get('setting');return classSetting?classSetting['course.premium']:false;}), /**
   * @property {Boolean} isPublicClass
   */isPublic:_ember['default'].computed.alias('class.isPublic'), /**
   * @property {Boolean} isJoinedPublicClass
   */isJoinedPublicClass:_ember['default'].computed('isPublic','class',function(){var isPublic=this.get('isPublic');var userId=this.get('session.userId');var gradeBounds=this.get('class.memberGradeBounds');var gradeBound=gradeBounds.findBy(userId);var studentGradeBound=_ember['default'].Object.create(gradeBound.get(userId));return isPublic && studentGradeBound.get('grade_lower_bound') && studentGradeBound.get('grade_upper_bound');}), /**
   * @property {JSON} studentMathGrade
   */studentMathGrade:_ember['default'].computed('currentCourseBounds','taxonomyGrades',function(){var taxonomyGrades=this.get('taxonomyGrades');var studentGradeMath=null;if(this.get('isPublic') && this.get('isPremiumClass')){var gradeLowerBound=this.get('currentCourseBounds.gradeLowerBound');studentGradeMath = taxonomyGrades.findBy('id',gradeLowerBound);}return studentGradeMath;}), /**
   * @property {Object} currentCourseBounds
   */currentCourseBounds:_ember['default'].computed('isPublic','independentCourses',function(){var courseBounds=null;var independentCourses=this.get('independentCourses');if(this.get('isPublic') && this.get('isPremiumClass')){var currentCourse=independentCourses.findBy('id',this.get('courseId'));var courseSettings=currentCourse.get('settings');courseBounds = _ember['default'].Object.create({gradeCurrent:courseSettings.get('gradeCurrent'),gradeLowerBound:courseSettings.get('gradeLowerBound'),gradeUpperBound:courseSettings.get('gradeUpperBound')});}return courseBounds;}),isDiagnosticApplicable:_ember['default'].computed('isPublic','independentCourses',function(){var diagnosticApplicable=false;var independentCourses=this.get('independentCourses');if(this.get('isPublic') && this.get('isPremiumClass')){var currentCourse=independentCourses.findBy('id',this.get('courseId'));var courseSettings=currentCourse.get('settings');diagnosticApplicable = courseSettings.get('isDiagnosticApplicable');}return diagnosticApplicable;}), /**
   * @property {Array} mathLevelGrades
   */mathLevelGrades:_ember['default'].computed('currentCourseBounds',function(){var mathLevelGrades=_ember['default'].A([]);if(this.get('isPublic') && this.get('isPremiumClass')){var currentCourseBounds=this.get('currentCourseBounds');var taxonomyGrades=this.get('taxonomyGrades').sortBy('sequence');var gradeCurrent=currentCourseBounds.get('gradeCurrent');var startGrade=taxonomyGrades.get('firstObject');var startGradeIndex=taxonomyGrades.indexOf(startGrade);var endGrade=taxonomyGrades.findBy('id',gradeCurrent);var endGradeIndex=taxonomyGrades.indexOf(endGrade);mathLevelGrades = taxonomyGrades.slice(startGradeIndex,endGradeIndex + 1);}return mathLevelGrades;}),setupTooltip:function setupTooltip(tooltipDiv,descriptionDiv){var component=this;var $anchor=this.$('.' + tooltipDiv);$anchor.attr('data-html','true');$anchor.popover({placement:'top',content:function content(){return component.$('.' + descriptionDiv).html();},trigger:'manual',container:'body'});$anchor.on('click',function(){var $this=$(this);if(!$this.hasClass('list-open')){ // Close all tag tooltips by simulating a click on them
$('.' + tooltipDiv + '.list-open').click();$this.addClass('list-open').popover('show');}else {$this.removeClass('list-open').popover('hide');}});}});});