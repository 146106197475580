define('gooru-web/mixins/content/builder',['exports','ember','gooru-web/models/content/builder/item'],function(exports,_ember,_gooruWebModelsContentBuilderItem){ /**
 * Properties/functionality in common for content creation
 *
 * @mixin
 */exports['default'] = _ember['default'].Mixin.create({ // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Reorder child items
     */sortItems:function sortItems(){var $sortable=this.$(this.get('sortableSelector'));this.set('model.isExpanded',true);this.set('model.isSorting',true);$sortable.sortable({scroll:false});$sortable.addClass('sorting').sortable('enable');}, /**
     * Cancel sorting of child items
     */cancelSort:function cancelSort(){var $sortable=this.$(this.get('sortableSelector'));this.set('model.isSorting',false);this.cancelSort();$sortable.removeClass('sorting').sortable('disable');}, /**
     * Save sorting of child items
     */finishSort:function finishSort(){var $sortable=this.$(this.get('sortableSelector'));this.set('model.isSorting',false);$sortable.removeClass('sorting').sortable('disable');this.refreshList();}}, // -------------------------------------------------------------------------
// Events
setupSortable:_ember['default'].on('didInsertElement',function(){this._super.apply(this,arguments);var component=this;var sortableSelector=this.get('sortableSelector');this.$(sortableSelector).sortable({disabled:true,update:function update(){return component.refreshOrderList();}});}),destroySortable:_ember['default'].on('willDestroyElement',function(){var $sortable=this.$(this.get('sortableSelector')); // Test if the element had a sortable widget instantiated
if($sortable.hasClass('ui-sortable')){$sortable.sortable('destroy');}}), // -------------------------------------------------------------------------
// Properties
/**
   * @prop {Number} index - Item index number relative to its sibling
   */index:null, /**
   * @property {Boolean} isAddingItem - Is a new item being added or not?
   */isAddingItem:_ember['default'].computed('items.@each.isNew',function(){var items=this.get('items');return !!items.filterBy('isNew').length;}), /**
   * @property {Boolean} Is this item currently being reordered among its siblings?
   */isSorting:false, /**
   * @property {Boolean} isEditingItem - Is an item being edited or not?
   * New items (i.e. not yet saved) are also considered as being edited.
   */isEditingItem:_ember['default'].computed('items.@each.isEditing',function(){var items=this.get('items');return items.filterBy('isEditing',true).length;}), /**
   * @prop {Ember.RSVP.Promise} items - children of the accordion
   * Will resolve to {Unit[] | Lesson[] | Collection[]}
   */items:[], /**
   * @prop {Unit | Lesson} model - Item data model (accordion root)
   */model:null, /**
   * @property {String[]} Array of item IDs corresponding to the current order of the child items
   */orderList:null, /**
   * @prop {String} sortableSelector - CSS selector for the sortable element in all places this
   * mixin is being used
   */sortableSelector:'> .panel > .panel-body > .sortable, > .sortable',dragElSelector:' > li',parentElSelector:' > li', /**
   * @property {Boolean} totalSavedItems - Number of items that have been saved at least once?
   */totalSavedItems:_ember['default'].computed('items.@each.isNew',function(){var items=this.get('items');return items.filterBy('isNew',false).length;}), // -------------------------------------------------------------------------
// Methods
/**
   * Refresh the order list when changing items
   */refreshOrderList:function refreshOrderList(){var items=this.get('items');var findById=undefined;if(items.length && items[0] instanceof _gooruWebModelsContentBuilderItem['default']){findById = function(id){return items.findBy('data.id',id);};}else {findById = function(id){return items.findBy('id',id);};}var $items=this.$(this.get('sortableSelector')).find(this.get('dragElSelector')); // Only use DOM elements that have a corresponding item in the list
var orderList=$items.filter(function(idx,item){return !!findById($(item).data('id'));}).map(function(idx,item){return $(item).data('id');}).toArray();this.set('orderList',orderList); // Remove DOM elements that have no corresponding item in the list
var toRemove=$items.filter(function(idx,item){var dataId=$(item).data('id');return !!dataId && !findById(dataId);});toRemove.each(function(idx,item){return $(item).remove();});}, /**
   * Refresh the item list after save reorder
   */refreshList:function refreshList(){var orderList=this.get('orderList');var items=this.get('items');if(orderList){var filterFunc=undefined;if(items.length && items[0] instanceof _gooruWebModelsContentBuilderItem['default']){filterFunc = function(item,index,items){return items.findBy('data.id',orderList[index]);};}else {filterFunc = function(item,index,items){return items.findBy('id',orderList[index]);};}var sortedItems=items.map(filterFunc);items.clear();items.addObjects(sortedItems);}}, /**
   * Return elements to their original positions
   */cancelSort:function cancelSort(){var sortableSelector=this.get('sortableSelector');var items=this.get('items');var $sortable=this.$(sortableSelector);var dragEl=this.get('parentElSelector');var $items=$sortable.find(dragEl); // Create map of dom elements with the id as key
var itemsMap=$items.toArray().reduce(function(itemsMap,item){itemsMap[$(item).data('id')] = item;return itemsMap;},{});var getId=undefined;if(items.length && items[0] instanceof _gooruWebModelsContentBuilderItem['default']){getId = function(item){return item.get('data.id');};}else {getId = function(item){return item.get('id');};} // Send an update event to move every element to its original position
items.map(function(item){return itemsMap[getId(item)];}).forEach(function(item,index){$sortable.sortable('option','update')(null,{item:$sortable.find(dragEl + ':eq(' + index + ')').after($(item))});});}});});