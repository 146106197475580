define('gooru-web/components/content/questions/answers/gru-comprehension',['exports','ember','gooru-web/config/question','gooru-web/mixins/modal','gooru-web/mixins/content/builder'],function(exports,_ember,_gooruWebConfigQuestion,_gooruWebMixinsModal,_gooruWebMixinsContentBuilder){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],_gooruWebMixinsContentBuilder['default'],{ // --------------------------------------------------------------------
// Attributes
classNames:['gru-comprehension'], /**
   * @requires service:api-sdk/question
   */questionSevice:_ember['default'].inject.service('api-sdk/question'), // ---------------------------------------------------------------------
// Dependencies
// ----------------------------------------------------------------------
// Properties
tempQuestion:_ember['default'].computed('tempModel',function(){return this.get('tempModel');}),collection:null, /**
   * @property {Array} questionList hasList of question
   **/questionList:_ember['default'].computed('tempQuestion.subQuestions.@each',function(){return this.get('tempQuestion.subQuestions') || _ember['default'].A();}),model:_ember['default'].computed.alias('tempQuestion'),items:_ember['default'].computed.alias('questionList'),isComprehension:true,sortableSelector:'.gru-comprehension-panel-section.sortable',dragElSelector:' > .comprehension-component > li',parentElSelector:' > .comprehension-component', /**
   * @property{Array{}} questionTypes
   */questionTypes:_ember['default'].computed(function(){var question=[];Object.keys(_gooruWebConfigQuestion.QUESTION_CONFIG).forEach(function(item){if(!_gooruWebConfigQuestion.QUESTION_CONFIG[item].serpType){question.push(item);}});return question;}), // -----------------------------------------------------------------------
// Events
// ----------------------------------------------------------------------
// Actions
actions:{onAddNew:function onAddNew(){var component=this;var collection=component.get('collection');var model=_ember['default'].Object.create({isComprehension:true,id:component.get('tempQuestion.id'),baseResource:collection});component.send('showModal','content.modals.gru-question-new',model);},onSelectQusType:function onSelectQusType(question,type){question.set('type',type); // this.set('tempQuestion.type', type);
},onRemoveCollectionItem:function onRemoveCollectionItem(question){var questionList=this.get('questionList');questionList.removeObject(question);}, /**
     * Save reorder collection items
     */saveCollectionItemsOrder:function saveCollectionItemsOrder(){var component=this;var orderList=component.get('orderList');var collection=component.get('collection');if(orderList){component.get('questionSevice').reorderQuestions(component.get('model.id'),component.get('orderList'),collection).then(function(){component.actions.finishSort.call(component);});}else {component.actions.finishSort.call(component);}}} // -------------------------------------------------------------------------
// Methods
});});