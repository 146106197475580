define('gooru-web/components/teacher/class/gru-class-statistics',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){ /**
 * Teacher class statistics
 *
 * Component responsible for showing class statistics data
 * @module
 * @see controllers/teacher/class.js
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-class-statistics','teacher'], // -------------------------------------------------------------------------
// Actions
actions:{}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {Class} class
   */'class':null // -------------------------------------------------------------------------
// Methods
});});