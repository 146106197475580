define('gooru-web/routes/content/external-assessments/edit',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/mixins/learning-upgrade-mixin'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebMixinsLearningUpgradeMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],_gooruWebMixinsLearningUpgradeMixin['default'],{queryParams:{editing:{refreshModel:true},editingContent:{refreshModel:true},isLibraryContent:false,isPreviewReferrer:false}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Session} current session
   */session:_ember['default'].inject.service('session'),externalAssessmentService:_ember['default'].inject.service('api-sdk/external-assessment'),courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @requires service:api-sdk/question
   */questionService:_ember['default'].inject.service('api-sdk/question'), /**
   * @requires service:century-skill/century-skill
   */centurySkillService:_ember['default'].inject.service('century-skill'), // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Methods
model:function model(params){this.set('isPreviewReferrer',params.isPreviewReferrer);var route=this;return route.get('externalAssessmentService').readExternalAssessment(params.collectionId).then(function(assessment){var courseId=assessment.get('courseId');var learningToolId=assessment.get('learningToolId');var isEditing=params.editing;var editingContent=params.editingContent?params.editingContent:null;var isLibraryContent=params.isLibraryContent;var course=null;var toolDetails=null;var isLUContent=!!learningToolId;if(courseId){course = route.get('courseService').fetchById(courseId);}if(learningToolId){toolDetails = route.get('learningToolService').getLearningToolInformation(learningToolId);}var questionPromiseList=assessment.get('children').map(function(question){return route.get('questionService').readQuestion(question.get('id'));});return _ember['default'].RSVP.hash({questions:_ember['default'].RSVP.all(questionPromiseList),assessment:assessment,course:course,isEditing:isEditing === 'true',editingContent:editingContent,isLibraryContent:isLibraryContent,isPreviewReferrer:params.isPreviewReferrer,isLUContent:isLUContent,toolDetails:toolDetails});});},setupController:function setupController(controller,model){var route=this;var isLUContent=model.isLUContent;var toolDetails=model.toolDetails; // Since assessment is a collection with only questions, we'll reuse the same components
// for collections (for example, see: /app/components/content/assessments/gru-assessment-edit.js)
// and that is why the property 'collection' is being reused here, too.
model.assessment.set('children',model.questions);controller.set('collection',model.assessment);controller.set('course',model.course);controller.set('isEditing',model.isEditing);controller.set('editingContent',model.editingContent);controller.set('isExternalAssessment',true);controller.set('isLibraryContent',model.isLibraryContent);controller.set('isPreviewReferrer',model.isPreviewReferrer);controller.set('isLUContent',isLUContent);controller.set('toolDetails',toolDetails);route.get('centurySkillService').findCenturySkills().then(function(centurySkillsArray){controller.set('centurySkills',centurySkillsArray.toArray());});if(model.isEditing || model.editingContent){controller.set('tempCollection',model.assessment.copy());}if(isLUContent){route.GenerateLUContentURL(model.assessment,toolDetails);}},resetController:function resetController(controller){controller.set('isPreviewReferrer',undefined);this.get('controller').set('isEditing','false');}});});