define('gooru-web/adapters/content/external-collection',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Collection CRUD operations in the API 3.0
 *
 * @typedef {Object} ExternalCollectionAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),externalNamespace:'/api/nucleus/v1/collections-external', /**
   * Creates a new external collection
   *
   * @param data collection data to be sent in the request body
   * @returns {Promise}
   */createExternalCollection:function createExternalCollection(collectionData){var adapter=this;var url=this.get('externalNamespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(collectionData)};return _ember['default'].$.ajax(url,options);}, /**
   * Reads an External Collection by id
   *
   * @param {string} collectionId
   * @returns {Promise}
   */readExternalCollection:function readExternalCollection(collectionId){var adapter=this;var namespace=adapter.get('externalNamespace');var url=namespace + '/' + collectionId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   * Update a Collection
   *
   * @param collectionId the id of the Collection to be updated
   * @param data Collection data to be sent in the request body
   * @returns {Promise}
   */updateExternalCollection:function updateExternalCollection(collectionId,data){var adapter=this;var namespace=this.get('externalNamespace');var url=namespace + '/' + collectionId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes a collection by id
   *
   * @param collectionId collection id to be sent
   * @returns {Promise}
   */deleteExternalCollection:function deleteExternalCollection(collectionId){var adapter=this;var namespace=this.get('externalNamespace');var url=namespace + '/' + collectionId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});