define('gooru-web/controllers/student-locate',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), /**
   * @type {ChronoPerformanceService} Service to retrieve performance information
   */chronoPerformanceService:_ember['default'].inject.service('api-sdk/chrono-performance'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'),activityStartDate:_ember['default'].computed('studentActivities',function(){return this.get('studentActivities.activityStartDate');}),activities:_ember['default'].computed('studentActivities',function(){return this.get('studentActivities.activities').reverse();}),performanceSummary:_ember['default'].computed('class',function(){return this.get('class.performanceSummary');}),barChartData:_ember['default'].computed('class',function(){var completed=this.get('class.performanceSummary.totalCompleted');var total=this.get('class.performanceSummary.total');var percentage=completed?completed / total * 100:0;return [{color:_gooruWebConfigConfig.ANONYMOUS_COLOR,percentage:percentage}];}),performancePercentage:_ember['default'].computed('barChartData',function(){var data=this.get('barChartData').objectAt(0);return data.percentage.toFixed(0);}), /**
   * @property {activeStudent}
   */activeStudent:null,mileStone:_ember['default'].computed(function(){return {iconClass:'msaddon',offset:{left:'-20px'}};}), // -------------------------------------------------------------------------
// Attributes
queryParams:['classId','courseId','studyPlayer'], /**
   * @property {limit}
   */limit:15, /**
   * @property {offset}
   */offset:0, /**
   * @property {noMoreData}
   */noMoreData:false,studyPlayer:false, /**
   * @property {String}
   * Property to store course subject bucket or K12.MA will be default
   */subjectBucket:_ember['default'].computed('course',function(){var controller=this;return controller.get('course.subject') || null;}),domainCompetencyList:null,init:function init(){var controller=this;controller.fetchStudentDetails();},actions:{paginateNext:function paginateNext(){var controller=this;if(!controller.get('noMoreData')){var offset=controller.get('limit') + controller.get('offset') + 1;controller.set('offset',offset);controller.getStudentPerformance();}},mileStoneHandler:function mileStoneHandler(){var controller=this;var classId=controller.get('classId');var courseId=controller.get('courseId');if(controller.get('studyPlayer')){window.history.back();}else if(classId){controller.transitionToRoute('student.class.course-map',classId);}else {controller.transitionToRoute('student.independent.course-map',courseId);}},showStudentProficiency:function showStudentProficiency(){var controller=this;var userId=this.get('session.userId');var courseId=this.get('courseId');var classId=this.get('classId');controller.transitionToRoute('student-learner-proficiency',userId,{queryParams:{classId:classId,courseId:courseId,role:'student'}});}}, /**
   * @function getStudentPerformance
   * Method to get active student performance details
   */getStudentPerformance:function getStudentPerformance(){var _this=this;var userId=this.get('session.userId');var classId=this.get('classId');var filter={userId:userId,courseId:this.get('courseId'),classId:classId,offset:this.get('offset'),limit:this.get('limit')};var studentPerformancePromise=classId?this.get('chronoPerformanceService').getStudentPerformanceOfAllItemsInClass(filter):this.get('chronoPerformanceService').getStudentPerformanceOfIndepedentLearning(filter);return _ember['default'].RSVP.hashSettled({studentPerformance:studentPerformancePromise}).then(function(hash){if(hash.studentPerformance.state === 'fulfilled'){var activities=hash.studentPerformance.value.activities.reverse();var noMoreData=activities.length < _this.get('limit');_this.set('noMoreData',noMoreData);activities.map(function(activitiy,index){_this.get('activities').insertAt(index,activitiy);});}});}, /**
   * @function fetchStudentDetails
   * @param userId
   * Method to fetch list of student using given user id
   */fetchStudentDetails:function fetchStudentDetails(){var controller=this;var userId=controller.get('session.userId');return _ember['default'].RSVP.hash({studentDetails:_ember['default'].RSVP.resolve(controller.get('profileService').readUserProfile(userId))}).then(function(_ref){var studentDetails=_ref.studentDetails;controller.set('activeStudent',studentDetails);});}});});