define('gooru-web/models/content/oa/reference',['exports','ember','ember-cp-validations'],function(exports,_ember,_emberCpValidations){var Validations=(0,_emberCpValidations.buildValidations)({location:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.enter-url'}),(0,_emberCpValidations.validator)('format',{type:'url',regex:new RegExp(/^(ftp|http|https):\/\/[^ "]+$/),message:'{{description}}',descriptionKey:'errors.url'})],fileName:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.referene.name'})]}); /**
 *
 *
 * reference model
 *
 * @typedef {Object} Content/oa/reference
 */exports['default'] = _ember['default'].Object.extend(Validations,{ /**
   * @property {String} id - Gooru id for the unit
   */id:null, /**
   * @property {String} id- Parent OA id
   */oaId:null, /**
   * @property {String} type
   */type:'', /**
   * @property {String} subType
   */subType:'', /**
   * @property {location} location
   */location:null, /**
   *
   *
   * @function
   * @return {Content/oa/task}
   */copy:function copy(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key)); // Copy null values as well to avoid triggering the validation on empty input fields
if(value === 'string' || value === 'number' || value === 'boolean' || value === 'null'){properties.push(key);}} // Copy the task data
properties = this.getProperties(properties);return this.get('constructor').create(properties);}, /**
   * Copy a list of property values from another reference to override the current ones
   *
   * @function
   * @param {Content/oa/reference} reference
   * @param {String[]} propertyList
   * @return {null}
   */merge:function merge(unit){var propertyList=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var properties=unit.getProperties(propertyList);this.setProperties(properties);}});});