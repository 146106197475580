define('gooru-web/services/api-sdk/skyline-initial',['exports','ember','gooru-web/adapters/skyline-initial/skyline-initial','gooru-web/serializers/skyline-initial/skyline-initial'],function(exports,_ember,_gooruWebAdaptersSkylineInitialSkylineInitial,_gooruWebSerializersSkylineInitialSkylineInitial){ /**
 * Service for the skyline initial
 *
 * @typedef {Object} skylineInitialService
 */exports['default'] = _ember['default'].Service.extend({skylineAdapter:null,skylineSerializer:null,init:function init(){this._super.apply(this,arguments);this.set('skylineAdapter',_gooruWebAdaptersSkylineInitialSkylineInitial['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('skylineSerializer',_gooruWebSerializersSkylineInitialSkylineInitial['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Get state of skyline initialization
   * @returns {Promise.<[]>}
   */fetchState:function fetchState(classId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('skylineAdapter').fetchState(classId).then(function(response){resolve(service.get('skylineSerializer').normalizeFetchState(response));},reject);});}, /**
   * calculate skyline
   * @returns {Promise.<[]>}
   */calculateSkyline:function calculateSkyline(classId,userIds){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('skylineAdapter').calculateSkyline(classId,userIds).then(function(){resolve();},reject);});}});});