define('gooru-web/components/gru-status-switch',['exports','ember'],function(exports,_ember){ /**
 * Switch component
 * Component responsible for show two options to switch
 * Use Bootstrap Toggle component that helps
 * you turn your default HTML checkboxes into toggles.
 * @see https://github.com/minhur/bootstrap-toggle/
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-status-switch'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
/**
   * Overwrites didInsertElement hook.
   */didInsertElement:function didInsertElement(){var component=this;var $toggle=this.$('input[type=checkbox][data-toggle^=toggle]');$toggle.bootstrapToggle();$toggle.change(function(){var checked=$toggle.prop('checked');component.sendAction('onOptionSwitch',checked,component.get('item'));});this.changeStatus(this.get('isChecked'));},stateObserver:_ember['default'].observer('isChecked',function(){this.changeStatus(this.get('isChecked'));}), // -------------------------------------------------------------------------
// Methods
changeStatus:function changeStatus(isChecked){var $toggle=this.$('input[type=checkbox][data-toggle^=toggle]');$toggle.prop('checked',isChecked).change();}, // -------------------------------------------------------------------------
// Properties
/**
   * Disabled the switch
   * @property {Boolean} disabled
   */disabled:false, /**
   * Item to change
   *
   */item:null, /**
   * Indicate if the switch is checked
   * @property {Boolean} isChecked
   */isChecked:false});});