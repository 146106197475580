define('gooru-web/components/reports/pull-up/open-ended-report-pull-up',['exports','ember','gooru-web/models/result/context','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsResultContext,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Ember.Service} Service to retrieve an assessment result
   */userSessionService:_ember['default'].inject.service('api-sdk/user-session'), /**
   * @type {RubricService} Service to retrieve rubric information
   */rubricService:_ember['default'].inject.service('api-sdk/rubric'), /**
   * @type {ProfileService} Service to retrieve question information
   */questionService:_ember['default'].inject.service('api-sdk/question'), /**
   * @property {Ember.Service} Service to retrieve an assessment result
   */performanceService:_ember['default'].inject.service('api-sdk/performance'),classNames:['open-ended-report-pull-up'],actions:{closeAll:function closeAll(){this.sendAction('onClosePullUp');},onPullUpClose:function onPullUpClose(){this.closePullUp();}}, // -------------------------------------------------------------------------
// Events
/**
   * Function to triggered once when the component element is first rendered.
   */didInsertElement:function didInsertElement(){this.openPullUp();this.loadData();}, // -------------------------------------------------------------------------
// Properties
/**
   * Propery to hide the default pullup.
   * @property {showPullUp}
   */showPullUp:false, /**
   * @property {JSON}
   */context:null, /**
   * Propery to show question thumbnail.
   * @property {String}
   */questionThumbnail:null, /**
   * Propery to show the question title or description.
   * @property {String}
   */questionText:null, /**
   * @property {Question}
   */question:null, /**
   * @property {Rubric}
   */rubric:null, /**
   * @property {ResourceResult} questionResult
   */questionResult:null, /**
   * @property {RubricGrade} questionSummary
   */questionSummary:null, /**
   * @property {string} indicates if it is a student or teacher view
   */role:null, /**
   * @property {String}
   */collectionType:null, /**
   * @property {String}
   */collectionTitle:String, /**
   * @property {Boolean}
   */isLoading:true, /**
   * @property {boolean} Shows if the question has score
   */hasScore:null, /**
   * @property {RubricCategoryScore[]} List of categories score
   */categoriesScore:null, //--------------------------------------------------------------------------
// Methods
/**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);}, /**
   * Function to animate the  pullup from top to bottom
   */closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);});}, /**
   * Function to load the  initial data
   */loadData:function loadData(){var component=this;var context=component.get('context');component.getRubricQuestionSummary(context).then(function(model){var questionResults=model.questionResult.resourceResults;var questionResult=questionResults?questionResults.findBy('resourceId',model.question.get('id')):null;component.set('questionText',model.question.get('description') || model.question.get('title'));component.set('questionThumbnail',model.question.get('thumbnail'));component.set('question',model.question);component.set('rubric',model.question.get('rubric'));component.set('questionSummary',model.summary);component.set('questionResult',questionResult);component.set('collectionType',model.context.collectionType);component.set('hasScore',component.getScore());component.set('categoriesScore',component.getCategoryScore());component.set('isLoading',false);});}, /**
   * Function to get score
   */getScore:function getScore(){var component=this;var maxScore=component.get('questionSummary.maxScore');return maxScore && maxScore !== 0;}, /**
   * Function to get categories score
   */getCategoryScore:function getCategoryScore(){var component=this;var categories=_ember['default'].A([]);if(component.get('questionSummary.categoriesScore.length')){categories = component.get('questionSummary.categoriesScore');}return categories;}, /**
   * Function to get rubric question summary
   */getRubricQuestionSummary:function getRubricQuestionSummary(params){var component=this;var context=component.getContext(params);var classId=params.classId;var courseId=params.courseId;var collectionId=params.collectionId;var questionId=params.questionId;var sessionId=params.sessionId;var studentId=params.studentId;var collectionType=params.collectionType;var questionPromise=component.get('questionService').readQuestion(questionId);var summaryPromise=courseId?component.get('rubricService').getRubricQuestionSummary(studentId,classId,courseId,collectionId,questionId,sessionId):component.get('rubricService').getRubricQuestionSummaryForDCA(studentId,classId,collectionId,questionId,sessionId);var isCollection=collectionType === _gooruWebConfigConfig.CONTENT_TYPES.COLLECTION;var session=!isCollection?sessionId:null;if(session){context.set('sessionId',session);}var performancePromise=component.get('performanceService').findAssessmentResultByCollectionAndStudent(context);return _ember['default'].RSVP.hash({question:questionPromise,context:context,summary:summaryPromise,sessionId:sessionId,questionResult:performancePromise});}, /**
   * Get the player context
   * @param params
   * @returns {Context}
   */getContext:function getContext(params){var collectionType=params.collectionType;var classId=params.classId;var courseId=params.courseId;var collectionId=params.collectionId;var userId=params.studentId;var unitId=params.unitId;var lessonId=params.lessonId;return _gooruWebModelsResultContext['default'].create({collectionType:collectionType,userId:userId,collectionId:collectionId,courseId:courseId,classId:classId,unitId:unitId,lessonId:lessonId});}});});