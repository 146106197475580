define('gooru-web/services/api-sdk/external-collection',['exports','ember','gooru-web/serializers/content/external-collection','gooru-web/adapters/content/external-collection'],function(exports,_ember,_gooruWebSerializersContentExternalCollection,_gooruWebAdaptersContentExternalCollection){ /**
 * @typedef {Object} ExternalCollectionService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {Profile} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {ExternalCollectionSerializer} ExternalCollectionSerializer
   */externalCollectionSerializer:null, /**
   * @property {ExternalCollectionAdapter} ExternalCollectionAdapter
   */externalCollectionAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('externalCollectionSerializer',_gooruWebSerializersContentExternalCollection['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('externalCollectionAdapter',_gooruWebAdaptersContentExternalCollection['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Creates a new collection
   *
   * @param collectionData object with the collection data
   * @returns {Promise}
   */createExternalCollection:function createExternalCollection(collectionData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedExternalCollectionData=service.get('externalCollectionSerializer').serializeCreateExternalCollection(collectionData);service.get('externalCollectionAdapter').createExternalCollection(serializedExternalCollectionData).then(function(responseData,textStatus,request){var createdCollectionData=_ember['default'].Object.create(collectionData);var collectionId=request.getResponseHeader('location');createdCollectionData.set('id',collectionId);resolve(createdCollectionData);},function(error){reject(error);});});}, /**
   * Updates a Collection
   *
   * @param collectionId the id of the Collection to be updated
   * @param collectionModel the Collection model with the data
   * @returns {Promise}
   */updateExternalCollection:function updateExternalCollection(collectionId,collectionModel){var service=this;var serializedData=service.get('externalCollectionSerializer').serializeUpdateCollection(collectionModel);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('externalCollectionAdapter').updateExternalCollection(collectionId,serializedData).then(function(){resolve();},reject);});}, /**
   * Gets an External Collection by id
   * @param {string} collectionId
   * @returns {Promise}
   */readExternalCollection:function readExternalCollection(collectionId){var isSetOwner=arguments.length <= 1 || arguments[1] === undefined?true:arguments[1];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('externalCollectionAdapter').readExternalCollection(collectionId).then(function(responseData){var collection=service.get('externalCollectionSerializer').normalizeReadCollection(responseData);if(isSetOwner){var profileService=service.get('profileService');profileService.readUserProfile(collection.get('ownerId')).then(function(profile){collection.set('owner',profile);resolve(collection);});}else {resolve(collection);}},reject);});}, /**
   * Delete collection
   *
   * @param collectionId The collection id to delete
   * @returns {Promise}
   */deleteExternalCollection:function deleteExternalCollection(collectionId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('externalCollectionAdapter').deleteExternalCollection(collectionId).then(function(){resolve();},reject);});}});});