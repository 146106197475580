define('gooru-web/services/api-sdk/offline-activity/oa-analytics',['exports','ember','gooru-web/serializers/offline-activity/offline-activity','gooru-web/adapters/offline-activity/oa-analytics'],function(exports,_ember,_gooruWebSerializersOfflineActivityOfflineActivity,_gooruWebAdaptersOfflineActivityOaAnalytics){exports['default'] = _ember['default'].Service.extend({ /**
   * @property {oaAnaltyicsAdapter} oaAnaltyicsAdapter
   */oaAnaltyicsAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('offlineActivitySerializer',_gooruWebSerializersOfflineActivityOfflineActivity['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('oaAnaltyicsAdapter',_gooruWebAdaptersOfflineActivityOaAnalytics['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Get the list of OA with the count of students, that the teacher needs to grade for a given class
   * @param {string} classId
   * @param {string} userId
   * @returns {Object}
   */getOAToGrade:function getOAToGrade(classId,userId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('oaAnaltyicsAdapter').getOAToGrade(classId,userId).then(function(payload){var oaGradeItems=service.get('offlineActivitySerializer').normalizeOAGradeItems(payload);resolve(oaGradeItems);},reject);});}, /**
   * Get the list of Students to-be graded for a given Offline Activity
   * @param {string} classId
   * @param {string} activityId
   * @returns {Object}
   */getStudentListToGrade:function getStudentListToGrade(classId,activityId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('oaAnaltyicsAdapter').getStudentListToGrade(classId,activityId).then(function(payload){var oaGradeItems=service.get('offlineActivitySerializer').normalizeStudentsForActivity(payload);resolve(oaGradeItems);},reject);});}, /**
   * Get the list of Students to-be graded for a given Offline Activity
   * @param {string} classId
   * @param {string} activityId
   * @returns {Object}
   */getSubmissionsToGrade:function getSubmissionsToGrade(classId,activityId,studentId){var dataParam=arguments.length <= 3 || arguments[3] === undefined?undefined:arguments[3];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('oaAnaltyicsAdapter').getSubmissionsToGrade(classId,activityId,studentId,dataParam).then(function(payload){var oaSubmitedData=service.get('offlineActivitySerializer').normalizeSubmissionGrade(payload);resolve(oaSubmitedData);},reject);});},submitOAGrade:function submitOAGrade(userGrade){var data=this.get('offlineActivitySerializer').serializeRubricGrades(userGrade);return this.get('oaAnaltyicsAdapter').submitOAGrade(data);}, /**
   * @function getOaCompletedStudents
   * @param {UUID} classId
   * @param {UUID} oaId
   * @param {UUID} itemId CA content ID
   */getOaCompletedStudents:function getOaCompletedStudents(classId,oaId){var itemId=arguments.length <= 2 || arguments[2] === undefined?undefined:arguments[2];var dataParam=arguments.length <= 3 || arguments[3] === undefined?undefined:arguments[3];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('oaAnaltyicsAdapter').getOaCompletedStudents(classId,oaId,itemId,dataParam).then(function(payload){resolve(service.get('offlineActivitySerializer').serializeOaCompletedStudents(payload));},reject);});}});});