define('gooru-web/components/new-cards/gru-resource-card',['exports','ember','gooru-web/mixins/modal','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebMixinsModal,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){ /**
 * Resource and Question card
 *
 * Component responsible of showing the resource or question information in cards, so that most useful information is summarized there.
 * @module
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // Dependencies
/**
   * @property {Service} session
   */session:_ember['default'].inject.service('session'), /**
   * @property {Service} profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), // -------------------------------------------------------------------------
// Attributes
classNames:['cards','gru-resource-card'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered to edit the resource/question
     */editResource:function editResource(){this.sendAction('onEditResource',this.get('resource'));}, /**
     * Action triggered to play the resource/question
     */playResource:function playResource(){this.sendAction('onPlayResource',this.get('resource'));}, /**
     * Action triggered to remix the question
     */remixQuestion:function remixQuestion(){if(this.get('session.isAnonymous')){this.send('showModal','content.modals.gru-login-prompt');}else {this.sendAction('onRemixQuestion',this.get('resource'));}},selectSingleBox:function selectSingleBox(content){this.sendAction('selectSingleBox',content);}, /**
     * Action triggered to add to collection
     */addToCollection:function addToCollection(){var component=this;if(component.get('session.isAnonymous')){component.send('showModal','content.modals.gru-login-prompt');}else {var assessmentsPromise=_ember['default'].RSVP.resolve(null);if(component.get('isQuestion')){assessmentsPromise = component.get('profileService').readAssessments(component.get('session.userId'));}assessmentsPromise.then(function(assessments){return component.get('profileService').readCollections(component.get('session.userId')).then(function(collections){return {content:component.get('resource'),collections:collections,assessments:assessments};});}).then(function(model){return component.send('showModal','content.modals.gru-add-to-collection',model,null,'add-to');});}}}, // -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Resource|Question} resource
   */resource:null, /**
   * Indicates if this resource is a question
   * @property {boolean}
   */isQuestion:_ember['default'].computed.equal('resource.format','question'), /**
   * Indicates if the edit functionality is enabled
   * @property {boolean}
   */editEnabled:false, /**
   * Indicates if the add functionality is enabled
   * @property {boolean}
   */addEnabled:true, /**
   * Indicates if the remix functionality is enabled
   * @property {boolean}
   */remixEnabled:_ember['default'].computed.not('editEnabled'), /**
   * @property {string} edit action
   */onEditResource:null, /**
   * @property {string} remix action
   */onRemixQuestion:null, /**
   * Indicates if the publish icon is visible
   * @property {boolean}
   */publishVisible:false, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('resource.standards.[]',function(){var standards=this.get('resource.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), /**
   * Show the publisher if the resource has publisher and is publish
   * @property {boolean}
   */showPublisher:_ember['default'].computed('resource',function(){return this.get('resource')?this.get('resource').isPublished && this.get('resource').publisher:false;}), /**
   * Indicates if it allow profile navigation or not in the cards
   * @property {boolean} allowProfileNavigation
   */allowProfileNavigation:true, /**
   * Indicates if the student logged in and search for resources
   * @property {boolean} isStudentSearch
   */isStudentSearch:_ember['default'].computed('profile.isStudent',function(){var isStudent=this.get('profile.isStudent');var isSearch=this.get('isSearch');if(isStudent && isSearch){return true;}return false;}), /**
   * Indicates if the teacher logged in and search for resources
   * @property {boolean} isTeacherSearch
   */isTeacherSearch:_ember['default'].computed('profile.isTeacher',function(){var isTeacher=this.get('profile.isTeacher');var isSearch=this.get('isSearch');if(isTeacher && isSearch){return true;}return false;}),isH5PContent:_ember['default'].computed('resource',function(){return this.get('resource.format') === 'h5p';})});});