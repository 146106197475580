define('gooru-web/mixins/content/protocol',['exports','ember'],function(exports,_ember){ /**
 * Properties in common for resource and rubric preview/player.
 *
 * @mixin
 */exports['default'] = _ember['default'].Mixin.create({ // -------------------------------------------------------------------------
// Properties
/**
   * @property {Resource} resource
   */resource:null, /**
   * The protocol the user is using to access the page (http or https)
   * @property {String}
   */currentProtocol:window.location.protocol, /**
   * The protocol for the resource url
   * @property {String}
   */resourceProtocol:_ember['default'].computed('resource.url',function(){var httpsPattern=/^(https:\/\/)/;return httpsPattern.test(this.get('resource.url'))?'https:':'http:';}), /**
   * Indicates if the current protocol matches the resource protocol
   * @property {boolean}
   */sameProtocol:_ember['default'].computed(function(){return this.get('currentProtocol') === this.get('resourceProtocol');})});});