define('gooru-web/utils/gooru-integration',['exports','ember'],function(exports,_ember){ /**
 * Utility methods to handle the integration with 3rd party app
 * @typedef {object} GooruIntegration
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {*} integration params
   */params:{}, /**
   * @property {string} application key
   */appKey:_ember['default'].computed.alias('params.appKey'), /**
   * @property {string} authentication token
   */token:_ember['default'].computed.alias('params.token'), /**
   * @property {string} application page
   */page:_ember['default'].computed.alias('params.page'), /**
   * Indicates if the app is valid
   * @property {boolean}
   */validAppKey:_ember['default'].computed('appKey',function(){return true; //TODO for now all keys are allowed
}), /**
   * @property {boolean}
   */classInfoPage:_ember['default'].computed.equal('page','class-info'), /**
   * @property {boolean}
   */teacherDataPage:_ember['default'].computed.equal('page','teacher-data'), /**
   * @property {boolean}
   */studentDataPage:_ember['default'].computed.equal('page','student-data'), /**
   * @property {boolean}
   */courseMapPage:_ember['default'].computed.equal('page','course-map'), /**
   * @property {boolean}
   */playerPage:_ember['default'].computed.equal('page','player'), /**
   * It returns the corresponding route params for a integration request
   * @property {*}
   */routeParams:_ember['default'].computed(function(){var params=['sign-in']; //TODO simplify this if statement
if(this.get('classInfoPage')){params = this.get('routeParamsForInfoPage');}else if(this.get('teacherDataPage')){params = this.get('routeParamsForTeacherDataPage');}else if(this.get('studentDataPage')){params = this.get('routeParamsForStudentDataPage');}else if(this.get('courseMapPage')){params = this.get('routeParamsForCourseMapPage');}else if(this.get('playerPage')){params = this.get('routeParamsForPlayerPage');}return params;}), /**
   * Returns the route params for the info page
   * @property {*}
   */routeParamsForInfoPage:_ember['default'].computed(function(){return ['class.info',this.get('params.classId')];}), /**
   * Returns the route params for the teacher data page
   * @property {*}
   */routeParamsForTeacherDataPage:_ember['default'].computed(function(){return ['class.analytics.performance.teacher.course',this.get('params.classId')];}), /**
   * Returns the route params for the student data page
   * @property {*}
   */routeParamsForStudentDataPage:_ember['default'].computed(function(){return ['class.analytics.performance.student',this.get('params.classId')];}), /**
   * Returns the route params for the player page
   * @property {*}
   */routeParamsForPlayerPage:_ember['default'].computed(function(){var queryParams={sourceId:this.get('params.sourceId'),type:this.get('params.collectionType'),role:'student'};return ['player',this.get('params.collectionId'),{queryParams:queryParams}];}), /**
   * Returns the route params for the course map page
   * @property {*}
   */routeParamsForCourseMapPage:_ember['default'].computed(function(){var unitId=this.get('params.unitId');var lessonId=this.get('params.lessonId');var collectionId=this.get('params.collectionId');var location=undefined;if(unitId){location = unitId;if(lessonId){location = unitId + '-' + lessonId;if(collectionId){location = unitId + '-' + lessonId + '-' + collectionId;}}}var params=location?['class.overview',this.get('params.classId'),{queryParams:{location:location}}]:['class.overview',this.get('params.classId')];return params;})});});