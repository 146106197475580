define('gooru-web/utils/tenant',['exports'],function(exports){exports.getTenantFwPrefBySubject = getTenantFwPrefBySubject;exports.getMinScoreByTenantFwSub = getMinScoreByTenantFwSub;exports.concatFwSub = concatFwSub;exports.getMasteryMinScore = getMasteryMinScore; /**
 * @function getTenantFwPrefBySubject
 * @param {String} assSubject
 * @param {Object} tenantFwPref
 * @return {Array}
 * Method to get an array of frameworks
 * which are mapped with given subject as tenant pref
 */function getTenantFwPrefBySubject(assSubject,tenantFwPref){var fwSubject=[];if(tenantFwPref){var subjectFramework=tenantFwPref[assSubject];fwSubject = subjectFramework?subjectFramework.fw_ids:[];}return fwSubject;} /**
 * @function getMinScoreByTenantFwSub
 * @param {String} fwSub
 * @param {Number | NULL} minScores
 * Method to get MinScore by matching tenant's subject framework otherwise null
 */function getMinScoreByTenantFwSub(fwSub,minScores){if(minScores && fwSub){return parseFloat(minScores[fwSub]);}return null;} /**
 * @function concatFwSub
 * @param {String} fw
 * @param {String} sub
 * @return {String}
 * Method to concat framework with subject
 */function concatFwSub(){var fw=arguments.length <= 0 || arguments[0] === undefined?'':arguments[0];var sub=arguments.length <= 1 || arguments[1] === undefined?'':arguments[1];return fw + '.' + sub;} /**
 * @function getMasteryMinScore
 * @param {String} assSubject
 * @param {Object} classPreference
 * @param {Object} tenantSetting
 * @return {Number} masteryMinScore
 * Method to get mastery min score by matching class preferency if any,
 * otherwise look into tenant's subject framework preferences
 */function getMasteryMinScore(assSubject,classPreference,tenantSetting){if(classPreference === undefined)classPreference = {};var frameworks=[];var tenantSubFwPref=tenantSetting.tw_fw_pref;if(classPreference && classPreference.subject && classPreference.framework){if(classPreference.subject === assSubject && classPreference.framework){frameworks = [classPreference.framework];}else {frameworks = getTenantFwPrefBySubject(assSubject,tenantSubFwPref);}}else if(tenantSubFwPref){frameworks = getTenantFwPrefBySubject(assSubject,tenantSubFwPref);}var minScore=tenantSetting.competency_completion_default_min_score || 0;var competencyCompletionMinScores=tenantSetting.competency_completion_min_score;frameworks.forEach(function(framework){var masteryMinScore=getMinScoreByTenantFwSub(concatFwSub(framework,assSubject),competencyCompletionMinScores);if(masteryMinScore){minScore = masteryMinScore;}});return parseFloat(minScore);}});