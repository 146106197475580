define('gooru-web/components/cards/gru-timepicker-card',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['cards','gru-timepicker-card'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){this._super.apply(this,arguments);this.numberValidation();}, // -------------------------------------------------------------------------
// Actions
actions:{ //Action triggered when increment hour
onIncrementHour:function onIncrementHour(){var component=this;var hour=component.get('hour');var maxHour=component.get('maxHour');var minute=component.get('minute');if(hour === maxHour){hour = 0;minute = 0;}else {hour++;}component.set('hour',hour);component.set('minute',minute);}, //Action triggered when decrement hour
onDecrementHour:function onDecrementHour(){var component=this;var hour=component.get('hour');var maxHour=component.get('maxHour');var minute=component.get('minute');hour = hour === 0?maxHour:hour - 1;minute = hour === maxHour?0:minute;component.set('hour',hour);component.set('minute',minute);}, //Action triggered when increment minute
onIncrementMinute:function onIncrementMinute(){var component=this;var minute=component.get('minute');var maxMinute=component.get('maxMinute');var hour=component.get('hour');var maxHour=component.get('maxHour');if(minute === maxMinute){minute = 0;hour = hour === maxHour?0:hour + 1;}else {hour = hour === maxHour?0:hour;minute++;}component.set('minute',minute);component.set('hour',hour);}, //Action triggered when decrement minute
onDecrementMinute:function onDecrementMinute(){var component=this;var minute=component.get('minute');var maxMinute=component.get('maxMinute');var hour=component.get('hour');minute = minute === 0?maxMinute:minute - 1;hour = minute === maxMinute && hour > 0?hour - 1:hour;component.set('minute',minute);component.set('hour',hour);}}, // -------------------------------------------------------------------------
// Function
/**
   * @function numberValidation
   * Method to only accept number
   */numberValidation:function numberValidation(){var component=this;component.$('.time-value').keypress(function(event){ // 0 means key without character input, 8 is backspace, 48-57 are numbers
var keyCode=typeof event.which === 'number'?event.which:event.keyCode;return keyCode === 0 || keyCode === 8 || keyCode >= 48 && keyCode <= 57;});}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Number} maxHour
   */maxHour:24, /**
   * @property {Number} maxMinute
   */maxMinute:59, /**
   * @property {Number} hour
   */hour:0, /**
   * @property {Number} minute
   */minute:0});});