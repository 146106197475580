define('gooru-web/components/student/independent/gru-independent-learning-courses',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {LearnerService} Service to retrieve learner information
   */learnerService:_ember['default'].inject.service('api-sdk/learner'), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'),actions:{ /**
    * toggle show more/less
    */togglePanel:function togglePanel(){this.set('isExpanded',!this.get('isExpanded'));},showMoreResults:function showMoreResults(){var _this=this;this.set('isLoading',true);var pageSize=this.get('PAGE_SIZE');var userId=this.get('session.userId');var contentType=this.get('contentType');var offset=this.get('OFFSET') + pageSize;var locations=this.get('locations');var performance=this.get('performance');return _ember['default'].RSVP.hash({locations:this.get('learnerService').fetchLocations(userId,contentType,offset,pageSize),performance:this.get('learnerService').fetchPerformance(userId,contentType,offset,pageSize)}).then(function(hash){_this.set('OFFSET',offset);_this.set('locations',locations.concat(hash.locations));_this.set('CURRENT_ITERATION_SIZE',hash.locations.length);_this.set('performance',performance.concat(hash.performance));});}}, // -------------------------------------------------------------------------
// Attributes
/**
   * @property {Number} number of items to load per page
   */PAGE_SIZE:8, /**
   * @property {Number} number of items loaded in this iteration
   */CURRENT_ITERATION_SIZE:0, /**
   * @property {Number} number of items to show in a row
   */ROW_SIZE:4, /**
   * @property {Number} number of offset to get items
   */OFFSET:0, /**
   * @property {String} the content type
   */contentType:_gooruWebConfigConfig.CONTENT_TYPES.COURSE, /**
   * @property {Location} location information
   */locations:null, /**
   * @property {Performance} performance information
   */performance:null, /**
   * @property {CourseItems} courseItems list to show in the courses section
   */courseItems:null, /**
  * @property {Boolean} isExpanded panel
  */isExpanded:false, /**
  * @property {Boolean} isLoading data from the API
  */isLoading:true, // -------------------------------------------------------------------------
// Events
init:function init(){var _this2=this;this._super.apply(this,arguments);this.set('isLoading',true);var pageSize=this.get('PAGE_SIZE');var userId=this.get('session.userId');var contentType=this.get('contentType');var offset=this.get('OFFSET');return _ember['default'].RSVP.hash({locations:this.get('learnerService').fetchLocations(userId,contentType,offset,pageSize),performance:this.get('learnerService').fetchPerformance(userId,contentType,offset,pageSize)}).then(function(hash){_this2.set('locations',hash.locations);_this2.set('CURRENT_ITERATION_SIZE',hash.locations.length);_this2.set('performance',hash.performance);});}, // -------------------------------------------------------------------------
// Methods
courseInfo:_ember['default'].observer('performance',function(){var locations=this.get('locations');var performance=this.get('performance');var performanceMap=performance.reduce(function(result,perfItem){result[perfItem.courseId || perfItem.collectionId] = perfItem;return result;},{});var courseData=locations.map(function(location){return {location:location,performance:performanceMap[location.courseId || location.collectionId]};});this.set('courseItems',courseData);this.set('isLoading',false);}),isShowMoreVisible:_ember['default'].computed('locations',function(){return this.get('locations.length') && this.get('CURRENT_ITERATION_SIZE') >= this.get('PAGE_SIZE');}),showMoreToggle:_ember['default'].computed('locations',function(){return this.get('locations.length') >= this.get('ROW_SIZE');})});});