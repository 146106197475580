define('gooru-web/services/api-sdk/course-map',['exports','ember','gooru-web/serializers/map/course-map','gooru-web/adapters/map/course-map'],function(exports,_ember,_gooruWebSerializersMapCourseMap,_gooruWebAdaptersMapCourseMap){ /**
 * @typedef {Object} CourseMapService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {CourseMapSerializer} courseMapSerializer
   */courseMapSerializer:null, /**
   * @property {CourseMapAdapter} courseMapAdapter
   */courseMapAdapter:null,init:function init(){this._super.apply(this,arguments);this.set('courseMapSerializer',_gooruWebSerializersMapCourseMap['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('courseMapAdapter',_gooruWebAdaptersMapCourseMap['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Gets the lesson infor for course map
   * @param {string} courseId - course the lesson belongs to
   * @param {string} unitId - unit the lesson belongs to
   * @param {string} lessonId - lesson ID to search for
   * @returns {Promise}
   */getLessonInfo:function getLessonInfo(classId,courseId,unitId,lessonId,isTeacher,userId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('courseMapAdapter').getLessonInfo(classId,courseId,unitId,lessonId,userId).then(function(response){return resolve(service.get('courseMapSerializer').normalizeLessonInfo(response,isTeacher));},reject);});}, /**
   * Gets the course infor for teacher class management -> course map
   * @param {string} classId - course the belongs to
   * @param {string} courseId - unit the belongs to
   * @returns {Promise}
   */getCourseInfo:function getCourseInfo(classId,courseId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('courseMapAdapter').getCourseInfo(classId,courseId).then(function(response){return resolve(service.get('courseMapSerializer').normalizeCourseInfo(response));},reject);});}, /**
   * Gets the course infor for teacher class management -> course map
   * @param {string} classId - course the belongs to
   * @param {string} courseId - unit the belongs to
   * @returns {Promise}
   */findClassPerformanceByStudentIdUnitLevel:function findClassPerformanceByStudentIdUnitLevel(classId,courseId,students){var options=arguments.length <= 3 || arguments[3] === undefined?{collectionType:'assessment'}:arguments[3];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('courseMapAdapter').findClassPerformanceByStudentIdUnitLevel(classId,courseId,students,options).then(function(response){return resolve(service.get('courseMapSerializer').normalizeClassPerformanceByStudentId(response));},reject);});}, /**
   * Creates a New Path based on the Context data.
   * @param {MapContext} context - is the context where the suggestion was presented
   * @param {MapSuggestion} suggestion - the suggestion. The suggested path
   * @returns {Ember.RSVP.Promise}
   */createNewPath:function createNewPath(context,suggestion){return this.get('courseMapAdapter').createNewPath(context,suggestion);}, /**
   * Creates a suggested Path based on the Context data.
   * @param {MapContext} context - is the context where the suggestion was presented
   * @param {MapSuggestion} suggestion - the suggestion. The suggested path
   * @returns {Ember.RSVP.Promise}
   */addSuggestedPath:function addSuggestedPath(context,suggestion){return this.get('courseMapAdapter').addSuggestedPath(context,suggestion);}, /**
   * Help to fetch milestone alternate path for learning journey
   * @param {String} milestoneId selected milestone idea
   * @param {Object} params has classId and userId(optional)
   */fetchMilestoneAlternatePath:function fetchMilestoneAlternatePath(milestoneId,params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('courseMapAdapter').fetchMilestoneAlternatePath(milestoneId,params).then(function(response){resolve(service.get('courseMapSerializer').normalizeMilestoneAlternatePath(response));},function(){resolve(service.get('courseMapSerializer').normalizeMilestoneAlternatePath({}));});});}, /**
   * Help to fetch course map alternate path for learning journey
   * @param {String} courseId selected courseid
   * @param {Object} params has classId and userId(optional)
   */fetchCourseMapAlternatePath:function fetchCourseMapAlternatePath(courseId,params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('courseMapAdapter').fetchCourseMapAlternatePath(courseId,params).then(function(response){resolve(service.get('courseMapSerializer').normalizeCourseAlternatePath(response));},function(){resolve({});});});}, /**
   * @function fetchMilstoneDependentPaths
   * milestoneId
   */fetchMilestoneDependentPath:function fetchMilestoneDependentPath(milestoneId,params){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('courseMapAdapter').fetchMilestoneDependentPath(milestoneId,params).then(function(response){resolve(service.get('courseMapSerializer').normalizeDependentPath(response));},function(){resolve(service.get('courseMapSerializer').normalizeDependentPath({}));});});}});});