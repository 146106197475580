define('gooru-web/adapters/performance/course-competency-completion',['exports','ember','gooru-web/adapters/application'],function(exports,_ember,_gooruWebAdaptersApplication){ /**
 * Adapter CourseCompetencyCompletion
 *
 * @typedef {Object} CourseCompetencyCompletionAdapter
 */exports['default'] = _gooruWebAdaptersApplication['default'].extend({ /**
   * @property {String} End-point URI
   */namespace:'/api/nucleus-insights/v3', /**
   * Built the REST API Adapter to fetch the Course competency completion data
   * @param  {String} studentId   Logged in student id
   * @param  {String} courseIds Course unique id's
   * @return {Promise} Promise object which carries the Course competency completion ajax
   */findCourseCompetencyCompletionByCourseIds:function findCourseCompetencyCompletionByCourseIds(studentId,courseIds){var namespace=this.get('namespace');var url=namespace + '/courses/competency-completion?userId=' + studentId;var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'json',processData:false,headers:this.defineHeaders(),data:JSON.stringify({courseIds:courseIds})};return new _ember['default'].RSVP.Promise(function(resolve,reject){_ember['default'].$.ajax(url,options).then(function(responseData){resolve(responseData);},reject);});},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});