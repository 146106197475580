define('gooru-web/routes/sign-in',['exports','ember','gooru-web/mixins/public-route-mixin','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsPublicRouteMixin,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPublicRouteMixin['default'],_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * The session service.
   * @property session
   * @readOnly
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), /**
   * @requires service:authentication
   */authenticationService:_ember['default'].inject.service('api-sdk/authentication'), /**
   * @requires service:tenantService
   */tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Properties
/**
   * Maintains the state of anonymous session data.
   * @type {Session}
   */anonymousSessionData:null, // -------------------------------------------------------------------------
// Methods
model:function model(params){return params;},beforeModel:function beforeModel(transition){var route=this;if(!route.get('session.isAnonymous')){window.triggerRelaunchCastEvent = true;return route.transitionTo('index');}else {var nonce=transition.queryParams.nonce;var authenticationService=this.get('authenticationService');return authenticationService.authenticateAsAnonymous(nonce).then(function(data){route.set('anonymousSessionData',data);return route.get('session').authenticateAsAnonymousWithData(data).then(function(){var applicationController=route.controllerFor('application');return _ember['default'].RSVP.all([applicationController.loadTranslationLabels()]);});});}}, /**
   * Set all controller properties used in the template
   * @param controller
   * @param model
   */setupController:function setupController(controller,model){ // remove old notifications
this.get('notifications').remove();controller.set('redirectURL',model.redirectURL);controller.set('anonymousSessionData',this.get('anonymousSessionData'));controller.resetProperties();this.handleRedirectionBasedOnDomain(controller);}, /**
   * Verfiy the domain have any directions before model get execute.
   */handleRedirectionBasedOnDomain:function handleRedirectionBasedOnDomain(controller){var endpoint=this.get('redirectEndpoint') || window.location.href;var domainURL=new URL(endpoint);var domain=domainURL.hostname;var redirectURL=this.get('controller').get('redirectURL');this.get('authenticationService').domainBasedRedirection(domain,redirectURL).then(function(data){if(data && data.statusCode === 303){window.location.href = data.redirectUrl;}else {controller.set('isRedirectionDomainDone',true);}});},actions:{signUpFinish:function signUpFinish(){this.transitionTo('sign-up-finish');}}});});