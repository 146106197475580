define('gooru-web/controllers/teacher/class/atc',['exports','ember','gooru-web/mixins/language'],function(exports,_ember,_gooruWebMixinsLanguage){exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsLanguage['default'],{queryParams:['activeReport'], // -------------------------------------------------------------------------
// Dependencies
/**
   * Analytics Service
   */analyticsService:_ember['default'].inject.service('api-sdk/analytics'), /**
   * Class Service
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * Session Service
   */session:_ember['default'].inject.service('session'), /**
   * Class Controller
   */classController:_ember['default'].inject.controller('teacher.class'), /**
   * Class Activities Service
   */classActivitiesService:_ember['default'].inject.service('api-sdk/class-activity'), /**
   * Competency Service
   */competencyService:_ember['default'].inject.service('api-sdk/competency'), // -------------------------------------------------------------------------
// Properties
/**
   * A link to the parent class controller
   * @see controllers/class.js
   * @property {Class}
   */'class':_ember['default'].computed.alias('classController.class'), /**
   * @property {Object} course
   */course:_ember['default'].computed.alias('class.course'), /**
   * @property {UUID} classId
   */classId:_ember['default'].computed.alias('class.id'), /**
   * @property {UUID} courseId
   */courseId:_ember['default'].computed.alias('course.id'), /**
   * @property {Array} students
   */students:_ember['default'].computed.alias('class.members'), /**
   * @property {String} subjectCode
   */subjectCode:_ember['default'].computed.alias('course.subject'), /**
   * @type {Boolean}
   * Property to check whether a class is premium
   */isPremiumClass:_ember['default'].computed('class',function(){var controller=this;var currentClass=controller.get('class');var setting=currentClass.get('setting');return setting?setting['course.premium']:false;}), /**
   * @property {Boolean} isShowCompetencyCompletionReport
   */isShowCompetencyCompletionReport:false, /**
   * Maintains the value which of month activities displaying
   * @type {Integer}
   */activeMonth:_ember['default'].computed(function(){return moment().format('MM');}), /**
   * @property {Boolean} isCurrentMonth
   */isCurrentMonth:_ember['default'].computed('activeMonth',function(){var controller=this;var activeMonth=controller.get('activeMonth');var currentMonth=moment().format('MM');return activeMonth === currentMonth;}), /**
   * Maintains the value which of year activities displaying
   * @type {Integer}
   */activeYear:_ember['default'].computed(function(){return moment().format('YYYY');}), /**
   * @property {String} userAgent
   */userAgent:'desktop', /**
   * @property {Boolean} isExpandedView
   */isExpandedView:false, /**
   * @property {Boolean} isShowAtcView
   */isShowAtcView:_ember['default'].computed('students','courseId',function(){var controller=this;var isStudentsAvailable=controller.get('students.length');var isCourseMapped=controller.get('courseId');return isStudentsAvailable && isCourseMapped;}), /**
   * @property {Boolean} isShowActivitySearchContentPullup
   * Property to show/hide activity search content pullup
   */isShowActivitySearchContentPullup:false, /**
   * @property {String} defaultContentTypeToSuggest
   * Property to hold default content suggstion
   */defaultContentTypeToSuggest:'collection', /**
   * @property {Array} selectedStudents
   * Property to hold list of students to suggest
   */selectedStudents:_ember['default'].A([]), /**
   * @property {Json} classPreference
   */classPreference:_ember['default'].computed.alias('class.preference'), /**
   * @property {Number} maxLimitToSuggestContent
   */maxLimitToSuggestContent:6, /**
   * @property {Array} fwCompetencies
   */fwCompetencies:_ember['default'].computed.alias('classController.fwCompetencies'), /**
   * @property {Array} fwDomains
   */fwDomains:_ember['default'].computed.alias('classController.fwDomains'),secondaryClasses:_ember['default'].computed.alias('classController.secondaryClasses'),secondaryClassToBeShownList:_ember['default'].computed('secondaryClasses.@each.id',function(){var controller=this;var secondaryClasses=controller.get('secondaryClasses');return secondaryClasses?_ember['default'].copy(secondaryClasses):_ember['default'].A([]);}),selectedSecondary:null,watchSecondaryClass:_ember['default'].observer('selectedSecondary',function(){this.set('isShowSecondaryClass',false);if(this.get('selectedSecondary') && !this.get('selectedSecondary.isPrimaryClass')){this.set('isShowSecondaryClass',true);}}),isMultiClassEnabled:_ember['default'].computed.alias('classController.isMultiClassEnabled'),isShowSecondaryClass:false,isReportView:false,isMilestoneReport:true,classFramework:_ember['default'].computed.alias('classController.classFramework'),isDefaultShowFW:_ember['default'].computed.alias('classController.isDefaultShowFW'), // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Actions
actions:{onSelectSecondaryClass:function onSelectSecondaryClass(secondaryClass){this.get('classController').send('onSelectSecondaryClass',secondaryClass);},onRemoveClassView:function onRemoveClassView(secondaryClass){var controller=this;controller.get('selectedSecondaryClassList').removeObject(secondaryClass);controller.get('secondaryClassToBeShownList').pushObject(secondaryClass);},onGoBack:function onGoBack(){this.set('isReportView',false);this.set('class.isDisable',false);},onGoDataDashboard:function onGoDataDashboard(page){this.set('isReportView',true);this.set('class.isDisable',true);this.set('isMilestoneReport',page === 'milestone');}},changeLanguage:function changeLanguage(){var controller=this;var classes=controller.get('class');controller.changeLanguages(classes);} // -------------------------------------------------------------------------
// Functions
});});