define('gooru-web/models/rubric/rubric-category',['exports','ember','ember-cp-validations'],function(exports,_ember,_emberCpValidations){function _toConsumableArray(arr){if(Array.isArray(arr)){for(var i=0,arr2=Array(arr.length);i < arr.length;i++) arr2[i] = arr[i];return arr2;}else {return Array.from(arr);}}var Validations=(0,_emberCpValidations.buildValidations)({title:{validators:[(0,_emberCpValidations.validator)('presence',{presence:true,message:'{{description}}',descriptionKey:'common.errors.category-title-presence'})]}}); /**
 * Rubric Category model
 *
 * @typedef {Object} RubricCategory
 */exports['default'] = _ember['default'].Object.extend(Validations,{ /**
   *Init the scoring levels default on each category
   */initLevels:function initLevels(){this.set('levels',_ember['default'].A([{name:'',score:null},{name:'',score:null},{name:'',score:null},{name:'',score:null},{name:'',score:null}]));return this;}, /**
   * @property {String} id
   */id:null, /**
   * @property {String} title
   */title:'', /**
   * @property {String} feedbackGuidance
   */feedbackGuidance:'', /**
   * @property {boolean} requiresFeedback
   */requiresFeedback:true, /**
   * @property {boolean} allowScoring
   */allowsScoring:false, /**
   * @property {boolean} allowLevel
   */allowsLevels:false, /**
   * @property {Ember.A} levels { name: string, score: number }
   */levels:_ember['default'].A([]), /**
   * @property {Boolean} isNew
   */isNew:false, /**
   * @property {Array} level scores
   */scores:_ember['default'].computed.mapBy('levels','score'), /**
   * @property {number} total points
   */totalPoints:_ember['default'].computed('scores',function(){return Math.max.apply(Math,[0].concat(_toConsumableArray(this.get('scores'))));}), /**
   * Return a copy of the category
   *
   * @function
   * @return {Category}
   */copy:function copy(){var properties=this.getProperties(this.modelProperties()); // Copy array values
properties.levels = this.get('levels').map(function(level){return _ember['default'].Object.create({name:level.name,score:level.score});});return this.get('constructor').create(_ember['default'].getOwner(this).ownerInjection(),properties);}, /**
   * Copy a list of property values from another model to override the current ones
   *
   * @function
   * @param {Category} model
   * @param {String[]} propertyList
   * @return {null}
   */merge:function merge(model){var propertyList=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var properties=model.getProperties(propertyList);this.setProperties(properties);}, /**
   * Return a list of properties
   *
   * @function
   * @return {Array}
   */modelProperties:function modelProperties(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key));if(value === 'string' || value === 'number' || value === 'boolean'){properties.push(key);}}return properties;}});});