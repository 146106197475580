define('gooru-web/components/content/etl/ps-etl-second',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['content','etl','ps-etl-second'],tenantService:_ember['default'].inject.service('api-sdk/tenant'),tagName:'article',isShowErrorMessage:false,estimatedTime:_ember['default'].computed('estimatedMinutes','estimatedHours',function(){var estimatedMinutes=this.get('estimatedMinutes');var estimatedHours=this.get('estimatedHours');if(estimatedMinutes || estimatedHours){return !(estimatedHours === '0 hr' && estimatedMinutes === '0 min');}}), // isShowFluency: Ember.computed(function() {
//   let tenantSettings = JSON.parse(
//     this.get('tenantService').getStoredTenantSetting()
//   );
//   return tenantSettings && tenantSettings.fluency_level === 'on';
// }),
// fluenciesData: Ember.computed('fluencyData', function() {
//   return this.get('fluencyData.fluency');
// }),
actions:{ // setFluency(item) {
//   const component = this;
//   component.sendAction('selectedFluency', item);
// }
}, // -------------------------------------------------------------------------
// Properties
init:function init(){this._super.apply(this,arguments);},didInsertElement:function didInsertElement(){var component=this;component._super.apply(component,arguments);var editorClass='.etl-seconds .etl-label .title-label input';var tempData=component.get('tempData');var valueHours=component.get('valueHours');var valueMins=component.get('valueMins');component.$().on('focus',editorClass,function(){var hrs=$('input[name=' + valueHours + ']');var mins=$('input[name=' + valueMins + ']');(0,_gooruWebUtilsUtils.etlFocus)(hrs,mins);});component.$().on('focusout',editorClass,function(){var hrs=$('input[name=' + valueHours + ']');var mins=$('input[name=' + valueMins + ']');(0,_gooruWebUtilsUtils.etlFocusOut)(hrs,mins,tempData);var isErrorMessage=tempData.get('isErrorMessage');component.set('isShowErrorMessage',isErrorMessage);});component.$().on('keypress',editorClass,function(evt){evt = evt?evt:window.event;var charCode=evt.which?evt.which:evt.keyCode;if(charCode > 31 && (charCode < 48 || charCode > 57)){return false;}return true;});}, /**
   * willDestroyElement events
   */willDestroyElement:function willDestroyElement(){var component=this;var editorClass='.duration-hours .etl-seconds .etl-label .title-label input';component.$().off('focusout',editorClass);component.$().off('focus',editorClass);component.$().off('keypress',editorClass);}});});