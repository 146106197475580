define('gooru-web/components/student/class/dashboard/learner-details',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // ----------------------------------------------------
// Attributes
classNames:['learner-details'] // ---------------------------------------------------------
// Dependencies
// -------------------------------------------------------
// Properties
// ------------------------------------------------------
// Hooks
// -----------------------------------------------------
// Actions
// --------------------------------------------------------
// Methods
});});