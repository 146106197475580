define('gooru-web/components/teacher/class-performance-chart',['exports','ember','d3'],function(exports,_ember,_d3){ /**
 * time Spent activities chart
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend({ //------------------------------------------------------------------------
//Dependencies
i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['class-performance-chart'], // -------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var $component=this.$(); // Get the component dimensions from the css
this.setProperties({height:parseInt($component.height()),width:parseInt($component.width()),radius:95});this.drawchart();}, /**
   * Observer to watch data changes
   */dataObserver:_ember['default'].observer('data',function(){_d3['default'].select('svg.performance-chart').remove();this.drawchart();}), // -------------------------------------------------------------------------
// Properties
/**
   * @property {Number} width
   */width:null, /**
   * @property {Number} height
   */height:null, /**
   * Data of courses and independent journeys count
   * @return {Array}
   */data:_ember['default'].A([]), // -------------------------------------------------------------------------
// Methods
/**
   * @function drawchart
   * Method to draw donut chart based on the class performance
   */drawchart:function drawchart(){var component=this;var width=component.get('width');var height=component.get('height');var data=component.get('data');var performanceColor=component.get('performanceColor');var svg=_d3['default'].select(component.element).append('svg').attr('class','pie').attr('class','performance-chart').attr('width',width).attr('height',height);var g=svg.append('g').attr('transform','translate(' + width / 2 + ',' + height / 2 + ')');var text=g.append('svg:foreignObject').attr('width',100).attr('height',100).attr('x',-50).attr('y',-50);text.append('xhtml:div').style('background-color',performanceColor).attr('class','performance-score').text(data[0].score + '%');}});});