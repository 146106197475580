define('gooru-web/components/content/assessments/gru-assessment-edit',['exports','ember','gooru-web/components/content/collections/gru-collection-edit','gooru-web/config/config','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/mixins/tenant-settings-mixin','gooru-web/utils/taxonomy','gooru-web/utils/utils','gooru-web/config/parse-event','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebComponentsContentCollectionsGruCollectionEdit,_gooruWebConfigConfig,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebMixinsTenantSettingsMixin,_gooruWebUtilsTaxonomy,_gooruWebUtilsUtils,_gooruWebConfigParseEvent,_gooruWebMixinsConfiguration){exports['default'] = _gooruWebComponentsContentCollectionsGruCollectionEdit['default'].extend(_gooruWebMixinsTenantSettingsMixin['default'],_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/course
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'),fluencyService:_ember['default'].inject.service('api-sdk/fluency'),tenantService:_ember['default'].inject.service('api-sdk/tenant'),parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'), /**
   * Collection model as instantiated by the route. This is the model used when not editing
   * or after any collection changes have been saved.
   * @property {Collection}
   */collection:null,editingSubContent:null,isShowFluency:false,isShowCompetencyNotTagged:false,aggregatedTags:_ember['default'].computed('tempCollection.aggregatedTag.[]',function(){var aggregatedTags=_gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('tempCollection.aggregatedTag'),false,false,true);return aggregatedTags;}),isCheckFluency:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());return tenantSettings && tenantSettings.fluency_level === 'on';}),isShowDiagnosticBtn:_ember['default'].computed('tempCollection.children',function(){return !this.get('tempCollection.children').length;}), /**
   * Toggle Options
   * @property {Ember.Array}
   */switchOptions:_ember['default'].A([_ember['default'].Object.create({label:'common.yes',value:true}),_ember['default'].Object.create({label:'common.no',value:false})]),isShowFacets:_ember['default'].computed(function(){return this.get('isShowFacetsWidget');}), // -------------------------------------------------------------------------
// Attributes
classNames:['content','assessments','gru-assessment-edit'], // -------------------------------------------------------------------------
// Actions
actions:{selectCategory:function selectCategory(category){var component=this;if(category === component.get('selectedCategory')){component.set('selectedCategory',null);}else {component.set('selectedCategory',category);}component.set('selectedSubject',null);component.set('tempCollection.metadata.fluency',null);component.set('isShowFluency',false);}, /**
     * Save Content
     */updateContent:function updateContent(){var component=this;var editedAssessment=component.get('tempCollection');var assessment=component.get('collection');var etlHrs=editedAssessment.get('hours');var etlMins=editedAssessment.get('minutes');(0,_gooruWebUtilsUtils.etlSecCalculation)(editedAssessment,etlHrs,etlMins);editedAssessment.validate().then(function(_ref){var validations=_ref.validations;if(validations.get('isValid')){var imageIdPromise=new _ember['default'].RSVP.resolve(editedAssessment.get('thumbnailUrl'));if(editedAssessment.get('thumbnailUrl') && editedAssessment.get('thumbnailUrl') !== assessment.get('thumbnailUrl')){imageIdPromise = component.get('mediaService').uploadContentFile(editedAssessment.get('thumbnailUrl'));}imageIdPromise.then(function(imageId){editedAssessment.set('thumbnailUrl',imageId);component.get('assessmentService').updateAssessment(editedAssessment.get('id'),editedAssessment).then(function(){assessment.merge(editedAssessment,['title','learningObjectives','isVisibleOnProfile','thumbnailUrl','standards','audience','depthOfknowledge','centurySkills','hours','minutes','primaryLanguage','metadata']);component.set('isEditing',false);component.get('tempCollection.standards').forEach(function(suggeststanObj){suggeststanObj.set('isRemovable',false);});if(component.get('tempCollection.metadata.fluency')){var fluData=_ember['default'].Object.create({});fluData.fluency = assessment.metadata.fluency;component.get('fluencyService').updateFluencyLevel(fluData,'assessments',assessment.id);}component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.SAVE_ASSESSMENT);})['catch'](function(error){var message=component.get('i18n').t('common.errors.assessment-not-updated').string;component.get('notifications').error(message);_ember['default'].Logger.error(error);});});}component.set('didValidate',true);});}, /**
     * Add tag data from the taxonomy list in tempUnit
     */addTag:function addTag(taxonomyTag){ // let tagData = taxonomyTag;
taxonomyTag.set('isRemovable',true);taxonomyTag.set('tagAlreadyexist',false);this.get('tempCollection.standards').addObject(taxonomyTag);this.set('tempCollection.standards',this.get('tempCollection.standards').uniqBy('code'));this.get('tempCollection.aggregatedTag').removeObject(taxonomyTag);var newtaxonomyObj=_ember['default'].Object.create({code:taxonomyTag.get('code'),frameworkCode:taxonomyTag.get('frameworkCode'),isRemovable:false,tagAlreadyexist:false});this.get('tempCollection.aggregatedTag').addObject(newtaxonomyObj);this.compareAggregatedTags();}, /**
     * Remove tag data from the taxonomy list in tempUnit
     */removeTag:function removeTag(taxonomyTag){var tagData=taxonomyTag;this.get('tempCollection.standards').removeObject(tagData);tagData.set('isRemovable',false);tagData.set('tagAlreadyexist',false);this.get('tempCollection.aggregatedTag').addObject(tagData);this.set('tempCollection.aggregatedTag',this.get('tempCollection.aggregatedTag').uniqBy('code'));this.compareAggregatedTags();}, /**
     * Save setting for visibility of collection in profile
     */publishToProfile:function publishToProfile(){var collectionForEditing=this.get('collection').copy();this.set('tempCollection',collectionForEditing);this.actions.updateContent.call(this);}, /**
     * @type {String} the selected category
     */selectedCategory:_ember['default'].computed('collection',function(){var standard=this.get('collection.standards.firstObject');var subjectId=standard?(0,_gooruWebUtilsTaxonomy.getSubjectId)(standard.get('id')):null;return subjectId?(0,_gooruWebUtilsTaxonomy.getCategoryCodeFromSubjectId)(subjectId):null;}),selectedSubject:_ember['default'].computed('collection',function(){var standard=this.get('collection.standards.firstObject');if(standard){standard.set('subjectCode',(0,_gooruWebUtilsTaxonomy.getGutCodeFromSubjectId)((0,_gooruWebUtilsTaxonomy.getSubjectId)(standard.get('id'))));}return standard?standard:null;}),selectSubject:function selectSubject(subject){this.set('selectedSubject',subject);this.set('isShowFluency',true);var subCode=(0,_gooruWebUtilsTaxonomy.getGutCodeFromSubjectId)(subject.get('id'));var fluData={subject_code:subCode,fw_id:subject.frameworkId,tenant_id:this.get('session.tenantId')};this.getFluenciesData(fluData);},selectedFluency:function selectedFluency(fluencyData){this.set('tempCollection.metadata.fluency',fluencyData);}, /**
     * Delete assessment
     */deleteItem:function deleteItem(){var myId=this.get('session.userId');var model={content:this.get('collection'),isHeaderDelete:true,parentName:this.get('course.title'),deleteMethod:(function(){return this.get('assessmentService').deleteAssessment(this.get('collection'));}).bind(this),type:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT,redirect:{route:'library-search',params:{profileId:myId,type:'my-content'}}};this.actions.showModal.call(this,'content.modals.gru-delete-content',model,null,null,null,false);}, //Action triggered when click back in the edit page
onClickBack:function onClickBack(){var isPreview=this.get('isPreviewReferrer');var router=this.get('router');var isShowFacets=this.get('isShowFacets');var previewRoute=isShowFacets?'facets-search':'library-search';var myId=this.get('session.userId');var queryParams={profileId:myId,type:'my-content',activeContentType:'assessment'};if(isPreview){router.transitionTo(previewRoute,{queryParams:queryParams});}else {window.history.back();}}, /**
     * Cancel edit content
     */cancelEdit:function cancelEdit(){var assessmentForCancelEditing=this.get('collection').copy();this.set('isEditing',false);this.set('tempCollection',assessmentForCancelEditing);},onDiagnosticChange:function onDiagnosticChange(checked){var component=this;var content={is_diagnostic_assessment:checked};this.get('assessmentService').diagnosticAssessment(content,this.get('collection.id')).then(function(response){response.get('content_subtype')?component.set('tempCollection.metadata.content_subtype',[Number(response.get('content_subtype'))]):component.set('tempCollection.metadata.content_subtype',[]);component.set('isShowCompetencyNotTagged',false);},function(){component.set('isShowCompetencyNotTagged',true);component.set('collection.enableDiagnostic',false);});}}, /**
   * Returns compareAggregatedTags data
   * @param {Number[]} compareAggregatedTags ids
   * @return {compareAggregatedTags[]}
   */compareAggregatedTags:function compareAggregatedTags(){var component=this;component.get('tempCollection.aggregatedTag').forEach(function(suggeststanObj){_ember['default'].set(suggeststanObj,'tagAlreadyexist',true);});component.get('tempCollection.standards').forEach(function(standardObj){var suggestObj=component.get('tempCollection.aggregatedTag').findBy('code',standardObj.code);if(suggestObj !== undefined){_ember['default'].set(suggestObj,'tagAlreadyexist',false);}});},getFluenciesData:function getFluenciesData(fluData){var _this=this;this.get('fluencyService').getFluencyLevel(fluData).then(function(res){_this.set('fluencyData',res);});},init:function init(){this._super.apply(this,arguments);var collection=this.get('collection').copy(); // let audience = collection.audience || Ember.A([]);
// this.set(collection, 'audience', audience);
this.set('tempCollection',collection);if(this.get('selectedSubject')){var fluData={subject_code:this.get('selectedSubject').subjectCode,fw_id:this.get('selectedSubject').frameworkCode,tenant_id:this.get('session.tenantId')};this.getFluenciesData(fluData);this.set('isShowFluency',true);}}});});