define('gooru-web/controllers/student-independent-learning/learning-base',['exports','ember'],function(exports,_ember){ /**
 * Independent Learning Courses controller
 *
 * Controller responsible of the logic for the Independent Learning Courses page
 */exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {Controller} Parent controller
   */studentIndependentController:_ember['default'].inject.controller('student-independent-learning'), /**
   * @type {LearnerService} Service to retrieve learner information
   */learnerService:_ember['default'].inject.service('api-sdk/learner'), /**
   * @type {SessionService} Service to retrieve session information
   */session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Attributes
// -------------------------------------------------------------------------
// Actions
actions:{ /**
     * when loading more items from learner locations
     */showMoreResults:function showMoreResults(){var _this=this;var userId=this.get('session.userId');var contentType=this.get('contentType');var offset=this.get('offset');var locations=this.get('locations');var performance=this.get('performance');return _ember['default'].RSVP.hash({locations:this.get('learnerService').fetchLocations(userId,contentType,offset),performance:this.get('learnerService').fetchPerformance(userId,contentType,offset)}).then(function(hash){_this.set('offset',offset + _this.get('pageSize'));_this.set('locations',locations.concat(hash.locations));_this.set('performance',performance.concat(hash.performance));});}}, // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {LearnerLocation[]} list of locations to show
   */items:_ember['default'].computed('locations.[]','performance.[]',function(){var locations=this.get('locations');var performance=this.get('performance');var performanceMap=performance.reduce(function(result,perfItem){result[perfItem.courseId || perfItem.collectionId] = perfItem;return result;},{});return locations.map(function(location){return {location:location,performance:performanceMap[location.courseId || location.collectionId]};});}), /**
   * @property {LearnerLocation[]} list of locations to show
   */locations:null, /**
   * @property {LearnerPerformance[]} list of performance for every location
   */performance:null, /**
   * @property {Number} number of items loaded, used to load more
   */pageSize:20, /**
   * @property {boolean} if the show more button should be displayed
   */showMoreResultsButton:_ember['default'].computed('locations.[]',function(){return this.get('locations.length') && this.get('locations.length') % this.get('pageSize') === 0;}), /**
   * @property {Number} number of items loaded, used to load more
   */offset:20, /**
   * @property {String} the content type
   */contentType:null // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});