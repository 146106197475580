define('gooru-web/components/ca-student-list-suggestion',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['ca-student-list-suggestion'], /**
   * @requires service:api-sdk/profile
   */profileService:_ember['default'].inject.service('api-sdk/profile'),suggestedToContext:_ember['default'].computed.alias('suggestion.suggestedToContext'),actions:{onPullUpClose:function onPullUpClose(){var component=this;component.closePullUp();},openPerformanceReport:function openPerformanceReport(student){var component=this;var suggestion=component.get('suggestion');var params={userId:student.get('userId'),classId:component.get('classId'),collectionId:suggestion.get('suggestedContentId'),type:suggestion.get('suggestedContentType'),isStudent:false,collection:suggestion,studentPerformance:student.get('performance')};component.set('studentReportContextData',params);component.set('showStudentDcaReport',true);},onClosePullUp:function onClosePullUp(){var component=this;component.set('showStudentDcaReport',false);}},init:function init(){var component=this;component._super.apply(component,arguments);component.fetchStudentProfiles();}, //--------------------------------------------------------------------------
// Events
didInsertElement:function didInsertElement(){var component=this;component.openPullUp();}, //--------------------------------------------------------------------------
// Methods
fetchStudentProfiles:function fetchStudentProfiles(){var component=this;var profileIds=component.get('suggestedToContext').map(function(context){return context.get('userId');});component.get('profileService').readMultipleProfiles(profileIds).then(function(profiles){component.get('suggestedToContext').map(function(context){var profile=profiles.findBy('id',context.get('userId'));context.set('profile',profile);});});}, /**
   * Function to animate the  pullup from bottom to top
   */openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(){var component=this;component.$().animate({top:'100%'},400,function(){component.sendAction('onClosePullUp');});}});});