define('gooru-web/routes/content/assessments/edit',['exports','ember','gooru-web/mixins/private-route-mixin'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin){exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],{queryParams:{editing:{refreshModel:true},editingContent:{refreshModel:true},editingSubContent:{refreshModel:true},isLibraryContent:false,isPreviewReferrer:false}, // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Session} current session
   */session:_ember['default'].inject.service('session'),assessmentService:_ember['default'].inject.service('api-sdk/assessment'),courseService:_ember['default'].inject.service('api-sdk/course'), /**
   * @requires service:api-sdk/question
   */questionService:_ember['default'].inject.service('api-sdk/question'), /**
   * @requires service:century-skill/century-skill
   */centurySkillService:_ember['default'].inject.service('century-skill'), // -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Methods
model:function model(params){this.set('isPreviewReferrer',params.isPreviewReferrer);var route=this;return route.get('assessmentService').readAssessment(params.assessmentId).then(function(assessment){var courseId=assessment.get('courseId');var isEditing=params.editing;var editingContent=params.editingContent?params.editingContent:null;var editingSubContent=params.editingSubContent?params.editingSubContent:null;var isLibraryContent=params.isLibraryContent;var course=null;if(courseId){course = route.get('courseService').fetchById(courseId);}var questionPromiseList=assessment.get('children').map(function(question){return route.get('questionService').readQuestion(question.get('id'));});return _ember['default'].RSVP.hash({questions:_ember['default'].RSVP.all(questionPromiseList),assessment:assessment,course:course,isEditing:isEditing === 'true',editingContent:editingContent,editingSubContent:editingSubContent,isLibraryContent:isLibraryContent,isPreviewReferrer:params.isPreviewReferrer});});},setupController:function setupController(controller,model){var route=this; // Since assessment is a collection with only questions, we'll reuse the same components
// for collections (for example, see: /app/components/content/assessments/gru-assessment-edit.js)
// and that is why the property 'collection' is being reused here, too.
model.assessment.set('children',model.questions);controller.set('collection',model.assessment);controller.set('course',model.course);controller.set('isEditing',model.isEditing);controller.set('editingContent',model.editingContent);controller.set('isAssessment',true);controller.set('isLibraryContent',model.isLibraryContent);controller.set('isPreviewReferrer',model.isPreviewReferrer);controller.set('editingSubContent',model.editingSubContent);route.get('centurySkillService').findCenturySkills().then(function(centurySkillsArray){controller.set('centurySkills',centurySkillsArray.toArray());});if(model.isEditing || model.editingContent){controller.set('tempCollection',model.assessment.copy());}},resetController:function resetController(controller){controller.set('isPreviewReferrer',undefined);this.get('controller').set('isEditing','false');}});});