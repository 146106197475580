define('gooru-web/services/api-sdk/collection',['exports','ember','gooru-web/serializers/content/collection','gooru-web/adapters/content/collection','gooru-web/adapters/taxonomy/taxonomy','gooru-web/serializers/taxonomy/taxonomy','gooru-web/utils/utils'],function(exports,_ember,_gooruWebSerializersContentCollection,_gooruWebAdaptersContentCollection,_gooruWebAdaptersTaxonomyTaxonomy,_gooruWebSerializersTaxonomyTaxonomy,_gooruWebUtilsUtils){ /**
 * @typedef {Object} CollectionService
 */exports['default'] = _ember['default'].Service.extend({ /**
   * @property {Profile} Profile service
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @property {Store} Store service
   */store:_ember['default'].inject.service(), /**
   * @property {CollectionSerializer} collectionSerializer
   */collectionSerializer:null, /**
   * @property {CollectionAdapter} collectionAdapter
   */collectionAdapter:null, /**
   * @property {CollectionService}
   */quizzesCollectionService:_ember['default'].inject.service('quizzes/collection'),init:function init(){this._super.apply(this,arguments);this.set('collectionSerializer',_gooruWebSerializersContentCollection['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('collectionAdapter',_gooruWebAdaptersContentCollection['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomyAdapter',_gooruWebAdaptersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));this.set('taxonomySerializer',_gooruWebSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Creates a new collection
   *
   * @param collectionData object with the collection data
   * @returns {Promise}
   */createCollection:function createCollection(collectionData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedClassData=service.get('collectionSerializer').serializeCreateCollection(collectionData);service.get('collectionAdapter').createCollection({body:serializedClassData}).then(function(responseData,textStatus,request){var collectionId=request.getResponseHeader('location');collectionData.set('id',collectionId);resolve(collectionData);},function(error){reject(error);});});}, /**
   * Gets a Collection by id
   * @param {string} collectionId
   * @returns {Promise}
   */readCollection:function readCollection(collectionId,classFramework,isDefaultShowFW){var isSetOwner=arguments.length <= 3 || arguments[3] === undefined?true:arguments[3];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').readCollection(collectionId).then(function(responseData){ //get resources and questions count, if it is not available
if(typeof responseData.resource_count === 'undefined'){var content=(0,_gooruWebUtilsUtils.getContentCount)(responseData.content);responseData.resource_count = content.resourceCount;responseData.question_count = content.questionCount;}var collection=service.get('collectionSerializer').normalizeReadCollection(responseData);var taxonomyIds=(0,_gooruWebUtilsUtils.getTaxonomyIds)(collection,true);var profileService=service.get('profileService');_ember['default'].RSVP.hash({getcrosswalkCompetency:isDefaultShowFW && taxonomyIds.length?service.getcrosswalkCompetency(collection,classFramework,taxonomyIds):[],profile:isSetOwner?profileService.readUserProfile(collection.get('ownerId')):{}}).then(function(hash){if(isSetOwner){collection.set('owner',hash.profile);}resolve(collection);});},reject);});}, /**
   * Updates a Collection
   *
   * @param collectionId the id of the Collection to be updated
   * @param collectionModel the Collection model with the data
   * @returns {Promise}
   */updateCollection:function updateCollection(collectionId,collectionModel){var service=this;var serializedData=service.get('collectionSerializer').serializeUpdateCollection(collectionModel);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').updateCollection(collectionId,serializedData).then(function(){service.notifyQuizzesCollectionChange(collectionId);resolve();},reject);});}, /**
   * Gets a Collection by id
   * @param {string} collectionId
   * @param {string} type collection|assessment
   * @param {boolean} refresh
   * @returns {Promise.<Collection>}
   */readQuizzesCollection:function readQuizzesCollection(collectionId,type){var refresh=arguments.length <= 2 || arguments[2] === undefined?false:arguments[2];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').readQuizzesCollection(collectionId,type,refresh).then(function(responseData){resolve(responseData);},reject);});}, /**
   * Gets a Collection by id
   * @param {string} collectionId
   * @param {string} type collection|assessment
   * @param {boolean} refresh
   * @returns {Promise.<Collection>}
   */readPerformanceData:function readPerformanceData(classId,collectionId,startDate){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').readPerformanceCollection(classId,collectionId,startDate).then(function(responseData){resolve(responseData);},reject);});}, /**
   * Gets a Collection by id
   * @param {string} collectionId
   * @param {string} type collection|assessment
   * @param {boolean} refresh
   * @returns {Promise.<Collection>}
   */readPerformanceDataDCA:function readPerformanceDataDCA(classId,collectionId,startDate,assessmentType){var service=this;var collectionType='assessment';if(!assessmentType){collectionType = 'collection';}return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').readPerformanceCollectionDCA(classId,collectionId,startDate,collectionType).then(function(responseData){resolve(responseData);},reject);});}, /**
   * Updates the Collection title
   *
   * @param collectionId the id of the Collection to be updated
   * @param title the Collection title
   * @returns {Promise}
   */updateCollectionTitle:function updateCollectionTitle(collectionId,title,type){var service=this;var serializedData=service.get('collectionSerializer').serializeUpdateCollectionTitle(title);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').updateCollection(collectionId,serializedData,type).then(function(){if(type !== 'collections-external'){service.notifyQuizzesCollectionChange(collectionId);}resolve();},reject);});}, /**
   * Gets an External Collection by id
   * @param {string} collectionId
   * @returns {Promise}
   */readExternalCollection:function readExternalCollection(collectionId,classFramework,isDefaultShowFW){var isSetOwner=arguments.length <= 3 || arguments[3] === undefined?true:arguments[3];var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').readExternalCollection(collectionId).then(function(responseData){var collection=service.get('collectionSerializer').normalizeReadCollection(responseData);var taxonomyIds=(0,_gooruWebUtilsUtils.getTaxonomyIds)(collection,true);var profileService=service.get('profileService');_ember['default'].RSVP.hash({getcrosswalkCompetency:isDefaultShowFW && taxonomyIds.length?service.getcrosswalkCompetency(collection,classFramework,taxonomyIds):[],profile:isSetOwner?profileService.readUserProfile(collection.get('ownerId')):{}}).then(function(hash){if(isSetOwner){collection.set('owner',hash.profile);}resolve(collection);});},reject);});}, /**
   * Reorder collection resources
   *
   * @param collectionId the id of the Collection to be updated
   * @param {string[]} resourceIds
   * @returns {Promise}
   */reorderCollection:function reorderCollection(collectionId,resourceIds){var service=this;var serializedData=service.get('collectionSerializer').serializeReorderCollection(resourceIds);return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').reorderCollection(collectionId,serializedData).then(function(){service.notifyQuizzesCollectionChange(collectionId);resolve();},reject);});}, /**
   * Gets a specific collection|assessment by ID
   * @param {string} collectionId
   * @returns {Collection}
   */findById:function findById(collectionId){return this.get('store').findRecord('collection/collection',collectionId,{reload:true});}, /**
   * Gets all collections|assessments for an specific unit and lesson.
   * @param classId
   * @param courseId
   * @param unitId
   * @param lessonId
   * @returns {Collection[]}
   */findByClassAndCourseAndUnitAndLesson:function findByClassAndCourseAndUnitAndLesson(classId,courseId,unitId,lessonId){return this.get('store').queryRecord('collection/collection',{classId:classId,courseId:courseId,unitId:unitId,lessonId:lessonId});}, /**
   * Adds a resource to a specific collection
   * @param collectionId
   * @param resourceId
   * @returns {Promise}
   */addResource:function addResource(collectionId,resourceId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').addResource(collectionId,resourceId).then(function(){service.notifyQuizzesCollectionChange(collectionId);resolve();},reject);});}, /**
   * Adds a question to a specific collection
   * @param collectionId
   * @param questionId
   * @returns {Promise}
   */addQuestion:function addQuestion(collectionId,questionId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').addQuestion(collectionId,questionId).then(function(){service.notifyQuizzesCollectionChange(collectionId);resolve();},reject);});}, /**
   * Delete collection
   *
   * @param collectionId The collection id to delete
   * @returns {Promise}
   */deleteCollection:function deleteCollection(collectionId){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').deleteCollection(collectionId).then(function(){service.notifyQuizzesCollectionChange(collectionId);resolve();},reject);});}, /**
   * Copies a collection by id
   * @param {string} collectionId
   * @returns {Ember.RSVP.Promise}
   */copyCollection:function copyCollection(collectionId,type){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('collectionAdapter').copyCollection(collectionId,type).then(function(responseData,textStatus,request){resolve(request.getResponseHeader('location'));},reject);});}, /**
   * Notify a collection change at quizzes
   * @param {string} collectionId
   */notifyQuizzesCollectionChange:function notifyQuizzesCollectionChange(collectionId){var quizzesCollectionService=this.get('quizzesCollectionService');_ember['default'].Logger.info('Notifying collection change');return quizzesCollectionService.notifyCollectionChange(collectionId,'collection');}, /**
   * Creates a new collection
   *
   * @param collectionData object with the collection data
   * @returns {Promise}
   */createExternalCollection:function createExternalCollection(collectionData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var serializedExternalCollectionData=service.get('collectionSerializer').serializeCreateExternalCollection(collectionData);service.get('collectionAdapter').createExternalCollection(serializedExternalCollectionData).then(function(responseData,textStatus,request){var createdCollectionData=_ember['default'].Object.create(collectionData);var collectionId=request.getResponseHeader('location');createdCollectionData.set('id',collectionId);resolve(createdCollectionData);},function(error){reject(error);});});},fetchCollectionSummary:function fetchCollectionSummary(param){var service=this;return new _ember['default'].RSVP.Promise(function(resolve){service.get('collectionAdapter').fetchCollectionSummary(param).then(function(response){resolve(service.get('collectionSerializer').serializeCollectionSummary(response));});});},getcrosswalkCompetency:function getcrosswalkCompetency(collection,classFramework,taxonomyIds){var service=this;return service.get('taxonomyAdapter').crosswalkCompetency(classFramework,taxonomyIds).then(function(crosswalkResponse){var frameworkCrossWalkComp=service.get('taxonomySerializer').normalizeCrosswalkCompetency(crosswalkResponse);var collectionStandrs=collection.standards;collectionStandrs.map(function(standard){var taxonomyData=frameworkCrossWalkComp.findBy('sourceDisplayCode',standard.code);if(taxonomyData){standard.code = taxonomyData.targetDisplayCode;standard.frameworkCode = taxonomyData.targetFrameworkId;}});collection.children.map(function(resource){resource.standards.map(function(standard){var taxonomyData=frameworkCrossWalkComp.findBy('sourceDisplayCode',standard.code);if(taxonomyData){standard.code = taxonomyData.targetDisplayCode;standard.frameworkCode = taxonomyData.targetFrameworkId;}});});});}});});