define('gooru-web/components/reports/assessment/questions/gru-vowel-teams',['exports','ember','quizzes-addon/mixins/reports/assessment/questions/question'],function(exports,_ember,_quizzesAddonMixinsReportsAssessmentQuestionsQuestion){ /**
 * SERP Underline
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-vowel-teams'], // -------------------------------------------------------------------------
// Properties
didInsertElement:function didInsertElement(){var _this=this;if(this.get('baseAnswers') && this.get('baseAnswers').length){this.get('baseAnswers').forEach(function(element,answerIndex){var elementItem=!_this.get('showCorrect')?_this.get('answerObj')[answerIndex]:element;var correctAnswer=!_this.get('showCorrect')?JSON.parse(elementItem.text):element.correctAnswer;for(var index=0;index < correctAnswer.length;index++) {var elements=correctAnswer[index];_this.hightLightDefaultWord(elements,answerIndex);}});}},showCorrect:false,baseAnswers:_ember['default'].computed('question',function(){return this.get('question.answers');}), // ---------------------------------------------------------------
// Methods
hightLightDefaultWord:function hightLightDefaultWord(text,answerIndex){var component=this;var innerHTML='';var html='';var start=text.start;var end=text.end;var fulltext=text.text;var findPosition=function findPosition(index,itemName){var findItem=text[itemName]?text[itemName]:[];return findItem.indexOf(index) !== -1;};var sptText=fulltext.split('').map(function(item,index){return '<b class=\'' + (findPosition(index,'macronPositions')?'macron ':'') + ' ' + (findPosition(index,'crossPositions')?'cross ':'') + '\'>' + item + '</b>';}).join('');html = '<span class="serp-hl-text-span">' + sptText + '</span>';String.prototype.replaceBetween = function(start,end,what){return this.substring(0,start) + what + this.substring(end);};component.$('.vowel-teams-edit-' + answerIndex)[0].childNodes.forEach(function(childNode){if(childNode.data && childNode.data.substring(start,end) === fulltext){innerHTML = innerHTML + childNode.data.replaceBetween(start,end,$.trim(html));}else if(childNode.data){innerHTML = innerHTML + childNode.data;}else {innerHTML = innerHTML + childNode.outerHTML;}});component.$('.vowel-teams-edit-' + answerIndex).html(innerHTML);}});});