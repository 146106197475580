define('gooru-web/utils/question/decoding-assessment',['exports','ember','gooru-web/utils/question/question','gooru-web/utils/question/answer-object'],function(exports,_ember,_gooruWebUtilsQuestionQuestion,_gooruWebUtilsQuestionAnswerObject){ /**
 * It contains convenience methods for grading and retrieving useful information
 * for multple answer questions
 *
 * # Answer object (structure required by the BE)
 *
 *   It is an array containing a json object for each user selection
 *
 *   text contains Yes or No based on user selection
 *   status could be correct or incorrect based on the user selection
 *   order represents the order of this user selection
 *   answerId corresponds to the answer choice id selected
 *   skip is always false
 *
 *  [{"text":"Yes","status":"correct","order":1,"answerId":1234,"skip":false},
 *  {"text":"Yes","status":"incorrect","order":2,"answerId":1234,"skip":false},
 *  {"text":"No","status":"incorrect","order":3,"answerId":"1234,"skip":false},
 *  {"text":"No","status":"correct","order":4,"answerId":1235,"skip":false}]
 *
 * # User answer (structure used by the FE)
 *
 *   It corresponds to an array representing the user selection
 *
 *   id represents the answerId selected
 *   selection indicates if the user selected Yes=true or No=false
 *
 *   [ { id: string, selection: boolean }, ... ]
 *
 * @typedef {Object} DecodingAssessmentAnswerUtil
 */exports['default'] = _gooruWebUtilsQuestionQuestion['default'].extend({ // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
/**
   * Indicates if the answer choice is correct
   * @param { { id: number, selection: boolean } } answerChoice
   *
   * @see '# User Answer' section at class comment
   */isAnswerChoiceCorrect:function isAnswerChoiceCorrect(answerChoice){var correctAnswer=this.getCorrectAnswer();var found=correctAnswer.filterBy('id',answerChoice.id);return found.get('length') && found.get('firstObject.selection') === answerChoice.selection;}, /**
   * Gets the correct answer
   * It returns which is the correct selection (yes=true | no=false) for each answer choice
   * @return {Array} the correct answer for this question type [ { id: string, selection: boolean }, ... ]
   *
   * @see '# User Answer' section at class comment
   */getCorrectAnswer:function getCorrectAnswer(){var answers=this.get('question.answers');return answers.map(function(answer){return {id:answer.get('id'),selection:answer.get('isCorrect')};});}, /**
   * Returns a unique key representing the answer
   * For multiple answer the answer is an array of { id: number, selection: boolean }
   * @param { { id: number, selection: boolean }[] } answer
   * @returns {string} i.e id_true,id_false,id_true
   *
   * @see '# User Answer' section at class comment
   */answerKey:function answerKey(answer){var keys=_ember['default'].A(answer).sortBy('id').map(function(item){return item.id + '_' + item.selection;});return keys.toArray().join();}, /**
   * Converts the model user answer into an answerObject format
   *
   * @param { { id: string, selection: boolean }[] } userAnswer
   * @return {AnswerObject[]}
   *
   * @see '# User Answer' section at class comment
   * @see '# Answer Object' section at class comment
   */toAnswerObjects:function toAnswerObjects(userAnswer){var util=this;return userAnswer.map(function(item,index){var answer=util.getAnswerById(item.id);var text=item.selection?'Yes':'No';return _gooruWebUtilsQuestionAnswerObject['default'].create({text:text,correct:util.isAnswerChoiceCorrect(item),order:index + 1,answerId:answer.get('id'),skip:false});});}, /**
   * Converts an answerObject format to model userAnswer
   *
   * @param {AnswerObject[]} answerObjects
   * @return { { id: string, selection: boolean }[] } answer selections
   *
   * @see '# User Answer' section at class comment
   * @see '# Answer Object' section at class comment
   */toUserAnswer:function toUserAnswer(answerObjects){return !answerObjects || !answerObjects.length?null //if not respond is provided
:answerObjects.map(function(answerObject){return {id:_ember['default'].get(answerObject,'answerId'),selection:_ember['default'].get(answerObject,'text') === 'Yes'};});}});});