define('gooru-web/models/country',['exports','ember'],function(exports,_ember){ /**
 * Country model
 *
 * @typedef {Object} Country
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string}
   */id:null, /**
   * @property {string}
   */name:null, /**
   * @property {string}
   */code:null});});