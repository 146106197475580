define('gooru-web/adapters/content/resource',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Resource CRUD operations in the API 3.0
 *
 * @typedef {Object} ResourceAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/nucleus/v1/resources',usmStandardsNamespace:'/api/v1/usm-data-handler',copierNamespace:'/api/nucleus/v1/copier/resources',fbCheckerNamespace:'/api/nucleus-utils/v1/fb-checker', /**
   * Posts a new resource
   *
   * @param data resource data to be sent in the request body
   * @returns {Promise}
   */createResource:function createResource(data){var adapter=this;var url=this.get('namespace');var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data.body)};return _ember['default'].$.ajax(url,options);}, /**
   * Posts a new resource
   *
   * @param data fb checker data to be sent in the request body
   * @returns {Promise}
   */createFbChecker:function createFbChecker(data){var adapter=this;var url=this.get('fbCheckerNamespace');var body={};if(data){body.url = data;}var options={type:'POST',contentType:'application/json; charset=utf-8',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(body)};return _ember['default'].$.ajax(url,options);}, /**
   * Reads a resource by id
   *
   * @param {string} resourceId
   * @returns {Promise}
   */readResource:function readResource(resourceId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + resourceId;var options={type:'GET',contentType:'application/json; charset=utf-8',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);},getUSMStandardsInfo:function getUSMStandardsInfo(params){var adapter=this;var url=this.usmStandardsNamespace + '/usmstandards';var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:params};return _ember['default'].$.ajax(url,options).then(function(response){ // Handle the success response here
return response; // You can return the response if needed
})['catch'](function(error){ // Handle any errors here
throw error; // You can rethrow the error if needed
});}, /*
   * Updates a resource by id
   *
   * @param {string} resourceId
   * @param data resource data to be sent in the request body
   * @returns {Promise}
   */updateResource:function updateResource(resourceId,data){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/' + resourceId;var options={type:'PUT',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(data)};return _ember['default'].$.ajax(url,options);}, /**
   * Copies a resource by id
   *
   * @param data resource data to be sent in the request body
   * @returns {Promise}
   */copyResource:function copyResource(resourceIds,title){var adapter=this;var namespace=this.get('copierNamespace');var url='' + namespace;var body={};if(typeof resourceIds === 'string'){resourceIds = [resourceIds];}body.resourceIds = resourceIds;if(title){body.title = title;}var options={type:'POST',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify(body)};return _ember['default'].$.ajax(url,options);}, /**
   * Deletes a resource by id
   *
   * @param resourceId resource id to be sent
   * @returns {Promise}
   */deleteResource:function deleteResource(resourceId){var adapter=this;var namespace=this.get('namespace');var url=namespace + '/' + resourceId;var options={type:'DELETE',contentType:'application/json; charset=utf-8',dataType:'text',processData:false,headers:adapter.defineHeaders(),data:JSON.stringify({})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});