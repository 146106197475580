define('gooru-web/adapters/lookup/facet-search',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Lookup API 3.0 integration
 *
 * @typedef {Object} FacetSearchAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/admin/v1/settings', /**
   * Gets the audience list information
   * @returns {Promise.<[]>}
   */getTenantSpecificFacets:function getTenantSpecificFacets(){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/facets';var options={type:'GET',headers:adapter.defineHeaders()};return _ember['default'].$.ajax(url,options);}, /**
   *
   * @returns {{Authorization: string}}
   */defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});