define('gooru-web/components/cards/dca-search-collection-card',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/config/config','gooru-web/config/parse-event','gooru-web/mixins/instructional-coache-mixin'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebConfigConfig,_gooruWebConfigParseEvent,_gooruWebMixinsInstructionalCoacheMixin){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsInstructionalCoacheMixin['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['cards','dca-search-collection-card'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/class-activity
   */classActivityService:_ember['default'].inject.service('api-sdk/class-activity'), /**
   * @property {Service} parseEvent service
   */parseEventService:_ember['default'].inject.service('api-sdk/parse-event/parse-event'),session:_ember['default'].inject.service(), //
// -------------------------------------------------------------------------
// Events
didRender:function didRender(){var component=this;component.$('[data-toggle="tooltip"]').tooltip({trigger:'hover'});}, // -------------------------------------------------------------------------
// Properties
/**
   * collection object
   * @type {Object}
   */collection:null, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('collection.standards.[]',function(){var standards=this.get('collection.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}}),isInstructionalCoache:_ember['default'].computed(function(){return this.instructionalCoache();}), /**
   * Maintains collection type
   * @type {String}
   */contentType:null, /**
   * Selected collection for scheduling
   * @type {Object}
   */selectedCollectionForSchedule:null, /**
   * Class Id
   * @type {String}
   */classId:null, /**
   * @property {Boolean} isMediumScreen
   * Property to check whether loading card in medium screen or not
   */isMediumScreen:_ember['default'].computed(function(){return _gooruWebConfigConfig.SCREEN_SIZES.MEDIUM >= screen.width;}), /**
   * @property {Boolean} showSuggestionBtn
   */showSuggestionBtn:false, // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user play collection
     * It'll open the player in new tab
     */onPlayCollection:function onPlayCollection(collection){var component=this;if(collection.format === 'collection'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_COLLECTION_PLAY);}else if(collection.format === 'assessment'){component.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_ASSESSMENT_PLAY);}component.sendAction('onPreviewContent',collection);}, /**
     * Action triggered when add collection to dca.
     * @param  {Object} collection
     */onAddCollectionToDCA:function onAddCollectionToDCA(collection){if(collection.format === 'collection'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_COLLECTION_ADD_TODAY_CLASS);}else if(collection.format === 'assessment'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_ASSESSMENT_ADD_TODAY_CLASS);}this.sendAction('onAddContentToDCA',collection);}, /**
     * Action get triggered when schedule content to CA got clicked
     */onScheduleContentToDCA:function onScheduleContentToDCA(content,event){if(content.format === 'collection'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_COLLECTION_SCHEDULE_LATER);}else if(content.format === 'assessment'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_ASSESSMENT_SCHEDULE_LATER);}this.sendAction('onScheduleContentToDCA',content,event);}, // Action triggered when click suggestion button
onSuggestContent:function onSuggestContent(collection){if(collection.format === 'collection'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_COLLECTION_SUGGEST_STUDENT);}else if(collection.format === 'assessment'){this.get('parseEventService').postParseEvent(_gooruWebConfigParseEvent.PARSE_EVENTS.CLICK_GROWTH_SUGGESTION_ASSESSMENT_SUGGEST_STUDENT);}this.sendAction('onSuggestContent',collection);}}, // -------------------------------------------------------------------------
// Methods
serializerSearchContent:function serializerSearchContent(content,contentId,date,forMonth,forYear){return _ember['default'].Object.create({id:contentId,added_date:date,collection:content,activityDate:date,forMonth:forMonth,forYear:forYear,usersCount:-1,isActive:false});}});});