define('gooru-web/components/reports/pull-up/activity/add-performance-data-choice',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Attributes
classNames:['add-performance-data-by-choice'], // -------------------------------------------------------------------------
// Properties
/**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */taxonomyTags:_ember['default'].computed('assessment.standards.[]',function(){var standards=this.get('assessment.standards');if(standards){standards = standards.filter(function(standard){ // Filter out learning targets (they're too long for the card)
return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), /**
   * @property {Array[]} List of choice
   */choiceList:_ember['default'].A([{'name':'question','icon':'help_outline','showMessage':false},{'name':'upload-image','icon':'photo','showMessage':true}]), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when teacher select choice.
     */selectChoiceOption:function selectChoiceOption(option){var component=this;component.sendAction('onSelectOption',option);}}});});