define('gooru-web/routes/teacher-home',['exports','ember','gooru-web/mixins/private-route-mixin','gooru-web/mixins/configuration','gooru-web/config/config','gooru-web/mixins/accessibility-settings-mixin','gooru-web/mixins/ui-helper-mixin'],function(exports,_ember,_gooruWebMixinsPrivateRouteMixin,_gooruWebMixinsConfiguration,_gooruWebConfigConfig,_gooruWebMixinsAccessibilitySettingsMixin,_gooruWebMixinsUiHelperMixin){ /**
 * Teacher route
 *
 * @module
 * @augments Ember.Route
 */exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsPrivateRouteMixin['default'],_gooruWebMixinsConfiguration['default'],_gooruWebMixinsAccessibilitySettingsMixin['default'],_gooruWebMixinsUiHelperMixin['default'],{queryParams:{isDeepLink:false}, // -------------------------------------------------------------------------
// Dependencies
performanceService:_ember['default'].inject.service('api-sdk/performance'), /**
     * @requires service:api-sdk/course
     */courseService:_ember['default'].inject.service('api-sdk/course'), /**
     * @type {I18nService} Service to retrieve translations information
     */i18n:_ember['default'].inject.service(), /**
     * @property {Service} Profile service
     */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
     * @property {Service} tenant service
     */tenantService:_ember['default'].inject.service('api-sdk/tenant'),session:_ember['default'].inject.service('session'), // -------------------------------------------------------------------------
// Actions
beforeModel:function beforeModel(){if(this.get('session') && this.get('session.isContentNavOnly')){this.isContentNavOnly = this.get('session.isContentNavOnly') === 'true';}if(!this.isContentNavOnly && this.get('session.role') === 'teacher'){this.transitionTo('teacher-home');}else if(!this.isContentNavOnly && this.get('session.role') !== 'teacher'){this.transitionTo('student-home');}else {this.transitionTo('facets-search');}},actions:{ /**
       * Open the player with the specific currentLocation
       *
       * @function actions:playItem
       * @param {string} currentLocation - All the params for the currentLocation
       */studyPlayer:function studyPlayer(currentLocation){var route=this;var role=_gooruWebConfigConfig.ROLES.STUDENT;var source=_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.COURSE_MAP;var courseId=currentLocation.get('courseId');var classId=currentLocation.get('classId');var unitId=currentLocation.get('unitId');var lessonId=currentLocation.get('lessonId');var collectionId=currentLocation.get('collectionId');var collectionType=currentLocation.get('collectionType');var collectionSubType=currentLocation.get('collection.collectionSubType');var pathId=currentLocation.get('collection.pathId') || 0;var queryParams={classId:classId,unitId:unitId,lessonId:lessonId,collectionId:collectionId,role:role,source:source,type:collectionType,subtype:collectionSubType,pathId:pathId};var suggestionPromise=null; // Verifies if it is a suggested Collection/Assessment
if(collectionSubType){suggestionPromise = route.get('navigateMapService').startSuggestion(courseId,unitId,lessonId,collectionId,collectionType,collectionSubType,pathId,classId);}else {suggestionPromise = route.get('navigateMapService').startCollection(courseId,unitId,lessonId,collectionId,collectionType,classId);}suggestionPromise.then(function(){return route.transitionTo('study-player',courseId,{queryParams:queryParams});});}, /**
       * Triggered when a teacher card menu item is selected
       * @param {string} item
       * @param {string} classId
       */selectMenuItem:function selectMenuItem(item,classId){var route=this;if(item === 'students'){route.transitionTo('teacher.class.students-proficiency',classId);}else if(item === 'course-map'){route.transitionTo('teacher.class.course-map',classId,{queryParams:{location:null,selectedCollectionId:null,selectedQuestionId:null,selectedUserId:null}});}else if(item === 'class-activities'){route.transitionTo('teacher.class.class-activities',classId);}else if(item === 'ca-report'){route.transitionTo('teacher.class.class-activities',classId,{queryParams:{tab:'report'}});}else if(item === 'cm-report'){route.transitionTo('teacher.class.course-map',classId,{queryParams:{tab:'report'}});}else if(item === 'atc'){route.transitionTo('teacher.class.atc',classId);}else if(item === 'class-management'){route.transitionTo('teacher.class.class-management',classId);}else if(JSON.parse(this.get('session.isContentNavOnly')) === true){route.transitionTo('facets-search');}else {route.transitionTo('teacher-home');}}}, // -------------------------------------------------------------------------
// Methods
/**
     * Get model for the controller
     */model:function model(params){var route=this;if(params.isDeepLink){_ember['default'].$('body').addClass('hide-header');}route.set('isDeepLink',params.isDeepLink === 'true');var myClassessPromise=_ember['default'].RSVP.resolve(route.controllerFor('application').loadUserClasses());route.setTitle('Instructor Classroom',null);return _ember['default'].RSVP.hash({myClasses:myClassessPromise,preferenceSettings:route.get('profileService').getProfilePreference()}).then(function(hash){var myClasses=hash.myClasses;route.setAccessibilitySettingsAPI(hash.preferenceSettings);var myId=route.get('session.userId');var activeClasses=myClasses.getTeacherActiveClasses(myId);var archivedClasses=myClasses.getTeacherArchivedClasses();var incompleteClasses=myClasses.getTeacherIncompleteClasses();if(incompleteClasses.length){var _ret=(function(){var rosterArray=_ember['default'].A([]);incompleteClasses.map(function(data){rosterArray.push(parseInt(data.roster_id));});return {v:route.get('tenantService').getGrade({roster_ids:rosterArray}).then(function(rostersubject){incompleteClasses.forEach(function(data){var roaster=rostersubject.roster_grade_subjects.filterBy('roster_id',parseInt(data.roster_id));data.set('rosterData',roaster);});return {activeClasses:activeClasses,archivedClasses:archivedClasses,incompleteClasses:incompleteClasses,preferenceSettings:hash.preferenceSettings};})};})();if(typeof _ret === 'object')return _ret.v;}else {return {activeClasses:activeClasses,archivedClasses:archivedClasses,incompleteClasses:incompleteClasses,preferenceSettings:hash.preferenceSettings};}});}, /**
     * Set all controller properties from the model
     * @param controller
     * @param model
     */setupController:function setupController(controller,model){controller.set('archivedClasses',model.archivedClasses);controller.set('incompleteClasses',model.incompleteClasses);controller.set('preferenceSetting',model.preferenceSettings);controller.set('activeClasses',model.activeClasses);controller.changeLanguage();var lastAccessedClassData=controller.getLastAccessedClassData();if(model.activeClasses.length){controller.updateLastAccessedClassPosition(lastAccessedClassData.id);}controller.loadPerformance();},resetController:function resetController(controller){controller.set('showActiveClasses',true);controller.set('showArchivedClasses',false);controller.set('showIncompleteClasses',false);controller.set('isActiveClassPerformanceLoaded',false);controller.set('isArchivedClassPerformanceLoaded',false);controller.set('isIncompleteClassPerformanceLoaded',false);}});});