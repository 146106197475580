define('gooru-web/adapters/skyline-initial/skyline-initial',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the skyline initial API
 *
 * @typedef {Object} SkylineAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/skyline-initial', /**
   * Fetch state of skyline initialization
   * @returns {Promise.<[]>}
   */fetchState:function fetchState(classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/v1/state';var options={type:'POST',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',data:JSON.stringify({classId:classId})};return _ember['default'].$.ajax(url,options);}, /**
   * calculate skyline
   * @returns {Promise.<[]>}
   */calculateSkyline:function calculateSkyline(classId,userIds){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/v1/calculate';var options={type:'POST',headers:adapter.defineHeaders(),dataType:'text',processData:false,contentType:'application/json; charset=utf-8',data:JSON.stringify({classId:classId,users:userIds})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});