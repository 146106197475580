define('gooru-web/serializers/rescope/rescope',['exports','ember'],function(exports,_ember){ /**
 * Serializer to support Rescope Operations
 *
 * @typedef {Object} RescopeSerializer
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @function normalizeSkippedContents
   * Method to normalize skipped contents
   */normalizeSkippedContents:function normalizeSkippedContents(payload){var skippedContents=_ember['default'].Object.create({assessments:_ember['default'].A([]),collections:_ember['default'].A([]),assessmentsExternal:_ember['default'].A([]),collectionsExternal:_ember['default'].A([]),offlineActivities:_ember['default'].A([]),lessons:_ember['default'].A([]),units:_ember['default'].A([])});if(payload){skippedContents.set('assessments',payload.assessments);skippedContents.set('collections',payload.collections);skippedContents.set('assessmentsExternal',payload.assessmentsExternal);skippedContents.set('collectionsExternal',payload.collectionsExternal);skippedContents.set('lessons',payload.lessons);skippedContents.set('units',payload.units);skippedContents.set('offlineActivities',payload.offlineActivities || _ember['default'].A([]));}return skippedContents;}, /**
   * @function normalizeRescopeContents
   * Method to normalize Rescope contents
   */normalizeRescopeContents:function normalizeRescopeContents(payload){var rescopeData=payload?payload:null;var rescopeList=_ember['default'].A([]);if(rescopeData && rescopeData.rescopeData.length){rescopeData.rescopeData.forEach(function(data){rescopeList.pushObject(_ember['default'].Object.create({userId:data.userId,skippedContent:data.skippedContent,lessons:data.skippedContent.lessons}));});}return rescopeList;}});});