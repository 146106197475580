define('gooru-web/models/goal/goal',['exports','ember','gooru-web/config/config','ember-cp-validations','gooru-web/validations/create-goal'],function(exports,_ember,_gooruWebConfigConfig,_emberCpValidations,_gooruWebValidationsCreateGoal){var Validations=(0,_emberCpValidations.buildValidations)({}); /**
 * Goal model
 *
 * @typedef {Object} Goal
 */var GoalModel=_ember['default'].Object.extend(Validations,{ /**
   * @property {String} id - Gooru id for the goal
   */id:null, /**
   * @property {String} title
   */title:null, /**
   * @property {String} description
   */description:null, /**
   * @property {Date} startDate
   */startDate:null, /**
   * @property {Date} endDate
   */endDate:null, /**
   * @property {String} status
   * @see gooru-web/config/config.js#GOAL_STATUS
   */status:null, /**
   * @property {String} reflection
   */reflection:null, /**
   * @property {number}
   */order:null, /**
   * @property {boolean} dropped
   */dropped:_ember['default'].computed.equal('status',_gooruWebConfigConfig.GOAL_STATUS.DROPPED), /**
   * @property {boolean} active
   */active:_ember['default'].computed.equal('status',_gooruWebConfigConfig.GOAL_STATUS.ACTIVE), /**
   * @property {boolean} notStarted
   */notStarted:_ember['default'].computed.equal('status',_gooruWebConfigConfig.GOAL_STATUS.NOT_STARTED), /**
   * @property {boolean} completed
   */completed:_ember['default'].computed.equal('status',_gooruWebConfigConfig.GOAL_STATUS.COMPLETED), /**
   * Return a copy of the goal
   *
   * @function
   * @return {Goal}
   */copy:function copy(){var properties=this.getProperties(this.modelProperties());var title=this.get('title');var description=this.get('description');var startDate=this.get('startDate');var endDate=this.get('endDate');var status=this.get('status');var reflection=this.get('reflection'); // Copy info values
properties.title = title;properties.description = description;properties.startDate = startDate;properties.endDate = endDate;properties.status = status;properties.reflection = reflection;var newGoal=GoalModel.extend(_gooruWebValidationsCreateGoal['default']);return newGoal.create(_ember['default'].getOwner(this).ownerInjection(),properties);}, /**
   * Copy a list of property values from another model to override the current ones
   *
   * @function
   * @param {Goal} model
   * @param {String[]} propertyList
   * @return {null}
   */merge:function merge(model){var propertyList=arguments.length <= 1 || arguments[1] === undefined?[]:arguments[1];var properties=model.getProperties(propertyList);this.setProperties(properties);}, /**
   * Return a list of Goal properties
   *
   * @function
   * @return {Array}
   */modelProperties:function modelProperties(){var properties=[];var enumerableKeys=Object.keys(this);for(var i=0;i < enumerableKeys.length;i++) {var key=enumerableKeys[i];var value=_ember['default'].typeOf(this.get(key));if(value === 'string' || value === 'number' || value === 'boolean'){properties.push(key);}}return properties;}});exports['default'] = GoalModel;}); /*validator,*/