define('gooru-web/models/result/assessment',['exports','ember','gooru-web/models/result/question','gooru-web/models/result/resource','gooru-web/utils/question-result'],function(exports,_ember,_gooruWebModelsResultQuestion,_gooruWebModelsResultResource,_gooruWebUtilsQuestionResult){ /**
 * Model for a group of questions that were answered by a user during one attempt to complete an assessment.
 *
 * @typedef {Object} AssessmentResult
 *
 */exports['default'] = _ember['default'].Object.extend({ //
// Properties
/**
   * @property {ResourceResult[]} resourceResults
   */resourceResults:_ember['default'].A([]), /**
   * @property {string} sessionId
   */sessionId:null, /**
   * The owner collection
   * @property {Collection}
   */collection:null, /**
   * Collection score, could be null
   * @property {number}
   */score:null, /**
   * Collection time spent, could be null
   * @property {number}
   */timeSpent:null, /**
   * @property {number}
   */views:null, /**
   * @property {QuestionResult[]} questionResults
   */questionResults:_ember['default'].computed('resourceResults.[]',function(){return this.get('resourceResults').filter(function(resourceResult){return resourceResult instanceof _gooruWebModelsResultQuestion['default'];});}), /**
   * @property {QuestionResult[]} nonOpenEndedQuestionResults
   */nonOpenEndedQuestionResults:_ember['default'].computed('questionResults.[]',function(){return this.get('questionResults').filter(function(questionResult){return !questionResult.get('question.isOpenEnded');});}), /**
   * @property {QuestionResult[]} openEndedQuestionResults
   */openEndedQuestionResults:_ember['default'].computed('questionResults.[]',function(){return this.get('questionResults').filter(function(questionResult){return questionResult.get('question.isOpenEnded');});}), /**
   * @property {QuestionResult[]} questionResults
   */resources:_ember['default'].computed('resourceResults.[]',function(){return this.get('resourceResults').filter(function(resourceResult){return !(resourceResult instanceof _gooruWebModelsResultQuestion['default']);});}), /**
   * @property {QuestionResult[]} questionResults
   */sortedResourceResults:_ember['default'].computed('resourceResults.[]',function(){return this.get('resourceResults').sortBy('resource.order');}), /**
   * TODO: TBD
   * @property {Object[]} mastery - An array of learning target objects
   * Each object should have the following properties:
   *
   * standard: DS.attr('string'),       // standard text
   * learningTarget: DS.attr('string'), // learning target text
   * relatedQuestions: DS.attr()        // array of question result ids; these correspond to the ids in questionResults
   * suggestedResources: DS.attr()      // array of resource cards
   */mastery:[], /**
   * Indicates if it has mastery
   * @property {boolean} hasMastery
   */hasMastery:_ember['default'].computed.bool('mastery.length'), /**
   * @property {Date} submittedAt - Date in which the attempt was submitted
   */submittedAt:null, /**
   * @property {Date} startedAt - Date in which the attempt was started
   */startedAt:null, /**
   * @property {string} title - Title of the assessment
   */title:_ember['default'].computed.alias('collection.title'), /**
   * @property {number} totalAttempts - Number of attempts the user has made for this assessment
   */totalAttempts:0, /**
   * @property {number}
   */totalResources:_ember['default'].computed.alias('resourceResults.length'), /**
   * @property {number}
   */totalNonOpenEndedQuestions:_ember['default'].computed.alias('nonOpenEndedQuestionResults.length'), /**
   * @property {boolean}
   */hasNonOpenEndedQuestions:_ember['default'].computed.bool('totalNonOpenEndedQuestions'), /**
   * @property {boolean} submitted
   */submitted:false, /**
   * @property {boolean} started
   */started:_ember['default'].computed.bool('startedAt'), /**
   * @property {boolean} is a real time result
   */isRealTime:false, /**
   * Returns the last visited resource
   * @property {Resource} lastVisitedResource
   */lastVisitedResource:(function(){var resourceResults=this.get('sortedResourceResults');var result=resourceResults.filterBy('started',true).get('lastObject');return result?result.get('resource'):resourceResults.get('firstObject').get('resource');}).property(), // -------------------------------------------------------------------------
// Computed Properties
/**
   * Average user reaction to the questions in the assessment
   * @prop {number} averageReaction
   */averageReaction:_ember['default'].computed('resourceResults.[]',function(){return (0,_gooruWebUtilsQuestionResult.averageReaction)(this.get('resourceResults'));}), /**
   * Percentage of correct answers vs. the total number of questions
   * @prop {number}
   */correctPercentage:_ember['default'].computed('nonOpenEndedQuestionResults.[]',function(){var score=this.get('score');return score?score:(0,_gooruWebUtilsQuestionResult.correctPercentage)(this.get('nonOpenEndedQuestionResults'),!this.get('isRealTime'));}), /**
   * Total number of seconds spent completing the current attempt
   * @prop {number}
   */totalTimeSpent:_ember['default'].computed('resourceResults.[]',function(){var timeSpent=this.get('timeSpent');return timeSpent?timeSpent:(0,_gooruWebUtilsQuestionResult.totalTimeSpent)(this.get('resourceResults'));}), /**
   * Total correct answers
   * @prop {number}
   */correctAnswers:_ember['default'].computed('nonOpenEndedQuestionResults.[]',function(){return (0,_gooruWebUtilsQuestionResult.correctAnswers)(this.get('nonOpenEndedQuestionResults'));}), /**
   * Total completed questions
   * @prop {number}
   */totalQuestionsCompleted:_ember['default'].computed('nonOpenEndedQuestionResults.[]',function(){return (0,_gooruWebUtilsQuestionResult.totalCompleted)(this.get('nonOpenEndedQuestionResults'));}), /**
   * Returns pending question results, those started results but not submitted
   * @return {QuestionResult[]}
   */pendingQuestionResults:_ember['default'].computed('questionResults.[]',function(){var questionResults=this.get('questionResults');return questionResults.filterBy('pending',true);}), // -------------------------------------------------------------------------
// Methods
/**
   * Initializes the assessment results
   * @param {Collection} collection
   */merge:function merge(collection){var resourceResults=this.get('resourceResults');var resources=collection.get('resources');this.set('collection',collection);if(resources.get('length')){this.addMissingResource(resources,resourceResults);}else {_ember['default'].Logger.error('Playlist with ID: ' + collection.get('id') + ' does not have any materials. No material results were set.');}this.removeExtraResource(resources,resourceResults);}, /**
   * add missing collection resource in the resourceResults
   * @param {QuestionResult[]} resources
   * @param {QuestionResult[]} resourceResults
   */addMissingResource:function addMissingResource(resources,resourceResults){resourceResults.forEach(function(resourceResult){var collectionResource=resources.findBy('id',_ember['default'].get(resourceResult,'resourceId'));_ember['default'].set(resourceResult,'resource',collectionResource);if(resourceResult.subQuestions){resourceResult.subQuestions.forEach(function(subQuestion){var subResource=collectionResource.subQuestions.findBy('id',subQuestion.resourceId);subQuestion.set('resource',subResource);});}});resources.forEach(function(resource){var resourceId=resource.get('id');var found=resourceResults.findBy('resourceId',resourceId);if(!found){var result=resource.get('isQuestion')?_gooruWebModelsResultQuestion['default'].create({resourceId:resourceId,resource:resource}):_gooruWebModelsResultResource['default'].create({resourceId:resourceId,resource:resource});resourceResults.pushObject(result);}else {_ember['default'].set(found,'resource',resource);}});}, /**
   * remove an extra collection resource of the resourceResults
   * @param {QuestionResult[]} resources
   * @param {QuestionResult[]} resourceResults
   */removeExtraResource:function removeExtraResource(resources,resourceResults){if(resourceResults.get('length')){var extraResources=resourceResults.filter(function(resource){var resourceResultId=_ember['default'].get(resource,'resourceId');return !resources.findBy('id',resourceResultId);});resourceResults.removeObjects(extraResources);}}, /**
   * Gets the result by resource id
   * @param {string} resourceId
   * @returns {ResourceResult}
   */getResultByResourceId:function getResultByResourceId(resourceId){return this.get('resourceResults').findBy('resourceId',resourceId);}, /**
   * Fix the order of the resourceResults list
   * @param {A[]} resourceResults
   * @returns {ResourceResult}
   */fixResultsOrder:function fixResultsOrder(){this.get('sortedResourceResults').forEach(function(resourceResult,index){_ember['default'].set(resourceResult,'resource.order',index + 1);});}});});