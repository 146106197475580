define('gooru-web/components/content/modals/gru-question-remix',['exports','ember','gooru-web/components/content/modals/gru-base-remix'],function(exports,_ember,_gooruWebComponentsContentModalsGruBaseRemix){exports['default'] = _gooruWebComponentsContentModalsGruBaseRemix['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} Assessment service API SDK
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {Service} Collection service API SDK
   */collectionService:_ember['default'].inject.service('api-sdk/collection'), /**
   * @property {Service} Question service API SDK
   */questionService:_ember['default'].inject.service('api-sdk/question'),fluencyService:_ember['default'].inject.service('api-sdk/fluency'),tenantService:_ember['default'].inject.service('api-sdk/tenant'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-question-remix'],isShowFluencyLevel:_ember['default'].computed(function(){var tenantSettings=JSON.parse(this.get('tenantService').getStoredTenantSetting());return tenantSettings && tenantSettings.fluency_level === 'on';}), // -------------------------------------------------------------------------
// Actions
copyContent:function copyContent(question){return this.get('questionService').copyQuestion(question.get('id'));},updateContent:function updateContent(question){var component=this;return component.get('questionService').updateQuestionTitle(question.get('id'),question.get('title')).then(function(){var collectionId=component.get('collectionId');var format=component.get('isCollection')?'collections':'assessments';if(component.get('model').collection.metadata && component.get('model').collection.metadata.fluency && component.get('isShowFluencyLevel')){var fluData=_ember['default'].Object.create({});fluData.fluency = component.get('model').collection.metadata.fluency;component.get('fluencyService').updateFluencyLevel(fluData,format,collectionId);}var parentService=component.get('isCollection')?component.get('collectionService'):component.get('assessmentService');return collectionId?parentService.addQuestion(collectionId,question.get('id')):_ember['default'].RSVP.resolve();});},showSuccessNotification:function showSuccessNotification(question){var component=this;var successMsg=component.get('i18n').t('common.remix-question-success',{questionTitle:question.get('title')});var questionEditUrl=component.get('router').generate('content.questions.edit',question.get('id'));var edit=component.get('i18n').t('common.edit');component.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + questionEditUrl + '">' + edit + '</a>');},showFailureNotification:function showFailureNotification(){var message=this.get('i18n').t('common.errors.question-not-copied').string;this.get('notifications').error(message);},init:function init(){this._super.apply(this,arguments);this.set('collectionId',this.get('model.collectionId'));this.set('isCollection',this.get('model.isCollection'));},collectionId:null,isCollection:null});});