define('gooru-web/components/cards/gru-class-card',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @type {ClassService} Service to retrieve class information
   */classService:_ember['default'].inject.service('api-sdk/class'), /**
   * @type {CourseService} Service to retrieve course information
   */courseService:_ember['default'].inject.service('api-sdk/course'),performanceService:_ember['default'].inject.service('api-sdk/performance'), // -------------------------------------------------------------------------
// Attributes
classNames:['cards gru-class-card '], // -------------------------------------------------------------------------
// Properties
/**
   * @property {Class} class information
   */'class':null, /**
   * @property {course} course information
   */course:null, /**
   * @property {Object} Object containing student count by class
   */classStudentCount:null, /**
   * @property {Boolean} Indicates if units count is displayed
   */showUnitsCount:false, /**
   * @property {Profile}
   */profile:null, /**
   * @property {boolean} Indicates if the class path
   */classPath:_ember['default'].computed('profile',function(){return this.get('profile').get('role') === 'student'?'student.class':'class.overview';}), /**
   * @property {Number} Count of collaborators in the class
   */collaboratorsCount:_ember['default'].computed('class.collaborators',function(){var collaborators=this.get('class.collaborators');return collaborators && collaborators.length > 1?collaborators.length - 1:0;}), /**
   * @property {Number} Count of students in the class
   */studentCount:_ember['default'].computed('class.id','classStudentCount',function(){var classStudentCount=this.get('classStudentCount');return classStudentCount && _ember['default'].keys(classStudentCount).length?classStudentCount[this.get('class.id')]?classStudentCount[this.get('class.id')]:0:0;}), /**
   * @property {Number} score percentage
   * Computed property for performance score percentage
   */scorePercentage:_ember['default'].computed('class.performanceSummary',function(){var scorePercentage=this.get('class.performanceSummary.score');return scorePercentage >= 0 && scorePercentage !== null?scorePercentage + '%':'_';}), /**
   * @property {Boolean}
   * Computed property  to identify class is started or not
   */hasStarted:_ember['default'].computed('class.performanceSummary',function(){var scorePercentage=this.get('class.performanceSummary.score');return scorePercentage !== null && scorePercentage >= 0;}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     *
     * Triggered when an menu item is selected
     * @param item
     */selectItem:function selectItem(item){var classId=this.get('class.id');if(this.get('onItemSelected')){this.sendAction('onItemSelected',item,classId);}}, /**
     * Action triggered when click on archived class report
     */onShowArchivedClassReport:function onShowArchivedClassReport(){var component=this;component.fetchClassDetails().then(function(classData){component.sendAction('onShowArchivedClassReport',classData);});}}, // -------------------------------------------------------------------------
// Events
init:function init(){var component=this;component._super.apply(component,arguments);}, // -------------------------------------------------------------------------
// Functions
/**
   * @function fetchClassDetails
   * Method used to fetch class members and course data
   */fetchClassDetails:function fetchClassDetails(){var component=this;var classService=component.get('classService');var courseService=component.get('courseService');var classData=component.get('class');return _ember['default'].RSVP.hash({classMembers:_ember['default'].RSVP.resolve(classService.readClassMembers(classData.get('id'))),course:_ember['default'].RSVP.resolve(courseService.fetchById(classData.get('courseId')))}).then(function(_ref){var classMembers=_ref.classMembers;var course=_ref.course;if(!component.isDestroyed){classData.set('owner',classMembers.get('owner'));classData.set('collaborators',classMembers.get('collaborators'));classData.set('memberGradeBounds',classMembers.get('memberGradeBounds'));classData.set('members',classMembers.get('members'));classData.set('course',course);return classData;}});}});});