define('gooru-web/components/reports/assessment/questions/gru-basword',['exports','ember','quizzes-addon/mixins/reports/assessment/questions/question'],function(exports,_ember,_quizzesAddonMixinsReportsAssessmentQuestionsQuestion){ /**
 * SERP Underline
 *
 * Component responsible for show the reorder, what option are selected
 * and the correct option.
 *
 * @module
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','assessment','questions','gru-baseword'], // -------------------------------------------------------------------------
// Properties
didInsertElement:function didInsertElement(){var _this=this;if(this.get('baseAnswers') && this.get('baseAnswers').length){this.get('baseAnswers').forEach(function(element,answerIndex){var elementItem=!_this.get('showCorrect')?_this.get('answerObj')[answerIndex]:element;var correctAnswer=!_this.get('showCorrect')?JSON.parse(elementItem.text):element.correctAnswer;for(var index=0;index < correctAnswer.length;index++) {var elements=correctAnswer[index];var userEle=element.correctAnswer[index];_this.hightLightDefaultWord(elements,answerIndex,userEle);}});}},showCorrect:false,baseAnswers:_ember['default'].computed('question',function(){return this.get('question.answers');}), // ---------------------------------------------------------------
// Methods
hightLightDefaultWord:function hightLightDefaultWord(text,answerIndex,userEle){var component=this;var innerHTML='';var html='';var start=text.start;var end=text.end;var fulltext=text.word_text;var isCorrect=true;if(!component.get('showCorrect')){if(userEle){isCorrect = userEle.word_text === fulltext && userEle.word_text_type === text.word_text_type;}}html = '<span class="serp-hl-text basword-select ' + (isCorrect?'correct':'wrong') + ' disable-select">' + fulltext + '</span>';String.prototype.replaceBetween = function(start,end,what){return this.substring(0,start) + what + this.substring(end);};component.$('.base-word-edit-' + answerIndex)[0].childNodes.forEach(function(childNode){if(childNode.data && childNode.data.substring(start,end) === fulltext){innerHTML = innerHTML + childNode.data.replaceBetween(start,end,$.trim(html));}else if(childNode.data){innerHTML = innerHTML + childNode.data;}else {innerHTML = innerHTML + childNode.outerHTML;}});component.$('.base-word-edit-' + answerIndex).html(innerHTML);}});});