define('gooru-web/components/reports/pull-up/likert-scale-report',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['reports','backdrop-pull-ups','pull-up-question-report'], // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/analytics
   */analyticsService:_ember['default'].inject.service('api-sdk/analytics'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when the user invoke the pull up.
     **/onPullUpClose:function onPullUpClose(closeAll){this.closePullUp(closeAll);},reportClose:function reportClose(){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);});}}, /**
   * Function to triggered once when the component element is first rendered.
   */didInsertElement:function didInsertElement(){this.openPullUp();this.initialize();}, // -------------------------------------------------------------------------
// Properties
/**
   * Maintains context data
   * @type {Object}
   */context:null, /**
   * ClassId belongs to this collection report.
   * @type {String}
   */classId:_ember['default'].computed.alias('context.classId'), /**
   * CourseId belongs to this collection report.
   * @type {String}
   */courseId:_ember['default'].computed.alias('context.courseId'), /**
   * Unit belongs to this collection report.
   * @type {String}
   */unit:_ember['default'].computed.alias('context.unit'), /**
   * Lesson belongs to this question report.
   * @type {[type]}
   */lesson:_ember['default'].computed.alias('context.lesson'), /**
   * Collection belongs to this question report.
   * @type {Object}
   */collection:_ember['default'].computed.alias('context.collection'), /**
   * collectionId of this  question report.
   * @type {String}
   */collectionId:_ember['default'].computed.alias('context.collection.id'), /**
   * Selected question for this report
   * @type {Object}
   */selectedQuestion:_ember['default'].computed.alias('context.selectedQuestion'), /**
   * Propery to hide the default pullup.
   * @property {showPullUp}
   */showPullUp:false, /**
   * List of class members
   * @type {Object}
   */classMembers:_ember['default'].computed.alias('context.classMembers'), /**
   * It maintains the state of loading
   * @type {Boolean}
   */isLoading:false, /**
   *  It enable chart component
   */isUserChart:false,isEmptyQuestionArray:false,initialize:function initialize(){var component=this;component.set('isLoading',true);var classId=component.get('classId');var courseId=component.get('courseId');var unitId=component.get('unit.id');var lessonId=component.get('lesson.id');var collectionId=component.get('collection.id');var selectedQuestionId=component.get('selectedQuestion.id');return component.get('analyticsService').findLikertResources(classId,courseId,unitId,lessonId,collectionId,'assessment',selectedQuestionId).then(function(userResourcesResults){if(userResourcesResults.length > 0){component.set('userResourcesResults',userResourcesResults);component.set('isUserChart',true);component.set('userContext',component.get('context'));}else {component.set('isEmptyQuestionArray',true);}});},openPullUp:function openPullUp(){var component=this;component.$().animate({top:'10%'},400);},closePullUp:function closePullUp(closeAll){var component=this;component.$().animate({top:'100%'},400,function(){component.set('showPullUp',false);if(closeAll){component.sendAction('onClosePullUp');}});}});});