define('gooru-web/components/content/activity/task/submission/gru-submission-line-item',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({classNames:['uploaded-item'], /**------------------------------------------------------------------------------------------------
   * list submission, update parent for removal
   * ------------------------------------------------------------------------------------------------*/ // -------------------------------------------------------------------------
// Attributes
// -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Updates parent for removal
     */removeLineItem:function removeLineItem(){var component=this;component.get('removeLineItem')(component.get('submission'));},updateSubmissionCollection:function updateSubmissionCollection(){var component=this;component.get('updateSubmissionCollection')(component.get('submission').taskSubmissionSubType);}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);} // -------------------------------------------------------------------------
// Properties
});});