define('gooru-web/controllers/content/rubric/preview',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag){exports['default'] = _ember['default'].Controller.extend({ // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Performs a back action in the browser history
     */goBack:function goBack(){window.history.go(-1);}}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */tags:_ember['default'].computed('rubric.standards.[]',function(){return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('rubric.standards'),false);}), /**
   * @property {Ember.Object} resource
   * Resource to show on preview
   */resource:_ember['default'].computed('rubric.url',function(){return _ember['default'].Object.create({url:this.get('rubric.url')});})});});