define('gooru-web/adapters/collection/collection',['exports','gooru-web/adapters/application'],function(exports,_gooruWebAdaptersApplication){exports['default'] = _gooruWebAdaptersApplication['default'].extend({ /**
   * @property {string} End-point URI
   */namespace:'/gooruapi/rest/v3', /**
   * Builds the end-point URL for the findRecord queryParam, params are optional
   * @param modelName
   * @param id
   * @param snapshot
   * @returns {string}
   */urlForFindRecord:function urlForFindRecord(id){var namespace=this.get('namespace');var includeItemParam='includeItems=true';var includeLastModifiedUserParam='includeLastModifiedUser=true';return namespace + '/collection/' + id + '?' + includeItemParam + '&' + includeLastModifiedUserParam;}, /**
   * Builds the end-point URL for the queryRecord queryParam
   * @param query
   * @returns {string}
   */urlForQueryRecord:function urlForQueryRecord(query){var namespace=this.get('namespace');var classId=query.classId;var courseId=query.courseId;var unitId=query.unitId;var lessonId=query.lessonId;delete query.classId;delete query.courseId;delete query.unitId;delete query.lessonId;return namespace + '/class/' + classId + '/course/' + courseId + '/unit/' + unitId + '/lesson/' + lessonId;}});});