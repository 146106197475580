define('gooru-web/models/resource/answer',['exports','ember'],function(exports,_ember){ /**
 * @typedef {Object} Answer
 */exports['default'] = _ember['default'].Object.extend({ /**
   * @property {string}
   */id:null, /**
   * @property {string}
   */text:null, /**
   * @property {string}
   */answerType:null, /**
   * @property {string}
   */order:null, /**
   * @property {string}
   */isCorrect:null});});