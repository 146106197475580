define('gooru-web/components/class/gru-create-class-popup',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Component.extend({ // --------------------------------------------------------------------------
// Attributes
classNames:['gru-create-class-popup'],classNameBindings:['isNewClassCardOnly:class-card-only'], /**
   * @property {Service} taxonomy service API SDK
   */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'), /**
   * @property {Service} Class service API SDK
   */classService:_ember['default'].inject.service('api-sdk/class'),session:_ember['default'].inject.service('session'), // -----------------------------------------------------------------------
// Properties
/**
   * @property {Array} levelsList Holding level names
   */levelsList:['category','subject','framework','grade'], /**
   * @property {Array} classCreationLevels Holding number of steps to finish create class
   */classCreationLevels:_ember['default'].computed('levelsList',function(){var levelsList=this.get('levelsList').copy();levelsList = levelsList.map(function(level){return _ember['default'].Object.create({level:level,isActive:false,levelData:_ember['default'].A([])});});return _ember['default'].A(levelsList);}), /**
   * @property {Object} activeLevel Holding active level object
   */activeLevel:_ember['default'].computed('classCreationLevels.@each.isActive',function(){return this.get('classCreationLevels').findBy('isActive',true);}), /**
   * @property {Array} selectedList Holding selected list
   */selectedList:_ember['default'].computed('classCreationLevels.@each.isActive',function(){return this.get('classCreationLevels').mapBy('selectedItem').filter(function(item){return item;});}), /**
   * @property {Number} currentIndex Holding active index
   */currentIndex:_ember['default'].computed('classCreationLevels.@each.isActive',function(){return this.get('classCreationLevels').findIndex(function(level){return level.get('isActive');});}), /**
   * @property {Object} selectedSubject Holding active subject
   */selectedSubject:_ember['default'].computed('classCreationLevels.@each.isActive',function(){var subject=this.get('classCreationLevels').findBy('level','subject');return subject.get('selectedItem') || null;}), /**
   * @property {Object} selectedFramework Holding active framework
   */selectedFramework:_ember['default'].computed('classCreationLevels.@each.isActive',function(){var framework=this.get('classCreationLevels').findBy('level','framework');return framework.get('selectedItem') || null;}), /**
   * @property {Object} selectedGrade Holding active grade
   */selectedGrade:_ember['default'].computed('classCreationLevels.@each.isActive',function(){var grade=this.get('classCreationLevels').findBy('level','grade');return grade.get('selectedItem') || null;}), /**
   * @property {String} activePanelTitle Holding active path
   */activePanelTitle:_ember['default'].computed('selectedList',function(){var selectedItem=this.get('selectedList');return selectedItem.mapBy('title').join(' > ');}), /**
   * @property {Object} newClass
   * Property to hold new create class Name
   */newClass:null, /**
   * @property {Boolean} isShowCreateClassPop
   * Property help to show hide create class pop
   */isShowCreateClassPop:false, /**
   * @property {Boolean} isShowConfirmationPopup
   * Property help to show hide confirmation popup
   */isShowConfirmationPopup:false, // ---------------------------------------------------------------------------------------------
// Hooks
didInsertElement:function didInsertElement(){var firstLevel=this.get('classCreationLevels').get(0);firstLevel.set('isActive',true);this.categoryList();}, // --------------------------------------------------------------------------------------------
// Actions
actions:{ // Actions trigger when select any item from the panel
onSelectLevel:function onSelectLevel(item){var currentLevel=this.get('activeLevel');var currentIndex=this.get('currentIndex');var nextIndex=currentIndex + 1;if(nextIndex <= this.get('classCreationLevels').length - 1){currentLevel.setProperties({selectedItem:item,isActive:false});var nextLevel=this.get('classCreationLevels').get(nextIndex);nextLevel.set('isActive',true);this.onLoadAPI();}else {currentLevel.setProperties({selectedItem:item});this.set('selectedGrade',item);this.set('isShowConfirmationPopup',true);}}, // Action trigger when click back button on the panel
onSelectback:function onSelectback(){this.set('isShowConfirmationPopup',false);var currentLevel=this.get('activeLevel');var currentIndex=this.get('currentIndex');var prevIndex=currentIndex - 1;if(prevIndex >= 0){currentLevel.setProperties({selectedItem:null,isActive:false,levelData:_ember['default'].A([])});var prevLevel=this.get('classCreationLevels').get(prevIndex);prevLevel.setProperties({isActive:true,selectedItem:null});}}, // Action trigger when click on close button of create class popup
onClosePopup:function onClosePopup(){this.set('isShowCreateClassPop',false);}, // Action trigger when click on close confirmation button on confirm popup
onCloseConfirmation:function onCloseConfirmation(){this.set('isShowConfirmationPopup',false);}, // Action triggered when click confirmation button on confirm popup
onConfirmation:function onConfirmation(){var component=this;var selectedSubject=component.get('selectedSubject');var selectedFramework=component.get('selectedFramework');var newClass=component.get('newClass');var userId=this.get('session.userId');var lastAccessedClassIdList=JSON.parse(localStorage.getItem('last_accessed_classId_' + userId)) || [];component.get('classService').createClass(newClass).then(function(newClass){var currentDateTime=new Date().toISOString();var lastSelected={classId:newClass.id,currentDateTime:currentDateTime};lastAccessedClassIdList.push(lastSelected);localStorage.setItem('last_accessed_classId_' + userId,JSON.stringify(lastAccessedClassIdList));if(selectedSubject){(function(){var preferenceSettings={subject:selectedSubject.get('id'),framework:selectedFramework?selectedFramework.get('frameworkId'):null};component.get('classService').updatePreference(newClass.id,preferenceSettings).then(function(){var selectedGrade=component.get('selectedGrade');if(selectedGrade){var grade=component.get('classCreationLevels').findBy('level','grade');var initialGrade=grade.get('levelData').get(0);var settings={grade_lower_bound:initialGrade.get('id'),grade_current:component.get('selectedGrade.id'),force_calculate_ilp:true,preference:preferenceSettings};component.get('classService').classSettings(newClass.id,settings).then(function(){component.gotoClassSettings(newClass);});}else {component.gotoClassSettings(newClass);}});})();}else {component.gotoClassSettings(newClass);}});}}, // --------------------------------------------------------------------------------------------
// Methods
// Method used to fetch category list
categoryList:function categoryList(){var _this=this;this.get('taxonomyService').fetchCategories().then(function(categories){_this.set('activeLevel.levelData',categories);});}, // Method help to fetch subject list
subjectList:function subjectList(){var _this2=this;var category=this.get('classCreationLevels').findBy('level','category');var categoryId=category.get('selectedItem.id') || 'k_12';var filter={filter:'hasgrade'};this.get('taxonomyService').fetchSubjects(categoryId,filter).then(function(subject){subject = subject.filter(function(item){return item.get('frameworks').length;});_this2.set('activeLevel.levelData',subject);});}, // Methods help to fetch framework list
frameworkList:function frameworkList(){var subject=this.get('classCreationLevels').findBy('level','subject');var frameworks=subject.get('selectedItem.frameworks');if(frameworks){this.set('activeLevel.levelData',frameworks);}}, // Method help to fetch grade list
gradeList:function gradeList(){var _this3=this;var selectedSubject=this.get('selectedSubject');var selectedFramework=this.get('selectedFramework');var filter={subject:selectedSubject.get('id'),fw_code:selectedFramework.get('frameworkId')};this.get('taxonomyService').fetchGradesBySubject(filter).then(function(grades){_this3.set('activeLevel.levelData',grades);});}, // Method help to identify and call the api based on active level
onLoadAPI:function onLoadAPI(){var activeLevel=this.get('activeLevel');switch(activeLevel.get('level')){case 'category':this.categoryList();break;case 'subject':this.subjectList();break;case 'framework':this.frameworkList();break;case 'grade':this.gradeList();break;default:break;}}, // Method help to go class activities page
gotoClassActivity:function gotoClassActivity(newClass){var component=this;component.set('isShowCreateClassPop',false);component.set('isShowConfirmationPopup',false);component.get('router').transitionTo('teacher.class.class-activities',newClass.id);},gotoClassSettings:function gotoClassSettings(newClass){var component=this;component.set('isShowCreateClassPop',false);component.set('isShowConfirmationPopup',false);component.get('router').transitionTo('teacher.class.class-management',newClass.id);}});});