define('gooru-web/components/content/modals/gru-course-remix',['exports','ember','gooru-web/components/content/modals/gru-base-remix'],function(exports,_ember,_gooruWebComponentsContentModalsGruBaseRemix){exports['default'] = _gooruWebComponentsContentModalsGruBaseRemix['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} User service API SDK
   */courseService:_ember['default'].inject.service('api-sdk/course'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-course-remix'], // -------------------------------------------------------------------------
// Actions
copyContent:function copyContent(course){return this.get('courseService').copyCourse(course.get('id'));},updateContent:function updateContent(course){return this.get('courseService').updateCourseTitle(course.get('id'),course.get('title'));},showSuccessNotification:function showSuccessNotification(course){var component=this;var successMsg=component.get('i18n').t('common.remix-course-success',{courseTitle:course.get('title')});var courseEditUrl=component.get('router').generate('content.courses.edit',course.get('id'));var edit=component.get('i18n').t('common.edit');component.get('notifications').success(successMsg + ' <a class="btn btn-success" href="' + courseEditUrl + '">' + edit + '</a>');},showFailureNotification:function showFailureNotification(){var message=this.get('i18n').t('common.errors.course-not-copied').string;this.get('notifications').error(message);}, // -------------------------------------------------------------------------
// Methods
/**
   * Overwrite beforeCopy
   */beforeCopy:function beforeCopy(){var component=this;component.closeModal();}, /**
   * Overwrite afterCopy
   */afterCopy:function afterCopy(contentModel){var component=this;component.notifyCopy(contentModel);}});});