define('gooru-web/components/player/questions/gru-hs-image',['exports','ember','gooru-web/components/player/questions/gru-hs-text'],function(exports,_ember,_gooruWebComponentsPlayerQuestionsGruHsText){ /**
 * Hot Spot Image
 *
 * Component responsible for controlling the logic and appearance of a hot spot
 * image question inside of the {@link player/gru-question-viewer.js}
 *
 * @module
 * @see controllers/player.js
 * @see components/player/gru-question-viewer.js
 * @augments components/player/questions/gru-hs-text.js
 */exports['default'] = _gooruWebComponentsPlayerQuestionsGruHsText['default'].extend({ // -------------------------------------------------------------------------
// Dependencies
i18n:_ember['default'].inject.service(), // -------------------------------------------------------------------------
// Attributes
classNames:['gru-hs-image'], // -------------------------------------------------------------------------
// Actions
// -------------------------------------------------------------------------
// Events
// -------------------------------------------------------------------------
// Properties
/**
   * @property {String} itemType
   */itemType:'hs-image', /*
   * @prop {String} instructions - Question instructions
   */instructions:_ember['default'].computed(function(){var action=this.get('i18n').t(this.get('instructionsActionTextKey')).string;return this.get('i18n').t('gru-hs-image.instructions',{action:action});}), /*
   * @typedef answers
   * @prop {id} id - answer id
   * @prop {string} content - url string for an image
   */answers:_ember['default'].computed.map('question.answers',function(answer){return {id:answer.get('id'),content:answer.get('text')};}) // -------------------------------------------------------------------------
// Observers
// -------------------------------------------------------------------------
// Methods
});});