define('gooru-web/models/result/resource',['exports','ember','gooru-web/mixins/serializable'],function(exports,_ember,_gooruWebMixinsSerializable){ /**
 * Model for the status of a resource after it has been viewed by a user.
 *
 * @typedef {Object} ResourceResult
 *
 */exports['default'] = _ember['default'].Object.extend(_gooruWebMixinsSerializable['default'],{ /**
   * @property {number} reaction - Value of the reaction the user had towards the question
   */reaction:0, /**
   * @property {number} timeSpent - Time in seconds that it took the user to answer the question
   *
   * This value is also modify by @see submittedAt and @see startedAt property definition
   */timeSpent:0, /**
   * @property {Resource} resource
   */resource:null, /**
   * @property {string} uuid - This is used for eventId and sessionId, should be generated in the FE, you can use generateUUID inside utils folder
   */uuid:null, /**
   * Sometimes the resource is not resolved and only the id is provided
   * This is used mostly by the real time
   * TODO once the SDK is integrated we could analyze if is possible to use only 'resource'
   * @property {number} resourceId - ID of the resource
   */resourceId:null, /**
   * Indicates when the result was started
   * @property {Date}
   */startedAt:null, /**
   * Indicates when the result was submitted
   * @property {Date}
   */submittedAt:null, /**
   * Indicates the amount of executions for the same result
   * @property {number}
   */attempts:null, /**
   * A result is started when it has time spent
   * @property {boolean} indicates when it has been started
   */started:_ember['default'].computed.bool('timeSpent'), /**
   * Indicates if it is submitted
   * @return {boolean}
   */submitted:_ember['default'].computed.bool('submittedAt'), /**
   * Indicates if the result is pending, it means it has started but not submitted
   * @property {boolean}
   */pending:_ember['default'].computed('startedAt','submitted',function(){return this.get('startedAt') && !this.get('submitted');}), /**
   * Indicates if the resource was skipped, a result is skipped
   * @property {boolean}
   */skipped:_ember['default'].computed.not('startedAt'), /**
   * Indicates if it is completed
   * All started question are treated as completed
   * @return {boolean}
   */completed:_ember['default'].computed.bool('started'), /**
   * @property {String}
   */attemptStatus:_ember['default'].computed('started',function(){return this.get('started')?'started':'skipped';}), /**
   * Unique Id generated by FE for every event. This is used at the player only
   * This eventId should be same for start and stop event
   * @property {string} resourceEventId
   */resourceEventId:null, // -------------------------------------------------------------------------
// Observer
/**
   * When the start at changes it resets some properties
   */onStartAtChange:_ember['default'].observer('startedAt',function(){this.set('submittedAt',null);}), /**
   * When the submitted at changes it resets some properties
   */onSubmittedAtChange:_ember['default'].observer('submittedAt',function(){var timeSpent=0;var submittedAt=this.get('submittedAt');if(submittedAt){var startedAt=this.get('startedAt');if(startedAt){ //updating time spent when submitted at is changed
timeSpent = submittedAt.getTime() - startedAt.getTime();}}this.set('timeSpent',timeSpent);}),toJSON:function toJSON(){return {gooruOId:this.get('resourceId'),reaction:this.get('reaction'),timeSpent:this.get('timeSpent'),resourceType:'resource'};}});});