define('gooru-web/adapters/sign-in',['exports','gooru-web/adapters/application','ember'],function(exports,_gooruWebAdaptersApplication,_ember){exports['default'] = _gooruWebAdaptersApplication['default'].extend({session:_ember['default'].inject.service('session'), /**
   * @property {string} End-point URI
   */namespace:'/gooruapi/rest/v2/account/login',signInNamespace:'/api/nucleus-auth/v2/users', /**
   * Builds the end-point URL using the apiKey as a query string param
   * @param modelName
   * @param id
   * @param snapshot
   * @param requestType
   * @param query
   * @returns {string}
   */buildURL:function buildURL(modelName,id,snapshot,requestType,query){var apiKeyParam='apiKey=' + this.get('apiKey');return this._super(modelName,id,snapshot,requestType,query) + '?' + apiKeyParam;}, /**
   * Fetch user tenant accounts
   * @returns {Promise.<[]>}
   */getUserTenantAccounts:function getUserTenantAccounts(user){var adapter=this;var namespace=adapter.get('signInNamespace');var url=namespace + '/accounts';var options={type:'POST',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',data:JSON.stringify({email:user.get('email')})};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});