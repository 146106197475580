define('gooru-web/controllers/reports/student-collection',['exports','ember','gooru-web/mixins/configuration','gooru-web/config/config','gooru-web/utils/taxonomy','gooru-web/utils/tenant','gooru-web/mixins/reports/player-report-mixin','gooru-web/mixins/tenant-settings-mixin'],function(exports,_ember,_gooruWebMixinsConfiguration,_gooruWebConfigConfig,_gooruWebUtilsTaxonomy,_gooruWebUtilsTenant,_gooruWebMixinsReportsPlayerReportMixin,_gooruWebMixinsTenantSettingsMixin){ /**
 *
 * Controls the access to the analytics data for a
 * student related to a collection of resources
 *
 */exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsConfiguration['default'],_gooruWebMixinsReportsPlayerReportMixin['default'],_gooruWebMixinsTenantSettingsMixin['default'],{queryParams:['classId','courseId','unitId','lessonId','collectionId','userId','type','role','contextId','source','minScore','milestoneId','isIframeMode','isLTIResourceLinkLaunch'], // -------------------------------------------------------------------------
// Dependencies
/**
     * @requires service:api-sdk/taxonomy
     */taxonomyService:_ember['default'].inject.service('api-sdk/taxonomy'),session:_ember['default'].inject.service('session'), /**
     * @property {activityFeedbackService}
     */activityFeedbackService:_ember['default'].inject.service('api-sdk/activity-feedback'),tenantService:_ember['default'].inject.service('api-sdk/tenant'),i18n:_ember['default'].inject.service(),init:function init(){this.set('bgUrl',this.tenantSettingBgCheck());}, // -------------------------------------------------------------------------
// Actions
actions:{showTimer:function showTimer(){this.toggleProperty('isShowStudyTimer');},showFeedbackContainer:function showFeedbackContainer(){this.toggleProperty('isShowFeedbackContainer');},submitUserFeedback:function submitUserFeedback(){this.set('isShowFeedbackContainer',false);this.set('isShowStudyTimer',false);window.parent.postMessage(_gooruWebConfigConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE,'*');}}, // -------------------------------------------------------------------------
// Properties
/**
     * @property {string} indicates if it is collection or assessment
     */type:null, /**
     * @property {string} indicates if it is a student or teacher view
     */role:null, /**
     * Indicates the component of the application that is originating the events
     * @property {String} source
     */source:null, /**
     * @property {Boolean} isAnonymous
     */isAnonymous:_ember['default'].computed.alias('session.isAnonymous'),isIframeMode:false,isLTIResourceLinkLaunch:false, /**
     * @property {Boolean} isShowActivityFeedback
     * Property to evaluate whether the feedback tab should shown
     */isShowActivityFeedback:false, /**
     * @property {array[]} feedbackCategory
     * store feedback category list
     */feedbackCategory:null,masteryMinScore:_gooruWebConfigConfig.SCORES.VERY_GOOD, /**
     * @property {boolean} showCorrectAnswer
     * Property to show/hide correct answer toggle
     */showCorrectAnswer:_ember['default'].computed('currentClass',function(){var showCorrectAnswer=true;var currentClass=this.get('currentClass');if(currentClass){var setting=currentClass.get('setting');if(setting){showCorrectAnswer = setting.show_correct_answer || setting['show.correct.answer'];}}return showCorrectAnswer === undefined?true:showCorrectAnswer;}), /**
     * @property {boolean} isAnswerKeyHidden - Should the answer key be hidden?
     */isAnswerKeyHidden:_ember['default'].computed('showCorrectAnswer','masteryMinScore',function(){var controller=this;var minScore=controller.get('minScore');var showCorrectAnswer=controller.get('showCorrectAnswer');var masteryMinScore=controller.get('masteryMinScore');var averageScore=controller.get('attemptData.averageScore');var attempts=controller.get('collectionObj.attempts');var totalAttempts=controller.get('totalAttempts').length;var role=controller.get('role');var type=controller.get('type');if(role === _gooruWebConfigConfig.ROLES.STUDENT && type === _gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT){var answerKeyHidden=showCorrectAnswer && (minScore && minScore <= averageScore || averageScore >= masteryMinScore || attempts === totalAttempts);return !answerKeyHidden;}return !controller.get('showCorrectAnswer');}), /**
     * @property {boolean} showExemplarText
     * Property to show exemplar text
     */showExemplarText:_ember['default'].computed('currentClass',function(){var component=this;var classData=component.get('currentClass');return component.isShowExemplarText(classData);}), /**
     * @property {Boolean}
     */isPublicClass:_ember['default'].computed.alias('currentClass.isPublic'), /**
     * @property {boolean} isShowStudyTimer
     * Property to show/hide study timer component
     */isShowStudyTimer:false, // -------------------------------------------------------------------------
// Methods
/**
     * @function findCompletionMinScore
     * @return {Number | Promise}
     * Method to find completion min score based on assessment subject
     * and framework tagged with class/tenants frameworks
     * otherwise it will be either teanant's default score or Gooru's default score
     */findCompletionMinScore:function findCompletionMinScore(){var controller=this;var assessment=this.get('collectionObj');var classId=this.get('classId') || this.get('mapLocation.context.classId');var assessmentGutCodes=assessment.get('gutCodes');if(classId && assessmentGutCodes && assessmentGutCodes.length){return _ember['default'].RSVP.hash({classData:classId?controller.get('currentClass'):_ember['default'].RSVP.resolve({}),tenantSetting:this.getTenantSetting()}).then(function(_ref){var classData=_ref.classData;var tenantSetting=_ref.tenantSetting;var assSubject=(0,_gooruWebUtilsTaxonomy.getSubjectId)(assessmentGutCodes[0]);var tenantCompletionScore=(0,_gooruWebUtilsTenant.getMasteryMinScore)(assSubject,classData.preference,tenantSetting);controller.set('masteryMinScore',tenantCompletionScore || _gooruWebConfigConfig.SCORES.VERY_GOOD);return tenantCompletionScore || _gooruWebConfigConfig.SCORES.VERY_GOOD;});}return _ember['default'].RSVP.resolve(_gooruWebConfigConfig.SCORES.VERY_GOOD);}, /**
     * @function getTenantSetting
     * Method to get tenant setting for active user
     */getTenantSetting:function getTenantSetting(){return this.get('tenantService').getActiveTenantSetting();}, /**
     * @function fetchActivityFeedbackCateory
     * Method to fetch learning activity feedback
     */fetchActivityFeedbackCateory:function fetchActivityFeedbackCateory(){var controller=this;var role=controller.get('session.role');var userCategoryId=_gooruWebConfigConfig.FEEDBACK_USER_CATEGORY['' + role];controller.get('activityFeedbackService').getFeedbackCategory(userCategoryId).then(function(categoryLists){controller.set('categoryLists',categoryLists);var isCollection=controller.get('collection.isCollection');var type=isCollection?_gooruWebConfigConfig.CONTENT_TYPES.COLLECTION:_gooruWebConfigConfig.CONTENT_TYPES.ASSESSMENT;var contentCategory=categoryLists.get(type + 's');if(contentCategory && contentCategory.length){controller.set('isShowActivityFeedback',true);controller.set('feedbackCategory',contentCategory.sortBy('feedbackTypeId'));controller.set('format',type);}else {controller.set('isShowActivityFeedback',false);controller.set('feedbackCategory',null);}});}, /**
     * @function getFeedbackObject
     * Method to return feedback objective
     */getFeedbackObject:function getFeedbackObject(){var controller=this;var userId=controller.get('session.userId');var role=controller.get('session.role')?controller.get('session.role'):_gooruWebConfigConfig.ROLES.STUDENT;var userCategoryId=_gooruWebConfigConfig.FEEDBACK_USER_CATEGORY['' + role];var userFeedback=_ember['default'].A([]);var categoryLists=controller.get('userCategoryFeedback');var collection=controller.get('collection');categoryLists.map(function(category){var feedbackObj={feeback_category_id:category.categoryId};if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE){feedbackObj.user_feedback_quantitative = category.rating;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.BOTH){feedbackObj.user_feedback_qualitative = category.comments;}else if(category.feedbackTypeId === _gooruWebConfigConfig.FEEDBACK_RATING_TYPE.QUALITATIVE){feedbackObj.user_feedback_qualitative = category.quality;}userFeedback.pushObject(feedbackObj);});var userFeedbackObj={content_id:collection.get('id'),content_type:controller.get('format'),user_category_id:userCategoryId,user_feedbacks:userFeedback,user_id:userId};return userFeedbackObj;}});});