define('gooru-web/adapters/performance/performance',['exports','ember'],function(exports,_ember){ /**
 * Adapter to support the Performance API
 *
 * @typedef {Object} PerformanceAdapter
 */exports['default'] = _ember['default'].Object.extend({session:_ember['default'].inject.service('session'),namespace:'/api/ds/users',insightsNamespace:'/api/nucleus-insights/v2',visibilityNamespace:'/api/nucleus/v1/classes/learners', /**
   * Get performance of user performance units
   * @returns {Promise.<[]>}
   */getUserPerformanceUnits:function getUserPerformanceUnits(user,courseId,classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/v2/user/performance/course';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',data:{courseId:courseId,user:user}};if(classId){options.data.classId = classId;}return _ember['default'].RSVP.hashSettled({userPerformanceUnits:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.userPerformanceUnits.value;});},getTeacherClassPerformance:function getTeacherClassPerformance(classId,courseId,collectionType){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/performance';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:{collectionType:collectionType}};return _ember['default'].$.ajax(url,options);},getTeacherPerformanceForUnit:function getTeacherPerformanceForUnit(classId,courseId,collectionType,unitId){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/unit/' + unitId + '/performance';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:{collectionType:collectionType}};return _ember['default'].$.ajax(url,options);}, /**
   * Get performance of user performance lessons
   * @returns {Promise.<[]>}
   */getUserPerformanceLessons:function getUserPerformanceLessons(user,courseId,unitId,classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/v2/user/performance/lessons';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',data:{courseId:courseId,unitId:unitId,user:user}};if(classId){options.data.classId = classId;}return _ember['default'].RSVP.hashSettled({userPerformanceLessons:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.userPerformanceLessons.value;});}, /**
   * Get performance of user performance collections
   * @returns {Promise.<[]>}
   */getUserPerformanceCollections:function getUserPerformanceCollections(user,courseId,unitId,lessonId,classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/v2/user/performance/collections';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',data:{courseId:courseId,unitId:unitId,lessonId:lessonId,user:user}};if(classId){options.data.classId = classId;}return _ember['default'].RSVP.hashSettled({userPerformanceCollections:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.userPerformanceCollections.value;});}, /**
   * Get performance of user  resource in assessments
   * @returns {Promise.<[]>}
   */getUserPerformanceResourceInAssessment:function getUserPerformanceResourceInAssessment(user,courseId,unitId,lessonId,assessmentId,sessionId,classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/v2/user/summary/assessment';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',data:{assessmentId:assessmentId,user:user,sessionId:sessionId}};if(classId){options.data.classId = classId;}if(courseId){options.data.courseId = courseId;}if(unitId){options.data.unitId = unitId;}if(lessonId){options.data.lessonId = lessonId;}return _ember['default'].RSVP.hashSettled({userResourceInAssessment:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.userResourceInAssessment.value;});}, /**
   * Get performance of user  resource in collection
   * @returns {Promise.<[]>}
   */getUserPerformanceResourceInCollection:function getUserPerformanceResourceInCollection(user,courseId,unitId,lessonId,collectionId,sessionId,classId){var adapter=this;var namespace=adapter.get('namespace');var url=namespace + '/v2/user/summary/collection';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',data:{collectionId:collectionId,user:user,sessionId:sessionId}};if(classId){options.data.classId = classId;}if(courseId){options.data.courseId = courseId;}if(unitId){options.data.unitId = unitId;}if(lessonId){options.data.lessonId = lessonId;}return _ember['default'].RSVP.hashSettled({userResourceInCollection:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.userResourceInCollection.value;});}, /**
   * Get performance of user resource in collection for teacher view
   * @returns {Promise.<[]>}
   */getStudentsCollectionPerformance:function getStudentsCollectionPerformance(classId,courseId,unitId,lessonId,type){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/study/assessment/performance';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',data:{classId:classId,courseId:courseId,unitId:unitId,lessonId:lessonId,collectionType:type}};return _ember['default'].RSVP.hashSettled({collectionPerformance:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.collectionPerformance.value;});},updateCollectionOfflinePerformance:function updateCollectionOfflinePerformance(performanceData){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/offline-report';var options={type:'POST',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'text',data:JSON.stringify(performanceData)};return _ember['default'].RSVP.hashSettled({updatedPerformance:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.updatedPerformance;});}, /**
   * @function getCAPerformanceData
   * performance Data of Class Activities for ALL classes of a Student/Teacher
   */getCAPerformanceData:function getCAPerformanceData(classIds,userId){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/dca/classes/performance';var options={type:'POST',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:JSON.stringify({classIds:classIds,userId:userId})};return _ember['default'].$.ajax(url,options);}, /**
   * @function overwriteCollectionPerformance
   * Method to overwrite collection performance data
   */overwriteCollectionPerformance:function overwriteCollectionPerformance(performanceData){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/perf-update';var options={type:'PUT',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'text',data:JSON.stringify(performanceData)};return _ember['default'].RSVP.hashSettled({overwritedPerformance:_ember['default'].$.ajax(url,options)}).then(function(hash){return hash.overwritedPerformance;});}, /**
   * @function getPerformanceForUnits
   * Get units Performance Data for route0
   */getPerformanceForUnits:function getPerformanceForUnits(classId,courseId,collectionType,userUid){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/performance';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:{collectionType:collectionType,userUid:userUid}};return _ember['default'].$.ajax(url,options);}, /**
   * @function getPerformanceForLessons
   * Get lessons Performance Data for route0
   */getPerformanceForLessons:function getPerformanceForLessons(classId,courseId,unitId,collectionType,userUid){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/class/' + classId + '/course/' + courseId + '/unit/' + unitId + '/performance';var options={type:'GET',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:{collectionType:collectionType,userUid:userUid}};return _ember['default'].$.ajax(url,options);},getSuggestionPerformance:function getSuggestionPerformance(context){var adapter=this;var namespace=adapter.get('insightsNamespace');var url=namespace + '/suggestions/performance';var options={type:'POST',headers:adapter.defineHeaders(),contentType:'application/json; charset=utf-8',dataType:'json',data:JSON.stringify(context)};return _ember['default'].$.ajax(url,options);},defineHeaders:function defineHeaders(){return {Authorization:'Token ' + this.get('session.token-api3')};}});});