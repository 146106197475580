define('gooru-web/components/reports/pull-up/activity/assessment-performance-data-by-upload-image',['exports','ember','gooru-web/models/taxonomy/taxonomy-tag','gooru-web/models/taxonomy/taxonomy-tag-data','gooru-web/utils/utils','gooru-web/mixins/modal','gooru-web/config/config'],function(exports,_ember,_gooruWebModelsTaxonomyTaxonomyTag,_gooruWebModelsTaxonomyTaxonomyTagData,_gooruWebUtilsUtils,_gooruWebMixinsModal,_gooruWebConfigConfig){exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Attributes
classNames:['assessment-performance-data-by-upload-image'], /**
   * @property {MediaService} Media service API SDK
   */mediaService:_ember['default'].inject.service('api-sdk/media'), /**
   * @property {AssessmentService} assessment service API SDK
   */assessmentService:_ember['default'].inject.service('api-sdk/assessment'), /**
   * @property {PerformanceService} performance service API SDK
   */performanceService:_ember['default'].inject.service('api-sdk/performance'), /**
   * @property {SessionService} session service API SDK
   */session:_ember['default'].inject.service('session'), /**
   * @requires service:notifications
   */notifications:_ember['default'].inject.service(), /**
   * @type {i18nService} Service to retrieve translations information
   */i18n:_ember['default'].inject.service(), /**
   * @property {I2dService} Media service API SDK
   */i2dService:_ember['default'].inject.service('api-sdk/i2d'), // -------------------------------------------------------------------------
// Properties
/**
   * List of selected files
   * @prop {Array}
   */selectedFiles:_ember['default'].A([]), /**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}), /**
   * @property {String} contentSource
   */contentSource:'dailyclassactivity', /**
   * It maintains the preview content
   * @prop {Array}
   */activeContent:_ember['default'].computed('activePreviewIndex',function(){var component=this;var activeIndex=component.get('activePreviewIndex');return component.get('selectedFiles').objectAt(activeIndex);}), /**
   * List of assessment questions
   * @prop {Array}
   */questions:_ember['default'].A([]), /**
   * List of valid file extensions.
   * @prop {String}
   */mimeType:'image/*', /**
   * It maintains the assessment Id.
   * @prop {String}
   */assessmentId:_ember['default'].computed.alias('assessment.id'), /**
   * @property {Boolean} isMobileView
   * Property to handle is mobile view
   */isMobileView:(0,_gooruWebUtilsUtils.isCompatibleVW)(_gooruWebConfigConfig.SCREEN_SIZES.MEDIUM), /**
   * It maintains the format
   * @prop {String}
   */format:_ember['default'].computed.alias('assessment.format'), /**
   * It maintains the id of course.
   * @prop {String}
   */courseId:_ember['default'].computed.alias('course.id'), /**
   * It maintains the activity id.
   * @prop {String}
   */activityId:_ember['default'].computed.alias('activityData.id'), /**
   * It maintains the whether button is disable or not.
   * @prop {Boolean}
   */isDisableButton:false, /**
   * It maintains the index position of current preview image.
   * @prop {Integer}
   */activePreviewIndex:0, /**
   * It maintains the whether we need to show confirm button or not.
   * @prop {Boolean}
   */showConfirm:false, /**
   * It maintains the whether we need to show toggle buttons or not.
   * @prop {Boolean}
   */showToggle:false, /**
   * @property {TaxonomyTag[]} List of taxonomy tags
   */taxonomyTags:_ember['default'].computed('assessment.standards.[]',function(){var standards=this.get('assessment.standards');if(standards){standards = standards.filter(function(standard){return !_gooruWebModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));});}return _gooruWebModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);}), // -------------------------------------------------------------------------
// Observers
resetPicker:_ember['default'].observer('mimeType',function(){ // Clear any previous errors
this.get('filePickerErrors').clear();}),onError:_ember['default'].observer('filePickerErrors.[]',function(){var errorMsg=this.get('i18n').t('common.errors.file-max-size-increase');this.notifyInvalidFileType(errorMsg);}), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when user select any file.
     */prepareForSubmission:function prepareForSubmission(file){var component=this;component.set('showConfirm',true);var selectedFiles=component.get('selectedFiles');component.readFile(file).then(function(fileData){var uploadedFile=_ember['default'].Object.create({file:fileData,url:fileData.data,name:fileData.name,isUploadSuccess:false});selectedFiles.pushObject(uploadedFile);});}, /**
     * Action triggered when user select any file for re-upload.
     */prepareForReUpload:function prepareForReUpload(file){var component=this;component.set('showConfirm',true);var selectedFile=component.get('selectedFile');component.readFile(file).then(function(fileData){selectedFile.set('file',fileData);selectedFile.set('url',fileData.data);selectedFile.set('name',fileData.name);selectedFile.set('isUploadSuccess',false);selectedFile.set('isUploadReadyForReview',false);selectedFile.set('conversionErros',null);selectedFile.set('uploadStatus',1);});}, /**
     * @function actions:disableButtons
     */resetFileSelection:function resetFileSelection(){ // Reset the input element in the file picker
// http://stackoverflow.com/questions/1043957/clearing-input-type-file-using-jquery/13351234#13351234
var $fileInput=this.$('input[type="file"]');$fileInput.wrap('<form>').closest('form').get(0).reset();$fileInput.unwrap();}, /**
     * Action triggered when user click re-upload button.
     */onReUpload:function onReUpload(selectedFile){var component=this;component.set('selectedFile',selectedFile);}, /**
     * Action triggered when user confirms the upload.
     */onConfirmUpload:function onConfirmUpload(){var component=this;component.set('isDisableButton',true);component.uploadImageFiles();}, /**
     * Action triggered when user select student score button.
     */showStudentScores:function showStudentScores(){var component=this;component.set('showScoreReview',true);}, /**
     * Action triggered when user select image preview button.
     */showImagePreview:function showImagePreview(){var component=this;component.set('showScoreReview',false);}, /**
     * Action triggered when user clicks on Ingore button.
     */onIgnore:function onIgnore(selectedFile){var component=this;component.get('selectedFiles').removeObject(selectedFile);}, /**
     * Action triggered when user select confirm score button.
     */onConfirmScore:function onConfirmScore(){var component=this;var performanceService=component.get('performanceService');var activeContent=component.get('activeContent');var students=component.get('studentList');var performancePromises=students.map(function(student){return performanceService.updateCollectionOfflinePerformance(component.getAssessmentDataParams(student));});Promise.all(performancePromises).then(function(){component.get('i2dService').markImageReviewed(activeContent.get('id')).then(function(){if(component.get('selectedFiles').length === 1){component.sendAction('onClosePullUp');}});});}}, // -------------------------------------------------------------------------
// Methods
init:function init(){this._super.apply(this,arguments);this.set('filePickerErrors',_ember['default'].A());}, // -------------------------------------------------------------------------
// Methods
/**
   * It is used to convert the selected file to data url
   * @return {Promise Object}
   */readFile:function readFile(file){var _this=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){var reader=new FileReader();reader.onloadend = function(){file.data = reader.result;resolve(file);};if(_gooruWebConfigConfig.I2D_SUPPORTED_IMAGE_TYPES.validTypes.contains(file.type)){reader.readAsDataURL(file);}else {var warningMsg=_this.get('i18n').t('common.errors.file-upload-missing',{extensions:_gooruWebConfigConfig.I2D_SUPPORTED_IMAGE_TYPES.validExtensions});_this.notifyInvalidFileType(warningMsg);reject();}});}, /**
   * It is used to upload the selected filees to s3 and BE
   */uploadImageFiles:function uploadImageFiles(){var component=this;if(component.get('isMobileView')){component.handleMobileViewControl();component.set('isUpload',true);}var selectedFiles=component.get('selectedFiles').filterBy('file');var filePromises=selectedFiles.map(function(selectedFile){return new _ember['default'].RSVP.Promise(function(resolve,reject){return component.get('mediaService').uploadContentFile(selectedFile.get('file')).then(function(imageId){selectedFile.set('isUploadSuccess',true);selectedFile.set('isUploadFailed',false);resolve(_ember['default'].Object.create({url:(0,_gooruWebUtilsUtils.addProtocolIfNecessary)(imageId),id:selectedFile.get('id')}));},function(error){selectedFile.set('isUploadSuccess',false);selectedFile.set('isUploadFailed',true);component.set('showError',true);reject(error);});});});Promise.all(filePromises).then(function(imagePaths){var reUploadedFiles=imagePaths.filterBy('id');var uploadedFiles=imagePaths.rejectBy('id').map(function(image){return image.get('url');});if(reUploadedFiles.length){reUploadedFiles.map(function(uploadFile){var request=_ember['default'].Object.create({image_path:uploadFile.get('url')});component.get('i2dService').replaceImage(uploadFile.get('id'),request).then(function(){component.notifyUploadSuccess();});});}if(uploadedFiles.length){var imageUploadContext=component.get('imageUploadContext');imageUploadContext.set('image_path',uploadedFiles);component.get('i2dService').uploadImage(imageUploadContext).then(function(){var model={onConfirm:function onConfirm(){component.sendAction('onClosePullUp');}};component.actions.showModal.call(component,'content.modals.i2d-message-dialog',model);});}});}, /**
   * This method is used to handle mobile view
   */handleMobileViewControl:function handleMobileViewControl(){var component=this;component.$('.left-panel').show();component.$('.right-panel').hide();}, /**
   * This method is used to trigger toast message
   */notifyUploadSuccess:function notifyUploadSuccess(){var component=this;component.get('notifications').setOptions({positionClass:'toast-top-full-width',toastClass:'gooru-toast',timeOut:10000});var successMsg=component.get('i18n').t('upload-success');component.get('notifications').success('' + successMsg);}, /**
   * This method is used to trigger toast message
   */notifyInvalidFileType:function notifyInvalidFileType(message){var component=this;component.get('notifications').setOptions({positionClass:'toast-top-full-width',toastClass:'gooru-toast',timeOut:10000});component.get('notifications').warning('' + message);}, /**
   * @function getAssessmentDataParams
   */getAssessmentDataParams:function getAssessmentDataParams(student){var component=this;var questions=student.get('questions');var assessmentResources=_ember['default'].A([]);var activityData=component.get('activityData');var activityId=activityData.get('id');var conductedOn=component.get('activityData.activation_date')?new Date(component.get('activityData.activation_date')):new Date();var classId=component.get('classId');var assessment=component.get('assessment');var courseId=component.get('course')?component.get('course').id:null;questions.map(function(question){var resourceData={resource_id:question.get('questionId'),resource_type:'question',question_type:question.get('questionType'),score:Number(question.get('score')) || 0,max_score:question.get('maxScore')};assessmentResources.push(resourceData);});var studentPerformanceData={tenant_id:component.get('session.tenantId') || null,student_id:student.get('userId'),session_id:(0,_gooruWebUtilsUtils.generateUUID)(),class_id:classId,collection_id:assessment.get('id'),collection_type:'assessment',content_source:component.get('contentSource'),time_zone:component.get('timeZone'),conducted_on:conductedOn.toISOString(),path_id:0,path_type:null,resources:assessmentResources,course_id:courseId,additionalContext:btoa(JSON.stringify({dcaContentId:activityId}))};return studentPerformanceData;}});});