define('gooru-web/components/teacher/class/gru-class-navigation',['exports','ember','gooru-web/mixins/configuration'],function(exports,_ember,_gooruWebMixinsConfiguration){ /**
 * Teacher class navigation
 *
 * Component responsible for enabling more flexible navigation options for the teacher class.
 * For example, where {@link teacher/class/gru-class-navigation.js}} allows access the teacher class information and navigate through the menu options.
 * @module
 * @see controllers/teacher/class.js
 * @augments ember/Component
 */exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsConfiguration['default'],{ // -------------------------------------------------------------------------
// Dependencies
// -------------------------------------------------------------------------
// Attributes
classNames:['gru-class-navigation','teacher'], // -------------------------------------------------------------------------
// Actions
actions:{ /**
     *
     * Triggered when an menu item is selected
     * @param item
     */selectItem:function selectItem(item){if(this.get('onItemSelected')){this.selectItem(item);this.sendAction('onItemSelected',item);}}, /**
     * Triggered when a menu item is selected. Set the class icon for the item selected showing in the mobiles dropdown menu.
     */toggleHeader:function toggleHeader(){this.set('toggleState',!this.get('toggleState'));if(this.onCollapseExpandClicked){this.sendAction('onCollapseExpandClicked',this.get('toggleState'));}}}, // -------------------------------------------------------------------------
// Events
/**
   * DidInsertElement ember event
   */didInsertElement:function didInsertElement(){var item=this.get('selectedMenuItem');this.selectItem(item);},willDestroyElement:function willDestroyElement(){this._super.apply(this,arguments);this.set('selectedMenuItem',null);}, // -------------------------------------------------------------------------
// Properties
/**
   * @property {Class} class
   */'class':null, /**
   * @property {String|Function} onItemSelected - event handler for when an menu item is selected
   */onItemSelected:null, /**
   * @property {String} selectedMenuItem - menu Item selected
   */selectedMenuItem:null, /**
   * @property {boolean|Function} onCollapseExpandClicked - event handler for when the toggle button is clicked
   */onCollapseExpandClicked:null, /**
   * @property {boolean} toggleState - indicates the toggle button state, false means open, true means closed
   */toggleState:false, // -------------------------------------------------------------------------
// Observers
/**
   * Refreshes the left navigation with the selected menu item
   */refreshSelectedMenuItem:(function(){var item=this.get('selectedMenuItem');this.selectItem(item);}).observes('selectedMenuItem'), // -------------------------------------------------------------------------
// Methods
/**
   * Triggered when a menu item is selected. Set the class icon for the item selected showing in the mobiles dropdown menu.
   * @param {string} item
   */selectItem:function selectItem(item){if(item){var itemElement='.' + item;this.$('.tab').removeClass('active');this.$(itemElement).addClass('active');}}});});